/* eslint-disable no-console */
import axios from "axios";

export const createCsvFromJson = async (title, jsonData) => {
  try {
    const response = await axios.post(
      "/api/files/csv",
      JSON.stringify({ title, data: jsonData }),
      { responseType: "blob" },
    );
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${title}.csv`);
    document.body.appendChild(link);
    link.click();
    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error(error.response.status);
  }
};

export default {
  createCsvFromJson,
};
