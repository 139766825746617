/* eslint-disable no-underscore-dangle */
import React, {
  useRef, useEffect, useState, useCallback, useMemo, useReducer,
} from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  Box,
  Button,
  Fab,
  Grid,
  Modal,
  TextField,
  Typography,
  Skeleton, useMediaQuery,
} from "@mui/material";
import ErrorBoundary from "@/utils/ErrorBoundary";
import useDebounce from "@/hooks/useDebounce";
import Progress from "@/ui/atoms/Progress";
import {
  searchOrg,
  getSavedSearches,
  createSavedSearch,
  patchSavedSearch,
} from "@/api/Search";
import {
  getFirmLists,
  getFirmListsMembers,
  getTopCompaniesList,
  getAllTopCompanies,
} from "@/api/InvestmentFirms";
import AffinityModal from "@/components/AffinityModal";
import { FundingTypes } from "@/utils/FundingTypes";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import omit from "lodash/omit";

import Filters from "./Filters";
import SearchList from "./SearchList";

dayjs.extend(utc);

const sortMapping = {
  co_most_recent_funding: {
    filter: { "deals.deal_date": { mode: "max", order: "desc", nested: { path: "deals" } } },
    label: "Most Recent Funding",
  },
  MOIC_model: {
    label: "MOIC Model",
    filter: {
      "signals.score": {
        mode: "max",
        order: "desc",
        nested: {
          path: "signals",
          filter: {
            term: { "signals.signal_type": "signal_fimoica_prediction" },
          },
        },
      },
    },
  },
};

const DealSearchOptions = {
  "Most Recent Deal": { tailIndex: 0 },
  "All Deals": {},
  "First Deal": { headIndex: 0 },
};

const RAISED_FILTER_MIN = 0;
const RAISED_FILTER_MAX = Infinity;

const initialState = {
  dealDateRange: {},
  // default relationship firm
  chosenFirmList: "6c8b39d5-8c2e-46db-ac9e-8c716b2bf518",
  chosenCompaniesList: "None",
  customFirms: [],
  usaOnly: true,
  isBlockchain: false,
  minimumNumberOfFirms: 1,
  termSearch: "",
  filterRaised: [
    RAISED_FILTER_MIN,
    RAISED_FILTER_MAX,
  ],
  relativeDealSearch: "Most Recent Deal",
  dealNames: [
    "Series B",
    "Series A",
    "Early Stage VC",
    "Seed",
    "Angel",
  ],
  sort: "co_most_recent_funding",
  predictedSectors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "changeValue":
      return { ...state, [action.field]: action.value };
    case "replaceState":
      return { ...action.state };
    default:
      throw new Error();
  }
};

export default function Search() {
  const [busy, setBusy] = useState(false);
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [showFilters, setShowFilters] = useState(true);
  const [affModalCo, setAffModalCo] = useState(null);
  const [firmListMembers, setFirms] = useState([]);

  const [savedSearchModal, setSaveModalState] = useState(null);

  const [searchState, dispatch] = useReducer(reducer, initialState);

  const [savedSearchId, setSavedSearchId] = useState("None");
  useEffect(() => {
    if (isSmDown) {
      setShowFilters(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmDown]);

  const {
    dealDateRange,
    chosenFirmList,
    chosenCompaniesList,
    customFirms,
    usaOnly,
    predictedSectors,
    isBlockchain,
    minimumNumberOfFirms,
    termSearch,
    filterRaised,
    relativeDealSearch,
    dealNames,
    sort,
  } = searchState;

  const debouncedTermSearch = useDebounce(termSearch, 500);

  const queryClient = useQueryClient();

  const isInTouchPWA = useIsInTouchPWA();

  const prepareSearchQueryForStorage = ({ searchName, searchQuery, id }) => {
    const rest = omit(searchQuery, ["dealDateRange"]);
    return { id, searchName, searchQuery: rest };
  };

  const parseSavedSearch = (savedSearchQuery) => {
    const mergedState = { ...initialState, ...savedSearchQuery };
    if (mergedState.filterRaised[1] == null) {
      // python dicts have no concept of Math.Infinity
      // rather than write some custom parser I introduce this temporary hack and tech debt.
      // thank you.
      mergedState.filterRaised[1] = RAISED_FILTER_MAX;
    }
    return mergedState;
  };

  const {
    data: firmList = [],
  } = useQuery(["FirmList"], getFirmLists);

  const { data: companiesList = [] } = useQuery(["CompaniesList"], getTopCompaniesList);
  const { data: listIdToCompanies = [] } = useQuery(["AllTopCompanies"], getAllTopCompanies);

  const { data: savedSearches = [] } = useQuery(
    ["SavedSearches"],
    getSavedSearches,
  );

  const saveModalStateCo = (id) => {
    let findSavedSearch = null;
    if (id === "None") {
      setSaveModalState(
        { searchName: "", searchQuery: searchState, id: null },
      );
    } else if (savedSearches.length && savedSearchId !== "None") {
      findSavedSearch = savedSearches.find((x) => x.id === id);
      setSaveModalState({ ...findSavedSearch, searchQuery: searchState });
    }
  };

  const combinedFirmsList = useMemo(() => {
    const flattenedList = firmListMembers.map(((firm) => {
      if (firm.alsoKnownAs) {
        return [firm.name, ...firm.alsoKnownAs];
      }
      return firm.name;
    })).flat();
    return [...(customFirms.map((x) => x.name)), ...flattenedList];
  }, [customFirms, firmListMembers]);

  const dealFilter = useMemo(() => {
    if (
      (relativeDealSearch
        || dealNames
        || combinedFirmsList?.length > 0
        || (dealDateRange?.startDate && dealDateRange?.endDate)
      )) {
      let filteredAmount = null;
      if (filterRaised[0] !== RAISED_FILTER_MIN && filterRaised[1] !== RAISED_FILTER_MAX) {
        filteredAmount = filterRaised;
      }
      return {
        type: "deals",
        params: {
          ...DealSearchOptions[relativeDealSearch],
          dealNames,
          minimumNumberOfFirms,
          investorNames: combinedFirmsList,
          dealDateRange: (
            dealDateRange?.startDate && dealDateRange?.endDate
              ? [dealDateRange.startDate, dealDateRange.endDate] : null),
          dealRaisedAmountRange: filteredAmount,
        },
      };
    }
    return null;
  }, [
    JSON.stringify(combinedFirmsList),
    JSON.stringify(dealNames),
    JSON.stringify(filterRaised),
    relativeDealSearch,
    minimumNumberOfFirms,
    combinedFirmsList,
    dealNames,
    filterRaised,
    dealDateRange?.startDate,
    dealDateRange?.endDate,
  ]);

  const filters = [];
  if (dealFilter) {
    filters.push(dealFilter);
  }
  if (usaOnly) {
    filters.push({
      type: "term_match",
      params: {
        country: "US",
      },
    });
  }

  const chosenCompaniesListSelected = chosenCompaniesList !== "None" && chosenCompaniesList in listIdToCompanies;

  if (chosenCompaniesListSelected) {
    filters.push({
      type: "execs_from_top_companies",
      params: {
        valorIds: listIdToCompanies[chosenCompaniesList].map((c) => c.valorId),
      },
    });
  }

  if (isBlockchain) {
    filters.push({
      type: "is_blockchain",
      params: {},
    });
  }

  if (debouncedTermSearch) {
    filters.push({ type: "multi_match", params: { query: debouncedTermSearch, fields: ["synaptic_classifications^2", "description", "name^3"] } });
  }

  if (predictedSectors.length) {
    predictedSectors.forEach((sector) => {
      filters.push({
        type: "term_match",
        params: {
          sector_predictions: sector,
        },
      });
    });
  }

  const {
    data: searchResults,
    error,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    [
      "Search",
      sort,
      JSON.stringify(filters),
      savedSearchId,
    ],
    async ({ pageParam: offset = 0 }) => {
      const response = await searchOrg({
        filters,
        sort: [sortMapping[sort].filter],
        offset,
      });
      return response;
    },
    {
      enabled: (
        Boolean(Object.keys(dealDateRange)?.length === 0
        || (dealDateRange?.startDate && dealDateRange?.endDate))),
      // this could get more complicated maybe worth memoizing in the future
      getNextPageParam: (lastPage) => {
        if (lastPage?.total?.offset < lastPage?.total?.value) {
          return (lastPage?.total?.offset ?? 0) + 20;
        }
        return false;
      },
    },
  );

  const [pages, setPages] = useState([]);
  const parentRef = useRef();

  useEffect(
    () => {
      const setChosenFirmList = async (id) => {
        setBusy(true);
        try {
          if (id === "None") {
            setFirms([]);
          } else {
            const response = await getFirmListsMembers(id);
            setFirms(response);
          }
        } finally {
          setBusy(false);
        }
      };
      setChosenFirmList(chosenFirmList);
    },
    [chosenFirmList],
  );

  const setSavedSearch = (id) => {
    setSavedSearchId(id);
    if (id === "None") {
      dispatch({ type: "replaceState", state: initialState });
    } else {
      const findSavedSearch = savedSearches.find((x) => x.id === id);
      if (findSavedSearch?.searchQuery) {
        dispatch({ type: "replaceState", state: parseSavedSearch(findSavedSearch.searchQuery) });
      }
    }
  };

  useEffect(() => {
    const arr = searchResults?.pages.map((row) => row?.hits);
    if (arr) setPages(arr.flat());
  }, [searchResults?.pages]);

  const rowVirtualizer = useVirtualizer({
    count: pages?.length,
    getScrollElement: () => parentRef?.current,
    estimateSize: useCallback(() => (isSmDown ? 300 : 248), [isSmDown]),
  });

  const renderModal = () => (
    <Modal
      open={savedSearchModal !== null}
      onClose={() => setSaveModalState(null)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.5)",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "640px",
          height: "100%",
          maxHeight: "360px",
          padding: 4,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: (theme) => theme.shape.borderRadius,
          outline: "none",
          boxShadow: (theme) => theme.shadows[5],
        }}
      >
        <Typography>
          {savedSearchId !== "None"
            ? "Overwrite Search" : "Save Search"}
        </Typography>
        <TextField
          label="Search Name"
          value={savedSearchModal?.searchName}
          onChange={
            (e) => setSaveModalState(
              (old) => (
                {
                  ...old,
                  searchName: e.target.value,
                }
              ),
            )
          }
        />
        <Box display="flex" justifyContent="space-between">
          <Button onClick={() => setSaveModalState(null)} variant="outlined" color="secondary">
            Cancel
          </Button>

          {savedSearchModal?.id !== null
            ? (
              <Box display="flex" style={{ gap: "16px" }}>
                <Button
                  disabled={busy}
                  onClick={async () => {
                    setBusy(true);
                    try {
                      const { id, searchQuery: patchedQuery } = await patchSavedSearch(
                        prepareSearchQueryForStorage(savedSearchModal),
                      );
                      queryClient.invalidateQueries("SavedSearches");
                      dispatch({ type: "replaceState", state: parseSavedSearch(patchedQuery) });
                      setSavedSearchId(id);
                      setSaveModalState(null);
                    } finally {
                      setBusy(false);
                    }
                  }}
                  variant="outlined"
                  color="primary"
                >
                  Overwrite
                </Button>
                <Button
                  disabled={busy}
                  onClick={async () => {
                    setBusy(true);
                    try {
                      const { id, searchQuery: newQuery } = await createSavedSearch(
                        prepareSearchQueryForStorage(savedSearchModal),
                      );

                      queryClient.invalidateQueries("SavedSearches");
                      dispatch({ type: "replaceState", state: parseSavedSearch(newQuery) });
                      setSavedSearchId(id);
                      setSaveModalState(null);
                    } finally {
                      setBusy(false);
                    }
                  }}
                  variant="contained"
                  color="primary"
                >
                  Save as New
                </Button>
              </Box>
            )
            : (
              <Button
                disabled={busy}
                onClick={async () => {
                  setBusy(true);
                  try {
                    const { id, searchQuery: newQuery } = await createSavedSearch(
                      prepareSearchQueryForStorage(savedSearchModal),
                    );
                    queryClient.invalidateQueries("SavedSearches");
                    dispatch({ type: "replaceState", state: parseSavedSearch(newQuery) });
                    setSavedSearchId(id);
                    setSaveModalState(null);
                  } finally {
                    setBusy(false);
                  }
                }}
                variant="contained"
                color="primary"
              >
                Save
              </Button>

            )}
        </Box>

      </Box>
    </Modal>
  );

  return (
    <ErrorBoundary errorMessage="An Error occured with your search, contact the labs team with your parameters. Refresh the page to keep searching">
      {renderModal()}
      <Grid
        container
        style={{
          overflow: "hidden",
          flex: 1,
        }}
      >
        {!showFilters && (
          <Fab
            sx={{
              position: "absolute",
              bottom: (theme) => (isInTouchPWA ? theme.spacing(10) : theme.spacing(2)),
              left: (theme) => theme.spacing(12),
              zIndex: 1000,
            }}
            color="secondary"
            variant="extended"
            onClick={() => setShowFilters(true)}
          >
            Filter
          </Fab>
        )}
        {showFilters && (
          <Filters
            setShowFilters={setShowFilters}
            dispatch={dispatch}
            chosenFirmList={chosenFirmList}
            chosenCompaniesList={chosenCompaniesList}
            companiesList={companiesList}
            noCompanies={
              chosenCompaniesListSelected
                ? listIdToCompanies[chosenCompaniesList].length
                : null
            }
            busy={busy}
            firmList={firmList}
            firmListMembers={firmListMembers}
            customFirms={customFirms}
            minimumNumberOfFirms={minimumNumberOfFirms}
            FundingTypes={FundingTypes}
            dealNames={dealNames}
            filterRaised={filterRaised}
            dealDateRange={dealDateRange}
            termSearch={termSearch}
            usaOnly={usaOnly}
            predictedSectors={predictedSectors}
            isBlockchain={isBlockchain}
            relativeDealSearch={relativeDealSearch}
            DealSearchOptions={DealSearchOptions}
            sort={sort}
            sortMapping={sortMapping}
            savedSearches={savedSearches}
            savedSearchId={savedSearchId}
            setSavedSearch={setSavedSearch}
            saveModalStateCo={saveModalStateCo}
            setBusy={setBusy}
            filters={filters}
          />
        )}

        <Grid item xs={12} sm={12} md={showFilters ? 9 : 12}>
          {status === "loading" ? (
            <Box display="flex" flexDirection="column" style={{ gap: "16px", margin: "0 8px" }}>
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
              <Skeleton variant="rounded" height={184} />
            </Box>
          ) : status === "error" ? (
            <p>
              Error:
              {" "}
              {error.message}
            </p>
          ) : (
            <div
              ref={parentRef}
              style={{
                height: "95vh",
                width: "100%",
                overflow: "auto",
              }}
              onScroll={(e) => {
                // check if at bottom
                const defaultNegativeHeight = 300;
                const { scrollTop, scrollHeight, clientHeight } = e.target;
                if (scrollHeight - scrollTop - clientHeight < defaultNegativeHeight) {
                  if (!hasNextPage || isFetchingNextPage) return;
                  fetchNextPage();
                }
              }}
            >
              <div>
                {!(rowVirtualizer.getVirtualItems().length === 0)
                  && isFetchingNextPage && (
                    <Progress
                      sx={{
                        position: "absolute",
                        left: "50%",
                        bottom: "16px",
                        zIndex: 1000,
                      }}
                    />
                )}
              </div>
              <SearchList
                topCompanies={
                  chosenCompaniesListSelected
                    ? listIdToCompanies[chosenCompaniesList]
                    : []
                }
                totalSize={rowVirtualizer.getTotalSize()}
                items={rowVirtualizer.getVirtualItems()}
                setAffModalCo={setAffModalCo}
                combinedFirmsList={combinedFirmsList}
                relativeDealSearch={relativeDealSearch}
                pages={pages}
              />
            </div>
          )}
        </Grid>
      </Grid>

      <AffinityModal
        open={!!affModalCo}
        onClose={() => setAffModalCo(null)}
        name={affModalCo?.name}
        domain={affModalCo?.domain}
        valorId={affModalCo?.valorId}
      />
    </ErrorBoundary>
  );
}
