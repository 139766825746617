import React, { useMemo, useState } from "react";
import { useAuth } from "@/hooks/useAuth";
import {
  Card,
  Box, ButtonBase, Typography, useMediaQuery, Collapse,
  Button,
  Breadcrumbs,
  Grid,
} from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import {
  MeetingRoom,
  ExpandMore,
  ExpandLess,
} from "@mui/icons-material";
import {
  Link, useNavigate,
  useParams,
} from "react-router-dom";
import { destinations } from "@/utils/destinations";
import Flake from "@/ui/atoms/Icons/Flake";
import TakeNoteModal from "@/components/TakeNoteModal";
import TakeOAModal from "@/components/TakeOAModal";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { createTask } from "@/api/Process";
import CmdK from "@/components/CmdK";
import FormWizard from "../../ProcessManagement/Forms/Wizard";

const NOTE_WRITE_MODAL = "WRITE_NOTE";
const MAKE_OA_MODAL = "MAKE_OA";

const QA_ID = "d5495316-7131-4612-97b7-73f8ade3b55e";
const ACD_ID = "2b88ae61-1452-456a-8936-df8044eadaf1";

const INVESTMENT_PROCESS = "Investment Process";
const INVESTMENT_PROCESS_TAB_KEY = "investment_process";
const PORTFOLIO_PROCESS = "Portfolio Company Management Process";
const PORTFOLIO_PROCESS_TAB_KEY = "portfolio_process";

const SectionButton = styled(ButtonBase)(({ theme, disabled }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: theme.spacing(2),
  width: "100%",
  height: "100%",
  borderRadius: theme.shape.borderRadius,
  border: 1,
  borderStyle: "solid",
  borderColor: theme.palette.dividerBlue,
  "& .MuiTouchRipple-root .MuiTouchRipple-ripple": {
    color: theme.palette.blue.main, // Change this to your desired ripple color
  },
  ...(disabled
    ? {
      pointerEvents: "none",
      backgroundColor: theme.palette.action.disabledBackground,
    }
    : {
      backgroundColor: theme.palette.background.paper,
      "&:hover": {
        backgroundColor: theme.palette.action.hover,
      },
    }),
}));

function RenderSection({ title, items }) {
  const [expanded, setExpanded] = useState(false);
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isExpandable = items.length > 3;

  const toggleExpand = () => setExpanded((prev) => !prev);

  const itemsCanSee = items.filter((i) => {
    try {
      if (i.canSee) {
        return i?.canSee(user) ?? true;
      }
      return true;
    } catch (e) {
      console.error(e, i);
      return false;
    }
  });

  if (itemsCanSee.length === 0) return null;

  const visibleItems = !expanded
    ? itemsCanSee.slice(0, 3)
    : itemsCanSee.slice(0);

  const renderSectionButton = (item) => (
    <SectionButton
      theme={theme}
      disabled={item.disabled ?? false}
      onClick={() => (item.path ? navigate(item.path) : item.onClick())}
    >
      <Box>
        <Box
          sx={(t) => ({
            display: "flex",
            gap: "8px",
            alignItems: "center",
            color: t.palette.text.primary,
          })}
        >
          {item.Icon
            ? <item.Icon /> : null}
          <Typography sx={{ textAlign: "left" }} variant="boldSubtitle1">{item.name}</Typography>
        </Box>
        {!isSmDown && (
          <Typography
            color="text.secondary"
            variant="subtitle2"
            sx={{
              textAlign: "start",
            }}
          >
            {item.subtitle}
          </Typography>
        )}
      </Box>
    </SectionButton>
  );

  return (
    <Grid item xs={12}>
      <Card sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={(t) => ({ color: t.palette.text.secondary })}
              variant="h3"
            >
              {title}
            </Typography>
          </Grid>
          {isSmDown
            ? null
            : itemsCanSee.map((item) => (
              <Grid key={item.title} item xs={12} md={6} lg={6} xl={4}>
                {renderSectionButton(item)}
              </Grid>
            ))}
        </Grid>
        {isSmDown && (
          <>
            <Collapse collapsedSize="222px" in={expanded}>
              <Grid container spacing={2}>
                {visibleItems.map((item) => (
                  <Grid key={item.title} item xs={12} md={6} lg={4}>
                    {renderSectionButton(item)}
                  </Grid>
                ))}
              </Grid>
            </Collapse>
            {isExpandable ? (
              <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                <Button
                  onClick={toggleExpand}
                  endIcon={expanded ? <ExpandLess /> : <ExpandMore />}
                >
                  {expanded ? "Show Less" : "Show More"}
                </Button>
              </Box>
            ) : null}
          </>
        )}
      </Card>
    </Grid>
  );
}

export default function ReportNavigation() {
  const { subPage, tab } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(null);
  const [openQAModal, setOpenQAModal] = React.useState(false);
  const [openACDModal, setOpenACDModal] = React.useState(false);
  const [cmdKOpen, setCmdKOpen] = useState(false);

  const handleOpenSurvey = () => {
    setCmdKOpen(true);
  };

  const titles = {
    investments: "Investments Group",
    "scale-group": "Scale Group",
    "i-r": "Investor Relations",
    infrastructure: "Infrastructure Group",
  };

  const tabMap = {
    [INVESTMENT_PROCESS_TAB_KEY]: INVESTMENT_PROCESS,
    [PORTFOLIO_PROCESS_TAB_KEY]: PORTFOLIO_PROCESS,
  };

  const isSmDown = useMediaQuery(() => theme.breakpoints.down("sm"));

  const width = isSmDown ? "128px" : "256px";
  const height = isSmDown ? "96px" : "96px";

  const Invest = destinations.HomeInvest;
  const IR = destinations.HomeIR;
  const Infra = destinations.HomeInfra;
  const Scale = destinations.HomeScale;

  const home = (
    <Box
      p={2}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          "& > div > svg": {
            width: isSmDown ? "3em" : "8em !important",
            height: isSmDown ? "3em" : "8em !important",
          },
          gap: isSmDown ? "0px" : "24px",
          marginTop: isSmDown ? "0px" : "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SectionButton
            onClick={() => navigate(IR.path)}
            sx={{
              width,
              height,
              alignItems: "center",
            }}
            variant="contained"
          >
            <IR.Icon />
            <Typography
              sx={{ textAlign: "center", width: "100%" }}
              variant="h3"
            >
              {isSmDown ? "IR Group" : "Investor Relations Group"}
            </Typography>
          </SectionButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "32px",
          }}
        >
          <SectionButton
            onClick={() => navigate(`${Invest.path}/${INVESTMENT_PROCESS_TAB_KEY}`)}
            sx={{ width, height, alignItems: "center" }}
            variant="contained"
          >
            <Invest.Icon />
            <Typography
              sx={{ textAlign: "center", width: "100%" }}
              variant="h3"
            >
              Investment Group
            </Typography>
          </SectionButton>
          <Flake />
          <SectionButton
            onClick={() => navigate(Infra.path)}
            sx={{ width, height, alignItems: "center" }}
            variant="contained"
          >
            <Infra.Icon />
            <Typography
              sx={{ textAlign: "center", width: "100%" }}
              variant="h3"
            >
              {isSmDown ? "Infra Group" : "Infrastructure Group"}
            </Typography>
          </SectionButton>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <SectionButton
            onClick={() => navigate(Scale.path)}
            sx={{ width, height, alignItems: "center" }}
            variant="contained"
          >
            <Scale.Icon />
            <Typography
              sx={{ textAlign: "center", width: "100%" }}
              variant="h3"
            >
              Scale Group
            </Typography>
          </SectionButton>
        </Box>
      </Box>
    </Box>
  );

  async function addTask(processId) {
    try {
      const task = await createTask(processId, {});
      navigate(`/process-management/${processId}/new-task/${processId}-${task.id}?view=table`);
    } catch (e) {
      console.error(e);
    }
  }

  const availableSections = useMemo(() => {
    const sec = {
      investments: {
        [INVESTMENT_PROCESS]: {
          display: {
            Actions: [
              destinations["New Opportunity"],
              {
                name: destinations["Opportunity Assessment - Create"].name,
                Icon: destinations["Opportunity Assessment - Create"].Icon,
                subtitle:
                    destinations["Opportunity Assessment - Create"].subtitle,
                onClick: () => setOpenModal(MAKE_OA_MODAL),
              },

              // destinations["Stage Update"]
              // destinations["Track Pass Follow Through"],
              // destinations["Add Post Closing Actions"],
              // destinations["Following Opportunity"],
              {
                name: destinations["Write a Note"].name,
                Icon: destinations["Write a Note"].Icon,
                subtitle: destinations["Write a Note"].subtitle,
                canSee: destinations["Write a Note"].canSee,
                onClick: () => setOpenModal(NOTE_WRITE_MODAL),
              },
              destinations["Opportunity Assessments"],
              destinations["Survey Drafts"],
            ],
            Reports: [
              destinations["New Opportunity Distribution"],
              destinations["New Opportunities - Pre OA"],
              destinations["Considering for Term Sheet"],
              destinations["Under Term Sheet"],
              destinations["Post Closing Actions"],
              destinations["Closed Investments"],
              destinations.Following,
              destinations["Pass Follow Throughs"],
            ],
            Meetings: [
              destinations["Weekly IC Meeting"],
              {
                Icon: MeetingRoom,
                name: "Growth Fund",
                subtitle: "Growth fund team meeting",
                disabled: true,
                canSee: destinations["Weekly IC Meeting"].canSee,
              },
              {
                Icon: MeetingRoom,
                name: "VSV Team",
                subtitle: "VSV team meeting",
                disabled: true,
                canSee: destinations["Weekly IC Meeting"].canSee,
              },
              {
                Icon: MeetingRoom,
                name: "VAAI Team",
                subtitle: "VAAI team meeting",
                disabled: true,
                canSee: destinations["Weekly IC Meeting"].canSee,
              },
            ],
          },
          tabKey: INVESTMENT_PROCESS_TAB_KEY,
        },
        [PORTFOLIO_PROCESS]: {
          display: {
            Actions: [
              {
                name: destinations["PortCo Setup"].name,
                Icon: destinations["PortCo Setup"].Icon,
                subtitle: destinations["PortCo Setup"].subtitle,
                canSee: destinations["PortCo Setup"].canSee,
                onClick: () => addTask("portfolio"),
              },
              {
                name: destinations["Quarterly Assessment"].name,
                Icon: destinations["Quarterly Assessment"].Icon,
                subtitle: destinations["Quarterly Assessment"].subtitle,
                canSee: destinations["Quarterly Assessment"].canSee,
                onClick: () => setOpenQAModal(true),
              },
              {
                name: destinations["Additional Capital Deployed"].name,
                Icon: destinations["Additional Capital Deployed"].Icon,
                subtitle: destinations["Additional Capital Deployed"].subtitle,
                canSee: destinations["Additional Capital Deployed"].canSee,
                onClick: () => setOpenACDModal(true),
              },
              {
                name: destinations["Write a Note"].name,
                Icon: destinations["Write a Note"].Icon,
                subtitle: destinations["Write a Note"].subtitle,
                canSee: destinations["Write a Note"].canSee,
                onClick: () => setOpenModal(NOTE_WRITE_MODAL),
              },
              {
                name: destinations["Take a Survey"].name,
                Icon: destinations["Take a Survey"].Icon,
                subtitle: destinations["Take a Survey"].subtitle,
                canSee: destinations["Take a Survey"].canSee,
                onClick: () => handleOpenSurvey(),
              },
            ],
            Reports: [
              destinations["My Active Portfolio Companies"],
              destinations["Quarterly Assessment Status"],
              destinations["Additional Capital Deployment Status"],
              { ...destinations["PortCo Setup Status"], disabled: true },
            ],
          },
          tabKey: PORTFOLIO_PROCESS_TAB_KEY,
        },
      },
      "scale-group": {
        "Scale Group": {
          display: {
            Reports: [
              destinations["Lean Growth"],
              destinations["Revenue Generation"],
              destinations["Human Capital"],
            ],
          },
        },
      },
      "i-r": {
        "Investor Relations": {
          display: {
            Reports: [
              destinations["Funds (Active / In Consideration)"],
              destinations["Fund Administration"],
            ],
          },
        },
      },
      infrastructure: {
        "Infrastructure Group": {
          display: {
            Reports: [
              destinations["Finance Tracker"],
              destinations.IT,
              destinations["IT (CEO/CIO)"],
              destinations.Legal,
            ],
          },
        },
      },
    };
    return sec;
  }, []);

  const renderSubPage = (key) => {
    const tabs = Object.keys(availableSections[key]).reduce((acc, subsection) => {
      const tabKey = availableSections[key][subsection]?.tabKey;
      if (tabKey) {
        acc.push({
          key: tabKey,
          label: tabMap[tabKey],
        });
      }
      return acc;
    }, []);

    const subSections = tab ? [tabMap[tab]] : Object.keys(availableSections[key]);

    return (
      subSections.map((section) => {
        const subsubSections = Object.keys(availableSections[key][section].display).map((sub) => (
          <RenderSection
            key={sub}
            title={sub}
            items={availableSections[key][section].display[sub]}
          />
        ));
        if (subsubSections.every((x) => x === null)) return null;

        return (
          <Grid key={section} container spacing={2}>
            {tabs.length > 0 && (
              <Grid item md={8} sm={12} xs={12}>
                <ButtonTabs
                  options={tabs}
                  onClick={(option) => navigate(`/home/${key}/${option.key}`)}
                  activeKey={tab}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h2">{section}</Typography>
            </Grid>
            {subsubSections}
          </Grid>
        );
      })

    );
  };

  return (
    <Box
      p={2}
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: "240px", marginBottom: "16px" }}
          src="/images/primary_valor.png"
          alt="Argos Logo"
        />
      </Box>
      <Breadcrumbs
        sx={{
          marginBottom: isSmDown ? "8px" : "16px",
          visibility: subPage ? "visible" : "hidden",
        }}
      >
        <Link
          to="/home"
          style={{
            color: theme.palette.text.primary,
            display: "inline-block",
          }}
        >
          Home
        </Link>
        <Typography>{titles[subPage]}</Typography>
      </Breadcrumbs>
      {subPage ? renderSubPage(subPage) : home}
      <TakeNoteModal
        open={openModal === NOTE_WRITE_MODAL}
        onClose={() => setOpenModal(null)}
      />
      <TakeOAModal
        open={openModal === MAKE_OA_MODAL}
        onClose={() => setOpenModal(null)}
      />
      <FormWizard
        open={openQAModal}
        onClose={() => setOpenQAModal(false)}
        formId={QA_ID}
        processId="portfolio"
      />
      <FormWizard
        open={openACDModal}
        onClose={() => setOpenACDModal(false)}
        formId={ACD_ID}
        processId="portfolio"
      />
      {cmdKOpen && <CmdK initialApplet="takeSurvey" onExternalClose={setCmdKOpen} />}
    </Box>
  );
}
