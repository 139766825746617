import {
  Process, TaskList, updateTask as patchTask, updateTaskFieldValue,
} from "@/api/Process";

export function createTask() {

}

export function updateTask(
  processId: string,
  task: TaskList["tasks"][0],
  field: Process["fields"][0],
  newValue: string,
  sortOrder: number,
) {
  // get field
  // const field = fields.find((f) => f.id === fieldId);
  const fieldId = field.id;

  // get updated values
  let choiceId;
  let value;
  const payload = [];

  if (field.type === "select") {
    const choice = field.choices.find(
      ({ value: choiceValue }) => choiceValue === newValue,
    );
    if (!choice) {
      choiceId = null;
      value = null;
    } else {
      choiceId = choice.id;
      value = choice.value;
    }
  }

  if (field.type === "ryg") {
    value = newValue;
  }

  // find task
  // const task = tasks.find(({ id }) => updatedTaskId === id.toString());

  // update task
  const currentFieldValue = task.fieldValues?.[fieldId];
  const newTask = {
    ...task,
    sortOrder: sortOrder !== null ? sortOrder : task.sortOrder,
  };

  if (!currentFieldValue || currentFieldValue.choiceId !== choiceId) {
    let newFieldValues = { ...task.fieldValues };
    //  if not choiceId, remove from task.field_values in new task otherwise overwrite / set value
    if (choiceId === null) {
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
      const { [fieldId]: _, ...rest } = newFieldValues;
      newFieldValues = rest;
    } else {
      newFieldValues[fieldId] = currentFieldValue
        ? {
          ...currentFieldValue,
          choiceId,
          value,
        }
        : {
          fieldValueId: fieldId,
          choiceId,
          value,
        };
    }
    newTask.fieldValues = newFieldValues;

    if (!choiceId) {
      payload.push({});
    } else {
      payload.push({
        choiceId,
        value,
      });
    }
  }

  // update server
  if (payload.length) {
    updateTaskFieldValue(processId, task.id.toString(), fieldId, {
      correlationId: crypto.randomUUID(),
      values: payload,
    });
  }

  let newSortOrder = null;
  let newPublished = null;

  if (task.sortOrder !== sortOrder && sortOrder !== null) {
    newSortOrder = sortOrder;
  }

  if (task.published) {
    newPublished = task.published;
  }

  if (newSortOrder !== null || newPublished !== null) {
    patchTask(processId, task.id.toString(), {
      ...(newSortOrder !== null ? { sortOrder: newSortOrder } : {}),
      ...(newPublished !== null ? { published: newPublished } : {}),
    });
  }

  return newTask;
}
