import React, { useState, useMemo } from "react";
import {
  Box,
  Table,
  TableRow,
  Card,
  Typography,
  Button,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import Page from "@/ui/molecules/Page";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { useNavigate } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import { getEvents, closeTask } from "@/api/Tasks";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { canSeeAllTasks, canWriteTasks } from "@/constants/Roles";
import AutocompleteSelect from "@/ui/atoms/AutocompleteSelect";
import UserAvatar from "@/ui/atoms/UserAvatar";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  ProcessTypes,
  UserFilter,
  ProcessType,
  UserFilterType,
  taskTypeProcessId,
  taskTypePrettyName,
} from "./utils";
import {
  MTableHead,
  MTableCell,
  MHeaderCell,
  MTableBody,
  TaskActions,
  ProcessTaskLink,
  OrganizationDisplay,
  UsersDisplay,
  EventCard,
  SkeletonLoader,
} from "./TaskComponents";

dayjs.extend(relativeTime);

interface Filters {
  process: ProcessType;
  userFilter: UserFilterType;
  currentUser: string | undefined;
  organizations: string[];
  taskType: string;
}

export default function Events() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const { user } = useAuth();
  const canCurrentUserSeeAllEvents = canSeeAllTasks(user);
  const canCurrentUserWriteTasks = canWriteTasks(user);

  const [filters, setFilters] = useState<Filters>({
    process: ProcessTypes.Deals,
    userFilter: UserFilter.Assigned,
    currentUser: user.id,
    organizations: [],
    taskType: "",
  });

  const { data: events = [], isLoading } = useQuery(
    ["TaskEvents", filters.currentUser],
    () => getEvents(filters.currentUser),
  );

  const queryClient = useQueryClient();

  const closeTaskMutation = useMutation(closeTask, {
    onMutate: async (eventId) => {
      await queryClient.cancelQueries(["TaskEvents", filters.currentUser]);

      queryClient.setQueryData(["TaskEvents", filters.currentUser], (old) =>
        old?.filter((event) => event.id !== eventId)
      );
    },

    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(["TaskEvents", filters.currentUser]);
    },
  });

  const taskCounts = useMemo(() => {
    if (!events) {
      return {
        deals: { assigned: 0, cced: 0 },
        portfolio: { assigned: 0, cced: 0 },
      };
    }

    return events.reduce(
      (acc, event) => {
        const process = taskTypeProcessId[event.taskType] ?? ProcessTypes.Deals;
        const category = process === ProcessTypes.Deals ? "deals" : "portfolio";

        if (event.assignees.some((a) => a.id === user.id)) {
          acc[category].assigned += 1;
        } else if (event.ccs.some((cc) => cc.id === user.id)) {
          acc[category].cced += 1;
        }
        return acc;
      },
      {
        deals: { assigned: 0, cced: 0 },
        portfolio: { assigned: 0, cced: 0 },
      },
    );
  }, [events, user.id]);

  const processFiltered = useMemo(() => {
    if (!events) return [];
    return events.filter((event) => {
      const process = taskTypeProcessId[event.taskType] ?? ProcessTypes.Deals;
      return process === filters.process;
    });
  }, [events, filters.process]);

  const userFiltered = useMemo(() => {
    if (!events) return [];
    return processFiltered.filter(
      (event) => filters.userFilter === "all"
        || (filters.userFilter === "assigned"
          && event.assignees.some((a) => a.id === user.id)),
    );
  }, [processFiltered, filters.userFilter, user.id, events]);

  const orgFiltered = useMemo(() => {
    if (!events) return [];
    return userFiltered.filter(
      (event) => filters.organizations.length === 0
        || filters.organizations.includes(event.organization.valorId),
    );
  }, [userFiltered, filters.organizations, events]);

  const filteredEvents = useMemo(() => {
    if (!events) return [];
    return orgFiltered
      .filter(
        (event) => !filters.taskType || event.taskType === filters.taskType,
      )
      .sort((a, b) => {
        // Sort by organization name first, then by due date
        if (a.organization.name !== b.organization.name) {
          return a.organization.name.localeCompare(b.organization.name);
        }
        if (a.dueDate && b.dueDate) {
          return a.dueDate.localeCompare(b.dueDate);
        }
        return a.taskType.localeCompare(b.taskType);
      });
  }, [orgFiltered, filters.taskType, events]);

  const organizationOptions = useMemo(() => {
    const uniqueOrgs = new Map();
    userFiltered.forEach((event) => {
      uniqueOrgs.set(event.organization.valorId, {
        name: event.organization.name,
        valorId: event.organization.valorId,
      });
    });
    return Array.from(uniqueOrgs.values()).sort((a, b) => a.name.localeCompare(b.name));
  }, [userFiltered]);

  const taskTypeOptions = useMemo(
    () => Array.from(new Set(filteredEvents.map((e) => e.taskType))),
    [filteredEvents],
  );

  if (isLoading) {
    return (
      <SkeletonLoader
        isMobile={isMobile}
        canCurrentUserSeeAllEvents={canCurrentUserSeeAllEvents}
      />
    );
  }
  let contentBody = null;
  if (!events || events.length === 0) {
    contentBody = (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="h6">No current events</Typography>
      </Box>
    );
  }

  if (userFiltered.length === 0 && filters.userFilter !== "all") {
    contentBody = (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="h6">
          No events found for current user filter (CC vs Assigned)
        </Typography>
      </Box>
    );
  } else if (processFiltered.length === 0) {
    contentBody = (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="h6">
          No events found for current event type (Deals/Portfolio)
        </Typography>
      </Box>
    );
  } else if (filteredEvents.length === 0) {
    const hasUnfilteredEvents =
      userFiltered.length > 0 || processFiltered.length > 0;

    contentBody = (
      <Box sx={{ textAlign: "center", py: 4 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {hasUnfilteredEvents
            ? "No events found for current filters"
            : "No events found"}
        </Typography>

        {hasUnfilteredEvents && (
          <Button
            variant="outlined"
            onClick={() =>
              setFilters((prev) => ({
                ...prev,
                organizations: [],
                taskType: "",
              }))
            }
          >
            Reset Filters
          </Button>
        )}
      </Box>
    );
  }

  // User stats for "All Tasks" view
  const userTaskStats = filters.currentUser === undefined
    ? events.reduce((acc, event) => {
      event.assignees?.forEach((assignee) => {
        if (!acc[assignee.id]) {
          acc[assignee.id] = { user: assignee, count: 0 };
        }
        acc[assignee.id].count += 1;
      });
      return acc;
    }, {})
    : null;

  return (
    <Page>
      <Page.Content>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              md: "row",
            },
            justifyContent: {
              xs: "flex-start",
              md: "space-between",
            },
            mb: 1,
            gap: "8px",
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
            <ButtonTabs
              tabLabel="Process"
              width="400px"
              activeKey={filters.process}
              onClick={({ key }) => setFilters((prev) => ({
                ...prev,
                process: key as ProcessType,
                organizations: [],
                taskType: "",
              }))}
              options={[
                { key: ProcessTypes.Deals, label: "Investment" },
                { key: ProcessTypes.Portfolio, label: "Portfolio" },
              ]}
            />
            <ButtonTabs
              tabLabel="User Task"
              width="400px"
              color="secondary"
              activeKey={filters.userFilter}
              onClick={({ key }) => setFilters((prev) => ({
                ...prev,
                userFilter: key as UserFilterType,
              }))}
              options={[
                {
                  key: UserFilter.Assigned,
                  label: `Assigned to me (${
                    taskCounts[
                      filters.process === ProcessTypes.Deals
                        ? "deals"
                        : "portfolio"
                    ].assigned
                  })`,
                },
                {
                  key: UserFilter.All,
                  label: `Assigned + CC'd to me (${
                    taskCounts[
                      filters.process === ProcessTypes.Deals
                        ? "deals"
                        : "portfolio"
                    ].assigned
                    + taskCounts[
                      filters.process === ProcessTypes.Deals
                        ? "deals"
                        : "portfolio"
                    ].cced
                  })`,
                },
              ]}
            />
            {canCurrentUserSeeAllEvents && (
              <ButtonTabs
                tabLabel="Task View"
                width="400px"
                activeKey={
                  filters.currentUser === user.id ? "yourTasks" : "allTasks"
                }
                onClick={({ key }) => setFilters((prev) => ({
                  ...prev,
                  currentUser: key === "yourTasks" ? user.id : undefined,
                  userFilter:
                      key === "yourTasks" ? prev.userFilter : UserFilter.All,
                  organizations: [],
                  taskType: "",
                }))}
                options={[
                  { key: "yourTasks", label: "Your Tasks" },
                  { key: "allTasks", label: "All Tasks" },
                ]}
              />
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "320px",
            }}
          >
            <AutocompleteSelect
              id="organization-filter"
              label="Organization"
              multiple // Enable multiple selection
              options={organizationOptions.map((org) => ({
                id: org.valorId,
                label: org.name,
              }))}
              value={
                filters.organizations.length > 0
                  ? organizationOptions
                    .filter((org) => filters.organizations.includes(org.valorId))
                    .map((org) => ({
                      id: org.valorId,
                      label: org.name,
                    }))
                  : []
              }
              onChange={(newValue) => {
                setFilters((prev) => ({
                  ...prev,
                  organizations: Array.isArray(newValue)
                    ? newValue.map((item) => item.id)
                    : [],
                }));
              }}
            />

            {/* Task Type Filter */}
            <FormControl sx={{ minWidth: 220, maxWidth: 320, width: "100%" }}>
              <InputLabel shrink>Task Type</InputLabel>
              <Select
                value={filters.taskType}
                onChange={(e) => setFilters((prev) => ({
                  ...prev,
                  taskType: e.target.value,
                }))}
                displayEmpty
                label="Task Type"
              >
                <MenuItem value="">
                  <em>All</em>
                </MenuItem>
                {taskTypeOptions.map((type) => (
                  <MenuItem key={type} value={type}>
                    {taskTypePrettyName[type] || type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        {filters.currentUser === undefined && (
          <Stack>
            {/* User Assignment Stats */}
            <Box
              sx={{
                mb: 2,
                border: "1px solid",
                borderColor: "divider",
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                p: 2,
              }}
            >
              {Object.values(userTaskStats ?? {})
                .sort((a, b) => a.user.lastName.localeCompare(b.user.lastName))
                .map(({ user: assignee, count }) => (
                  <Box
                    key={assignee.id}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <UserAvatar
                      user={assignee}
                      style={{ width: "24px", height: "24px" }}
                      displayTooltip
                    />
                    <Typography variant="body2" color="text.primary">
                      {assignee.firstName}
                      {" "}
                      {assignee.lastName}
                      :
                      {" "}
                      {count}
                      {" "}
                      open
                      tasks
                    </Typography>
                  </Box>
                ))}
            </Box>

            {/* Task Type Stats */}
            <Box
              sx={{
                mb: 2,
                border: "1px solid",
                borderColor: "divider",
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                p: 2,
              }}
            >
              {Object.entries(
                events.reduce(
                  (acc, event) => {
                    const { taskType } = event;
                    acc[taskType] = (acc[taskType] || 0) + 1;
                    return acc;
                  },
                  {} as Record<string, number>,
                ),
              )
                .sort(([aType], [bType]) => aType.localeCompare(bType))
                .map(([taskType, count]) => (
                  <Box
                    key={taskType}
                    sx={{ display: "flex", alignItems: "center", gap: 1 }}
                  >
                    <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                      {taskTypePrettyName[taskType] || taskType}
                      :&nbsp;
                      <Typography component="span" variant="body2">
                        {count}
                      </Typography>
                    </Typography>
                  </Box>
                ))}
            </Box>
          </Stack>
        )}
        {contentBody ? (
          contentBody
        ) : (
          <Box sx={{ overflowX: "auto" }}>
            {isMobile ? (
              <Box sx={{ p: 2 }}>
                {filteredEvents?.map((event) => (
                  <EventCard key={event.id} event={event} navigate={navigate} />
                ))}
              </Box>
            ) : (
              <Card>
                <Table>
                  <MTableHead>
                    <TableRow>
                      <MHeaderCell>
                        <Typography variant="tableHeader">
                          Organization
                        </Typography>
                      </MHeaderCell>
                      <MHeaderCell>
                        <Typography variant="tableHeader">Task Type</Typography>
                      </MHeaderCell>
                      <MHeaderCell>
                        <Typography variant="tableHeader">Name</Typography>
                      </MHeaderCell>
                      <MHeaderCell>
                        <Typography variant="tableHeader">People</Typography>
                      </MHeaderCell>
                      <MHeaderCell>
                        <Typography variant="tableHeader">Action</Typography>
                      </MHeaderCell>
                      {canCurrentUserWriteTasks && (
                        <MHeaderCell>
                          <Typography variant="tableHeader">
                            Close Task
                          </Typography>
                        </MHeaderCell>
                      )}
                    </TableRow>
                  </MTableHead>
                  <MTableBody>
                    {filteredEvents?.map((event) => (
                      <TableRow key={event.id} sx={{ height: "96px" }}>
                        <MTableCell>
                          <OrganizationDisplay
                            organization={event.organization}
                          />
                        </MTableCell>
                        <MTableCell>
                          <Typography variant="boldSubtitle1">
                            {taskTypePrettyName[event.taskType] ??
                              event.taskType}
                          </Typography>
                        </MTableCell>
                        <MTableCell>
                          <ProcessTaskLink
                            id={event.task.id}
                            processId={event.task.processId}
                            name={
                              event.task.processId === "deals"
                                ? `${event.task.name}${event.task.targetFund ? ` - ${event.task.targetFund}` : ""}`
                                : event.organization.name
                            }
                            targetDate={event.task.targetCloseDate}
                            dueDate={event.dueDate}
                          />
                        </MTableCell>
                        <MTableCell>
                          <Stack spacing={1}>
                            <UsersDisplay
                              users={event.assignees}
                              label="Assignee(s)"
                            />
                            <UsersDisplay users={event.ccs} label="CC" />
                          </Stack>
                        </MTableCell>
                        <MTableCell>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              rowGap: "4px",
                            }}
                          >
                            <TaskActions
                              taskType={event.taskType}
                              processId={event.task.processId}
                              taskId={event.task.id}
                              valorId={event.organization.valorId}
                              navigate={(u) => window.open(u, "_blank")}
                            />
                          </Box>
                        </MTableCell>
                        {canCurrentUserWriteTasks && (
                          <MTableCell>
                            <Button
                              variant="contained"
                              color="error"
                              size="small"
                              onClick={() => closeTaskMutation.mutate(event.id)}
                              disabled={closeTaskMutation.isLoading}
                            >
                              {closeTaskMutation.isLoading
                                ? "Disabled"
                                : "Close Task"}
                            </Button>
                          </MTableCell>
                        )}
                      </TableRow>
                    ))}
                  </MTableBody>
                </Table>
              </Card>
            )}
          </Box>
        )}
      </Page.Content>
    </Page>
  );
}
