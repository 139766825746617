export const titleCase = (str) => {
  if (!str) return str;

  const strLowerCase = str.toLowerCase().replaceAll("_", " ");
  const wordArr = strLowerCase.split(" ").map((currentValue) => currentValue[0].toUpperCase() + currentValue.substring(1));

  return wordArr.join(" ");
};

export default titleCase;
