import axios from "axios";
import { operations } from "./index";

type GetConversationsResponses = operations["get_conversations_api_chats_conversations_get"]["responses"];
export type Conversations = GetConversationsResponses["200"]["content"]["application/json"];
export const getConversations = async (q?: string) => {
  const result = await axios.get<Conversations>(
    "/api/chats/conversations",
    {
      params: {
        q,
      },
    },
  );
  return result.data;
};

type GetConversation = operations["get_convo_api_chats_conversations__conversation_id__get"];
export type Conversation = GetConversation["responses"]["200"]["content"]["application/json"];
export const getConversation = async (conversationId) => {
  const result = await axios.get<GetConversation>(
    `/api/chats/conversations/${conversationId}`,
  );
  return result.data;
};

type UpdateConversation = operations["update_convo_api_chats_conversations__conversation_id__put"];
type ConversationBody = UpdateConversation["requestBody"]["content"]["application/json"];
export const updateConversation = async (conversationId, body: ConversationBody) => {
  const result = await axios.put<UpdateConversation>(
    `/api/chats/conversations/${conversationId}`,
    body,
  );
  return result.data;
};

export const deleteConversation = async (conversationId) => {
  const result = await axios.delete(
    `/api/chats/conversations/${conversationId}`,
  );
  return result.data;
};
