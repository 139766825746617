export const FieldTypes = [
  "number",
  "dollar",
  "date",
  "user",
  "user_multi",
  "target_date",
  "select",
  "select_multi",
  "company",
  "ryg",
  "checkbox",
  "text",
];
export const GroupableFields = ["select", "ryg", "checkbox"];
export const ChoiceFields = ["select", "select_multi"];
