import axios from "axios";

export async function createOrg() {
  const data = await axios.post("/api/combined");
  return data.data.valorId;
}

export default {
  createOrg,
};
