import React from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
} from "@mui/material";
import { uniq } from "lodash";

type SelectOption = {
  value: string;
  label: string;
};

type SelectInputProps = {
  value: string | string[] | null;
  onBlur: (value: string | string[] | null) => void;
  onChange: (value: string | string[] | null) => void;
  options: SelectOption[];
  label?: string;
  required?: boolean;
  multiple?: boolean;
  sx?: SxProps,
}

export default function SelectInput({
  value,
  onChange,
  onBlur,
  label,
  options,
  required,
  multiple,
  disabled,
  error,
  sx,
  dataCy,
}: SelectInputProps) {
  return (
    <FormControl
      required={required}
      disabled={disabled}
      error={error}
      sx={sx}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <Select
        value={value || []}
        multiple={multiple}
        onChange={(event) => {
        // if multiple, return an array of unique selected values
          if (multiple) {
            const selectedValues = uniq(event.target.value);
            onChange(selectedValues);
            return;
          }

          onChange(event.target.value);
        }}
        label={label}
        required={required}
        onBlur={(e) => {
          let newVal = e.target.value;
          if (Array.isArray(newVal) && newVal.length === 0) {
            newVal = null;
          }
          onBlur(newVal);
        }}
        fullWidth
        data-cy={dataCy}
        error={error}
      >
        {!multiple && (
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        )}
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
