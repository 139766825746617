import React, { useCallback, useMemo } from "react";
import {
  Box, Typography,
} from "@mui/material";

import SAYT from "@/components/SAYT";
import { getProcess, getProcessTasks } from "@/api/Process";
import { useQuery } from "react-query";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { SPECIAL_FIELD_SETTING_IDS } from "./ProcessView/ViewUtils";

function SAYTTaskResult({
  name,
  company,
}: {
  name: string;
  company: {
    name: string;
    logoUrl: string;
    domain: string;
  };
}) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={1}
    >
      {company && (
        <CompanyAvatar
          src={company?.logoUrl}
          name={company?.name}
          size={40}
        />
      )}
      <Box
        display="flex"
        flexDirection="column"
      >
        <Typography variant="body1">
          {name || company?.name }
        </Typography>
        {company && (
          <Typography variant="caption">
            {name && `${company?.name} - `}
            {company?.domain}
          </Typography>
        )}
      </Box>
    </Box>
  );
}

export default function ProcessTaskSAYT({
  processId,
  value,
  onChange,
}: {
  processId: string;
  value: any;
  onChange: (v: any) => void;
}) {
  const {
    data: processData,
  } = useQuery(
    ["process", processId],
    () => getProcess(processId),
    { enabled: !!processId },
  );

  const entityName = useMemo(() => processData?.settings?.find(({ key }) => key === "entity_name")?.value || "Task", [processData]);

  const primaryCompanyField = useMemo(() => (
    processData?.fields.find(({ type, isPrimary }) => type === "company" && isPrimary)
  ), [processData]);
  const nameField = useMemo(() => (
    processData?.fields.find(({ settingId }) => (
      settingId === SPECIAL_FIELD_SETTING_IDS.NAME
    ))
  ), [processData]);
  const descriptionField = useMemo(() => (
    processData?.fields.find(({ settingId }) => (
      settingId === SPECIAL_FIELD_SETTING_IDS.DESCRIPTION
    ))
  ), [processData]);

  const saytForTask = useCallback(async (query) => {
    // build filter body
    const body = {
      OR: {
        ...(query?.length > 2
          ? {
            // task name
            ilike: {
              ...(nameField
                ? { [`tasks.fieldValues.${nameField.id}.text`]: query }
                : {}),
              ...(descriptionField
                ? {
                  [`tasks.fieldValues.${descriptionField.id}.text`]: query,
                }
                : {}),
              ...(primaryCompanyField
                ? {
                  [`tasks.fieldValues.${primaryCompanyField.id}.company`]:
                        query,
                }
                : {}),
            },
          }
          : {}),
      },
    };
    const result = await getProcessTasks(
      [processId],
      10,
      null,
      [],
      body,
    );
    return result.data;
  }, [processId, primaryCompanyField, nameField, descriptionField]);

  return (
    <Box>
      <SAYT
        value={value}
        onChange={onChange}
        label={`Select ${entityName}`}
        search={(term) => saytForTask(term)}
        getOptionSelected={(option, selectedOption) => (
          option.id === selectedOption?.id
        )}
        getOptionLabel={(option) => (option ? (
          option?.fieldValues[nameField?.id]?.value
          || option?.fieldValues[primaryCompanyField?.id]?.company?.name
        ) : "")}
        renderOption={(option, { inputValue }) => (
          <SAYTTaskResult
            name={option.fieldValues[nameField?.id]?.value}
            description={option.fieldValues[descriptionField?.id]?.value}
            company={option.fieldValues[primaryCompanyField?.id]?.company}
            query={inputValue}
          />
        )}
      />
    </Box>
  );
}
