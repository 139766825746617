import React from "react";
import { Switch, FormControlLabel } from "@mui/material";

type BooleanInputValue = boolean | "Yes" | "No" | null;

type BooleanInputProps = {
  value: BooleanInputValue;
  onChange: (value: BooleanInputValue) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
};

export default function BooleanInput({
  value,
  onChange,
  label,
  required,
  disabled,
  error,
  dataCy,
}: BooleanInputProps) {
  return (
    <FormControlLabel
      control={(
        <Switch
          checked={value === "Yes" || value === true || null}
          onChange={(event) => {
            onChange(event.target.checked);
          }}
          type="checkbox"
          required={required}
          error={error}
          disabled={disabled}
          inputProps={{
            "data-cy": dataCy,
          }}
        />
      )}
      label={label}
      required={required}
      sx={{
        "& .MuiFormControlLabel-label": {
          color: error ? "red" : "inherit",
        },
      }}
    />
  );
}
