import axios from "axios";

export const getFirmLists = async () => {
  try {
    const response = await axios.get("/api/investment-firms/list");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFirmListsMembers = async (id) => {
  if (!id) {
    return [];
  }
  try {
    const response = await axios.get(`/api/investment-firms/list/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteFirmMember = async (listId, valorId) => {
  try {
    const response = await axios.delete(`/api/investment-firms/member/${listId}/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addMemberToFirmList = async (listId, valorIds) => {
  const queryParams = valorIds.map((id) => `id=${id}`).join("&");
  try {
    const response = await axios.post(
      `/api/investment-firms/members-to-list/${listId}?${queryParams}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createFirmList = async (name, description) => {
  try {
    const response = await axios.post(
      `/api/investment-firms/create-firm-list?name=${name}&description=${description}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addCompanies = async (listId, valorIds) => {
  const queryParams = valorIds.map((id) => `id=${id}`).join("&");
  try {
    const response = await axios.post(
      `/api/investment-firms/top-companies/${listId}?${queryParams}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getTopCompanies = async (listId) => {
  if (!listId) {
    return [];
  }
  try {
    const response = await axios.get(`/api/investment-firms/top-companies/${listId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getAllTopCompanies = async () => {
  try {
    const response = await axios.get("/api/investment-firms/all-top-companies");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createTopCompaniesList = async (name, description) => {
  try {
    const response = await axios.post(
      `/api/investment-firms/create-top-companies-list?name=${name}&description=${description}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getTopCompaniesValorIds = async () => {
  try {
    const response = await axios.get("/api/investment-firms/top-companies-valor-ids");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteCompany = async (listId, valorId) => {
  try {
    const response = await axios.delete(`/api/investment-firms/top-companies/${listId}/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getTopCompaniesList = async () => {
  try {
    const response = await axios.get("/api/investment-firms/top-companies-list");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  getFirmLists,
  deleteFirmMember,
  getFirmListsMembers,
  addMemberToFirmList,
  createFirmList,
  addCompanies,
  createTopCompaniesList,
  getTopCompanies,
  getAllTopCompanies,
  getTopCompaniesList,
  getTopCompaniesValorIds,
  deleteCompany,
};
