import {
  rankItem,
} from "@tanstack/match-sorter-utils";

// use the same fuzzy search logic that react-table uses
export default (value, searchValue) => {
  const itemRank = rankItem(value, searchValue);
  // Return if the item should be filtered in/out
  return itemRank.passed;
};
