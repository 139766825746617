import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import highlightWords from "highlight-words";
import CompanyAvatar from "../CompanyAvatar";
import RemoveButton from "../RemoveButton";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    minWidth: "280px",
    margin: "auto",
    cursor: "default",
  },
  root: {
    display: "flex",
  },
  query: {
    fontWeight: 800,
    cursor: "default",
  },
}));

export default function SAYTCompanyResult(props) {
  const classes = useStyles();
  const {
    title, domain, logoUrl, query, handleRemove,
  } = props;

  function renderTextWithHighlighting(text, queryText) {
    if (!text || !queryText) {
      return <Typography component="span">{ text }</Typography>;
    }

    const chunks = highlightWords({
      text,
      query: queryText,
    });

    return chunks.map(({ key, text: textChunk, match }) => (
      <Typography key={key} component="span" className={match ? classes.query : null}>{ textChunk }</Typography>
    ));
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <CompanyAvatar size="32" src={logoUrl} name={title} domain={domain} />
        <Box paddingLeft={2}>
          <Box>
            {renderTextWithHighlighting(title, query)}
          </Box>
          <Typography>{renderTextWithHighlighting(domain, query)}</Typography>
        </Box>
      </Box>
      {handleRemove && <RemoveButton onClick={handleRemove} />}
    </Box>
  );
}

SAYTCompanyResult.propTypes = {
  title: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  logoUrl: PropTypes.string,
  query: PropTypes.string,
  handleRemove: PropTypes.func,
};

SAYTCompanyResult.defaultProps = {
  logoUrl: null,
  query: "",
  handleRemove: null,
};
