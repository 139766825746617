import React, {
  useState, useMemo,
} from "react";
import {
  Box,
  Typography,
  TableRow,
  Chip,
  Tooltip,
  IconButton,
  Card,
} from "@mui/material";
import { Organization, getOrganizationPortfolioById, getOrganizationPortfolioByIdCSV } from "@/api/Organization";

import DebouncedTextField from "@/components/InfiniteTable/DebouncedTextField";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import {
  mapSorting,
  mapFilters,
  getNextPageParam,
  VirtualTableContainer,
  VirtualTableBody,
} from "@/components/InfiniteTable";

import {
  CompanyCell,
  DateCell,
  MoneyCell,
  MoicCell,
  TextCell,
  BooleanCell,
  LocationCell,
  PredictedMoicCell,
} from "@/components/Table/Primitives";
import { Cell as HeaderCell, Toolbar as TableToolbar } from "@/components/Table/Header";

import SectorsCell from "@/components/Table/Sectors/SectorsCell";
import SectorsFilter from "@/components/Table/Sectors/SectorsFilter";

import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { useInfiniteQuery } from "react-query";
import {
  ColumnFiltersState,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  ColumnResizeMode,
} from "@tanstack/react-table";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";

import dayjs from "dayjs";
import InvestorsCell from "./InvestorsCell";
import InvestorsFilter from "./InvestorsFilter";

type PortfolioPageProps = {
  company: Organization;
};

function PortfolioPage({
  company,
}: PortfolioPageProps) {
  // get portfolio for company
  const limit = 25;
  const [sorting, setSorting] = useState<SortingState>([{ id: "deal.dealDate", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | null>("");
  const [columnVisibility, setColumnVisibility] = useState<{ [key: string]: boolean }>({});
  const columnResizeMode: ColumnResizeMode = "onEnd";

  const pagedQueryKey = useMemo(() => (
    ["portfolio", company.valorId, sorting, columnFilters, globalFilter]
  ), [company.valorId, sorting, columnFilters, globalFilter]);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status: queryStatus,
  } = useInfiniteQuery({
    queryKey: pagedQueryKey,
    queryFn: ({ pageParam = null }) => getOrganizationPortfolioById(
      company.valorId,
      limit,
      pageParam,
      mapSorting(sorting),
      mapFilters(columnFilters, globalFilter),
    ),
    getNextPageParam,
  });

  const downloadAsExcel = () => getOrganizationPortfolioByIdCSV(
    company.valorId,
    mapSorting(sorting),
    mapFilters(columnFilters, globalFilter),
  );

  const pagedColumns = useMemo(() => [
    {
      id: "organization.name",
      accessorFn: (row) => ({
        valorId: row.valorId,
        name: row.name,
        domain: row.domain,
        logoUrl: row.logoUrl,
      }),
      cell: (prop) => <CompanyCell value={prop.getValue()} />,
      header: "Company",
      meta: {
        sortType: "alpha",
      },
      enableColumnFilter: false,
    },
    {
      id: "organization.country",
      accessorFn: (row) => ({
        city: row.city,
        country: row.country,
      }),
      cell: (info) => (
        <LocationCell
          value={info.getValue()}
        />
      ),
      header: "HQ Location",
      enableSorting: false,
      enableColumnFilter: false,
    },
    {
      header: "Year Founded",
      id: "organization.yearFounded",
      accessorFn: (row) => Math.floor(row.yearFounded).toString(),
      cell: (prop) => <TextCell value={prop.getValue()} />,
      meta: {
        sortType: "date",
      },
      enableColumnFilter: false,
    },
    {
      header: "Description",
      id: "organization.description",
      enableSorting: false,
      enableColumnFilter: false,
      accessorKey: "description",
      cell: (prop) => <TextCell value={prop.getValue()} />,
    },
    {
      header: "Sectors",
      id: "organization.sectors",
      enableSorting: false,
      accessorKey: "sectors",
      cell: (prop) => <SectorsCell value={prop.getValue()} />,
      filter: (column) => <SectorsFilter column={column} />,
      meta: {
        optional: true,
      },
    },
    {
      header: "Deal",
      id: "deal.roundName",
      accessorKey: "roundName",
      cell: (prop) => <TextCell value={prop.getValue()} />,
      meta: {
        sortType: "alpha",
        optional: true,
      },
      enableColumnFilter: false,
    },
    {
      header: "Deal Date",
      id: "deal.dealDate",
      enableColumnFilter: false,
      accessorKey: "dealDate",
      cell: (prop) => <DateCell value={prop.getValue()} />,
      meta: {
        sortType: "date",
        optional: true,
      },
    },
    {
      header: "Post-Money Valuation",
      id: "deal.postMoneyValuation",
      enableColumnFilter: false,
      accessorKey: "postValuation",
      cell: (prop) => <MoneyCell value={prop.getValue()} />,
      meta: {
        sortType: "numeric",
        optional: true,
      },
    },
    {
      header: "Deal Size",
      id: "deal.roundSize",
      enableColumnFilter: false,
      accessorKey: "roundSize",
      cell: (prop) => <MoneyCell value={prop.getValue()} />,
      meta: {
        sortType: "numeric",
        optional: true,
      },
    },
    {
      header: "Is Lead",
      id: "deal.isLead",
      enableColumnFilter: false,
      accessorKey: "isLead",
      cell: (prop) => <BooleanCell value={prop.getValue()} />,
      meta: {
        sortType: "boolean",
      },
    },
    {
      header: "Other Notable Investors",
      id: "organization.notableInvestors",
      accessorKey: "notableInvestors",
      enableSorting: false,
      cell: (info) => <InvestorsCell info={info} nameToOmit={company.name} />,
      filter: (column) => <InvestorsFilter column={column} valorId={company.valorId} />,
      meta: {
        optional: true,
      },
    },
    {
      header: "Actual MOIC (as of today)",
      id: "deal.actualMoic",
      accessorKey: "actualMoic",
      enableColumnFilter: false,
      cell: (info) => <MoicCell value={info.getValue()} />,
      meta: {
        sortType: "numeric",
        optional: true,
      },
    },
    {
      header: "Predicted MOIC",
      id: "organization.predictedMoic",
      accessorKey: "predictedMoic",
      enableColumnFilter: false,
      cell: (info) => (
        <PredictedMoicCell
          value={info.getValue()}
          country={info.row.original.country}
          valorId={info.row.original.valorId}
        />
      ),
      filterFn: () => true, // defer to serverside filtering
      meta: {
        sortType: "numeric",
        optional: true,
      },
    },
    {
      header: "Liquidity Events",
      id: "organization.liquidityEvents",
      accessorKey: "liquidityEvents",
      enableColumnFilter: false,
      enableSorting: false,
      cell: function LiquidityEventsCell(info) {
        const value = info.getValue();
        if (!value) return "-";
        return (
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            flexWrap="wrap"
            gap={1}
          >
            {value?.map((event) => (
              <Chip
                key={event.dealDate}
                label={`${event.dealType} - ${dayjs(event.dealDate).format("MM/DD/YYYY")}`}
                size="small"
              />
            ))}
          </Box>
        );
      },
      meta: {
        optional: true,
      },
    },
  ].map((x) => ({
    ...x,
    minSize: 100,
    filterFn: () => true,
  })), [company.name, company.valorId]);

  const pages = useMemo(() => (
    data?.pages
      .map((x) => x.data)
      .flat() || []
  ), [data]);

  const table = useReactTable({
    data: pages,
    columns: pagedColumns,
    columnResizeMode,
    manualSorting: true, // default to server side sorting
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  const isLoading = queryStatus === "loading";
  const isError = queryStatus === "error";

  if (isError) {
    return (
      <Card elevation={0}>
        <Box>
          <Typography variant="body1">
            {`Error loading ${company.name}'s portfolio.`}
          </Typography>
        </Box>
      </Card>
    );
  }

  return (
    <Card
      elevation={0}
      sx={{
        maxHeight: "calc(100vh - 200px)",
        overflow: "hidden",
      }}
    >
      {(isLoading || isFetchingNextPage) && (
        <CenteredProgress
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        />
      )}
      <Box
        px={1}
        py={2}
        maxWidth="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}
        >
          <Typography
            variant="h3"
          >
            Portfolio
          </Typography>
        </Box>
        <Box
          display="flex"
          gap={1}
          alignItems="center"
        >
          <DebouncedTextField
            label="Search"
            dense
            InputProps={{
              startAdornment: (<SearchIcon />),
            }}
            value={globalFilter}
            size="small"
            variant="standard"
            onChange={(value) => {
              setGlobalFilter(value.toString());
            }}
          />
          <Tooltip
            title="Export to Excel"
          >
            <IconButton
              onClick={downloadAsExcel}
            >
              <DownloadIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>

      <TableToolbar
        columnHeaders={pagedColumns}
        columnVisibility={columnVisibility}
        sorting={sorting}
        columnFilters={columnFilters}
        onRemoveColumnFilters={() => {
          setColumnFilters([]);
        }}
        onRemoveSorting={() => {
          setSorting([]);
        }}
        onRemoveVisibility={() => {
          setColumnVisibility({});
        }}
      />

      <VirtualTableContainer
        onScrollBottomReached={() => {
          if (!isFetching && hasNextPage) {
            fetchNextPage?.();
          }
        }}
      >
        <StickyTable
          centerTotalSize={table.getCenterTotalSize()}
        >
          <StickyTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <HeaderCell
                    key={header.id}
                    header={header}
                    column={header.column}
                    table={table}
                  />
                ))}
              </TableRow>
            ))}
          </StickyTableHead>
          <VirtualTableBody
            rows={table.getRowModel().rows}
            estimateSize={100}
            table={table}
          />
        </StickyTable>
      </VirtualTableContainer>
    </Card>
  );
}

export default PortfolioPage;
