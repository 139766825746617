import React, { useState } from "react";
import {
  ButtonBase, Tooltip, useTheme,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import FollowIcon from "@mui/icons-material/PlaylistAddCheck";
import FollowingIcon from "@mui/icons-material/PlaylistAdd";

interface Props {
  isFollowing: boolean;
  onClick?(...args: unknown[]): unknown;
  isXs?: boolean;
  showBorder?: boolean;
}

interface StyleProps extends Props {
  isHovering: boolean
}

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return createStyles({
    root: ({
      isFollowing, isHovering, isXs, showBorder,
    }: StyleProps) => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "45px",
      width: isXs ? theme.spacing(5) : theme.spacing(16),
      padding: isXs ? theme.spacing(0.5, 1) : theme.spacing(1),
      border: (() => {
        if (!showBorder) {
          return null;
        }
        if (isFollowing) {
          if (isHovering) {
            return `1px solid ${theme.palette.red.main} !important`;
          }
          return `1px solid ${theme.palette.divider} !important`;
        }
        if (isXs) {
          return `1px solid ${theme.palette.divider} !important`;
        }
        return null;
      })(),
      borderRadius: `${theme.shape.borderRadius}px !important`,
      color: (() => {
        if (isFollowing) {
          if (isHovering) {
            return `${theme.palette.red.main} !important`;
          }
          return `${theme.palette.text.primary} !important`;
        }
        if (isXs) {
          return "#ffffff !important";
        }
        return "#ffffff !important";
      })(),
      backgroundColor: (() => {
        if (isFollowing) {
          if (isHovering) {
            return `${theme.palette.red.highlight} !important`;
          }
          if (isXs) {
            if (isHovering) {
              return `${theme.palette.blue.highlight} !important`;
            }
            return null;
          }
        } else {
          if (isXs) {
            if (isHovering) {
              return `${theme.palette.blue.highlight} !important`;
            }
            return null;
          }
          return `${theme.palette.blue.main} !important`;
        }
        return null;
      })(),
    }),
    icon: ({ isXs }: StyleProps) => ({
      marginRight: !isXs && theme.spacing(0.5),
    }),
  });
});

const tooltipCopy = {
  true: "Unfollow this company and no longer receive updates",
  false: "Add this company to your follow list",
};

export default function FollowButton(props: Props) {
  const {
    isFollowing, isXs = false, onClick, showBorder = true,
  } = props;
  const [isHovering, setIsHovering] = useState(false);
  const classes = useStyles({
    isFollowing, isXs, isHovering, showBorder,
  });

  const toggleHover = (state) => {
    setIsHovering(state);
  };

  return (
    <Tooltip
      title={tooltipCopy[String(isFollowing)]}
      placement="top"
      disableFocusListener={!isXs}
      disableHoverListener={!isXs}
      disableTouchListener={!isXs}
    >
      <ButtonBase
        className={classes.root}
        onClick={onClick}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}
      >
        {isFollowing ? (
          <FollowIcon className={classes.icon} />
        ) : (
          <FollowingIcon className={classes.icon} />
        )}
        {!isXs
          && (isFollowing ? (isHovering ? "UNFOLLOW" : "FOLLOWING") : "FOLLOW")}
      </ButtonBase>
    </Tooltip>
  );
}
