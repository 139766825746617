import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Select,
  MenuItem,
  useTheme,
} from "@mui/material";
import Close from "@mui/icons-material/Close";
import Segments from "@/constants/Segments";

export function MapHeader(props) {
  const {
    mapTitle,
    onMapTitleChange,
    currentlyMappedValorId,
    setCurrentlyMappedValorId,
    onMapClassificationChange,
    segment,
    toggleDraft,
    isDraft,
    viewMap,
    onDelete,
  } = props;
  const [title, setTitle] = useState(mapTitle);
  const [deleteDialog, setDeleteDialog] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    setTitle(mapTitle);
  }, [mapTitle]);

  return (
    <Box
      sx={{
        width: "100%",
        padding: 1,
        display: "flex",
        flex: 1,
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.palette.blue.dark,
        height: theme.spacing(8),
      }}
    >
      <Box display="flex" height="100%" flex={1} gap={1}>
        <input
          value={title}
          onBlur={(event) => onMapTitleChange(event.target.value)}
          onChange={(event) => setTitle(event.target.value)}
          style={{
            flex: 1,
            backgroundColor: "transparent",
            color: "#ffffff",
            fontSize: theme.typography.h5.fontSize,
            maxWidth: "520px",
            border: "none",
            borderBottom: "1px solid #ffffff",
            outline: "none",
          }}
        />
        <Select
          value={segment}
          sx={{
            width: "240px",
            "&::after": {
              borderBottomColor: "#ffffff",
            },
            "&::before": {
              borderBottomColor: "#ffffff",
            },
            "& > div:hover:not(.Mui-disabled):before": {
              borderBottomColor: "#ffffff",
            },
            "& > label": {
              color: "#ffffff",
            },
            "& > div": {
              color: "#ffffff",
              borderBottomColor: "#ffffff",
            },
            "& > div > svg": {
              color: "#ffffff",
            },
            "& > svg": {
              color: "#ffffff",
            },
          }}
        >
          {Object.keys(Segments).map((seg) => (
            <MenuItem
              key={seg}
              value={seg}
              onClick={() => onMapClassificationChange(seg)}
            >
              {Segments[seg]}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {currentlyMappedValorId
      && (
      <Button
        style={{ marginRight: "8px" }}
        color="white"
        variant="contained"
        onClick={() => setCurrentlyMappedValorId(null)}
      >
        Done
      </Button>
      )}
      <FormControlLabel
        style={{ color: "white" }}
        control={(
          <Switch
            checked={!isDraft}
            onChange={toggleDraft}
            name="publish"
            color="secondary"
          />
        )}
        label={isDraft ? "Publish Map" : "Unpublish Map"}
      />
      <Button color="secondary" variant="contained" onClick={() => viewMap()}>
        View Map
      </Button>
      <IconButton
        onClick={() => setDeleteDialog(true)}
        style={{ color: "red" }}
        variant="contained"
      >
        <Close />
      </IconButton>
      <Dialog
        open={deleteDialog}
        onClose={() => setDeleteDialog(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Delete this Map
          {" "}
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This map
            {" "}
            <b>cannot</b>
            {" "}
            be recovered once deleted. Are you sure you
            want to proceed? If you want to hide the map you can simply
            unpublish it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setDeleteDialog(false);
            }}
            color="primary"
          >
            Don&apos;t Delete
          </Button>
          <Button
            onClick={() => {
              setDeleteDialog(false);
              onDelete();
            }}
            color="inherit"
            style={{ color: "red" }}
            autoFocus
          >
            Yes Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default MapHeader;
