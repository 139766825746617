import React from "react";
import { useNavigate } from "react-router-dom";
import { Modal, Box, Typography } from "@mui/material";
import SearchBar from "@/ui/molecules/SearchBar";
import { saytForOrg } from "@/api/Search";

function TakeNoteModal({ open, onClose }) {
  const navigate = useNavigate();

  const handleSelectCompany = (selectedCompany) => {
    if (selectedCompany && selectedCompany.valorId) {
      navigate(`/org/${selectedCompany.valorId}/notes/new`);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100vw", sm: 600 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6">
          Search for a company to compose a note
        </Typography>
        <SearchBar
          autoFocus
          showSAYT
          selectTerm={handleSelectCompany}
          entity="company"
          search={saytForOrg}
          placeholder="Search..."
        />
      </Box>
    </Modal>
  );
}

export default TakeNoteModal;
