import React from "react";
import {
  Box, Select, MenuItem, FormControl, InputLabel,
} from "@mui/material";

export default function SavedViews({
  views,
  value,
  onChange,
}: {
  views: { id: string; name: string }[];
  value: string;
  onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}) {
  return (
    <Box
      py={1}
      sx={{
        width: "100%",
      }}
    >
      <FormControl
        sx={{
          width: {
            xs: "100%",
            sm: "320px",
          },
        }}
        size="small"
      >
        <InputLabel id="views-select-label" shrink>Views</InputLabel>
        <Select
          value={value}
          onChange={onChange}
          displayEmpty
          size="small"
          id="views-select"
          labelId="views-select-label"
          label="Views"
          variant="outlined"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {views?.filter((view) => view?.public).map((view) => (
            <MenuItem key={view.id} value={view.id}>
              {view.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
