function formatNumber(num, decimalPlaces) {
  const roundedNum = Math.round(num * 10 ** decimalPlaces) / 10 ** decimalPlaces;
  return roundedNum.toLocaleString("en-US", {
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  });
}

export function formatShrunkTotal(num) {
  const absNum = Math.abs(num);
  if (absNum >= 1e15) {
    const val = formatNumber(num / 1e15, 1); // Q`;
    if (val === "1,000.0") { return "1.0Qt"; }
    return `${val}`;
  } if (absNum >= 1e12) {
    const val = formatNumber(num / 1e12, 1); // T`;
    if (val === "1,000.0") { return "1.0Q"; }
    return `${val}T`;
  } if (absNum >= 1e9) {
    const val = formatNumber(num / 1e9, 1); // B`;
    if (val === "1,000.0") { return "1.0T"; }
    return `${val}B`;
  } if (absNum >= 1e6) {
    const val = formatNumber(num / 1e6, 1); // M`;
    if (val === "1,000.0") { return "1.0B"; }
    return `${val}M`;
  } if (absNum >= 1e3) {
    const val = formatNumber(num / 1e3, 1); // K`;
    if (val === "1,000.0") { return "1.0M"; }
    return `${val}K`;
  }
  return formatNumber(num, 0);
}

export function formatShrunkTotalFundingRounds(num) {
  const absNum = Math.abs(num);
  if (absNum >= 1e15) {
    const val = formatNumber(num / 1e15, 1); // Q`;
    if (val === "1,000.0") { return "1.0Qt"; }
    return `${val}`;
  } if (absNum >= 1e12) {
    const val = formatNumber(num / 1e12, 1); // T`;
    if (val === "1,000.0") { return "1.0Q"; }
    return `${val}T`;
  } if (absNum >= 1e9) {
    const val = formatNumber(num / 1e9, 1); // B`;
    if (val === "1,000.0") { return "1.0T"; }
    return `${val}B`;
  }

  const val = formatNumber(num / 1e6, 1); // M`;
  if (val === "1,000.0") { return "1.0B"; }
  return `${val}M`;
}

export const formatTotal = (value, maximumFractionDigits = 0) => value?.toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits,
});

export const formatFloat = (value, maximumFractionDigits = 2) => (
  Number.parseFloat(value).toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }));

export const adjustNumberToMinThreeDigits = (n) => {
  if (Math.abs(n) > 0 && Math.abs(n) < 10) return n.toFixed(2);
  if (Math.abs(n) >= 10 && Math.abs(n) <= 99) {
    const fixed = n.toFixed(1);
    return fixed.endsWith(".0") ? parseInt(fixed, 10) : parseFloat(fixed);
  }
  return Math.trunc(n);
};
export const formatMultiplierFromPercentage = (percent) => {
  if (!percent) {
    return null;
  }
  const isPositive = percent > 0;
  const shouldRatio = percent > 100;

  if (shouldRatio) {
    const ratio = Math.round(percent / 100) + 1;
    if (ratio < 10) {
      return isPositive
        ? `up ${(percent / 100 + 1).toFixed(1)}x`
        : `down ${(percent / 100 + 1).toFixed(1)}x`;
    }
    return isPositive ? `up ${ratio.toFixed(0)}x` : `down ${ratio.toFixed(0)}x`;
  }
  return isPositive ? `+${percent.toFixed(0)}%` : `${percent.toFixed(0)}%`;
};

export const formatPercentage = (value, maximumFractionDigits = 2) => `${(value * 100).toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits,
})}%`;

const DollarFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatDollars = (dollars, showCents = true) => {
  if (!dollars) {
    return null;
  }
  const format = DollarFormatter.format(dollars);
  if (showCents) {
    return format;
  }
  return format.slice(0, format.length - 3);
};

export const formatBigDollars = (dollars) => {
  if (dollars == null || Number.isNaN(dollars)) {
    return "";
  }
  if (Math.abs(dollars) > 999) {
    return `$${formatShrunkTotal(dollars)}`;
  }
  return DollarFormatter.format(dollars);
};

export const formatBigFundingRoundDollars = (dollars) => {
  if (dollars == null || Number.isNaN(dollars)) {
    return "";
  }
  if (Math.abs(dollars) > 999) {
    return `$${formatShrunkTotalFundingRounds(dollars)}`;
  }
  return DollarFormatter.format(dollars);
};

export const formatCompact = (dollars, fractionDigits = 1) => {
  if (dollars == null || Number.isNaN(dollars)) {
    return "";
  }
  return new Intl.NumberFormat("en-US", {
    notation: "compact",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  }).format(dollars);
};

export default {
  formatTotal,
  formatFloat,
  formatShrunkTotal,
  formatPercentage,
  formatBigDollars,
  formatDollars,
  formatMultiplierFromPercentage,
  formatCompact,
  formatBigFundingRoundDollars,
};
