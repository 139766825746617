import React, { useState } from "react";
import {
  Box, Typography, Popover,
} from "@mui/material";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";

interface Props {
  logoUrl?: string;
  id: string;
  onClick: (id: string)=> void;
  name: string;
  domain?: string;
  deEmphasize?: boolean;
  onMouseEnter?:(id: string)=> void;
  onMouseLeave?:(id: string)=> void;
}

export function MarketMapItem(props: Props) {
  const {
    logoUrl,
    name,
    domain,
    id,
    onClick,
    deEmphasize = false,
    onMouseEnter = () => {},
    onMouseLeave = () => {},
  } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  const handleKeyDown = (event) => {
    if (isHovered && event.shiftKey) {
      onMouseEnter(id);
    }
  };
  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setIsHovered(true);
  };

  const handlePopoverClose = () => {
    onMouseLeave(id);
    setIsHovered(false);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        justifySelf: "center",
        minWidth: "100%",
        overflow: "hidden",
        cursor: "pointer",
        maxWidth: "72px",
        maxHeight: "72px",
      }}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      onKeyDown={handleKeyDown}
      onClick={() => id && onClick(id)}
    >
      <Box
        sx={{
          boxSizing: "border-box",
          margin: "8px",
          opacity: deEmphasize ? 0.7 : 1,
          filter: `blur(${deEmphasize ? 2 : 0}px)`,
          height: "100%",
          maxHeight: "100%",
          minWidth: "100%",
        }}
      >
        <CompanyAvatar src={logoUrl} name={name} domain={domain} loading="eager" />
      </Box>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
          "& .MuiPopover-paper": {
            padding: 0.5,
          },
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography>{name}</Typography>
      </Popover>
    </Box>
  );
}

MarketMapItem.defaultProps = {
  logoUrl: "",
  deEmphasize: false,
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default MarketMapItem;
