/* eslint-disable no-loop-func */
const monthAbbrevs = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const getPeriodColumns = (yearStart = "2020", isFiscal = false, periodType = "MONTHLY") => {
  const columns = [];
  let year = yearStart;
  let currentYear = new Date().getFullYear();

  if (periodType === "MONTHLY") {
    const currentMonth = new Date().getMonth();

    while (year <= currentYear) {
      monthAbbrevs.forEach((month, i) => {
        if (year === currentYear && i > currentMonth) return;
        columns.push(`${month} ${year}`);
      });
      year += 1;
    }
  } else if (periodType === "QUARTERLY") {
    if (isFiscal) {
      currentYear += 1;
    }
    const quarters = ["Q1", "Q2", "Q3", "Q4"];
    while (year <= currentYear) {
      quarters.forEach((quarter) => {
        columns.push(`${quarter} ${isFiscal ? "FY" : ""}${year}`);
      });
      year += 1;
    }
  } else if (periodType === "YEARLY") {
    if (isFiscal) {
      currentYear += 1;
    }
    while (year <= currentYear) {
      columns.push(`${isFiscal ? "FY" : ""}${year}`);
      year += 1;
    }
  }
  return columns;
};
export default getPeriodColumns;
