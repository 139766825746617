export const getSurveyLink = ({
  surveyName = null, surveyId, id, valorId,
}) => {
  if (surveyName === "Opportunity Assessment") {
    return `/opportunity-assessment-reports/${valorId}?responseId=${id}&surveyId=${surveyId}&source=orgPage`;
  }
  return `/org/${valorId}/response/${id}?surveyId=${surveyId}`;
};

export default { getSurveyLink };
