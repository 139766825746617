import React from "react";
import { Box, ButtonBase, Typography } from "@mui/material";
import Signal from "@/ui/atoms/Icons/Signal";

interface Props {
  value: number[];
  disabled: boolean;
  onChange: (value: number[]) => void
}

export default function SignalFilter(props: Props) {
  const { onChange, disabled = false, value = [] } = props;
  const choices = [1, 2, 3];
  const handleClick = (bars) => {
    let newValue = [];
    if (value.indexOf(bars) === -1) {
      newValue = [...value, bars];
    } else {
      newValue = value.filter((x) => x !== bars);
    }
    onChange(newValue);
  };
  return (
    <Box>
      <Typography
        sx={{
          paddingBottom: (theme) => theme.spacing(0.5),
          color: (theme) => theme.palette.text.secondary,
          fontWeight: (theme) => theme.typography.fontWeightBold,
          fontSize: "12px",
        }}
      >
        SIGNAL SCORE
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          overflow: "hidden",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          borderRadius: (theme) => theme.shape.borderRadius,
          "& > button:not(:last-child)": {
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
          },
        }}
      >
        {choices.map((x) => (
          <ButtonBase
            key={x}
            className={classes.button}
            disabled={disabled}
            style={{
              backgroundColor:
                (theme) => value.indexOf(x) !== -1 && theme.palette.green.highlight,
              width: "33%",
              padding: (theme) => theme.spacing(1, 2),
            }}
            onClick={() => handleClick(x)}
          >
            <Signal bars={x} />
          </ButtonBase>
        ))}
      </Box>
    </Box>
  );
}
