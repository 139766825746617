import React from "react";
import {
  Route, Routes, useLocation,
} from "react-router-dom";
import { Link } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import { useAuth } from "@/hooks/useAuth";
import PathBreadCrumbs from "@/ui/atoms/BreadCrumbs";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import { canSeeCompliance } from "@/constants/Roles";
import Page from "@/ui/molecules/Page";
import Individual from "./User";
import Role from "./Role";
import Compliance from "./Compliance";

function ComplianceRoot() {
  const { user } = useAuth();
  const location = useLocation();

  if (!canSeeCompliance(user)) {
    return (
      <ErrorMessage
        Icon={<LockIcon />}
        title="Unauthorized"
        message={(
          <>
            You don’t have access to view Compliance page. If you think this is
            an error contact
            {" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={(e) => {
                window.location.href = "mailto:labs@valorep.com?subject=Compliance Page Permissions";
                e.preventDefault();
              }}

            >
              labs@valorep.com
            </Link>
          </>
        )}
      />
    );
  }

  return (
    <Page>
      <Page.Title>
        Compliance
      </Page.Title>
      <Page.Content>
        <PathBreadCrumbs path={location.pathname} />
        <Routes>
          <Route
            path="*"
            element={<Compliance />}
          />
          <Route
            path="role"
            element={<Role />}
          />
          <Route
            path="user"
            element={<Individual />}
          />
          <Route
            path="individuals"
            element={<Compliance />}
          />
        </Routes>
      </Page.Content>
    </Page>
  );
}

export default ComplianceRoot;
