import React, { useMemo } from "react";

import MarkdownIt from "markdown-it";
import MarkdownItKatex from "markdown-it-katex";
import {
  Box,
  Typography,
  useTheme,
  Table,
  TableRow,
  List,
  ListItem,
} from "@mui/material";

import {
  MTableHead,
  MTableCell,
  MHeaderCell,
  MTableBody,
} from "@/components/MiniTable";
import hljs from "highlight.js";
import "highlight.js/styles/atom-one-dark.css";
import "katex/dist/katex.min.css";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import ChatBubble from "./ChatBubble";

export default function GrokChatBubble({
  content,
  stream,
}: {
  content?: string;
  stream?: { content: string }[];
}) {
  const theme = useTheme();
  const isInTouchPWA = useIsInTouchPWA();
  const color = theme.palette.background.blue;
  const fontColor = theme.palette.getContrastText(color);

  const processStream = (s) => {
    if (!s) return "";
    return s.map((x) => x.content).join("");
  };

  const convertNode = (node: Node, key?: number): React.ReactNode => {
    // Handle text nodes
    if (node.nodeType === Node.TEXT_NODE) {
      // Return null for pure whitespace text nodes
      if (node.textContent?.trim() === "") {
        return null;
      }
      return node.textContent;
    }

    // Handle element nodes
    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const children = Array.from(element.childNodes).map((child, index) =>
        convertNode(child, index)
      );
      const tagName = element.tagName.toLowerCase();
      switch (tagName) {
        case "p":
          return (
            <Typography key={key} variant="body1" paragraph>
              {children}
            </Typography>
          );

        case "h1":
          return (
            <Typography key={key} variant="h1" sx={{ my: 2 }}>
              {children}
            </Typography>
          );

        case "h2":
          return (
            <Typography key={key} variant="h2" sx={{ my: 2 }}>
              {children}
            </Typography>
          );

        case "h3":
          return (
            <Typography key={key} variant="h3" sx={{ my: 2 }}>
              {children}
            </Typography>
          );

        case "h4":
          return (
            <Typography key={key} variant="h4" sx={{ my: 2 }}>
              {children}
            </Typography>
          );

        case "table":
          return (
            <Table
              key={key}
              sx={{
                my: 2,
                border: "1px solid",
                borderColor: theme.palette.divider,
                "& td, & th": {
                  borderRight: "1px solid",
                  borderColor: theme.palette.divider,
                  "&:last-child": {
                    borderRight: "none",
                  },
                },
              }}
            >
              {children}
            </Table>
          );

        case "thead":
          return <MTableHead key={key}>{children}</MTableHead>;

        case "tbody":
          return <MTableBody key={key}>{children}</MTableBody>;

        case "tr":
          return <TableRow key={key}>{children}</TableRow>;

        case "td":
          return <MTableCell key={key}>{children}</MTableCell>;

        case "th":
          return <MHeaderCell key={key}>{children}</MHeaderCell>;
        case "ol":
          return (
            <List
              key={key}
              sx={{
                pl: 2,
                listStyle: "decimal", // Shows numbers
                "& li": {
                  display: "list-item",
                },
              }}
            >
              {children}
            </List>
          );
        case "ul":
          return (
            <List key={key} sx={{ pl: 2 }}>
              {children}
            </List>
          );
        case "span":
          if (element.classList.contains("katex")) {
            return (
              <Box
                key={key}
                component="span"
                dangerouslySetInnerHTML={{ __html: element.outerHTML }}
                sx={{
                  "& .katex-display": {
                    margin: "1em 0",
                  },
                  "& .katex": {
                    fontSize: "1.1em",
                  },
                }}
              />
            );
          }
          return children;
        case "li":
          return (
            <ListItem
              key={key}
              sx={{
                display: "list-item",
                pl: 0,
                py: 0.5,
                "& > *": {
                  display: "block",
                },
                "& p": {
                  margin: 0,
                  marginBottom: 1,
                },
                // Add specific styling for code blocks within list items
                "& pre": {
                  display: "in-line",
                  my: 1,
                  mx: 0,
                },
              }}
            >
              {children}
            </ListItem>
          );

        case "br":
          return <Box key={key} component="br" />;

        case "hr":
          return (
            <Box
              key={key}
              component="hr"
              sx={{
                my: 2, // margin top and bottom
                border: "none",
                height: "1px",
                backgroundColor: theme.palette.divider,
                width: "100%",
              }}
            />
          );

        case "strong":
          return (
            <Typography
              key={key}
              component="span"
              sx={{
                fontWeight: "bold",
                display: "inline", // Add this to keep text inline
              }}
            >
              {children}
            </Typography>
          );
        case "s":
          return (
            <Typography
              key={key}
              component="span"
              sx={{
                textDecoration: "line-through",
                display: "inline",
              }}
            >
              {children}
            </Typography>
          );
        case "em":
          return (
            <Typography key={key} component="span" sx={{ fontStyle: "italic" }}>
              {children}
            </Typography>
          );
        case "pre":
          return (
            <Box
              key={key}
              component="pre"
              sx={{
                backgroundColor: theme.palette.grey[900],
                color: theme.palette.grey[100],
                borderRadius: 1,
                p: 2,
                overflow: "auto",
                "& code": {
                  fontFamily:
                    'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
                  fontSize: "0.875rem",
                },
                "& .hljs": {
                  background: "transparent",
                  padding: 0,
                },
              }}
            >
              {children}
            </Box>
          );

        case "code": {
          const isInPre =
            element.parentElement?.tagName.toLowerCase() === "pre";
          return (
            <Typography
              key={key}
              component="code"
              sx={{
                fontFamily:
                  'Consolas, Monaco, "Andale Mono", "Ubuntu Mono", monospace',
                ...(isInPre
                  ? {
                      display: "block",
                      backgroundColor: "transparent",
                    }
                  : {
                      backgroundColor: theme.palette.grey[200],
                      p: 0.5,
                      borderRadius: 0.5,
                      fontSize: "0.875em",
                    }),
              }}
              {...(isInPre
                ? { dangerouslySetInnerHTML: { __html: element.innerHTML } }
                : { children })}
            />
          );
        }
        case "sub":
          return (
            <Typography
              key={key}
              component="sub"
              sx={{
                fontSize: "75%",
                lineHeight: 0,
                position: "relative",
                verticalAlign: "baseline",
                bottom: "-0.25em",
              }}
            >
              {children}
            </Typography>
          );

        case "sup":
          return (
            <Typography
              key={key}
              component="sup"
              sx={{
                fontSize: "75%",
                lineHeight: 0,
                position: "relative",
                verticalAlign: "baseline",
                top: "-0.5em",
              }}
            >
              {children}
            </Typography>
          );
        // LaTex Crap
        case "annotation":
        case "math":
        case "mfrac":
        case "mi":
        case "mn":
        case "mo":
        case "mrow":
        case "mspace":
        case "msqrt":
        case "msub":
        case "msubsup":
        case "msup":
        case "mtext":
        case "semantic":
        case "semantics":
          return children;
        default:
          console.log(tagName);
          return children;
      }
    }

    return null;
  };

  const renderContent = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, "text/html");
    return Array.from(doc.body.childNodes).map((node, index) =>
      convertNode(node, index)
    );
  };

  const html = useMemo(() => {
    const md = new MarkdownIt({
      highlight(str, lang) {
        if (lang && hljs.getLanguage(lang)) {
          try {
            return hljs.highlight(str, {
              language: lang,
              ignoreIllegals: true,
            }).value;
          } catch (error) {
            console.error("Failed to highlight:", error);
          }
        }
        return hljs.highlight(str, {
          language: "plaintext",
          ignoreIllegals: true,
        }).value;
      },
    }).use(MarkdownItKatex, {
      throwOnError: false,
      errorColor: "#cc0000",
      displayMode: true, // Enable display mode by default
    });

    let processedContent = content || processStream(stream);

    processedContent = processedContent?.replace(/\\\\/g, "\\");

    // Clean up double backslashes
    processedContent = processedContent?.replace(/\\\\/g, "\\");

    // First, handle display math
    processedContent = processedContent?.replace(
      /\\\[([\s\S]*?)\\\]/g,
      (match, formula) => {
        return `\n$$${formula.trim()}$$\n`;
      }
    );

    // Then handle inline math
    processedContent = processedContent?.replace(
      /\\\(([\s\S]*?)\\\)/g,
      (match, formula) => {
        return `$${formula.trim()}$`;
      }
    );

    // Handle bullet points with proper spacing
    processedContent = processedContent?.replace(
      /- \*\*(.*?)\*\*:/g,
      "\n- **$1**:"
    );

    // preformatted markdown to not be preformatted
    processedContent = processedContent?.replace(
      /```markdown\n([\s\S]*?)```/g,
      (_, markdownContent) => markdownContent
    );
    // Process first three backticks markdown to not be preformatted
    processedContent = processedContent?.replace(/```markdown/, "");

    return md.render(processedContent || "");
  }, [content, stream]);

  return (
    <ChatBubble
      sx={{
        color: fontColor,
        backgroundColor: isInTouchPWA ? "transparent" : color,
        borderColor: theme.palette.divider,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {renderContent(html)}
      </Box>
    </ChatBubble>
  );
}
