import React, { useCallback, useMemo } from "react";
import { getVAAIReport } from "@/api/Reporting";
import { useQuery } from "react-query";
import TableView from "@/pages/ProcessManagement/TableView";
import Page from "@/ui/molecules/Page";
import Progress from "@/ui/atoms/Progress";
import { useParams } from "react-router-dom";

export function VAAIReport() {
  const { subPage } = useParams();
  const {
    data = {
      process: {},
      tasks: [],
      view: {},
    },
    isLoading,
  } = useQuery([subPage, "VAAIReport"], () => getVAAIReport(subPage));
  const { process, tasks, view } = data;

  const fetchNextPage = useCallback(() => {}, []);
  const onColumnOrderChange = useCallback(() => {}, []);
  const onSortingChange = useCallback(() => {}, []);
  const onColumnFilterChange = useCallback(() => {}, []);
  const onColumnVisibilityChange = useCallback(() => {}, []);

  const columnFilters = useMemo(() => [], []);
  const sorting = useMemo(() => view.sorting || [], [view.sorting]);
  const columnOrder = useMemo(() => view.columnOrder || [], [view.columnOrder]);
  const columnVisibility = useMemo(
    () => view.columnVisibility || {},
    [view.columnVisibility],
  );
  const reportTitle = {
    deals: "Deal Flow",
    portfolio: "Portfolio",
  };

  if (isLoading) return <Progress />;
  if (!subPage || !reportTitle[subPage]) return "Invalid report";
  if (!data) return "No data";

  return (
    <Page>
      <Page.Title>
        {`VAAI ${reportTitle[subPage]} Report`}
      </Page.Title>
      <Page.Content>
        <TableView
          isLoading={isLoading}
          fields={process.fields}
          processId={process.processId}
          tasks={tasks}
          views={process.views}
          isFetching={isLoading}
          fetchNextPage={fetchNextPage}
          sorting={sorting}
          columnFilters={columnFilters}
          columnOrder={columnOrder}
          columnVisibility={columnVisibility}
          onColumnOrderChange={onColumnOrderChange}
          onSortingChange={onSortingChange}
          onColumnFilterChange={onColumnFilterChange}
          onColumnVisibilityChange={onColumnVisibilityChange}
          enableColumnFilter={false}
          enableSorting={false}
          enableViews={false}
          readOnly
        />
      </Page.Content>
    </Page>
  );
}
export default VAAIReport;
