import React from "react";
import { Helmet } from "react-helmet";
import {
  getPermissions,
  grantPermissions as postGrant,
  denyPermissions as postDeny,
  revokePermissions as postRevoke,
} from "@/api/Compliance";
import {
  getProcesses,
} from "@/api/Process";
import { useQuery, useQueryClient } from "react-query";
import {
  Link, Box, Typography, Tooltip, Tabs, Tab, Button,
} from "@mui/material";
import UserCell from "@/components/Table/Primitives/UserCell";

import {
  PrivacyTip,
} from "@mui/icons-material";
import { useQueryParam, withDefault, StringParam } from "use-query-params";
import GrantDenyTable from "../GrantDenyTable";

function generatePermissionsMap(permissionsList) {
  return permissionsList.reduce((acc, permission) => {
    if (!permission.processId) {
      return acc;
    }
    return ({
      ...acc,
      [permission.processId]: [
        ...(acc[permission.processId] || []),
        permission,
      ],
    });
  }, {});
}

function includeRolesPermissions(role, userPerms) {
  if (!role) {
    return userPerms;
  }

  return [
    ...(role.permissions?.map((rolePerm) => ({
      ...rolePerm,
      granted: rolePerm.granted,
      fromRole: true,
      roleName: role.name,
    })) || []),
    ...userPerms,
  ];
}

const PROCESS_PERMISSION_IDS = [
  68, 69, 71, 70,
];
export default function Processes() {
  const [page, setPage] = useQueryParam("page", withDefault(StringParam, "roles"));
  // get permissions
  const { data: permissionsData, isLoading } = useQuery("permissions", getPermissions, {
    refetchOnWindowFocus: false,
    refetchInterval: 0,
  });
  const { data: processes } = useQuery("processes", () => getProcesses(100, null, null, { processType: "ALL" }));

  const queryClient = useQueryClient();

  const relevantPermissions = React.useMemo(() => {
    if (!permissionsData) {
      return null;
    }

    const processPermissions = PROCESS_PERMISSION_IDS;

    const { base, roles, users } = permissionsData.data;

    return {
      data: {
        base: base.filter((permission) => (
          processPermissions.includes(permission.id)
        )),
        roles: roles.map((role) => ({
          ...role,
          permissions: role.permissions.filter((permission) => (
            processPermissions.includes(permission.id)
          )),
        })),
        users: users.map((user) => ({
          ...user,
          permissions: user.permissions.filter((permission) => (
            processPermissions.includes(permission.id)
          )),
        })),
      },
    };
  }, [permissionsData?.data?.base, permissionsData?.data?.roles, permissionsData?.data?.users]);

  const columns = React.useMemo(() => {
    if (!relevantPermissions) {
      return null;
    }

    const addPermissionToEntity = (
      type,
      entityId,
      processId,
      permissions,
      granted,
    ) => (oldData) => {
      const newPermissions = permissions.map((permission) => ({
        id: permission,
        granted,
        processId,
      }));
      return {
        ...oldData,
        data: {
          ...oldData.data,
          roles: (type === "role"
            ? oldData.data.roles.map((role) => {
              if (role.id !== entityId) return role;
              return ({
                ...role,
                permissions: [
                  ...role.permissions.filter((p) => p.processId !== processId),
                  ...newPermissions,
                ],
              });
            })
            : oldData.data.roles
          ),
          users: (type === "user"
            ? oldData.data.users.map((user) => {
              if (user.id !== entityId) return user;
              return ({
                ...user,
                permissions: [
                  ...user.permissions.filter((p) => p.processId !== processId),
                  ...newPermissions,
                ],
              });
            })
            : oldData.data.users),
        },
      };
    };

    const removePermissionFromEntity = (type, entityId, processId) => (
      (oldData) => ({
        ...oldData,
        data: {
          ...oldData.data,
          roles: (type === "role"
            ? oldData.data.roles.map((role) => {
              if (role.id !== entityId) return role;
              return ({
                ...role,
                permissions: role.permissions.filter((p) => p.processId !== processId),
              });
            })
            : oldData.data.roles
          ),
          users: (type === "user"
            ? oldData.data.users.map((user) => {
              if (user.id !== entityId) return user;
              return ({
                ...user,
                permissions: user.permissions.filter((p) => p.processId !== processId),
              });
            })
            : oldData.data.users),
        },
      }));

    const grantProcessAccess = async (type, entityId, processId) => {
      postGrant({
        userId: type === "user" ? entityId : null,
        roleId: type === "role" ? entityId : null,
        processId,
        permissionIds: PROCESS_PERMISSION_IDS,
      });

      // update permissions
      queryClient.setQueryData(
        "permissions",
        addPermissionToEntity(type, entityId, processId, PROCESS_PERMISSION_IDS, true),
      );
    };
    const denyProcessAccess = async (type, entityId, processId) => {
      postDeny({
        userId: type === "user" ? entityId : null,
        roleId: type === "role" ? entityId : null,
        processId,
        permissionIds: PROCESS_PERMISSION_IDS,
      });

      // update permissions
      queryClient.setQueryData(
        "permissions",
        addPermissionToEntity(type, entityId, processId, PROCESS_PERMISSION_IDS, false),
      );
    };
    const revokeProcessAccess = async (type, entityId, processId) => {
      postRevoke({
        userId: type === "user" ? entityId : null,
        roleId: type === "role" ? entityId : null,
        processId,
        permissionIds: PROCESS_PERMISSION_IDS,
      });

      // update permissions
      queryClient.setQueryData(
        "permissions",
        removePermissionFromEntity(type, entityId, processId),
      );
    };

    return [
      {
        header: "Role / User",
        id: "entity",
        accessorFn: (row) => row,
        cell: (prop) => {
          const value = prop.getValue();
          if (value.type === "role") {
            return (
              <Link href={`/compliance-and-permissions/roles?id=${value.id}`}>
                {value.name}
              </Link>
            );
          }
          return (
            <UserCell value={value} />
          );
        },
        minSize: 200,
        enableSorting: false,
        enableColumnFilter: false,
      },
      ...(
        processes
          ?.toSorted((a, b) => a.name.localeCompare(b.name))
          .reduce((acc, process) => {
            if (acc.some((col) => col.name === process.name)) {
              return acc;
            }
            return [
              ...acc,
              process,
            ];
          }, [])
          .map((process) => ({
            header: process.id.toUpperCase(),
            accessorFn: (row) => row.permissions[process.id],
            cell: function PermissionCell(prop) {
              const {
                id: entityId,
                type,
              } = prop.row.original;
              const permissions = prop.getValue();

              // if user, get user perms
              let granted = permissions?.some((p) => p.granted);
              let fromRole = permissions?.some((p) => (
                p.fromRole
                && p.processId === process.id
                && p.granted === granted
              ));
              const roleName = permissions?.find((p) => (
                p.fromRole && p.processId === process.id
              ))?.roleName;
              if (type === "user") {
                // check for user perms
                const userPerms = permissions?.filter((p) => (
                  p.processId === process.id && !p.fromRole
                ));
                if (userPerms?.length) {
                  granted = userPerms.some((p) => p.granted);
                  fromRole = false;
                }
              }
              const processId = process.id;

              return (
                <Box
                  position="relative"
                >
                  {fromRole && (
                  <Tooltip
                    title={`Inherited from ${roleName}`}
                  >
                    <PrivacyTip
                      fontSize="small"
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                      }}
                    />
                  </Tooltip>
                  )}
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <input
                      type="radio"
                      id={`action-${type}-${entityId}-${processId}-granted`}
                      name={`action-${type}-${entityId}-${processId}`}
                      value="granted"
                      checked={granted}
                      onChange={() => grantProcessAccess(type, entityId, processId)}
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      style={{
                        fontWeight: granted ? "bold" : "normal",
                        color: granted ? "green" : "black",
                        cursor: "pointer",
                      }}
                      htmlFor={`action-${type}-${entityId}-${processId}-granted`}
                    >
                      Granted
                    </label>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                    }}
                  >
                    <input
                      type="radio"
                      id={`action-${type}-${entityId}-${processId}-denied`}
                      name={`action-${type}-${entityId}-${processId}`}
                      value="denied"
                      checked={!granted}
                      onChange={() => denyProcessAccess(type, entityId, processId)}
                      style={{ cursor: "pointer" }}
                    />
                    <label
                      style={{
                        fontWeight: !granted ? "bold" : "normal",
                        color: !granted ? "red" : "black",
                        cursor: "pointer",
                      }}
                      htmlFor={`action-${type}-${entityId}-${processId}-denied`}
                    >
                      Denied
                    </label>
                  </div>

                  {type === "user" && (
                  <Button size="small" fullWidth onClick={() => revokeProcessAccess(type, entityId, processId)}>
                    Revoke
                  </Button>
                  )}
                </Box>
              );
            },
            minSize: 70,
            enableSorting: false,
            enableColumnFilter: false,
          })) || []
      ),
    ];
  }, [relevantPermissions?.data?.base, queryClient]);

  const roleRows = React.useMemo(() => {
    if (!relevantPermissions) {
      return null;
    }

    return relevantPermissions.data.roles.toSorted((a, b) => a.name.localeCompare(b.name))
      .map((role) => ({
        ...role,
        permissions: generatePermissionsMap(role.permissions) || {},
      }));
  }, [relevantPermissions?.data?.roles, generatePermissionsMap]);

  const userRows = React.useMemo(() => {
    if (!relevantPermissions) {
      return null;
    }

    const { roles, users } = relevantPermissions.data;

    return users.toSorted((a, b) => a.name.localeCompare(b.name))
      .map((user) => ({
        ...user,
        permissions: generatePermissionsMap(
          includeRolesPermissions(
            roles.find((role) => role.id === user.roleId),
            user.permissions,
          ),
        ) || {},
      }));
  }, [
    relevantPermissions?.data?.roles,
    relevantPermissions?.data?.users,
    generatePermissionsMap,
    includeRolesPermissions,
  ]);

  return (
    <div>
      <Helmet>
        <title>Compliance - Process Access</title>
      </Helmet>

      <Typography variant="h1">Process Access</Typography>

      <Tabs
        value={page}
        onChange={(e, newValue) => {
          setPage(newValue);
        }}
      >
        <Tab
          label="Roles"
          value="roles"
        />
        <Tab
          label="Users"
          value="users"
        />
      </Tabs>

      {!isLoading && page === "roles" && (
        <GrantDenyTable
          columns={columns}
          rows={roleRows}
        />
      )}

      {!isLoading && page === "users" && (
        <GrantDenyTable
          columns={columns}
          rows={userRows}
        />
      )}
    </div>
  );
}
