import axios from "axios";
import { searchParamsObject, buildURLParameters } from "@/utils/general";

import { operations, components, paths } from "./index";
import download from "./utils/Download";
import upload from "./utils/Upload";

export type PeriodType = "MONTHLY" | "QUARTERLY" | "YEARLY";
export type FileTaskStatus = "IN_PROGRESS" | "SUCCESS" | "READY" | "BROKEN_FILE" | "NO_DATA_OR_METRIC_NOT_FOUND";
export type HTTPValidationError = components["schemas"]["HTTPValidationError"];

type GetMetricLabelsResponses = paths["/api/metrics/labels"]["get"]["responses"];
export type MetricsLabel =
  GetMetricLabelsResponses["200"]["content"]["application/json"][0];
export const getMetricLabels = async (): Promise<MetricsLabel> => {
  const response = await axios.get("/api/metrics/labels");
  return response.data;
};

type GetMetricLabelClassificationssResponses =
  paths["/api/metrics/label-classifications"]["get"]["responses"];
export type MetricsClassifications =
  GetMetricLabelClassificationssResponses["200"]["content"]["application/json"][0];
export const getMetricLabelClassifications = async (): Promise<MetricsLabel> => {
  const response = await axios.get("/api/metrics/label-classifications");
  return response.data;
};

type GetOrganizationKeyMetrics = paths["/api/metrics/organization/{valor_id}/key-metrics"]["get"];
export type GetOrganizationKeyMetricsResponses = GetOrganizationKeyMetrics["responses"];
export type GetOrganizationKeyMetricsResult = GetOrganizationKeyMetricsResponses["200"]["content"]["application/json"];
export const getOrganizationKeyMetrics = async (
  organizationId: string,
): Promise<GetOrganizationKeyMetricsResult> => {
  const response = await axios.get<GetOrganizationKeyMetricsResult>(
    `/api/metrics/organization/${organizationId}/key-metrics`,
  );
  return response.data;
};

export const postOrganizationKeyMetric = async (
  valorId: string,
  labelId: string,
): Promise<void> => {
  const response = await axios.post(`/api/metrics/organization/${valorId}/key-metrics/${labelId}`);
  return response.data;
};

export const deleteOrganizationKeyMetric = async (
  valorId: string,
  labelId: string,
): Promise<void> => {
  const response = await axios.delete(
    `/api/metrics/organization/${valorId}/key-metrics/${labelId}`,
  );
  return response.data;
};

type postLabelBusinessSector =
  paths["/api/metrics/label-business-sector"]["post"];
export type postLabelBusinessSectorRequestBody = postLabelBusinessSector["requestBody"];
export type postLabelBusinessSectorResponses = postLabelBusinessSector["responses"];
export type postLabelBusinessSectorRequest = postLabelBusinessSectorRequestBody["content"]["application/json"];
export type postLabelBusinessSectorResult = postLabelBusinessSectorResponses["200"]["content"]["application/json"];
export const postLabelBusinessSector = async (
  businessSectorId: string,
  categoryName: string,
): Promise<postLabelBusinessSectorResult> => {
  const body: postLabelBusinessSectorRequest = {
    id: businessSectorId,
    name: categoryName,
  };
  const response = await axios.post("/api/metrics/label-business-sector", body);
  return response.data;
};

type PostCalculatedMetric = paths["/api/metrics/calculated-metric"]["post"];
export type PostCalculatedMetricRequestBody = PostCalculatedMetric["requestBody"];
export type PostCalculatedMetricResponses = PostCalculatedMetric["responses"];
export type PostCalculatedMetricRequest =
  PostCalculatedMetricRequestBody["content"]["application/json"];
export type PostCalculatedMetricResult =
  PostCalculatedMetricResponses["200"]["content"]["application/json"];
export const postCalculatedMetric = async (
  valorId: string,
  labelId: string,
  lhsLabelIds: string[],
  rhsLabelIds: string[],
  operator: string,
): Promise<PostCalculatedMetricResult> => {
  const body: PostCalculatedMetricRequest = {
    valorId,
    labelId,
    lhsLabelIds,
    rhsLabelIds,
    operator,
  };
  const response = await axios.post("/api/metrics/calculated-metric", body);
  return response.data;
};

type PutCalculatedMetric = paths["/api/metrics/calculated-metric"]["put"];
export type PutCalculatedMetricRequestBody = PutCalculatedMetric["requestBody"];
export type PutCalculatedMetricResponses = PutCalculatedMetric["responses"];
export type PutCalculatedMetricRequest = PutCalculatedMetricRequestBody["content"]["application/json"];
export type PutCalculatedMetricResult = PutCalculatedMetricResponses["200"]["content"]["application/json"];
export const putCalculatedMetric = async (
  valorId: string,
  labelId: string,
  lhsLabelIds: string[],
  rhsLabelIds: string[],
  operator: string,
): Promise<PutCalculatedMetricResult> => {
  const body: PutCalculatedMetricRequest = {
    valorId,
    labelId,
    lhsLabelIds,
    rhsLabelIds,
    operator,
  };
  const response = await axios.put("/api/metrics/calculated-metric", body);
  return response.data;
};

export const patchHideMetricValue = async (metricValueId: string): Promise<void> => {
  const response = await axios.patch(`/api/metrics/metric-values/${metricValueId}/hide`);
  return response.data;
};

type CreateTask = operations["create_file_task_api_metrics_file_tasks__valor_id__post"];
export type CreateTaskResult = CreateTask["responses"]["200"]["content"]["application/json"];
export const createTask = async (valorId: string): Promise<CreateTaskResult> => {
  const response = await axios.post<CreateTaskResult>(
    `/api/metrics/file-tasks/${valorId}`,
  );
  return response.data;
};

type PostMetricLabel = paths["/api/metrics/label-business-sector"]["post"];
export type PostMetricLabelRequestBody = PostMetricLabel["requestBody"];
export type PostMetricLabelResponses = PostMetricLabel["responses"];
export type PostMetricLabelRequest = PostMetricLabelRequestBody["content"]["application/json"];
export type PostMetricLabelResult = PostMetricLabelResponses["200"]["content"]["application/json"];

type postData = {

  labelName: string,
  metricFamilyId: number,
  valueType: string,
  businessSectorId: string,
  canonicalParentMetricId: string,
  canRollup: boolean,
  isCalculated: boolean,

}
export const postMetricLabel = async ({
  labelName,
  metricFamilyId,
  valueType,
  businessSectorId,
  canonicalParentMetricId,
  canRollup,
  isCalculated,
}: postData): Promise<PostMetricLabelResult> => {
  const body: PostMetricLabelRequest = {
    name: labelName,
    businessSectorId,
    metricFamilyId,
    canonicalParentMetricId,
    canRollup,
    isCalculated,
    valueType,
  };
  const response = await axios.post("/api/metrics/labels", body);
  return response.data;
};

type GetActiveTasks = operations["get_active_tasks_api_metrics_file_tasks_get"];
export type GetActiveTasksResponses = GetActiveTasks["responses"];
export type GetActiveTasksResult = GetActiveTasksResponses["200"]["content"]["application/json"];
export const getActiveTasks = async (): Promise<GetActiveTasksResult> => {
  const response = await axios.get<GetActiveTasksResult>("/api/metrics/file-tasks");
  return response.data;
};

type GetCalculatedVariables = operations["get_calculated_metric_variables_get"];
export type GetCalculatedVariablesResponses = GetCalculatedVariables["responses"];
export type GetCalculatedVariablesResult = GetCalculatedVariablesResponses["200"]["content"]["application/json"];
export const getCalculatedVariables = async (
  entityMetricLabelRelationshipId,
  valorId,
): Promise<GetCalculatedVariablesResult> => {
  const response = await axios.get<GetCalculatedVariablesResult>(
    `/api/metrics/calculated-metric/${entityMetricLabelRelationshipId}/${valorId}`,
  );
  return response.data;
};

type PutFileTask = paths["/api/metrics/file-tasks"]["put"];
export type PutFileTaskRequestBody = PutFileTask["requestBody"];
export type PutFileTaskResponses = PutFileTask["responses"];
export type PutFileTaskRequest = PutFileTaskRequestBody["content"]["application/json"];
export type PutFileTaskResult = PutFileTaskResponses["200"]["content"]["application/json"];
export const putFileTask = async (
  taskIds: string[],
  status: FileTaskStatus,
  details: string = null,
  commentary: string = null,
): Promise<PutFileTaskResult> => {
  const body: PutFileTaskRequest = taskIds.map((taskId) => ({
    id: taskId,
    status,
    details,
    commentary,
  }));
  const response = await axios.put<PutFileTaskResult>("/api/metrics/file-tasks", body);
  return response.data;
};

type GetOrganizations = operations["get_organizations_api_metrics_organizations_get"];
export type GetOrganizationResult = GetOrganizations["responses"]["200"]["content"]["application/json"];
export const getOrganizations = async (): Promise<GetOrganizationResult> => (
  (await axios.get<GetOrganizationResult>("/api/metrics/organizations")).data
);

export const downloadTemplate = (companyName: string, valorId: string, fileName = "Financial-Template") => {
  const filename = `${companyName} --- ${fileName}.xlsx`;

  download({
    url: `/api/metrics/template/${valorId}?filename=${encodeURIComponent(filename)}`,
    filename,
  });
};

export const downloadFile = (taskIds: string[]) => {
  download({
    url: `/api/metrics/file-tasks/files?${taskIds.map((x) => `task_ids=${x}`).join("&")}`,
  });
};

type UploadMetricsFile = paths["/api/metrics/file-tasks/upload"]["put"];
export type UploadMetricsFileResult = UploadMetricsFile["responses"]["200"]["content"]["application/json"];
export const uploadMetricsFile = async (file: File, taskIds: string[]) => (
  upload<UploadMetricsFileResult>({
    method: "PUT",
    url: "/api/metrics/file-tasks/upload",
    data: {
      filledTemplate: file,
      taskIds,
    },
  })
);

type PostMetrics = paths["/api/metrics"]["post"];
export type PostMetricsRequestBody = PostMetrics["requestBody"]["content"]["application/json"];
export type PostMetricsResponses = PostMetrics["responses"]["200"]["content"]["application/json"];
export const postMetrics = async (
  valorId: string,
  body: PostMetricsRequestBody,
): Promise<PostMetricsResponses> => {
  const response = await axios.post<PostMetricsResponses>(`/api/metrics/bulk/${valorId}`, body);
  return response.data;
};

type GetReportingPeriodTypes = paths["/api/metrics/reporting-periods"]["get"];
export type GetReportingPeriodTypesResponses = GetReportingPeriodTypes["responses"];
export type GetReportingPeriodTypesResult = GetReportingPeriodTypesResponses["200"]["content"]["application/json"];
export const getReportingPeriodTypes = async (): Promise<GetReportingPeriodTypesResult> => {
  const response = await axios.get<GetReportingPeriodTypesResult>(
    "/api/metrics/reporting-periods",
  );
  return response.data;
};

type GetOrganizationReportingPeriods = paths["/api/metrics/organization/{valor_id}/reporting-periods"]["get"];
export type GetOrganizationReportingPeriodsResponses = GetOrganizationReportingPeriods["responses"];
export type GetOrganizationReportingPeriodsResult = GetOrganizationReportingPeriodsResponses["200"]["content"]["application/json"];
export const getOrganizationReportingPeriods = async (
  organizationId: string,
): Promise<GetOrganizationReportingPeriodsResult> => {
  const response = await axios.get<GetOrganizationReportingPeriodsResult>(
    `/api/metrics/organization/${organizationId}/reporting-periods`,
  );
  return response.data;
};

type PutOrganizationReportingPeriods = paths["/api/metrics/organization/{valor_id}/reporting-periods"]["put"];
export type PutOrganizationReportingPeriodsRequestBody = PutOrganizationReportingPeriods["requestBody"];
export type PutOrganizationReportingPeriodsResponses = PutOrganizationReportingPeriods["responses"];
export type PutOrganizationReportingPeriodsRequest = PutOrganizationReportingPeriodsRequestBody["content"]["application/json"];
export type PutOrganizationReportingPeriodsResult = PutOrganizationReportingPeriodsResponses["200"]["content"]["application/json"];
export const putOrganizationReportingPeriods = async (
  organizationId: string,
  reportingPeriods: PutOrganizationReportingPeriodsRequest,
): Promise<PutOrganizationReportingPeriodsResult> => {
  const response = await axios.put<PutOrganizationReportingPeriodsResult>(
    `/api/metrics/organization/${organizationId}/reporting-periods`,
    reportingPeriods,
  );
  return response.data;
};

type GetFinanceOrg = paths["/api/metrics/organization/{valor_id}"]["get"];
export type GetFinanceOrgResponses = GetFinanceOrg["responses"];
export type GetFinanceOrgResult = GetFinanceOrgResponses["200"]["content"]["application/json"];

export const getFinanceOrg = async (valorId): Promise<GetFinanceOrgResult> => {
  const response = await axios.get<GetFinanceOrgResult>(`/api/metrics/organization/${valorId}`);
  return response.data;
};

type UpdateFinanceOrg = paths["/api/metrics/organization/{valor_id}"]["put"]
export type UpdateFinanceOrgRequestBody = UpdateFinanceOrg["requestBody"];
export type UpdateFinanceOrgResponses = UpdateFinanceOrg["responses"];
export type UpdateFinanceOrgRequest = UpdateFinanceOrgRequestBody["content"]["application/json"];
export type UpdateFinanceOrgResult = UpdateFinanceOrgResponses["200"]["content"]["application/json"];

export const updateFinanceOrg = async (
  valorId,
  body: UpdateFinanceOrgRequest,
): Promise<UpdateFinanceOrgResult> => {
  const response = await axios.put<UpdateFinanceOrgResult>(
    `/api/metrics/organization/${valorId}`,
    body,
  );
  return response.data;
};

type GetMetricsActivityFeed = paths["/api/metrics/activity-feed"]["get"]
export type GetMetricsActivityFeedResponses = GetMetricsActivityFeed["responses"];
export type GetMetricsActivityFeedResult = GetMetricsActivityFeedResponses["200"]["content"]["application/json"];
export const getMetricsActivityFeed = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
): Promise<GetMetricsActivityFeedResult> => {
  const params = searchParamsObject({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get(
    "/api/metrics/activity-feed",
    { params },
  );
  return response.data;
};

type GetMetricsActivityFeedTasks = paths["/api/metrics/activity-feed/tasks"]["get"]
export type GetMetricsActivityFeedTasksResponses = GetMetricsActivityFeedTasks["responses"];
export type GetMetricsActivityFeedTasksResult = GetMetricsActivityFeedTasksResponses["200"]["content"]["application/json"];
export const getActivityFeedTasks = async (taskIds: string[])
  : Promise<GetMetricsActivityFeedTasksResult> => {
  const response = await axios.get(`/api/metrics/activity-feed/tasks?${taskIds.map((x) => `taskIds=${x}`).join("&")}`);
  return response.data;
};

type GetMetricsActivityFeedTasksMetrics = paths["/api/metrics/activity-feed/tasks/metrics"]["get"]
export type GetMetricsActivityFeedTasksMetricsResponses = GetMetricsActivityFeedTasksMetrics["responses"];
export type GetMetricsActivityFeedTasksMetricsResult = GetMetricsActivityFeedTasksMetricsResponses["200"]["content"]["application/json"];
export const getActivityFeedTaskMetrics = async (taskIds: string[])
  : Promise<GetMetricsActivityFeedTasksMetricsResult> => {
  const response = await axios.get(`/api/metrics/activity-feed/tasks/metrics?${taskIds.map((x) => `taskIds=${x}`).join("&")}`);
  return response.data;
};

type GetMetricsCount = paths["/api/metrics/count"]["get"];
export type GetMetricsCountResult = GetMetricsCount["responses"]["200"]["content"]["application/json"];
export const getMetricsCount = async (): Promise<GetMetricsCountResult> => {
  const response = await axios.get("/api/metrics/count");
  return response.data;
};

type GetMissingMetrics = paths["/api/metrics/missing"]["get"];
export type GetMissingMetricsResult = GetMissingMetrics["responses"]["200"]["content"]["application/json"];
export const getMissingMetrics = async (): Promise<GetMissingMetricsResult> => {
  const response = await axios.get("/api/metrics/missing");
  return response.data;
};

type GetInternalFiles = paths["/api/metrics/internal-files"]["get"];
export type getInternalFilesResult = GetInternalFiles["responses"]["200"]["content"]["application/json"];
export const getInternalFiles = async (
  limit = 25,
  marker: string | null = null,
  sort: string[] | null = null,
  filter: { [key: string]: string | any } | null = null,
) => {
  const params = searchParamsObject({
    limit,
    marker,
    sort,
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get(
    "/api/metrics/internal-files-paged",
    { params },
  );
  return response.data;
};

type PutMetricsInternalFiles = paths["/api/metrics/internal-files/{file_id}"]["put"];
export type PutMetricsInternalFilesRequest = PutMetricsInternalFiles["requestBody"]["content"]["application/json"];
export type PutMetricsInternalFilesResult = PutMetricsInternalFiles["responses"]["200"]["content"]["application/json"];
export const updateInternalFileStatus = async (
  fileId: string,
  body: PutMetricsInternalFilesRequest,
) : Promise<PutMetricsInternalFilesResult> => {
  const response = await axios.put(`/api/metrics/internal-files/${fileId}`, body);
  return response.data;
};

export const downloadAllFiles = async (valorId: string): Promise<void> => {
  download({
    url: `/api/metrics/file-tasks/files/${valorId}`,
  });
};

type GetMetrics = paths["/api/metrics"]["get"];
export type Metric = GetMetrics["responses"]["200"]["content"]["application/json"][0];

export const getMetrics = async (valorIds: string[], periodType: string): Promise<Metric[]> => {
  const params = buildURLParameters({ valorIds, periodType });
  const response = await axios.get(`/api/metrics?${params}`);
  return response.data;
};

type GetExplainedMetric = paths["/api/metrics/explain"]["get"];
export type ExplainedMetricResult = GetExplainedMetric["responses"]["200"]["content"]["application/json"];
export const explainMetric = async (
  valorId: string,
  labelId: string,
  periodName: string,
  isCalculated: boolean,
): Promise<ExplainedMetricResult> => {
  const params = buildURLParameters({
    valorId,
    labelId,
    periodName,
    isCalculated,
  });
  const response = await axios.get(
    `/api/metrics/explain?${params}`,
  );
  return response.data;
};

type PostNewMetric = paths["/api/metrics"]["post"];
export type PostNewMetricRequestBody = PostNewMetric["requestBody"]["content"]["application/json"];

export const postNewMetricValue = async (
  body: PostNewMetricRequestBody,
): Promise<void> => {
  const response = await axios.post("/api/metrics", body);
  return response.data;
};

export default {
  deleteOrganizationKeyMetric,
  downloadTemplate,
  getFinanceOrg,
  getMetricLabels,
  patchHideMetricValue,
  getMetrics,
  postNewMetricValue,
  postOrganizationKeyMetric,
  updateFinanceOrg,
};
