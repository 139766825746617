import React from "react";
import { Box } from "@mui/material";
import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";

function IHEChangeCellIcon({
  color,
  animated,
  size,
}: {
  size: "small" | "big";
  color: "red" | "green" | "yellow";
  animated?: boolean;
}) {
  const Icon = FrameworkChoiceColorIconMapping[color];
  const animation = {
    green: "bounce 2s ease infinite",
    red: "bounceDown 2s ease infinite",
    yellow: "hithere 2s ease infinite",
    gray: "hithere 2s ease infinite",
  };
  const getSize = {
    small: 18,
    big: 24,
  };
  const getRemSize = {
    small: ".75rem",
    big: "1rem",
  };

  if (Icon) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        border={(theme) => (size === "small" ? null : `1px solid ${theme.palette[color].main}`)}
        color={(theme) => theme.palette[color].main}
        borderRadius={size === "small" ? 0.5 : 1}
        padding={0.5}
        width={getSize[size]}
        height={getSize[size]}
      >
        <Icon
          style={{
            fontSize: getRemSize[size],
            animation: animated ? animation[color] : "none",
          }}
        />
      </Box>
    );
  }
  return null;
}

export default IHEChangeCellIcon;
