import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  Popover,
  Chip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SortIcon from "@mui/icons-material/Sort";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { SortingState, ColumnFiltersState } from "@tanstack/react-table";
import { titleCase } from "@/utils/titleCase";
import Progress from "@/ui/atoms/Progress";

import SearchIcon from "@mui/icons-material/Search";
import DebouncedTextField from "@/components/InfiniteTable/DebouncedTextField";

import styled from "@mui/material/styles/styled";

const ToolbarButton = styled(Box, {
  shouldForwardProp: (prop) => prop !== "active",
})<{ active: boolean }>(({ theme, active }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  color: active ? theme.palette.text.primary : theme.palette.text.secondary,
  backgroundColor: active ? theme.palette.action.selected : "transparent",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ToolbarActionCount = styled(Typography)({
  fontSize: "0.75rem",
  fontWeight: "bold",
  marginLeft: "0.5rem",
});

const ClearIcon = styled(CloseIcon)({
  cursor: "pointer",
});

function ToolbarAction({
  icon,
  collection,
  renderPopoverValue,
  onClear,
  readOnly = false,
}: {
  icon: React.ReactNode;
  collection: any[];
  renderPopoverValue: (value: any) => React.ReactNode;
  onClear: () => void;
  readOnly?: boolean;
}) {
  const hasItems = collection.length > 0;
  const [showPopover, setShowPopover] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  return (
    <>
      <ToolbarButton
        active={hasItems}
        onClick={() => {
          if (hasItems) {
            setShowPopover(true);
          }
        }}
        ref={ref}
      >
        {icon}
        {hasItems && (<ToolbarActionCount>{collection.length}</ToolbarActionCount>)}
        {hasItems && !readOnly && (
          <ClearIcon
            onClick={(e) => {
              onClear();
              e.preventDefault();
              e.stopPropagation();
            }}
          />
        )}
      </ToolbarButton>
      <Popover
        open={showPopover}
        anchorEl={ref.current}
        onClose={() => {
          setShowPopover(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box
          padding={1}
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {collection?.map((item) => renderPopoverValue(item))}
        </Box>
      </Popover>
    </>
  );
}

export default function Toolbar({
  columnHeaders,
  columnVisibility,
  sorting,
  columnFilters,
  globalFilter,
  onRemoveColumnFilters,
  onRemoveSorting,
  onRemoveVisibility,
  onGlobalFilterChange,
  isLoading = false,
  readOnly = false,
}: {
  columnHeaders: { id: string; header: string }[];
  columnVisibility: Record<string, boolean>;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
  globalFilter: string | null;
  onRemoveColumnFilters: () => void;
  onRemoveSorting: () => void;
  onRemoveVisibility: () => void;
  onGlobalFilterChange?: (value: string | null) => void;
  isLoading?: boolean;
  readOnly?: boolean;
}) {
  return (
    <Box
      sx={{
        padding: 1,
      }}
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      {/* filters & sorting  */}
      <Box display="flex" justifyContent="space-between" alignItems="center" gap={4}>
        <Box display="flex" flexDirection="row" gap={1}>
          <ToolbarAction
            icon={<FilterAltIcon />}
            collection={columnFilters}
            renderPopoverValue={(filter) => {
              const header = columnHeaders.find(
                (x) => x.id === filter.id,
              )?.header;
              let { value } = filter;
              if (Array.isArray(value)) {
                if (value.length === 0) {
                  return null;
                }
                if (typeof value[0] === "object") {
                  value = value.map((x) => x.label).join(" or ");
                } else {
                  value = value.map((x) => titleCase(x)).join(" or ");
                }
              }
              if (typeof value === "object" && value !== null) {
                if (value.min && value.max) {
                  value = `${value.min} to ${value.max}`;
                } else if (value.min) {
                  value = `≥ ${value.min}`;
                } else if (value.max) {
                  value = `≤ ${value.max}`;
                } else {
                  value = value.label;
                }
              }
              return <Chip key={filter.id} label={`${header} "${value}"`} />;
            }}
            onClear={onRemoveColumnFilters}
            readOnly={readOnly}
          />

          <ToolbarAction
            icon={<SortIcon />}
            collection={sorting}
            renderPopoverValue={({ id, desc }) => {
              const column = columnHeaders.find((x) => x.id === id);
              const header = column?.header;
              const type = column?.meta?.sortType || "alpha";
              const sortMap = {
                alpha: {
                  asc: "A-Z",
                  desc: "Z-A",
                },
                date: {
                  asc: "Oldest first",
                  desc: "Newest first",
                },
                numeric: {
                  asc: "Low to High",
                  desc: "High to Low",
                },
                boolean: {
                  asc: "False first",
                  desc: "True first",
                },
                default: {
                  asc: "Ascending",
                  desc: "Descending",
                },
              };
              const sort = sortMap[type][desc ? "desc" : "asc"];
              return <Chip key={id} label={`${header} "${sort}"`} />;
            }}
            onClear={onRemoveSorting}
            readOnly={readOnly}
          />

          <ToolbarAction
            icon={<VisibilityOffIcon />}
            collection={Object.entries(columnVisibility || {}).filter(
              ([, value]) => !value,
            )}
            renderPopoverValue={([key]) => {
              const columnHeader = columnHeaders.find((x) => x.id === key);
              const header = columnHeader?.header;

              if (!header) {
                return null;
              }
              return <Chip key={columnHeader.id} label={header} />;
            }}
            onClear={onRemoveVisibility}
            readOnly={readOnly}
          />
        </Box>
        <Box>
          {isLoading ? <Progress /> : null}
        </Box>
      </Box>
      {onGlobalFilterChange && (
        <Box>
          <DebouncedTextField
            label="Search"
            clearable
            dense
            InputProps={{
              startAdornment: <SearchIcon />,
            }}
            value={globalFilter}
            variant="standard"
            onChange={(value) => {
              onGlobalFilterChange(value.toString());
            }}
          />
        </Box>
      )}
    </Box>
  );
}
