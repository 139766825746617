import React, {
  useState, useMemo, useCallback, useEffect,
} from "react";
import { Form, Task } from "@/api/Process";
import {
  Collapse, Box, Typography, Button, Alert,
} from "@mui/material";
import Input from "@/components/FormInputs/Input";
import { FIELD_VALUE_TYPE_MAP as typeMap } from "../constants";

const Field = React.memo(({
  field,
  value,
  onChange,
  onBlur,
  organizationName,
  required,
  error,
  disabled,
}: {
  field: FieldList[0];
  value: any;
  onChange: (v: any, f: FieldList[0]) => void;
  onBlur: (v: any, f: FieldList[0]) => void;
  organizationName: string;
  required: boolean;
  error: boolean;
  disabled: boolean;
}) => (
  <Input
    dataCy={`fields.${field.type}.${field.id}`}
    key={field.id}
    type={field.type}
    name={field.name}
    value={value}
    onChange={(newValue) => onChange(newValue, field)}
    onBlur={(v) => onBlur(v, field)}
    choices={field.choices}
    isOrganizationField={field.isOrganizationField}
    organizationName={organizationName}
    required={required}
    disabled={disabled}
    error={error}
  />
));
Field.displayName = "Field";

function MaskedField({
  field,
  value,
  // onChange,
  onBlur,
  organizationName,
  required,
  error,
  disabled,
}: {
  field: FieldList[0];
  value: any;
  // onChange: (value: any, field: FieldList[0]) => void;
  onBlur: (v: any, f: FieldList[0]) => void;
  organizationName: string;
  required: boolean;
  error: boolean;
  disabled: boolean;
}) {
  const [bareValue, setBareValue] = useState();
  const [maskValue, setMaskValue] = useState();

  useEffect(() => {
    if (bareValue !== value) {
      setBareValue(value);
    }
  }, [value, bareValue]);

  const inputValue = useMemo(() => {
    if (maskValue !== undefined) {
      return maskValue;
    }
    if (bareValue !== undefined) {
      return bareValue;
    }
    return null;
  }, [maskValue, bareValue]);

  return (
    <Field
      field={field}
      value={inputValue}
      onChange={(newValue) => {
        setMaskValue(newValue);
      }}
      onBlur={onBlur}
      organizationName={organizationName}
      required={required}
      error={error}
      disabled={disabled}
    />
  );
}

function renderField(field: Form["sections"][0]["fields"][0], options: any) {
  const {
    value,
    disabled,
    onBlur,
    required,
    error,
  } = options;
  return (
    <MaskedField
      key={field.id}
      field={field}
      value={value}
      onBlur={onBlur}
      organizationName=""
      required={required}
      error={error}
      disabled={disabled}
    />
  );
}

export default function FormComponent({
  structure,
  value,
  onSubmit,
  onCancel,
  disabled,
  loading,
}: {
  structure: Form;
  value: Task;
  onSubmit: (values: Task["fieldValues"]) => void;
  onCancel: () => void;
  disabled?: boolean;
  loading?: boolean;
}) {
  const [validating, setValidating] = React.useState(false);
  const [openSection, setOpenSection] = React.useState<Form["sections"][0]>(null);
  const isOpenSection = (section: Form["sections"][0]) => section?.title === "a"
    || section?.collapsable === false
    || (openSection && section?.id === openSection.id);

  const [mask, setMaskValues] = React.useState<Task["fieldValues"]>({});
  const computedValues = useMemo(() => {
    if (!value) return null;
    return {
      ...value,
      fieldValues: {
        ...value.fieldValues,
        ...mask,
      },
    };
  }, [mask, value]);

  useEffect(() => {
    setMaskValues({});
  }, [value?.id]);

  const getRequiredFields = useCallback(
    () => structure.sections.reduce((acc, section) => {
      const fields = section.fields.filter((f) => f.required);
      return [
        ...acc,
        ...fields.map((f) => ({
          ...f,
          section,
        })),
      ];
    }, []),
    [structure],
  );
  const unfilledRequiredFields = useCallback(() => {
    const requiredFields = getRequiredFields();
    return requiredFields.filter((f) => {
      const fieldValue = computedValues?.fieldValues?.[f.id]?.[typeMap[f.type]];
      if (
        fieldValue === undefined
        || fieldValue === null
        || fieldValue === ""
        || (f.type === "checkbox" && fieldValue !== true && fieldValue !== "Yes")
      ) {
        return true;
      }
      if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        return true;
      }
      return false;
    });
  }, [computedValues, getRequiredFields]);

  const validate = useCallback(() => {
    setValidating(true);
    const invalidFields = unfilledRequiredFields();

    return invalidFields;
  }, [unfilledRequiredFields]);

  if (structure === undefined || !value || loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
      }}
    >
      {validating && unfilledRequiredFields().length > 0 && (
        <Alert severity="error">
          Please fill out all required fields. Missing value(s) for:
          {" "}
          <ul>
            {unfilledRequiredFields()
              .reduce((acc, f) => {
                // group by section id
                const sectionId = f.section.id;
                return [
                  ...acc.filter((a) => a.id !== sectionId),
                  {
                    id: sectionId,
                    title: f.section.title,
                    fields: [
                      ...(acc.find((a) => a.id === sectionId)?.fields || []),
                      f,
                    ],
                  },
                ];
              }, [])
              .map((section) => (
                <li key={section.id}>
                  {section.title.length > 0 && (
                    <>
                      {section.title}
                      <ul>
                        {section.fields.map((field) => (
                          <li key={field.id}>{field.name}</li>
                        ))}
                      </ul>
                    </>
                  )}
                  {section.title.length === 0 && section.fields[0].name}
                </li>
              ))}
          </ul>
        </Alert>
      )}
      {structure.sections.map((section) => (
        <Box key={section.id}>
          {section.title && (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: (theme) => (validating
                  && unfilledRequiredFields().some(
                    (f) => f.section.id === section.id,
                  )
                  && !isOpenSection(section)
                  ? theme.palette.error.main
                  : theme.palette.divider),
                background: (theme) => (isOpenSection(section)
                  ? theme.palette.blue.main
                  : theme.palette.disabled),
                color: (theme) => (isOpenSection(section)
                  ? theme.palette.getContrastText(theme.palette.blue.main)
                  : validating
                        && unfilledRequiredFields().some(
                          (f) => f.section.id === section.id,
                        )
                    ? theme.palette.error.main
                    : theme.palette.text.primary),
                fontWeight: (theme) => (isOpenSection(section)
                  ? theme.typography.fontWeightBold
                  : theme.typography.fontWeightRegular),
                px: 1,
              }}
              onClick={() => {
                setOpenSection((prev) => (prev?.id === section.id ? null : section));
              }}
            >
              <Typography variant="overline">{section.title}</Typography>
            </Box>
          )}
          <Collapse in={isOpenSection(section)}>
            {section.fields.map((field) => (
              <Box
                key={field.id}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  py: 1,
                }}
              >
                {renderField(field, {
                  value:
                    computedValues?.fieldValues[field.id]?.[
                      typeMap[field.type]
                    ],
                  onBlur: (newValue) => {
                    const processedValue = {};
                    processedValue[typeMap[field.type]] = newValue;
                    //  set form values
                    setMaskValues((prev) => ({
                      ...prev,
                      [field.id]: processedValue,
                    }));
                  },
                  onChange: (newValue) => {
                    const processedValue = {};
                    processedValue[typeMap[field.type]] = newValue;
                    //  set form values
                    setMaskValues((prev) => ({
                      ...prev,
                      [field.id]: processedValue,
                    }));
                  },
                  required: field.required,
                  error:
                    validating
                    && unfilledRequiredFields().some((f) => f.id === field.id),
                  disabled,
                })}
              </Box>
            ))}
          </Collapse>
        </Box>
      ))}

      <Box display="flex" justifyContent="flex-end" gap={1}>
        <Button onClick={onCancel} disabled={disabled}>
          Discard
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (validate().length === 0) {
              onSubmit(mask);
            }
          }}
          disabled={disabled}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}
