import React, { useState } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import { Square, Send } from "@mui/icons-material";

export default function ChatBox({
  onSend,
  onStop,
  disabled,
}: {
  onSend: (message: string) => void;
  onStop: () => void;
  disabled: boolean;
}) {
  const [message, setMessage] = useState("");

  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    if (disabled) return;
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        event.preventDefault();
        if (message.trim()) {
          onSend(message);
          setMessage("");
        }
      }
    }
  };
  return (
    <Box
      padding={1}
      position="sticky"
      bottom={0}
      borderRadius={1}
      sx={{
        border: (theme) => `solid 1px ${theme.palette.divider}`,
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <TextField
        multiline
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={handleKeyDown}
        sx={{
          width: "100%",
          background: (theme) => theme.palette.background.paper,
        }}
        placeholder="Reply to Grok..."
        InputProps={{
          endAdornment: (
            <IconButton
              onClick={() => {
                if (!disabled && message.trim()) {
                  onSend(message);
                  setMessage("");
                } else if (disabled) {
                  onStop();
                }
              }}
            >
              {disabled ? (
                <Square />
              ) : (
                <Send sx={{ color: (t) => t.palette.primary.main }} />
              )}
            </IconButton>
          ),
        }}
      />
    </Box>
  );
}
