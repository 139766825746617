import React from "react";
import { Avatar, Tooltip } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { getInitials } from "@/utils/general";
import getThemeColor from "@/utils/getThemeColor";

const CustomAvatar = styled(Avatar)`
  border: 3px solid ${(props) => props.theme.palette.primary.main};
`;

interface User {
  id?: string,
  firstName?: string,
  lastName?: string,
  profilePicture?: string,
  name?: string;
  largeProfilePicture?: string
}

interface Props {
  user: User,
  useLargeImage?: boolean;
  displayTooltip?: boolean;
  style?: any // yes this is bad but this concept is bad in general
}

export default function UserAvatar({
  user,
  useLargeImage = false,
  displayTooltip = false,
  style,
}: Props) {
  const theme = useTheme();

  const name = user?.name ?? `${user?.firstName ?? ""} ${user?.lastName ?? ""}`.trim();
  const profileImage = (useLargeImage && user?.largeProfilePicture)
    ? user?.largeProfilePicture
    : user?.profilePicture;
  const title = displayTooltip ? name : "";
  const color = getThemeColor(name, theme);

  const avatar = user?.profilePicture ? (
    <CustomAvatar alt={name} src={profileImage} sx={style} />
  ) : (
    <Avatar
      alt={name}
      style={{
        color: theme.palette.getContrastText(color),
        backgroundColor: color,
        ...style,
      }}
    >
      {name ? getInitials(name) : null}
    </Avatar>
  );

  if (displayTooltip) {
    return (
      <Tooltip title={title} placement="top">
        {avatar}
      </Tooltip>
    );
  }

  return avatar;
}
