import React from "react";
import {
  Box, Chip,
} from "@mui/material";

export default function RYGCell({
  value,
}: {
  value: "R"|"Y"|"G";
}) {
  if (!value) return <>-</>;

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: "100%", height: "100%" }}
    >
      {value === "R" && (
        <Chip label="R" color="error" sx={{ height: "25px" }} />
      )}
      {value === "Y" && (
        <Chip label="Y" color="warning" sx={{ height: "25px" }} />
      )}
      {value === "G" && (
        <Chip label="G" color="success" sx={{ height: "25px" }} />
      )}
    </Box>
  );
}
