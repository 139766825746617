/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { useAuth } from "@/hooks/useAuth";
import LoginCard from "@/ui/atoms/LoginCard";

function LoginPage() {
  const { isAuthenticated, signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const referrer = location?.state?.referrer;

  useEffect(() => {
    if (isAuthenticated) {
      if (referrer) {
        return navigate(referrer + location.search);
      }
      return navigate("/home");
    }
    return undefined;
  }, [isAuthenticated, referrer, navigate, location.search]);

  return (
    <Box
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoginCard logoUrl="/images/vos_rect.png" onLogin={() => signIn()} />
    </Box>
  );
}

export default LoginPage;
