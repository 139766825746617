import React from "react";
import {
  FormControl,
  Select,
  MenuItem,
} from "@material-ui/core";
import PropTypes from "prop-types";

const colors = [
  null,
  "red",
  "yellow",
  "green",
];

export function ColoredSelect({ value, onChange, id }) {
  return (
    <FormControl
      variant="standard"
      margin="dense"
      style={{ width: "100px", backgroundColor: value }}
    >
      <Select
        id={id}
        value={value}
        onChange={(event) => {
          onChange(event.target?.value);
        }}
      >
        {colors.map((color) => <MenuItem style={{ backgroundColor: color, minHeight: "30px" }} key={color} value={color} />)}
      </Select>
    </FormControl>
  );
}

ColoredSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default ColoredSelect;
