/* eslint-disable no-console */
import axios from "axios";

export const saytForOrg = async (query, extraFields) => {
  let url = `/api/search/sayt?q=${encodeURIComponent(query)}`;

  if (extraFields) {
    extraFields.forEach((field) => {
      url += `&extra_fields=${field}`;
    });
  }
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const saytForUsers = async (query) => {
  const url = `/api/search/users/sayt?q=${encodeURIComponent(query)}`;

  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchOrg = async ({
  filters, sort, offset, size,
}) => {
  const url = "/api/search/org";
  try {
    const response = await axios.post(url, {
      filters, sort, offset, size,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const searchOrgCSV = async (name, { filters, sort }) => {
  const url = "/api/search/org/csv";
  try {
    const response = await axios.post(url, { filters, sort });
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `${name}.csv`);
    document.body.appendChild(link);
    link.click();
  } catch (error) {
    console.log(error);
    return error.response;
  }
  return null;
};

export const getSavedSearches = async () => {
  try {
    const response = await axios.get("/api/search/org/saved-search");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const patchSavedSearch = async (savedSearch) => {
  try {
    const response = await axios.patch("/api/search/org/saved-search", savedSearch);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createSavedSearch = async (savedSearch) => {
  try {
    const response = await axios.post("/api/search/org/saved-search", savedSearch);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  saytForOrg, searchOrg, searchOrgCSV, getSavedSearches, createSavedSearch, patchSavedSearch,
};
