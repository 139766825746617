import React, { useMemo } from "react";
import {
  Card,
  Box,
  Typography,
  TableRow,
  Chip,
  Button,
  useMediaQuery,
  useTheme,
  Select, MenuItem,
  Skeleton,
} from "@mui/material";

import {
  useReactTable,
  SortingState,
  ColumnFiltersState,
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";

import {
  CompanyCell,
  UserCell,
  DateCell,
  TextCell,
} from "@/components/Table/Primitives";
import HeaderCell from "@/components/Table/Header/Cell";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import {
  VirtualTableContainer,
  VirtualTableBody,
} from "@/components/InfiniteTable";
import { Toolbar as TableToolbar } from "@/components/Table/Header";
import { DateRangeFilter } from "@/components/Table/CustomFilters";
import dayjs from "dayjs";
import useProcess from "@/pages/ProcessManagement/ProcessContext/useProcess";
import { IHEChangeCell, ElementDelta } from "./IHEChangeCell";

export type IHEPortfolioChange = {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
  };
  organization: {
    valorId: string;
    name: string;
    domain: string;
    logoUrl: string;
  };
  funds: {
    [key: string]: boolean;
  };
  investmentElementChanges: ElementDelta[];
  completedAt?: string; // ISO date string YYYY-MM-DD
  surveyName: string;
};

export type IHEPortfolioChangeTableProps = {
  pages: IHEPortfolioChange[];
  isLoading?: boolean;
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  sorting: SortingState;
  columnFilters: ColumnFiltersState;
  fetchNextPage?: () => void;
  onSortingChange?: (sorting: SortingState) => void;
  onColumnFiltersChange?: (columnFilters: ColumnFiltersState) => void;
  onGlobalFilterChange?: (globalFilter: string | null) => void;
};

export default function IHEPortfolioChangeTable({
  pages,
  // totalCount,
  isLoading,
  isFetching,
  isFetchingNextPage,
  hasNextPage,
  fetchNextPage,
  sorting,
  columnFilters,
  columnVisibility,
  shouldPivotColumns,
  togglePivotColumns,
  onSortingChange,
  onColumnFiltersChange,
  onColumnVisibilityChange,
  onGlobalFilterChange,
}: IHEPortfolioChangeTableProps) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const isMsl = columnFilters.find((x) => x.id === "isMsl")?.value === true;

  // get portfolio process
  const { data: process, isLoading: isProcessLoading } = useProcess("portfolio");
  const furtherInvestmentStatusField = process?.fields.find(
    (x) => x.id === "f7274069-4879-45d4-948e-f8af823fa087",
  );

  const fivValue = useMemo(() => {
    const fivFilterValue = columnFilters.find((x) => x.id === "furtherInvestmentStatus")?.value;
    return fivFilterValue?.id || "0d52c59f-3c2f-62e1-1358-bc3aa3821e1d";
  }, [columnFilters]);

  // define column def
  const columnDef = useMemo(
    () => [
      {
        header: "Company",
        accessorKey: "organization",
        cell: (prop) => <CompanyCell value={prop.getValue()} />,
        id: "organization",
        minSize: isSmDown ? 48 : 250,
        size: isSmDown ? 48 : 250,
      },
      {
        header: "Funds",
        id: "funds[]",
        accessorKey: "funds",
        enableSorting: false,
        enableColumnFilter: false,
        minSize: 172,
        cell: (info) => (
          <Box
            display="inline-flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            flexWrap="wrap"
            width="100%"
            height="100%"
          >
            {(info.getValue() ?? {}).map((key) => (
              <Chip key={key} label={key} size="small" />
            ))}
          </Box>
        ),
      },
      {
        header: "Primary Fund",
        accessorFn: (row) => row.primaryFund,
        id: "primaryFund",
        sortType: "alpha",
        cell: (prop) => <TextCell value={prop.getValue()} />,
        minSize: 120,
        size: 120,
        maxSize: 120,
      },
      {
        header: "Management",
        id: "iheChangesManagement",
        accessorFn: (row) => row.managementChanges,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => <IHEChangeCell value={prop.getValue()} />,
        minSize: 185,
        size: 185,
      },
      {
        header: "Fundamentals",
        id: "iheChangesFundamentals",
        accessorFn: (row) => row.fundamentalsChanges,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => <IHEChangeCell value={prop.getValue()} />,
        minSize: 185,
        size: 185,
      },

      {
        header: "Revenue",
        id: "iheChangesRevenue",
        accessorFn: (row) => row.revenueChanges,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => <IHEChangeCell value={prop.getValue()} />,
        minSize: 185,
        size: 185,
      },
      {
        header: "Asymmetric Risk",
        id: "iheChangesRisk",
        accessorFn: (row) => row.asymmetricRiskChanges,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => <IHEChangeCell value={prop.getValue()} />,
        minSize: 185,
        size: 185,
      },
      {
        header: "Other",
        id: "iheChangesOther",
        accessorFn: (row) => row.otherChanges,
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => <IHEChangeCell value={prop.getValue()} />,
        minSize: 185,
        size: 185,
      },
      {
        header: "Negative Changes",
        id: "negativeChanges",
        accessorFn: (row) => [
          ...(row.managementChanges ?? []),
          ...(row.fundamentalsChanges ?? []),
          ...(row.revenueChanges ?? []),
          ...(row.asymmetricRiskChanges ?? []),
          ...(row.otherChanges ?? []),
        ],
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => (
          <IHEChangeCell type="negative" value={prop.getValue()} />
        ),
        minSize: 185,
        size: 280,
      },
      {
        header: "Positive Changes",
        id: "positiveChanges",
        accessorFn: (row) => [
          ...(row.managementChanges ?? []),
          ...(row.fundamentalsChanges ?? []),
          ...(row.revenueChanges ?? []),
          ...(row.asymmetricRiskChanges ?? []),
          ...(row.otherChanges ?? []),
        ],
        enableSorting: false,
        enableColumnFilter: false,
        cell: (prop) => (
          <IHEChangeCell type="positive" value={prop.getValue()} />
        ),
        minSize: 185,
        size: 280,
      },
      // {
      //   header: "Tier",
      //   accessorFn: (row) => row.tier,
      //   cell: (prop) => (
      //     <Typography sx={{ textAlign: "center" }}>
      //       {prop.getValue()}
      //     </Typography>
      //   ),
      //   id: "tier",
      //   size: 24,
      //   filter: TierFilter,
      // },

      {
        header: isMsl ? "MSL" : "User",
        accessorFn: (row) => row.user,
        cell: (prop) => <UserCell value={prop.getValue()} />,
        id: "user.name",
        size: 148,
      },
      {
        header: "Survey Name",
        accessorKey: "surveyName",
        id: "surveyName",
        cell: (prop) => <TextCell value={prop.getValue()} />,
        size: 200,
      },

      {
        header: "Taken On",
        id: "completedAt",
        filter: DateRangeFilter,
        accessorKey: "completedAt",
        enableSorting: false,
        cell: (prop) => <DateCell value={prop.getValue()} />,
        meta: {
          sortType: "date",
        },
        size: 120,
      },
      {
        header: "Prior Survey(s) Taken On",
        id: "prevCompletedAt",
        accessorKey: "prevCompletedAt",
        cell: (prop) => (
          <Box
            height="100%"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            {prop.getValue()?.map((x) => (
              <Typography
                key={x}
                sx={{ textAlign: "center" }}
                variant="body2"
              >
                {dayjs(x).format("MM/DD/YYYY")}
              </Typography>
            ))}
          </Box>
        ),
        enableSorting: false,
        enableColumnFilter: false,
        size: 210,
      },
      {
        header: "MSL",
        accessorKey: "isMsl",
        id: "isMsl",
        visible: false,
      },
      {
        header: "Further Investment Status",
        accessorFn: (row) => row.furtherInvestmentStatus?.value,
        id: "furtherInvestmentStatus",
        visible: false,
      },
    ].map((x) => ({
      ...x,
      filterFn: () => true,
    })),
    [isSmDown, isMsl],
  );

  const filteredPages = useMemo(() => {
    // hack to filter out further investment status because
    // trying to get it performant in SQL is a massive waste of time
    if (!fivValue) {
      return pages;
    }
    return pages.filter((row) => row.furtherInvestmentStatus?.id === fivValue);
  }, [pages, fivValue]);

  const table = useReactTable({
    data: filteredPages,
    columns: columnDef,
    state: {
      sorting,
      columnFilters,
      columnVisibility: {
        ...columnVisibility,
        isMsl: false,
        furtherInvestmentStatus: false,
      },
    },
    onColumnFiltersChange,
    onGlobalFilterChange,
    onColumnVisibilityChange,
    onSortingChange,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <Card
      sx={{
        maxHeight: "100vh",
        overflowY: "hidden",
      }}
    >
      {(isLoading || isFetchingNextPage) && (
        <CenteredProgress
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        />
      )}

      <Box
        px={1}
        py={2}
        maxWidth="100%"
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          gap={1}
          width="100%"
        >
          <Typography
            variant="h3"
            data-cy={filteredPages?.length === 0 ? "no-data-message" : null}
          >
            {"Portfolio IHE Changes "}
            {typeof filteredPages?.length === "number"
              && `- ${
                filteredPages?.length > 0 ? filteredPages?.length.toLocaleString() : "No"
              } Results`}
          </Typography>
          <Box display="flex" gap={1}>
            {isProcessLoading ? (
              <Skeleton variant="rounded" width={100} height={60} />
            ) : (
              <Select
                value={fivValue}
                onChange={(e) => {
                  const prev = columnFilters;
                  const id = e.target.value as string;
                  const value = furtherInvestmentStatusField?.choices.find(
                    (x) => x.id === id,
                  );
                  onColumnFiltersChange?.(
                    [
                      ...prev.filter((x) => x.id !== "furtherInvestmentStatus"),
                      {
                        id: "furtherInvestmentStatus",
                        value: {
                          id: value?.id,
                          label: value?.value,
                          value: value?.id,
                        },
                      },
                    ].filter((x) => x) as ColumnFiltersState,
                  );
                }}
              >
                {furtherInvestmentStatusField?.choices.map((choice) => (
                  <MenuItem key={choice.id} value={choice.id}>
                    {choice.value}
                  </MenuItem>
                ))}
              </Select>
            )}
            <Button
              variant="contained"
              onClick={() => {
                if (shouldPivotColumns) {
                  table.setColumnVisibility((prev) => ({
                    ...prev,
                    positiveChanges: true,
                    negativeChanges: true,
                    iheChangesManagement: false,
                    iheChangesFundamentals: false,
                    iheChangesRevenue: false,
                    iheChangesRisk: false,
                    iheChangesOther: false,
                  }));
                } else {
                  table.setColumnVisibility((prev) => ({
                    ...prev,
                    positiveChanges: false,
                    negativeChanges: false,
                    iheChangesManagement: true,
                    iheChangesFundamentals: true,
                    iheChangesRevenue: true,
                    iheChangesRisk: true,
                    iheChangesOther: true,
                  }));
                }
                togglePivotColumns();
              }}
            >
              {shouldPivotColumns ? "By Change" : "By Category"}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                const prev = columnFilters;
                const showOnlyMSL = !prev?.find((x) => x.id === "isMsl")?.value;
                onColumnFiltersChange?.(
                  [
                    ...prev.filter((x) => x.id !== "isMsl"),
                    { id: "isMsl", value: showOnlyMSL },
                  ].filter((x) => x) as ColumnFiltersState,
                );
              }}
            >
              {columnFilters.find((x) => x.id === "isMsl")?.value
                ? "Show Non-MSL"
                : "Show MSL"}
            </Button>
          </Box>
        </Box>
      </Box>

      <TableToolbar
        columnHeaders={columnDef}
        sorting={sorting}
        columnFilters={columnFilters}
        columnVisibility={columnVisibility}
        onRemoveColumnFilters={() => onColumnFiltersChange([])}
        onRemoveSorting={() => onSortingChange([])}
        onRemoveVisibility={() => onColumnVisibilityChange({})}
      />

      <VirtualTableContainer
        height="calc(100vh - 200px)"
        onScrollBottomReached={() => {
          if (!isFetching && hasNextPage) {
            fetchNextPage?.();
          }
        }}
      >
        <StickyTable>
          <StickyTableHead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <HeaderCell
                    key={header.id}
                    header={header}
                    column={header.column}
                    table={table}
                  />
                ))}
              </TableRow>
            ))}
          </StickyTableHead>
          <VirtualTableBody
            rows={table.getRowModel().rows}
            estimateSize={120}
            table={table}
          />
        </StickyTable>
      </VirtualTableContainer>
    </Card>
  );
}
