import React from "react";
import { Grid } from "@mui/material";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getRolesMembersRestrictions } from "@/api/Compliance";
import RoleCard from "./RoleCard";

function Roles() {
  const navigate = useNavigate();

  const { data: roles = [] } = useQuery(
    ["RolesMembersRestrictions"],
    getRolesMembersRestrictions,
  );

  const handleMemberClick = (user) => {
    navigate(`/compliance-and-permissions/user?id=${user.id}`);
  };

  return (
    <Grid container direction="row" spacing={3}>
      {roles?.map((role) => (
        <Grid item key={`grid-item-${role.id}`}>
          <RoleCard role={role} handleMemberClick={handleMemberClick} />
        </Grid>
      ))}
    </Grid>
  );
}

export default Roles;
