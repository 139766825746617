import React from "react";
import { styled } from "@mui/material/styles";
import { Dayjs } from "dayjs";
import {
  LocalizationProvider,
  DatePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import getStartAndEndDate from "@/utils/getStartAndEndDate";
import SingleSelect from "@/ui/atoms/SingleSelect";
import DropdownInfo from "@/ui/atoms/DropdownInfo";

const StyledFormControl = styled("div")(() => ({
  "& .MuiInputBase-root": {
    padding: 0,
    "& .MuiButtonBase-root": {
      padding: 0,
      paddingLeft: 0,
    },
    "& .MuiInputBase-input": {
      padding: 0,
    },
  },
}));

const dateRangeDocs = (
  <>
    <h3>Filter By Date Range</h3>
    Date range holds a list of most used date filters.
    <br />
    <br />
    By selecting a date range the
    <i>Start Date</i>
    and
    <i>End Date</i>
    will be autopopulated.
    <br />
    <br />
    If you want to manually pick a
    <i>Start Date</i>
    and
    <i>End Date</i>
    select
    <strong>
      <i>Custom</i>
    </strong>
    option and the dates will be enabled.
    <br />
  </>
);

const defaultStartDateDoc = (
  <>
    <h3>Filter By Start Date</h3>
    The histories values be greater than or equal to the
    <i>Start Date</i>
    .
    <br />
    <br />
  </>
);

const defaultEndDateDoc = (
  <>
    <h3>Filter By End Date</h3>
    The histories values be less than or equal to
    <i>End Date</i>
    .
    <br />
    <br />
  </>
);

const dateOptions = {
  custom: "Custom",
  last_6_months: "Last 6 Months",
  last_year: "Last year",
  last_2_years: "Last 2 years",
  all_time: "All Time",
};

interface StartEndDatesProps {
  setStartDate: (date: Dayjs | null) => void;
  setEndDate: (date: Dayjs | null) => void;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
  startDateLabel?: string;
  endDateLabel?: string;
  dateRange: string;
  setDateRange: (range: string) => void;
  startDateDoc?: JSX.Element;
  endDateDoc?: JSX.Element;
  showPickers: boolean;
}

function StartEndDates({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  startDateLabel = "Start Date",
  endDateLabel = "End Date",
  dateRange,
  setDateRange,
  startDateDoc = defaultStartDateDoc,
  endDateDoc = defaultEndDateDoc,
  showPickers,
}: StartEndDatesProps) {
  const disabledDate = dateRange !== "custom";

  const onChangeDateRange = (value: string) => {
    setDateRange(value);
    const o = getStartAndEndDate(value);
    setStartDate(o.startDate);
    setEndDate(o.endDate);
  };

  return (
    <>
      <DropdownInfo
        component={(
          <SingleSelect
            id="date-range"
            title="Date Range"
            value={dateRange}
            onChange={onChangeDateRange}
            options={dateOptions}
          />
        )}
        title={dateRangeDocs}
      />
      {showPickers && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DropdownInfo
            component={(
              <DatePicker
                className={StyledFormControl}
                format="MMMM-DD-YYYY"
                margin="dense"
                id="date-picker-start-date"
                label={startDateLabel}
                value={startDate}
                disabled={disabledDate}
                onChange={setStartDate}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            )}
            title={startDateDoc}
          />
          <DropdownInfo
            component={(
              <DatePicker
                className={StyledFormControl}
                format="MMMM-DD-YYYY"
                margin="dense"
                id="date-picker-end-date"
                label={endDateLabel}
                value={endDate}
                disabled={disabledDate}
                onChange={setEndDate}
                // eslint-disable-next-line react/jsx-props-no-spreading
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            )}
            title={endDateDoc}
          />
        </LocalizationProvider>
      )}
    </>
  );
}

export default StartEndDates;
