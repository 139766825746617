import React from "react";
import { Box } from "@mui/material";
import { SignalChip } from "@/components/SignalChip";

type MoicCellProps = {
  value: number | null;
};

export default function MoicCell({
  value,

}: MoicCellProps) {
  if (typeof value !== "number") {
    return <>-</>;
  }
  // Hard coding mantis to show just moic multiple
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100%"
      gap={1}
    >
      <SignalChip
        signal={{
          signalType: "signal_mantis_prediction",
          score: value,
        }}
        showIcon
      />
    </Box>
  );
}
