import React, { useState } from "react";
import {
  Box, Typography, Card, Divider, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, useTheme,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import dayjs from "dayjs";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { Task } from "@/api/Process";
import CommentForm from "./CommentForm";

type CommentProps = {
  processId: string;
  currentUser: {
    id: string;
    firstName: string;
    lastName: string;
    profilePicture: string;
  };
  comment: Task["comments"][0];
  activeComment: Task["comments"][0];
  setActiveComment: (comment: Task["comments"][0]) => void;
  onComment: (comment: { id?: string; comment: string }) => void;
}

export function Comment({
  processId,
  currentUser,
  comment,
  activeComment,
  setActiveComment,
  onComment,
}: CommentProps) {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState(null);
  const canEdit = comment.user?.id === currentUser.id;

  const commentFormatTime = (createdAt, updatedAt) => {
    const createdAtDate = dayjs.utc(createdAt).local();
    const updatedAtDate = dayjs.utc(updatedAt).local();

    const relativeCreatedAt = createdAtDate.fromNow();
    const diff = createdAtDate.diff(updatedAtDate);
    const isEdited = diff !== 0;

    let absoluteDate = createdAtDate.format("h:mma MMM D, YYYY");
    // if this year, don't show year
    if (createdAtDate.year() === dayjs().year()) {
      absoluteDate = createdAtDate.format("h:mma MMM D");
    }

    return `${relativeCreatedAt}${isEdited ? " (edited)" : ""} at ${absoluteDate}`;
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      gridGap={16}
      width="100%"
      key={comment.id}
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        gridGap={8}
        flexGrow={1}
      >
        <UserAvatar
          user={{
            ...(comment?.user || {}),
            name: `${comment.user?.firstName} ${comment.user?.lastName}`,
          }}
        />
        <Divider orientation="vertical" style={{ flexGrow: 1, height: "auto" }} />
      </Box>
      <Card
        variant="outlined"
        style={{
          width: "100%",
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          gridGap={8}
          padding={1}
          width="100%"
        >
          <Box
            display="flex"
            flexDirection="row"
          >
            <Typography variant="body1">
              <span style={{ fontWeight: theme.typography.fontWeightBold }}>
                { comment.user?.firstName }
              </span>
              { " " }
              <span
                style={{
                  color: theme.palette.text.secondary,
                  fontSize: theme.typography.fontSize * 0.9,
                }}
              >
                { commentFormatTime(comment.createdAt, comment.updatedAt) }
              </span>
            </Typography>
          </Box>
          {canEdit && (
            <>
              <IconButton
                onClick={(e) => {
                  setAnchorEl(e.currentTarget);
                }}
                size="small"
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id={`comment-menu-${comment.id}`}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                <MenuItem
                  onClick={() => {
                    setActiveComment(comment);
                    setAnchorEl(null);
                  }}
                  disabled={Boolean(activeComment)}
                >
                  <ListItemIcon>
                    <EditIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="Edit" />
                </MenuItem>
              </Menu>
            </>
          )}
        </Box>
        {!activeComment || activeComment.id !== comment.id ? (
          <Typography variant="body1" style={{ padding: 8 }}>
            {comment.comment}
          </Typography>
        ) : (
          <Box
            padding={2}
          >
            <CommentForm
              processId={processId}
              comment={comment}
              onComment={(newComment) => {
                if (onComment) {
                  onComment({
                    ...activeComment,
                    ...newComment,
                  });
                }
                setActiveComment(null);
              }}
              onCancel={() => setActiveComment(null)}
            />
          </Box>
        )}
      </Card>
    </Box>
  );
}

export default Comment;
