import { Editor } from "slate";
import { ReactEditor } from "slate-react";

export default function autocapitalize(editorInstance: ReactEditor, e: InputEvent) {
  if (/^[A-Za-z]{1}$/.test(e.data)) {
    // check if should autocapitalize
    const text = e.data;
    const { selection } = editorInstance;
    const before = Editor.before(editorInstance, selection, { unit: "character" });
    const char = before && Editor.string(
      editorInstance,
      { anchor: before, focus: selection.focus },
    );
    const before2 = before && { path: before.path, offset: before.offset - 1 };
    const char2 = before2 && Editor.string(
      editorInstance,
      { anchor: before2, focus: before },
    );
    if (
      (char === undefined || char === "\n" || char === "")
      || (char !== undefined && char2 !== undefined && char === " " && char2 === ".")
    ) {
      e.preventDefault();
      editorInstance.insertText(text.toUpperCase());
    }
  }
}
