// Adjustments for MUI v5, including styling with the `styled` function and functional updates.
import React, { useState } from "react";
import { useField } from "react-final-form";
import {
  styled,
  useTheme,
  Slider,
  Input,
  Box,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  fontWeight: theme.typography.fontWeightBold,
  textAlign: "left",
}));

const StyledInput = styled(Input)(({ theme }) => ({
  width: theme.spacing(5),
  color: theme.palette.text.primary,
}));

interface SingleDistributionProps {
  name: string;
  value: number;
  setValue: (num: number) => void;
  id: string;
}

export function SingleDistribution(props: SingleDistributionProps) {
  const {
    name, value, setValue, id,
  } = props;
  const [sliderVal, setSliderVal] = useState(value);
  const [inputVal, setInputVal] = useState(`${value}%`);

  const cleanInput = (stringVal) => {
    let val = stringVal.replaceAll("%", "");
    val = val === "" ? "" : Number(val);
    val = Number.isNaN(val) ? 0 : val;
    return val;
  };

  const handleSliderChange = (_, newValue) => {
    setSliderVal(newValue);
    setInputVal(`${newValue}%`);
    setValue(newValue);
  };

  const handleInputChange = (event) => {
    const eventVal = event.target.value;
    const val = cleanInput(eventVal);
    if (eventVal.indexOf("%") === -1) {
      setSliderVal(val);
      setInputVal(eventVal);
      return;
    }
    setSliderVal(val);
    setInputVal(val === "" ? "" : `${val}%`);
  };

  const handleBlur = () => {
    const val = cleanInput(inputVal);
    if (val < 0 || val === "") {
      setInputVal("0%");
      setSliderVal(0);
      setValue(0);
      return;
    }
    if (val > 100) {
      setInputVal("100%");
      setSliderVal(100);
      setValue(100);
      return;
    }
    setInputVal(`${val}%`);
    setValue(val);
  };

  return (
    <StyledBox sx={{ width: "400px" }}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={6} sm={3}>
          <StyledTypography gutterBottom>{name}</StyledTypography>
        </Grid>
        <Grid item xs={6} sm={3}>
          <StyledInput
            value={inputVal}
            margin="dense"
            onChange={handleInputChange}
            onBlur={handleBlur}
            inputProps={{
              "data-cy": `input-${id}-${name}`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Slider
            value={typeof sliderVal === "number" ? sliderVal : 0}
            step={5}
            min={0}
            max={100}
            onChange={handleSliderChange}
            inputProps={{
              "data-cy": `slider-${id}-${name}`,
            }}
          />
        </Grid>
      </Grid>
    </StyledBox>
  );
}

const DEFAULT_DISTRO = {
  "0x": 0,
  "1x": 0,
  "1-2x": 0,
  "2-3x": 0,
  "3-5x": 0,
  "5-7x": 0,
  "7-10x": 0,
  "10x plus": 0,
};

interface ReturnDistributionProps {
  questionId: string;
  initialValue: string;
  initialComment: string;
}

export default function ReturnDistribution(props: ReturnDistributionProps) {
  const {
    questionId,
    initialValue = JSON.stringify(DEFAULT_DISTRO),
    initialComment = "",
  } = props;
  const theme = useTheme();

  const distroValidate = (value) => {
    const obj = JSON.parse(value);
    const total = Object.values(obj).reduce((acc, curr) => acc + curr, 0);
    return total === 100 ? undefined : "Total must equal 100%";
  };

  const { input: distributionInput, meta: distributionMeta } = useField(
    `${questionId}.answerData`,
    {
      initialValue,
      validate: distroValidate,
    },
  );
  const { input: commentaryInput } = useField(`${questionId}.commentary`, {
    initialValue: initialComment,

  });

  const [distributions, setDistributions] = useState(JSON.parse(initialValue));
  const sum = Object.values(distributions).reduce((acc, curr) => acc + curr, 0);

  const handleChange = (key, val) => {
    const copy = { ...distributions, [key]: val };
    setDistributions(copy);
    distributionInput.onChange(JSON.stringify(copy));
  };

  const getSumColor = () => {
    if (sum === 0) return theme.palette.text.secondary;
    if (sum < 100) return theme.palette.warning.main;
    if (sum > 100) return theme.palette.error.main;
    return theme.palette.success.main;
  };

  return (
    <Grid
      container
      sx={{
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
      }}
    >
      <Grid item xs={12} lg={4}>
        <Typography sx={{ color: theme.palette.error.main }}>
          {distributionMeta.submitFailed && distributionMeta.error}
        </Typography>
        {Object.entries(distributions).map(([key, val]) => (
          <SingleDistribution
            key={key}
            id={questionId}
            name={key}
            value={val}
            setValue={(newPct) => handleChange(key, newPct)}
          />
        ))}
        <Box sx={{ padding: theme.spacing(1, 0) }}>
          <Typography sx={{ fontWeight: "bold", color: getSumColor() }}>
            TOTAL:
            {" "}
            {sum}
            %
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} lg={8}>
        <StyledTypography variant="body2">COMMENTS</StyledTypography>
        <TextField
          {...commentaryInput}
          multiline
          sx={{ width: "100%" }}
          maxRows={5}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
}
