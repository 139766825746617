/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState, useMemo } from "react";
import { useQuery, useQueryClient } from "react-query";
import { Helmet } from "react-helmet";
import { Form, FormSpy } from "react-final-form";
import { useParams, useNavigate, Link } from "react-router-dom";
import { Box, Typography, Button, Card, useTheme, Alert } from "@mui/material";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import {
  getSurveyQuestions,
  patchResponse,
  getResponse,
  getAllAnswers,
} from "@/api/FrameworkSurvey";
import FrameworkQuestion from "@/ui/molecules/FrameworkQuestion";
import Progress from "@/ui/atoms/Progress";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import { useSocket } from "@/hooks/useSocket";
import { useAuth } from "@/hooks/useAuth";
import UserAvatar from "@/ui/atoms/UserAvatar";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import { getSurveyLink } from "@/utils/surveys";

const defaultParam = withDefault(StringParam, null);

function MembersFab({ roomId }) {
  const socket = useSocket();
  const { user } = useAuth();
  const isPWATouch = useIsInTouchPWA();

  const [members, setMembers] = useState([]);
  useEffect(() => {
    socket.emit("join_survey_room", {
      roomId,
      user: {
        id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        profilePicture: user.profilePicture,
      },
    });

    socket.on("update members", (data) => {
      if (data.room === roomId) {
        setMembers(data.members);
      }
    });

    return () => {
      socket.emit("leave_survey_room", roomId);
      socket.off("update members");
    };
  }, [roomId, socket, user]);

  return (
    <Box
      sx={{
        background: (t) => t.palette.primary.main,
        width: "fit-content",
        position: "fixed",
        display: "flex",
        gap: 1,
        bottom: isPWATouch ? 70 : 20,
        right: 120,
        border: (t) => `1px solid ${t.palette.divider}`,
        padding: (t) => t.spacing(1),
        borderRadius: (t) => t.spacing(1),
      }}
    >
      {members.length === 0 && <Progress />}
      {members.map((member) => (
        <Box key={member.id}>
          <UserAvatar displayTooltip user={member} />
        </Box>
      ))}
    </Box>
  );
}

function OrganizationInvestmentFramework({ company }) {
  const { id, frameworkId } = useParams();

  const [responseId, setResponseId] = useQueryParam(
    "responseId",
    defaultParam,
    {
      updateType: "replaceIn",
      removeDefaultsFromUrl: false,
      includeKnownParams: true,
      includeAllParams: true,
    }
  );

  if (!responseId) {
    setResponseId(crypto.randomUUID());
  }

  const navigate = useNavigate();
  const theme = useTheme();
  const contentRef = useRef(null);
  const queryClient = useQueryClient();
  const topRef = useRef();
  const [hasHitSubmit, setHasHitSubmit] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);

  const { isLoading: isLoadingResponse } = useQuery(
    ["FrameworkSurveyResponses", responseId],
    async () => getResponse(responseId),
    {
      onError: (error) => {
        if (error?.response?.status === 403) {
          setHasAccess(false);
        }
      },
      enabled: !!responseId,
      retry: 0,
      refetchInterval: false,
      refetchOnWindowFocus: false,
    }
  );
  const { isLoading: isLoadingAnswers } = useQuery(
    ["InvestmentFrameworkAnswers", responseId],
    async () => getAllAnswers(responseId),
    {
      onSuccess: (data) => {
        if (data.length) {
          data.forEach((answer) => {
            queryClient.setQueryData(
              ["PriorAnswer", responseId, answer.questionId],
              answer,
              {
                staleTime: Infinity,
              }
            );
          });
        }
      },
      enabled: !!responseId,
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
      retry: 0,
      retryDelay: 0,
    }
  );

  const { data: survey = { sections: [] }, isLoading: isLoadingSurvey } =
    useQuery(
      ["InvestmentFramework", frameworkId],
      async () => getSurveyQuestions(frameworkId),
      {
        retry: 1,
        refetchInterval: false,
        refetchOnWindowFocus: false,
      }
    );

  let MAPPER = survey.sections;
  if (survey.groups) {
    MAPPER = survey.groups;
  }

  const submitForm = async () => {
    try {
      const response = await patchResponse(responseId, {
        hidden: false,
        status: "PUBLISHED",
        isReleased: survey.department !== "peer_review",
      });
      queryClient.fetchQuery(["NumTasks"]);
      const removeSurveyResponsePromise = queryClient.removeQueries(
        ["FrameworkSurveyResponses", id],
        { exact: true }
      );
      if (response.status === 200) {
        await removeSurveyResponsePromise;
        navigate(
          getSurveyLink({
            surveyId: frameworkId,
            id: responseId,
            valorId: id,
          })
        );
      }
    } catch (e) {
      console.error(e);
    }
  };

  const handleOnClick = async () => {
    setHasHitSubmit(true);
    await submitForm();
    setHasHitSubmit(false);
  };

  const isLoading = useMemo(
    () => isLoadingResponse || isLoadingAnswers || isLoadingSurvey,
    [isLoadingResponse, isLoadingAnswers, isLoadingSurvey]
  );

  if (!hasAccess) {
    return (
      <Alert severity="error">
        <Typography variant="h3">
          You do not have access to this survey
        </Typography>
        <Typography variant="body1">
          Please contact labs team if that is a mistake
        </Typography>
        <Link to={`/org/${id}/summary`}>
          <Button color="error" variant="contained">
            Go back to summary
          </Button>
        </Link>
      </Alert>
    );
  }

  if (isLoading) {
    return <CenteredProgress isLoading />;
  }

  return (
    <CenteredProgress isLoading={isLoading}>
      <Helmet>
        <title>{`${company.name} - ${survey.name}`}</title>
        <meta property="og:title" content={`${company.name} - vOS Surveys`} />
        <meta
          property="og:description"
          content={`Take a survey for ${company.name}`}
        />
      </Helmet>
      {survey.allowCollaborators ? <MembersFab roomId={responseId} /> : null}
      <Form
        onSubmit={handleOnClick}
        render={({ handleSubmit, submitting }) => (
          <form ref={contentRef} onSubmit={handleSubmit}>
            <FormSpy
              onChange={(state) => {
                const { submitFailed, errors, dirtySinceLastSubmit } = state;
                if (!submitFailed) {
                  return;
                }
                if (!dirtySinceLastSubmit && Object.keys(errors).length) {
                  const els = [
                    ...document.querySelectorAll("[data-formstate]"),
                  ];
                  const firstErrorEl = els.find(
                    (el) => el.getAttribute("data-formstate") === "error"
                  );
                  if (firstErrorEl) {
                    firstErrorEl.scrollIntoView({ behavior: "smooth" });
                  }
                }
              }}
            />
            <Box ref={topRef} display="flex" justifyContent="space-between">
              <Typography variant="h3">
                {survey?.vertical === "after_action_review"
                  ? `${survey?.name}`
                  : `${InvestmentProductMappings[survey?.department]} ${survey?.name}`}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                "& > div:not(:last-child)": {
                  marginBottom: 2,
                },
              }}
            >
              {MAPPER?.map((section) => (
                <Card key={section.name}>
                  <Box
                    sx={{
                      padding: 3,
                      [theme.breakpoints.down("xs")]: {
                        padding: 1,
                      },
                      "& > div:not(:last-child)": {
                        marginBottom: 2,
                      },
                    }}
                  >
                    <Typography
                      variant="h2"
                      sx={{
                        paddingBottom: 1,
                        color: theme.palette.text.secondary,
                      }}
                    >
                      {section.name}
                    </Typography>
                    {section.attributes.map((a) => (
                      <FrameworkQuestion
                        key={a.id}
                        responseId={responseId}
                        valorId={company.valorId}
                        question={a.question}
                        name={a.name}
                        subtitle={a?.subtitle}
                      />
                    ))}
                  </Box>
                </Card>
              ))}
            </Box>
            <Button
              data-cy="submit-survey"
              color="primary"
              variant="contained"
              sx={{
                margin: 2,
              }}
              type="submit"
              disabled={hasHitSubmit || submitting}
            >
              Submit
            </Button>
          </form>
        )}
      />
    </CenteredProgress>
  );
}

export default OrganizationInvestmentFramework;
