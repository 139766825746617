import React, { useRef, useState } from "react";
import {
  Box, Typography, List, ListItem, Button, Stack,
} from "@mui/material";
import { WarningAmberRounded } from "@mui/icons-material";
import { styled, useTheme } from "@mui/material/styles";
import ExplainabilityChip from "@/pages/Org/Explainability/ModelFeatures/ExplainabilityChip";
import { usePopover } from "@/hooks/useGlobalPopover";
import Progress from "@/ui/atoms/Progress";
import Cone from "@/ui/atoms/Icons/Cone";
import {
  canSeeExplainability,
  canGlassMoicScores,
  canSeeMOICChip,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { getSignalExplainability, hideFimoicaSignal } from "@/api/Signals";

const TartarusIcon = styled(WarningAmberRounded)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const ConeIcon = styled(Cone)(({ theme }) => ({
  fill: "transparent",
  color: theme.palette.yellow.main,
}));

interface TartarusChipProps {
  signalId: string;
  score: number;
  showIcon?: boolean;
  showName?: boolean;
  showBorder?: boolean;
}

const TartarusChip: React.FC<TartarusChipProps> = ({
  signalId,
  score,
  showIcon = true,
  showName = true,
  showBorder = true,
}) => {
  const theme = useTheme();
  const divRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const { openPopover, closePopover } = usePopover();
  const { user } = useAuth();
  const couldGlassMoicScores = canGlassMoicScores(user);
  const couldSeeExplainability = canSeeExplainability(user);
  const showChip = canSeeMOICChip(user);

  const isBig = showName && showIcon;

  const SignalBox = styled(Box)(() => ({
    display: "flex",
    border: showBorder ? `1px solid ${theme.palette.divider}` : null,
    flexDirection: isBig ? "column" : "row",
    justifyContent: isBig ? "center" : "flex-start",
    gap: isBig ? null : "8px",
    alignItems: "center",
    padding: theme.spacing(1),
    width: isBig ? "120px" : "96px",
    height: isBig ? "80px" : "40px",
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  }));

  if (!signalId || !showChip) {
    return null;
  }

  function PopoverContent({ records = [] }) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{ gap: "4px", margin: theme.spacing(1) }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: 1,
            mb: 2,
          }}
        >
          {score ? (
            <TartarusIcon
              sx={{
                width: "36px",
                height: "36px",
              }}
            />
          ) : (
            <ConeIcon sx={{ width: "36px", height: "36px" }} />
          )}
          <Typography variant="h3">
            {score ? "Low Return Predicted" : "Low Return Not Predicted"}
          </Typography>
        </Box>
        <Stack>
          <Typography>
            <Typography variant="subtitle2">
              This model is tuned for high precision AND low recall. If it
              predicts a company will return less than 2x, it is usually right
              (high precision) but misses the vast majority of low return
              companies (low recall).
            </Typography>
          </Typography>
          <Typography mt={2} variant="h4">
            The details:
          </Typography>
          <List component="ol">
            <ListItem>
              <Typography variant={score === 1 ? "boldSubtitle2" : "subtitle2"}>
                When predicting a return less than 2x, the model is correct 90%
                of the time in backtesting (high precision).
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant={score === 0 ? "boldSubtitle2" : "subtitle2"}>
                Warning: the model only predicts 10% of low-return companies.
                The vast majority (90%) of less than 2x companies are
                unidentified (low recall).
              </Typography>
            </ListItem>
          </List>

          <Typography variant="h4">How to use the information:</Typography>

          <List component="ol">
            <ListItem>
              <Typography variant={score === 1 ? "boldSubtitle2" : "subtitle2"}>
                If the model makes a prediction of less than 2x, you must prove
                to the IC why the model is wrong.
              </Typography>
            </ListItem>
            <ListItem>
              <Typography variant={score === 0 ? "boldSubtitle2" : "subtitle2"}>
                The absence of a prediction is meaningless. If the model does
                not flag a company, the company could easily still be a loss in
                the fund. Apply the usual rigor.
              </Typography>
            </ListItem>
          </List>

          <Button variant="contained" color="primary" onClick={closePopover}>
            I understand
          </Button>
        </Stack>

        {couldGlassMoicScores && (
          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              color="error"
              onClick={() => hideFimoicaSignal(signalId)}
            >
              Hide Score
            </Button>
          </Box>
        )}

        {couldSeeExplainability && records.length > 0 && (
          <Box sx={{ mt: 3, width: "100%" }}>
            <Typography variant="boldSubtitle2" sx={{ mb: 1 }}>
              Signals:
            </Typography>
            <Box sx={{ display: "inline-flex", flexWrap: "wrap", gap: "8px" }}>
              {records.map(({ signalFeature = {}, modelId, indexId }) => (
                <ExplainabilityChip
                  key={signalFeature.featureFamily}
                  dense
                  modelId={modelId}
                  indexId={indexId}
                  feature={signalFeature}
                />
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <div ref={divRef}>
      <SignalBox
        onClick={async () => {
          setLoading(true);
          try {
            let signalExplain = { records: [] };

            if (score) {
              signalExplain = await getSignalExplainability(signalId);
            }
            openPopover(
              divRef,
              <PopoverContent records={signalExplain.records} />,
              null,
              false,
            );
          } catch (e) {
            console.error("chip render", e);
          } finally {
            setLoading(false);
          }
        }}
      >
        {showName && (
          <Typography sx={{ textAlign: "center" }} variant="boldSubtitle2">
            {score ? "Low Return" : "Low Return"}
          </Typography>
        )}
        {showIcon && (score ? <TartarusIcon /> : <ConeIcon />)}
        {isLoading ? (
          <Progress />
        ) : (
          <Typography variant="boldBody2">
            {score ? "Predicted" : "Not Predicted"}
          </Typography>
        )}
      </SignalBox>
    </div>
  );
};

export default TartarusChip;
