import React, { useState } from "react";
import { Box, TextField, IconButton } from "@mui/material";
import { Send } from "@mui/icons-material";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";

export default function Prompt({ onSubmit }) {
  const [message, setMessage] = useState("");
  const isInTouchPWA = useIsInTouchPWA();
  return (
    <Box>
      <TextField
        multiline
        placeholder="How can Grok help you today?"
        sx={{
          minWidth: "100%",
        }}
        rows={3}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            onSubmit(message);
          }
        }}
        InputProps={{
          endAdornment: isInTouchPWA ? (
            <IconButton
              onClick={() => {
                if (message.trim()) {
                  onSubmit(message);
                  setMessage("");
                }
              }}
            >
              <Send sx={{ color: (t) => t.palette.primary.main }} />
            </IconButton>
          ) : null,
        }}
      />
    </Box>
  );
}
