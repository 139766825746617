import React from "react";
import Box from "@mui/material/Box";

export default function NotPrintable({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={{
        "@media print": {
          display: "none",
        },
      }}
    >
      {children}
    </Box>
  );
}
