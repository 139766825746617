import React from "react";
import { useQuery } from "react-query";
import { getSummariesByOrg } from "@/api/Tegus";
import { getCompanyResponses } from "@/api/FrameworkSurvey";

export default function useSummaries(valorId, limit = 10) {
  // get latest survey from msl
  const {
    data: surveyData,
    isLoading: isSurveyLoading,
    error: surveyError,
  } = useQuery(
    ["survey", valorId],
    async () => getCompanyResponses({
      valorId,
      mostRecentMslOnly: true,
    }),
    {
      enabled: !!valorId,
    },
  );

  // get summaries
  const {
    data,
    isLoading,
    error,
  } = useQuery(
    ["transcriptSummaries", valorId, limit],
    async () => getSummariesByOrg(valorId, limit),
    {
      enabled: !!valorId,
    },
  );

  // copy over pythia response id map
  const pythiaToSurvey = {
    skills_traits_attributes: "bd092e9e-807f-45af-8952-4728888b7615",
    domain_experience_expertise: "6d345e02-5c41-4a46-b5d1-7b9be09412e7",
    resources_process_values: "c3a6c9ff-02d5-48f9-82ec-b75fbaf19eed",
    market_positioning: "a7794e74-a93f-4dca-86af-1e09c8446bfc",
    macro_perspective: "5a0d0042-541b-40e3-8708-ba4f82020046",
    micro_perspective: "5600da9c-cf55-4124-b637-4b3f1a327b3c",
    financials: "3e7fe2bb-4ef1-4a73-9778-af39569b1414",
    risk: "c2ab60e0-b601-4624-9737-8dcbee2bf3e9",
    scalability: "fb97a2c7-b928-4201-ab47-a72a1e877110",
    product_market_fit: "9064634a-55e7-46cb-b513-dfa634a982d4",
    growth_rate: "86e596e7-497f-4d58-b6bb-cf6ebe060e57",
    cash_flow: "a8f30084-cc19-4261-9df7-ae6a462de0dd",
    uniqueness_of_asset: "e1761ca3-e6c8-4e03-96bf-ce2f67b6023b",
  };

  const surveyToPythia = Object.entries(pythiaToSurvey).reduce((acc, [key, value]) => ({
    ...acc,
    [value]: key,
  }), {});

  // get ihe trends per summary
  const iheTrends = React.useMemo<{ [id: string]: { [attr: string]: [string, string] } }>(() => {
    const survey = surveyData?.responses?.[0];
    if (!survey) return {};

    // if survey is +- a year from the transcript
    const isSurveyRelevant = (transcriptDate: Date, surveyDate: Date) => {
      const transcriptYear = transcriptDate.getFullYear();
      const surveyYear = surveyDate.getFullYear();
      return Math.abs(transcriptYear - surveyYear) < 1;
    };

    const transcripts = data?.data || [];
    const relevantTranscripts = transcripts.filter((transcript) => {
      const surveyDate = new Date(survey.completedAt);
      const transcriptDate = transcript.postDate.toDate();
      return isSurveyRelevant(transcriptDate, surveyDate);
    });

    const { answers } = survey;
    if (!answers) return {};

    const surveyMap = Object.entries(answers).reduce((acc, [key, value]) => {
      const promptType = surveyToPythia[key];
      if (!promptType) return acc;
      return {
        ...acc,
        [surveyToPythia[key]]: value.choice.color,
      };
    }, {});

    const transcriptMap = relevantTranscripts.map((transcript) => {
      // create a map of the prompt types
      const promptTypeMap = transcript.scores.reduce((acc, score) => ({
        ...acc,
        [score.promptType]: score.color,
      }), {});
      return {
        id: transcript.id,
        scores: promptTypeMap,
      };
    });

    return relevantTranscripts.reduce((acc, transcript) => {
      // diff the survey map with the transcript map
      const diff = Object.entries(surveyMap).reduce((acc2g, [key, value]) => {
        const transcriptScore = transcriptMap.find((ts) => ts.id === transcript.id);
        if (!transcriptScore) return acc2g;
        const transcriptColor = transcriptScore.scores[key];
        if (!transcriptColor) return acc2g;

        if (value === "yellow" || transcriptColor === "yellow") return acc2g;
        if (value === transcriptColor) return acc2g;
        if (value === "gray" || transcriptColor === "gray") return acc2g;

        return {
          ...acc2g,
          [key]: [value, transcriptColor],
        };
      }, {});

      return {
        ...acc,
        [transcript.id]: diff,
      };
    }, {});
  }, [data, surveyData]);

  return {
    survey: surveyData?.responses?.[0],
    summaries: data?.data,
    isLoading: isLoading || isSurveyLoading,
    iheTrends,
    error: error || surveyError,
  };
}
