import React from "react";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { components } from "@/api/index";

type MatchingOrgsByDomain = components["schemas"]["MatchingOrgsByDomain"];
type SmallOrganization = components["schemas"]["SmallOrganization"];

function renderResultCard(
  org: SmallOrganization,
  added: boolean,
  onAdd: (orgToAdd: SmallOrganization) => void,
) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="flex-start"
      >
        <CompanyAvatar
          sx={{
            width: 40,
          }}
          src={org.logoUrl}
          name={org.name}
          domain={org.domain}
          size="40"
        />
        <Box
          paddingLeft="8px"
        >
          <Typography
            variant="body1"
            style={{
              fontWeight: "bold",
            }}
          >
            {org.name}
          </Typography>
          <Typography variant="subtitle1">{org.domain}</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: "flex-end",
        }}
      >
        <IconButton
          size="medium"
          aria-label="add"
          color="primary"
          onClick={() => onAdd(org)}
          disabled={added}
        >
          <AddIcon fontSize="medium" />
        </IconButton>
      </Box>
    </Box>
  );
}

function renderResults(
  results: MatchingOrgsByDomain[],
  added: string[],
  onAdd: (org: SmallOrganization) => void,
) {
  return results.map((match) => (
    <Box key={match.domain}>
      <Box
        marginY="8px"
        borderBottom="solid 1px #000"
      >
        <Typography variant="h2">{ match.domain }</Typography>
      </Box>
      {
        match.orgs.map((org) => (
          <Box
            key={org.valorId}
            sx={{
              padding: 1,
              borderRadius: 1,
              "& > button": {
                visibility: "hidden",
              },
              "&:hover": {
                backgroundColor: (theme) => theme.palette.primary.highlight,
                "& > button": {
                  visibility: "visible",
                },
              },
            }}
          >
            {renderResultCard(org, added.includes(org.valorId), onAdd)}
          </Box>
        ))
      }
      {
        match.orgs.length === 0 && (
          <Typography variant="subtitle1">
            No organization found for this domain.
          </Typography>
        )
      }
    </Box>
  ));
}

export default function AddDialog({
  results,
  added,
  open,
  onClose,
  onAdd,
}: {
  added: string[],
  results: MatchingOrgsByDomain[],
  open: boolean,
  onClose: () => void,
  onAdd: (org: SmallOrganization) => void,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle>Domain Search Results</DialogTitle>
      <DialogContent>
        {renderResults(results, added, onAdd)}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button
            variant="outlined"
            onClick={onClose}
          >
            Finish
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
