import React from "react";
import { Box, Tooltip, ButtonBase } from "@mui/material";
import { isValidUrl } from "@/utils/general";

type KeyMaterial = {
  id: string;
  attribute: string;
  color: string;
  comment: string;
  Icon: any;
};

export function KeyMaterials({ keyMaterials = [] }: { keyMaterials: KeyMaterial[] }) {
  if (!keyMaterials || !keyMaterials.length) return null;
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1.5}
      flexWrap="wrap"
      alignItems="center"
    >
      {keyMaterials.map((keyMaterial) => (
        <Tooltip
          title={`${keyMaterial.attribute} ${
            keyMaterial.comment ? `: ${keyMaterial.comment}` : ""
          }`}
          key={keyMaterial.id}
        >
          <Box
            display="flex"
            sx={{
              color: (t) => t.palette[keyMaterial.color].main,
              border: (t) => `1px solid ${t.palette[keyMaterial.color].main}`,
              borderRadius: 1,
              cursor: isValidUrl(keyMaterial.comment) ? "pointer" : "default",
            }}
            alignItems="center"
            gap={1}
          >
            <ButtonBase
              onClick={() => window.open(keyMaterial.comment, "_blank")}
              disabled={!isValidUrl(keyMaterial.comment)}
              sx={{ padding: 0.5 }}
            >
              <keyMaterial.Icon size="small" />
            </ButtonBase>
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
}

export default KeyMaterials;
