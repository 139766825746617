import React, { useMemo } from "react";
import { useQuery } from "react-query";
import { useTheme } from "@mui/material/styles";
import { useAuth } from "@/hooks/useAuth";
import {
  Organization,
  getOrganizationInternalFunding,
} from "@/api/Organization";
import Section from "@/ui/atoms/Section";
import { Box, Chip, IconButton } from "@mui/material";
import dayjs from "dayjs";
import { formatBigFundingRoundDollars } from "@/utils/numberFormat";
import MiniTable from "@/components/MiniTable";
import LabeledDetail from "@/components/LabeledDetail";
import { Edit } from "@mui/icons-material";
import { canSeeDeals } from "@/constants/Roles";
import ProcessForm from "@/components/ProcessForm";

interface Props {
  company: Organization;
}

function ActionCell({
  getValue,
  refetch,
}: {
  getValue: () => number;
  refetch: () => void;
}) {
  const id = getValue();
  const [open, setOpen] = React.useState(false);
  return (
    <Box>
      <IconButton
        size="small"
        onClick={() => {
          setOpen(true);
        }}
      >
        <Edit />
      </IconButton>

      <ProcessForm
        open={open}
        processId="deals"
        taskId={id.toString()}
        formType="edit"
        onClose={() => {
          setOpen(false);
          // refetch
          refetch();
        }}
      />
    </Box>
  );
}

export function ValorFunding({ company }: Props) {
  const { valorId } = company;
  const { user } = useAuth();
  const userCanSeeDeals = useMemo(() => canSeeDeals(user), [user]);

  const { data = [], isLoading, refetch } = useQuery(["ValorFunding", valorId], () => getOrganizationInternalFunding(valorId));

  const theme = useTheme();

  const colorCoding = useMemo(
    () => ({
      "New Opportunity": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "Pre Opportunity Assessment": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "IC Opportunity Assessment Approval": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "Considering for Team Sheet": {
        backgroundColor: theme.palette.blue.highlight,
        color: theme.palette.getContrastText(theme.palette.blue.highlight),
      },
      "IC Term Sheet Approval": {
        backgroundColor: theme.palette.teal.highlight,
        color: theme.palette.getContrastText(theme.palette.teal.highlight),
      },
      "Considering for TS": {
        backgroundColor: theme.palette.teal.highlight,
        color: theme.palette.getContrastText(theme.palette.teal.highlight),
      },
      "Term Sheet Approved by IC": {
        backgroundColor: theme.palette.teal.highlight,
        color: theme.palette.getContrastText(theme.palette.teal.highlight),
      },
      "IC Approval to Close": {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.getContrastText(theme.palette.blue.main),
      },
      "IC Approved to Close": {
        backgroundColor: theme.palette.blue.main,
        color: theme.palette.getContrastText(theme.palette.blue.main),
      },
      "Post Closing Items": {
        backgroundColor: theme.palette.green.highlight,
        color: theme.palette.getContrastText(theme.palette.green.highlight),
      },
      "Post Closing Complete": {
        backgroundColor: theme.palette.green.highlight,
        color: theme.palette.getContrastText(theme.palette.green.highlight),
      },
      Pass: {
        backgroundColor: theme.palette.text.disabled,
        color: theme.palette.getContrastText(theme.palette.text.disabled),
      },
      Following: {
        backgroundColor: theme.palette.yellow.main,
        color: theme.palette.getContrastText(theme.palette.yellow.main),
      },
    }),
    [theme],
  );
  const columns = useMemo(
    () => [
      {
        id: "name",
        header: "Deal Name",
        accessorKey: "name",
        width: 200,
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          return val;
        },
      },
      {
        id: "fund",
        header: "Target Fund",
        accessorKey: "targetFund",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          return val;
        },
      },
      {
        id: "type",
        header: "Type",
        accessorKey: "investmentType",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          return val;
        },
      },
      {
        id: "amount",
        header: "Amount",
        accessorKey: "investmentAmount",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }

          return formatBigFundingRoundDollars(val);
        },
        width: 85,
      },
      {
        id: "status",
        header: "Status",
        accessorKey: "stage",
        width: "auto",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }

          return <Chip sx={colorCoding[val]} label={val} />;
        },
      },
      {
        id: "close_date",
        header: "Close Date",
        accessorFn: (row) => {
          const { stage } = row;

          const { passDate } = row;

          const closeDate = row.targetCloseDate;

          if (stage === "Pass" && passDate) {
            return passDate;
          }
          return closeDate;
        },
        width: "auto",
        cell: ({ getValue }) => {
          const val = getValue();
          if (!val) {
            return null;
          }
          return dayjs(val).utc().format("YYYY-MM-DD");
        },
      },
      userCanSeeDeals
        ? {
          id: "actions",
          header: "Edit",
          accessorKey: "taskId",
          width: 40,
          cell: ({ getValue }) => (
            <ActionCell getValue={getValue} refetch={refetch} />
          ),
        }
        : null,
    ].filter((x) => x),
    [colorCoding, refetch, userCanSeeDeals],
  );

  return (
    <Section>
      <Section.Title>Valor Funding</Section.Title>
      <Section.Content>
        <LabeledDetail
          title="VALOR INVESTMENTS"
          expandable={data?.length}
          maxLines={4}
          lineHeight="36px"
        >
          <MiniTable
            isLoading={isLoading}
            data={data}
            columns={columns}
            emptyTable="No Valor Funding Opportunities "
            enableSorting
          />
        </LabeledDetail>
      </Section.Content>
    </Section>
  );
}

export default ValorFunding;
