import React, { useState } from "react";
import {
  Transforms,
} from "slate";
import {
  useSlateStatic,
  ReactEditor,
  useSelected,
  useFocused,
} from "slate-react";
import { Dialog, Skeleton } from "@mui/material";

import { AdvancedDescendant } from "../../types/RichText";
import {
  BlockQuote,
  BulletedList,
  ListItem,
  ListItemText,
  NumberedList,
  Link,
  Image,
  ImagePlaceHolder,
} from "../../types/Element";

function InlineChromiumBugfix() {
  return (
    <span
      contentEditable={false}
      style={{
        fontSize: "0",
      }}
    >
      {String.fromCodePoint(160) /* &nbsp; */}
    </span>
  );
}

function ImagePlaceHolderComponent({ children, ...attributes }) {
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      <Skeleton
        variant="rectangular"
        width="80%"
        height="20em"
      />
      {children}
    </div>
  );
}

function ReadonlyImageComponent({ children, element, ...attributes }) {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      <div
        style={{
          height: 0,
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      <div
        style={{
          position: "relative",
        }}
        onClick={() => setLightboxOpen(true)}
      >
        <img
          src={element.url}
          alt={element.name}
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "20em",
          }}
        />
      </div>
      <Dialog
        open={lightboxOpen}
        onClose={() => setLightboxOpen(false)}
        fullWidth
        maxWidth="lg"
      >
        <img
          src={element.url}
          alt={element.name}
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </Dialog>
    </div>
  );
}

function ImageComponent({ children, element, ...attributes }) {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);

  const selected = useSelected();
  const focused = useFocused();
  return (
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      // select image on click
      onClick={(event) => {
        if (editor) {
          event.preventDefault();
          Transforms.select(editor, path);
        }
      }}
    >
      <div
        style={{
          height: 0,
          overflow: "hidden",
        }}
      >
        {children}
      </div>
      <div
        contentEditable={false}
        style={{
          position: "relative",
        }}
      >
        <img
          src={element.url}
          alt={element.name}
          style={{
            display: "block",
            maxWidth: "100%",
            maxHeight: "20em",
            boxShadow: selected && focused ? "0 0 0 3px #B4D5FF" : "none",
          }}
        />
      </div>
    </div>
  );
}

function LinkComponent({
  element,
  children,
  ...attributes
}: {
  element: AdvancedDescendant,
  attributes: JSX.IntrinsicAttributes,
  children: JSX.Element,
}) {
  const style = { textAlign: element.align };
  return (
    <a
      style={style}
      href={element.url}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      rel="noreferrer"
      target="_blank"
    >
      <InlineChromiumBugfix />
      {children}
      <InlineChromiumBugfix />
    </a>
  );
}

export default function renderElement(element, attributes, children, readonly = false) {
  const style = { textAlign: element.align };
  switch (element.type) {
    case BlockQuote:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case BulletedList:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case ListItem:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case ListItemText:
      // @warning this is a hack to make the list item text work with images
      if (!element.url) {
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <span style={style} {...attributes}>
            {children}
          </span>
        );
      }
      if (readonly) {
        return (
          <ReadonlyImageComponent
            element={element}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes}
          >
            {children}
          </ReadonlyImageComponent>
        );
      }
      return (
        <ImageComponent
          element={element}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
        >
          {children}
        </ImageComponent>
      );
    case Image:
      if (readonly) {
        return (
          <ReadonlyImageComponent
            element={element}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...attributes}
          >
            {children}
          </ReadonlyImageComponent>
        );
      }
      return (
        <ImageComponent
          element={element}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
        >
          {children}
        </ImageComponent>
      );
    case ImagePlaceHolder:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ImagePlaceHolderComponent {...attributes}>
          {children}
        </ImagePlaceHolderComponent>
      );
    case NumberedList:
      return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <ol style={style} {...attributes} start={element.start} type={element.listType}>
          {children}
        </ol>
      );
    case Link:
      return (
        <LinkComponent
          element={element}
          attributes={attributes}
        >
          {children}
        </LinkComponent>
      );
    default:
      return (
        <p
          style={{
            ...style,
            wordWrap: "break-word",
            whiteSpace: "pre-wrap",
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...attributes}
        >
          {children}
        </p>
      );
  }
}
