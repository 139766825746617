// Adjustments for MUI v5, focusing on streamlined styling and functional updates.
import React, { useState, useEffect } from "react";
import { useField } from "react-final-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  textAlign: "left",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-inputMultiline": {
    height: theme.spacing(4),
    fontSize: theme.spacing(3),
  },
}));

interface Estimate {
  personal: string;
  company: string;
}

interface Props {
  questionId: string;
  initialValue: Estimate;
  initialComment: string;
}

const initialState = { personal: "", company: "" };

export default function DollarEstimateBizPersonalPrediction(props: Props) {
  const {
    questionId,
    initialValue = initialState,
    initialComment = "",
  } = props;
  const [notPersonalApplicable, setPersonalNotApplicable] = useState(false);
  const [notCompanyApplicable, setCompanyNotApplicable] = useState(false);
  const [estimates, setEstimates] = useState(initialValue);

  useEffect(() => {
    if (estimates.personal === "N/A") {
      setPersonalNotApplicable(true);
    }
    if (estimates.company === "N/A") {
      setCompanyNotApplicable(true);
    }
  }, [estimates.personal, estimates.company]);

  const validate = (value: string) => {
    const { personal, company } = JSON.parse(value);
    if (!personal || !company) {
      return "Required";
    }
    if (
      (personal !== "N/A" && Number.isNaN(Number(personal)))
      || (company !== "N/A" && Number.isNaN(Number(company)))
    ) {
      return "Invalid Input Numbers / Commas Only unless N/A";
    }
    return undefined;
  };

  const formatVal = (val: string) => {
    if (!val) return "";
    if (val !== "N/A" && !Number.isNaN(Number(val))) {
      return `$${Number(val).toLocaleString("en-US")}`;
    }
    return val;
  };

  const cleanValue = (val) => {
    const parsedValue = val.replaceAll(",", "").replaceAll("$", "");
    if (!parsedValue) {
      return "";
    }
    if (parsedValue === "N/A" || Number.isNaN(Number(parsedValue))) {
      return parsedValue;
    }
    return String(Number(parsedValue));
  };

  const parse = (value: string) => {
    const obj = JSON.parse(value);
    return JSON.stringify({
      personal: cleanValue(obj.personal),
      company: cleanValue(obj.company),
    });
  };

  const { input: estimateInput, meta: estimateMeta } = useField(
    `${questionId}.answerData`,
    {
      initialValue: JSON.stringify(initialValue),
      validate,
      parse,
    },
  );

  const { input: commentaryInput } = useField(`${questionId}.commentary`, {
    initialValue: initialComment,

  });

  const handleChange = (key: string, val: string) => {
    const cleanedVal = cleanValue(val);
    const copy = { ...estimates, [key]: cleanedVal };
    const formattedCopy = { ...estimates, [key]: formatVal(cleanedVal) };
    setEstimates(formattedCopy);
    estimateInput.onChange(JSON.stringify(copy));
  };

  const onCheckChange = (event, setCheckChange, onChange) => {
    const { checked } = event.target;
    setCheckChange(checked);
    if (checked) {
      onChange("N/A");
    } else {
      onChange("");
    }
  };

  return (
    <Grid item xs={12}>
      <StyledTypography variant="body2">
        Numbers and Commas Only, Complete Numbers not in Thousands or Millions
      </StyledTypography>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 900 }} variant="subtitle1">
            Company Forecast
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  data-cy={`${questionId}-company-not-applicable`}
                  checked={notCompanyApplicable}
                  onChange={(event) => onCheckChange(event, setCompanyNotApplicable, (val) => handleChange("company", val))}
                />
              )}
              label="Company Forecast Not Applicable/Available"
            />
          </FormGroup>
          <StyledTextField
            data-cy={`${questionId}-company`}
            value={formatVal(estimates.company)}
            onChange={(event) => handleChange("company", event.target.value)}
            error={!!(estimateMeta.submitFailed && estimateMeta.error)}
            label={estimateMeta.submitFailed ? estimateMeta.error : ""}
            disabled={notCompanyApplicable}
            multiline
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Typography sx={{ fontWeight: 900 }} variant="subtitle1">
            Personal Forecast
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  data-cy={`${questionId}-personal-not-applicable`}
                  checked={notPersonalApplicable}
                  onChange={(event) => onCheckChange(event, setPersonalNotApplicable, (val) => handleChange("personal", val))}
                />
              )}
              label="Personal Forecast Not Applicable/Available"
            />
          </FormGroup>
          <StyledTextField
            data-cy={`${questionId}-personal`}
            value={formatVal(estimates.personal)}
            onChange={(event) => handleChange("personal", event.target.value)}
            error={!!(estimateMeta.submitFailed && estimateMeta.error)}
            label={estimateMeta.submitFailed ? estimateMeta.error : ""}
            disabled={notPersonalApplicable}
            multiline
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <StyledTypography variant="body2" sx={{ height: "42px" }}>
            COMMENTS
          </StyledTypography>
          <StyledTextField
            {...commentaryInput}
            multiline
            variant="outlined"
            onChange={(event) => commentaryInput.onChange(event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
