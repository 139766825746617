import React from "react";
import { Routes, Route } from "react-router-dom";
import Helmet from "react-helmet";
import { useAuth } from "@/hooks/useAuth";
import { canWriteMarketMaps } from "@/constants/Roles";
import MarketMapView from "./MarketMapView";
import MarketMaps from "./MarketMaps";
import MarketMapCreateEdit from "./MarketMapCreateEdit";

function MarketMapRoot() {
  const { user } = useAuth();
  const canEditMaps = canWriteMarketMaps(user);
  return (
    <>
      <Helmet>
        <title>MarketMap - vOS</title>
      </Helmet>
      <Routes>
        <Route path="" element={<MarketMaps />} />
        {canEditMaps ? (
          <Route path="create" element={<MarketMapCreateEdit />} />
        ) : null}
        {canEditMaps ? (
          <Route path="edit/:id" element={<MarketMapCreateEdit />} />
        ) : null}

        <Route path=":slug?/view/:id" element={<MarketMapView />} />
      </Routes>
    </>
  );
}

export default MarketMapRoot;
