/* eslint-disable no-console */
import axios from "axios";
import { searchParams } from "@/utils/general";

export const getUsers = async () => {
  try {
    const response = await axios.get("/api/vpo/users");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getJobs = async (status) => {
  const params = {
    status,
  };

  try {
    const response = await axios.get(`/api/vpo/jobs?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getInternalJobsStats = async (status) => {
  const params = {
    status,
  };

  try {
    const response = await axios.get(`/api/vpo/internal-jobs-stats?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getPortcoJobsStats = async (status) => {
  const params = {
    status,
  };

  try {
    const response = await axios.get(`/api/vpo/portco-jobs-stats?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateRecruitmentStage = (jobId, newRecruitmentStage) => {
  const params = {
    new_recruitment_stage: newRecruitmentStage,
  };

  try {
    return axios.put(`/api/vpo/recruitment-stage/${jobId}?${searchParams(params)}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateLevelOfSupport = (jobId, newLevelOfSupport) => {
  const params = {
    new_level_of_support: newLevelOfSupport,
  };

  return axios.put(`/api/vpo/level-of-support/${jobId}?${searchParams(params)}`);
};

export const updateColor = (jobId, color) => {
  const params = {
    color,
  };

  try {
    return axios.put(`/api/vpo/color/${jobId}?${searchParams(params)}`);
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCompaniesStats = async (status) => {
  const params = {
    status,
  };

  try {
    const response = await axios.get(`/api/vpo/companies-stats?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCandidatePipeline = async () => {
  try {
    const response = await axios.get("/api/vpo/candidate-pipeline");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getApplicationsAggregatedBy = async (groupBy) => {
  try {
    const response = await axios.get(`/api/vpo/applications-aggregated-by/${groupBy}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getSourcesPerJob = async () => {
  try {
    const response = await axios.get("/api/vpo/sources-per-job");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getHiredEmployees = async () => {
  try {
    const response = await axios.get("/api/vpo/hired-employees");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getPriorityCandidates = async (status) => {
  const params = {
    status,
  };

  try {
    const response = await axios.get(`/api/vpo/priority-candidates?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getLastTimeReportsDistributed = async () => {
  try {
    const response = await axios.get("/api/vpo/last-time-reports-distributed");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
// eslint-disable-next-line camelcase
export const sendOutReports = async (override_email_recipient, ccs, reports) => {
  const params = {
    // eslint-disable-next-line camelcase
    override_email_recipient,
    ccs,
    reports,
  };

  try {
    const response = await axios.post(`/api/vpo/send-out-reports?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  getUsers,
  getJobs,
  getInternalJobsStats,
  getPortcoJobsStats,
  updateRecruitmentStage,
  updateLevelOfSupport,
  updateColor,
  getCompaniesStats,
  getCandidatePipeline,
  getApplicationsAggregatedBy,
  getSourcesPerJob,
  getHiredEmployees,
  getPriorityCandidates,
  getLastTimeReportsDistributed,
  sendOutReports,
};
