import React, { useState, useMemo } from "react";
import {
  SortingState,
  ColumnFiltersState,
  VisibilityState,
} from "@tanstack/react-table";
import {
  mapFilters, mapSorting, getNextPageParam,
} from "@/components/InfiniteTable";
import { useInfiniteQuery } from "react-query";
import {
  useQueryParam, withDefault, JsonParam,
} from "use-query-params";
import { getSignalModelPipeline } from "@/api/Reporting";
import { Box } from "@mui/material";
import MantisTable from "./SignalModelFundTable";

export default function SignalModelFund() {
  const TABLE_DEFAULTS = {
    filters: [
      {
        id: "status",
        value: ["Outreach", "Connected", "Access"],
      },
    ],
    sort: [
      { id: "status", desc: false },
      { id: "predictedMoic", desc: true },
    ],
  };

  const DefaultFilterQueryParam = useMemo(() => withDefault(
    JsonParam,
    TABLE_DEFAULTS?.filters || [],
  ), []);
  const DefaultVisibilityQueryParam = useMemo(() => withDefault(
    JsonParam,
    TABLE_DEFAULTS?.visibility || {},
  ), []);
  const DefaultSortingQueryParam = useMemo(() => withDefault(
    JsonParam,
    TABLE_DEFAULTS?.sort || [],
  ), []);

  const [sorting, setSorting] = useQueryParam<SortingState>(
    "sorting",
    DefaultSortingQueryParam,
  );
  const [columnFilters, setColumnFilters] = useQueryParam<ColumnFiltersState>(
    "columnFilters",
    DefaultFilterQueryParam,
  );
  const [globalFilter, setGlobalFilter] = useState<string | null>("");
  const [columnVisibility, setColumnVisibility] = useQueryParam<VisibilityState>(
    "columnVisibility",
    DefaultVisibilityQueryParam,
  );

  const pagedQueryKey = useMemo(() => (
    ["SignalModelPipeline", sorting, columnFilters, globalFilter]
  ), [sorting, columnFilters, globalFilter]);

  const {
    data,
    isLoading,
    isFetching,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: pagedQueryKey,
    queryFn: ({ pageParam = null }) => getSignalModelPipeline(
      50,
      pageParam,
      mapSorting(sorting),
      mapFilters(columnFilters, globalFilter),
    ),
    getNextPageParam,
  });

  const finalData = data;

  return (
    <Box>
      <MantisTable
        data={finalData}
        isLoading={isLoading}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        sorting={sorting}
        columnFilters={columnFilters}
        columnVisibility={columnVisibility}
        globalFilter={globalFilter}
        onSortingChange={setSorting}
        onColumnFiltersChange={setColumnFilters}
        onGlobalFilterChange={setGlobalFilter}
        onColumnVisibilityChange={setColumnVisibility}
      />
    </Box>
  );
}
