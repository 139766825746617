import React from "react";
import { Helmet } from "react-helmet";
import {
  getPermissions,
  grantPermissions as postGrant,
  denyPermissions as postDeny,
  revokePermissions as postRevoke,
} from "@/api/Compliance";
import { useQuery, useQueryClient } from "react-query";
import {
  Link, Box, Typography, Tooltip, Tabs, Tab, Button, Chip,
} from "@mui/material";
import UserCell from "@/components/Table/Primitives/UserCell";

import {
  PrivacyTip,
} from "@mui/icons-material";
import { useQueryParam, withDefault, StringParam } from "use-query-params";
import GrantDenyTable from "../GrantDenyTable";

function generatePermissionsMap(permissionsList) {
  return permissionsList.reduce((acc, permission) => ({
    ...acc,
    [permission.id]: permission,
  }), {});
}

function includeRolesPermissions(role, userPerms) {
  return [
    ...(role?.permissions || []).map((perm) => ({
      ...perm,
      roleName: role.name,
      fromRole: true,
    })),
    ...userPerms,
  ];
}

export default function Permissions() {
  const [activeAttr, setActiveAttr] = useQueryParam("attr", withDefault(StringParam, "All"));
  const [page, setPage] = useQueryParam("page", withDefault(StringParam, "roles"));

  const [filterUsersWithPermissions, setFilterUsersWithPermissions] = React.useState(false);

  // get permissions
  const { data: permissions, isLoading } = useQuery("permissions", getPermissions, {
    refetchOnWindowFocus: false,
    refetchInterval: 0,
    refetchOnMount: false,
  });
  const queryClient = useQueryClient();

  const filterableAttrs = React.useMemo(() => {
    if (!permissions) {
      return null;
    }

    const { base } = permissions.data;

    // get all attrs
    return [
      "All",
      ...([...new Set(base.reduce((acc, permission) => ([
        ...acc,
        ...(permission?.attributes || []),
      ]), []))].toSorted((a, b) => a.localeCompare(b))),
    ];
  }, [permissions?.data?.base]);

  const columns = React.useMemo(() => {
    if (!permissions) {
      return null;
    }

    const { base, users } = permissions.data;

    let filtered = base
      .filter((permission) => {
        if (activeAttr === "All") {
          return true;
        }

        return permission.attributes?.includes(activeAttr);
      })
      .filter((permission) => ![68, 69, 70, 71].includes(permission.id));

    if (page === "users" && filterUsersWithPermissions) {
      // get user permissions
      const userPermissionsSet = new Set(
        users.map(({ permissions: userPerms }) => userPerms.map(({ id }) => id)).flat(),
      );
      filtered = filtered.filter((permission) => userPermissionsSet.has(permission.id));
    }

    const addPermissionToEntity = (type, entityId, permissionId, granted) => (oldData) => {
      const permission = {
        id: permissionId,
        granted,
      };
      return {
        ...oldData,
        data: {
          ...oldData.data,
          roles: (type === "role"
            ? oldData.data.roles.map((role) => {
              if (role.id !== entityId) return role;
              return ({
                ...role,
                permissions: [
                  ...role.permissions.filter((p) => p.id !== permissionId),
                  permission,
                ],
              });
            })
            : oldData.data.roles
          ),
          users: (type === "user"
            ? oldData.data.users.map((user) => {
              if (user.id !== entityId) return user;
              return ({
                ...user,
                permissions: [
                  ...user.permissions.filter((p) => p.id !== permissionId),
                  permission,
                ],
              });
            })
            : oldData.data.users),
        },
      };
    };

    const removePermissionFromEntity = (type, entityId, permissionId) => (
      (oldData) => ({
        ...oldData,
        data: {
          ...oldData.data,
          roles: (type === "role"
            ? oldData.data.roles.map((role) => {
              if (role.id !== entityId) return role;
              return ({
                ...role,
                permissions: role.permissions.filter((p) => p.id !== permissionId),
              });
            })
            : oldData.data.roles
          ),
          users: (type === "user"
            ? oldData.data.users.map((user) => {
              if (user.id !== entityId) return user;
              return ({
                ...user,
                permissions: user.permissions.filter((p) => p.id !== permissionId),
              });
            })
            : oldData.data.users),
        },
      }));

    const grantPermission = async (type, entityId, permissionId) => {
      postGrant({
        userId: type === "user" ? entityId : null,
        roleId: type === "role" ? entityId : null,
        permissionIds: [permissionId],
      });

      // update permissions
      queryClient.setQueryData(
        "permissions",
        addPermissionToEntity(type, entityId, permissionId, true),
      );
    };
    const denyPermission = async (type, entityId, permissionId) => {
      postDeny({
        userId: type === "user" ? entityId : null,
        roleId: type === "role" ? entityId : null,
        permissionIds: [permissionId],
      });

      // update permissions
      queryClient.setQueryData(
        "permissions",
        addPermissionToEntity(type, entityId, permissionId, false),
      );
    };
    const revokePermission = async (type, entityId, permissionId) => {
      postRevoke({
        userId: type === "user" ? entityId : null,
        roleId: type === "role" ? entityId : null,
        permissionIds: [permissionId],
      });

      // update permissions
      queryClient.setQueryData(
        "permissions",
        removePermissionFromEntity(type, entityId, permissionId),
      );
    };

    return [
      {
        header: "Role / User",
        id: "entity",
        accessorFn: (row) => row,
        cell: (prop) => {
          const value = prop.getValue();
          if (value.type === "role") {
            return (
              <Link href={`/compliance-and-permissions/roles?id=${value.id}`}>
                {value.name}
              </Link>
            );
          }
          return (
            <UserCell value={value} />
          );
        },
        minSize: 200,
        enableSorting: false,
        enableColumnFilter: false,
      },
      ...filtered.toSorted((a, b) => a.id - b.id).map((permission) => ({
        header: permission.name
          .replace(/_/g, " ")
          .toLowerCase()
          .replace(/\b\w/g, (c) => c.toUpperCase()),
        headerTooltip: permission.description,
        accessorFn: (row) => row.permissions[permission.id],
        cell: function PermissionCell(prop) {
          const {
            granted,
            fromRole,
            roleName,
          } = prop.getValue() || {
            granted: false,
            fromRole: false,
            roleName: "",
          };

          const {
            id: entityId,
            type,
            permissions: rowPermissions,
          } = prop.row.original;

          const permissionId = permission.id;
          const isDefaultPermission = !rowPermissions?.[permissionId];

          return (
            <Box
              position="relative"
            >
              {(fromRole || (isDefaultPermission && page === "user")) && (
                <Tooltip
                  title={`Inherited from ${roleName}`}
                >
                  <PrivacyTip
                    fontSize="small"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: 0,
                    }}
                  />
                </Tooltip>
              )}
              <div
                style={{
                  display: "flex",
                  gap: 8,
                }}
              >
                <input
                  type="radio"
                  id={`action-${type}-${entityId}-${permissionId}-granted`}
                  name={`action-${type}-${entityId}-${permissionId}`}
                  value="granted"
                  checked={granted}
                  onChange={() => grantPermission(type, entityId, permissionId)}
                  style={{ cursor: "pointer" }}
                />
                <label
                  style={{
                    fontWeight: granted ? "bold" : "normal",
                    color: granted ? "green" : "black",
                    cursor: "pointer",
                  }}
                  htmlFor={`action-${type}-${entityId}-${permissionId}-granted`}
                >
                  Granted
                </label>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: 8,
                }}
              >
                <input
                  type="radio"
                  id={`action-${type}-${entityId}-${permissionId}-denied`}
                  name={`action-${type}-${entityId}-${permissionId}`}
                  value="denied"
                  checked={!granted}
                  onChange={() => denyPermission(type, entityId, permissionId)}
                  style={{ cursor: "pointer" }}
                />
                <label
                  style={{
                    fontWeight: !granted ? "bold" : "normal",
                    color: !granted ? "red" : "black",
                    cursor: "pointer",
                  }}
                  htmlFor={`action-${type}-${entityId}-${permissionId}-denied`}
                >
                  Denied
                </label>
              </div>

              {type === "user" && (
                <Button size="small" fullWidth onClick={() => revokePermission(type, entityId, permissionId)}>
                  Revoke
                </Button>
              )}
            </Box>
          );
        },
        size: 180,
        enableSorting: false,
        enableColumnFilter: false,
      })),
    ];
  }, [permissions?.data?.base, queryClient, activeAttr, page, filterUsersWithPermissions]);

  const roleRows = React.useMemo(() => {
    if (!permissions) {
      return null;
    }

    return permissions.data.roles.toSorted((a, b) => a.name.localeCompare(b.name))
      .map((role) => ({
        ...role,
        permissions: generatePermissionsMap(role.permissions) || {},
      }));
  }, [permissions?.data?.roles, generatePermissionsMap]);

  const userRows = React.useMemo(() => {
    if (!permissions) {
      return null;
    }

    const { roles, users } = permissions.data;

    let filteredUsers = users;
    if (filterUsersWithPermissions) {
      filteredUsers = users
        .filter((user) => user.permissions.length > 0);
    }

    return filteredUsers.toSorted((a, b) => a.name.localeCompare(b.name))
      .map((user) => ({
        ...user,
        permissions: generatePermissionsMap(
          includeRolesPermissions(
            roles.find((role) => role.id === user.roleId),
            user.permissions,
          ),
        ) || {},
      }));
  }, [
    permissions?.data?.roles,
    permissions?.data?.users,
    generatePermissionsMap,
    includeRolesPermissions,
    filterUsersWithPermissions,
  ]);

  return (
    <div>
      <Helmet>
        <title>Compliance - Permissions</title>
      </Helmet>

      <Typography variant="h1">Permissions</Typography>

      <Tabs
        value={page}
        onChange={(e, newValue) => {
          setPage(newValue);
        }}
      >
        <Tab
          label="Roles"
          value="roles"
        />
        <Tab
          label="Users"
          value="users"
        />
      </Tabs>

      {!isLoading && page === "users" && (
        <Box>
          <Button
            onClick={() => setFilterUsersWithPermissions((prev) => !prev)}
          >
            {filterUsersWithPermissions ? "Show all users" : "Show users with non-role permissions"}
          </Button>
        </Box>
      )}

      <Box
        display="flex"
        gap={1}
        flexWrap="wrap"
        my={1}
      >
        {filterableAttrs?.map((attr) => (
          <Chip
            key={attr}
            label={attr}
            color={attr === activeAttr ? "primary" : "default"}
            onClick={() => {
              setActiveAttr(attr);
            }}
          />
        ))}
      </Box>

      {!isLoading && page === "roles" && (
        <GrantDenyTable
          columns={columns}
          rows={roleRows}
        />
      )}

      {!isLoading && page === "users" && (
        <GrantDenyTable
          columns={columns}
          rows={userRows}
        />
      )}
    </div>
  );
}
