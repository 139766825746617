import { Transforms } from "slate";
import { ReactEditor } from "slate-react";
import { jsx } from "slate-hyperscript";
import {
  Link,
  Image,
  Paragraph,
  ListItem,
  ListItemText,
  NumberedList,
  BulletedList,
  BlockQuote,
} from "../types/Element";

const ELEMENT_TAGS = {
  A: (el) => ({ type: Link, url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: BlockQuote }),
  H1: () => ({ type: Paragraph }),
  H2: () => ({ type: Paragraph }),
  H3: () => ({ type: Paragraph }),
  H4: () => ({ type: Paragraph }),
  H5: () => ({ type: Paragraph }),
  H6: () => ({ type: Paragraph }),
  IMG: (el) => ({ type: Image, url: el.getAttribute("src") }),
  LI: () => ({ type: ListItem }),
  OL: () => ({ type: NumberedList }),
  P: () => ({ type: Paragraph }),
  PRE: () => ({ type: Paragraph }),
  UL: () => ({ type: BulletedList }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

const toTextNode = (attrs, ...children) => {
  // safely convert stuff to text
  // broken formatting is better than nothing
  try {
    return jsx("text", attrs, children);
  } catch (e) {
    console.error(e, attrs, children);
    return jsx("text", attrs, "<!!!>");
  }
};

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  }
  if (el.nodeType !== 1) {
    return null;
  }
  if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === "PRE"
    && el.childNodes[0]
    && el.childNodes[0].nodeName === "CODE"
  ) {
    [parent] = el.childNodes;
  }

  if (!parent.childNodes || Array.from(parent.childNodes).length === 0) {
    return null;
  }

  let children = Array.from(parent.childNodes)
    .map(deserialize)
    .flat();

  if (children.length === 0) {
    children = [{ text: "" }];
  }

  if (el.nodeName === "BODY") {
    children = children.filter((child) => (
      child && (child.type || (child.text && child.text !== ""))
    ));
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);

    if (nodeName === "OL") {
      attrs.start = el.start;
      attrs.listType = el.type;
    }
    return jsx("element", attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => toTextNode(attrs, child));
  }

  if (nodeName === "SPAN") {
    const styleMap = {
      color: "color",
      background: "backgroundColor",
    };
    const attrs = Object.keys(styleMap).reduce((acc, key) => ({
      ...acc,
      ...(el.style.getPropertyValue(key) && { [styleMap[key]]: el.style.getPropertyValue(key) }),
    }), {});
    return children.map((child) => toTextNode(attrs, child));
  }

  return children;
};

const fixMsoLists = (body: HTMLBodyElement) => {
  // if there is a P.MsoListParagraph between two OLs, its a depth 3 list
  // loop through top level elements, if we find a P.MsoListParagraph, we need to
  // find the previous OL and add it as a child of that OL
  let i = 0;
  while (i < body.childNodes.length) {
    const child = body.childNodes[i];
    if (child.nodeName === "P" && (child as HTMLParagraphElement).classList.contains("MsoListParagraph")) {
      let { previousSibling } = child;
      // get previous sibling that is not text
      while (previousSibling && previousSibling.nodeName === "#text") {
        previousSibling = previousSibling.previousSibling;
      }

      if (previousSibling && previousSibling.nodeName === "OL") {
        let list: HTMLElement = previousSibling as HTMLOListElement;

        // get the deepest OL up to 3 levels deep
        let depth = 1;
        while (list.lastElementChild && list.lastElementChild.nodeName === "OL" && depth <= 3) {
          if (list.lastElementChild.nodeName === "OL") {
            list = list.lastElementChild as HTMLOListElement;
            depth += 1;
          } else {
            break;
          }
        }

        // mso list paragraphs are always depth 3
        while (depth < 3) {
          const newList = document.createElement("ol");
          list.appendChild(newList);
          list = newList;
          depth += 1;
        }

        const listItem = document.createElement("li");
        const listItemText = document.createElement("p");
        listItemText.innerHTML = child.innerHTML;

        // remove garbage from the beginning of the text,
        // that is supposed to make it look like a list
        let cleanHTML = listItemText.innerHTML.split("<!--[if !supportLists]-->");
        if (cleanHTML.length > 1) {
          cleanHTML = cleanHTML[1].split("<!--[endif]-->");
          if (cleanHTML.length > 1) {
            // eslint-disable-next-line prefer-destructuring
            listItemText.innerHTML = cleanHTML[1];
          }
        }

        listItem.appendChild(listItemText);
        list.appendChild(listItem);
        body.removeChild(child);
      }
    }
    i += 1;
  }

  // for all OLs that are side by side, we should combine them into one OL
  let j = 0;
  while (j < body.childNodes.length) {
    const child = body.childNodes[j];
    if (child.nodeName === "OL") {
      const listElement = child as HTMLOListElement;
      const { nextElementSibling } = listElement;

      // get all child nodes and add to current list element
      const children = nextElementSibling.childNodes;
      listElement.append(...children);

      body.removeChild(nextElementSibling);
    }
    j += 1;
  }

  // @warning: the above doesn't recursively consolidate lists
  // so if you join two lists there is the chance there will be empty list items
  // that need to be removed manually, however the indentation and list type ("1ai") will
  // be correct

  return body;
};

const fixLists = (fragment, parent = null) => {
  if (!fragment) return null;

  let previousSibling = null;
  const newFragment = fragment.reduce((acc, node) => {
    if (!node) return acc;
    if (acc.length !== 0) {
      previousSibling = acc[acc.length - 1];
    }

    if (parent && node.type === NumberedList && parent.type === NumberedList) {
      if (previousSibling && previousSibling.type === ListItem) {
        previousSibling.children.push(
          {
            ...node,
            children: [
              ...fixLists(node.children, node),
            ],
          },
        );
        return acc;
      }

      return [
        ...acc,
        {
          type: ListItem,
          children: [
            { type: ListItemText, children: [{ text: "" }] },
            {
              ...node,
              children: [
                ...fixLists(node.children, node),
              ],
            },
          ],
        },
      ];
    }

    return [
      ...acc,
      {
        ...node,
        children: fixLists(node.children, node),
      },
    ];
  }, []);
  return newFragment;
};

const fixSpaces = (fragment) => {
  if (!fragment) return null;
  return fragment.map((node) => {
    if (!node) return null;
    if (!node?.text) {
      const newChildren = fixSpaces(node.children);
      return {
        ...node,
        ...(!!newChildren && { children: newChildren }),
      };
    }

    const newText = node.text?.replace(/ +/g, " ");
    if (newText === " ") {
      return null;
    }
    return {
      ...node,
      text: newText,
    };
  }).filter((node) => node);
};

export default (editor: ReactEditor) => {
  const { insertData, isInline, isVoid } = editor;

  // eslint-disable-next-line no-param-reassign
  editor.isInline = (element) => (element.type === Link ? true : isInline(element));

  // eslint-disable-next-line no-param-reassign
  editor.isVoid = (element) => (element.type === Image ? true : isVoid(element));

  // eslint-disable-next-line no-param-reassign
  editor.insertData = (data) => {
    const html = data.getData("text/html");

    if (html) {
      const noNewLines = html.replace(/\r?\n/g, " ").replace("  ", " ");
      const parsed = new DOMParser()
        .parseFromString(noNewLines, "text/html");

      const msoListed = fixMsoLists(parsed.body);

      const fragment = fixLists(fixSpaces(deserialize(msoListed)));
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};
