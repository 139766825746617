import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Modal, Box, Typography, Button,
} from "@mui/material";
import SearchBar from "@/ui/molecules/SearchBar";
import { saytForOrg } from "@/api/Search";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";

const surveys = [
  {
    id: "0d4772ae-d656-4763-bda6-ff5b4e941354",
    name: "Opportunity Assessment",
    department: "platform",
  },
  {
    id: "bedfb774-3086-5cb8-9a0e-c4050cef5fd3",
    name: "Opportunity Assessment",
    department: "vsv",
  },
  {
    id: "b7050dec-791a-46a5-b206-fa18b7a05498",
    name: "Opportunity Assessment",
    department: "ancillary",
  },
  {
    id: "89c5be86-b6f3-4756-a4af-6fb997ef052f",
    name: "Opportunity Assessment",
    department: "vsv",
  },
  {
    id: "35f42e38-7cf0-47f0-8dc6-0018e8ddf62c",
    name: "Opportunity Assessment",
    department: "seed",
  },
];

function TakeSurveyModal({ open, onClose }) {
  const navigate = useNavigate();
  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleSelectCompany = (company) => {
    if (company && company.valorId) {
      setSelectedCompany(company);
    }
  };

  const handleSelectSurvey = (survey) => {
    if (selectedCompany && survey) {
      navigate(`/org/${selectedCompany.valorId}/surveys/${survey.id}`);
      onClose();
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { xs: "100vw", sm: 600 },
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        {!selectedCompany ? (
          <>
            <Typography variant="h6">
              Search for a Company to take an Opportunity Assessment
            </Typography>
            <SearchBar
              autoFocus
              showSAYT
              selectTerm={handleSelectCompany}
              entity="company"
              search={saytForOrg}
              placeholder="Search..."
            />
          </>
        ) : (
          <>
            <Typography variant="h6">
              Select a survey for
              {" "}
              {selectedCompany.name}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
              {surveys.map((survey) => (
                <Button
                  key={survey.id}
                  variant="outlined"
                  sx={{ mb: 1 }}
                  onClick={() => handleSelectSurvey(survey)}
                >
                  {`${survey.name} (${
                    InvestmentProductMappings[survey.department]
                  })`}
                </Button>
              ))}
            </Box>
          </>
        )}
      </Box>
    </Modal>
  );
}

export default TakeSurveyModal;
