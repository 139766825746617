const ProcessesMapping = {
  "vsv-revops": "VSV Rev OPS",
  legal: "Legal Project Tracker",
  finance: "Finance – Management",
  it: "IT",
  leangrowth: "Lean / Growth",
  research: "Research",
  dev: "Developer Project",
  "rev-gen": "Growth Pipeline",
  portfolio: "Valor Portfolio",
  itceo: "IT CEO / CIO",
  funds: "Funds (Active/In Consideration)",
  deals: "Investment Process",
  "lp-ab": "LP/AB Meetings & Consents",
};

export default ProcessesMapping;