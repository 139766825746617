import Boolean from "./BooleanCell";
import Chip from "./ChipCell";
import Company from "./CompanyCell";
import Date from "./DateCell";
import DisclaimerMoic from "./DisclaimerMoicCell";
import KPI from "./KPICell";
import Location from "./LocationCell";
import Moic from "./MoicCell";
import Money from "./MoneyCell";
import MultiUser from "./MultiUserCell";
import OpportunityNote from "./OpportunityNoteCell";
import PredictedMoic from "./PredictedMoicCell";
import RelativeDate from "./RelativeDateCell";
import Text from "./TextCell";
import User from "./UserCell";
import Person from "./PersonCell";
import MultiPerson from "./MultiPersonCell";

export const BooleanCell = Boolean;
export const ChipCell = Chip;
export const CompanyCell = Company;
export const DateCell = Date;
export const DisclaimerMoicCell = DisclaimerMoic;
export const KPICell = KPI;
export const LocationCell = Location;
export const MoicCell = Moic;
export const MoneyCell = Money;
export const MultiUserCell = MultiUser;
export const OpportunityNoteCell = OpportunityNote;
export const PredictedMoicCell = PredictedMoic;
export const RelativeDateCell = RelativeDate;
export const TextCell = Text;
export const UserCell = User;
export const PersonCell = Person;
export const MultiPersonCell = MultiPerson;
