import React, { useState } from "react";

import {
  Badge,
  Box,
  Button,
  ButtonBase,
  Link,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { canSeeExplainability } from "@/constants/Roles";
import { styled, useTheme } from "@mui/material/styles";
import {
  EditNote, Poll, LibraryAdd,
} from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { doesUserFollowCompany, updateFollowing } from "@/api/Follow";
import { Organization, getUserOrgView, getPortfolioCount } from "@/api/Organization";
import { useAuth } from "@/hooks/useAuth";
import AffinityModal from "@/components/AffinityModal";
import NotPrintable from "@/components/NotPrintable";
import ResourceLinks from "./Links";
import CRMDropdown from "./CRMDropdown";

interface OrganizationHeaderProps {
  company: Organization,
  toggleSurveyDrawer: () => void,
}

const RootBox = styled(Box)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(3),
  display: "flex",
  justifyContent: "space-between",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "flex-start",
    height: "96px",
    padding: theme.spacing(1),
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
  },
}));

const FollowButton = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(0.5),
  height: theme.spacing(3),
  color: theme.palette.primary.main,
  "&:hover": {
    background: theme.palette.background.blue,
  },
  [theme.breakpoints.up("md")]: {
    marginLeft: theme.spacing(1),
  },

}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 700,
  "&  > div > div": {
    gap: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      gap: theme.spacing(2),
    },
  },
  "& > div > div > button": {
    overflow: "visible",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  "&  .Mui-selected": {
    fontWeight: 700,
  },
}));

const UnfollowButton = styled(FollowButton)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.red.main,
    background: theme.palette.red.highlight,
  },
}));

const SectionSelect = styled(Select)(({ theme }) => ({
  width: "100%",
  height: theme.spacing(8),
  fontWeight: 700,
  boxShadow: theme.shadows[3],
  background: theme.palette.background.paper,
  color: theme.palette.primary.main,
  "&:hover": {
    border: "none",
  },
  "& > svg": {
    top: "inherit",
    width: "2em",
    height: "2em",
    fill: theme.palette.primary.main,
  },
}));

const InlineBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    left: "100%",
    marginLeft: 15,
  },
}));

function OrganizationHeader({ company, toggleSurveyDrawer }: OrganizationHeaderProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const {
    valorId, name, logoUrl, domain,
  } = company;
  const {
    user,
  } = useAuth();
  const userId = user?.id;
  const isSmDown = useMediaQuery((t) => t.breakpoints.down("sm"), {
    noSsr: true,
  });

  const params = useParams();
  const section = params["*"]?.split("/")[0] || "summary";
  const [affModalOpen, setAffModalOpen] = useState(false);

  const queryClient = useQueryClient();

  const { data: isFollowing, isLoading: isLoadingFollowing } = useQuery(
    ["isFollowingOrg", valorId],
    async () => {
      try {
        const response = await doesUserFollowCompany(userId, valorId);
        return Boolean(response);
      } catch (e) {
        throw new Error(e);
      }
    },
    {
      retry: 1,
      retryOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  );

  const {
    data: userViews = null,
  } = useQuery(
    ["userViews", company.valorId],
    () => getUserOrgView(company.valorId),
  );

  const {
    data: portfolioCount,
    isLoading: isPortfolioLoading,
  } = useQuery(
    ["hasPortfolio", company.valorId],
    async () => getPortfolioCount(company.valorId),
  );
  const hasPortfolio = portfolioCount > 0;

  if (!name) {
    // Temp
    return null;
  }

  function renderButtonTabs() {
    const tabData: {
      id: string;
      label: string;
      disabled?: boolean;
      badge?: number;
    }[] = [
      { id: "summary", label: "SUMMARY" },
      // { id: "crm", label: "CRM" },
      { id: "documents", label: "DOCUMENTS", badge: userViews?.documents },
      {
        id: "financials",
        label: "FINANCIALS",
        badge: userViews?.financials,
      },
    ];

    // add if for if a company has feature
    if (canSeeExplainability(user)) {
      tabData.push({ id: "explainability", label: "MODEL" });
    }

    tabData.push(
      { id: "notes", label: "NOTES", badge: userViews?.notes },
      { id: "transcripts", label: "TRANSCRIPTS", badge: userViews?.transcripts },
      { id: "contacts", label: "CONTACTS" },
      { id: "public-data", label: "PUBLIC DATA" },
      { id: "competitors", label: "COMPETITORS", badge: userViews?.competitors },
    );

    if (!isPortfolioLoading && hasPortfolio) {
      tabData.push({
        id: "portfolio",
        label: "PORTFOLIO",
        disabled: isPortfolioLoading,
      });
    }

    const tabs = tabData.map(({
      id, label, badge, disabled,
    }) => (
      <Tab
        key={id}
        value={id}
        label={(
          <InlineBadge
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            badgeContent={badge}
            color="primary"
          >
            {label}
          </InlineBadge>
        )}
        disabled={disabled}
      />
    ));

    if (isSmDown) {
      return (
        <SectionSelect
          value={section || "summary"}
          defaultValue="summary"
          onChange={(e: {target: {value: string}}) => {
            navigate(`/org/${valorId}/${e.target.value}`);
          }}
        >
          {tabData.map(({
            id, label, badge,
          }) => (
            badge ? (
              <MenuItem key={id} value={id}>
                <InlineBadge
                  anchorOrigin={{ vertical: "top", horizontal: "left" }}
                  badgeContent={badge}
                  color="primary"
                >
                  {label}
                </InlineBadge>
              </MenuItem>
            ) : (
              <MenuItem value={id} key={id}>
                {label}
              </MenuItem>
            )
          ))}
        </SectionSelect>

      );
    }

    return (
      <StyledTabs
        value={section || "summary"}
        variant="scrollable"
        scrollButtons="auto"
        onChange={(_: React.SyntheticEvent, v: string) => {
          navigate(`/org/${valorId}/${v}`);
        }}
      >
        {tabs}
      </StyledTabs>
    );
  }

  function renderFollow(isFollow, isLoading) {
    if (isLoading) {
      return (
        <ButtonBase disabled={isLoading} />
      );
    }
    if (isFollow) {
      return (
        <UnfollowButton
          onClick={() => {
            updateFollowing(userId, valorId, isFollow);
            queryClient.setQueryData(["isFollowingOrg", valorId], () => !isFollow);
          }}
        >
          Following
        </UnfollowButton>
      );
    }
    return (
      <FollowButton
        onClick={() => {
          updateFollowing(userId, valorId, isFollow);
          queryClient.setQueryData(["isFollowingOrg", valorId], () => !isFollow);
        }}
      >
        Follow
        <LibraryAdd />
      </FollowButton>
    );
  }

  if (isSmDown) {
    return (
      <NotPrintable>
        <Box
          mb={2}
        >
          <RootBox>
            <Box onClick={async () => navigator.clipboard.writeText(company.valorId)}>
              <CompanyAvatar src={logoUrl} name={name} domain={domain} size={52} />
            </Box>
            <Box ml={2} display="flex" flexDirection="column" alignItems="flex-start">
              <Typography sx={{ color: theme.palette.text.primary }} variant="h3">{company.name}</Typography>
              <Box
                display="flex"
              >
                <Link
                  href={`https://${domain}`}
                  sx={{ textDecoration: "none" }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{ color: theme.palette.text.secondary }}
                    variant="body1"
                  >
                    {domain}
                  </Typography>
                </Link>
              </Box>
              {renderFollow(isFollowing, isLoadingFollowing)}
            </Box>
          </RootBox>
          <Box mt={1} mb={1.5} display="flex" flexDirection="column" sx={{ gap: theme.spacing(1) }}>
            <Button
              onClick={() => toggleSurveyDrawer()}
              sx={{ padding: theme.spacing(1.5) }}
              data-cy="take-survey-btn"
              variant="contained"
              color="primary"
              endIcon={<Poll />}
            >
              Take Survey
            </Button>
            <Box display="flex" sx={{ gap: "8px" }}>
              <Button
                sx={{ width: "50%", padding: theme.spacing(0.5), backgroundColor: theme.palette.background.paper }}
                variant="outlined"
                onClick={() => navigate(`/org/${company.valorId}/notes/new`)}
                color="primary"
                endIcon={<EditNote />}
              >
                Write Note
              </Button>
            </Box>
          </Box>
          {renderButtonTabs()}
        </Box>
      </NotPrintable>
    );
  }
  return (
    <NotPrintable>
      <Box
        mb={1}
      >
        <RootBox>
          <Box display="flex">
            <Box onClick={async () => navigator.clipboard.writeText(company.valorId)}>
              <CompanyAvatar src={logoUrl} name={name} domain={domain} size={100} />
            </Box>
            <Box ml={4} display="flex" flexDirection="column" alignItems="flex-start">
              <Typography sx={{ color: theme.palette.text.primary }} variant="h3">{company.name}</Typography>
              <Box
                mt={1}
                mb={1}
                display="flex"
              >
                <Link
                  href={`https://${domain}`}
                  sx={{
                    textDecoration: "none",
                    "&hover": {
                      textDecoration: "underline",
                    },
                  }}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Typography
                    sx={{
                      color: theme.palette.text.secondary,
                      "&:hover": {
                        textDecoration: "underline",
                      },
                    }}
                    variant="body1"
                  >
                    {domain}
                  </Typography>
                </Link>
                {renderFollow(isFollowing, isLoadingFollowing)}
              </Box>
              <ResourceLinks
                linkedin={company.linkedinUrl}
                crunchbase={company.cbId}
                pitchbook={company.pbId}
                affinity={company.affId}
                synaptic={company.syId}
                diffbot={company.dfId}
              />
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ width: "180px", gap: "8px" }}>
            <Button
              onClick={() => toggleSurveyDrawer()}
              data-cy="take-survey-btn"
              sx={{ padding: 1.5 }}
              variant="contained"
              color="primary"
              endIcon={<Poll />}
            >
              Take Survey
            </Button>
            <Button
              sx={{
                padding: 0.5,
                backgroundColor: theme.palette.background.paper,
              }}
              variant="outlined"
              onClick={() => navigate(`/org/${company.valorId}/notes/new`)}
              color="primary"
              endIcon={<EditNote />}
            >
              Write Note
            </Button>
            <CRMDropdown
              company={company}
            />
          </Box>
        </RootBox>

        {renderButtonTabs()}

        <AffinityModal
          open={affModalOpen}
          onClose={() => setAffModalOpen(false)}
          name={name}
          domain={domain}
          valorId={valorId}
        />
      </Box>
    </NotPrintable>
  );
}

export default OrganizationHeader;
