import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useSwipeable } from "react-swipeable";
import CloseIcon from "@material-ui/icons/Close";

// TODO may need to add a prop in the future to add
// touchAction property to prevent scrolling while swiping.
const useStyles = makeStyles((theme) => ({
  container: ({ translateX, leftColor, rightColor }) => ({
    display: "flex",
    position: "relative",
    backgroundColor:
      translateX !== 0
        ? Math.sign(translateX) === 1
          ? theme.palette[leftColor].highlight
          : theme.palette[rightColor].highlight
        : "transparent",
  }),
  children: ({ translateX }) => ({
    zIndex: 100,
    transform: `translateX(${translateX}px)`,
    flex: 1,
  }),
  leftDismissable: {
    zIndex: 0,
    position: "absolute",
    marginLeft: theme.spacing(2),
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  leftIcon: ({ leftColor }) => ({
    color: theme.palette[leftColor].main,
  }),
  rightDismissable: {
    zIndex: 0,
    right: 0,
    marginRight: theme.spacing(2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  rightIcon: ({ rightColor }) => ({
    color: theme.palette[rightColor].main,
  }),
}));

const DIRECTIONS = {
  LEFT: "left",
  RIGHT: "right",
  OMNI: "omni",
};

const MIN_EFFECT_SWIPE_DISTANCE = 128;

const MIN_SWIPE_DISTANCE = 24;

export default function SwipeX({
  children,
  onLeft,
  LeftIcon,
  leftColor,
  onRight,
  RightIcon,
  rightColor,
}) {
  const [translateX, setTranslateX] = useState(0);
  const Direction = (() => {
    if (onLeft && onRight) return DIRECTIONS.OMNI;
    if (onLeft) return DIRECTIONS.LEFT;
    return DIRECTIONS.RIGHT;
  })();

  const classes = useStyles({ translateX, leftColor, rightColor });
  const handleSwiping = ({ deltaX }) => {
    if (
      Direction === DIRECTIONS.OMNI
      || (Direction === DIRECTIONS.LEFT && deltaX < MIN_SWIPE_DISTANCE * -1)
      || (Direction === DIRECTIONS.RIGHT && deltaX > MIN_SWIPE_DISTANCE)
    ) {
      setTranslateX(deltaX);
    }
  };

  const handleSwiped = ({ deltaX }) => {
    if (
      deltaX > MIN_EFFECT_SWIPE_DISTANCE
      && (Direction === DIRECTIONS.RIGHT || Direction === DIRECTIONS.OMNI)
    ) {
      onRight();
    } else if (
      deltaX < -1 * MIN_EFFECT_SWIPE_DISTANCE
      && (Direction === DIRECTIONS.LEFT || Direction === DIRECTIONS.OMNI)
    ) {
      onLeft();
    }
  };

  const handlers = useSwipeable({
    onSwiping: handleSwiping,
    onSwiped: () => setTranslateX(0),
    onSwipedRight: handleSwiped,
    onSwipedLeft: handleSwiped,
    preventDefaultTouchmoveEvent: true,
    delta: 32,
  });

  return (
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    <div {...handlers} className={classes.container}>
      {Math.sign(translateX) === 1 && (
        <div className={classes.leftDismissable}>
          <LeftIcon className={classes.leftIcon} />
        </div>
      )}
      <div className={classes.children}>{children}</div>
      {Math.sign(translateX) === -1 && (
        <div className={classes.rightDismissable}>
          <RightIcon className={classes.rightIcon} />
        </div>
      )}
    </div>
  );
}

SwipeX.propTypes = {
  LeftIcon: PropTypes.object,
  RightIcon: PropTypes.object,
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
  onLeft: PropTypes.func,
  onRight: PropTypes.func,
  children: PropTypes.node.isRequired,
};

SwipeX.defaultProps = {
  LeftIcon: CloseIcon,
  RightIcon: CloseIcon,
  leftColor: "green",
  rightColor: "green",
  onLeft: null,
  onRight: null,
};
