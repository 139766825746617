import React, { useMemo } from "react";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { searchPersons } from "@/api/People";
import { TextCell } from "@/components/Table/Primitives";
import { MiniTable } from "@/components/MiniTable";
import { Add, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

export function OrgContacts({ valorId, companyName, logoUrl }) {
  const navigate = useNavigate();
  const [canEditRow, setCanEditRow] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const { data: people = [], isLoading } = useQuery(
    ["People", valorId],
    async () => {
      const response = await searchPersons(valorId, null);
      return response;
    },
    {
      retry: 1,
    }
  );

  const queriedData = useMemo(
    () =>
      people.filter((person) => {
        if (query === "") {
          return true;
        }
        const fullName = `${person.firstName} ${person.lastName} ${person.primaryEmail}`;
        return fullName.toLowerCase().includes(query.toLowerCase());
      }),
    [people, query]
  );

  const columns = useMemo(
    () => [
      {
        id: "edit",
        header: "",
        accessorKey: "id",
        width: 50,
        cell: (prop) => (
          <IconButton
            sx={{
              visibility: canEditRow ? "visible" : "hidden",
            }}
            onClick={() =>
              navigate(`/person/${prop.getValue()}`, {
                state: { companyName, valorId },
              })
            }
          >
            <Edit />
          </IconButton>
        ),
      },
      {
        id: "firstName",
        header: "First Name",
        accessorKey: "firstName",
        width: 200,
        cell: (prop) => <TextCell value={prop.getValue()} disableTooltip />,
      },
      {
        id: "lastName",
        header: "Last Name",
        accessorKey: "lastName",
        width: 200,
        cell: (prop) => <TextCell value={prop.getValue()} disableTooltip />,
      },
      {
        id: "title",
        header: "Title(s)",
        accessorKey: "experience",
        width: 200,
        cell: (prop) => {
          const exp = prop.getValue();
          const companyExp = exp.filter(
            (e) => e.organization.valorId === valorId
          );
          return (
            <TextCell
              value={companyExp.map((e) => e.title).join(", ")}
              disableTooltip
            />
          );
        },
      },
      {
        id: "primaryEmail",
        header: "Primary Email",
        accessorKey: "primaryEmail",
        width: 200,
        cell: (prop) => <TextCell value={prop.getValue()} disableTooltip />,
      },
      {
        id: "Other Emails",
        header: "Other Email",
        accessorKey: "otherEmails",
        width: 200,
        cell: (prop) => (
          <TextCell value={(prop.getValue() ?? []).join(", ")} disableTooltip />
        ),
      },
    ],
    [canEditRow, navigate, valorId]
  );

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <TextField
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            label="Search Contacts"
          />
          <Typography ml={0.5} variant="gigaSmall">
            Name and Email
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "16px",
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setCanEditRow((o) => !o)}
          >
            {canEditRow ? "Done" : "Edit Row"}
          </Button>
          <Button
            onClick={() =>
              navigate("/person", { state: { companyName, valorId, logoUrl } })
            }
            color="primary"
            variant="contained"
          >
            Add Contact
            <Add />
          </Button>
        </Box>
      </Box>
      <MiniTable
        enableSorting
        isLoading={isLoading}
        columns={columns}
        data={queriedData}
        emptyTable={query ? "No Results" : "No Data"}
      />
    </Box>
  );
}

export default OrgContacts;
