import React from "react";
import { Box } from "@mui/material";
import Section from "@/ui/atoms/Section";
import { Organization } from "@/api/Organization";

import StaticStats from "./StaticStats";
// import AppAnnieStats from "./AppAnnieStats";

type StatsProps = {
  company: Organization;
};

export default function Stats({ company }: StatsProps) {
  return (
    <Box padding={1}>
      <Section>
        <Section.Title>
          Stats
        </Section.Title>
        <Section.Content>
          <StaticStats company={company} />
          {/* <AppAnnieStats company={company} /> */}
        </Section.Content>
      </Section>
    </Box>
  );
}
