import React from "react";
import { useQuery } from "react-query";
import ValorFolio from "@/ui/atoms/ValorFolio";
import {
  Box,
  Grid,
  Skeleton,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Organization, getOrganizationSignalsById } from "@/api/Organization";
import SignalChip from "@/components/SignalChip";

interface Props {
  company: Organization;
}

const usedSignals = [
  "signal_employee_growth",
  "signal_second_measure_growth",
  "signal_similarweb_growth",
  "signal_executive_hire",
  "signal_executive_departure",
  "signal_app_annie_growth",
  "signal_valor_sentiment",
];

const SignalBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "32px 32px",
  [theme.breakpoints.down("sm")]: {
    gap: "8px",
    maxWidth: "420px",
  },
}));
function SignalFolio({ company }: Props) {
  const theme = useTheme();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  const { data: signals = { signals: [] }, isLoading } = useQuery(
    ["Signals", ...usedSignals, company.valorId],
    async () => getOrganizationSignalsById(company.valorId, usedSignals),
  );

  const chips = signals?.signals
    ?.map((s) => (
      <SignalChip
        key={s.signalId}
        signal={s}
        showIcon
        showName
        valorId={company.valorId}
      />
    ))
    .filter((x) => x);

  if (isLoading || (signals?.signals?.length ?? 0) === 0) {
    return null;
  }

  return (
    <Grid item xs={12} md={6} xl={4}>
      <ValorFolio title="SIGNALS">
        <SignalBox>
          {isLoading ? (
            <Box
              display="flex"
              flexWrap="wrap"
              sx={{ gap: isSmDown ? "4px" : "8px" }}
            >
              <Skeleton variant="rounded" width="120px" height="80px" />
              <Skeleton variant="rounded" width="120px" height="80px" />
              <Skeleton variant="rounded" width="120px" height="80px" />
              <Skeleton variant="rounded" width="120px" height="80px" />
              {isSmDown ? (
                <>
                  <Skeleton variant="rounded" width="120px" height="80px" />
                  <Skeleton variant="rounded" width="120px" height="80px" />
                </>
              ) : null}
            </Box>
          ) : signals && signals?.signals ? (
            <Box
              display="flex"
              flexWrap="wrap"
              sx={{ gap: isSmDown ? "4px" : "8px" }}
            >
              {chips}
            </Box>
          ) : (
            <Typography mt={1}>No Signals</Typography>
          )}
        </SignalBox>
      </ValorFolio>
    </Grid>
  );
}
export default SignalFolio;
