/**
 * Parse name and return initials
 * E.g. 'Clark Kent' => 'CK'
 * @param  {dayjs} value
 * @return {dayjs}
 */
export const getLastMonday = (dayjs) => {
  let today = dayjs();
  if (today.day() === 1) {
    today = today.subtract(1, "week");
  } else if (today.day() === 0) {
    today = today.subtract(6, "day");
  } else {
    today = today.startOf("week").add(1, "day");
  }

  return today;
};

/**
 * Capitalize firs letter of string
 * E.g. 'chicago' => 'Chicago'
 * @param  {String} str
 * @return {String}
 */
export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

/**
 * Parse string and concat first letters
 * E.g. 'Chicago Trading Company' => 'CTC'
 * @param  {String} value The string to be parsed and concated.
 * @return {String}       The concated first letters.
 */
export const concatFirstLetters = (value) => value
  .split(" ")
  .map((word) => word[0])
  .join("");

/**
 * Parse name and return initials
 * E.g. 'Clark Kent' => 'CK'
 * @param  {String} value
 * @return {String}
 */
export const getInitials = (name) => name
  ?.split(" ")
  .map((word) => word[0])
  .join("");

/**
 * Check if value is string or number
 * @param  {String} value
 * @return {Bool}
 */
export function isStringOrNumber(value) {
  return (
    typeof value === "string"
    || value instanceof String
    || typeof value === "number"
    || value instanceof Number
  );
}

const isObject = (obj) => {
  const type = typeof obj;
  return type === "function" || (type === "object" && !!obj);
};

export const getKey = (x) => {
  if (!x) {
    return x;
  }

  return (isObject(x) && "key" in x ? x.key : x);
};

export const getLabel = (x) => {
  if (!x) {
    return x;
  }
  if (isObject(x) && "label" in x) {
    return x.label;
  }
  return typeof x === "number" ? x : capitalizeFirstLetter(x);
};

export const searchParamsObject = (params) => new URLSearchParams(
  Object.fromEntries(Object.entries(params).filter(([, v]) => v != null)),
);
export const searchParams = (params) => searchParamsObject(params).toString();

export const buildURLParameters = (obj) => {
  const params = [];
  Object.keys(obj).forEach((key) => {
    if (Array.isArray(obj[key])) {
      obj[key].forEach((value) => {
        if (value === null || value === undefined) return;
        params.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(value)}`);
      });
    } else if (obj[key] !== null && obj[key] !== undefined) {
      params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
  });

  return params.join("&");
};

export function isValidUrl(string) {
  try {
    new URL(string);
    return true;
  } catch (e) {
    return false;
  }
}

export const buildSharepointUrl = (product, path, filename) => (
  `https://valorep.sharepoint.com/${product === "Growth" ? "Investments/" : ""
  }${path}/${filename}`
);

export default {
  buildSharepointUrl,
  capitalizeFirstLetter,
  concatFirstLetters,
  getInitials,
  getLastMonday,
  isStringOrNumber,
  getKey,
  getLabel,
  searchParams,
  isValidUrl,
  searchParamsObject,
  buildURLParameters,
};
