import React from "react";
import TextCell from "./TextCell";

export default function LocationCell({ value }: {
  value: {
    city: string;
    state: string;
    country: string;
  }
}) {
  if (!value) {
    return null;
  }

  const { city, state, country } = value;

  // country is US only show city, state
  if (country === "US") {
    return (
      <TextCell
        value={[city, state].filter(Boolean).join(", ")}
      />
    );
  }
  return (
    <TextCell
      value={country}
    />
  );
}
