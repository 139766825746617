import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minWidth: theme.spacing(4),
    minHeight: theme.spacing(4),
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightSemiBold,
    fontSize: "1rem",
    border: `1px solid ${theme.palette.teal.main}`,
    borderRadius: "100%",
    cursor: "default",
  },
}));
export default function SimilarityBadge(props) {
  const { similarity } = props;
  const classes = useStyles();
  return (
    <Tooltip title="Overall Similarity" placement="top">
      <Box className={classes.root}>{Math.round(similarity * 100)}</Box>
    </Tooltip>
  );
}

SimilarityBadge.propTypes = {
  similarity: PropTypes.number.isRequired,
};
