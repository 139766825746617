import React, { useRef, useState } from "react";
import {
  Box,
  Popover,
  Tooltip,
  Typography,
  useTheme,
  Button,
} from "@mui/material";
import dayjs from "dayjs";
import { ArrowForward } from "@mui/icons-material";
import { usePopover } from "@/hooks/useGlobalPopover";
import IHEChangeCellIcon from "./IHEIcon";

export type IHElement = {
  choiceText: string;
  commentary: string;
  color: string;
  attribute: string;
};

export type ElementDelta = {
  choice: IHElement;
  previousChoice?: IHElement;
};

export type IHEChangeCellProps = {
  value: ElementDelta[];
};

function isNegativeChange(change: ElementDelta) {
  return (
    change.choice.color === "red"
    || (change.choice.color === "yellow"
      && change.previousChoice.color === "green")
  );
}

function isPositiveChange(change: ElementDelta) {
  return (
    change.choice.color === "green"
    || (change.choice.color === "yellow"
      && (change.previousChoice.color === "gray"
        || change.previousChoice.color === "red"))
  );
}

function IHEChange({ value }: { value: ElementDelta }) {
  const { choice, previousChoice } = value;
  const ref = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const theme = useTheme();

  const isPositive = isPositiveChange(value);
  const changeColor = isPositive
    ? theme.palette.green.main
    : theme.palette.red.main;

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        gap={1}
        border={(t) => `1px solid ${t.palette.divider}`}
        boxSizing="border-box"
        padding={0.5}
        borderRadius={1}
        flexBasis={1}
        maxWidth="120px"
        width="120px"
        sx={{
          backgroundColor: "background.paper",
          cursor: "pointer",
          "&:hover": {
            backgroundColor: (t) => t.palette.action.hover,
          },
        }}
        onClick={() => {
          setShowPopover(true);
        }}
        ref={ref}
      >
        <Box
          width="100%"
          overflow="hidden"
          textOverflow="ellipsis"
          sx={{ textAlign: "center" }}
        >
          {choice.attribute}
        </Box>
        <Box display="flex" alignItems="flex-end" gap={0.5}>
          <IHEChangeCellIcon
            size="big"
            color={previousChoice.color}
            animated={showPopover}
          />
          <ArrowForward sx={{ color: changeColor }} size="big" />
          <IHEChangeCellIcon
            size="big"
            color={choice.color}
            animated={showPopover}
          />
        </Box>
      </Box>
      <Popover
        open={showPopover}
        onClose={() => setShowPopover(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box padding={2} maxWidth={300} display="flex" flexDirection="column">
          <Typography
            variant="boldBody1"
            sx={(t) => ({
              fontWeight: t.typography.fontWeightBold,
            })}
          >
            {choice.attribute}
          </Typography>
          <Typography variant="overline">Currently</Typography>
          <Typography
            sx={(t) => ({
              border: `2px solid ${t.palette[choice.color].main}`,
              padding: theme.spacing(1),
              borderRadius: theme.spacing(1),
            })}
            variant="body2"
          >
            {choice.choiceText}
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            {choice.commentary}
          </Typography>
          <Tooltip title={previousChoice.surveyName}>
            <Typography variant="overline">
              Previously on
              {" "}
              {dayjs(previousChoice.takenAt).format("MM/DD/YYYY")}
            </Typography>
          </Tooltip>
          <Typography
            sx={(t) => ({
              border: `2px solid ${t.palette[previousChoice.color].main}`,
              padding: theme.spacing(1),
              borderRadius: theme.spacing(1),
            })}
            variant="body2"
          >
            {previousChoice.choiceText}
          </Typography>
          <Typography variant="body2" sx={{ fontStyle: "italic" }}>
            {previousChoice.commentary}
          </Typography>
        </Box>
      </Popover>
    </Box>
  );
}

export function IHEChangeCell({ value, type }: IHEChangeCellProps) {
  const { openPopover } = usePopover();
  const theme = useTheme();
  const buttonRef = useRef<HTMLButtonElement>(null);

  if (!value) return null;

  const positiveChanges = value.filter(isPositiveChange) || [];
  const negativeChanges = value.filter(isNegativeChange) || [];

  const mapping = {
    positive: positiveChanges,
    negative: negativeChanges,
  };

  let valuesToMap = value;
  if (type) {
    valuesToMap = mapping[type];
  }
  if (!valuesToMap.length) return <>-</>;

  const threshold = 2;
  const shouldShowMoreButton = valuesToMap.length > threshold;
  const displayedValues = valuesToMap.slice(0, threshold);

  const handleShowMore = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!buttonRef.current) return;
    const additionalContent = (
      <Box
        sx={{
          padding: theme.spacing(1),
          display: "flex",
          flexWrap: "wrap",
          gap: theme.spacing(1),
        }}
      >
        {valuesToMap.map((change) => (
          <IHEChange value={change} key={change.attribute} />
        ))}
      </Box>
    );
    openPopover(buttonRef, additionalContent, "IHE Changes");
  };

  return (
    <Box display="flex" flexDirection="column" width="100%" height="100%">
      <Box
        ref={buttonRef}
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        alignItems="flex-end"
        gap={1}
        width="100%"
        maxWidth="100%"
      >
        {displayedValues.map((change) => (
          <IHEChange value={change} key={change.attribute} />
        ))}
        {shouldShowMoreButton && (
          <Button
            variant="outlined"
            sx={{
              height: (t) => t.spacing(4),
            }}
            onClick={handleShowMore}
          >
            Show All
          </Button>
        )}
      </Box>
    </Box>
  );
}

export default IHEChangeCell;
