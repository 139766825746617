import React, { useState, useEffect } from "react";
import {
  Box, Select, MenuItem, Button, Typography, IconButton, useTheme,
} from "@mui/material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { useQuery, useQueryClient } from "react-query";
import { JOB_CATEGORIES } from "@/constants/JobCategories";
import { getMixedLabels, skipLabel, postTitleLabel } from "@/api/Label";

function LabelingItem({
  // eslint-disable-next-line react/prop-types
  department, jobTitle, onSubmit, onSkip,
}) {
  const [jobCategory, setJobCategory] = useState(department);
  const [busy, setBusy] = useState(false);

  const queryClient = useQueryClient();

  const handleSubmit = async () => {
    setBusy(true);
    await onSubmit(jobTitle, jobCategory);
    setBusy(false);
    queryClient.setQueriesData(["MixedJobPredictions"], (oldData) => oldData.filter((x) => x.jobTitle !== jobTitle));
  };

  const handleSkip = async () => {
    setBusy(true);
    await onSkip(jobTitle);
    setBusy(false);
    queryClient.setQueriesData(["MixedJobPredictions"], (oldData) => oldData.filter((x) => x.jobTitle !== jobTitle));
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding={2}
      sx={{
        gap: 2,
        border: (theme) => `1px solid ${theme.palette.divider}`,
      }}
    >
      <Box display="flex" alignItems="center">
        <Typography variant="h2">{jobTitle}</Typography>
        <IconButton onClick={() => navigator.clipboard.writeText(jobTitle)}>
          <AssignmentIcon />
        </IconButton>
      </Box>
      <Box display="flex" style={{ gap: "16px" }}>
        <Select
          style={{ width: "160px" }}
          value={jobCategory || ""}
          onChange={(e) => setJobCategory(e.target.value)}
        >
          {JOB_CATEGORIES.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        <Button
          color="secondary"
          variant="outlined"
          onClick={handleSkip}
          disabled={busy}
        >
          Skip
        </Button>
        <Button
          color="secondary"
          variant="contained"
          disabled={busy}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Box>
    </Box>
  );
}

export function LabelingPage() {
  const theme = useTheme();
  const [jobCategory, setJobCategory] = useState(null);

  const {
    data: labels = [], refetch, isLoading, isRefetching,
  } = useQuery(
    ["MixedJobPredictions", jobCategory],
    async () => getMixedLabels(jobCategory),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  );

  useEffect(() => {
    if (labels.length === 0 && !isLoading) {
      setJobCategory(null);
      refetch();
    }
  }, [labels.length, refetch, setJobCategory, isLoading]);

  return (
    <Box
      sx={{
        ...theme.defaultMargin,
      }}
    >
      <Box display="flex">
        <CenteredProgress isLoading={isLoading || isRefetching} />
        <h1>Labeling</h1>
        <Select
          style={{ width: "240px", marginLeft: "16px" }}
          value={jobCategory || ""}
          onChange={(e) => setJobCategory(e.target.value)}
        >
          <MenuItem value="">ALL</MenuItem>
          {JOB_CATEGORIES.map((category) => (
            <MenuItem
              key={category}
              value={category}
            >
              {category}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box
        sx={{
          "& >div:nth-of-type(even)": {
            backgroundColor: (t) => t.palette.purple.highlight,
          },
          "& >div:nth-of-type(odd)": {
            backgroundColor: (t) => t.palette.blue.highlight,
          },
        }}
      >
        {labels.map(({ jobTitle, department }) => (
          <LabelingItem
            key={jobTitle}
            jobTitle={jobTitle}
            department={department}
            onSubmit={async (title, label) => postTitleLabel(title, label)}
            onSkip={async (title) => skipLabel(title)}
          />
        ))}
      </Box>
    </Box>
  );
}
export default LabelingPage;
