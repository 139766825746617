import React from "react";
import {
  Box, Button, Chip, MenuItem, Typography, Select, IconButton, Menu,
  ListItemText, ListItemIcon,
  Skeleton, Alert,
  TextField,
} from "@mui/material";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import {
  canCreateProcesses, canFavoriteProcesses, canDeleteProcesses, canArchiveProcesses,
} from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { useQuery, useQueryClient } from "react-query";
import {
  useQueryParam, StringParam, JsonParam, withDefault,
} from "use-query-params";
import {
  getProcesses, createFavorite, removeFavorite, deleteProcess, updateProcess,
} from "@/api/Process";
import {
  blue, pink,
} from "@mui/material/colors";
import BuildingIcon from "@mui/icons-material/Business";
import DollarIcon from "@mui/icons-material/AttachMoney";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import {
  Delete, MoreVert, Archive, Unarchive, Search, Close,
} from "@mui/icons-material";

import dayjs from "dayjs";
import fuzzyFilter from "@/utils/fuzzyFilter";
import omit from "lodash/omit";
import CreateNewListModal from "./CreateNewListModal";

function CRMIcon({
  icon,
  color,
}: {
  icon: "dollar" | "building";
  color: "pink" | "blue";
}) {
  const colorMap = {
    pink: {
      main: pink["500"],
      light: pink["200"],
    },
    blue: {
      main: blue["500"],
      light: blue["200"],
    },
  };
  const iconMap = {
    dollar: DollarIcon,
    building: BuildingIcon,
  };
  const Icon = iconMap[icon];
  const width = 50;
  const height = 45;
  return (
    <Box
      sx={{
        transform: "scale(0.6)",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Icon
        sx={{
          color: colorMap[color].main,
          background: colorMap[color].light,
          borderRadius: 1,
          padding: 1,
          height,
          width,
        }}
      />
      <Box
        sx={{
          background: colorMap[color].main,
          borderRadius: "0 8px 8px 0",
          width: 3,
          height: height * 0.8,
          margin: "auto",
          marginLeft: "2px",
        }}
      />
      <Box
        sx={{
          background: colorMap[color].main,
          borderRadius: "0 8px 8px 0",
          width: 3,
          height: height * 0.6,
          margin: "auto",
          marginLeft: "2px",
        }}
      />
    </Box>
  );
}

function CRMListItem({
  listItem,
  canFavorite,
  canArchive,
  canDelete,
  onFavorite,
  onDelete,
  onArchive,
}) {
  const isOrganization = listItem.settings?.find(({ key }) => key === "tag")?.value === "Organizations";
  const isOpportunity = listItem.settings?.find(({ key }) => key === "tag")?.value === "Opportunities";
  const [menuOpen, setMenuOpen] = React.useState(false);
  const menuRef = React.useRef(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        gap: 1,
        padding: 1,
        borderRadius: 1,
        background: (theme) => theme.palette.background.default,
        cursor: "pointer",
      }}
    >
      {isOrganization && (
        <CRMIcon
          icon="building"
          color="blue"
        />
      )}
      {isOpportunity && (
        <CRMIcon
          icon="dollar"
          color="pink"
        />
      )}
      <Box
        sx={{
          borderBottom: "1px solid",
          borderColor: (theme) => theme.palette.divider,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box>
          <Typography variant="boldBody1">
            {listItem.id ? (
              <Link
                to={`/process-management/${listItem.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {listItem.name}
              </Link>
            ) : (
              listItem.name
            )}
          </Typography>
          <Typography variant="body1">
            {listItem.lastViewedAt === null ? (
              "Never viewed"
            ) : (
              `Viewed ${dayjs(listItem.lastViewedAt).format("MMM DD")}`
            )}
          </Typography>
        </Box>

        <Box>
          {canFavorite && (
            <IconButton
              onClick={() => {
                onFavorite(listItem.id);
              }}
            >
              {listItem.favorited ? (
                <StarIcon sx={{ color: (theme) => theme.palette.yellow.main }} />
              ) : (<StarOutlineIcon />)}
            </IconButton>
          )}

          {(canDelete || canArchive) && (
            <>
              <IconButton
                onClick={() => setMenuOpen(true)}
                ref={menuRef}
              >
                <MoreVert />
              </IconButton>

              <Menu
                anchorEl={menuRef.current}
                open={Boolean(menuOpen)}
                onClose={() => setMenuOpen(null)}
              >
                {canArchive && (
                  <MenuItem
                    onClick={() => {
                      onArchive(listItem.id);
                      setMenuOpen(false);
                    }}
                  >
                    {listItem.archived ? (
                      <>
                        <ListItemIcon><Unarchive /></ListItemIcon>
                        <ListItemText>Unarchive</ListItemText>
                      </>
                    ) : (
                      <>
                        <ListItemIcon><Archive /></ListItemIcon>
                        <ListItemText>Archive</ListItemText>
                      </>
                    )}
                  </MenuItem>
                )}
                {canDelete && (
                  <MenuItem
                    onClick={() => {
                      onDelete(listItem.id);
                      setMenuOpen(false);
                    }}
                  >
                    <ListItemIcon><Delete /></ListItemIcon>
                    <ListItemText>Delete</ListItemText>
                  </MenuItem>
                )}
              </Menu>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default function CRM() {
  const FILTERS = ["Organizations", "Opportunities"];
  const [nameFilter, setNameFilter] = useQueryParam("name", withDefault(StringParam, ""));
  const [activeFilter, setActiveFilter] = useQueryParam("filter", withDefault(JsonParam, {}));
  const [activeSort, setActiveSort] = useQueryParam("sort", withDefault(StringParam, "recentlyViewed"));

  const [newListModalOpen, setNewListModalOpen] = React.useState(false);

  const { user } = useAuth();
  const canCreateProcess = canCreateProcesses(user);

  // fetch processes
  const {
    data: lists,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ["crmlists", activeFilter, activeSort],
    async () => getProcesses(
      100,
      null,
      [activeSort],
      {
        processType: "CRM_LIST",
        ...(activeFilter ? { settings: { tag: activeFilter.tag } } : {}),
      },
    ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  );
  const queryClient = useQueryClient();

  // favorite list
  function onFavorite(id) {
    // update list
    const list = lists.find((l) => l.id === id);
    list.favorited = !list.favorited;
    queryClient.setQueryData(
      ["crmlists", activeFilter, activeSort],
      (prev) => ([
        ...prev,
      ]),
    );

    // update backend
    if (list.favorited) {
      // add to favorites
      createFavorite(list.id);
    } else {
      // remove from favorites
      removeFavorite(list.id);
    }
  }

  // delete list
  function onDelete(id) {
    // remove list from lists
    queryClient.setQueryData(
      ["crmlists", activeFilter, activeSort],
      (prev) => prev.filter((list) => list.id !== id),
    );

    deleteProcess(id);
  }

  // archive list
  function onArchive(id) {
    // update list
    const list = lists.find((l) => l.id === id);
    list.archived = !list.archived;
    queryClient.setQueryData(
      ["crmlists", activeFilter, activeSort],
      (prev) => ([
        ...prev.filter((l) => l.id !== id),
        list,
      ]),
    );

    // update backend
    updateProcess({ id, archived: list.archived });
  }

  const toSortedList = (list) => {
    switch (activeSort) {
      case "name":
        return list.toSorted((a, b) => a.name.localeCompare(b.name));
      case "-name":
        return list.toSorted((a, b) => b.name.localeCompare(a.name));
      case "-createdAt":
        return list.toSorted((a, b) => b.createdAt - a.createdAt);
      case "createdAt":
        return list.toSorted((a, b) => a.createdAt - b.createdAt);
      case "recentlyViewed":
      default:
        return list.toSorted((a, b) => b.lastViewedAt - a.lastViewedAt);
    }
  };

  const toFilteredList = (list) => {
    if (nameFilter.trim().length === 0) {
      return list;
    }
    return list.filter((l) => fuzzyFilter(l.name, nameFilter));
  };

  const regularLists = toFilteredList(toSortedList(lists?.filter(
    (list) => !list.favorited && !list.archived,
  ) || []));
  const favoriteLists = toFilteredList(toSortedList(lists?.filter(
    (list) => list.favorited && !list.archived,
  ) || []));
  const archivedLists = toFilteredList(toSortedList(lists?.filter(
    (list) => list.archived,
  ) || []));

  const canFavorite = canFavoriteProcesses(user);
  const canDelete = canDeleteProcesses(user);
  const canArchive = canArchiveProcesses(user);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: 800,
        maxWidth: "100%",
        padding: 1,
        marginX: "auto",
      }}
    >
      <TextField
        label="Search by name"
        variant="outlined"
        fullWidth
        margin="normal"
        value={nameFilter}
        onChange={(e) => setNameFilter(e.target.value)}
        InputProps={{
          startAdornment: <Search />,
          endAdornment: (nameFilter.length > 0 && (
            <IconButton
              onClick={() => setNameFilter("")}
            >
              <Close />
            </IconButton>
          )),
        }}
      />

      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        justifyContent="space-between"
        margin={1}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
        >
          {FILTERS.map((filter) => (
            <Chip
              key={filter}
              label={filter}
              color={activeFilter.tag === filter ? "primary" : "default"}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                setActiveFilter((prev) => {
                  if (prev.tag === filter) {
                    return omit(prev, "tag");
                  }
                  return {
                    ...prev,
                    tag: filter,
                  };
                });
              }}
            />
          ))}
        </Box>

        <Select
          value={activeSort}
          onChange={(e) => setActiveSort(e.target.value)}
          size="small"
        >
          <MenuItem value="recentlyViewed">Recently Viewed</MenuItem>
          <MenuItem value="name">Name A to Z</MenuItem>
          <MenuItem value="-name">Name Z to A</MenuItem>
          <MenuItem value="-createdAt">Created (Newest)</MenuItem>
          <MenuItem value="createdAt">Created (Oldest)</MenuItem>
        </Select>
      </Box>

      {favoriteLists.length > 0 && (
        <Box>
          <Typography
            variant="h2"
            sx={{ margin: 1 }}
          >
            Favorites
          </Typography>

          <Box
            display="flex"
            flexDirection="column"
            gap={1}
          >
            {favoriteLists.map((list) => (
              <CRMListItem
                key={list.id}
                listItem={list}
                onFavorite={(id) => onFavorite(id)}
                onDelete={(id) => onDelete(id)}
                onArchive={(id) => onArchive(id)}
                canFavorite={canFavorite}
                canDelete={canDelete}
                canArchive={canArchive}
              />
            ))}
          </Box>
        </Box>
      )}

      <Box
        display="flex"
        flexDirection="row"
        gap={1}
        margin={1}
      >
        <Typography variant="h2">Lists</Typography>
        {canCreateProcess && (
          <Button
            variant="text"
            color="primary"
            size="small"
            startIcon={
              <AddIcon />
            }
            onClick={() => setNewListModalOpen(true)}
          >
            New List
          </Button>
        )}
      </Box>

      <Box>
        {
          isLoading && (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
            >
              <Skeleton variant="rounded" height={80} />
              <Skeleton variant="rounded" height={80} />
              <Skeleton variant="rounded" height={80} />
              <Skeleton variant="rounded" height={80} />
            </Box>
          )
        }

        {isError && (
          <Alert severity="error">
            An error occurred while fetching CRM lists, refresh the page to try again.
            If the problem persists, contact the labs team.
          </Alert>
        )}

        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {regularLists.map((list) => (
            <CRMListItem
              key={list.id}
              listItem={list}
              onFavorite={(id) => onFavorite(id)}
              onDelete={(id) => onDelete(id)}
              onArchive={(id) => onArchive(id)}
              canFavorite={canFavorite}
              canDelete={canDelete}
              canArchive={canArchive}
            />
          ))}
        </Box>

        {regularLists?.length === 0 && !isLoading && (
          <Typography variant="boldBody1">No lists found</Typography>
        )}
      </Box>

      {canDeleteProcesses && archivedLists?.length > 0 && (
      <Box
        marginY={3}
      >
        <Typography variant="h3">Archived</Typography>
        <Box display="flex" flexDirection="column" gap={1}>
          {archivedLists.map((list) => (
            <CRMListItem
              key={list.id}
              listItem={list}
              onFavorite={(id) => onFavorite(id)}
              onDelete={(id) => onDelete(id)}
              onArchive={(id) => onArchive(id)}
              canFavorite={canFavorite}
              canDelete={canDelete}
              canArchive={canArchive}
            />
          ))}
        </Box>
      </Box>
      )}

      <CreateNewListModal
        open={newListModalOpen}
        onClose={(newList) => {
          if (newList) {
            // add list to lists
            queryClient.setQueryData(
              ["crmlists", activeFilter, activeSort],
              (prev) => ([
                ...prev,
                newList,
              ]),
            );
            refetch();
          }
          setNewListModalOpen(false);
        }}
      />
    </Box>
  );
}
