import React, { useEffect } from "react";
import {
  Box,
  Button,
  Drawer,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  Add,
  ChevronLeft,
  Close,
} from "@mui/icons-material";
import {
  DragDropContext,
  Droppable,
  Draggable,
} from "react-beautiful-dnd";
import { useProcessData, useProcessActions } from "../ProcessContext";
import ViewListItem from "./ViewListItem";
import ViewForm from "./ViewForm";

export default function ViewEditor({
  processId,
  open,
  onClose,
}: {
  processId: string;
  open: boolean;
  onClose: () => void;
}) {
  const [showNew, setShowNew] = React.useState(false);
  const [activeView, setActiveView] = React.useState(null);
  const {
    views,
    isProcessLoading: loading,
  } = useProcessData();

  const activeViews = React.useMemo(() => (
    views?.filter((view) => view.deletedAt === null && view.public)
  ), [views]);

  const {
    createView,
    updateView,
    updateViews,
    deleteView,
  } = useProcessActions();

  const [formDisabled, setFormDisabled] = React.useState(false);

  const drawerRef = React.useRef(null);
  useEffect(() => {
    drawerRef.current?.scrollTo(0, 0, "smooth");
  }, [activeView, showNew]);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        if (!formDisabled) {
          onClose();
        }
      }}
    >
      <Box
        width="min(100vw, 600px)"
        maxWidth="100%"
        padding={1}
        ref={drawerRef}
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          px={1}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            gap={1}
            onClick={() => {
              setShowNew(false);
              setActiveView(null);
            }}
          >
            {(showNew || activeView) && (<ChevronLeft />)}
            <Typography variant="h2">
              View Editor
            </Typography>
          </Box>
          <IconButton
            onClick={onClose}
          >
            <Close />
          </IconButton>
        </Box>

        {loading && (
          <Stack spacing={1}>
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
            <Skeleton variant="rounded" height={40} />
          </Stack>
        )}

        {!loading && showNew && (
          <Box>
            <ViewForm
              disabled={formDisabled}
              onSubmit={async (newView) => {
                setFormDisabled(true);
                try {
                  await createView(processId, {
                    ...newView,
                    sort: activeViews.length,
                  });
                  setShowNew(false);
                } catch (error) {
                  console.error(error);
                } finally {
                  setFormDisabled(false);
                }
              }}
              onCancel={() => {
                setShowNew(false);
              }}
            />
          </Box>
        )}

        {!loading && activeView && (
          <Box>
            <ViewForm
              initialValue={activeView}
              disabled={formDisabled}
              onSubmit={async (newView) => {
                setFormDisabled(true);
                try {
                  await updateView(processId, newView);
                  setActiveView(null);
                } catch (error) {
                  console.error(error);
                } finally {
                  setFormDisabled(false);
                }
              }}
              onCancel={() => {
                setActiveView(null);
              }}
            />
          </Box>
        )}

        {!loading && !showNew && !activeView && (
          <Box>
            <DragDropContext onDragEnd={async (dragEvent) => {
              const { source, destination } = dragEvent;
              if (!destination) return;

              const newFields = [...activeViews];
              const [removed] = newFields.splice(source.index, 1);
              newFields.splice(destination.index, 0, removed);

              // update sort order on server
              const reorderedFields = newFields.map((field, index) => ({
                id: field.id,
                sort: index,
              }));
              await updateViews(processId, reorderedFields);
            }}
            >
              <Droppable droppableId="fields">
                {(dropProvided) => (
                  <Box
                    ref={dropProvided.innerRef}
                    /* eslint-disable-next-line react/jsx-props-no-spreading */
                    {...dropProvided.droppableProps}
                  >
                    {activeViews?.map((view, index) => (
                      <Draggable key={view.id} draggableId={view.id} index={index}>
                        {(dragProvided) => (
                          <Box
                            ref={dragProvided.innerRef}
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...dragProvided.draggableProps}
                            /* eslint-disable-next-line react/jsx-props-no-spreading */
                            {...dragProvided.dragHandleProps}
                          >
                            <ViewListItem
                              key={view.id}
                              view={view}
                              onEdit={() => {
                                setActiveView(view);
                              }}
                              onDelete={() => {
                                deleteView(processId, view.id);
                              }}
                            />
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {dropProvided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>

            <Tooltip title="Add Field">
              <Button
                fullWidth
                onClick={() => {
                  setShowNew(true);
                }}
              >
                <Add />
              </Button>
            </Tooltip>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
