import React, { useRef, useState } from "react";
import {
  Box,
  Typography,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  TextField,
  Alert,
  Snackbar,
} from "@mui/material";
import dayjs from "dayjs";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { User } from "@/api/Users";
import { FileTaskStatus } from "@/api/Metrics";

import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";

type OrganizationTaskListTaskProps = {
  fileName: string;
  fileDate: string;
  state: string;
  user: User;
  currentUser: User;
  downloadFileAndTemplate: () => void;
  uploadMetricsFile: (file: File) => void;
  skipTask: (reason: FileTaskStatus, details: string) => void;
  startTask: () => void;
};

export default function OrganizationTaskListTask({
  fileName,
  fileDate,
  state,
  user,
  currentUser,
  downloadFileAndTemplate,
  downloadAllFiles,
  uploadMetricsFile,
  skipTask,
  startTask,
}: OrganizationTaskListTaskProps) {
  const [skipOpen, setSkipOpen] = useState(false);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [uploadDisabled, setUploadDisabled] = useState(false);
  const fileRef = useRef(null);

  const [uploadError, setUploadError] = useState(null);

  const skipStates = [
    { label: "Broken File", value: "BROKEN_FILE" },
    { label: "No New Data", value: "NO_DATA_OR_METRIC_NOT_FOUND" },
  ];

  const isInTodo = () => state === "READY";
  const isInProgress = () => state === "IN_PROGRESS";
  const isInProgressByOtherUser = () => state === "IN_PROGRESS" && user?.id && user?.id !== currentUser?.id;

  const [skipValue, setSkipValue] = useState("");
  const [skipDetails, setSkipDetails] = useState("");

  return (
    <Box
      display="flex"
      flexDirection="column"
      width="100%"
      gap={1}
    >
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent={{ sm: "space-between" }}
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        width="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems={{
            xs: "flex-start",
            sm: "center",
          }}
          flexWrap="wrap"
          width={{
            xs: "100%",
            sm: "auto",
          }}
        >
          <Typography
            variant="body2"
            sx={{
              maxWidth: 600,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: {
                xs: "normal",
                sm: "nowrap",
              },
            }}
          >
            {fileName}
          </Typography>
          {fileDate && fileDate.length > 0 && (
            <Typography
              variant="caption"
            >
              {dayjs.utc(fileDate).format("MMM DD, YYYY [at] h:mm A")}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}
        >
          {isInTodo() && (
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => {
                startTask();
              }}
            >
              Start Task
            </Button>
          )}
          {isInProgress() && !isInProgressByOtherUser() && (
            <>
              <Button
                color="primary"
                variant="outlined"
                onClick={downloadAllFiles}
                size="small"
                startIcon={<DownloadIcon />}
              >
                All Files
              </Button>
              <Button
                color="primary"
                variant="outlined"
                onClick={downloadFileAndTemplate}
                size="small"
                startIcon={<DownloadIcon />}
              >
                File & Template
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setUploadOpen(true)}
                size="small"
                startIcon={<UploadIcon />}
              >
                Metrics File
              </Button>
              <Divider orientation="vertical" flexItem />
              <Button
                color="primary"
                variant="outlined"
                onClick={() => setSkipOpen(true)}
                size="small"
              >
                Skip
              </Button>
            </>
          )}
          {
            isInProgressByOtherUser() && (
              <UserAvatar
                user={{
                  name: `${user.firstName} ${user.lastName}`,
                  profilePicture: user.profilePicture,
                }}
                displayTooltip
                style={{
                  width: "30px",
                  height: "30px",
                }}
              />
            )
          }
        </Box>
      </Box>

      <Dialog
        open={skipOpen}
        onClose={() => setSkipOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Are you sure you want to skip this task?</DialogTitle>
        <DialogContent>
          <Box
            mt={1}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <FormControl
              fullWidth
              variant="outlined"
            >
              <InputLabel id="skip-reason-label">Reason</InputLabel>
              <Select
                labelId="skip-reason-label"
                label="Reason"
                required
                value={skipValue}
                onChange={(e) => setSkipValue(e.target.value)}
              >
                {skipStates.map((skipState) => (
                  <MenuItem
                    key={skipState.value}
                    value={skipState.value}
                  >
                    {skipState.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Details"
              multiline
              fullWidth
              value={skipDetails}
              onChange={(e) => setSkipDetails(e.target.value)}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setSkipOpen(false)}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setSkipOpen(false);
              skipTask(
                skipValue,
                skipDetails,
              );
            }}
          >
            Skip Task
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={uploadOpen}
        onClose={() => setUploadOpen(false)}
      >
        <DialogTitle>Upload Metrics File</DialogTitle>
        <DialogContent>
          <Box
            mt={1}
            display="flex"
            flexDirection="column"
            gap={1}
          >
            <TextField
              type="file"
              fullWidth
              disabled={uploadDisabled}
              InputProps={{
                accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
              }}
              inputRef={fileRef}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setUploadOpen(false)}
            disabled={uploadDisabled}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              setUploadDisabled(true);
              try {
                const file = fileRef.current.files[0];
                await uploadMetricsFile(file);
                setUploadOpen(false);
              } catch (e) {
                if (e.response?.status === 400) {
                  const errorData = e.response.data;
                  setUploadError(errorData);
                }
              }
              setUploadDisabled(false);
            }}
            disabled={uploadDisabled}
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={!!uploadError}
        autoHideDuration={6000}
        onClose={() => setUploadError(null)}
      >
        <Alert
          onClose={() => setUploadError(null)}
          severity="error"
        >
          {typeof uploadError?.detail === "string" ? uploadError?.detail : (
            <>
              {uploadError?.detail?.title}
              <Box
                maxHeight="400px"
                sx={{ overflowY: "scroll" }}
              >
                <pre>
                  {JSON.stringify(uploadError?.errors, null, 2)}
                </pre>
              </Box>
            </>
          )}
        </Alert>
      </Snackbar>
    </Box>
  );
}
