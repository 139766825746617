import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Button,
  TextField,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  Typography,
  Checkbox,
  Alert,
} from "@mui/material";
import { useQuery } from "react-query";
import { getMetricLabelClassifications } from "@/api/Metrics";
import Progress from "@/ui/atoms/Progress";

const VALUE_TYPES = ["DOLLAR", "INTEGER", "FLOAT", "PERCENTAGE", "MULTIPLE"];
export default function NewLabelDialog({
  open,
  onClose,
  initialLabelText,
  onSubmit,
  disabled,
}: {
  open: boolean;
  onClose: () => void;
  initialLabelText: string;
  onSubmit: (payload: {
    label: string;
    metricFamilyId: number;
    valueType: string;
    category?: string;
    businessSectorId?: string;
    canonicalParentMetricId?: string;
    canRollup?: boolean;
  }) => void;
  disabled: boolean;
}) {
  const [addNewCategory, setAddNewCategory] = useState(false);
  const [newLabel, setNewLabel] = useState("");
  const [newCategory, setNewCategory] = useState("");
  const [newValueType, setNewValueType] = useState(VALUE_TYPES[0]);
  const [metricFamily, setMetricFamily] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [parentLabel, setParentLabel] = useState("");
  const [canRollup, setCanRollup] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const {
    isLoading,
    data: classifications =
    {
      families: [],
      businessSectors: [],
      canonicalParentMetrics: [],
    },
  } = useQuery(
    "LabelClassifications",
    getMetricLabelClassifications,
  );

  const {
    families: metricFamilies,
    businessSectors: categories,
    canonicalParentMetrics: parentLabels,
  } = classifications;

  useEffect(() => {
    setNewLabel(initialLabelText);
  }, [initialLabelText]);

  useEffect(() => {
    setSelectedCategory("");
    setNewCategory("");
    setAddNewCategory(false);
  }, [open]);

  const addNewLabel = async () => {
    setErrorMessage("");
    const payload: {
      label: string;
      metricFamilyId: number;
      valueType: string;
      category?: string;
      businessSectorId?: string;
      canonicalParentMetricId?: string;
      canRollup?: boolean;
    } = {
      label: newLabel,
      canonicalParentMetricId: parentLabel,
      canRollup,
      metricFamilyId: Number(metricFamily),
      valueType: newValueType,
    };
    if (addNewCategory) {
      payload.category = newCategory;
    } else {
      payload.businessSectorId = selectedCategory;
    }

    try {
      await onSubmit(payload);
    } catch (e) {
      setErrorMessage(e.detail);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Add New Label</DialogTitle>
      <DialogContent>
        {isLoading ? <Progress />
          : (
            <Box display="flex" flexDirection="column" gap={2} paddingY={1}>
              {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

              <TextField
                fullWidth
                label="Label Name"
                value={newLabel}
                onChange={(event) => setNewLabel(event.target.value)}
                disabled={disabled}
                autoFocus={open}
              />

              <FormControl fullWidth>
                <InputLabel id="category-label">Parent Label</InputLabel>
                <Select
                  labelId="parent-label"
                  label="Parent Label"
                  value={parentLabel}
                  onChange={(event) => setParentLabel(event.target.value as string)}
                  disabled={disabled}
                >
                  {parentLabels?.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel id="value-label">Value Type</InputLabel>
                <Select
                  labelId="value-label"
                  label="Value Type"
                  value={newValueType}
                  onChange={(event) => setNewValueType(event.target.value as string)}
                  disabled={disabled}
                >
                  {VALUE_TYPES?.map((vType) => (
                    <MenuItem key={vType} value={vType}>
                      {vType}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth>
                <InputLabel id="family-label">Metric Family Type</InputLabel>
                <Select
                  labelId="family-label"
                  label="Metric Family Type"
                  value={metricFamily}
                  onChange={(event) => setMetricFamily(event.target.value as string)}
                  disabled={disabled}
                >
                  {metricFamilies?.map((vType) => (
                    <MenuItem key={vType.id} value={vType.id}>
                      {vType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {addNewCategory ? (
                <FormControl fullWidth>
                  <TextField
                    fullWidth
                    label="Category Name"
                    value={newCategory}
                    onChange={(event) => setNewCategory(event.target.value)}
                    disabled={disabled}
                  />
                  <Typography variant="body2" align="center">
                    OR
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setAddNewCategory(false)}
                    disabled={disabled}
                  >
                    Select Category
                  </Button>
                </FormControl>
              ) : (
                <FormControl>
                  <InputLabel id="category-label">Select Category</InputLabel>
                  <Select
                    labelId="category-label"
                    label="Select Category"
                    value={selectedCategory}
                    onChange={(event) => setSelectedCategory(event.target.value as string)}
                    disabled={disabled}
                  >
                    {categories?.map((category) => (
                      <MenuItem key={category.id} value={category.id}>
                        {category.name}
                      </MenuItem>
                    ))}
                  </Select>
                  <Typography variant="body2" align="center">
                    OR
                  </Typography>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={() => setAddNewCategory(true)}
                    disabled={disabled}
                  >
                    Add New Category
                  </Button>
                </FormControl>
              )}

              <FormControlLabel
                label="Check this box if this is a LTM or rolling metric (Revenue, COGS, etc.) (as opposed to a point-in-time metric such as Cash or Assets)."
                control={(
                  <Checkbox
                    checked={canRollup}
                    disabled={disabled}
                    onChange={(event) => setCanRollup(event.target.checked)}
                  />
            )}
              />
            </Box>
          )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          variant="outlined"
          disabled={disabled}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            addNewLabel();
          }}
          disabled={
            disabled
            || !newLabel
            || (!addNewCategory && !selectedCategory)
            || !parentLabel
          }
        >
          Add New Label
        </Button>
      </DialogActions>
    </Dialog>
  );
}
