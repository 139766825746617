import React from "react";
import Section from "@/ui/atoms/Section";
import {
  Box,
  Typography,
  Skeleton,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

import { canReadTegusScores } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import SummaryRow from "../../Transcripts/SummaryRow";
import useSummaries from "../../Transcripts/useSummaries";

export default function Transcripts({
  valorId,
}: {
  valorId: string;
}) {
  const navigate = useNavigate();
  const { user } = useAuth();
  const version: "admin" | "associate" = (
    canReadTegusScores(user) ? "admin" : "associate"
  );

  const {
    summaries: transcripts,
    isLoading,
    iheTrends,
  } = useSummaries(valorId, 5);

  function Title() {
    return (
      <>
        Expert Interviews
      </>
    );
  }

  if (isLoading) {
    return (
      <Section>
        <Section.Title>
          <Title />
        </Section.Title>
        <Section.Content>
          {Array(5).fill(0).map((_, key) => (
            <Box
              display="flex"
              sx={{ gap: "8px", margin: "16px 0", width: "100%" }}
              // eslint-disable-next-line react/no-array-index-key
              key={key}
            >
              <Box display="flex" flexDirection="column">
                <Skeleton variant="text" height="20px" width="220px" />
                <Skeleton variant="text" height="16px" width="80px" />
                <Skeleton variant="text" height="16px" width="120px" />
              </Box>
            </Box>
          ))}
        </Section.Content>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Title>
        <Title />
      </Section.Title>
      <Section.Content>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {transcripts?.length === 0 && (
            <Typography variant="body2">
              No transcripts available
            </Typography>
          )}
          {transcripts?.map((transcript) => (
            <SummaryRow
              key={transcript.id}
              transcript={transcript}
              iheTrends={iheTrends[transcript.id]}
              onSummaryOpen={() => {
                navigate(`../transcripts/${transcript.id}`);
              }}
              version={version}
            />
          ))}
        </Box>
        <Button
          variant="text"
          disabled={transcripts?.length === 0}
          onClick={() => {
            navigate("transcripts");
          }}
          fullWidth
        >
          View More
        </Button>
      </Section.Content>
    </Section>
  );
}
