import React from "react";
import IHEChanges from "@/pages/IHEChange/IHEChanges";
import Page from "@/ui/molecules/Page";

function IHEChangePage() {
  return (
    <Page>
      <Page.Content>
        <IHEChanges />
      </Page.Content>
    </Page>
  );
}

export default IHEChangePage;
