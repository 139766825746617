import React, { useState } from "react";
import { Box, Chip, ClickAwayListener } from "@mui/material";
import OverflowBox from "./OverflowBox";

export default function ChipCell({ value }: { value?: string[] }) {
  const [showOverflow, setShowOverflow] = useState(false);
  if (!value || value?.length === 0) {
    return <>-</>;
  }

  return (
    <ClickAwayListener onClickAway={() => setShowOverflow(false)}>
      <Box
        maxHeight="100px"
        // maxWidth="200px"
        // idk why this here gonna give it a cheek comment out
        position="relative"
        display="flex"
        height="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap={1}
          overflow="hidden"
          visibility={showOverflow ? "hidden" : "visible"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setShowOverflow(true)}
        >
          {value?.map((x) => (
            <Chip
              key={x}
              label={x}
              size="small"
            />
          ))}
        </Box>
        <OverflowBox
          open={showOverflow}
        >
          {value?.map((x) => (
            <Chip
              key={x}
              label={x}
              size="small"
            />
          ))}
        </OverflowBox>
      </Box>
    </ClickAwayListener>
  );
}
