import React from "react";
import { OrganizationSignals } from "@/api/Organization";
import FimoicaChip from "./FimoicaChip";
import OtherSignalsChip from "./SignalsChip";

type Signal = OrganizationSignals["signals"][0];
interface SignalChipProps {
  signal: Signal,
  showIcon?: boolean;
  showName?: boolean;
  showBorder?: boolean
  valorId?: string;
}

export function SignalChip({
  signal,
  showIcon,
  showName,
  showBorder,
  valorId,
}: SignalChipProps) {
  if (!signal) return null;
  if (signal.signalType === "signal_fimoica_prediction") {
    return (
      <FimoicaChip
        signal={signal}
        showIcon={showIcon}
        showName={showName}
        showBorder={showBorder}
        valorId={valorId}
      />
    );
  }
  return (
    <OtherSignalsChip
      signal={signal}
      showIcon={showIcon}
      showName={showName}
      showBorder={showBorder}
    />
  );
}
export default SignalChip;
