import React, { useRef } from "react";
import { TextField } from "@mui/material";

type DollarInputProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
  dataCy?: string;
  error?: boolean;
};

const formatNumberWithCommas = (value: number): string => {
  if (value === undefined) return "";

  // Remove any existing commas and non-numeric characters except decimal point
  const cleanValue = String(value).replace(/[^\d.]/g, "");

  const parts = cleanValue.split(".");
  if (parts.length > 2) {
    parts.splice(2); // Remove extra decimal parts
  }

  const integerPart = parts[0];
  const decimalPart = parts[1];

  const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return decimalPart !== undefined
    ? `${formattedInteger}.${decimalPart}`
    : formattedInteger;
};

const removeCommas = (value: string): string => (value ? value.replace(/,/g, "") : "");

export default function DollarInput({
  value,
  onChange,
  onBlur,
  label,
  required,
  disabled,
  dataCy,
  error,
}: DollarInputProps) {
  const displayValue = formatNumberWithCommas(value);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const newValue = input.value;
    const cursorPosition = input.selectionStart;

    if (newValue === "") {
      onChange("");
      return;
    }

    // Only proceed if the input matches our desired pattern
    if (!/^\$?[0-9,]*\.?\d*$/.test(newValue)) {
      return;
    }

    const beforeCommas = (newValue.slice(0, cursorPosition).match(/,/g) || [])
      .length;
    const formattedValue = formatNumberWithCommas(newValue);
    const afterCommas = (
      formattedValue.slice(0, cursorPosition).match(/,/g) || []
    ).length;

    onChange(formattedValue);

    requestAnimationFrame(() => {
      if (inputRef.current) {
        const newPosition = (cursorPosition || 0) + (afterCommas - beforeCommas);
        inputRef.current.setSelectionRange(newPosition, newPosition);
      }
    });
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const strippedValue = removeCommas(e.target.value);
    onBlur(strippedValue);
  };

  return (
    <TextField
      label={`${label} - $`}
      value={displayValue}
      onChange={handleChange}
      onBlur={handleBlur}
      variant="standard"
      required={required}
      type="text"
      sx={{ minWidth: "100%" }}
      disabled={disabled}
      inputProps={{
        "data-cy": dataCy,
        inputMode: "decimal",
        ref: inputRef,
      }}
      error={error}
    />
  );
}
