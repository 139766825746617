/* eslint-disable react/jsx-filename-extension */
import React, {
  createContext, useState, useEffect, useContext,
} from "react";

const platformContext = createContext();

function useProvidePlatformInfo() {
  const [onlineStatus, setOnlineStatus] = useState("online");
  const canUseBackgroundSync = "serviceWorker" in navigator && "SyncManager" in window;

  const onConnectionChange = ({ type }) => {
    setOnlineStatus(type);
  };

  useEffect(() => {
    window.addEventListener("online", onConnectionChange);

    return () => window.removeEventListener("online", onConnectionChange);
  });

  useEffect(() => {
    window.addEventListener("offline", onConnectionChange);
    return () => window.removeEventListener("offline", onConnectionChange);
  });

  return {
    onlineStatus,
    canUseBackgroundSync,
  };
}

// eslint-disable-next-line react/prop-types
export function ProvidePlatformInfo({ children }) {
  const provider = useProvidePlatformInfo();
  return (
    <platformContext.Provider value={provider}>
      {children}
    </platformContext.Provider>
  );
}

export const usePlatformInfo = () => useContext(platformContext);
