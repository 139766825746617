/* eslint-disable react/prop-types */
import React, {
  useRef, useState, useMemo, useCallback,
} from "react";
import {
  PersonOutlined,
  PersonAdd,
  LocalAtm,
  PersonAddDisabled,
  Poll,
  CreditCard,
} from "@mui/icons-material";
import { Link, Box, Typography } from "@mui/material";

import dayjs from "dayjs";
import { getSignalExplainability } from "@/api/Signals";
import { styled, useTheme } from "@mui/material/styles";
import { OrganizationSignals } from "@/api/Organization";
import { usePopover } from "@/hooks/useGlobalPopover";
import { useAuth } from "@/hooks/useAuth";
import {
  formatMultiplierFromPercentage,
  formatTotal,
  formatPercentage,
  formatBigDollars,
} from "@/utils/numberFormat";
import {
  get3MonthGrowth,
  getLastValue,
} from "@/utils/calculateTimeSeriesMetrics";
import {
  canSeeMOICChip,
  canSeeMOICScoreWithoutDisclaimer,
} from "@/constants/Roles";
import Progress from "@/ui/atoms/Progress";
import KPICard from "@/ui/molecules/KPICard";

function DepartureText({
  name,
  linkedinUrl,
  previousJobTitle,
  currentCompanyName,
}) {
  return (
    <span>
      {linkedinUrl ? (
        <Link href={linkedinUrl} target="_blank" rel="noreferrer">
          {name}
        </Link>
      ) : (
        name
      )}
      {" has left"}
      {previousJobTitle && ` their job as ${previousJobTitle}`}
      {currentCompanyName > 0 && ` for a job at ${currentCompanyName}.`}
      .
    </span>
  );
}

function HireText({
  name,
  linkedinUrl,
  newJobTitle,
  previousJobTitle,
  previousCompanyName,
}) {
  return (
    <>
      {linkedinUrl ? (
        <Link href={linkedinUrl} target="_blank" rel="noreferrer">
          {name}
        </Link>
      ) : (
        name
      )}
      {" has joined"}
      {newJobTitle && ` as ${newJobTitle}`}
      {previousCompanyName && ` from ${previousCompanyName}`}
      {previousJobTitle
        && previousCompanyName
        && ` where they were ${previousJobTitle}`}
      .
    </>
  );
}

type Signal = OrganizationSignals["signals"][0];
interface SignalChipProps {
  signal: Signal;
  showIcon?: boolean;
  showName?: boolean;
  showBorder?: boolean;
}
export function SignalChip({
  signal,
  showIcon,
  showName,
  showBorder,
}: SignalChipProps) {
  const {
    signalType, score, value, signalId,
  } = signal;

  const theme = useTheme();
  const { user: valorUser } = useAuth();
  const divRef = useRef(null);
  const [isLoading, setLoading] = useState(false);
  const { openPopover } = usePopover();

  const getFimoicaColor = useCallback(
    (scoreFimoica: number) => {
      if (scoreFimoica < 1) {
        return theme.palette.red.dark;
      }
      if (scoreFimoica === 1) {
        return theme.palette.grey[500];
      }
      if (scoreFimoica > 1 && scoreFimoica <= 3) {
        return theme.palette.yellow.main;
      }
      return theme.palette.green.main;
    },
    [theme],
  );

  const getSignalColor = useCallback(
    (s: number) => {
      if (s >= 0.5) {
        return theme.palette.green.main;
      }
      if (s < 0.5 && s > -0.5) {
        return theme.palette.yellow.main;
      }
      return theme.palette.red.dark;
    },
    [theme],
  );

  const signalMapping = useMemo(
    () => ({
      signal_valor_sentiment: {
        name: "Surveys",
        Icon: Poll,
        dataDisplay: ({ score: s }: Signal) => (
          <Typography variant="boldBody2" sx={{ color: getSignalColor(s) }}>
            {score.toFixed(2)}
          </Typography>
        ),
        popover: ({ records = [] }) => (
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "8px", margin: theme.spacing(1) }}
          >
            <Typography variant="boldSubtitle1">Surveys</Typography>
            <Typography
              variant="boldBody2"
              sx={{ color: getSignalColor(score) }}
            >
              {score.toFixed(2)}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ width: "100%", borderBottom: "1px solid black" }}
            >
              Sentiment of the last surveys taken for this company. 1 being the
              most positive and -1 being the most negative.
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="flex-start">
              {records.map(
                ({
                  surveyName, user, completedAt, score: individualScore,
                }) => (
                  <Box
                    key={completedAt}
                    display="flex"
                    flexDirection="column"
                    sx={{
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: theme.spacing(4),
                      padding: theme.spacing(2),
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="boldBody2"
                      sx={{ color: getSignalColor(individualScore) }}
                    >
                      {individualScore.toFixed(2)}
                    </Typography>
                    <Typography variant="subtitle2">{surveyName}</Typography>
                    <Typography variant="subtitle2">
                      {user.firstName}
                      {" "}
                      {user.lastName}
                      {" on "}
                      {dayjs(completedAt).format("MM/DD/YYYY")}
                    </Typography>
                  </Box>
                ),
              )}
            </Box>
          </Box>
        ),
        onClickDisabled: false,
      },
      signal_mantis_prediction: {
        name: "MOIC Model (Series A)",
        Icon: LocalAtm,
        dataDisplay: ({ score: s }: Signal) => (
          <Typography variant="boldBody2" sx={{ color: getFimoicaColor(s) }}>
            {score <= 0 ? "0x" : `${score.toFixed(2)}x`}
          </Typography>
        ),
        popover: () => null,
        onClickDisabled: true,
      },
      signal_employee_growth: {
        name: "Employees",
        Icon: PersonOutlined,
        dataDisplay: ({ value: v, score: s }: Signal) => (
          <Typography
            sx={{ whiteSpace: "nowrap", color: getSignalColor(s) }}
            variant="boldBody2"
          >
            {formatMultiplierFromPercentage(v) ?? 0}
          </Typography>
        ),
        popover: ({ records = [] }) => (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="boldSubtitle1">Employees</Typography>
            <Typography
              sx={{ whiteSpace: "nowrap", color: getSignalColor(score) }}
              variant="boldBody2"
            >
              {formatMultiplierFromPercentage(value) ?? 0}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", width: "100%" }}
            >
              Employee Growth YoY Growth (Smoothed)
            </Typography>
            <KPICard
              title="Employee Headcount"
              metrics={[
                {
                  label: "3 Month Growth",
                  value: formatPercentage(get3MonthGrowth(records)),
                },
                {
                  label: "Currently",
                  value: formatTotal(getLastValue(records)),
                },
              ]}
              dataFormatter={formatTotal}
              data={records}
              keys={["employee"]}
            />
          </Box>
        ),
        onClickDisabled: false,
      },
      signal_second_measure_growth: {
        name: "Sales",
        Icon: CreditCard,
        dataDisplay: ({ value: v, score: s }: Signal) => (
          <Typography
            sx={{ whiteSpace: "nowrap", color: getSignalColor(s) }}
            variant="boldBody2"
          >
            {formatMultiplierFromPercentage(v) ?? 0}
          </Typography>
        ),
        popover: ({ records = [] }) => (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="boldSubtitle1">Sales</Typography>
            <Typography
              sx={{ whiteSpace: "nowrap", color: getSignalColor(score) }}
              variant="boldBody2"
            >
              {formatMultiplierFromPercentage(value) ?? 0}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", width: "100%" }}
            >
              Sales Growth YoY Growth (Smoothed)
            </Typography>
            <KPICard
              title="Sales"
              metrics={[
                {
                  label: "3 Month Growth",
                  value: formatPercentage(get3MonthGrowth(records)),
                },
                {
                  label: "Currently",
                  value: formatBigDollars(getLastValue(records)),
                },
              ]}
              dataFormatter={formatBigDollars}
              data={records}
              keys={["sales"]}
            />
          </Box>
        ),
        onClickDisabled: false,
      },
      signal_executive_departure: {
        name: "Executive Departure",
        Icon: PersonAddDisabled,
        dataDisplay: ({ score: s }: Signal) => (
          <Typography variant="boldBody2">{s}</Typography>
        ),
        popover: ({ records = [] }) => (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="boldSubtitle1">Exec Departure</Typography>
            <Typography variant="boldBody2">{score}</Typography>
            <Typography
              variant="subtitle2"
              sx={{ textAlign: "center", width: "100%" }}
            >
              Number of Executives who have recently departed.
            </Typography>
            {records.map((record) => (
              <DepartureText
                key={
                  record.name
                  + record.previousJobTitle
                  + record.currentCompanyName
                }
                name={record.name}
                linkedinUrl={record.linkedinUrl}
                previousJobTitle={record.previousJobTitle}
                currentCompanyName={record.currentCompanyName}
              />
            ))}
          </Box>
        ),
        onClickDisabled: false,
      },
      signal_executive_hire: {
        name: "Executive Hire",
        Icon: PersonAdd,
        dataDisplay: ({ score: s }: Signal) => (
          <Typography variant="boldBody2">{s}</Typography>
        ),
        popover: ({ records = [] }) => (
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="boldSubtitle1">Exec Hires</Typography>
            <Typography variant="boldBody2">{score}</Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              {records.map((record) => (
                <HireText
                  key={
                    record.name
                    + record.newJobTitle
                    + record.previousJobTitle
                    + record.previousCompanyName
                  }
                  name={record.name}
                  linkedinUrl={record.linkedinUrl}
                  newJobTitle={record.newJobTitle}
                  previousJobTitle={record.previousJobTitle}
                  previousCompanyName={record.previousCompanyName}
                />
              ))}
            </Box>
          </Box>
        ),
        onClickDisabled: false,
      },
    }),
    [getFimoicaColor, getSignalColor, score, theme, value],
  );

  if (!signalMapping[signalType]) {
    return null;
  }

  const {
    name, Icon, dataDisplay, onClickDisabled, popover,
  } = signalMapping[signalType];

  const isBig = showName && showIcon;
  const SignalBox = styled(Box)(() => ({
    display: "flex",
    border: showBorder ? `1px solid ${theme.palette.divider}` : null,
    flexDirection: isBig ? "column" : "row",
    justifyContent: isBig ? "center" : "flex-start",
    gap: isBig ? null : "8px",
    alignItems: "center",
    padding: theme.spacing(1),
    width: isBig ? "120px" : "96px",
    height: isBig ? "80px" : "40px",
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(1),
    cursor: onClickDisabled ? "auto" : "pointer",
    "&:hover": {
      backgroundColor: onClickDisabled
        ? theme.palette.background.paper
        : theme.palette.action.hover,
    },
  }));

  if (
    !canSeeMOICScoreWithoutDisclaimer(valorUser)
    && signalType === "signal_mantis_prediction"
  ) {
    return null;
  }

  return (
    <div ref={divRef} key={signalId}>
      <SignalBox
        onClick={async () => {
          if (!onClickDisabled) {
            setLoading(true);
            try {
              const signalExplain = await getSignalExplainability(signalId);
              openPopover(divRef, popover(signalExplain));
            } catch (e) {
              console.error("chip render", e);
            } finally {
              setLoading(false);
            }
          }
        }}
      >
        {showName && (
          <Typography sx={{ textAlign: "center" }} variant="boldSubtitle2">
            {name}
          </Typography>
        )}
        {showIcon && Icon && <Icon />}
        {isLoading ? <Progress /> : dataDisplay({ value, score })}
      </SignalBox>
    </div>
  );
}

export default SignalChip;
