import React, { useEffect, useRef } from "react";

import { Box, Skeleton } from "@mui/material";
import { useAuth } from "@/hooks/useAuth";
import { Conversation as ConversationModel } from "@/api/Chat";
import UserChatBubble from "./UserChatBubble";
import GrokChatBubble from "./GrokChatBubble";

export default function Conversation({
  conversation,
  loading,
}: {
  conversation: ConversationModel;
  loading: boolean;
}) {
  const { user } = useAuth();

  const [controlledScroll, setControlledScroll] = React.useState(false);

  const chatBox = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (!controlledScroll && chatBox.current) {
        chatBox.current.scrollTop = chatBox.current.scrollHeight;
      }
    }, 1);
  }, [conversation, controlledScroll]);

  return (
    <Box
      width="100%"
      flexGrow={1}
      display="flex"
      gap={1}
      flexDirection="column"
      py={1}
      ref={chatBox}
      sx={{
        overflowY: "auto",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": { display: "none" },
        "-ms-overflow-style": "none",
      }}
      onScroll={(e) => {
        if (
          e.target.scrollHeight - e.target.scrollTop <
          e.target.clientHeight + 100
        ) {
          setControlledScroll(false);
        } else {
          setControlledScroll(true);
        }
      }}
    >
      {loading && (
        <>
          <Skeleton width="100%" height={50} variant="rounded" />
          <Skeleton width="100%" height={150} variant="rounded" />
          <Skeleton width="100%" height={50} variant="rounded" />
          <Skeleton width="100%" height={200} variant="rounded" />
        </>
      )}
      {conversation.messages?.map((chat) => (
        <Box key={chat.id}>
          {chat.role === "user" ? (
            <UserChatBubble user={user} content={chat.content} />
          ) : (
            <GrokChatBubble content={chat.content} stream={chat.stream} />
          )}
        </Box>
      ))}
    </Box>
  );
}
