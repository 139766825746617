import { styled } from "@mui/system";
import { Box } from "@mui/material";

export default styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(1),
  flexDirection: "row",
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  maxWidth: "100%",
  wordBreak: "break-word",
  "*": {
    wordBreak: "break-word",
    maxWidth: "100%",
  },
  "& pre, & code": {
    maxWidth: "100%",
    overflowX: "auto",
    whiteSpace: "pre-wrap", /* Wraps text within the pre element */
    wordBreak: "break-word",
  },
}));
