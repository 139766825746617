import React, {
  useState, useMemo, useRef, useEffect,
} from "react";
import { useQuery, useQueryClient } from "react-query";
import {
  Box,
  Button,
  Card,
  Grid,
  IconButton,
  Link,
  Skeleton,
  Collapse,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import {
  canWriteMetrics,
} from "@/constants/Roles";
import { useTheme } from "@mui/material/styles";
import xlsx from "json-as-xlsx";
import { GetApp } from "@mui/icons-material";
import {
  getMetricLabels,
  getOrganizationKeyMetrics,
  getMetrics,
  postLabelBusinessSector,
  postMetricLabel,
  getFinanceOrg,
  getOrganizationReportingPeriods,
} from "@/api/Metrics";
import { Organization } from "@/api/Organization";
import { useAuth } from "@/hooks/useAuth";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import DataTable from "@/components/LocalTable";

import KeyMetrics from "./KeyMetrics";
import ReportingPeriods from "./ReportingPeriods";
import CompanyConfigCard from "./CompanyConfigCard";
import NewLabelDialog from "./NewLabelDialog";
import useTripwire from "../useTripwire";
import CalculatedMetricsDialogue from "./CalculatedMetricsDialogue";
import { getPeriodColumns } from "./utils";
import MetricLabelCell from "./Table/MetricLabelCell";
import MetricValueCell from "./Table/MetricValueCell";

const allPeriods = ["MONTHLY", "QUARTERLY", "YEARLY"];

type FinancialsPageProps = {
  company: Organization;
};

function TableLoader() {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Skeleton variant="rounded" width="100%" height={40} />
      {Array(12)
        .fill(0)
        .map((_, i) => (
          <Box
            // eslint-disable-next-line react/no-array-index-key
            key={`table-row-${i}`}
            display="flex"
            flexDirection="row"
            gap={0.5}
          >
            <Skeleton variant="rectangular" width={240} height={24} />
            {Array(10)
              .fill(0)
              .map((__, j) => (
                <Skeleton
                  // eslint-disable-next-line react/no-array-index-key
                  key={`cell-${i}-${j}`}
                  variant="rectangular"
                  height={24}
                  width={120}
                />
              ))}
          </Box>
        ))}
    </Box>
  );
}

export default function FinancialsPage({
  company,
}: FinancialsPageProps) {
  const theme = useTheme();
  const { user } = useAuth();
  const tableContainerRef = useRef(null);
  const queryClient = useQueryClient();
  const isSmDown = useMediaQuery(theme.breakpoints.down("sm"));
  useTripwire(company.valorId, "financials");

  const [showConfig, setShowConfig] = useState(false);
  const [selectedPeriodType, setSelectedPeriodType] = useState(null);
  const [calculatedFieldsDialogOpen, setCalculatedFieldsDialogOpen] = useState(false);
  const [newLabelDialogOpen, setNewLabelDialogOpen] = useState(false);
  const [initialLabelText, setInitialLabelText] = useState("");
  const [newLabelDialogDisabled, setNewLabelDialogDisabled] = useState(false);
  const [isCalculated, setIsCalculated] = useState(false);

  const [authorized, isAuthorized] = useState(true);

  const openNewLabelDialog = (labelText, newIsCalculated) => {
    setNewLabelDialogOpen(true);
    setInitialLabelText(labelText);
    setIsCalculated(newIsCalculated);
  };
  const {
    data: reportingPeriodsData = [],
    isLoading: isReportingPeriodLoading,
  } = useQuery(
    ["reportingPeriods", company.valorId],
    () => getOrganizationReportingPeriods(company.valorId),
    { refetchInterval: false },
  );

  const {
    data: companyConfig,
    isLoading: isCompanyConfigLoading,
    error: companyFinanceConfigError,
    refetch: refetchCompanyFinanceConfig,
  } = useQuery(
    ["CompanyFinanceConfig", company.valorId],
    () => getFinanceOrg(company.valorId),
    {
      refetchInterval: false,
    },
  );

  const {
    data: labelData,
    refetch: refetchLabels,
  } = useQuery(
    "financialLabels",
    () => getMetricLabels(),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          isAuthorized(false);
        }
      },
      retry: false,
    },
  );

  const categories = useMemo(() => {
    if (!labelData) {
      return [];
    }
    return Array.from(new Map(labelData.map((metric) => [metric.businessSectorId, {
      name: metric.categoryLabel,
      id: metric.businessSectorId,
    }])).values());
  }, [labelData]);

  const families = useMemo(() => {
    if (!labelData) {
      return [];
    }
    return Array.from(new Map(labelData.map((metric) => [metric.businessSectorId, {
      name: metric.family,
      id: metric.familyId,
    }])).values());
  }, [labelData]);

  const {
    data: keyMetricsData = [],
  } = useQuery(
    ["keyMetrics", company.valorId],
    () => getOrganizationKeyMetrics(company.valorId),
    {
      onError: (e) => {
        if (e.response.status === 403) {
          isAuthorized(false);
        }
      },
      retry: false,
    },
  );

  const { data: metrics = [], isLoading: metricsLoading, refetch: refetchMetrics } = useQuery(
    ["Metrics", company.valorId, selectedPeriodType],
    async () => getMetrics([company.valorId], selectedPeriodType),
    {
      enabled: selectedPeriodType !== "",
      onError: (e) => {
        if (e.response.status === 403) {
          isAuthorized(false);
        }
      },
      retry: false,
    },
  );
  useEffect(() => {
    if (
      metrics.length && tableContainerRef.current
      && tableContainerRef.current.firstChild
    ) {
      // eslint-disable-next-line max-len
      tableContainerRef.current.firstChild.scrollLeft = tableContainerRef.current.firstChild.scrollWidth;
    }
  }, [metrics, tableContainerRef]);

  useEffect(() => {
    queryClient.prefetchQuery(["Metrics", company.valorId, "MONTHLY"], () => getMetrics([company.valorId], "MONTHLY"));
    queryClient.prefetchQuery(["Metrics", company.valorId, "QUARTERLY"], () => getMetrics([company.valorId], "QUARTERLY"));
    queryClient.prefetchQuery(["Metrics", company.valorId, "YEARLY"], () => getMetrics([company.valorId], "YEARLY"));
  }, [company.valorId, queryClient]);

  useEffect(() => {
    if (
      !isReportingPeriodLoading && reportingPeriodsData.length > 0
      && !selectedPeriodType) {
      setSelectedPeriodType(reportingPeriodsData[0].periodType);
    } else if (
      !isReportingPeriodLoading && reportingPeriodsData.length === 0
      && !selectedPeriodType) {
      setSelectedPeriodType("MONTHLY");
    }
  }, [reportingPeriodsData, isReportingPeriodLoading, setSelectedPeriodType, selectedPeriodType]);

  const metricData = useMemo(() => {
    if (!metrics.length) {
      return {
        shownMetrics: [],
        borderStyle: {},
      };
    }
    let shownMetrics = {};

    metrics.forEach((metric) => {
      if (shownMetrics[metric.name] === undefined) {
        shownMetrics[metric.name] = {
          name: metric.name,
          entityId: metric.valorId,
          labelId: metric.labelId,
          isCalculated: metric.isCalculated,
          categorySort: metric.categorySort,
          valueType: metric.valueType,
          familySort: metric.familySort,
          data: {},
        };
      }
      shownMetrics[metric.name].data[metric.periodName] = metric;
    });
    shownMetrics = Object.values(shownMetrics);

    shownMetrics = shownMetrics.sort((a, b) => {
      if (a.familySort === b.familySort) {
        if (a.categorySort === b.categorySort) {
          if (a.isCalculated !== b.isCalculated) {
            return a.isCalculated ? -1 : 1;
          }
          return a.name.localeCompare(b.name);
        }
        return a.categorySort - b.categorySort;
      }

      return a.familySort - b.familySort;
    });

    const borderStyleIndices = [];
    let lastSeenId = null;
    shownMetrics.forEach((metric, idx) => {
      if (lastSeenId === null) {
        lastSeenId = metric.familySort;
      }
      if (metric.familySort !== lastSeenId && idx < shownMetrics.length - 1) {
        borderStyleIndices.push(idx);
        lastSeenId = metric.familySort;
      }
    });

    return {
      shownMetrics,
      borderStyle: borderStyleIndices.reduce((acc, index) => {
        acc[`& tr:nth-of-type(${index}) > *`] = {
          borderBottom: `3px solid ${theme.palette.divider} !important`,
        };
        return acc;
      }, {}),
    };
  }, [metrics, theme.palette.divider]);

  const columns = useMemo(() => {
    const col = [];
    if (!companyConfig) return col;
    const periodColumns = getPeriodColumns(
      companyConfig.startYear,
      companyConfig.fyeMonth !== 12,
      selectedPeriodType,
    );
    col.push({
      header: "Label",
      accessorKey: "name",
      cell: MetricLabelCell,
      minSize: 260,
      enableSorting: false,
      enableColumnFilter: false,
    });
    periodColumns.forEach((period) => {
      col.push({
        header: period,
        id: period,
        cell: (props) => (
          <MetricValueCell
            {...props}
            period={period}
            periodType={selectedPeriodType}
          />
        ),
        enableSorting: false,
        enableColumnFilter: false,
        accessorFn: (row) => {
          if (row.data[period]) {
            return row.data[period];
          }
          return null;
        },
      });
    });
    return col;
  }, [selectedPeriodType, companyConfig]);
  const getExcelData = (tableMetrics) => {
    const data = [];
    tableMetrics.forEach((metric) => {
      const row = {
        Label: metric.name,
      };
      Object.keys(metric.data).forEach((period) => {
        const value = metric.data[period]?.value;
        if (value === "Infinity") {
          row[period] = "N/A";
        } else {
          row[period] = value;
        }
      });
      data.push(row);
    });
    return data;
  };
  if (!authorized) {
    return (
      <ErrorMessage
        Icon={<LockIcon />}
        title="Unauthorized"
        message={(
          <>
            You don’t have access to Financials for this company. If you think this is
            an error, please contact
            {" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={(e) => {
                window.location.href = "mailto:labs@valorep.com?subject=Notes Access";
                e.preventDefault();
              }}
            >
              labs@valorep.com
            </Link>
          </>
        )}
      />
    );
  }
  return (
    <Box>
      <Box>
        <Collapse in={showConfig}>
          <Grid container spacing={1} mb={1}>
            <Grid item xs={12} md={6}>
              <Card
                elevation={0}
                sx={{ padding: theme.spacing(1), minHeight: 392 }}
              >
                <ReportingPeriods
                  valorId={company.valorId}
                  disabled={!canWriteMetrics(user)}
                />
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                elevation={0}
                sx={{ padding: theme.spacing(1), minHeight: 392 }}
              >
                <CompanyConfigCard
                  valorId={company.valorId}
                  companyConfig={companyConfig}
                  disabled={!canWriteMetrics(user)}
                  isLoading={isCompanyConfigLoading}
                  error={!!companyFinanceConfigError}
                  refetch={refetchCompanyFinanceConfig}
                />
              </Card>
            </Grid>
          </Grid>
        </Collapse>
        <Card
          elevation={0}
          sx={{
            mb: 2,
          }}
        >
          <Box padding={2}>
            {isReportingPeriodLoading && !selectedPeriodType ? (
              <Box mb={1}>
                <Skeleton variant="rectangular" width="100%" height={40} />
              </Box>
            ) : (
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Box
                  sx={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                  }}
                >
                  <Box
                    display="flex"
                    mt={2}
                    mb={2}
                    flexDirection="row"
                    alignItems="center"
                    gap={1}
                    width="300px"
                  >
                    <ButtonTabs
                      options={allPeriods}
                      onClick={setSelectedPeriodType}
                      activeKey={selectedPeriodType}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: 1,
                      marginBottom: 1,
                    }}
                  >
                    <Box
                      display="flex"
                      gap={1}
                      sx={{
                        flexDirection: isSmDown ? "column" : "row",
                      }}
                    >
                      <KeyMetrics
                        valorId={company.valorId}
                        openNewLabelDialog={openNewLabelDialog}
                        labelSet={labelData}
                        chosenLabels={keyMetricsData}
                        refetchMetrics={refetchMetrics}
                      />
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          height: "56px",
                        }}
                        onClick={() => setCalculatedFieldsDialogOpen(true)}
                        disabled={!canWriteMetrics(user)}
                      >
                        Add Calculated Metric
                      </Button>
                    </Box>
                    <Box>
                      <Button onClick={() => setShowConfig((o) => !o)}>
                        {showConfig ? "Hide Config" : "Show Company Config"}
                      </Button>
                      <Tooltip title="Export to Excel" placement="top">
                        <IconButton
                          sx={{ height: "30px", width: "30px" }}
                          onClick={() => xlsx(
                            [
                              {
                                sheet: selectedPeriodType,
                                columns: columns.map((column) => ({
                                  label: `${column.header} `,
                                  // space is added to prevent excel from converting to date
                                  // do not remove
                                  value: column.header,
                                })),
                                content: getExcelData(metricData.shownMetrics),
                              },
                            ],
                            {
                              fileName: `${company.name} ${selectedPeriodType} Financials`,
                              extraLength: 3,
                              writeOptions: {},
                            },
                          )}
                        >
                          <GetApp />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
              </Box>
            )}
            {metricsLoading
            || isCompanyConfigLoading
            || isReportingPeriodLoading ? (
              <TableLoader />
              ) : (
                <Box
                  ref={tableContainerRef}
                  sx={{
                    ...metricData.borderStyle,
                    "& > div > table tbody >tr > td": {
                      padding: "4px !important",
                    },
                  }}
                >
                  <DataTable columns={columns} data={metricData.shownMetrics} />
                </Box>
              )}
          </Box>
        </Card>
      </Box>

      <CalculatedMetricsDialogue
        valorId={company.valorId}
        open={calculatedFieldsDialogOpen}
        openNewLabelDialog={openNewLabelDialog}
        setClose={() => {
          setCalculatedFieldsDialogOpen(false);
          refetchMetrics();
        }}
        labelSet={labelData}
        chosenLabels={keyMetricsData}
      />
      <NewLabelDialog
        open={newLabelDialogOpen}
        onClose={() => setNewLabelDialogOpen(false)}
        initialLabelText={initialLabelText}
        categories={categories}
        parentLabels={families}
        disabled={newLabelDialogDisabled}
        onSubmit={async ({
          category,
          label,
          businessSectorId,
          canonicalParentMetricId,
          canRollup,
          metricFamilyId,
          valueType,
        }) => {
          setNewLabelDialogDisabled(true);

          // create category if new
          let finalbusinessSectorId = businessSectorId;
          if (category) {
            finalbusinessSectorId = crypto.randomUUID();
            await postLabelBusinessSector(finalbusinessSectorId, category);
          }
          try {
            // create label if new
            await postMetricLabel({
              labelName: label,
              businessSectorId: finalbusinessSectorId,
              canonicalParentMetricId,
              canRollup,
              isCalculated,
              metricFamilyId,
              valueType,
            });
          } catch (e) {
            console.error(e);
          } finally {
            setNewLabelDialogDisabled(false);
          }
          await refetchLabels();
          refetchMetrics();

          // close dialog
          setNewLabelDialogOpen(false);
          setNewLabelDialogDisabled(false);
        }}
      />
    </Box>
  );
}
