import React, { useMemo } from "react";
import {
  Box,
  Link,
  Typography,
  Dialog,
  Button,
  IconButton,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewTabIcon from "@mui/icons-material/OpenInNew";
import ClampsTooltip from "@/components/ClampsTooltip";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";

export default function CompanyCell({ value, shrink = false }: {
  value: {
    valorId: string;
    logoUrl: string;
    name: string;
    domain: string;
    description?: string;
  }
  shrink?: boolean;
}) {
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const Wrapper = useMemo(() => (function NewWrapper() {
    let returnValue = ({ children }) => children;
    if (value?.valorId) {
      returnValue = function LinkWrapper({ children }: { children: React.ReactNode }) {
        return (
          <Tooltip title={`Open ${value.name} page in new tab`}>
            <Link
              href={`/org/${value.valorId}`}
              target="_blank"
              sx={{
                textDecoration: "none",
                color: "inherit",
                height: "100%",
              }}
            >
              {children}
            </Link>
          </Tooltip>
        );
      };
    }

    if (value?.description && isSmDown) {
      return function DescriptionWrapper({ children }: { children: React.ReactNode }) {
        const [showPopover, setShowPopover] = React.useState(false);
        return (
          <Box display="flex" justifyContent="center" height="100%" width="100%">
            <Box
              onClick={() => setShowPopover(true)}
              sx={{
                cursor: "pointer",
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {children}
            </Box>
            <Dialog
              open={showPopover}
              onClose={() => setShowPopover(false)}
              maxWidth="sm"
              fullWidth
            >
              <Box
                display="flex"
                alignItems="center"
                width="100%"
                padding={2}
              >
                <Box
                  marginRight={3}
                >
                  <CompanyAvatar
                    src={value.logoUrl ? value.logoUrl : undefined}
                    name={value.name}
                    domain={value.domain}
                    size="100"
                  />
                </Box>
                <Box
                  maxWidth="100%"
                  overflow="hidden"
                >
                  <Typography
                    variant="h3"
                  >
                    {value.name}
                  </Typography>
                  <Link
                    href={`https://${value.domain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Typography
                      variant="body1"
                    >
                      {value.domain}
                    </Typography>
                  </Link>
                </Box>
                <IconButton
                  sx={{
                    marginLeft: "auto",
                    alignSelf: "flex-start",
                    justifySelf: "flex-end",
                  }}
                  onClick={() => {
                    setShowPopover(false);
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </Box>
              <Box
                sx={{
                  px: 2,
                }}
              >
                <Typography variant="body2">
                  {value.description}
                </Typography>

                <Button
                  variant="outlined"
                  onClick={() => {
                    window.open(`/org/${value.valorId}`, "_blank");
                  }}
                  sx={{
                    my: 2,
                  }}
                  fullWidth
                  endIcon={<OpenInNewTabIcon />}
                >
                  Open Company in new tab
                </Button>
              </Box>
            </Dialog>
          </Box>
        );
      };
    }
    return returnValue;
  }()), [value?.description, isSmDown, value?.valorId, value?.name, value?.domain, value?.logoUrl]);

  if (!value) return <>-</>;

  return (
    <Wrapper>
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        overflow="hidden"
        whiteSpace="nowrap"
        maxWidth="100%"
        justifyContent={isSmDown ? "center" : "flex-start"}
        sx={{
          width: { xs: 48, sm: 196, md: 248 },
        }}
        height="100%"
      >
        <Box minWidth={shrink ? "32" : "40"}>
          <CompanyAvatar
            src={value.logoUrl ? value.logoUrl : undefined}
            name={value.name}
            domain={value.domain}
            size={shrink ? "32" : "40"}
          />
        </Box>
        {!isSmDown && (
          <Box maxWidth="100%" overflow="hidden">
            <ClampsTooltip
              title={`${value.name}
          ${value.domain}`}
            >
              <Typography
                variant="body1"
                sx={{
                  maxWidth: { xs: 100, sm: 150, md: 200 },
                }}
              >
                {value.name}
              </Typography>
            </ClampsTooltip>
            {shrink ? null : (
              <ClampsTooltip title={value.domain}>
                <Typography
                  sx={{
                    maxWidth: { xs: 100, sm: 150, md: 200 },
                  }}
                  variant="body2"
                >
                  {value.domain}
                </Typography>
              </ClampsTooltip>
            )}
          </Box>
        )}
      </Box>
    </Wrapper>
  );
}
