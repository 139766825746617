/* eslint-disable react/prop-types */
import React, { useEffect, useReducer } from "react";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import analytics from "@/shared/analytics";
import {
  Grid,
  Box,
  Button,
  Typography,
  Menu,
  MenuItem,
  Switch,
  FormControlLabel,
  Card,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ReactPaginate from "react-paginate";
import { useQueryParam, StringParam } from "use-query-params";
import Helmet from "react-helmet";
import DescriptionEditor from "@/ui/molecules/DescriptionEditor";
import DiscoveryResult from "@/ui/molecules/DiscoveryResult";
import DualSliderFilter from "@/ui/molecules/DualSliderFilter";
import KeywordEditor from "@/ui/molecules/KeywordEditor";
import SeedCompanyEditor from "@/ui/molecules/SeedCompanyEditor";
import SignalFilter from "@/ui/molecules/SignalFilter";
import Progress from "@/ui/atoms/Progress";
import {
  discoverCompetitors,
  getSearchParams,
  getSavedSearch,
  searchKeywords,
} from "@/api/Discovery";
import { createCsvFromJson } from "@/api/Files";
import { saytForOrg } from "@/api/Search";
import {
  createNeedleCompetitors,
  deleteNeedleCompetitors,
  getNeedleCompetitorsByValorId,
} from "@/api/NeedleCompetitors";
import { formatBigDollars } from "@/utils/numberFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .pagination": {
      display: "inline-flex",
      padding: 0,
      "& li": {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "center",
        width: theme.spacing(4),
        height: theme.spacing(4),
        color: theme.palette.text.secondary,
        listStyle: "none",
        backgroundColor: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        cursor: "pointer",
        "& a": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100%",
        },
        "& a:hover": {
          color: theme.palette.primary.main,
        },
      },
      "& li.disabled-page": {
        color: theme.palette.text.secondary,
        backgroundColor: theme.palette.action.disabled,
      },
      "& .active": {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
  },
  focusedMenuItem: {
    color: theme.palette.primary.main,
  },
  resultHolder: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    alignItems: "center",
    justifyContent: "flex-start",
  },
  filterTitle: {
    width: "100%",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
    textAlign: "left",
  },
}));
const NUMBERS_PER_PAGE = 20;

const RAISED_FILTER_MIN = 100_000;
const RAISED_FILTER_MAX = 5_000_000_000;

const reducer = (state, action) => {
  analytics.track("DISCOVER change value", { state, action });
  switch (action.type) {
    case "changeValue":
      return { ...state, [action.field]: action.valueaction.field };
    case "appendValue":
      return { ...state, [action.field]: [...state[action.field], action.value] };
    case "replaceState":
      return { ...action.state };
    case "mergeState":
      return { ...state, ...action.state };
    case "changeArrayValue": {
      const { field, index, value } = action;
      const newState = { ...state };
      newState[field][index] = value;
      return newState;
    }
    default:
      throw new Error();
  }
};

function OrganizationDiscover(props) {
  const { company, id } = props;

  const initialState = {
    anchorEl: null,
    status: "loading",
    focusedSortIndex: 0,
    results: [],
    signalFilter: [],
    filterRaised: [RAISED_FILTER_MIN, RAISED_FILTER_MAX],
    filterUsaOnly: false,
    description: company?.description || "",
    seedCompanies: [],
    keywords: {
      keywords: [],
      requiredKeywords: [],
      bannedKeywords: [],
    },
    pagination: {
      data: [],
      offset: 0,
      numberPerPage: NUMBERS_PER_PAGE,
      pageCount: 0,
      currentData: [],
    },
  };
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const [searchId, setSearchId] = useQueryParam("saved_search_id", StringParam);

  const handleFilterRaised = (val) => {
    analytics.track("DISCOVER filter funding", { val });
    dispatch({ type: "changeValue", field: "filterRaised", value: val });
  };

  const handleUsaOnly = (val) => {
    analytics.track("DISCOVER filter USA", { val });
    dispatch({ type: "changeValue", field: "filterUsaOnly", value: val });
  };

  const handleSignalFilter = (val) => {
    analytics.track("DISCOVER filter signal", { val });
    dispatch({ type: "changeValue", field: "signalFilter", value: val });
  };

  const handleDescriptionChange = (newDescription) => {
    analytics.track("DISCOVER descriptionChange", {
      newDescription,
      oldDescription: state.description,
    });
    dispatch({
      type: "changeValue",
      field: "description",
      value: newDescription,
    });
  };

  const resetFilters = () => {
    dispatch(
      {
        type: "mergeState",
        state: {
          signalFilter: [],
          filterRaised: [RAISED_FILTER_MIN, RAISED_FILTER_MAX],
          filterUsaOnly: false,
          description: company?.description || "",
          seedCompanies: [],
          keywords: {
            keywords: [],
            requiredKeywords: [],
            bannedKeywords: [],
          },
        },
      },
    );
    navigate(pathname, { replace: true });
  };
  const fetchSavedSearchData = async () => {
    try {
      const savedSearch = await getSavedSearch(searchId);
      analytics
        .track("DISCOVER SAVED_SEARCH", { searchId, id });
      const {
        keywords,
        bannedKeywords,
        requiredKeywords,
        description,
        filterRaised,
        filterUSAOnly,
        signalFilter,
        competitors,
      } = savedSearch;
      const copyFilterRaised = [...filterRaised];
      if (copyFilterRaised[0] === null) {
        copyFilterRaised[0] = RAISED_FILTER_MIN;
      }
      if (copyFilterRaised[1] === null) {
        copyFilterRaised[1] = RAISED_FILTER_MAX;
      }

      const preparedState = {
        description: description || company?.description || "",
        filterRaised: copyFilterRaised,
        filterUsaOnly: filterUSAOnly,
        signalFilter: signalFilter || [],
        seedCompanies: competitors || [],
        keywords: {
          keywords: keywords || [],
          bannedKeywords: bannedKeywords || [],
          requiredKeywords: requiredKeywords || [],
        },
      };
      dispatch({
        type: "mergeState",
        state: {
          ...preparedState,
        },
      });
      return preparedState;
    } catch ({ response }) {
      if (response?.status === 404 || response?.status === 400) {
        navigate(pathname);
      }
    }
    return null;
  };

  const discoverCompanies = async (searchParams) => {
    dispatch({ type: "changeValue", field: "status", value: "loading" });
    try {
      const copyFilterRaised = [
        ...(searchParams.filterRaised ?? [RAISED_FILTER_MIN, RAISED_FILTER_MAX]),
      ];
      if (copyFilterRaised[0] === RAISED_FILTER_MIN) copyFilterRaised[0] = null;
      if (copyFilterRaised[1] === RAISED_FILTER_MAX) copyFilterRaised[1] = null;
      const params = {
        valorId: id,
        domain: company?.domain,
        description: searchParams.description ?? company?.description ?? "",
        keywords: searchParams.keywords.keywords ?? [],
        requiredKeywords: searchParams.keywords.requiredKeywords ?? [],
        bannedKeywords: searchParams.keywords.bannedKeywords ?? [],
        competitorValorIds:
          searchParams?.seedCompanies?.map((c) => c.valorId) ?? [],
        signalFilter: searchParams.signalFilter ?? [],
        filterRaised: copyFilterRaised,
        filterUSAOnly: searchParams.filterUsaOnly ?? false,
      };
      const response = await discoverCompetitors(params);
      const { results: discoverResults, savedSearchId } = response;
      analytics.track("DISCOVER search", params);
      if (savedSearchId) {
        setSearchId(savedSearchId);
        dispatch({
          type: "changeValue",
          field: "results",
          value: discoverResults,
        });
      }
      dispatch({
        type: "mergeState",
        state: {
          pagination: {
            ...state.pagination,
            data: discoverResults,
            offset: 0,
            currentData: discoverResults.slice(
              state.pagination.offset,
              state.pagination.offset + state.pagination.numberPerPage,
            ),
            pageCount: Math.ceil(discoverResults.length / NUMBERS_PER_PAGE),
          },
          status: "success",
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      if (state.status !== "success") {
        dispatch({ type: "changeValue", field: "status", value: "success" });
      }
    }
  };

  const fetchSearchParams = async () => {
    const params = await getSearchParams({
      valorId: id,
      competitor_valor_ids: state.seedCompanies?.map((x) => x.valorId) || [],
    });
    dispatch({
      type: "changeValue",
      field: "keywords",
      value: {
        keywords: [...(params.keywords || [])],
        bannedKeywords: [],
        requiredKeywords: [],
      },
    });
    return {
      keywords: [...(params.keywords || [])],
      bannedKeywords: [],
      requiredKeywords: [],
    };
  };

  useEffect(() => {
    const getParamsThenSearch = async () => {
      const params = await fetchSearchParams();
      await discoverCompanies(params);
    };
    const getSavedSearchFiltersThenSearch = async () => {
      const searchData = await fetchSavedSearchData();
      await discoverCompanies(searchData);
    };

    if (searchId) {
      getSavedSearchFiltersThenSearch();
    } else {
      getParamsThenSearch();
    }
  }, []);

  const { refetch: refetchNeedles, data: needleCompetitors = [] } = useQuery(
    ["Competitors", id],
    async () => {
      const response = await getNeedleCompetitorsByValorId([id]);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  const needleValorIdMap = needleCompetitors
    .map((x) => x.valorId)
    .reduce((a, v) => ({ ...a, [v]: true }), {});

  const sortCompetitors = (key, direction, data) => data.sort((a, b) => {
    if ((a[key] && b[key] === undefined) || a[key] === b[key]) {
      return 0;
    }
    if (a[key] === undefined || a[key] < b[key]) {
      return direction === "asc" ? 1 : -1;
    }
    if (b[key] === undefined || b[key] < a[key]) {
      return direction === "asc" ? -1 : 1;
    }
    return 0;
  });

  useEffect(() => {
    if (!state.results?.length) return;

    const sorted = sortCompetitors("overallSimilarity", "asc", state.results);
    dispatch({
      type: "mergeState",
      state: {
        focusedSortIndex: 0,
        pagination: {
          ...state.pagination,
          data: sorted,
          pageCount: sorted.length / state.pagination.numberPerPage,
          offset: 0,
          currentData: sorted.slice(
            state.pagination.offset,
            state.pagination.offset + state.pagination.numberPerPage,
          ),
        },
      },
    });
  }, [state.results]);

  useEffect(() => {
    dispatch({
      type: "changeValue",
      field: "pagination",
      value: {
        ...state.pagination,
        pageCount:
          state.pagination.data.length / state.pagination.numberPerPage,
        currentData: state.pagination.data.slice(
          state.pagination.offset,
          state.pagination.offset + state.pagination.numberPerPage,
        ),
      },
    });
  }, [state.pagination.numberPerPage, state.pagination.offset]);

  const handlePageClick = ({ selected }) => {
    const offset = selected * state.pagination.numberPerPage;
    dispatch({ type: "changeValue", field: "pagination", value: { ...state.pagination, offset } });
  };

  const handleCloseMenu = () => {
    dispatch({ type: "changeValue", field: "anchorEl", value: null });
  };

  const handleCompetitorListClick = async (isCompetitor, selectedValorId) => {
    analytics.track("DISCOVER Competitor Clicked for Needle", {
      selectedValorId,
      rootId: id,
      action: isCompetitor ? "unmarked competitor" : "marked as competitor",
    });
    if (id !== selectedValorId) {
      if (isCompetitor) {
        await deleteNeedleCompetitors(id, [selectedValorId]);
      } else {
        await createNeedleCompetitors(id, [selectedValorId]);
      }
      refetchNeedles();
    }
  };

  const handleSortClick = (key, direction, index) => {
    const shallowCopy = [...state.pagination.data];
    const sort = sortCompetitors(key, direction, shallowCopy);
    dispatch({
      type: "mergeState",
      state: {
        anchorEl: null,
        pagination: {
          ...state.pagination,
          data: sort,
          offset: 0,
          currentData: sort.slice(0, 0 + state.pagination.numberPerPage),
        },
        focusedSortIndex: index,
      },
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${company.name} - vOS Competitor Search`}</title>
        <meta
          property="og:title"
          content={`${company.name} - vOS Competitor Search`}
        />
        <meta
          property="og:description"
          content={`Search for competitors to ${company.name} on vOS`}
        />
      </Helmet>
      <Grid container spacing={1} className={classes.root}>
        <Grid direction="column" item container xs={12} md={3} lg={3} xl={2}>
          <Card
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              padding: "8px",
            }}
          >
            <Typography variant="h2">Inputs</Typography>
            <SeedCompanyEditor
              seedCompanies={state.seedCompanies}
              searchCompanies={saytForOrg}
              onSubmit={(companies) => dispatch({ type: "changeValue", field: "seedCompanies", value: companies })}
            />
            <DescriptionEditor
              description={state.description}
              onSubmit={(d) => handleDescriptionChange(d)}
            />
            {/* {!isFetchingParams || isFetchingSavedSearch ? ( */}
            {state.status !== "loading" ? (
              <KeywordEditor
                keywords={state.keywords}
                searchKeywords={searchKeywords}
                onDelete={(keyword, keywordType) => {
                  dispatch({
                    type: "changeValue",
                    field: "keywords",
                    value: {
                      ...state.keywords,
                      [keywordType]: state.keywords[keywordType].filter(
                        (k) => k !== keyword,
                      ),
                    },
                  });
                }}
                onSubmit={(kws) => dispatch({
                  type: "changeValue",
                  field: "keywords",
                  value: kws,
                })}
              />
            ) : (
              <Progress sx={{ alignSelf: "center" }} />
            )}

            <DualSliderFilter
              initialValue={state.filterRaised}
              valueLabelFormat={formatBigDollars}
              min={0}
              step={1}
              max={12}
              descale={(x) => {
                const invertMapping = {
                  100_000: 0,
                  500_000: 1,
                  1_000_000: 2,
                  5_000_000: 3,
                  10_000_000: 4,
                  20_000_000: 5,
                  50_000_000: 6,
                  100_000_000: 7,
                  200_000_000: 8,
                  500_000_000: 9,
                  1_000_000_000: 10,
                  2_000_000_000: 11,
                  5_000_000_000: 12,
                };
                return invertMapping[x];
              }}
              scale={(x) => {
                const mapping = {
                  0: 100_000,
                  1: 500_000,
                  2: 1_000_000,
                  3: 5_000_000,
                  4: 10_000_000,
                  5: 20_000_000,
                  6: 50_000_000,
                  7: 100_000_000,
                  8: 200_000_000,
                  9: 500_000_000,
                  10: 1_000_000_000,
                  11: 2_000_000_000,
                  12: 5_000_000_000,
                };
                return mapping[x];
              }}
              title="TOTAL RAISED"
              onSlide={handleFilterRaised}
            />
            <SignalFilter
              value={state.signalFilter}
              onChange={(val) => handleSignalFilter([...val])}
            />
            <Box>
              <Typography className={classes.filterTitle}>LOCATION</Typography>
              <FormControlLabel
                control={(
                  <Switch
                    checked={state.filterUsaOnly}
                    onChange={() => handleUsaOnly(!state.filterUsaOnly)}
                    name="Filter USA"
                    color="primary"
                  />
                )}
                label="Only US Companies"
              />
            </Box>
            <Box display="flex" justifyContent="center">
              <Button onClick={() => resetFilters()}>Reset Filters</Button>
            </Box>
          </Card>
        </Grid>
        <Grid item container xs={12} md={9} lg={9} xl={10}>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              style={{ marginBottom: "16px", gap: "16px" }}
            >
              <Button
                variant="outlined"
                color="secondary"
                onClick={({ currentTarget }) => dispatch({
                  type: "changeValue",
                  field: "anchorEl",
                  value: currentTarget,
                })}
              >
                Sort By
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => discoverCompanies(state)}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => state.results?.length
                  && createCsvFromJson(`${company.name} Discovery List`, state.results)}
              >
                Download Data
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} style={{ height: "100%" }}>
            {state.status === "loading" && (
              <Box className={classes.resultHolder}>
                <Progress />
              </Box>
            )}
            {state.status === "success" && Boolean(state.pagination.currentData.length) && (
              <>
                <Box className={classes.resultHolder}>
                  {state.pagination.currentData?.map((c) => (
                    <DiscoveryResult
                      key={`${c.valorId}-${c.domain} `}
                      company={c}
                      isCompetitor={needleValorIdMap[c.valorId] ?? false}
                      onSelect={() => handleCompetitorListClick(
                        needleValorIdMap[c.valorId] ?? false,
                        c.valorId,
                      )}
                    />
                  ))}
                </Box>
                <ReactPaginate
                  previousLabel={<NavigateBeforeIcon />}
                  nextLabel={<NavigateNextIcon />}
                  breakLabel="..."
                  pageCount={state.pagination.pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  pageClassName="page"
                  previousClassName="previous"
                  nextClassName="next"
                  disabledClassName="disabled-page"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </>
            )}
            {state.status === "success"
              && !state.pagination.currentData.length
              && !state.status === "loading" && (
                <div> No Results Found. Try modifying keywords or filters</div>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Menu
        id="simple-menu"
        anchorEl={state.anchorEl}
        keepMounted
        open={Boolean(state.anchorEl)}
        onClose={handleCloseMenu}
      >
        <MenuItem
          className={state.focusedSortIndex === 0 ? classes.focusedMenuItem : null}
          dense
          onClick={() => handleSortClick("overallSimilarity", "asc", 0)}
        >
          Most Relevant
        </MenuItem>
        <MenuItem
          className={state.focusedSortIndex === 1 ? classes.focusedMenuItem : null}
          dense
          onClick={() => handleSortClick("overallSimilarity", "desc", 1)}
        >
          Least Relevant
        </MenuItem>
        <MenuItem
          className={state.focusedSortIndex === 2 ? classes.focusedMenuItem : null}
          dense
          onClick={() => handleSortClick("lastRoundTotalUsd", "asc", 2)}
        >
          Last Round: High to Low
        </MenuItem>
        <MenuItem
          className={state.focusedSortIndex === 3 ? classes.focusedMenuItem : null}
          dense
          onClick={() => handleSortClick("lastRoundTotalUsd", "desc", 3)}
        >
          Last Round: Low to High
        </MenuItem>
        <MenuItem
          className={state.focusedSortIndex === 4 ? classes.focusedMenuItem : null}
          dense
          onClick={() => handleSortClick("totalFundingUsd", "asc", 4)}
        >
          Total Funding: High to Low
        </MenuItem>
        <MenuItem
          className={state.focusedSortIndex === 5 ? classes.focusedMenuItem : null}
          dense
          onClick={() => handleSortClick("totalFundingUsd", "desc", 5)}
        >
          Total Funding: Low to High
        </MenuItem>
      </Menu>
    </>
  );
}

OrganizationDiscover.propTypes = {
  // company: Company.isRequired,company
};

export default OrganizationDiscover;
