import React from "react";
import { useField } from "react-final-form";
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import { styled } from "@mui/material/styles";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  SelectChangeEvent,
} from "@mui/material";

dayjs.extend(quarterOfYear);

const SelectFormControl = styled(FormControl)(({ theme }) => ({
  width: theme.spacing(32),
}));

interface Props {
  questionId: string;
  initialComment: string;
}

export default function FutureQuarter({ questionId, initialComment = "" }: Props) {
  const { input, meta } = useField(`${questionId}.commentary`, {
    initialValue: initialComment,
    validate: (v: string) => (v ? undefined : "Required"),
  });

  const currYear = new Date().getFullYear();
  const getRange = (min: number, max: number) => (
    Array.from({ length: max - min + 1 }, (_, i) => min + i)
  );
  const years = getRange(currYear, currYear + 3);
  const quarters = ["Q1", "Q2", "Q3", "Q4"];

  const currentQuarter = dayjs().quarter();

  const yearQuarters = years
    .flatMap((year) => quarters.map((quarter) => `${year}-${quarter}`))
    .filter((yearQuarter) => {
      const [year, q] = yearQuarter.split("-");
      const quarter = parseInt(q.replace("Q", ""), 10);
      return (
        parseInt(year, 10) > currYear
        || (parseInt(year, 10) === currYear && quarter >= currentQuarter)
      );
    });

  const handleChange = (event: SelectChangeEvent) => {
    input.onChange(event.target.value);
  };

  return (
    <Grid item xs={12} data-formstate={meta.error ? "error" : ""}>
      <SelectFormControl
        variant="filled"
        error={meta.submitFailed && !!meta.error}
      >
        <InputLabel error={meta.submitFailed && !!meta.error}>
          {meta.submitFailed && meta.error ? meta.error : "Fiscal Quarter"}
        </InputLabel>
        <Select
          label="Fiscal Quarter"
          value={input.value}
          onChange={handleChange}
          data-cy={`${questionId}-future-quarter`}
          error={meta.submitFailed && !!meta.error}
        >
          <MenuItem value="No New Round Required/Expected">
            No New Round Required/Expected
          </MenuItem>
          {yearQuarters.map((yearQuarter) => (
            <MenuItem
              data-cy={`${questionId}-future-quarter-${yearQuarter}`}
              key={yearQuarter}
              value={yearQuarter}
            >
              {yearQuarter}
            </MenuItem>
          ))}
        </Select>
      </SelectFormControl>
    </Grid>
  );
}
