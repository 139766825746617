/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import axios from "axios";

export const getNewsArticles = async ({
  limit,
  valorId,
  isPipeline,
  isFollowing,
  isPortfolio,
  cursor,
}) => {
  let paramsData = {
    limit, valorId, isPipeline, isFollowing, isPortfolio,
  };
  if (cursor) {
    paramsData = {
      cursors: cursor[0],
      ...paramsData,
    };
  }
  const filtered = Object.keys(paramsData)
    .filter((key) => paramsData[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = paramsData[key];
      return obj;
    }, {});
  const params = `?${new URLSearchParams(filtered).toString()}`;
  try {
    const response = await axios.get(`/api/news-articles${params}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default { getNewsArticles };
