import React from "react";
import {
  Box, Card,
} from "@mui/material";

export default function TitlePage({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Card
        elevation={0}
        sx={{
          height: "auto",
          display: "flex",
          flexDirection: "column",
          gap: 4,
          padding: 2,
          minHeight: "calc(100vh - 300px)",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children}
      </Card>
    </Box>
  );
}
