/* eslint-disable react/prop-types */
import React from "react";
import PropTypes from "prop-types";
import { Card, Typography, useTheme } from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import {
  LineChart, Line, Tooltip, XAxis, ResponsiveContainer, YAxis,
} from "recharts";

dayjs.extend(utc);

function CustomizedAxisTick({
  x, y, payload,
}) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#666"
        transform="rotate(0)"
      >
        {payload.value}
      </text>
    </g>
  );
}
function CustomToolTip({ payload }) {
  if (payload?.length && Array.isArray(payload)) {
    const datum = payload[0];
    const { timestamp, formattedValue } = datum?.payload ?? {};
    return (
      <Card style={{ padding: "8px" }}>
        {timestamp && <Typography variant="body2">{timestamp}</Typography>}
        <Typography variant="subtitle1">{formattedValue}</Typography>
      </Card>
    );
  }
  return null;
}

export default function SparkLine(props) {
  const {
    data, xAxis, height, width, dataFormatter, domain,
  } = props;
  const theme = useTheme();
  const formattedData = data
    && data.map(({ value, timestamp }) => ({
      value,
      formattedValue: dataFormatter(value),
      timestamp: dayjs.unix(timestamp).utc().format("MMM-YY"),
    }));
  return (
    <ResponsiveContainer width={width || "100%"} height={height}>
      <LineChart
        data={formattedData}
        margin={{
          top: 5,
          right: 30,
          left: 0,
          bottom: 5,
        }}
      >
        <Tooltip
          offset={0}
          allowEscapeViewBox={{ x: false, y: false }}
          content={<CustomToolTip />}
        />
        {xAxis && data?.length && (
          <XAxis
            minTickGap={30}
            dataKey="timestamp"
            type="category"
            stroke={theme.palette.text.primary}
            tick={<CustomizedAxisTick />}
            height={60}
          />
        )}
        {domain && (
          <YAxis
            domain={domain}
          />
        )}
        <Line
          type="linear"
          dataKey="value"
          stroke={theme.palette.text.primary}
          strokeWidth={3}
          animationDuration={1000}
          dot={false}
        />
      </LineChart>
    </ResponsiveContainer>
  );
}

SparkLine.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.number, timestamp: PropTypes.number }),
  ),
  width: PropTypes.number,
  xAxis: PropTypes.bool,
  height: PropTypes.number,
  dataFormatter: PropTypes.func,
};

SparkLine.defaultProps = {
  data: [],
  width: null,
  height: 50,
  xAxis: false,
  dataFormatter: (value) => value,
};
