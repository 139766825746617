/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip as ChartTooltip,
  XAxis,
  YAxis,
  LineChart,
  Line,
  Legend,
  Surface,
  Symbols,
  Label,
} from "recharts";
import {
  Grid, Typography, Tooltip, useTheme,
} from "@material-ui/core";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import InfoIcon from "@material-ui/icons/Info";
import { getFunc } from "@/utils/data";
import DropdownInfo from "@/ui/atoms/DropdownInfo";
import MultiSelect from "@/ui/atoms/MultiSelect";

dayjs.extend(utc);

const displayDoc = (
  <>
    <h3>Display</h3>
    Select which companies you want to chart.
    <br />
    <br />
    <strong>
      <i>Note:</i>
    </strong>
    {" "}
    You can quickly (de)select all companies by clicking
    {" "}
    <strong>
      <i>&apos;All&apos;</i>
    </strong>
    .
  </>
);

export const firstNonZero = (history, company) => {
  for (let i = 0; i < history.length; i += 1) {
    const period = history[i];
    const value = period[company];
    if (value && value !== 0) {
      return value;
    }
  }
  return 1;
};

export const formatRelativeHistory = (displayValues, history) => {
  if (history && history.length > 0) {
    const divider = {};
    // find first non zero for every company
    displayValues.forEach((company) => {
      divider[company] = firstNonZero(history, company);
    });

    // assign 1 to timestamp so it does not change the value when divided later
    divider.timestamp = 1;

    const relativeHistory = history.map((period) => {
      const relative = {};
      Object.keys(period).forEach((company) => {
        relative[company] = +(period[company] / divider[company]).toFixed(2);
      });
      relative.timestamp = dayjs.unix(period.timestamp).utc().format("MMM-YY");
      return relative;
    });
    return relativeHistory;
  }
  return [];
};

// TODO delete margin
export default function CompetitorLineChart({
  metric,
  height,
  history,
  setHighlightedCompany,
  highlightedCompany,
  companyToColor,
  chartRepresents,
  allDisplayValues,
  margin,
}) {
  const theme = useTheme();
  const [onHoverCompany, setOnHoverCompany] = useState("");
  const [displayValues, setDisplayValues] = useState(allDisplayValues);

  const renderCustomizedLegend = ({ payload }) => (
    <div style={{ textAlign: "center" }}>
      {payload.map((entry) => {
        const { dataKey, color } = entry;
        const active = dataKey === onHoverCompany || dataKey === highlightedCompany;
        const style = {
          marginRight: 10,
          fontWeight: active ? "bold" : "normal",
          cursor: "pointer",
          color: active
            ? theme.palette.text.primary
            : theme.palette.text.secondary,
        };

        return (
          <span
            onClick={() => setHighlightedCompany(
              dataKey === highlightedCompany ? "" : dataKey,
            )}
            onMouseOver={() => setOnHoverCompany(dataKey)}
            onMouseOut={() => setOnHoverCompany("")}
            style={style}
            key={`span-legend-${dataKey}`}
          >
            <Surface
              width={10}
              height={10}
              viewBox={{
                x: 0, y: 0, width: 10, height: 10,
              }}
            >
              <Symbols cx={5} cy={5} type="circle" size={85} fill={color} />
              <Symbols
                cx={5}
                cy={5}
                type="circle"
                size={60}
                fill={active ? color : theme.palette.background.paper}
              />
            </Surface>
            <span>{dataKey}</span>
          </span>
        );
      })}
    </div>
  );

  const formatValue = (value) => ({
    absolute: getFunc[metric.formatter](value),
    relative: `${value}x`,
  })[chartRepresents];

  const yAxisLabel = {
    absolute: "per month",
    relative: "growth multiple",
  };

  const formatAbsoluteHistory = (absHistory) => {
    const absoluteHistory = (absHistory || []).map((value) => ({
      ...value,
      timestamp: dayjs.unix(value.timestamp).utc().format("MMM-YY"),
    }));
    return absoluteHistory;
  };

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid container item sm={12} md={7} justifyContent="flex-end">
          <Typography
            id={`line-${metric.label}`}
            variant="h3"
            color="textPrimary"
            style={{ textAlign: "center" }}
          >
            {metric.label}
          </Typography>
          {metric?.subtitle && (
            <Tooltip title={metric.subtitle}>
              <InfoIcon
                style={{
                  color: theme.palette.blue.main,
                  margin: theme.spacing(0, 1),
                }}
              />
            </Tooltip>
          )}
        </Grid>
        <Grid item sm={12} md={5} container justifyContent="flex-end">
          <DropdownInfo
            component={(
              <MultiSelect
                allValues={allDisplayValues}
                value={displayValues}
                title="Display"
                id={`${metric.id}-line`}
                onChange={setDisplayValues}
                colorMap={companyToColor}
              />
            )}
            title={displayDoc}
          />
        </Grid>
      </Grid>
      <ResponsiveContainer width="99%" height={height}>
        <LineChart
          id={metric.label}
          data={
            chartRepresents === "relative"
              ? formatRelativeHistory(displayValues, history)
              : formatAbsoluteHistory(history)
          }
          margin={margin}
          stroke={theme.palette.text.primary}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="timestamp" stroke={theme.palette.text.primary} />
          <YAxis tickFormatter={formatValue}>
            <Label
              value={yAxisLabel[chartRepresents]}
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: "middle" }}
              stroke={theme.palette.text.primary}
            />
          </YAxis>
          <ChartTooltip formatter={formatValue} />

          <Legend content={renderCustomizedLegend} />
          {displayValues.map((company) => (
            <Line
              key={`line-${company}`}
              type="monotone"
              strokeOpacity={
                company === highlightedCompany
                || company === onHoverCompany
                || (highlightedCompany === "" && onHoverCompany === "")
                  ? 1
                  : 0.3
              }
              strokeWidth={
                company === highlightedCompany || company === onHoverCompany
                  ? 4
                  : 2
              }
              dot={false}
              dataKey={company}
              stroke={companyToColor[company]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  );
}

CompetitorLineChart.propTypes = {
  metric: PropTypes.shape({
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }),
  height: PropTypes.number,
  history: PropTypes.array,
  highlightedCompany: PropTypes.string,
  companyToColor: PropTypes.object,
  chartRepresents: PropTypes.string,
  allDisplayValues: PropTypes.array,
  margin: PropTypes.object,
};
