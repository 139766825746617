/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { Typography } from "@mui/material";
import Progress from "@/ui/atoms/Progress";

interface Props {
  children?: React.ReactNode
  isLoading?: boolean
  displayChildren?: boolean
  isError?: boolean
  anchorOrigin?: {
    vertical: "top" | "bottom" | "center",
    horizontal: "left" | "right" | "center",
  },
}

export default function CenteredProgress({
  children,
  isLoading = true,
  displayChildren = false,
  isError = false,
  anchorOrigin = {
    vertical: "center",
    horizontal: "center",
  },
}: Props) {
  const { vertical, horizontal } = anchorOrigin;
  const left = horizontal === "center" ? "50%" : horizontal === "left" ? "25%" : "75%";
  const top = vertical === "center" ? "50%" : vertical === "top" ? "25%" : "75%";
  return (
    <>
      {(!isLoading || displayChildren) && children}
      {isLoading && (
        <Progress
          sx={{
            position: "fixed",
            top,
            left,
            marginLeft: "-22px",
            marginTop: "-22px",
            zIndex: 9999,
          }}
        />
      )}
      {isError && (
        <Typography
          variant="h2"
          sx={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
          }}
        >
          Something went wrong with displaying data, contact the labs team with
          this url.
        </Typography>
      )}
    </>
  );
}
