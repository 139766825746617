import React from "react";
import { useParams, useNavigate } from "react-router-dom";
// import { Section, canReadTegusScores } from "@valor-labs/marble";
import Section from "@/ui/atoms/Section";
import { canReadTegusScores } from "@/constants/Roles";
import {
  Card, Box, Stack, Pagination, Skeleton, Link,
} from "@mui/material";
import Helmet from "react-helmet";
import { useAuth } from "@/hooks/useAuth";
import NotPrintable from "@/components/NotPrintable";
import { Typography } from "@material-ui/core";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import LockIcon from "@mui/icons-material/Lock";
import Transcript from "./Transcript";
import useSummaries from "./useSummaries";
import TranscriptRow from "./SummaryRow";
import useTripwire from "../useTripwire";

export default function Transcripts() {
  const { id, transcriptId } = useParams<{ id: string; transcriptId: string; }>();
  const navigate = useNavigate();

  useTripwire(id, "transcripts");

  const {
    summaries,
    iheTrends,
    error,
  } = useSummaries(id, 200);

  const [page, setPage] = React.useState(1);
  const pagedSummaries = summaries?.slice((page - 1) * 5, page * 5);

  const { user } = useAuth();
  const version = canReadTegusScores(user) ? "admin" : "associate";

  if (summaries?.length === 0) {
    return (
      <Card elevation={0} sx={{ p: 1 }}>
        <Section>
          <Section.Title>Transcripts</Section.Title>
          <Section.Content>
            <Typography variant="body1">
              No transcripts available
            </Typography>
          </Section.Content>
        </Section>
      </Card>
    );
  }

  if (error?.response.status === 403) {
    return (
      <ErrorMessage
        Icon={<LockIcon />}
        title="Unauthorized"
        message={(
          <>
            You don’t have access to Transcripts for this company. If you think this is
            an error, please contact
            {" "}
            <Link
              href="mailto:labs@valorep.com?subject=Transcripts Access for Company"
            >
              labs@valorep.com
            </Link>
          </>
        )}
      />
    );
  }

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        xs: "1fr",
      }}
      gap={2}
    >
      <NotPrintable>
        <Card elevation={0} sx={{ p: 1 }}>
          <Section>
            <Section.Title>Transcripts</Section.Title>
            <Section.Content>
              {!pagedSummaries?.length && (
              <Stack
                direction="column"
                spacing={1}
              >
                <Skeleton variant="rectangular" width="100%" height={60} />
                <Skeleton variant="rectangular" width="100%" height={60} />
                <Skeleton variant="rectangular" width="100%" height={60} />
                <Skeleton variant="rectangular" width="100%" height={60} />
                <Skeleton variant="rectangular" width="100%" height={60} />
              </Stack>
              )}
              {pagedSummaries?.map((transcript) => (
                <TranscriptRow
                  key={transcript.id}
                  transcript={transcript}
                  iheTrends={iheTrends[transcript.id]}
                  onSummaryOpen={() => {
                    navigate(`/org/${id}/transcripts/${transcript.id}`);
                  }}
                  version={version}
                />
              ))}

              <Pagination
                shape="rounded"
                count={Math.ceil((summaries?.length || 0) / 5)}
                size="small"
                page={page}
                onChange={(_, value) => setPage(value)}
              />
            </Section.Content>
          </Section>
        </Card>
      </NotPrintable>
      {
        transcriptId && (
        <Card elevation={0} sx={{ p: 1 }}>
          <Helmet>
            <title>Transcripts</title>
          </Helmet>
          <Transcript
            id={transcriptId}
          />
        </Card>
        )
      }
    </Box>
  );
}
