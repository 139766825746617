/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import {
  Box,
  Chip,
  Typography,
  Hidden,
  useMediaQuery,
} from "@material-ui/core";
import { Link } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { formatTotal, formatPercentage } from "@/utils/numberFormat";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";

const useStyles = makeStyles((theme) => ({
  columnGroupTabs: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: theme.spacing(4),
    marginLeft: theme.spacing(1),
    textAlign: "center",
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.spacing(1, 1, 0, 0),
  },
  avatarCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  clamps: {
    display: "flex",
    justifyContent: "space-between",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "2",
    height: "60px",
    overflow: "hidden",
  },
  icons: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minWidth: "25px",
    color: theme.palette.text.secondary,
    "& svg": {
      width: ".75em",
      height: ".75em",
    },
  },
  companyName: {
    display: "block",
    maxHeight: "2em",
    overflow: "hidden",
    lineHeight: "1em",
    textOverflow: "ellipsis",
    wordWrap: "break-word",
  },
  seeMore: {
    alignSelf: "flex-end",
    order: 1,
    whiteSpace: "nowrap",
  },
  actionLink: {
    color: theme.palette.blue.main,
  },
}));

const renderTotals = (value, precision = 0) => (
  <Typography variant="subtitle1">
    <strong>{value && formatTotal(value, precision)}</strong>
  </Typography>
);

const renderDollars = (value) => {
  const total = `$${formatTotal(value, 2)}`;
  return (
    <Box>
      <Typography variant="subtitle1">{value && total}</Typography>
    </Box>
  );
};

const renderPct = (value) => {
  const theme = useTheme();
  return (
    <Typography
      variant="subtitle1"
      style={
        Math.sign(value) === 1
          ? { color: theme.palette.green.main }
          : { color: theme.palette.red.main }
      }
    >
      <strong>{value && formatPercentage(value)}</strong>
    </Typography>
  );
};
const renderDescription = (description, setPopOver) => {
  const divRef = React.useRef();
  const classes = useStyles();
  return (
    <Box>
      <Box className={classes.clamps}>
        {description}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        {description && (
          <div className={classes.seeMore}>
            <div
              className={classes.actionLink}
              ref={divRef}
              style={{ cursor: "pointer" }}
              onClick={() => setPopOver(divRef, description)}
            >
              See More
            </div>
          </div>
        )}
      </Box>
    </Box>
  );
};

const renderGroupHeader = (title) => {
  const classes = useStyles();
  return <Box className={classes.columnGroupTabs}>{title}</Box>;
};

const renderCompanyMark = (company, navigate) => {
  const {
    name, logoUrl, domain, valorId,
  } = company;

  const classes = useTheme();
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const uuidRegex = /^[a-f0-9-]{36}$/;
  const isValidValor = uuidRegex.test(valorId);
  const openDomain = () => window.open(`//${domain || valorId}`);
  const containerStyle = isValidValor ? { cursor: "pointer" } : {};
  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      width="100%"
      style={{ ...containerStyle, marginLeft: "8px" }}
      flexDirection={isSmDown ? "column" : "row"}
    >
      <Box display="flex" justifyContent="flex-start">
        <Hidden smDown>
          <Box onClick={() => navigate && navigate(`/org/${valorId}`)} margin="auto" className={classes.avatarCenter}>
            <CompanyAvatar src={logoUrl} name={name || valorId} size="40" domain={domain} />
          </Box>
        </Hidden>
        <Box
          paddingLeft={2}
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography className={classes.companyName} variant="subtitle1">
            {name || valorId}
          </Typography>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            style={{ cursor: "pointer", marginRight: "20px" }}
            href="#"
            onClick={openDomain}
            variant="body2"
          >
            {domain || valorId}
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

const renderSources = (value) => {
  const theme = useTheme();
  const sourceInfo = {
    SEMrush: {
      color: theme.palette.yellow.highlight,
    },
    Similarweb: {
      color: theme.palette.red.highlight,
    },
    "LinkedIn Keywords": {
      color: theme.palette.purple.highlight,
    },
    Keywords: {
      color: theme.palette.purple.highlight,
    },
    NLP: {
      color: theme.palette.green.highlight,
    },
  };
  return value?.map((source) => {
    const { color } = sourceInfo?.[source] || "red";
    return (
      <Chip key={source} style={{ backgroundColor: color }} label={source} />
    );
  });
};

export {
  renderCompanyMark,
  renderGroupHeader,
  renderPct,
  renderSources,
  renderTotals,
  renderDollars,
  renderDescription,
};
