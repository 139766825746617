import React from "react";
import {
  Box, Tooltip, Skeleton, Collapse,
} from "@mui/material";
// import Section from "@/ui/atoms/Section";
import Section from "@/ui/atoms/Section";
import { FeatureFamilyIconMap } from "./useFeature";

function FeatureCoverage({ coverage }) {
  const overallExisting = coverage?.reduce((acc, { existing: e }) => acc + e, 0);
  const overallTotal = coverage?.reduce((acc, { total: t }) => acc + t, 0);

  const featureFamilies = coverage?.toSorted((a, b) => b.existing / b.total - a.existing / a.total);

  const [openFamilies, setOpenFamilies] = React.useState<Record<string, boolean>>({});

  return (
    <Box>
      <Section>
        <Section.Title
          sx={{
            width: "100%",
          }}
        >
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            Feature Coverage
            <span>
              {coverage
                ? (
                  <Tooltip
                    title={`${overallExisting} out of ${overallTotal} features exist`}
                    placement="top"
                  >
                    <span
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {overallTotal > 0 ? ` (${Math.round((overallExisting / overallTotal) * 100)}%)` : " (N/A)"}
                    </span>
                  </Tooltip>
                )
                : null}
            </span>
          </Box>
        </Section.Title>
        <Section.Content>
          <ul
            style={{
              listStyle: "none",
              padding: 0,
            }}
          >
            {featureFamilies?.map(({
              featureFamily,
              total,
              existing,
              features,
            }) => {
              const Icon = FeatureFamilyIconMap[featureFamily] || FeatureFamilyIconMap.default;
              return (
                <li
                  key={featureFamily}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                    padding={1}
                    gap={1}
                    sx={{
                      "&:hover": {
                        backgroundColor: (theme) => theme.palette.action.hover,
                      },
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenFamilies((prev) => ({
                        ...prev,
                        [featureFamily]: !prev[featureFamily],
                      }));
                    }}
                  >
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                    >
                      <Icon />
                      {featureFamily}
                    </Box>

                    <Tooltip
                      title={`${existing} out of ${total} features exist`}
                      placement="top"
                    >
                      <span
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        {Math.floor((existing / total) * 100)}
                        %
                      </span>
                    </Tooltip>
                  </Box>
                  <Collapse in={openFamilies[featureFamily]}>
                    <Box>
                      <ul
                        style={{
                          listStyle: "none",
                        }}
                      >
                        {features?.toSorted((a, b) => {
                          // sort if exists last
                          if (a.exists !== b.exists) {
                            return a.exists ? -1 : 1;
                          }
                          // sort by name
                          return a.name.localeCompare(b.name);
                        })?.map(({
                          name,
                          exists,
                        }) => (
                          <li
                            key={name}
                            style={{
                              opacity: exists ? 1 : 0.5,
                            }}
                          >
                            {name}
                          </li>
                        ))}
                      </ul>
                    </Box>
                  </Collapse>
                </li>
              );
            })}
          </ul>
        </Section.Content>
      </Section>
    </Box>
  );
}

FeatureCoverage.Loading = function FeatureCoverageLoading() {
  return (
    <Section>
      <Section.Title
        sx={{
          width: "100%",
        }}
      >
        Feature Coverage
      </Section.Title>
      <Section.Content>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
          <Skeleton variant="rectangular" width="100%" height={30} />
        </Box>
      </Section.Content>
    </Section>
  );
};

export default FeatureCoverage;
