import React from "react";
import Editor, { RichTextEditorProps } from "./Editor";
import Serializer from "./Serializer";
import EditorContextProvider from "./Editor/EditorContext";

function EditorWithContext({
  initialValue,
  onChange,
  uploadImage,
  // options,
}: RichTextEditorProps) {
  return (
    <EditorContextProvider>
      <Editor
        initialValue={initialValue}
        onChange={onChange}
        uploadImage={uploadImage}
        // options={options}
      />
    </EditorContextProvider>
  );
}

export default EditorWithContext;
export const RichTextSerializer = Serializer;
