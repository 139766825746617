import React, {
  useState,
} from "react";

import { Box, Typography } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";

import Clamps from "@/components/Clamps";

const ExpandText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
}));

interface Props {
  title: string;
  children: React.ReactNode | React.ReactNode[];
  expandable?: boolean;
  maxLines?: number;
  lineHeight?: number | string;
}

export function LabeledDetail({
  title,
  children,
  expandable = false,
  maxLines = 2,
  lineHeight = 1.43,
}: Props) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const content = children;

  return (
    <Box display="flex" flexDirection="column" mb={1} sx={{ overflow: "hidden" }}>
      <Box display="flex" alignItems="center">
        <Typography
          sx={{ marginRight: expandable ? theme.spacing(2) : null }}
          variant="boldSubtitle2"
          color={theme.palette.text.secondary}
        >
          {title}
        </Typography>
        {expandable && hasOverflow ? (
          open ? (
            <ExpandText variant="overline" onClick={() => setOpen(false)}>
              Collapse
            </ExpandText>
          ) : (
            <ExpandText variant="overline" onClick={() => setOpen(true)}>
              View More
            </ExpandText>
          )
        ) : null}
      </Box>
      {expandable ? (
        <Clamps
          maxLines={maxLines}
          lineHeight={lineHeight}
          collapsed={!open}
          onReflow={(canExpand) => {
            setHasOverflow(canExpand);
          }}
        >
          {content}
        </Clamps>
      ) : content }
    </Box>
  );
}

export default LabeledDetail;
