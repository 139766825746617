import { Node } from "slate";
import { RichText } from "../types/RichText";

// Define a serializing function that takes a value and returns a string.
const serialize = (value?: RichText): string => {
  if (!value) return "";
  return value
    .map((n) => Node.string(n))
    .join("\n");
};

// Define a deserializing function that takes a string and returns a value.
const deserialize = (plainText: string): RichText => (
  plainText.split("\n").map((line) => ({ children: [{ text: line }] }))
);

export default {
  serialize,
  deserialize,
};
