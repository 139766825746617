import React from "react";
import {
  Card, Grid, Box,
} from "@mui/material";
import {
  Organization,
} from "@/api/Organization";
import GeneralInformation from "./GeneralInformation";
import ValorFunding from "./ValorFunding";
import FundingRounds from "./FundingRounds";
import SurveyResponse from "./SurveyResponses";
import Transcripts from "./Transcripts";
import RecentNews from "./RecentNews";

interface SummaryProps {
  company: Organization
}

function OrganizationSummary({ company }: SummaryProps) {
  return (
    <Grid
      container
      spacing={1}
      rowSpacing={2}
    >
      <Grid item xs={12} lg={8} xl={9}>
        <Box display="flex" flexDirection="column" sx={{ gap: "16px" }}>
          <Card
            elevation={0}
            sx={{
              padding: 1, display: "flex", flexDirection: "column",
            }}
          >
            <GeneralInformation company={company} />
          </Card>
          <Card
            elevation={0}
            sx={{
              padding: 1, display: "flex", flexDirection: "column",
            }}
          >
            <ValorFunding company={company} />
          </Card>
          <Card
            elevation={0}
            sx={{
              padding: 1, display: "flex", flexDirection: "column",
            }}
          >
            <SurveyResponse company={company} />
          </Card>
        </Box>
      </Grid>
      <Grid item xs={12} lg={4} xl={3}>
        <Card elevation={0} sx={{ padding: 1, marginBottom: 2 }}>
          <FundingRounds company={company} />
        </Card>
        <Card elevation={0} sx={{ padding: 1, marginBottom: 2 }}>
          <Transcripts valorId={company?.valorId} />
        </Card>
        <Card elevation={0} sx={{ padding: 1 }}>
          <RecentNews company={company} />
        </Card>
      </Grid>
    </Grid>
  );
}

export default OrganizationSummary;
