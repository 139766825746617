import React, { useState, useMemo } from "react";

import { Box, useTheme } from "@mui/material";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { useInfiniteQuery } from "react-query";
import { getMSLPortfolioCommunication } from "@/api/Reporting";
import { ColumnFiltersState, SortingState } from "@tanstack/react-table";
import { mapFilters, mapSorting, getNextPageParam } from "@/components/InfiniteTable";
import MSLSurveyTable, { MSLSurvey } from "./MSLSurveyTable";

const DEFAULT_SORT = [{ id: "surveyCompletedAt", desc: true }];
const DEFAULT_FILTERS = [
  { id: "growthIsExited", value: false },
  { id: "vsvIsExited", value: false },
];

type PortfolioType = "isVof" | "isVsv" | "isPlatform" | "isAncillary";
export function MSLSurveyCommunicationDelay() {
  const theme = useTheme();
  const [portfolioType, setPortfolioType] = useState<PortfolioType>("isPlatform");
  const activeFilter = {
    isVof: false,
    isVsv: false,
    isPlatform: false,
    isAncillary: false,
  };

  activeFilter[portfolioType as PortfolioType] = true;

  const [sorting, setSorting] = useState<SortingState>(DEFAULT_SORT);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(DEFAULT_FILTERS);
  const [columnVisibility, setColumnVisibility] = useState({});

  const pagedQueryKey = useMemo(() => (
    ["MSLDelay", sorting, columnFilters, portfolioType]
  ), [sorting, columnFilters, portfolioType]);

  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: pagedQueryKey,
    queryFn: ({ pageParam = null }) => getMSLPortfolioCommunication(
      20,
      pageParam,
      mapSorting(sorting),
      mapFilters([
        ...columnFilters,
        ...Object.keys(activeFilter).filter((key) => activeFilter[key])
          .map((key) => ({
            id: key,
            value: true,
          })),
      ], ""),
    ),
    getNextPageParam,
  });

  return (
    <Box>
      <ButtonTabs
        activeKey={portfolioType}
        width="320px"
        onClick={(option) => {
          setPortfolioType(option.key);
          setColumnFilters(DEFAULT_FILTERS);
          setSorting(DEFAULT_SORT);
          if (option.key === "isVsv") {
            setColumnVisibility({ tier: false });
          } else {
            setColumnVisibility({});
          }
        }}
        options={[
          { key: "isPlatform", label: "Platform" },
          { key: "isAncillary", label: "Ancillary" },
          { key: "isVsv", label: "VSV" },
          { key: "isVof", label: "VOF" },
        ]}
      />

      <Box
        sx={{
          marginTop: {
            xs: theme.spacing(0.5),
            sm: theme.spacing(1),
            md: theme.spacing(2),
          },
        }}
      >
        <MSLSurveyTable
          data={data as unknown as MSLSurvey[]}
          isLoading={isLoading}
          isFetching={isFetching}
          isFetchingNextPage={isFetchingNextPage}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          sorting={sorting}
          columnFilters={columnFilters}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={setColumnVisibility}
          onColumnFiltersChange={setColumnFilters}
          onSortingChange={setSorting}
        />
      </Box>
    </Box>
  );
}

export default MSLSurveyCommunicationDelay;
