import { RichText } from "../types/RichText";

import Plain from "./PlainSerializer";
import { serialize } from "./JSX";

export default {
  toJSX: (
    richText: RichText,
    readonly: boolean,
  ): JSX.Element[] => serialize(richText, readonly),
  toString: (richText: RichText): string => Plain.serialize(richText),
  toHTMLString: (): string => "",
  toMarkdown: (): string => "",
  fromString: (plainText: string): RichText => Plain.deserialize(plainText),
};
