import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Popover,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import useDebounce from "@/hooks/useDebounce";
import DualSliderFilter from "@/ui/molecules/DualSliderFilter";
import { formatBigDollars, formatTotal } from "@/utils/numberFormat";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { useQueryClient } from "react-query";
import { saytForOrg } from "@/api/Search";
import { respondToMapSuggestion } from "@/api/MarketMap";
import { FundingTypes } from "@/utils/FundingTypes";
import MultiSelect from "@/ui/atoms/MultiSelect";
import BulkAdd from "./BulkAdd";

const defaultFilters = {
  totalRaised: { range: [0, Infinity] },
  employeeCount: { range: [1, Infinity] },
  usOnly: { value: false },
  lastFunding: { values: [] },
};

const totalFundingInvertMapping = {
  0: 1,
  100000: 2,
  500000: 3,
  1000000: 4,
  5000000: 5,
  10000000: 6,
  20000000: 7,
  50000000: 8,
  100000000: 9,
  200000000: 10,
  500000000: 11,
  Infinity: 12,
};
const totalFundingMapping = {
  1: 0,
  2: 100000,
  3: 500000,
  4: 1000000,
  5: 5000000,
  6: 10000000,
  7: 20000000,
  8: 50000000,
  9: 100000000,
  10: 200000000,
  11: 500000000,
  12: Infinity,
};

const employeeCountInvertMapping = {
  1: 1,
  10: 2,
  50: 3,
  250: 4,
  1000: 5,
  5000: 6,
  Infinity: 7,
};

const employeeCountMapping = {
  1: 1,
  2: 10,
  3: 50,
  4: 250,
  5: 1000,
  6: 5000,
  7: Infinity,
};

export function MapCollector(props) {
  const {
    mapId,
    onCompanySelect,
    companies = [],
    onCompanyDelete,
    filters: propFilters,
    onFilterChange,
  } = props;
  const queryClient = useQueryClient();

  const [searchResults, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [openClassifierDialog, setOpenClassifierDialog] = useState(false);
  const [descriptionPopover, setPopover] = useState({ anchorEl: null, companyDescription: null });
  const debouncedQuery = useDebounce(query, 150);

  const [filters, setFilters] = useState(defaultFilters);

  useEffect(() => {
    const newFilters = { ...propFilters };
    if (newFilters.employeeCount.range[1] === null) {
      newFilters.employeeCount.range[1] = Infinity;
    }
    if (newFilters.totalRaised.range[1] === null) {
      newFilters.totalRaised.range[1] = Infinity;
    }
    setFilters({ ...defaultFilters, ...newFilters });
  }, [JSON.stringify(propFilters)]);

  useEffect(() => {
    const queryData = async () => {
      const response = await saytForOrg(debouncedQuery, ["description"]);
      setResults(response);
    };
    queryData().catch(console.error);
  }, [debouncedQuery]);

  const onSuggestionClickAccept = (co) => {
    onCompanySelect(co);
    respondToMapSuggestion(mapId, {
      valorId: co.valorId,
      confidenceScore: co.confidenceScore,
      recommendationStatus: "ACCEPTED",
    });
    queryClient.setQueriesData(["MarketMapSuggestions", mapId], (old) => {
      const newSuggestions = [...old].filter((x) => x.valorId !== co.valorId);
      return newSuggestions;
    });
  };

  const renderDialog = () => (
    <Dialog
      open={openClassifierDialog}
      onClose={() => setOpenClassifierDialog(false)}
      aria-labelledby="responsive-dialog-title"
    >
      <>
        <DialogTitle id="responsive-dialog-title">
          Update Suggestion Criteria
        </DialogTitle>
        <DialogContent>
          <Box>
            <DialogContentText>
              These filters will save and restrict the suggestions you see.
            </DialogContentText>
            <DualSliderFilter
              initialValue={filters?.totalRaised?.range}
              valueLabelFormat={formatBigDollars}
              min={1}
              step={1}
              max={12}
              descale={(x) => totalFundingInvertMapping[x]}
              scale={(x) => totalFundingMapping[x]}
              title="Total Raised"
              onSlide={(value) => setFilters((old) => ({
                ...old,
                totalRaised: {
                  range: value,
                },
              }))}
            />
            <DualSliderFilter
              initialValue={filters?.employeeCount?.range}
              valueLabelFormat={formatTotal}
              min={1}
              step={1}
              max={7}
              descale={(x) => employeeCountInvertMapping[x]}
              scale={(x) => employeeCountMapping[x]}
              title="Current Employee Count"
              onSlide={(value) => setFilters((old) => ({
                ...old,
                employeeCount: {
                  range: value,
                },
              }))}
            />
            <MultiSelect
              id="deal-select"
              allValues={FundingTypes}
              getKey={(x) => x}
              getLabel={(x) => x}
              value={filters?.lastFunding?.values}
              title="Last Series/Round Name"
              style={{ width: "100%", maxWidth: null }}
              onChange={(v) => setFilters((old) => ({
                ...old,
                lastFunding: {
                  values: v,
                },
              }))}
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={filters.usOnly.value}
                  onChange={(e) => setFilters((old) => ({
                    ...old,
                    usOnly: {
                      value: e.target.checked,
                    },
                  }))}
                  name="usOnly"
                  color="primary"
                />
              )}
              label="USA Only"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenClassifierDialog(false);
              setFilters(propFilters);
            }}
            color="primary"
          >
            Close
          </Button>
          <Button
            onClick={() => {
              setOpenClassifierDialog(false);
              onFilterChange(filters);
            }}
            color="primary"
            variant="contained"
          >
            Submit
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );

  const renderPaneResults = () => {
    const renderCard = (co) => (
      <Box
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider} `,
          marginBottom: "4px",
          height: (theme) => theme.spacing(12),
          alignItems: "center",
          justifyContent: "space-between",
          padding: 1,
          width: "100%",
          minWidth: "320px",
          borderRadius: 1,
          cursor: "pointer",
        }}
        display="flex"
        key={co.valorId}
        onClick={() => onCompanySelect(co)}
      >
        <Box display="flex" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{
              marginRight: "16px",
              visibility: "hidden",
            }}
          >
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "100%",
                border: "1px solid black",
                padding: "4px",
                height: 32,
                width: 32,
                textAlign: "center",
                position: "relative",
                bottom: "-8px",
              }}
            >
              {co.confidenceScore
                ? (<Typography>{co.confidenceScore.toFixed(2) * 100}</Typography>) : null}
            </Box>
          </Box>

          <CompanyAvatar src={co.logoUrl} name={co.name} domain={co.domain} size={48} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            style={{ margin: "0 8px" }}
          >
            <Typography
              variant="h2"
              style={{
                fontSize: "1.2vmin",
              }}
            >
              {co.name}
            </Typography>
            <Typography variant="subtitle1">
              <Link
                style={{ cursor: "default" }}
                onClick={(e) => e.stopPropagation()}
                href={`https://www.${co.domain}`}
                target="_blank"
              >
                {co.domain}
              </Link>
            </Typography>
          </Box>
        </Box>
        <IconButton
          size="medium"
          aria-label="Add"
          color="primary"
          style={{ width: "48px", height: "48px" }}
          onClick={(e) => {
            e.stopPropagation();
            onCompanySelect(co);
            onSuggestionClickAccept(co);
          }}
        >
          <AddIcon fontSize="medium" />
        </IconButton>
      </Box>
    );
    return searchResults.map((co) => renderCard(co));
  };

  return (
    <Box
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      <Grid
        container
        sx={{
          position: "absolute",
          top: 0,
          bottom: 0,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderTop: (theme) => `1px solid ${theme.palette.divier}`,
        }}
      >
        <Grid
          md={4}
          xl={3}
          item
          sx={{
            width: "100%",
            padding: 1,
            borderRight: (theme) => `1px solid ${theme.palette.divider}`,
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ margin: "8px 0" }}
            >
              <Typography variant="h2">
                Add a Company
              </Typography>
              <BulkAdd onOrgSelect={onCompanySelect} />
            </Box>
            <Box display="flex" justifyContent="center">
              <FormControl
                sx={{
                  width: "100%",
                  marginBottom: 1,
                }}
              >
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Search for a company"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box style={{ overflowY: "scroll" }}>{renderPaneResults()}</Box>
        </Grid>
        <Grid
          md={8}
          xl={9}
          item
          sx={{
            display: "flex",
            overflowY: "scroll",
            maxHeight: "100%",
          }}
        >
          <Box
            sx={{
              display: "inline-flex",
              padding: 3,
              gap: 3,
              flexWrap: "wrap",
              alignContent: "baseline",
            }}
          >
            {companies.map((co) => (
              <Box
                key={co.valorId}
                sx={{
                  padding: 0.5,
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                  "& > div > div": {
                    border: (theme) => `1px solid ${theme.palette.divider}`,
                    borderRadius: 1,
                  },
                  "&:hover > button": {
                    visibility: "visible",
                  },
                  "& > div > h6": {
                    color: (theme) => theme.palette.text.secondary,
                    width: (theme) => theme.spacing(12),
                    textAlign: "center",
                    overflowWrap: "normal",
                  },
                }}
              >
                <IconButton
                  color="primary"
                  size="small"
                  sx={{
                    visibility: "hidden",
                    position: "absolute",
                    top: "-10px",
                    right: "-10px",
                    border: (theme) => `1px solid ${theme.palette.primary.main}`,
                    backgroundColor: (theme) => `${theme.palette.primary.highlight} !important`,
                  }}
                  onClick={() => onCompanyDelete(co.valorId)}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <Box>
                  <CompanyAvatar size={112} src={co.logoUrl} name={co.name} domain={co.domain} />
                  <Typography
                    variant="subtitle2"
                  >
                    {co.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      {renderDialog()}
      <Popover
        open={Boolean(descriptionPopover.anchorEl)}
        anchorEl={descriptionPopover.anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        onClose={() => setPopover({ anchorEl: null, descriptionPopover: null })}
        disableRestoreFocus
      >
        <Box
          sx={{
            width: "320px",
            height: "320px",
            padding: 0.5,
          }}
        >
          {descriptionPopover.companyDescription}
        </Box>
      </Popover>
    </Box>
  );
}
export default MapCollector;
