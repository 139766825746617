/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import { addDays, format } from "date-fns";
import {
  Box, useTheme, TextField, Popover, makeStyles,
} from "@material-ui/core";
import PropTypes from "prop-types";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import InputAdornment from "@material-ui/core/InputAdornment";
import TodayIcon from "@material-ui/icons/Today";
import dayjs from "dayjs";

const today = new Date(new Date().setHours(0, 0, 0, 0));

const staticRanges = createStaticRanges([
  {
    label: "Today",
    range: () => ({
      startDate: today,
      endDate: new Date(),
    }),
  },
  {
    label: "Last 7 days",
    range: () => ({
      startDate: addDays(today, -6),
      endDate: new Date(),
    }),
  },

  {
    label: "Last 30 days",
    range: () => ({
      startDate: addDays(today, -29),
      endDate: new Date(),
    }),
  },
  {
    label: "Last 90 days",
    range: () => ({
      startDate: addDays(today, -89),
      endDate: new Date(),
    }),
  },
  {
    label: "All Time",
    range: () => ({
      startDate: null,
      endDate: null,
    }),
  },
]);

const useStyles = makeStyles({
  underline: {
    "&:before": {
      borderBottom: (props) => `1px solid ${props.color}`,
    },
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottom: (props) => `2px solid ${props.color}`,
    },
    "&:after": {
      borderBottom: (props) => `2px solid ${props.color}`,
    },
  },
  disabled: {},
  focused: {},
  error: {},
  // datePopover: {
  //   "& > .argos-MuiPopover-paper": {
  //     overflowX: "scroll",
  //   },
  // },
});

export default function DateRangeFilter({
  filterValue = {},
  setFilter,
  color,
  maxDate,
  useDayjs = false
}) {
  const theme = useTheme();
  const classes = useStyles({ color });
  const finalColor = color || theme.palette.text.primary;

  const anchorRef = React.useRef();
  const [openPopOver, setOpenPopover] = useState(false);
  const formatDate = (date) => (useDayjs ? dayjs(date).format("MMM DD, YYYY") : format(date, "MMM dd, yyyy"));

  const isStartDateEmpty = !("startDate" in filterValue) || filterValue.startDate === null;
  const isEndDateEmpty = !("endDate" in filterValue) || filterValue.endDate === null;

  const getLabel = () => {
    if (isStartDateEmpty && isEndDateEmpty) {
      return "All Time";
    }
    if (isStartDateEmpty) {
      return `On or Before ${formatDate(filterValue.endDate)}`;
    }
    if (isEndDateEmpty) {
      return `On or After ${formatDate(filterValue.startDate)}`;
    }

    return `${formatDate(filterValue.startDate)} - ${formatDate(filterValue.endDate)}`;
  };

  return (
    <>
      <Box
        ref={anchorRef}
        onClick={() => setOpenPopover(true)}
        display="flex"
        flexDirection="row"
        alignItems="center"
        flex={1}
      >
        <TextField
          style={{ flex: 1 }}
          id="date-text-field"
          value={getLabel()}
          InputProps={{
            classes,
            style: {
              // maxWidth: "260px",
              width: "100%",
              color: finalColor,
            },
            startAdornment: (
              <InputAdornment position="start">
                <TodayIcon />
              </InputAdornment>
            ),
          }}
        />
      </Box>
      <Popover
        className={classes.datePopover}
        open={openPopOver}
        anchorEl={anchorRef.current}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <DateRangePicker
          staticRanges={staticRanges}
          inputRanges={[]}
          onChange={(item) => {
            const { startDate, endDate } = item.selection;
            setFilter({
              startDate: startDate ? (useDayjs ? dayjs(startDate).startOf('day').toDate() : new Date(startDate).setHours(0, 0, 0, 0)) : null,
              endDate: endDate ? (useDayjs ? dayjs(endDate).endOf('day').toDate() : new Date(endDate).setHours(23, 59, 59, 999)) : null,
            });
          }}
          showSelectionPreview
          moveRangeOnFirstSelection={false}
          months={2}
          ranges={[{ ...filterValue, key: "selection", color: theme.palette.blue.main }]}
          direction="horizontal"
          maxDate={maxDate}
        />
      </Popover>
    </>
  );
}

DateRangeFilter.propTypes = {
  color: PropTypes.string,
  filterValue: PropTypes.shape(
    {
      startDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(dayjs),
      ]),
      endDate: PropTypes.oneOfType([
        PropTypes.instanceOf(Date),
        PropTypes.instanceOf(dayjs),
      ]),
    },
  ),
  setFilter: PropTypes.func.isRequired,
  useDayjs: PropTypes.bool,
};
