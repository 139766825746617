/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Helmet from "react-helmet";
import { useQuery } from "react-query";
import { Box } from "@material-ui/core";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import CompanyCell from "@/ui/atoms/CompanyCell";
import CustomTable from "@/ui/molecules/CustomTable";
import DateRangeFilter from "@/ui/atoms/TableFilters/DateRangeFilter";
import SelectColumnFilter from "@/ui/atoms/TableFilters/SelectColumnFilter";

import { format } from "date-fns";
import { lastTwoWeeksofInteractions } from "@/api/Interactions";
import Page from "@/ui/molecules/Page";

export function RecentMeetings() {
  const { data: responses = [], isLoading } = useQuery(["lastTwoWeeksOfInteractions"], lastTwoWeeksofInteractions);
  const columns = React.useMemo(
    () => [
      {
        Header: "Company",
        accessor: "companyName",
        Cell: ({ row }) => <CompanyCell {...row.original} />,
      },
      {
        Header: "Names",
        accessor: "names",
        Filter: SelectColumnFilter,
        filter: "includes",
        Cell: ({ value }) => value.join(", "),
      },
      {
        Header: "Interaction Date",
        accessor: (row) => new Date(row.interactionDate),
        filter: "dateRange",
        sortType: "date",
        Filter: ({ column }) => <Box style={{ maxWidth: "260" }}><DateRangeFilter filterValue={column.filterValue} setFilter={column.setFilter} /></Box>,
        Cell: ({ value: interactionDate }) => format(interactionDate, "MMM dd, yyyy"),
      },
    ],
    [],
  );

  return (
    <CenteredProgress isLoading={isLoading} displayChildren>
      <Helmet>
        <title>Recent Meetings - vOS</title>
      </Helmet>
      <Page>
        <Page.Title>Firm Meetings and Interactions</Page.Title>
        <Page.Content data-cy="recent-meeting__table">
          <CustomTable
            // title="Meetings and Interactions"
            data={responses}
            columns={columns}
          />
        </Page.Content>
      </Page>
    </CenteredProgress>
  );
}

export default RecentMeetings;
