import React from "react";
import { Routes, Route } from "react-router-dom";
import TaskList from "./TaskList";
import ActivityFeed from "./ActivityFeed";
import InternalMetricFiles from "./InternalMetricFiles";

export default function MetricTasks() {
  return (
    <Routes>
      <Route path="" element={<TaskList />} />
      <Route path="activity-feed" element={<ActivityFeed />} />
      <Route path="internal-files" element={<InternalMetricFiles />} />
    </Routes>
  );
}
