import React, { useMemo } from "react";
import Page from "@/ui/molecules/Page";
import { useQuery } from "react-query";
import { getPortfolioHealth } from "@/api/Reporting";
import {
  Card,
  Box,
  Typography,
  Chip,
  useMediaQuery,
  useTheme,
  Tooltip,
} from "@mui/material";
import { CompanyCell, KPICell, MoneyCell } from "@/components/Table/Primitives";
import { DataTable } from "@/components/LocalTable";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { IHEChangeCell } from "@/pages/IHEChange/IHEChangeCell";
import IHEChangeCellIcon from "@/pages/IHEChange/IHEIcon";
import fundsName from "@/utils/getFundNaming";
import dayjs from "dayjs";
import Progress from "@/ui/atoms/Progress";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import {
  FundListFilter,
  FundListAffinityKey,
} from "@/pages/WeeklyMeetingIC/utils";

const DefaultFundTypeQueryParam = withDefault(StringParam, "isGrowthFund6");

const reverseObject = (obj) => Object.fromEntries(
  Object.entries(obj).map(([key, value]) => [value, key]),
);

const timeSeriesDataSort = (a, b, columnId) => {
  const aData = a.original[columnId];
  const bData = b.original[columnId];
  if (!aData) {
    return -1;
  }
  if (!bData) {
    return 1;
  }
  const aValue = aData[0]?.value;
  const bValue = bData[0]?.value;
  if (aValue === bValue) {
    return 0;
  }
  return aValue < bValue ? -1 : 1;
};

function PortfolioHealth() {
  const theme = useTheme();
  const isMdDown = useMediaQuery(theme.breakpoints.down("md"));

  const [fundType, setFundType] = useQueryParam(
    "fundType",
    DefaultFundTypeQueryParam,
  );
  const flipFilter = reverseObject(FundListFilter);
  const flip = FundListAffinityKey[flipFilter[fundType]];

  const colorSentiment = (score) => {
    if (score >= 0.5) {
      return "green";
    }
    if (score < 0.5 && score > -0.5) {
      return "yellow";
    }
    return "red";
  };

  const columnDef = useMemo(
    () => [
      {
        header: "",
        id: "primary",
        columns: [
          {
            header: "Company",
            accessorKey: "organization",
            enableSorting: false,
            enableColumnFilter: false,
            cell: (prop) => <CompanyCell value={prop.getValue()} />,
            id: "organization",
            minSize: isMdDown ? 50 : 250,
          },
        ],
      },
      {
        header: "Valor Funding",
        id: "secondary",
        columns: [
          {
            header: "Funds",
            id: "funds[]",
            accessorKey: "fundMemberships",
            enableSorting: false,
            enableColumnFilter: false,

            minSize: 280,
            cell: (info) => (
              <Box
                display="inline-flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
                flexWrap="wrap"
                width="100%"
              >
                {Object.keys(info.getValue() ?? {}).map((key) => {
                  if (info.getValue()[key] === true) {
                    return (
                      <Chip key={key} label={fundsName[key]} size="small" />
                    );
                  }
                  return null;
                })}
              </Box>
            ),
          },
          {
            header: "Fund Invested Capital",
            id: "fundInvestments",
            sortType: "numeric",
            accessorFn: (row) => row.fundInvestments[flip],
            cell: (prop) => <MoneyCell value={prop.getValue()} />,
          },
        ],
      },
      {
        header: "Valor Surveys",
        columns: [
          {
            header: "Last Check In Survey",
            id: "mslsLastSurvey",
            accessorKey: "msl",
            enableSorting: false,
            enableColumnFilter: false,
            size: 240,
            cell: ({ row }) => {
              const { msl, survey, organization } = row.original;
              if (!msl && !survey) {
                return null;
              }
              return (
                <Tooltip
                  title={`${msl.firstName} ${msl.lastName} took ${survey.surveyName}`}
                >
                  <Box
                    display="flex"
                    padding={1}
                    gap={2}
                    width={192}
                    sx={{
                      cursor: "pointer",
                      border: `1px solid ${theme.palette.divider}`,
                      borderRadius: 1,
                      "&:hover": {
                        backgroundColor: theme.palette.action.hover,
                      },
                    }}
                    onClick={() => {
                      window.open(`/org/${organization.valorId}`, "_blank");
                    }}
                  >
                    <Box display="flex" gap={2}>
                      {msl ? (
                        <UserAvatar
                          user={msl}
                          style={{
                            width: "25px",
                            height: "25px",
                            fontSize: "1em",
                          }}
                        />
                      ) : null}
                      <Box>
                        <IHEChangeCellIcon
                          size="big"
                          color={colorSentiment(survey.sentimentScore)}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        {dayjs(survey.completedAt).format("MMM DD, YYYY")}
                      </Typography>
                    </Box>
                  </Box>
                </Tooltip>
              );
            },
          },
          {
            header: "Negative Changes",
            id: "negativeChanges",
            accessorFn: (row) => [
              ...(row.managementChanges ?? []),
              ...(row.fundamentalsChanges ?? []),
              ...(row.revenueChanges ?? []),
              ...(row.asymmetricRiskChanges ?? []),
              ...(row.otherChanges ?? []),
            ],
            enableSorting: false,
            enableColumnFilter: false,
            cell: (prop) => (
              <IHEChangeCell type="negative" value={prop.getValue()} />
            ),
            minSize: 555,
            size: 555,
          },
          {
            header: "Positive Changes",
            id: "positiveChanges",
            accessorFn: (row) => [
              ...(row.managementChanges ?? []),
              ...(row.fundamentalsChanges ?? []),
              ...(row.revenueChanges ?? []),
              ...(row.asymmetricRiskChanges ?? []),
              ...(row.otherChanges ?? []),
            ],
            enableSorting: false,
            enableColumnFilter: false,
            cell: (prop) => (
              <IHEChangeCell type="positive" value={prop.getValue()} />
            ),
            minSize: 555,
            size: 555,
          },
        ],
      },
      {
        header: "Employee",
        columns: [
          {
            header: " Employee Total",
            id: "orgEmployeeTotal",
            accessorKey: "orgEmployeeTotal",
            sortingFn: timeSeriesDataSort,
            sortType: "numeric",
            enableFiltering: false,
            minSize: 48,
            size: 80,
            cell: (prop) => (
              <KPICell name="Employee Total" value={prop.getValue()} />
            ),
          },
        ],
      },
      {
        header: "Glassdoor",
        columns: [
          {
            header: "Overall Rating",
            id: "overall",
            accessorKey: "overall",
            sortingFn: timeSeriesDataSort,
            sortType: "numeric",
            enableFiltering: false,
            minSize: 48,
            size: 80,
            cell: (prop) => (
              // JSON.stringify(prop.row.original.reviews
              //   .reduce((acc, review) => acc + review.value, 0))
              <KPICell
                name="Overall Glassdoor Rating"
                value={(prop.getValue() ?? []).map((x) => (x.value === 0
                  ? { timestamp: x.timestamp, value: null }
                  : x))}
                yDomain={[0, 5]}
              />
            ),
          },
          {
            header: "Business Outlook",
            id: "businessOutlook",
            accessorKey: "businessOutlook",
            sortingFn: timeSeriesDataSort,
            sortType: "numeric",
            enableFiltering: false,
            minSize: 48,
            size: 80,
            cell: (prop) => (
              <KPICell
                name="Business Outlook Rating"
                value={(prop.getValue() ?? []).map((x) => (x.value === 0
                  ? { timestamp: x.timestamp, value: null }
                  : x))}
                yDomain={[0, 100]}
              />
            ),
          },
          {
            header: "Senior Leadership",
            id: "seniorLeadership",
            accessorKey: "seniorLeadership",
            sortingFn: timeSeriesDataSort,
            sortType: "numeric",
            enableFiltering: false,
            minSize: 48,
            size: 80,
            cell: (prop) => (
              <KPICell
                name="Senior Leadership"
                value={(prop.getValue() ?? []).map((x) => (x.value === 0
                  ? { timestamp: x.timestamp, value: null }
                  : x))}
                yDomain={[0, 5]}
              />
            ),
          },
        ],
      },
      {
        header: "Credit Card Sample",
        columns: [
          {
            header: "Customers",
            id: "customers",
            accessorKey: "customers",
            enableSorting: false,
            enableFiltering: false,
            minSize: 48,
            size: 80,
            cell: (prop) => (
              <KPICell
                name="CC Customers"
                value={prop.getValue()}
                showValue={false}
              />
            ),
          },
          {
            header: "Sales",
            id: "sales",
            accessorKey: "sales",
            enableFiltering: false,
            minSize: 48,
            size: 80,
            cell: (prop) => (
              <KPICell
                name="CC Sales"
                value={prop.getValue()}
                showValue={false}
              />
            ),
          },
        ],
      },
    ],
    [flip],
  );
  const { data: portfolioCoHealth = [], isLoading: isPortfolioLoading } = useQuery(["portfolioCost", fundType], async () => {
    const data = await getPortfolioHealth(fundType);
    return data.sort(
      (a, b) => (b.fundInvestments?.[flip] ?? 0) - (a.fundInvestments?.[flip] ?? 0),
    );
  });

  return (
    <Page>
      <Page.Title>Portfolio Health Metrics</Page.Title>
      <Page.Content>
        <Box mb={1}>
          <ButtonTabs
            width="720px"
            activeKey={fundType}
            options={[
              { key: "isGrowthFund6", label: "Fund VI" },
              { key: "isGrowthFund5", label: "Fund V" },
              { key: "isGrowthFund4", label: "Fund IV" },
              { key: "isGrowthFund3", label: "Fund III" },
              { key: "isVofFund", label: "VOF" },
              { key: "isCvConsortioFund", label: "CV Consortio" },
              { key: "isSeedFund1", label: "Seed I" },
              { key: "isVsvFund1", label: "VSV I" },
              { key: "isVsvFund2", label: "VSV II" },
            ]}
            onClick={(o) => setFundType(o.key)}
          />
        </Box>
        <Card elevation={0} sx={{ height: "auto" }}>
          {isPortfolioLoading ? (
            <Box p={3}>
              <Progress />
            </Box>
          ) : (
            <DataTable data={portfolioCoHealth} columns={columnDef} />
          )}
        </Card>
      </Page.Content>
    </Page>
  );
}
export default PortfolioHealth;
