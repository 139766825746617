import { TaskEnum } from "@/api/Tasks";

export enum ProcessTypes {
  Deals = "deals",
  Portfolio = "portfolio",
}

export enum UserFilter {
  Assigned = "assigned",
  All = "all",
}

export type ProcessType = ProcessTypes.Deals | ProcessTypes.Portfolio;
export type UserFilterType = UserFilter.Assigned | UserFilter.All;

export type TaskProcessId = {
  [key in TaskEnum]: ProcessTypes;
};

export const taskTypeProcessId = {
  additional_capital: ProcessTypes.Portfolio,
  board_survey_missing: ProcessTypes.Portfolio,
  comm_delay: ProcessTypes.Portfolio,
  financial_metrics_setup_but_no_files: ProcessTypes.Portfolio,
  financial_metrics_setup_needed: ProcessTypes.Portfolio,
  forecasting_missing: ProcessTypes.Portfolio,
  oa_missing: ProcessTypes.Deals,
  os_missing: ProcessTypes.Deals,
  p2p_month_1_missing: ProcessTypes.Deals,
  p2p_month_6_missing: ProcessTypes.Deals,
  partner_signoff_on_portco_required: ProcessTypes.Portfolio,
  portco_setup_required: ProcessTypes.Portfolio,
  quarterly_assessment: ProcessTypes.Portfolio,
} as TaskProcessId;

export const taskTypePrettyName = {
  additional_capital: "Additional Capital Form Missing",
  board_survey_missing: "Board Survey Missing",
  comm_delay: "Communication Delay",
  financial_metrics_setup_but_no_files: "Financial Metrics Setup But No Files",
  financial_metrics_setup_needed: "Financial Metrics Setup Needed",
  forecasting_missing: "Forecasting Survey Missing",
  oa_missing: "OA Survey Missing",
  os_missing: "OS Survey Missing",
  p2p_month_1_missing: "P2P Month 1 Missing",
  p2p_month_6_missing: "P2P Month 6 Missing",
  partner_signoff_on_portco_required: "Partner Signoff on Portco Required",
  portco_setup_required: "Portfolio Company Setup Required",
  quarterly_assessment: "Quarterly Assessment Missing",
} as { [key in TaskEnum]: string };
