import React from "react";
import { Box, Skeleton } from "@mui/material";

type TaskFormLoaderProps = {
  edit?: boolean;
}

function TaskFormLoader({
  edit = false,
}: TaskFormLoaderProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap={1}
      minWidth="50%"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        flexGrow={1}
      >
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={200} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        <Skeleton variant="rectangular" width="100%" height={50} />
        {!edit && (
          <Box
            display="flex"
            flexDirection="row"
            gap={1}
            justifyContent="flex-end"
          >
            <Skeleton variant="rectangular" width={100} height={50} />
            <Skeleton variant="rectangular" width={100} height={50} />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default TaskFormLoader;
