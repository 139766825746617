import React from "react";
import Section from "@/ui/atoms/Section";
import {
  Skeleton, Box, Typography, useTheme, Link,
} from "@mui/material";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { getNewsArticles } from "@/api/NewsArticles";
import ClampsTooltip from "@/components/ClampsTooltip";
import { Organization } from "@/api/Organization";

interface Props {
  company: Organization
}

function RecentNews({ company }: Props) {
  const { valorId } = company;
  const theme = useTheme();
  const { data: newsArticles = [], isLoading } = useQuery(
    ["NewsArticles", valorId],
    async () => {
      const response = await getNewsArticles({ valorId, limit: 10 });
      return response?.results;
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
    },
  );

  const renderContent = () => {
    if (isLoading) {
      const fake = (key) => (
        <Box display="flex" sx={{ gap: "8px", margin: "16px 0", width: "100%" }} key={key}>
          <Skeleton variant="rounded" height="56px" width="86px" />
          <Box display="flex" flexDirection="column">
            <Skeleton variant="text" height="20px" width="220px" />
            <Skeleton variant="text" height="16px" width="80px" />
            <Skeleton variant="text" height="16px" width="120px" />
          </Box>
        </Box>
      );
      return [fake(1), fake(2), fake(3), fake(4), fake(5)];
    }
    let newsRender: JSX.Element | JSX.Element[] | null = null;
    if (newsArticles.length) {
      newsRender = newsArticles.map(({
        title, siteName, imageUrl, pageUrl, articleTimestamp, articleDid,
      }) => (
        <Box
          key={articleDid}
          display="flex"
          alignItems="center"
          sx={{
            gap: "16px",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}
        >
          <Link
            href={pageUrl}
            target="_blank"
          >
            <Box sx={{
              minWidth: 88,
              width: 88,
              height: 56,
              borderRadius: theme.shape.borderRadius,
            }}
            >
              {imageUrl ? (
                <img
                  alt=""
                  style={{
                    display: "block",
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                    borderRadius: theme.shape.borderRadius,
                  }}
                  src={imageUrl}
                />
              ) : null}
            </Box>
          </Link>
          <Box
            display="flex"
            flexDirection="column"
            overflow="hidden"
          >
            <ClampsTooltip
              title={title}
            >
              <Link
                href={pageUrl}
                target="_blank"
                sx={{
                  color: theme.palette.text.primary,
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                <Typography
                  variant="boldSubtitle2"
                >
                  {title}
                </Typography>
              </Link>
            </ClampsTooltip>
            <Typography
              sx={{
                width: "220px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
              }}
              variant="caption"
            >
              {siteName}
            </Typography>
            <Typography variant="caption">{dayjs(articleTimestamp).format("MM/DD/YYYY")}</Typography>
          </Box>

        </Box>
      ));
    } else {
      return null;
    }

    return (
      <Box display="flex" flexDirection="column" sx={{ gap: "8px", margin: "16px 0", width: "100%" }}>
        {newsRender}
      </Box>
    );
  };
  const content = renderContent();
  return (
    <Section>
      <Section.Title>
        Recent News
      </Section.Title>
      <Section.Content>
        {content !== null
          ? (
            <Box paddingLeft={1} paddingRight={1}>
              {content}
            </Box>
          )
          : <Typography>No Recent News</Typography>}

      </Section.Content>
    </Section>
  );
}

export default RecentNews;
