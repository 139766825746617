import React from "react";
import { styled, MUIStyledCommonProps } from "@mui/system";
import Flake from "@/ui/atoms/Icons/Flake";

const Parent = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& > svg": {
    animation: "spin 4s linear infinite",
    "@keyframes spin": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(359deg)",
      },
    },
  },
  "& > svg > path": {
    fill: theme.palette.primary.main,
  },
}));

interface Props {
  sx?: MUIStyledCommonProps["sx"];
}

export default function Progress({ sx }: Props) {
  return (
    <Parent sx={sx}>
      <Flake />
    </Parent>
  );
}
