import React from "react";
import { Transcript } from "@/api/Tegus";
import {
  Box,
} from "@mui/material";
import AdminSummaryRow from "./AdminSummaryRow";
import AssociateSummaryRow from "./AssociateSummaryRow";

export default function TranscriptRow({
  transcript,
  iheTrends,
  onSummaryOpen,
  version,
}: {
  transcript: Transcript;
  iheTrends: Record<string, [string, string]>;
  onSummaryOpen: (ts: Transcript) => void;
  version: "admin" | "associate";
}) {
  const ihe = Object
    .entries(iheTrends || {})
    .map(([trend, [survey, ts]]) => [trend, [survey, ts]])
    .filter((change) => change !== null);
  const allIhe = transcript.scores || [];
  const iheChanges = ihe.length;

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      key={transcript.id}
    >
      {version === "admin" && (
        <AdminSummaryRow
          transcript={transcript}
          ihe={ihe}
          allIhe={allIhe}
          iheChanges={iheChanges}
          onSummaryOpen={onSummaryOpen}
        />
      )}
      {version === "associate" && (
        <AssociateSummaryRow
          transcript={transcript}
          ihe={ihe}
          iheChanges={iheChanges}
          onSummaryOpen={onSummaryOpen}
        />
      )}
    </Box>
  );
}
