import React from "react";
import { Card, Box, Button } from "@mui/material";
import { useQuery } from "react-query";
import Progress from "@/ui/atoms/Progress";
import { getProcess, getAddtionalCapitalDeploymentReport } from "@/api/Process";
import Page from "@/ui/molecules/Page";
import { useAuth } from "@/hooks/useAuth";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TableView from "../ProcessManagement/TableView";
import FormWizard from "../ProcessManagement/Forms/Wizard";

const ACD_ID = '2b88ae61-1452-456a-8936-df8044eadaf1';

export function AdditionalCapitalDeploymentReport() {
  const { user } = useAuth();
  const [openACDModal, setOpenACDModal] = React.useState(false);

  const sortStatement = [
    { id: "tasks.fieldValues.f1b3b3b4-1b3b-4b3b-8b3b-1b3b3b4b3b3b.user", desc: false },
  ];

  const apiSortStatement = sortStatement.map(
    ({ id, desc }) => `${desc ? "-" : ""}${id}`,
  );

  const columnFilters = {
    OR: {
      eq: {
        "tasks.fieldValues.7c837f9a-f935-41ef-95a7-e5f802895779.user": [`${user.id}`],
        "tasks.fieldValues.f1b3b3b4-1b3b-4b3b-8b3b-1b3b3b4b3b3b.user": [`${user.id}`],
      },
    },
  };

  const columnVisOrder = {
    vis: {
      "tasks.fieldValues.384595e3-10d3-49df-8398-1412e9fdfdd1.company": true,
      "tasks.fieldValues.f7274069-4879-45d4-948e-f8af823fa087.choiceId": false,
      "tasks.fieldValues.430ede9f-eb53-4684-9659-1131004e5f4c.choiceId": true,
      "tasks.fieldValues.c8f096ce-34b3-4a0d-9bdb-13f703cb5c8c.user": true,
      "tasks.fieldValues.f1b3b3b4-1b3b-4b3b-8b3b-1b3b3b4b3b3b.user": true,
      "tasks.fieldValues.7c837f9a-f935-41ef-95a7-e5f802895779.user": true,
      "tasks.fieldValues.6c629edc-08b4-4501-8bc0-16706223f041.value": false,
      "tasks.fieldValues.7d7b8696-6e64-497d-9b91-92b7cd70633f.value": false,
      "tasks.fieldValues.7c6458a8-a67b-4176-91ec-b198194e8ba3.person": false,
      "tasks.fieldValues.d427fef1-f025-482c-a54c-62e46293a66c.person": false,
      "tasks.fieldValues.0d272d68-d325-4ffa-b43a-a6a463d18bad.choiceId": false,
      "tasks.fieldValues.96395dff-f3ec-4f77-af73-40501f8be321.person": false,
      "tasks.fieldValues.82197c80-0d71-4c01-87a9-87e0ce2b4fed.choiceId": false,
      "tasks.fieldValues.846b4e9a-fec6-4ec6-948c-a2f42855b0a5.number": false,
      "tasks.fieldValues.c305134d-01f2-487a-916d-97f1b039a0e1.value": false,
      "tasks.fieldValues.a3a8c877-3b68-4ce4-a459-02a20d75ddf3.value": false,
      "tasks.fieldValues.46654a9c-2211-4738-80db-846c8779e690.value": false,
      "tasks.fieldValues.16eb67df-5616-45d7-9185-26463b538229.choiceId": false,
      "tasks.fieldValues.02a1950b-5071-47e3-aaf3-b789ebf970d2.value": false,
      "tasks.fieldValues.387a6842-fd83-404c-9da9-e8c666c3bee1.number": false,
      "tasks.fieldValues.b679cd4e-cc34-4120-95da-ff0bffaafe4f.choiceId": false,
      "tasks.fieldValues.16322f1c-fdd5-45bc-a3ce-5f7207ee9c30.value": false,
      "tasks.fieldValues.6e10d69e-1f6d-4ddd-aa93-51029f08e4f7.number": false,
      "tasks.fieldValues.26316909-9548-4b62-aff4-21817199f5ef.value": false,
      "tasks.fieldValues.3e1cd0db-e4b7-4cb0-b914-e8d18d5b5d3b.value": false,
      "tasks.fieldValues.3a1bf1b9-cd3b-43af-9f18-6d77673457eb.value": false,
      "tasks.fieldValues.b5dffea6-3316-44b8-90da-bc6e6f37994a.value": false,
      "tasks.fieldValues.9cedb07b-f8e1-4139-a708-f62372b730ef.value": false,
      "tasks.fieldValues.80b1994b-7a28-44a4-902e-8fe14e1e1d62.choiceId": false,
      "tasks.fieldValues.ff3ce6c2-1a64-49d8-8c24-d8278f50608e.value": false,
      "tasks.fieldValues.2c38fadb-e851-48be-9eca-cb7f5ebede04.number": false,
      "tasks.fieldValues.71583cf2-d965-46e6-8284-5cee11dd2451.choiceId": false,
      "tasks.fieldValues.f17a5b4a-3fc1-498c-82c1-4b468f504afa.choiceId": false,
      "tasks.fieldValues.60b2417b-1e59-4021-8990-587b9e4720bc.choiceId": false,
      "tasks.fieldValues.30dff107-de1c-4ba7-bf07-28e94c8cefbd.number": false,
      "tasks.fieldValues.a6280479-cb81-4b06-bab5-5bfc77a2fffb.number": false,
      "tasks.fieldValues.dcae5197-585e-4185-8367-24d3c0ad7816.number": false,
      "tasks.fieldValues.c3e486d7-61cb-4365-bb13-517b9e8a22f2.choiceId": false,
      "tasks.fieldValues.cb882dd4-6c79-418d-8727-5563ecd61703.choiceId": false,
      "tasks.fieldValues.faf28465-1e8c-4e89-9aaa-90b961a34813.choiceId": false,
      "tasks.fieldValues.ffadad6c-7279-44ce-9214-49452a5c42f5.choiceId": false,
      "tasks.fieldValues.8fc940d4-4513-4db9-9852-b5dd5279404c.choiceId": false,
      "tasks.fieldValues.8648c3cb-313b-4334-8c45-727815fee318.choiceId": false,
      "tasks.fieldValues.cd95a943-8e6a-4c57-a563-3a13a6fe78c2.number": false,
      "tasks.fieldValues.88c33722-a3e0-4657-ac36-516250f0df36.number": false,
      "tasks.fieldValues.4812226d-2381-4828-970e-f49db7037b18.value": false,
      "tasks.fieldValues.39241f57-d67e-40c2-8573-16213b11ceea.choiceId": false,
      "tasks.fieldValues.1485cdfd-5e8d-4eeb-820d-bde2a0312d5e.value": false,
      "tasks.fieldValues.89e66991-f95c-4faf-a146-a8f62e7b7d66.number": false,
      "tasks.fieldValues.845ad563-c558-4718-b9fa-fb73fdd8c9ec.number": false,
      "tasks.fieldValues.490733f8-3292-427b-9713-6c743ff75152.number": false,
      "tasks.fieldValues.7358d62a-8e8f-4e72-8f2c-e38d88a83f8f.user": false,
      "tasks.fieldValues.cd18c9a8-d7fc-48eb-b129-9c5456cc5835.user": false,
      "tasks.fieldValues.8544be12-f399-4cb5-80ee-3f777321304f.choiceId": false,
      "tasks.fieldValues.98d2cb1e-9798-4a70-88dd-e77e6cce514b.choiceId": false,
      "tasks.fieldValues.a4600323-e2f9-4809-90cc-312021e7c144.choiceId": false,
      "tasks.fieldValues.94a3cc87-3b03-478a-89d1-2dbac033918e.choiceId": false,
      "tasks.fieldValues.dd977e2f-966e-4462-a242-36b58d70a292.choiceId": false,
      "tasks.fieldValues.a697ff6f-79dd-4eeb-a16d-c33580fc703c.choiceId": false,
      "tasks.fieldValues.661dd567-f615-4454-9d44-75ae2bff561b.choiceId": false,
      "tasks.fieldValues.7eb8fe9f-7ed3-4b68-8c4a-092e5e9729d6.choiceId": false,
      "tasks.fieldValues.d70c0e51-d889-47bf-9423-05c579111101.choiceId": false,
      "tasks.fieldValues.c1519ec0-b416-44ac-b0b7-1bb3378b34e0.choiceId": false,
      "tasks.fieldValues.29f4eeb9-6236-4c32-8437-0bc3bd208d73.choiceId": false,
      "tasks.fieldValues.68fdbb69-8776-4a08-9afd-636cb4fe58c5.choiceId": false,
      "tasks.fieldValues.fb849415-81a7-401a-9782-3d8bb4abdc42.choiceId": false,
      "tasks.fieldValues.ccc77160-f6b9-4327-9acb-16af2ee93f99.choiceId": false,
      "tasks.fieldValues.f7f703ae-e722-499b-aad4-3759f16ed36d.choiceId": false,
      "tasks.fieldValues.13b3e45b-738c-4e25-ba30-f1d9d793f679.choiceId": false,
      "tasks.fieldValues.4ce20cef-b61f-495c-b932-196187d294ac.value": false,
      "tasks.fieldValues.7ff19c39-1774-40e4-b394-7f06ad03856f.value": false,
      "tasks.fieldValues.b2f96457-0ed7-4376-80fc-2747c8915859.value": false,
      "tasks.fieldValues.a224ba44-4406-46f7-8ada-7a1a6502264d.value": false,
      "tasks.fieldValues.9a0e9279-4734-4b62-b615-9768863d7f2d.value": false,
      "tasks.fieldValues.be746b34-1d79-4328-82fd-f29f1160005a.value": false,
      dateAdded: false,
    },
    order: [
      "tasks.fieldValues.384595e3-10d3-49df-8398-1412e9fdfdd1.company",
      "tasks.fieldValues.f7274069-4879-45d4-948e-f8af823fa087.choiceId",
      "tasks.fieldValues.430ede9f-eb53-4684-9659-1131004e5f4c.choiceId",
      "tasks.fieldValues.c8f096ce-34b3-4a0d-9bdb-13f703cb5c8c.user",
      "tasks.fieldValues.f1b3b3b4-1b3b-4b3b-8b3b-1b3b3b4b3b3b.user",
      "tasks.fieldValues.7c837f9a-f935-41ef-95a7-e5f802895779.user",
    ],
  };

  const {
    data: tasks,
    isLoading: isLoadingTasks,
  } = useQuery(
    ["tasks"],
    () => getAddtionalCapitalDeploymentReport(apiSortStatement, columnFilters),
  );

  const {
    data: processData = {
      name: "",
      fields: [],
      fieldFilters: [],
      organizationFields: [],
      settings: [],
      lifecycleStages: [],
    },
    isLoading: isProcessLoading,
  } = useQuery(["process", "portfolio"], async () => getProcess("portfolio"));

  return (
    <Page>
      <Page.Title>Exception Report: Additional Capital Deployments</Page.Title>
      <Page.Content>
        <Card>
          {isProcessLoading || isLoadingTasks ? <Progress /> : null}
          <Box>
            <Box m={1} display="flex" gap={1} alignItems="center">
              <Button
                color="primary"
                variant="contained"
                onClick={() => setOpenACDModal(true)}
                startIcon={<PostAddIcon />}
              >
                Additional Capital Deployment
              </Button>
            </Box>
            <TableView
              enableViews={false}
              tasks={tasks || []}
              processId="portfolio"
              isLoading={isProcessLoading || isLoadingTasks}
              fields={[...processData.fields, ...processData.organizationFields]}
              mode="ungrouped"
              sorting={sortStatement}
              columnFilters={[]}
              columnVisibility={columnVisOrder.vis}
              columnOrder={columnVisOrder.order}
              enableColumnFilter={false}
              enableSorting={false}
            />
          </Box>
        </Card>
        <FormWizard
          open={openACDModal}
          onClose={() => setOpenACDModal(false)}
          formId={ACD_ID}
          processId="portfolio"
        />
      </Page.Content>
    </Page>
  );
}

export default AdditionalCapitalDeploymentReport;
