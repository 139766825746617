import React from "react";
import { Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";

type Person = {
  firstName: string;
  lastName: string;
};

export default function LastContactedCell(
  { value }: {
    value: {
      date: string;
      persons: Person[],
    } | null;
  },
) {
  const theme = useTheme();

  if (!value?.date) {
    return <Typography variant="body2">No Contact Data</Typography>;
  }

  const daysDiff = dayjs().diff(dayjs(value?.date), "days");
  let color = theme.palette.red.main;
  if (daysDiff < 30) {
    color = theme.palette.green.main;
  } else if (daysDiff < 90) {
    color = theme.palette.yellow.main;
  }

  return (
    <Box>
      <Typography
        variant="body2"
        sx={{
          color,
        }}
      >
        {dayjs(value.date).fromNow()}
      </Typography>
      {value.persons?.length > 0 && (
        <Typography
          variant="caption"
          sx={{
            whiteSpace: "wrap",
          }}
        >
          by
          {" "}
          {value.persons.map((person) => (
            (`${person.firstName} ${person.lastName}`)
          )).join(", ")}
        </Typography>
      )}
    </Box>
  );
}
