const AARSurveys = {
  selfReviewAnalyst: '6464231e-aa9a-4a88-aa3d-14d8e0d95026',
  selfReviewAssociate: '4c712ffb-b054-405f-8594-93d89366cd59',
  selfReviewVP: '4cfb67ff-afee-4c4a-9d15-7422c795bbf7',
  selfReviewPrincipal: '71580b28-843b-4c0c-b03f-cc7ab83a0f1b',
  peerReviewAnalyst: 'aa6b9a33-53fa-42c6-8016-7001d498c639',
  peerReviewAssociate: '61151c65-6a05-44a2-a4ee-bf48ff22de5f',
  peerReviewVP: '547d830a-4088-477b-abba-095b34756073',
  peerReviewPrincipal: '5f67eef1-e841-4061-b97e-cac4e34cb54d',
};

export default AARSurveys;