/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/prop-types */ // TODO
import React, { useRef } from "react";
import {
  BarChart,
  Bar,
  LabelList,
  YAxis,
  XAxis,
  ResponsiveContainer,
} from "recharts";
import { useQueryClient } from "react-query";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import { useTheme } from "@mui/material/styles";
import { Box, Typography, Switch } from "@mui/material";
import OverflowTip from "@/ui/atoms/OverflowTip";
import { ViewHeadline, Poll } from "@mui/icons-material";
import { formatBigDollars } from "@/utils/numberFormat";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { FrameworkChoiceColorIconMapping } from "@/constants/FrameworkChoiceColorIconMapping";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { usePopover } from "@/hooks/useGlobalPopover";
import { Link } from "react-router-dom";
import { useAuth } from "@/hooks/useAuth";
import { canEditAllSurveys, canReleaseAllPRs } from "@/constants/Roles";
import { patchResponse } from "@/api/FrameworkSurvey";
import { getSurveyLink } from "@/utils/surveys";

dayjs.extend(utc);

const getSurveyColor = (theme, score) => {
  if (score >= 0.5) {
    return theme.palette.green.main;
  }
  if (score < 0.5 && score > -0.5) {
    return theme.palette.yellow.main;
  }
  return theme.palette.red.dark;
};

const getDifferenceColor = (theme, value) => {
  if (value === null) {
    return theme.palette.gray.main;
  }
  if (value >= 10) {
    return theme.palette.green.main;
  }
  if (value >= -25) {
    return theme.palette.yellow.main;
  }
  return theme.palette.red.dark;
};

const periodConverter = {
  MONTHLY: "M",
  QUARTERLY: "Q",
  YEARLY: "Y",
};

const calculatePersonalCoDifference = ({ personal, company }) => {
  let difference = null;
  if (company !== "N/A" && personal !== "N/A") {
    const coNum = Number.parseFloat(company);
    const perNum = Number.parseFloat(personal);
    if (coNum === 0 && perNum === 0) {
      return null;
    }
    if (coNum === 0) {
      difference = ((coNum - perNum) / Math.abs(perNum)) * 100;
      difference = difference.toFixed(2);
    } else {
      difference = ((perNum - coNum) / Math.abs(coNum)) * 100;
      difference = difference.toFixed(2);
    }
  }
  return difference;
};

export function GridItem(props) {
  const {
    row,
    column,
    commentary,
    color,
    answerData,
    question,
    onClick,
    subject,
  } = props;

  const theme = useTheme();

  let content = null;
  let style = {};

  const rootSx = {
    gridRow: row,
    gridColumn: column,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    backgroundColor: `${color ? theme.palette?.[color]?.main : theme.palette.background.paper} !important`,
    "&:hover": {
      backgroundColor: `${color ? theme.palette?.[color]?.dark : theme.palette.action.hover} !important`,
    },
  };

  const iconStyleSx = {
    color: ["red", "green", "gray", "blue"].includes(color)
      ? "#fff"
      : theme.palette.mode === "light"
        ? "#4d4d4d"
        : "#fff",
  };

  if (
    question.questionType === "PERCENTAGE_ESTIMATE" ||
    question.questionType === "QUARTER"
  ) {
    content = (
      <Box sx={rootSx}>
        <Typography variant="subtitle2">
          {commentary}
          {question.questionType === "PERCENTAGE_ESTIMATE" ? "%" : ""}
        </Typography>
      </Box>
    );
  } else if (question.questionType.startsWith("DOLLAR_ESTIMATE")) {
    let personal;
    let period;
    let company;
    if (
      [
        "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE",
        "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
      ].includes(question.questionType)
    ) {
      const answerObj = JSON.parse(answerData);
      personal = answerObj.personal;
      period = answerObj.period;
      company = answerObj.company;
    } else if (
      question.questionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION"
    ) {
      const answerObj = JSON.parse(answerData);
      personal = answerObj.personal;
      company = answerObj.company;
    } else {
      personal = answerData;
    }

    const difference = calculatePersonalCoDifference({ personal, company });
    const differenceColor = getDifferenceColor(theme, difference);
    if (
      [
        "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
        "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION",
      ].includes(question.questionType)
    ) {
      style = { borderBottom: `3px solid ${differenceColor}` };
    }

    content = (
      <Box
        sx={{ ...rootSx, gap: "4px", ...style }}
        onClick={(event) => onClick(event, props)}
      >
        {company && (
          <Typography variant="subtitle2">
            {company === "N/A" ? company : formatBigDollars(company)}
          </Typography>
        )}
        {company && <Typography variant="subtitle2">v.</Typography>}
        <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
          {personal === "N/A" ? personal : formatBigDollars(personal)}
        </Typography>
        {period && (
          <Typography variant="subtitle2">{periodConverter[period]}</Typography>
        )}
        {Boolean(commentary?.length) && <ViewHeadline sx={iconStyleSx} />}
      </Box>
    );
  } else if (question.questionType === "FISCAL_YEAR") {
    const parse = JSON.parse(answerData);
    content = (
      <Box sx={rootSx}>
        <Typography variant="subtitle2">{`Current FY: ${parse.currentYear}`}</Typography>
      </Box>
    );
  } else if (
    question.questionType === "TEXT" ||
    question.questionType === "RETURN_DISTRIBUTION" ||
    question.questionType === "MULTIPLE_CHOICE"
  ) {
    const Icon = FrameworkChoiceColorIconMapping?.[color];
    content = (
      <Box
        sx={{ ...rootSx, ...style }}
        onClick={(event) => onClick(event, props)}
      >
        {question.questionType === "MULTIPLE_CHOICE" && (
          <Icon sx={iconStyleSx} />
        )}
        {Boolean(commentary?.length) && <ViewHeadline sx={iconStyleSx} />}
      </Box>
    );
  } else if (question.questionType === "SINGLE_USER") {
    content = (
      <Box
        sx={{ ...rootSx, ...style }}
        onClick={(event) => onClick(event, props)}
      >
        <Typography variant="boldBody2">
          {`${subject?.[0].firstName} ${subject?.[0].lastName}`}
        </Typography>
      </Box>
    );
  }

  return content;
}

export default function FrameworkGrid(props) {
  const { responses, sections, status, isAAR = false, aarParams = {} } = props;

  const queryClient = useQueryClient();
  const theme = useTheme();
  const referenceEl = useRef(null);
  const { openPopover } = usePopover();
  const { user } = useAuth();

  const RESPONSE_ROW_OFFSET = !isAAR ? 3 : 2;

  const handleChange = async (id, checked) => {
    try {
      queryClient.setQueryData(["aarSurveyResponses", aarParams], (oldData) =>
        oldData.map((r) => {
          const newR = { ...r };
          if (r.id === id) {
            newR.isReleased = checked;
          }
          return newR;
        })
      );
      const response = await patchResponse(id, {
        hidden: false,
        status: "PUBLISHED",
        isReleased: checked,
      });
      if (response?.status === 403) {
        throw new Error(response.status);
      }
    } catch (err) {
      queryClient.invalidateQueries(["aarSurveyResponses", aarParams], {
        exact: true,
      });
      // throw toast on error
    }
  };

  const animation = {
    green: "bounce 2s ease infinite",
    red: "bounceDown 2s ease infinite",
    yellow: "hithere 2s ease infinite",
    gray: "hithere 2s ease infinite",
    blue: "spin 2s ease infinite",
  };

  const renderPopOverContent = ({
    color,
    text,
    commentary,
    answerData,
    question: { questionType },
    subject,
  }) => {
    let questionSpecificVisualization = null;
    if (questionType === "MULTIPLE_CHOICE") {
      const Icon = FrameworkChoiceColorIconMapping[color];
      const style = {
        border: `1px solid ${theme.palette?.[color]?.main}`,
      };
      questionSpecificVisualization = (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              ...{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: {
                  xs: "100%",
                  sm: "calc(100% - 32px)",
                },
                height: theme.spacing(10),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
              },
              ...style,
            }}
          >
            <Icon
              sx={{
                color: theme.palette?.[color]?.main,
                marginRight: theme.spacing(1),
                animation: animation[color],
              }}
            />
            {text}
          </Box>
        </Box>
      );
    }

    if (questionType === "FISCAL_YEAR") {
      const parse = JSON.parse(answerData);
      return (
        <Box display="flex" flexDirection="column" width="100%">
          <Typography variant="subtitle1">{`Prior Year: ${parse.priorYear}`}</Typography>
          <Typography variant="subtitle1">{`Current Year: ${parse.currentYear}`}</Typography>
          <Typography variant="subtitle1">{`Type: ${parse.yearType}`}</Typography>
          {parse.fyeMonth ? (
            <Typography variant="subtitle1">{`FYE Month : ${parse.fyeMonth}`}</Typography>
          ) : null}
        </Box>
      );
    }

    if (questionType === "RETURN_DISTRIBUTION") {
      const answerObj = JSON.parse(answerData);
      const chartData = Object.entries(answerObj).map((x) => ({
        label: x[0],
        value: x[1],
      }));
      questionSpecificVisualization = (
        <ResponsiveContainer height={348} width={300}>
          <BarChart
            data={chartData}
            barGap={2}
            layout="vertical"
            margin={{
              top: 10,
              right: 40,
              bottom: 10,
              left: 5,
            }}
          >
            <XAxis datakey="value" type="number" hide />
            <YAxis dataKey="label" type="category" minTickGap={0} />
            <Bar barSize={48} dataKey="value" fill={theme.palette.primary.main}>
              <LabelList
                offset={1}
                position="right"
                dataKey="value"
                formatter={(value) => `${value}%`}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      );
    }

    if (questionType === "DOLLAR_ESTIMATE") {
      questionSpecificVisualization = (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="boldBody1">
            {formatBigDollars(answerData)}
          </Typography>
        </Box>
      );
    }

    if (questionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE") {
      const answerObj = JSON.parse(answerData);
      const { company, personal, period } = answerObj;
      const difference = calculatePersonalCoDifference({ personal, company });
      const differenceColor = getDifferenceColor(theme, difference);

      questionSpecificVisualization = (
        <Box display="flex" flexDirection="column" width="100%">
          {period}

          <Box
            display="flex"
            justifyContent="space-around"
            width="100%"
            sx={{ borderBottom: `3px solid ${differenceColor}` }}
          >
            <Box display="flex" flexDirection="column">
              <Typography>{"Company "}</Typography>
              <Typography>
                {company === "N/A" ? company : formatBigDollars(company)}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography>{"Personal "}</Typography>
              <Typography>
                {personal === "N/A" ? personal : formatBigDollars(personal)}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              {!!difference && (
                <>
                  <Typography>{"A difference of "}</Typography>
                  <Typography>{difference}%</Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      );
    }

    if (questionType === "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE") {
      const answerObj = JSON.parse(answerData);
      const { personal, period } = answerObj;

      questionSpecificVisualization = (
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" width="100%">
            <Box display="flex" flexDirection="column">
              <Typography>{"Company "}</Typography>
              <Typography>
                {personal === "N/A" ? personal : formatBigDollars(personal)}
                {personal === "N/A" ? "" : ` ${period}`}
              </Typography>
            </Box>
          </Box>
        </Box>
      );
    }

    if (questionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION") {
      const answerObj = JSON.parse(answerData);
      const { company, personal } = answerObj;
      const difference = calculatePersonalCoDifference({ personal, company });
      const differenceColor = getDifferenceColor(theme, difference);

      questionSpecificVisualization = (
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            justifyContent="space-around"
            width="100%"
            sx={{ borderBottom: `3px solid ${differenceColor}` }}
          >
            <Box display="flex" flexDirection="column">
              <Typography>{"Company "}</Typography>
              <Typography>
                {company === "N/A" ? company : formatBigDollars(company)}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography>{"Personal "}</Typography>
              <Typography>
                {personal === "N/A" ? personal : formatBigDollars(personal)}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              {!!difference && (
                <>
                  <Typography>{"A difference of "}</Typography>
                  <Typography>{difference}%</Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      );
    }

    if (questionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION") {
      const answerObj = JSON.parse(answerData);
      const { company, personal } = answerObj;
      const difference = calculatePersonalCoDifference({ personal, company });
      const differenceColor = getDifferenceColor(theme, difference);

      questionSpecificVisualization = (
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            display="flex"
            justifyContent="space-around"
            width="100%"
            sx={{ borderBottom: `3px solid ${differenceColor}` }}
          >
            <Box display="flex" flexDirection="column">
              <Typography>{"Company "}</Typography>
              <Typography>
                {company === "N/A" ? company : formatBigDollars(company)}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              <Typography>{"Personal "}</Typography>
              <Typography>
                {personal === "N/A" ? personal : formatBigDollars(personal)}
              </Typography>
            </Box>

            <Box display="flex" flexDirection="column">
              {!!difference && (
                <>
                  <Typography>{"A difference of "}</Typography>
                  <Typography>{difference}%</Typography>
                </>
              )}
            </Box>
          </Box>
        </Box>
      );
    }

    if (questionType === "SINGLE_USER") {
      const style = {
        border: `1px solid ${theme.palette?.[color]?.main}`,
      };
      questionSpecificVisualization = (
        <Box
          display="flex"
          flexDirection="column"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Box
            sx={{
              ...{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minWidth: {
                  xs: "100%",
                  sm: "calc(100% - 32px)",
                },
                height: theme.spacing(10),
                padding: theme.spacing(2),
                borderRadius: theme.shape.borderRadius,
                gap: theme.spacing(1),
              },
              ...style,
            }}
          >
            <UserAvatar user={subject?.[0]} />
            <Typography variant="boldBody1">{`${subject?.[0]?.firstName} ${subject?.[0]?.lastName}`}</Typography>
          </Box>
        </Box>
      );
    }

    return (
      <>
        {questionSpecificVisualization}
        {Boolean(commentary?.length) && (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Typography
              sx={{
                padding: theme.spacing(0.5, 0, 1, 0),
                color: theme.palette.text.secondary,
                fontWeight: theme.typography.fontWeightBold,
                textAlign: "left",
              }}
              variant="body2"
            >
              COMMENT
            </Typography>
            <Typography variant="body2">{commentary}</Typography>
          </Box>
        )}
      </>
    );
  };

  const handleClick = (event, data) => {
    if (data.answerData === "N/A" && !data.commentary) {
      event.stopPropagation();
      return;
    }
    const popOverContent = renderPopOverContent(data);

    referenceEl.current = event?.currentTarget;
    openPopover(referenceEl, popOverContent, data.attribute.name);
  };

  const getResponses = (answers, column) => {
    const responseCells = [];
    let row = 1;
    sections.forEach((section) => {
      row += RESPONSE_ROW_OFFSET;
      responseCells.push(
        <Box
          key={`cell-${row}-${column}`}
          sx={{
            gridColumn: column,
            gridRow: row,
            borderLeftStyle: "none",
          }}
        />
      );
      section.attributes.forEach((attribute) => {
        row += RESPONSE_ROW_OFFSET;
        const response = answers[attribute.id];
        responseCells.push(
          response ? (
            <GridItem
              key={`cell-${row}-${column}`}
              row={row}
              column={column}
              question={response.question}
              commentary={response.commentary}
              color={response.choice.color}
              text={response.choice.text}
              answerData={response.answerData}
              onClick={(e, data) => handleClick(e, { ...data, attribute })}
              subject={response?.users}
            />
          ) : (
            <Box
              key={`cell-${row}-${column}`}
              sx={{
                gridColumn: column,
                gridRow: row,
              }}
            />
          )
        );
      });
    });
    return responseCells;
  };
  const getLeftColumn = () => {
    const cells = [];
    let row = 1;
    if (!isAAR) {
      cells.push(
        <Box
          key="section-survey-sentiment"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
            gridRow: 3,
            gridColumn: 1,
            position: "sticky",
            left: 0,
            zIndex: 501,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0, 0.5),
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          Survey Sentiment
          <Poll sx={{ paddingLeft: "4px" }} />
        </Box>
      );
    }

    sections.forEach((section) => {
      row += RESPONSE_ROW_OFFSET;
      cells.push(
        <Box
          key={`section-${section.name}`}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gridRow: row,
            gridColumn: 1,
            height: row === 1 ? "76px" : "auto",
            position: "sticky",
            left: 0,
            zIndex: 501,
            backgroundColor: theme.palette.background.paper,
            padding: theme.spacing(0, 0.5),
            width: theme.spacing(28),
            borderRight: `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography variant="tableHeader">{section.name}</Typography>
        </Box>
      );
      section.attributes.forEach((attribute) => {
        row += RESPONSE_ROW_OFFSET;
        cells.push(
          <Box
            key={`attribute-${attribute.id}`}
            sx={{
              gridRow: row,
              gridColumn: 1,
              paddingLeft: "4px",
              position: "sticky",
              left: 0,
              zIndex: 501,
              backgroundColor: theme.palette.background.paper,
              display: "flex",
              alignItems: "center",
              borderRight: `1px solid ${theme.palette.divider}`,
            }}
          >
            <Typography
              sx={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                cursor: "pointer",
              }}
              variant="body2"
            >
              <OverflowTip>{attribute.name}</OverflowTip>
            </Typography>
          </Box>
        );
      });
    });
    return cells;
  };

  return (
    <Box sx={{ display: "flex" }}>
      {!responses?.length && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {status === "success" && sections.length && (
            <Typography
              sx={{
                fontWeight: 300,
                fontSize: "16px",
                width: "100%",
                height: "48px",
                textAlign: "center",
              }}
            >
              No Survey Responses
            </Typography>
          )}
        </Box>
      )}
      {Boolean(responses?.length) && (
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `224px repeat(${responses?.length}, 128px)`,
            width: {
              xs: "100%",
              sm: `calc(100vw - ${theme.spacing(20)})`,
            },
            overflowX: "scroll",
            overflowY: "scroll",
            height: "80vh",
            borderRight: `1px solid ${theme.palette.divider}`,
            "& > div": {
              borderTop: `1px solid ${theme.palette.divider}`,
              borderLeft: `1px solid ${theme.palette.divider}`,
              backgroundColor: theme.palette.background.paper,
            },
          }}
        >
          {getLeftColumn()}
          {responses.map((response, idx) => (
            <React.Fragment key={response.id}>
              <Box
                sx={{
                  gridRow: "1",
                  height: "148px",
                  gridColumn: idx + 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "space-between",
                  justifyContent: "center",
                  position: "sticky",
                  top: 0,
                  zIndex: 500,
                  backgroundColor: theme.palette.background.paper,
                  borderBottom: `1px solid ${theme.palette.divider}`,
                }}
                id="user-grid-item"
              >
                <Typography
                  sx={{ textAlign: "center" }}
                  variant="boldSubtitle2"
                >
                  {!isAAR
                    ? response.user.name
                    : `${response.user.firstName} ${response.user.lastName}`}
                </Typography>
                {canEditAllSurveys(user) && !isAAR ? (
                  <Link
                    to={`/org/${response.valorId}/surveys/${response.surveyId}?responseId=${response.id}`}
                  >
                    Edit
                  </Link>
                ) : null}
                <Typography
                  sx={{
                    color: theme.palette.text.secondary,
                    textAlign: "center",
                  }}
                  variant="caption"
                >
                  {dayjs
                    .utc(response.completedAt)
                    .local()
                    .format("MM/DD/YYYY")}
                </Typography>
                {!isAAR ? (
                  <>
                    <Typography
                      sx={{
                        width: "100%",
                        height: "72px",
                        textAlign: "center",
                        padding: (t) => t.spacing(1, 1),
                      }}
                      variant="caption"
                    >
                      <Link to={getSurveyLink(response)}>
                        {response.surveyName}
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                      variant="caption"
                    >
                      {response.vertical === "investments"
                        ? InvestmentProductMappings[response.department]
                        : InvestmentProductMappings[response.vertical]}
                    </Typography>
                  </>
                ) : (
                  <>
                    <Typography
                      sx={{
                        width: "100%",
                        height: "72px",
                        textAlign: "center",
                        padding: (t) => t.spacing(1, 1),
                      }}
                      variant="boldSubtitle2"
                    >
                      <Link to={getSurveyLink(response)}>
                        {response.surveyName}
                      </Link>
                    </Typography>
                    <Typography
                      sx={{
                        width: "100%",
                        textAlign: "center",
                      }}
                      variant="body2"
                    >
                      {response.organization.name}
                    </Typography>
                    <Box
                      sx={{
                        visibility:
                          (user.id === response.user.id ||
                            canReleaseAllPRs(user)) &&
                          response.department === "peer_review"
                            ? "visible"
                            : "hidden",
                      }}
                      display="flex"
                      alignItems="center"
                    >
                      <Box position="relative">
                        <Switch
                          id={`switch-${response.id}`}
                          checked={response?.isReleased || false}
                          onChange={(e) =>
                            handleChange(response?.id, e.target.checked)
                          }
                        />
                      </Box>
                      <Typography variant="caption">
                        {response?.isReleased || false
                          ? "Released"
                          : "Unreleased"}
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>

              {!isAAR ? (
                <Box sx={{ gridRow: "3", gridColumn: idx + 2 }}>
                  <Box
                    display="flex"
                    justifyContent="space-around"
                    alignItems="center"
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ color: getSurveyColor(theme, response?.score) }}
                    >
                      {response?.score?.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
              ) : null}
              {getResponses(response.answers, idx + 2)}
              <Box
                sx={{
                  position: "sticky",
                  left: 0,
                  zIndex: 501,
                  background: theme.palette.background.paper,
                }}
              />
            </React.Fragment>
          ))}
        </Box>
      )}
    </Box>
  );
}
