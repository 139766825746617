import React, { useState, useContext, useRef } from "react";
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  ToggleButtonGroup,
  ToggleButton,
  Popover,
  Chip,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/system";
import {
  Editor,
  Transforms,
  Element as SlateElement,
} from "slate";
import { useSlate } from "slate-react";

import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlineIcon from "@mui/icons-material/FormatUnderlined";
import FormatAlignLeftIcon from "@mui/icons-material/FormatAlignLeft";
import FormatAlignCenterIcon from "@mui/icons-material/FormatAlignCenter";
import FormatAlignRightIcon from "@mui/icons-material/FormatAlignRight";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import FormatListNumbered from "@mui/icons-material/FormatListNumbered";
import FormatListBulleted from "@mui/icons-material/FormatListBulleted";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";
import FormatColorTextIcon from "@mui/icons-material/FormatColorText";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";

import wrapLink from "../../Serializer/JSX/wrapLink";
import ImageButton from "./ImageButton";
import { EditorContext } from "../EditorContext";

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

type EditorToolbarProps = {
  uploadImage?: ({ file }: { file: File }) => Promise<string>;
  droppingImage?: boolean;
  showHotkeyHints?: boolean;
  modkey?: "Meta" | "Control";
};

export default function EditorToolbar({
  uploadImage,
  droppingImage,
  showHotkeyHints = false,
  modkey = "Control",
}: EditorToolbarProps) {
  const slate = useSlate();
  const context = useContext(EditorContext);

  const {
    selectedColor,
    setSelectedColor,
    selectedHighlight,
    setSelectedHighlight,
  } = context;

  // const colorAnchor = useRef(null);
  const highlightAnchor = useRef(null);

  const [linkValue, setLinkValue] = useState<string>("");
  const [linkOpen, setLinkOpen] = useState<boolean>(false);

  const [colorAnchorEl, setColorAnchorEl] = useState<HTMLElement>(null);
  const [highlightAnchorEl, setHighlightAnchorEl] = useState<HTMLElement>(null);

  const colorOpen = Boolean(colorAnchorEl);
  const highlightOpen = Boolean(highlightAnchorEl);

  const isBlockActive = (editor, format, blockType = "type") => {
    const { selection } = editor;
    if (!selection) return false;

    const [match] = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => (
          !Editor.isEditor(n)
                      && SlateElement.isElement(n)
                      && n[blockType] === format
        ),
      }),
    );

    return !!match;
  };

  const toggleBlock = (editor, format) => {
    const isActive = isBlockActive(
      editor,
      format,
      TEXT_ALIGN_TYPES.includes(format) ? "align" : "type",
    );
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => (
        !Editor.isEditor(n)
                    && SlateElement.isElement(n)
                    && LIST_TYPES.includes(n.type)
                    && !TEXT_ALIGN_TYPES.includes(format)
      ),
      split: true,
    });

    let newProperties: Partial<SlateElement>;
    if (TEXT_ALIGN_TYPES.includes(format)) {
      newProperties = {
        align: isActive ? undefined : format,
      };
    } else {
      newProperties = {
        type: isActive ? "paragraph" : isList ? "list-item" : format,
      };
    }
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const MARKS = [
    "italic",
    "bold",
    "underline",
  ];
  const toggleMark = (editor, formats) => {
    MARKS.forEach((format) => {
      Editor.removeMark(editor, format);
    });

    formats.forEach((format) => {
      Editor.addMark(editor, format, true);
    });
  };
  const COLORS = [
    "#000000",
    "#444444",
    "#666666",
    "#999999",
    "#cccccc",
    "#eeeeee",
    "#f3f3f3",
    "#ffffff",
    "#ff0000",
    "#ff9a00",
    "#ffff00",
    "#00ff00",
    "#00ffff",
    "#0001ff",
    "#9a00ff",
    "#ff00ff",
  ];
  const INVERSE_COLORS = {
    "#999999": "#000000",
    "#cccccc": "#000000",
    "#eeeeee": "#000000",
    "#f3f3f3": "#000000",
    "#ffffff": "#000000",
  };
  const toggleColorMark = (editor, format, value) => {
    Editor.addMark(editor, format, value);
  };

  const getMarks = () => {
    const marks = Editor.marks(slate);
    if (!marks) return [];
    const activeMarks = Object.keys(marks)
      .filter((mark) => marks[mark])
      .filter((mark) => MARKS.includes(mark));
    return activeMarks;
  };

  const getBlock = (editor, blockType) => {
    const { selection } = editor;
    if (!selection) return null;

    const nodes = Array.from(
      Editor.nodes(editor, {
        at: Editor.unhangRange(editor, selection),
        match: (n) => (
          !Editor.isEditor(n)
                      && SlateElement.isElement(n)
                      && n[blockType]
        ),
      }),
    );

    const [match] = nodes;

    if (!match) return null;
    return match[0][blockType];
  };

  const insertLink = (editor, url) => {
    if (editor.selection) {
      wrapLink(editor, url);
    }
  };

  const formatUrl = (url) => {
    if (
      !url.startsWith("http://")
                  && !url.startsWith("https://")
    ) {
      return `https://${url}`;
    }
    return url;
  };

  const onLinkSubmit = () => {
    const url = linkValue;
    if (!url) return;
    insertLink(slate, formatUrl(url));

    setLinkOpen(false);
    setLinkValue("");
  };

  const isLinkActive = (editor) => {
    const [link] = Editor.nodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
    return !!link;
  };

  const unwrapLink = (editor) => {
    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
    });
  };

  const getAlignmentBlock = (editor) => getBlock(editor, "align");
  const getQuoteListBlock = (editor) => getBlock(editor, "type");

  function handleFormats1(event: React.MouseEvent<HTMLElement>, newFormats: string[]) {
    toggleMark(slate, newFormats);
  }

  function handleBlock(event: React.MouseEvent<HTMLElement>, newFormat: string) {
    toggleBlock(slate, newFormat);
  }

  const HotkeyToggleButton = styled(ToggleButton)(() => ({
    position: "relative",
  }));

  const HotkeyChip = styled(Chip)(() => ({
    position: "absolute",
    top: "-20px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1,
    "& .MuiChip-label": {
      whiteSpace: "nowrap",
      textOverflow: "",
      overflow: "visible",
    },
  }));

  const modIcon = modkey === "Meta" ? "⌘" : "^";
  const hotkeyHintText = (char) => `${modIcon}${char}`;

  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="4px"
      paddingY={1}
      flexWrap="wrap"
    >
      <ToggleButtonGroup
        value={getMarks()}
        onChange={(e, f) => handleFormats1(e, f)}
        aria-label="text formatting"
      >
        <HotkeyToggleButton value="bold" aria-label="bold">
          <Tooltip title="Bold">
            <FormatBoldIcon />
          </Tooltip>
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("B")} size="small" />
          )}
        </HotkeyToggleButton>
        <HotkeyToggleButton value="italic" aria-label="italic">
          <Tooltip title="Italic">
            <FormatItalicIcon />
          </Tooltip>

          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("I")} size="small" />
          )}
        </HotkeyToggleButton>
        <HotkeyToggleButton value="underline" aria-label="underline">
          <Tooltip title="Underline">
            <FormatUnderlineIcon />
          </Tooltip>

          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("U")} size="small" />
          )}
        </HotkeyToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        aria-label="color formatting"
      >
        <ToggleButton
          value="color"
          aria-label="font color"
          onClick={(e) => {
            setColorAnchorEl(e.currentTarget);
            setHighlightAnchorEl(null);
          }}
          sx={{
            position: "relative",
          }}
        >
          <Tooltip title="Text Color">
            <FormatColorTextIcon
              sx={{
                color: selectedColor || "currentColor",
                backgroundColor: INVERSE_COLORS[selectedColor] || "transparent",
              }}
              onClick={(e) => {
                if (selectedColor) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                toggleColorMark(slate, "color", selectedColor);
                setColorAnchorEl(null);
              }}
            />
          </Tooltip>
          <ArrowDropDown
            sx={{
              marginRight: -0.5,
            }}
          />
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("⇧F")} size="small" />
          )}
        </ToggleButton>

        <Popover
          id="color-popover"
          open={colorOpen}
          anchorEl={colorAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={() => setColorAnchorEl(null)}
        >
          <Box
            display="grid"
            padding={1}
            gap={1}
            gridTemplateColumns="repeat(8, 1fr)"
          >
            {COLORS.map((color) => (
              <div
                key={color}
                onClick={() => {
                  toggleColorMark(slate, "color", color);
                  setSelectedColor(color);
                  setColorAnchorEl(null);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: color,
                  cursor: "pointer",
                }}
              />
            ))}
          </Box>
        </Popover>

        <ToggleButton
          value="backgroundColor"
          aria-label="highlight"
          onClick={(e) => {
            setHighlightAnchorEl(e.currentTarget);
            setColorAnchorEl(null);
          }}
          sx={{
            position: "relative",
          }}
        >
          <Tooltip title="Text Highlight">
            <BorderColorIcon
              sx={{
                color: selectedHighlight || "currentColor",
                backgroundColor: INVERSE_COLORS[selectedHighlight] || "transparent",
              }}
              onClick={(e) => {
                if (selectedHighlight) {
                  e.preventDefault();
                  e.stopPropagation();
                }
                toggleColorMark(slate, "backgroundColor", selectedHighlight);
                setHighlightAnchorEl(null);
              }}
            />
          </Tooltip>
          <ArrowDropDown
            sx={{
              marginRight: -0.5,
            }}
            onClick={() => {
              setHighlightAnchorEl(highlightAnchor.current);
              setColorAnchorEl(null);
            }}
          />
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("⇧H")} size="small" />
          )}
        </ToggleButton>

        <Popover
          id="highlight-popover"
          open={highlightOpen}
          anchorEl={highlightAnchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          onClose={() => setHighlightAnchorEl(null)}
        >
          <Box
            display="grid"
            padding={1}
            gap={1}
            gridTemplateColumns="repeat(8, 1fr)"
          >
            {COLORS.map((color) => (
              <div
                key={color}
                onClick={() => {
                  toggleColorMark(slate, "backgroundColor", color);
                  setSelectedHighlight(color);
                  setHighlightAnchorEl(null);
                }}
                style={{
                  width: "30px",
                  height: "30px",
                  backgroundColor: color,
                  cursor: "pointer",
                }}
              />
            ))}
          </Box>
        </Popover>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        value={getAlignmentBlock(slate)}
        exclusive
        onChange={(e, f) => handleBlock(e, f)}
        aria-label="text alignment"
      >
        <ToggleButton value="left" aria-label="left">
          <Tooltip title="Align Left">
            <FormatAlignLeftIcon />
          </Tooltip>
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("⇧E")} size="small" />
          )}
        </ToggleButton>
        <ToggleButton value="center" aria-label="center">
          <Tooltip title="Align Center">
            <FormatAlignCenterIcon />
          </Tooltip>
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("⇧C")} size="small" />
          )}
        </ToggleButton>
        <ToggleButton value="right" aria-label="right">
          <Tooltip title="Align Right">
            <FormatAlignRightIcon />
          </Tooltip>
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("⇧R")} size="small" />
          )}
        </ToggleButton>
        <ToggleButton value="justify" aria-label="justify">
          <Tooltip title="Align Justified">
            <FormatAlignJustifyIcon />
          </Tooltip>
          {showHotkeyHints && (
            <HotkeyChip label={hotkeyHintText("⇧J")} size="small" />
          )}
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        value={getQuoteListBlock(slate)}
        exclusive
        onChange={(e, f) => handleBlock(e, f)}
        aria-label="quote or list formatting"
      >
        <ToggleButton value="block-quote" aria-label="block-quote">
          <Tooltip title="Quote">
            <FormatQuoteIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="numbered-list" aria-label="numbered-list">
          <Tooltip title="Numbered List">
            <FormatListNumbered />
          </Tooltip>
        </ToggleButton>
        <ToggleButton value="bulleted-list" aria-label="bulleted-list">
          <Tooltip title="Bulleted List">
            <FormatListBulleted />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>

      <ToggleButtonGroup
        aria-label="link formatting"
      >
        <ToggleButton
          value="link"
          aria-label="link"
          onClick={(e) => {
            e.preventDefault();
            setLinkOpen(true);
          }}
        >
          <Tooltip title="Add Link">
            <LinkIcon />
          </Tooltip>
        </ToggleButton>
        <ToggleButton
          value="unlink"
          aria-label="unlink"
          onClick={(e) => {
            e.preventDefault();
            if (isLinkActive(slate)) {
              unwrapLink(slate);
            }
          }}
        >
          <Tooltip title="Remove Link">
            <LinkOffIcon />
          </Tooltip>
        </ToggleButton>
      </ToggleButtonGroup>

      <Dialog
        open={linkOpen}
        onClose={() => {
          setLinkOpen(false);
          setLinkValue("");
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Enter the URL of the link:</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              onLinkSubmit();
            }}
          >
            <FormControl
              fullWidth
            >
              <TextField
                autoFocus
                margin="dense"
                id="name"
                label="URL"
                type="text"
                fullWidth
                onChange={(e) => setLinkValue(e.target.value)}
              />
            </FormControl>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setLinkOpen(false);
              setLinkValue("");
            }}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={() => onLinkSubmit()}
            color="primary"
            variant="contained"
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>

      {uploadImage && (
      <ImageButton
        uploadImage={uploadImage}
        droppingImage={droppingImage}
      />
      )}
    </Box>
  );
}
