import React from "react";
import ThumbDown from "@mui/icons-material/ThumbDown";
import IndeterminateCheckBox from "@mui/icons-material/IndeterminateCheckBox";
import ThumbUp from "@mui/icons-material/ThumbUp";
import Chip from "@mui/material/Chip";
import Box from "@mui/material/Box";

export default function IHEChip({ color, label, ...props }) {
  const fontSize = "1rem";
  const colorIconMap = {
    red: <ThumbDown sx={{ color: (theme) => theme.palette[color].main, fontSize }} />,
    yellow: (
      <IndeterminateCheckBox
        sx={{
          color: (theme) => theme.palette[color].main,
          fontSize,
        }}
      />
    ),
    green: <ThumbUp sx={{ color: (theme) => theme.palette[color].main, fontSize }} />,
    gray: null,
  };
  return (
    <Chip
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      label={(
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          alignItems="center"
        >
          {colorIconMap[color]}
          {label}
        </Box>
      )}
      sx={{
        border: (theme) => `1px solid ${theme.palette[color]?.main}`,
        background: (theme) => theme.palette.background.paper,
        color,
      }}
    />
  );
}
