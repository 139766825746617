import React from "react";
import { useTheme, Typography } from "@mui/material";
import UserAvatar from "@/ui/atoms/UserAvatar";
import ChatBubble from "./ChatBubble";

export default function UserChatBubble({ user, content }) {
  const theme = useTheme();
  const color = theme.palette.background.paper;
  const fontColor = theme.palette.getContrastText(color);

  return (
    <ChatBubble
      sx={{
        color: fontColor,
        backgroundColor: color,
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <UserAvatar
        user={user}
        style={{
          height: 30,
          width: 30,
        }}
      />
      <Typography
        variant="body1"
        sx={{
          lineHeight: "30px",
          color: fontColor,
        }}
      >
        {content}
      </Typography>
    </ChatBubble>
  );
}
