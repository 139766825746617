import React from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { UploadMetricsFileResult, PeriodType } from "@/api/Metrics";

type UploadDiffProps = {
  diff: UploadMetricsFileResult;
  periodType: PeriodType;
}

export default function UploadDiff({
  diff,
  periodType = "MONTHLY",
}: UploadDiffProps) {
  const dataByPeriod = diff.data.filter((d) => d.periodType === periodType);
  const metrics = Object.keys(diff.new);
  const dates = [...new Set(dataByPeriod.map((d) => d.period))];

  if (dataByPeriod.length === 0) {
    return null;
  }

  if (periodType === "QUARTERLY") {
    dates.sort((a, b) => {
      const [, quarterA, yearA] = a.match(/Q(\d) F?Y?(\d{4})/);
      const [, quarterB, yearB] = b.match(/Q(\d) F?Y?(\d{4})/);

      return yearB - yearA || quarterB - quarterA;
    });
  } else {
    dates.sort((a, b) => (dayjs(a).isBefore(dayjs(b)) ? 1 : -1));
  }

  return (
    <Box
      m={1}
    >
      <Typography variant="h3">
        {`${periodType} Sheet`}
      </Typography>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            {dates.map((date) => (
              <TableCell key={`header-${date}`}>{date}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {metrics.map((metric) => (
            <TableRow key={metric}>
              <TableCell>{metric}</TableCell>
              {dates.map((date) => {
                const newValue = diff.new[metric]?.[date];
                const diffValue = diff.diff[metric]?.[date];
                if (newValue === undefined && !diffValue) {
                  return (
                    <TableCell key={`${metric}-${date}`}>
                      <Box
                        sx={{
                          // blur the cell
                          filter: "blur(5px)",
                        }}
                      >
                        1000000
                      </Box>
                    </TableCell>
                  );
                }
                if (diffValue) {
                  return (
                    <TableCell
                      key={`${metric}-${date}`}
                      sx={{
                        backgroundColor: "rgba(255, 129, 130, 0.4)",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          textDecoration: "line-through",
                        }}
                      >
                        { diffValue.old }
                      </Box>
                      <br />
                      { diffValue.new }
                    </TableCell>
                  );
                }
                return (
                  <TableCell
                    key={`${metric}-${date}`}
                    sx={{
                      backgroundColor: "rgb(171, 242, 188)",
                    }}
                  >
                    { newValue }
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
