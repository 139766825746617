import React from "react";
import { Box, useTheme, Typography } from "@mui/material";
import Flake from "../Icons/Flake";

interface Props {
  title: string;
  collapsible?: boolean;
  children: React.ReactElement<Props> | React.ReactElement<Props>[];
}

export default function ValorFolio({ title, children, collapsible }: Props) {
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          background: theme.palette.background.blue,
          borderTopLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius:
            collapsible && !open && theme.shape.borderRadius,
          borderBottomRightRadius:
            collapsible && !open && theme.shape.borderRadius,
          padding: theme.spacing(1, 1, collapsible && !open ? 1 : 0, 1),
          width: "fit-content",
          cursor: collapsible ? "pointer" : "default",
        }}
        onClick={() => setOpen((prev) => !prev)}
      >
        <Flake />
        <Typography sx={{ textTransform: "uppercase" }} variant="boldSubtitle2">
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          height: collapsible && !open ? "0px" : "100%",
          background: theme.palette.background.blue,
          borderBottomRightRadius: theme.shape.borderRadius,
          borderBottomLeftRadius: theme.shape.borderRadius,
          borderTopRightRadius: theme.shape.borderRadius,
          padding: collapsible && !open ? "0px" : theme.spacing(2, 1),
          minWidth: "200px",
          minHeight: !collapsible
            ? "140px"
            : collapsible && !open
              ? "0"
              : "64px",
          width: "fit-content",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
