import React, { useMemo } from "react";
import { Box } from "@mui/material";
import { useQuery } from "react-query";
import { getPortcoJobsStats } from "@/api/VPO";
import { useTheme } from "@mui/material/styles";
import { TextCell } from "@/components/Table/Primitives";
import DataTable from "@/components/LocalTable";
import Progress from "@/ui/atoms/Progress";
import MeetingPage from "./MeetingPage";

export function HumanCapitalSlide() {
  const { data: portcoJobs, isLoading } = useQuery(
    ["PortcoJobsStats", "open"],
    async () => getPortcoJobsStats("open"),
  );
  const theme = useTheme();

  const portcoJobsStatsColumns = useMemo(
    () => [
      {
        header: "Company",
        accessorKey: "company",
        id: "company",
        enableFiltering: false,
        meta: {
          sortType: "alpha",
          optional: true,
        },
        cell: (prop) => <TextCell value={prop.getValue()} />,
      },
      {
        header: "Job Title",
        accessorKey: "title",
        id: "title",
        meta: {
          sortType: "alpha",
          optional: true,
        },
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Department",
        accessorKey: "department",
        id: "department",
        meta: {
          sortType: "alpha",
          optional: true,
        },
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Stage of Recruitment",
        accessorKey: "recruitmentStage",
        id: "recruitmentStage",
        meta: {
          sortType: "alpha",
          optional: true,
        },
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
        size: 210,
      },
      {
        header: "Level of Support",
        accessorKey: "levelOfSupport",
        id: "supportLevel",
        meta: {
          sortType: "alpha",
          optional: true,
        },
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
        size: 175,
      },
      {
        header: "RYG",
        accessorKey: "color",
        id: "color",
        size: "64px",
        cell: (prop) => (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ width: "100%", height: "100%" }}
          >
            <Box
              sx={{
                width: "64px",
                height: "32px",
                borderRadius: theme.spacing(1),
                backgroundColor: prop.getValue()
                  ? theme.palette[prop.getValue()].main
                  : theme.palette.grey[400],
              }}
            />
          </Box>
        ),
        enableFiltering: false,
      },
      {
        header: "Days Open",
        accessorKey: "daysOpen",
        id: "daysOpen",
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Total",
        accessorKey: "totalCandidates",
        id: "totalCandidates",
        size: 100,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Active",
        accessorKey: "activeCandidates",
        id: "activeCandidates",
        size: 100,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Presented",
        accessorKey: "presentedCandidates",
        id: "presentedCandidates",
        size: 130,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Offer",
        accessorKey: "receivedOffer",
        id: "receivedOffer",
        size: 100,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Hired",
        accessorKey: "hiredCandidates",
        id: "hiredCandidates",
        size: 100,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
      {
        header: "Avg Conversion Rate",
        id: "Avg Conversion Rate",
        size: 175,
        accessor: (row) => `${(
          (row.hiredCandidates
              / (row.presentedCandidates === 0 ? 1 : row.presentedCandidates))
            * 100
        ).toFixed(2)}%`,
        cell: (prop) => <TextCell value={prop.getValue()} />,
        enableFiltering: false,
      },
    ],
    [],
  );
  if (isLoading) {
    return <Progress />;
  }
  return (
    <MeetingPage title="Human Capital Update">
      <Box sx={{ margin: 1 }}>
        <DataTable data={portcoJobs} columns={portcoJobsStatsColumns} />
      </Box>
    </MeetingPage>
  );
}
export default HumanCapitalSlide;
