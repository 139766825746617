export const JOB_CATEGORIES = [
  "accounting",
  "administrative",
  "art_and_design",
  "board_member",
  "business_development",
  "c_suite",
  "customer_service",
  "data_science",
  "education",
  "finance",
  "human_resources",
  "information_technology",
  "lean_process",
  "legal",
  "marketing",
  "media_and_communications",
  "other_engineering",
  "other_executive",
  "other_operations",
  "product_management",
  "recruiting",
  "sales",
  "software_engineering",
  "supply_chain",
];

export default JOB_CATEGORIES;
