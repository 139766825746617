import React from "react";
import { StickyTableHead, StickyTable } from "@/components/Table/StickyTable";
import {
  getCoreRowModel,
  flexRender,
  useReactTable,
  getFilteredRowModel,
  getFacetedRowModel,
  getSortedRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table";
import HeaderCell from "@/components/Table/Header/Cell";
import {
  Box, TableBody, TableRow, TableCell,
} from "@mui/material";

export function DataTable({ data, columns }) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });

  return (
    <Box
      sx={{
        overflow: "auto",
      }}
    >
      <StickyTable>
        <StickyTableHead>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const isParent = !!header.subHeaders?.length;
                return (
                  <HeaderCell
                    key={header.id}
                    header={header}
                    column={header.column}
                    table={table}
                    isParent={isParent}
                  />
                );
              })}
            </TableRow>
          ))}
        </StickyTableHead>
        <TableBody>
          {table.getRowModel().rows.map((row, index) => (
            <TableRow key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <TableCell
                  key={cell.id}
                  sx={(theme) => ({
                    background: cell.column.getIsFiltered()
                      ? theme.palette.background.activeTableColumn
                      : index % 2
                        ? theme.palette.background.oddTableRow
                        : theme.palette.background.paper,
                    borderRight: `1px solid ${theme.palette.divider}`,
                    boxSizing: "border-box",
                    minWidth: cell.column.getSize(),
                    width: cell.column.getSize(),
                    maxWidth: cell.column.getSize(),
                    padding: 1,
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </StickyTable>
    </Box>
  );
}
export default DataTable;
