import React from "react";
import { Box, Typography, Card } from "@mui/material";

export default function MeetingPage({ children, title }: {
  children: React.ReactNode;
  title: string | React.ReactNode;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Typography variant="h2">{title}</Typography>

      <Card elevation={0}>
        {children}
      </Card>
    </Box>
  );
}
