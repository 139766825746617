import React from "react";
import { useQuery } from "react-query";
import {
  getProcess,
  getTask,
  getForm,
  updateTaskFieldValues,
  createFormSubmission,
} from "@/api/Process";
import { updateOrgFieldValues } from "@/api/Organization";
import Form from "./Form";

export default function FormContainer({
  processId,
  formId,
  oppId,
  onCancel,
  onSubmit,
}: {
  processId: string;
  formId: string;
  oppId: string;
  onCancel: () => void;
  onSubmit: (values: any) => void;
}) {
  // busy
  const [busy, setBusy] = React.useState(false);

  // get form
  const { data: formData, isLoading: formLoading } = useQuery(
    ["form", formId],
    () => getForm(processId, formId),
    { enabled: !!formId },
  );

  // get opportunity
  const { data: oppData, isLoading: oppLoading } = useQuery(
    ["opportunity", oppId],
    () => getTask(processId, oppId),
    { enabled: oppId !== null || oppId !== undefined },
  );

  // get process for primary company id
  const { data: processData, isLoading: processLoading } = useQuery(
    ["process", processId],
    () => getProcess(processId),
    { enabled: !!processId },
  );

  const isLoading = formLoading || oppLoading || processLoading;

  return (
    <Form
      loading={isLoading}
      disabled={busy}
      structure={formData}
      value={oppData}
      onCancel={() => onCancel()}
      onSubmit={async (values) => {
        if (Object.keys(values).length === 0) {
          onCancel();
        }

        setBusy(true);

        const valueEntries = Object.entries(values).map(([key, value]) => ({
          fieldId: key,
          value,
        }));

        try {
          const sectionFields = formData.sections
            .map((section) => section.fields)
            .flat();

          const fields = sectionFields.reduce(
            (acc, field) => ({
              ...acc,
              [field.id]: field,
            }),
            {},
          );

          const orgFieldValues = valueEntries.filter((entry) => {
            const field = fields[entry.fieldId];
            return field.isOrganizationField;
          });
          const taskFieldValues = valueEntries.filter((entry) => {
            const field = fields[entry.fieldId];
            return !field.isOrganizationField;
          });

          const transformValue = (field, preValue) => {
            let value = preValue;
            if (field.type === "select_multi") {
              value = value.choices.map((v) => ({ choiceId: v }));
            }
            if (field.type === "user_multi") {
              value = value.users.map((v) => ({ userId: v.id }));
            }
            if (field.type === "person_multi") {
              value = value.persons.map((v) => ({ personId: v.id }));
            }
            if (field.type === "checkbox") {
              value = value ? [{ value: "Yes" }] : [{ value: "No" }];
            }
            value = Array.isArray(value) ? value : [value];
            return value;
          };

          const companyField = processData.fields.find(
            (f) => f.isPrimary && f.type === "company",
          );
          const companyValue = oppData.fieldValues[companyField.id]?.company;

          const orgRequestBody = orgFieldValues
            .map((entry) => {
              const { fieldId, value } = entry;
              const field = fields[fieldId];
              return {
                fieldId: field.settingId,
                value: transformValue(field, value),
              };
            })
            .reduce(
              (acc, entry) => ({
                ...acc,
                [entry.fieldId]: entry.value,
              }),
              {},
            );

          const requestBody = taskFieldValues
            .map((entry) => ({
              fieldId: entry.fieldId,
              value: transformValue(fields[entry.fieldId], entry.value),
            }))
            .reduce(
              (acc, entry) => ({
                ...acc,
                [entry.fieldId]: entry.value,
              }),
              {},
            );

          const correlationId = crypto.randomUUID();
          await Promise.all([
            // add form submission
            createFormSubmission(processId, formId, {
              correlationId,
              taskInternalId: oppData.internalId,
            }),
            updateOrgFieldValues(companyValue.valorId, {
              correlationId,
              values: orgRequestBody,
            }),
            updateTaskFieldValues(processId, oppId, {
              correlationId,
              values: requestBody,
            }),
          ]);
        } catch (e) {
          console.error(e);
          throw e;
        }
        onSubmit(values);
        setBusy(false);
      }}
    />
  );
}
