import React from "react";
import { Box, TextField } from "@mui/material";
import { useQuery } from "react-query";
import Helmet from "react-helmet";
import {
  getRoleInfo,
  getRestrictions,
  removeRestriction,
  addRestrictions,
} from "@/api/Compliance";
import { saytForOrg } from "@/api/Search";
import { useQueryParam } from "use-query-params";
import DragAndDropCompanies from "@/ui/molecules/DragAndDropCompanies";
import UsersTable from "./UsersTable";

function Role() {
  const [id] = useQueryParam("id");

  const { data: role = {} } = useQuery(["Role", id], async () => getRoleInfo(id));

  return (
    <div>
      <Helmet>
        <title>Compliance - Role</title>
      </Helmet>
      <Box
        boxShadow={1}
        marginTop={2}
        marginBottom={2}
        p={1}
        bgcolor="background.paper"
        borderRadius="10px"
      >
        <TextField
          id="role-outlined-textfield"
          margin="dense"
          label="Role"
          variant="outlined"
          value={role.name || " "}
        />
        <br />
        <TextField
          id="description-outlined-textfield"
          margin="dense"
          label="Description"
          variant="outlined"
          value={role.description || " "}
        />
      </Box>
      <DragAndDropCompanies
        id={id}
        column="role_id"
        dontShowAccess
        search={saytForOrg}
        getRestrictions={getRestrictions}
        removeRestriction={removeRestriction}
        addRestrictions={addRestrictions}
      />
      <Box marginTop={2}>
        <UsersTable
          title="Users"
          defaultFilters={[
            {
              id: "roleId",
              value: id,
            },
          ]}
        />
      </Box>
    </div>
  );
}

export default Role;
