import React, { useEffect, useRef, useState } from "react";
import dayjs from "dayjs";
import { useQuery, useQueryClient } from "react-query";
import LockIcon from "@mui/icons-material/Lock";
import Close from "@mui/icons-material/Close";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Link,
  useTheme,
  Card,
  Skeleton,
  Tooltip,
  AvatarGroup,
} from "@mui/material";
import MultiSelect from "@/ui/atoms/MultiSelect";
import ErrorMessage from "@/ui/atoms/ErrorMessage";
import { useNavigate } from "react-router-dom";
import {
  getCompanyResponses,
  getCompanyResponsesExcel,
  getAvailableFrameworkSurveys,
  getAllSections,
  patchResponse,
  getDrafts,
} from "@/api/FrameworkSurvey";
import { Organization } from "@/api/Organization";
import { useAuth } from "@/hooks/useAuth";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import FrameworkGrid from "@/ui/templates/FrameworkGrid";
import Section from "@/ui/atoms/Section";
import UserAvatar from "@/ui/atoms/UserAvatar";
import { usePopover } from "@/hooks/useGlobalPopover";
import { CloseRounded } from "@mui/icons-material";
import {
  canEditDraftCollabSurveys,
  canEditAllSurveys,
} from "@/constants/Roles";
import _ from "lodash";

interface Props {
  company: Organization;
}

function DraftCard({ draft, valorId }) {
  const navigate = useNavigate();
  const ref = useRef(null);
  const { user } = useAuth();
  const { openPopover, closePopover } = usePopover();
  const queryClient = useQueryClient();
  const canEditAll = canEditAllSurveys(user);
  const canEditDraftCollab = canEditDraftCollabSurveys(user, draft);

  const shouldDisableDelete = draft.authorUserId !== user.id;

  const canEditSurvey = canEditAll
    || (canEditDraftCollab && draft.allowCollaborators)
    || draft.authorUserId === user.id;

  const DeleteDraft = (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      flex={1}
    >
      <Typography variant="h3">Delete Draft ? </Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-end"
        gap={2}
      >
        <Box display="flex" gap={2}>
          <Button
            onClick={() => {
              patchResponse(draft.id, { hidden: true, status: "DELETED", isReleased: false });
              queryClient.setQueryData(["DraftResponses", valorId], (old) => old.filter((d) => d.id !== draft.id));
              closePopover();
            }}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
          <Button onClick={() => closePopover()} variant="outlined">
            Cancel
          </Button>
        </Box>
        <Typography>This can be undone by the labs team.</Typography>
      </Box>
    </Box>
  );

  return (
    <Card
      sx={{
        padding: 1,
        height: "fit-content",
        width: "fit-content",
      }}
      ref={ref}
    >
      <Box
        sx={{
          height: "144px",
          width: "320px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="boldSubtitle2">
          {InvestmentProductMappings[draft.department]}
          {" "}
          {draft.name}
        </Typography>

        <AvatarGroup>
          {draft.collaborators?.map((collaborator) => (
            <UserAvatar key={collaborator.id} user={collaborator} />
          ))}
        </AvatarGroup>
        <Typography variant="boldSubtitle2">Last saved: </Typography>
        <Typography variant="subtitle2">
          {dayjs.utc(draft.lastModified).local().format("MM/DD/YYYY h:mm a")}
        </Typography>
        <Box display="flex" gap={2}>
          <Button
            variant="outlined"
            sx={{ width: "100%" }}
            disabled={!canEditSurvey}
            onClick={() => navigate(
              `/org/${valorId}/surveys/${draft.surveyId}?responseId=${draft.id}`,
            )}
          >
            Edit
          </Button>
          {shouldDisableDelete ? (
            <Tooltip
              title={`Only the author (${draft.author.firstName} ${draft.author.lastName}) can delete this draft `}
              arrow
            >
              <Box>
                <Button
                  variant="outlined"
                  disabled={canEditAll ? false : shouldDisableDelete}
                  color="error"
                  onClick={() => openPopover(ref, DeleteDraft)}
                >
                  <CloseRounded />
                </Button>
              </Box>
            </Tooltip>
          ) : (
            <Button
              variant="outlined"
              color="error"
              onClick={() => openPopover(ref, DeleteDraft)}
            >
              <CloseRounded />
            </Button>
          )}
        </Box>
      </Box>
    </Card>
  );
}

function SurveyResponse({ company }: Props) {
  const theme = useTheme();
  const { valorId } = company;
  const queryClient = useQueryClient();
  const [authorized, setAuthorized] = useState(true);
  const [authorizedIfTaken, setAuthorizedIfTaken] = useState(false);
  const [selectedSurveys, setSelectedSurveys] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [uniqueSurveys, setUniqueSurveys] = useState([]);
  const [isLoadingData, setLoadingData] = useState(true);

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [uniqueProducts, setUniqueProducts] = useState([]);

  const {
    user: { id: userId },
  } = useAuth();

  const TWENTY_FOUR_HOURS_AGO = dayjs().subtract(24, "hour");

  function authorizedIfTakenPerm(perm) {
    return (
      perm.permission === "canViewSurveyResultsIfTaken"
      && perm.enabled
      && !perm.access
    );
  }

  const { data: sections = [], isLoading: isLoadingSections } = useQuery(
    ["Sections"],
    () => getAllSections({ isAAR: false }),
    {
      // refetchOnWindowFocus: false,
      // refetchOnReconnect: false,
      // refetchInterval: false,
    },
  );

  const { data: drafts = [], isLoading: isLoadingDrafts } = useQuery(
    ["DraftResponses", valorId],
    async () => getDrafts(valorId),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  );

  const { data: availableSurveys = [], isLoading: isLoadingFrameworks } = useQuery(
    ["FrameworkUniqueSurveys", valorId],
    async () => {
      const excludeVertical = "after_action_review";
      const response = await getAvailableFrameworkSurveys({ valorId, excludeVertical });
      if (
        response?.status === 404
        || response?.status === 422
        || response?.status === 403
      ) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      retry: 1,
    },
  );

  const getUniqueSurveys = (responses) => {
    return _.chain(responses)
      .filter(Boolean)
      .map('name')
      .uniq()
      .value();
  };
  
  const getUniqueUsers = (responses) => {
    return _.chain(responses)
      .filter(Boolean)
      .map((response) => ({
        id: response.user.id,
        name: response.user.name,
      }))
      .uniqBy('id')
      .value();
  };
  
  const getUniqueProducts = (responses) => {
    return _.chain(responses)
      .filter(Boolean)
      .map('department')
      .uniq()
      .value();
  };

  const {
    data: responses = { responses: [], hiddenResponses: [] },
    isLoading: isLoadingResponses,
    status,
  } = useQuery(
    ["FrameworkSurveyResponses", valorId],
    async () => {
      const response = await getCompanyResponses({
        valorId,
      });
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      if (response?.status === 403) {
        setAuthorized(false);
        if (response.data?.detail.some(authorizedIfTakenPerm)) {
          setAuthorizedIfTaken(true);
        }
      }
      return response;
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  );

  useEffect(() => {
    if (responses.responses?.length) {
      setLoadingData(true);
      const users = getUniqueUsers(responses.responses);
      setSelectedUsers(users);
      setUniqueUsers(users);

      const products = getUniqueProducts(responses.responses);
      setSelectedProducts(products);
      setUniqueProducts(products);
      setLoadingData(false);
    }
  }, [
    responses.responses,
    isLoadingResponses,
    setSelectedProducts,
    setSelectedUsers,
    setUniqueUsers,
    setUniqueProducts,
    setLoadingData,
  ]);

  useEffect(() => {
    if (availableSurveys.length === 0) {
      return;
    }
    setLoadingData(true);
    const surveyNames = getUniqueSurveys(availableSurveys);
    setSelectedSurveys(surveyNames);
    setUniqueSurveys(surveyNames);
    setLoadingData(false);
  }, [availableSurveys, setSelectedSurveys, setUniqueSurveys, setLoadingData]);

  const deleteableResponses = responses?.responses
    ?.filter(
      (r) => r.user.id === userId
        && dayjs.utc(r.completedAt).local() > TWENTY_FOUR_HOURS_AGO,
    )
    .map((x) => ({
      surveyName: x.surveyName,
      completedAt: dayjs.utc(x.completedAt).local().format("h:mm A"),
      id: x.id,
      department: InvestmentProductMappings[x.department],
    }));

  if (
    isLoadingFrameworks
    || isLoadingSections
    || isLoadingResponses
    || isLoadingDrafts
    || isLoadingData
  ) {
    return (
      <Section>
        <Section.Title>Survey Drafts</Section.Title>
        <Section.Content>
          <Box style={{ marginLeft: "8px", marginRight: "8px" }}>
            <Box
              marginBottom={1}
              display="flex"
              flexWrap="wrap"
              justifyContent="flex-start"
              sx={{ gap: "8px" }}
            >
              <Skeleton variant="rounded" height="144px" width="320px" />
              <Skeleton variant="rounded" height="144px" width="320px" />
            </Box>
          </Box>
        </Section.Content>

        <Section.Title>Survey Responses</Section.Title>
        <Section.Content>
          <Box style={{ marginLeft: "8px", marginRight: "8px" }}>
            <Box
              marginBottom={1}
              display="flex"
              justifyContent="space-between"
              sx={{ gap: "8px" }}
            >
              <Box display="flex" sx={{ gap: "8px" }}>
                <Skeleton variant="rounded" height="40px" width="80px" />
                <Skeleton variant="rounded" height="40px" width="80px" />
                <Skeleton variant="rounded" height="40px" width="80px" />
              </Box>
              <Box>
                <Skeleton variant="rounded" height="36px" width="144px" />
              </Box>
            </Box>
            <Box display="flex" flexDirection="column" sx={{ gap: "8px" }}>
              <Skeleton variant="rounded" height="100px" width="100%" />
              <Skeleton variant="rounded" height="100px" width="100%" />
              <Skeleton variant="rounded" height="100px" width="100%" />
              <Skeleton variant="rounded" height="100px" width="100%" />
            </Box>
          </Box>
        </Section.Content>
      </Section>
    );
  }

  if (!authorized && !authorizedIfTaken) {
    return (
      <ErrorMessage
        Icon={<LockIcon />}
        title="Unauthorized"
        message={(
          <>
            You don’t have access to view Survey Results. If you think this is
            an error contact
            {" "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              onClick={(e) => {
                window.location.href = "mailto:labs@valorep.com?subject=Survey Permissions";
                e.preventDefault();
              }}
            >
              labs@valorep.com
            </Link>
          </>
        )}
      />
    );
  }

  const hideSurveyResponse = async (responseId) => {
    await patchResponse(responseId, { hidden: true, status: "DELETED", isReleased: false });
    return queryClient.resetQueries(["FrameworkSurveyResponses", id], {
      exact: true,
    });
  };

  return (
    <Section>
      <Section.Title>Survey Drafts</Section.Title>
      <Section.Content>
        <Box
          sx={{
            display: "inline-flex",
            flexWrap: "wrap",
            gap: 1,
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          {drafts?.length ? (
            drafts.map((draft) => (
              <DraftCard key={draft.id} draft={draft} valorId={valorId} />
            ))
          ) : (
            <Typography variant="body2">No Drafts Available</Typography>
          )}
        </Box>
      </Section.Content>
      <Section.Title>Survey Responses</Section.Title>
      <Section.Content>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "8px",
            marginRight: "8px",
          }}
        >
          <Box display="flex" flexDirection="column">
            {Boolean(deleteableResponses?.length) && (
              <Box
                sx={{
                  margin: theme.spacing(1, 0),
                  padding: theme.spacing(2),
                  width: "100%",
                  backgroundColor: theme.palette.background.paper,
                  boxShadow: theme.shadows[5],
                }}
              >
                <Typography variant="h3">
                  Your Recently Completed Surveys
                </Typography>
                <Typography variant="subtitle2">
                  You can delete these surveys if you&apos;d like to retake
                  them. This action can be undone by the Labs team.
                </Typography>
                {deleteableResponses.map((r) => (
                  <Box
                    key={r.id}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: theme.spacing(1),
                      width: "fit-content",
                      border: `1px solid ${theme.palette.primary.main}`,
                    }}
                  >
                    <Box display="flex" flexDirection="column">
                      <Typography variant="body2">{r.surveyName}</Typography>
                      <Typography variant="body2">{r.department}</Typography>
                      <Typography variant="body2">{r.vertical}</Typography>
                      <Typography variant="body2">{r.completedAt}</Typography>
                    </Box>
                    <IconButton
                      sx={{
                        color: theme.palette.red.main,
                        width: theme.spacing(4),
                        height: theme.spacing(4),
                      }}
                      onClick={() => hideSurveyResponse(r.id)}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                ))}
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: (t) => t.spacing(1),
            }}
          >
            {!isLoadingResponses && !!responses?.responses?.length && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                    },
                    flexWrap: {
                      xs: "wrap",
                      sm: "nowrap",
                    },
                  }}
                >
                  <MultiSelect
                    id="users-select"
                    allValues={uniqueUsers}
                    getKey={(user) => user.id}
                    getLabel={(user) => user.name}
                    value={selectedUsers}
                    title="Users"
                    style={{ marginRight: "8px" }}
                    onChange={setSelectedUsers}
                  />
                  <MultiSelect
                    id="surveys-select"
                    allValues={uniqueSurveys}
                    value={selectedSurveys}
                    title="Surveys"
                    style={{ marginRight: "8px" }}
                    onChange={setSelectedSurveys}
                  />
                  <MultiSelect
                    id="products-select"
                    allValues={uniqueProducts}
                    value={selectedProducts}
                    title="Products"
                    onChange={setSelectedProducts}
                  />
                </Box>
                <Box>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => getCompanyResponsesExcel({ valorId })}
                    disabled={responses?.responses?.length === 0}
                  >
                    Download to Excel
                  </Button>
                </Box>
              </>
            )}
          </Box>
          <Box>
            {status === "success" && !isLoadingResponses && (
              <FrameworkGrid
                sections={sections}
                status={status}
                // takeSurvey={!authorized && authorizedIfTaken}
                // NOTE: This  VVV  algorithm's time complexity is O(n^2) but it's not a big deal
                // since we only have a few selectedSurveys and selectedUsers
                // If performance becomes an issue tranform selectedSurveys
                // and selectedUsers to a set and use .has()
                responses={responses?.responses?.filter(
                  (r) => selectedProducts.includes(r.department)
                    && selectedSurveys.includes(r.surveyName)
                    && selectedUsers.some((u) => u.id === r.user.id),
                )}
              />
            )}
          </Box>
        </Box>
      </Section.Content>
    </Section>
  );
}

export default SurveyResponse;
