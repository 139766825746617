import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

type DateInputProps = {
  value: number | null;
  onChange: (value: number | null) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
};

export default function DateInput({
  value,
  onChange,
  label,
  required,
  disabled,
  error,
}: DateInputProps) {
  return (
    <DatePicker
      disabled={disabled}
      sx={{
        width: "100%",
      }}
      label={label}
      value={value ? dayjs(Number(value)).local() : null}
      onChange={(date) => {
        onChange(date ? dayjs(date).valueOf() : null);
      }}
      slotProps={{
        field: {
          clearable: true,
          // onClear: () => {
          //   onChange(null);
          // },
          onKeyDown: (e) => {
            e.preventDefault();
          },
          required,
          error,
        },
      }}
      closeOnSelect
      orientation="portrait"
    />
  );
}
