/* eslint-disable react/prop-types */
import React from "react";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import {
  Box, Typography, Tooltip,
} from "@mui/material";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import SwipeX from "@/ui/atoms/SwipeX";
import CloseIcon from "@mui/icons-material/Close";

dayjs.extend(advancedFormat);

function InteractionLineItem(props) {
  const {
    valorId,
    name,
    domain,
    logoUrl,
    interactionDate,
    onClick,
    onDismiss,
  } = props;
  const enableTooltip = name.length > 20;

  return (
    <SwipeX onLeft={onDismiss} LeftIcon={CloseIcon} leftColor="blue">
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: (theme) => theme.spacing(1, 1.5),
          backgroundColor: (theme) => theme.palette.background.paper,
          "&:hover": {
            backgroundColor: (theme) => theme.palette.action.hover,
          },
        }}
        key={valorId}
      >
        <Tooltip
          title={name}
          placement="top"
          disableHoverListener={!enableTooltip}
          disableFocusListener={!enableTooltip}
          disableTouchListener={!enableTooltip}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              overflowX: "hidden",
              cursor: "pointer",
            }}
          >
            <CompanyAvatar
              onClick={(e) => onClick(e, valorId)}
              src={logoUrl}
              name={name}
              domain={domain}
              size="32"
            />
            <Box
              onClick={(e) => onClick(e, valorId)}
              display="flex"
              flexDirection="column"
              paddingLeft={2}
            >
              <Typography
                sx={{
                  overflowX: "hidden",
                  color: (theme) => theme.palette.text.secondary,
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",

                }}
                variant="subtitle1"
              >
                {name}
              </Typography>
              <Typography variant="caption">
                {dayjs(interactionDate).format("ddd, MMM Do YYYY")}
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
    </SwipeX>
  );
}

export default InteractionLineItem;
