import React from "react";
import { Box, Chip } from "@mui/material";

type KeyMetricsProps = {
  keyMetrics: string[];
};

export default function KeyMetrics({
  keyMetrics,
}: KeyMetricsProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={1}
    >
      {keyMetrics.map((metric) => (
        <Chip
          key={metric}
          label={metric}
        />
      ))}
    </Box>
  );
}
