import React from "react";
import CheckIcon from "@mui/icons-material/Check";

export default function BooleanCell({
  value,
}: {
  value: boolean | string;
}) {
  return (value === true || value === "Yes") ? (
    <CheckIcon />
  ) : <>-</>;
}
