import React, { useRef } from "react";
import {
  Typography, ButtonBase, Button, Box,
} from "@mui/material";
import { useQueryClient } from "react-query";
import { deleteOrganizationKeyMetric } from "@/api/Metrics";
import { usePopover } from "@/hooks/useGlobalPopover";
import Progress from "@/ui/atoms/Progress";

interface LabelDialogProps {
  name: string;
  labelId: string;
  isCalculated: boolean;
  entityId: string;
  closePopover: () => void;
}

function LabelDialog({
  name,
  labelId,
  isCalculated,
  entityId,
  closePopover,
}: LabelDialogProps) {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = React.useState(false);

  const removeCompanyKeyMetric = async (id: string) => {
    setLoading(true);
    try {
      await deleteOrganizationKeyMetric(entityId, id);

      await queryClient.refetchQueries(["Metrics", entityId]);

      queryClient.refetchQueries(["keyMetrics", entityId]);
    } finally {
      setLoading(false);
      closePopover();
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 1,
        width: "320px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        variant="overline"
        sx={{
          color: isCalculated ? "text.disabled" : "text.primary",
          fontWeight: isCalculated ? "normal" : "bold",
          fontStyle: isCalculated ? "italic" : "normal",
          display: "inline-block",
          maxWidth: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
          overflowWrap: "break-word",
        }}
      >
        {name}
      </Typography>
      { isLoading ? <Progress /> : null }
      <Button
        disabled={isLoading}
        onClick={() => removeCompanyKeyMetric(labelId)}
        sx={{ width: "128px" }}
        color="error"
        variant="contained"
      >
        Remove Label
      </Button>
    </Box>
  );
}

interface MetricLabelCellProps {
  getValue: () => string;
  row: {
    original: {
      isCalculated?: boolean;
      labelId: string;
      entityId: string;
    };
  };
}

export function MetricLabelCell({
  getValue,
  row: {
    original: { isCalculated = false, labelId, entityId },
  },
}: MetricLabelCellProps) {
  const name = getValue();
  const { openPopover, closePopover } = usePopover();
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <ButtonBase
      ref={buttonRef}
      onClick={() => openPopover(
        buttonRef,
        <LabelDialog
          name={name}
          labelId={labelId}
          isCalculated={isCalculated}
          entityId={entityId}
          closePopover={closePopover}
        />,
      )}
      sx={{
        width: "100%",
        height: "24px",
        display: "flex",
        justifyContent: "flex-start",
        padding: (t) => t.spacing(0.5),
        borderRadius: (t) => t.spacing(1),
      }}
    >
      <Typography
        variant="overline"
        sx={{
          color: isCalculated ? "text.disabled" : "text.primary",
          fontWeight: "bold",
          fontStyle: isCalculated ? "italic" : "normal",
          lineHeight: "normal",
          display: "inline-block",
          maxWidth: "100%",
          wordWrap: "break-word",
          whiteSpace: "normal",
          overflowWrap: "break-word",
          textAlign: "left",
        }}
      >
        {name}
      </Typography>
    </ButtonBase>
  );
}

export default MetricLabelCell;
