export const AGREE_STATUS = "AGREE";
export const DISAGREE_STATUS = "DISAGREE";
export const IN_DISCUSSION_STATUS = "DISCUSS";

export const DEPARTMENT_LABELS = {
  vsv: "VSV",
  ancillary: "Growth-Ancillary",
  platform: "Growth-Platform",
  seed: "Seed 1.0",
};
