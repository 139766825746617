import { useMemo } from "react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getResponses } from "@/api/FrameworkSurvey";
import { useAuth } from "@/hooks/useAuth";
import { canVoteOnFirstMeeting } from "@/constants/Roles";
import { getLastMonday } from "@/utils/general";

const surveyIds = [
  "43860881-ce24-4105-89a7-07f5170e84f1",
  "0d4772ae-d656-4763-bda6-ff5b4e941354",
  "4ffe8931-76b1-4cd4-899b-e9d609b8cfa1",
  "bedfb774-3086-5cb8-9a0e-c4050cef5fd3",
  "b7050dec-791a-46a5-b206-fa18b7a05498",
  "89c5be86-b6f3-4756-a4af-6fb997ef052f",
  "8efa5760-679a-4af9-a027-1b98c9b8183e",
  "35f42e38-7cf0-47f0-8dc6-0018e8ddf62c",
  "c519857f-c2e8-4a6e-9e8d-3b0ca645c90e",
];

export function useUnvotedLastWeekReportsCount() {
  const completedSince = getLastMonday(dayjs).format("YYYY-MM-DD");
  const { user } = useAuth();

  const canVote = canVoteOnFirstMeeting(user);

  const { data, isLoading } = useQuery(
    ["UnvotedReports"],
    () => getResponses({surveyIds, completedSince, isMsl: false}),
    {
      enabled: canVote,
    },
  );

  const unvotedReports = useMemo(() => {
    if (!data) return [];
    return data.filter((response) => !response.votes?.[user.id]);
  }, [data, user.id]);

  if (isLoading || user.lastName === "Dyer") {
    return { count: 0, isLoading };
  }

  if (!canVote) {
    return { count: 0, isLoading: false };
  }

  return { count: unvotedReports.length, isLoading };
}
export default useUnvotedLastWeekReportsCount;
