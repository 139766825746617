import React from "react";
import {
  Card,
  Box,
  Typography,
  useTheme,
  AvatarGroup,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import UserAvatar from "@/ui/atoms/UserAvatar";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { Task, FieldList } from "@/api/Process";
import Clamps from "@/components/Clamps";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import {
  getPrimaryUser,
  getPrimaryCompany,
  getFieldsToShow,
  renderFieldValue,
  getNameFieldValue,
} from "./ViewUtils";

type BoardTaskProps = {
  groupedByFieldId: string;
  fields: FieldList;
  task: Task;
  onClick?: (e: React.MouseEvent) => void;
  onMoveToStart?: () => void;
  onMoveToEnd?: () => void;
  draggable?: boolean;
};

function FieldChipsContainer({ children }) {
  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={0.5}
    >
      {children}
    </Box>
  );
}

export function BoardTask({
  groupedByFieldId,
  fields,
  task,
  onClick,
  onMoveToStart,
  onMoveToEnd,
  draggable = false,
}: BoardTaskProps) {
  const theme = useTheme();

  const primaryUser = getPrimaryUser(task, fields, true);
  const primaryCompany = getPrimaryCompany(task, fields);
  const nameField = getNameFieldValue(task, fields);

  const fieldsToShow = getFieldsToShow(fields, groupedByFieldId, task);

  const [showContextMenu, setShowContextMenu] = React.useState(false);
  const [contextMenuPosition, setContextMenuPosition] = React.useState({ x: 0, y: 0 });
  const contextMenuAnchor = React.useRef(null);

  return (
    <Card
      sx={{
        width: "314px",
        height: "auto",
        minHeight: "100px",
        padding: 1,
        cursor: draggable ? "move" : "auto",
        userSelect: "none",
        borderRadius: "8px !important",
      }}
      elevation={1}
      onClick={(e) => {
        if (!showContextMenu) {
          onClick?.(e);
        }
      }}
      onContextMenu={(e) => {
        if (onMoveToStart && onMoveToEnd) {
          e.preventDefault();
          e.stopPropagation();

          setShowContextMenu(true);
          setContextMenuPosition({ x: e.clientX, y: e.clientY });

          return false;
        }
        return true;
      }}
      data-cy="board-task"
      data-cy-id={task.id}
      ref={contextMenuAnchor}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        <Box
          display="flex"
          flexDirection="row"
          gap={1}
          justifyContent="space-between"
        >
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              height="100%"
            >
              {primaryCompany && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  gap={1}
                  mb={1}
                >
                  <CompanyAvatar
                    src={primaryCompany?.logoUrl}
                    name={primaryCompany?.name}
                    domain={primaryCompany?.domain}
                    size={40}
                  />
                  <Typography
                    variant="h3"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "200px",
                    }}
                  >
                    {primaryCompany?.name}
                  </Typography>
                </Box>
              )}
              <Typography
                variant="body1"
                sx={{
                  fontWeight: theme.typography.fontWeightBold,
                  maxHeight: 75,
                }}
              >
                <Clamps
                  lineHeight="25px"
                  maxLines={2}
                >
                  {nameField}
                </Clamps>
              </Typography>
            </Box>
          </Box>

          {Array.isArray(primaryUser) ? (
            <AvatarGroup max={3} spacing="small">
              {primaryUser.map((u) => (
                <UserAvatar
                  key={u.id}
                  user={u || {}}
                  style={{
                    width: 40,
                    height: 40,
                  }}
                />
              ))}
            </AvatarGroup>
          ) : (
            <UserAvatar
              user={primaryUser || {}}
              style={{
                width: 40,
                height: 40,
              }}
            />
          )}
        </Box>
        <FieldChipsContainer>
          {fieldsToShow.map((f) => renderFieldValue(f, theme))}
        </FieldChipsContainer>
      </Box>

      {showContextMenu && (
        <Menu
          id="board-task-context-menu"
          anchorEl={contextMenuAnchor.current}
          anchorReference="anchorPosition"
          anchorPosition={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
          open={showContextMenu}
          onClose={() => setShowContextMenu(false)}
        >
          <MenuItem onClick={() => {
            onMoveToStart();
            setShowContextMenu(false);
          }}
          >
            <ListItemIcon>
              <ArrowUpward />
            </ListItemIcon>
            <ListItemText>
              Move to top
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={() => {
            onMoveToEnd();
            setShowContextMenu(false);
          }}
          >
            <ListItemIcon>
              <ArrowDownward />
            </ListItemIcon>
            <ListItemText>
              Move to bottom
            </ListItemText>
          </MenuItem>
        </Menu>
      )}
    </Card>
  );
}

export default BoardTask;
