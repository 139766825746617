import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Menu,
} from "@mui/material";
import {
  Edit, Delete,
  DragIndicatorOutlined,
  CopyAll,
  MoreVert,
} from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

export default function ViewListItem({
  view,
  onEdit,
  onDelete,
  onDuplicate,
  disabled,
}: {
  view: {
    id: string;
    name: string;
  };
  onEdit?: () => void;
  onDelete?: () => void;
  onDuplicate?: () => void;
  disabled?: boolean;
}) {
  const [showDelete, setShowDelete] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorEl = React.useRef(null);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        <DragIndicatorOutlined
          sx={{
            cursor: "grab",
          }}
        />
        <Typography>
          {view.name}
        </Typography>
      </Box>

      <Box
        display={{
          xs: "none",
          md: "flex",
        }}
        flexBasis={0}
      >
        {onEdit && (
          <Tooltip title="Edit View">
            <IconButton
              onClick={() => {
                onEdit(view.id);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title="Delete View">
            <IconButton
              onClick={() => {
                setShowDelete(true);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
        {onDuplicate && (
          <Tooltip title="Duplicate View">
            <IconButton
              onClick={() => {
                onDuplicate(view.id);
              }}
            >
              <CopyAll />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box
        display={{
          xs: "auto",
          md: "none",
        }}
      >
        <IconButton
          ref={anchorEl}
          onClick={() => setMenuOpen(true)}
        >
          <MoreVert />
        </IconButton>
        <Menu
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          anchorEl={anchorEl.current}
        >
          {onEdit && (
            <ListItem
              onClick={() => {
                onEdit(view.id);
                setMenuOpen(false);
              }}
            >
              <ListItemIcon>
                <Edit />
              </ListItemIcon>
              <ListItemText>
                Edit View
              </ListItemText>
            </ListItem>
          )}
          {onDelete && (
            <ListItem
              onClick={() => {
                setShowDelete(true);
                setMenuOpen(false);
              }}
            >
              <ListItemIcon>
                <Delete />
              </ListItemIcon>
              <ListItemText>
                Delete View
              </ListItemText>
            </ListItem>
          )}
          {onDuplicate && (
            <ListItem
              onClick={() => {
                onDuplicate(view.id);
                setMenuOpen(false);
              }}
            >
              <ListItemIcon>
                <CopyAll />
              </ListItemIcon>
              <ListItemText>
                Duplicate View
              </ListItemText>
            </ListItem>
          )}
        </Menu>
      </Box>

      <Dialog
        open={showDelete}
        onClose={() => {
          setShowDelete(false);
        }}
      >
        <DialogTitle>
          Are you sure you want to delete
          {" "}
          {view?.name}
          ?
        </DialogTitle>
        <DialogContent>
          This will permanantly delete the view.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onDelete();
              setShowDelete(false);
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setShowDelete(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
