export const FundingTypes = [
  "Angel",
  "Early Stage VC",
  "Seed",
  "Series A",
  "Series B",
  "Series C",
  "Series D",
  "Late Stage VC",
  "Public Equity",
  "M&A",
  "Secondary",
  "Public Offering",
  "Debt",
  "Grant",
  "Other Financing/Acquisition",
  "Unknown",
];

export default FundingTypes;
