import React from "react";
import { Box, Link, Typography } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import dayjs from "dayjs";
import Clamps from "@/components/Clamps";

export default function Chat({
  title,
  createdAt,
  onClick,
}: {
  title: string;
  createdAt: string;
  onClick: () => void;
}) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      padding={2}
      border={(theme) => `solid 1px ${theme.palette.text.primary}`}
      borderRadius={1}
      sx={{
        "&:hover": {
          opacity: 0.5,
        },
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <ChatBubbleOutlineIcon fontSize="small" />
      <Clamps
        maxLines={2}
        collapsed
      >
        {title}
      </Clamps>
      <Typography
        variant="caption"
      >
        {dayjs(createdAt).fromNow()}
      </Typography>
    </Box>
  );
}
