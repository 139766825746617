import React from "react";
import { Box, Typography } from "@mui/material";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getConversations } from "@/api/Chat";
import { Button } from "@material-ui/core";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Chat from "./Chat";

export default function RecentChats() {
  const navigate = useNavigate();
  const { data: chats = [], isLoading: chatsLoading } = useQuery(
    "conversations",
    () => getConversations()
  );
  const isLoading = chatsLoading;

  return (
    <Box display="flex" flexDirection="column" gap={2} width="100%">
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        width="100%"
      >
        <Typography
          variant="h3"
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 1,
          }}
        >
          <ChatBubbleOutlineIcon />
          Your recent chats
        </Typography>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            navigate("/grok/search");
          }}
          endIcon={<ArrowForwardIcon />}
          size="small"
        >
          View All
        </Button>
      </Box>

      {chats.length === 0 && !isLoading && (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <Typography>No recent chats</Typography>
        </Box>
      )}

      {chats.length > 0 && (
        <Box
          display="grid"
          gridTemplateColumns={{
            xs: "1fr",
            sm: "repeat(3, 1fr)",
          }}
          gap={2}
          sx={{
            maxHeight: { xs: "56vh", sm: "auto" },
            overflow: { xs: "auto", sm: "visible" },
          }}
        >
          {chats.map((chat) => (
            <Chat
              key={chat.id}
              title={chat.title}
              createdAt={chat.createdAt}
              onClick={() => {
                navigate(`/grok/chat/${chat.id}`);
              }}
            />
          ))}
        </Box>
      )}
    </Box>
  );
}
