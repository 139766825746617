import React, { useMemo, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
} from "@mui/material";
import {
  getCalculatedVariables,
  postCalculatedMetric,
  putCalculatedMetric,
  MetricsLabel,
  GetOrganizationKeyMetricsResult,
} from "@/api/Metrics";
import { useQueryClient } from "react-query";
import Progress from "@/ui/atoms/Progress";
import MetricsAutocomplete from "../MetricsAutocomplete";

interface CalculatedMetricsDialogueProps {
  valorId: string;
  open: boolean;
  setClose: () => void;
  labelSet: MetricsLabel[];
  chosenLabels: GetOrganizationKeyMetricsResult;
  openNewLabelDialog: (inputValue: string, isCalc: boolean) => void;
}

export function CalculatedMetricsDialogue({
  valorId,
  open,
  setClose,
  labelSet = [],
  chosenLabels,
  openNewLabelDialog,
}:
CalculatedMetricsDialogueProps) {
  const queryClient = useQueryClient();
  const [isBusy, setIsBusy] = useState(false);
  const [isEditingCalcField, setIsEditingCalcField] = useState(false);
  const [lhsMetric, setLhsMetric] = useState<string[]>([]);
  const [rhsMetric, setRhsMetric] = useState<string[]>([]);
  const [calculatedField, setCalculatedField] = useState<MetricsLabel | null>(null);
  const [operator, setOperator] = useState<string>("");

  const closeCalculatedFieldDialog = () => {
    setClose();
    setOperator("");
    setLhsMetric([]);
    setRhsMetric([]);
    setIsEditingCalcField(false);
    setCalculatedField(null);
  };

  const handleCreateEdit = async () => {
    try {
      if (isEditingCalcField) {
        await putCalculatedMetric(
          valorId,
          calculatedField?.id,
          lhsMetric,
          rhsMetric,
          operator,
        );
      } else {
        await postCalculatedMetric(
          valorId,
          calculatedField?.id,
          lhsMetric,
          rhsMetric,
          operator,
        );
      }
      await queryClient.refetchQueries(["Metrics", valorId]);
    } finally {
      setIsBusy(false);
      closeCalculatedFieldDialog();
    }
  };

  const calculatedLabels = useMemo(() => labelSet.filter((x) => x.isCalculated), [labelSet]);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={closeCalculatedFieldDialog}
      aria-labelledby="create-calculated-field-dialog-title"
    >
      <DialogTitle id="create-calculated-field-dialog-title">
        New Calculated Metric
        <Typography variant="body2" color="text.secondary">
          Multiple Metrics can be selected for the Left Hand Side and Right Hand
          Side and will be summed up.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <MetricsAutocomplete
            label="Select Calculated Metric"
            options={calculatedLabels}
            value={calculatedField}
            disabled={false}
            loading={false}
            multiple={false}
            onChange={async (event, value) => {
              if (value.id) {
                setCalculatedField(value);
                let res = null;
                try {
                  setIsBusy(true);
                  res = await getCalculatedVariables(value.id, valorId);
                } finally {
                  setIsBusy(false);
                }

                if (res) {
                  setLhsMetric(res.lhsLabelIds);
                  setRhsMetric(res.rhsLabelIds);
                  setOperator(res.operator);
                  setIsEditingCalcField(true);
                } else {
                  setLhsMetric([]);
                  setRhsMetric([]);
                  setOperator("");
                  setIsEditingCalcField(false);
                }
              } else {
                openNewLabelDialog(value.inputValue, true);
              }
            }}
          />

          <Box fontSize={50}>=</Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flex: 1,
              gap: 2,
            }}
          >
            <FormControl fullWidth>
              <InputLabel id="label-lhs-metric">Left Hand Side</InputLabel>
              <Select
                id="lhs-metric"
                label="Left Hand Side"
                multiple
                disabled={isBusy}
                value={lhsMetric}
                onChange={(e) => setLhsMetric(e.target.value as string[])}
              >
                {chosenLabels.map((metric) => (
                  <MenuItem key={metric.id} value={metric.id}>
                    {metric.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl sx={{ minWidth: "200px" }}>
              <InputLabel id="label-operator">Operator</InputLabel>
              <Select
                label="Operator"
                value={operator}
                disabled={isBusy}
                onChange={(e) => setOperator(e.target.value as string)}
              >
                <MenuItem value="*">x</MenuItem>
                <MenuItem value="/">÷</MenuItem>
                <MenuItem value="+">+</MenuItem>
                <MenuItem value="-">-</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <InputLabel id="label-rhs-metric">Right Hand Side</InputLabel>
              <Select
                label="Right Hand Side"
                multiple
                disabled={isBusy}
                value={rhsMetric}
                onChange={(e) => setRhsMetric(e.target.value as string[])}
              >
                {chosenLabels.map((metric) => (
                  <MenuItem key={metric.id} value={metric.id}>
                    {metric.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
        <Box
          sx={{
            height: "48px",
          }}
        >
          {isBusy ? <Progress /> : null}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isBusy}
          onClick={closeCalculatedFieldDialog}
          color="primary"
        >
          Cancel
        </Button>
        <Button
          onClick={handleCreateEdit}
          variant="contained"
          color="primary"
          disabled={
            isBusy || lhsMetric.length === 0
            || rhsMetric.length === 0
            || operator === ""
          }
        >
          {isEditingCalcField ? "Edit" : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CalculatedMetricsDialogue;
