import React, { useState } from "react";
import { Box, ClickAwayListener, Chip } from "@mui/material";
import { styled } from "@mui/system";
import SectorChip from "@/components/SectorChip";

const BlueChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.blue,
}));

export default function SectorsCell({ value }: { value: string[] }) {
  const [showOverflow, setShowOverflow] = useState(false);

  if (!value || value.length === 0) {
    return <>-</>;
  }

  return (
    <ClickAwayListener onClickAway={() => setShowOverflow(false)}>
      <Box
        maxHeight="200px"
        height="100%"
        maxWidth="200px"
        position="relative"
      >
        <Box
          display="flex"
          alignItems="center"
          gap={1}
          height="100%"
          overflow="hidden"
          visibility={showOverflow ? "hidden" : "visible"}
          sx={{
            cursor: "pointer",
          }}
          onClick={() => setShowOverflow(value.length > 1)}
        >
          {value?.length > 0 && (
            <SectorChip
              sectorType={value[0]}
              sx={{
                cursor: "pointer",
                maxWidth: value.length > 1 ? "120px" : "auto",
              }}
            />
          )}
          {
            value?.length > 1 && (
              <BlueChip
                color="primary"
                label={`+${value.length - 1}`}
              />
            )
          }
        </Box>
        <Box
          position="absolute"
          top={0}
          left={0}
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          gap={1}
          visibility={showOverflow ? "visible" : "hidden"}
          bgcolor="background.paper"
          border={(theme) => `1px solid ${theme.palette.divider}`}
          padding={1}
          borderRadius={1}
          zIndex={1}
        >
          {value.map((sector: string) => (
            <SectorChip key={sector} sectorType={sector} />
          ))}
        </Box>
      </Box>
    </ClickAwayListener>
  );
}
