import React from "react";
import {
  MenuItem,
  Select,
  Theme,
  ToggleButton,
  ToggleButtonGroup,
  useMediaQuery,
  FormControl,
  InputLabel,
} from "@mui/material";
import { styled } from "@mui/system";

type ButtonTabOption =
  | { key: string; label: string; startIcon?: JSX.Element }
  | string;
type ButtonTabProps = {
  id?: string;
  width?: string;
  wrap?: boolean;
  whiteSpace?: string;
  evenSizedButtons?: boolean;
  getKey?: (option: ButtonTabOption) => string;
  getLabel?: (option: ButtonTabOption) => string;
  options: ButtonTabOption[];
  onClick: (option: ButtonTabOption) => void;
  disableTest?: (option: ButtonTabOption) => boolean;
  activeKey: string;
  tabLabel?: string;
  color?: "primary" | "secondary";
};

const IconParent = styled("div")({
  marginRight: "3px",
});

export default function ButtonTabs({
  id = "",
  width = "100%",
  wrap = false,
  whiteSpace = "nowrap",
  evenSizedButtons = true,
  getKey = (option) => (typeof option === "object" ? option?.key : option),
  getLabel = (option) => (typeof option === "object" ? option?.label : option),
  options,
  onClick,
  activeKey,
  disableTest = () => null,
  tabLabel = null,
  color = "primary",
}: ButtonTabProps) {
  const isSmDown = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  if (isSmDown && options.length > 2) {
    if (activeKey === undefined) {
      return null;
    }
    return (
      <FormControl fullWidth>
        {tabLabel ? <InputLabel>{tabLabel}</InputLabel> : null}
        <Select
          value={activeKey}
          label={tabLabel}
          onChange={(e) => onClick(options.find((o) => getKey(o) === e.target.value))}
          sx={{
            width: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            flexWrap: wrap ? "wrap" : "nowrap",
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={`toggle-button-${getKey(option)}`}
              value={getKey(option)}
              data-cy={`button-tab-${getKey(option)}`}
              disabled={disableTest(option)}
              onClick={() => onClick(option)}
            >
              {getLabel(option)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }
  return (
    <ToggleButtonGroup
      id={id}
      value={activeKey}
      onChange={(e, value) => onClick(options.find((o) => getKey(o) === value || o === value))}
      exclusive
      sx={{
        width: { xs: "100%", sm: width },
        overflowX: "auto",
        overflowY: "hidden",
        flexWrap: wrap ? "wrap" : "nowrap",
      }}
    >
      {options.map((option) => (
        <ToggleButton
          sx={(theme: Theme) => ({
            height: { xs: options.length <= 2 ? "32px" : "64px", sm: "30px" },
            display: "flex",
            maxWidth: wrap ? "fit-content" : null,
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 8px",
            flex: evenSizedButtons ? "1 1 0" : null,
            color: `${theme.palette.text.primary} !important`,
            backgroundColor: `${theme.palette.background.paper} !important`,
            border: `1px solid ${theme.palette.divider} !important`,
            whiteSpace: { whiteSpace },
            textTransform: "none",
            "&.Mui-selected": {
              color: `${theme.palette[color].contrastText} !important`,
              backgroundColor: `${theme.palette[color].main} !important`,
            },
            "&.Mui-disabled": {
              color: `${theme.palette.text.disabled} !important`,
              backgroundColor: `${theme.palette.background.paper} !important`,
            },
          })}
          key={`toggle-button-${getKey(option)}`}
          data-cy={`button-tab-${getKey(option)}`}
          disabled={disableTest(option)}
          value={getKey(option)}
        >
          {option.startIcon && <IconParent>{option.startIcon}</IconParent>}
          {getLabel(option)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}
