import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import MedalIcon from "@mui/icons-material/MilitaryTech";
import GroupsIcon from "@mui/icons-material/Groups";
import FirmographicIcon from "@mui/icons-material/Work";
import ReviewsIcon from "@mui/icons-material/RateReview";
import DealDataIcon from "@mui/icons-material/LocalOffer";
import FinancialsIcon from "@mui/icons-material/AccountBalance";
import WebTrafficIcon from "@mui/icons-material/Language";
import NewsIcon from "@mui/icons-material/Article";

import FlakeIcon from "@/ui/atoms/Icons/PlainFlake";
import GlassdoorIcon from "@/ui/atoms/Icons/Glassdoor";

import { useTheme } from "@mui/material";

import { ValueTransformer } from "./Utils";

export const FeatureFamilyIconMap = {
  default: GroupsIcon,
  "Employee Data": GroupsIcon,
  Firmographics: FirmographicIcon,
  "Investment Hypothesis Element": FlakeIcon,
  "Product Reviews": ReviewsIcon,
  "Deal Data": DealDataIcon,
  Financials: FinancialsIcon,
  "Company Rank": MedalIcon,
  Glassdoor: GlassdoorIcon,
  "Web Traffic": WebTrafficIcon,
  News: NewsIcon,
};

const useFeature = (feature) => {
  const theme = useTheme();

  const value = feature.displayQuantile ? feature.quantile : feature.value;
  const { units } = feature;
  let transformedValue = feature.displayQuantile
    ? (value !== null ? `${Math.round(value)}%ile` : "N/A")
    : (ValueTransformer[units] ? ValueTransformer[units](value) : ValueTransformer.default(value));
  if (transformedValue === "nan") {
    transformedValue = "N/A";
  }

  const isOutlier = feature.isSignificant; // outlier logic here
  const isPositiveContributor = feature.shapScore > 0;

  // internal sources
  const internalSources = ["Surveys"];

  return [
    {
      Icon: FeatureFamilyIconMap[feature.featureFamily] || FeatureFamilyIconMap.default,
      iconColor: (
        internalSources.includes(feature.sourcedFrom)
          ? theme.palette.primary.main
          : theme.palette.text.primary
      ),
      value: transformedValue,
      sourcedFrom: feature.sourcedFrom,
      sourcedFromColor: (
        internalSources.includes(feature.sourcedFrom)
          ? theme.palette.primary.main
          : theme.palette.text.primary
      ),
      units,
      isOutlier,
      OutlierIcon: CheckCircleIcon,
      outlierIconColor: theme.palette.secondary.main,
      isPositiveContributor,
    },
    feature,
  ];
};

export default useFeature;
