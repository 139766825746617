import React, {
  useParams, useNavigate, Route, Routes, Navigate,
} from "react-router-dom";
import {
  Box,
  Card,
  Grid,
} from "@mui/material";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { useAuth } from "@/hooks/useAuth";
import {
  canViewAnalystAssociateAARs,
  canViewAllAARs,
} from "@/constants/Roles";
import AARResponse from "./AARResponses";

export function AARDashboard() {
  const navigate = useNavigate();
  const { "*": tab } = useParams();
  const { user } = useAuth();

  const tabs = [
    {
      key: "self-reviews",
      department: "self_review",
      label: "My Self Reviews",
    },
    {
      key: "peer-reviews",
      department: "peer_review",
      label: "My Peer Reviews",
    },
    {
      key: "peer-reviews-me",
      department: "peer_review",
      label: "Peer Reviews of Me",
    },
  ];
  if (canViewAllAARs(user) || canViewAnalystAssociateAARs(user)) {
    tabs.push({
      key: "admin",
      label: "Management",
      department: "admin",
    });
  }

  return (
    <>
      <Grid
        container
        spacing={1}
        rowSpacing={1}
      >
        <Grid item md={6} container justifyContent="flex-end" sx={{ overflow: "hidden" }}>
          <ButtonTabs
            options={tabs}
            onClick={(option) => navigate(`/aar-dashboard/${option.key}`)}
            activeKey={tab}
          />
        </Grid>
      </Grid>

      <Routes>
        <Route
          path=""
          element={<Navigate to="self-reviews" replace />}
        />
        {tabs.map((t) => (
          <Route
            key={t.key}
            path={t.key}
            element={(
              <Grid
                container
                direction="column"
                sx={{ overflow: "hidden" }}
              >
                <Grid
                  item
                  sx={{ flex: 1, overflow: "auto" }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    sx={{ gap: "16px" }}
                  >
                    <Card
                      elevation={0}
                      sx={{
                        padding: 1,
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <AARResponse
                        department={t?.department}
                        tab={t.key}
                      />
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            )}
          />
        ))}
        ;
      </Routes>
    </>
  );
}

export default AARDashboard;
