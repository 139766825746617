import React from "react";
import {
  Box, Typography, Tooltip, useTheme,
} from "@mui/material";
import startCase from "lodash/startCase";
import MarketMapCell from "./MarketMapCell";
import MarketMapItem from "./MarketMapItem";

// One day it may make sense to use Recoil.js to manage the highlighting as the size of maps grows.
export function MarketMapGrid(props) {
  const {
    row,
    col,
    memberCells,
    highlightedCompanyId = null,
    setHighlightedCompanyId = null,
    onClick = null,
    style = null,
    id,
  } = props;
  const theme = useTheme();

  const onItemMouseEnter = (highlightId) => {
    if (highlightId) {
      setHighlightedCompanyId(highlightId);
    }
  };

  const onItemMouseLeave = () => {
    setHighlightedCompanyId(null);
  };

  const getCompaniesForCell = (rowId, colId) => memberCells
    .filter((cell) => cell.rowDimensionValueId === rowId && cell.colDimensionValueId === colId)
    .map((company) => (
      <MarketMapItem
        key={`${company.valorId}-${rowId}-${colId}`}
        onClick={onClick}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
        id={company.valorId}
        logoUrl={company.logoUrl}
        name={company.name}
        domain={company.domain}
        deEmphasize={highlightedCompanyId && company.valorId !== highlightedCompanyId}
      />
    ));

  const buildCells = () => row?.values?.map((rowValue, i) => col?.values?.map((colValue, j) => (
    <div key={`${rowValue.id}-${colValue.id}`} style={{ gridRow: i + 2, gridColumn: j + 2 }}>
      <MarketMapCell>
        {getCompaniesForCell(rowValue.id, colValue.id)}
      </MarketMapCell>
    </div>
  )));

  const labelStyles = (gridColumn, gridRow) => ({
    gridColumn,
    gridRow,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  });

  const buildColumnTitles = () => col?.values?.map((value, index) => (
    <Tooltip
      title={value.tooltip}
      placement="top"
      key={value.name}
      disableFocusListener={!value.tooltip}
      disableHoverListener={!value.tooltip}
      disableTouchListener={!value.tooltip}
    >
      <div
        style={{
          ...labelStyles(index + 2, 1),
          borderTopLeftRadius: "8px",
          borderTopRightRadius: "8px",
          backgroundColor: theme.palette.blue.light,
          color: "white",
          border: `1px solid ${theme.palette.divider}`,
        }}
      >
        <Typography
          style={{ fontWeight: theme.typography.fontWeightBold, whiteSpace: "normal", fontSize: "100%" }}
          variant="h2"
        >
          {value.name}
          {value.tooltip ? "*" : null}
        </Typography>
      </div>
    </Tooltip>
  ));

  const buildRowTitles = () => row?.values?.map((value, index) => {
    const baseStyle = {
      ...labelStyles(1, index + 2),
      width: "32px",
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      backgroundColor: theme.palette.blue.light,
      color: "white",
      border: `1px solid ${theme.palette.divider}`,
    };
    return (
      <Tooltip
        title={value.tooltip}
        placement="right"
        key={value.name}
        disableFocusListener={!value.tooltip}
        disableHoverListener={!value.tooltip}
        disableTouchListener={!value.tooltip}
      >
        <div style={baseStyle}>
          <Typography
            style={{ transform: "rotate(-90deg)", fontWeight: theme.typography.fontWeightBold, fontSize: "100%" }}
            variant="h2"
          >
            {value.name}
            {value.tooltip ? "*" : null}
          </Typography>
        </div>
      </Tooltip>
    );
  });

  const topLeft = () => (
    <div style={{ backgroundColor: "transparent" }} />
  );

  const rowLength = props?.row?.values.length;
  const colLength = props?.col?.values.length;
  const calcedExpression = `calc(${
    834 / (rowLength || 1)
  }px)`;

  return (
    <Box
      id={id}
      sx={{
        display: "flex",
        flexDirection: "row",
        width: style ? "1450px" : undefined,
      }}
    >
      <Box
        sx={{
          writingMode: "vertical-lr",
          textAlign: "center",
          transform: "rotate(180deg)",
        }}
      >
        <Typography variant="h2">
          {startCase(row?.name)}
        </Typography>
      </Box>
      <Box style={style || { width: "100%" }}>
        <Typography align="center" variant="h2">
          {startCase(col?.name)}
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `32px repeat(${colLength}, 1fr)`,
            gridTemplateRows: `32px repeat(${rowLength}, minmax(0, ${calcedExpression}))`,
          }}
        >
          {topLeft()}
          {buildColumnTitles()}
          {buildRowTitles()}
          {buildCells()}
        </Box>
      </Box>
    </Box>
  );
}

export default MarketMapGrid;
