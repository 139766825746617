import { AdvancedDescendant } from "../types/RichText";
import renderLeaf from "../Serializer/JSX/renderLeaf";

type LeafProps = {
  attributes: JSX.IntrinsicAttributes,
  children: string,
  leaf: AdvancedDescendant,
};

export default function Leaf({ attributes, children, leaf }: LeafProps) {
  return renderLeaf(leaf, attributes, children);
}
