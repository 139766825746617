import React from "react";
import TextField from "@mui/material/TextField";

type NumberInputProps = {
  value: string;
  onChange: (value: string) => void;
  onBlur: (value: string) => void;
  label: string;
  required?: boolean;
  disabled?: boolean;
  dataCy?: string;
};

export default function NumberInput({
  value,
  onChange,
  onBlur,
  label,
  required,
  disabled,
  dataCy,
  error,
}: NumberInputProps) {
  return (
    <TextField
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      onBlur={(e) => onBlur(e.target.value)}
      variant="standard"
      required={required}
      type="number"
      sx={{
        minWidth: "100%",
      }}
      disabled={disabled}
      inputProps={{
        "data-cy": dataCy,
      }}
      error={error}
    />
  );
}
