import React, { useEffect, useState } from "react";
import { useField } from "react-final-form";
import { Typography, Grid, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FrameworkRadio from "@/ui/atoms/FrameworkRadio";

const colorToIndex = {
  blue: 0,
  green: 1,
  yellow: 2,
  red: 3,
  gray: 4,
};

const choiceValidator = (value) => (!value ? "Required" : undefined);

const commentValidator = (
  value,
  questionRequiresComment,
  choiceNecessitatesComment,
) => {
  if (!value) {
    if (questionRequiresComment) return "This question requires a comment";
    if (choiceNecessitatesComment) return "Your choice for this question requires a comment";
  }
  return undefined;
};

export default function MultiChoice(props) {
  const {
    questionId,
    commentRequired: questionRequiresComment,
    showComment,
    choices: initialChoices,
    initialValue = null,
    initalComment = null,
  } = props;

  const [choiceNecessitatesComment, setChoiceNecessitatesComment] = useState(false);

  const { input: choiceInput, meta: choiceMeta } = useField(
    `${questionId}.choiceId`,
    {
      validate: choiceValidator,
      initialValue,

    },
  );
  const { input: commentaryInput, meta: commentaryMeta } = useField(
    `${questionId}.commentary`,
    {
      initialValue: initalComment,
      validate: (value) => commentValidator(
        value,
        questionRequiresComment,
        choiceNecessitatesComment,
      ),
    },
  );

  useEffect(() => {
    if (!questionRequiresComment) {
      const choiceCommentRequired = initialChoices.find((c) => c.id === choiceInput.value)
        ?.commentRequired ?? false;
      setChoiceNecessitatesComment(choiceCommentRequired);
    }
  }, [initialChoices, choiceInput.value, questionRequiresComment]);

  const choices = [...initialChoices].sort(
    (a, b) => colorToIndex[a.color] - colorToIndex[b.color],
  );

  let choiceChooser = null;
  if (choices.length <= 5) {
    choiceChooser = choices.map((choice) => (
      <Grid key={choice.id} item xs={12} lg={12 / choices.length || 1}>
        <FrameworkRadio
          id={choice.id}
          isActive={choiceInput.value === choice.id}
          onClick={() => choiceInput.onChange(choice.id)}
          color={choice.color}
          text={choice.choiceText}
        />
      </Grid>
    ));
  } else {
    choiceChooser = (
      <Grid container spacing={1}>
        <FormControl fullWidth margin="normal" sx={{ marginLeft: 2, maxWidth: "320px" }}>
          <InputLabel id={`chip-label-${questionId}`}>Select One</InputLabel>
          <Select
            labelId={`chip-label-${questionId}`}
            value={choiceInput.value}
            onChange={(e) => choiceInput.onChange(e.target.value)}
            label="Select One"
          >
            {choices.map((choice) => (
              <MenuItem key={choice.id} value={choice.id}>
                {choice.choiceText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    );
  }

  return (
    <>
      <Typography
        sx={{
          height: "24px",
          color: (theme) => theme.palette.error.main,
        }}
        data-formstate={choiceMeta.error ? "error" : ""}
      >
        {choiceMeta.submitFailed && choiceMeta.error}
      </Typography>
      <Grid container spacing={1}>
        {choiceChooser}
      </Grid>
      {(showComment || questionRequiresComment) && (
        <>
          <Typography
            sx={{
              paddingTop: 1,
              paddingBottom: 0.5,
              color: (theme) => theme.palette.text.secondary,
              textAlign: "left",
            }}
            variant="boldBody2"
          >
            COMMENTS
            {" "}
            {Boolean(questionRequiresComment || choiceNecessitatesComment)
              && "REQUIRED"}
          </Typography>
          <TextField
            multiline
            sx={{
              width: "100%",
            }}
            maxRows={5}
            data-formstate={commentaryMeta.error ? "error" : ""}
            error={!!(commentaryMeta.submitFailed && commentaryMeta.error)}
            label={commentaryMeta.submitFailed ? commentaryMeta.error : ""}
            {...commentaryInput}
            variant="outlined"
          />
        </>
      )}
    </>
  );
}
