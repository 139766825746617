import {
  useQueryParam, JsonParam, ArrayParam, StringParam,
} from "use-query-params";
import { useQuery, useQueryClient } from "react-query";
import { useEffect, useState } from "react";
import { getSession, createSession } from "@/api/Process";

export default function useTrackerTable(processId) {
  const [filter, setFilter] = useQueryParam("filter", JsonParam);
  const [sort, setSort] = useQueryParam("sort", JsonParam);
  const [visibility, setVisibility] = useQueryParam("visibility", JsonParam);
  const [order, setOrder] = useQueryParam("order", ArrayParam);

  const [maskFilter, setMaskFilter] = useState(null);
  const [maskSort, setMaskSort] = useState(null);
  const [maskVisibility, setMaskVisibility] = useState(null);
  const [maskOrder, setMaskOrder] = useState(null);

  // save session id in query
  const [sessionId, setSessionId] = useQueryParam("sessionId", StringParam);
  // if session id, get session
  const {
    data: session,
    isLoading,
  } = useQuery(
    ["session", sessionId],
    () => getSession(processId, sessionId),
    {
      enabled: !!sessionId,
    },
  );
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLoading) return;
    // on mask update, update current session until saved
    queryClient.setQueryData(["session", sessionId], (oldData) => ({
      columnFilters: maskFilter || oldData?.columnFilters || [],
      sorting: maskSort || oldData?.sorting || [],
      columnVisibility: maskVisibility || oldData?.columnVisibility || {},
      columnOrder: maskOrder || oldData?.columnOrder || [],
    }));

    // create session if current session differs from local state
    const sesh = queryClient.getQueryData(["session", sessionId]);
    const sessionBody = {
      columnFilters: maskFilter || sesh?.columnFilters || [],
      sorting: maskSort || sesh?.sorting || [],
      columnVisibility: maskVisibility || sesh?.columnVisibility || {},
      columnOrder: maskOrder || sesh?.columnOrder || [],
    };

    createSession(processId, sessionBody)
      .then((result) => {
        // update result id
        setSessionId(result?.id);
      });
  }, [
    isLoading,
    maskFilter,
    maskSort,
    maskVisibility,
    maskOrder,
    processId,
    setSessionId,
    queryClient,
    sessionId,
  ]);

  // if existing query params, set local state and clear query params
  useEffect(() => {
    if (filter?.length) {
      setMaskFilter(filter);
      setFilter(null);
    }
    if (sort?.length) {
      setMaskSort(sort);
      setSort(null);
    }
    if (visibility !== null) {
      setMaskVisibility(visibility);
      setVisibility(null);
    }
    if (order?.length) {
      setMaskOrder(order);
      setOrder(null);
    }
  }, [
    filter, sort, visibility, order,
    setFilter, setSort, setVisibility, setOrder,
  ]);

  return {
    filter: session?.columnFilters || maskFilter || [],
    sort: session?.sorting || maskSort || [],
    visibility: session?.columnVisibility || maskVisibility || {},
    order: session?.columnOrder || maskOrder || [],
    setFilter: setMaskFilter,
    setSort: setMaskSort,
    setVisibility: setMaskVisibility,
    setOrder: setMaskOrder,
  };
}
