import React, { useState, useMemo } from "react";
import {
  Box,
  Chip,
  Typography,
  Button,
  Paper,
  Breadcrumbs,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TableRow,
  Card,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/system";
import { useInfiniteQuery } from "react-query";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import Page from "@/ui/molecules/Page";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { formatPercentage } from "@/utils/numberFormat";
import {
  ColumnDef,
  getCoreRowModel,
  SortingState,
  ColumnFiltersState,
  useReactTable,
} from "@tanstack/react-table";
import { getMetricsActivityFeed, downloadFile } from "@/api/Metrics";

import {
  mapSorting,
  mapFilters,
  getNextPageParam,
  VirtualTableContainer,
  VirtualTableBody,
  depageData,
} from "@/components/InfiniteTable";
import { StickyTable, StickyTableHead } from "@/components/Table/StickyTable";
import { Cell as HeaderCell, Toolbar as TableToolbar } from "@/components/Table/Header";
import {
  UserCell, CompanyCell, TextCell, RelativeDateCell,
} from "@/components/Table/Primitives";
import KeyMetrics from "./KeyMetrics";
import TaskDiff from "./TaskDiff";
import Commentary from "./Commentary";

const TaskAction = styled("span")(({ theme }) => ({
  padding: theme.spacing(0.5, 1),
  borderRadius: theme.shape.borderRadius,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
  lineHeight: theme.typography.body2.lineHeight,
}));

const TaskActionSuccess = styled(TaskAction)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  filter: "saturate(0.7) brightness(1.2)",
}));

const TaskActionInfo = styled(TaskAction)(({ theme }) => ({
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
  filter: "saturate(0.7) brightness(1.2)",
}));

const TaskActionError = styled(TaskAction)(({ theme }) => ({
  backgroundColor: theme.palette.error.main,
  color: theme.palette.error.contrastText,
  filter: "saturate(0.7) brightness(1.2)",
}));

export default function ActivityFeed() {
  const limit = 50;
  const [sorting, setSorting] = useState<SortingState>([{ id: "updatedAt", desc: true }]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string | null>("");
  const [columnVisibility, setColumnVisibility] = useState({});

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status: queryStatus,
  } = useInfiniteQuery({
    queryKey: ["metrics", "activityFeed", sorting, columnFilters, globalFilter],
    queryFn: ({ pageParam = null }) => getMetricsActivityFeed(
      limit,
      pageParam,
      mapSorting(sorting),
      mapFilters(columnFilters, globalFilter),
    ),
    getNextPageParam,
  });

  const isLoading = queryStatus === "loading";
  const isError = queryStatus === "error";

  const [activeItem, setActiveItem] = useState(null);
  const [detailsOpen, setDetailsOpen] = useState(false);

  const columns = useMemo<ColumnDef>(() => [
    {
      header: "Assigned User",
      id: "assignedUser.fullName",
      accessorFn: (row) => (
        row.assignedUser?.firstName
          ? row.assignedUser
          : "Unassigned"
      ),
      cell: (info) => {
        if (info.getValue() === "Unassigned") {
          return "Unassigned";
        }
        return (
          <UserCell value={info.getValue()} />
        );
      },
    },
    {
      header: "Organization",
      accessorKey: "organization",
      id: "organization.name",
      minSize: 200,
      cell: (info) => {
        const co = info.getValue();
        return (
          <>
            <CompanyCell value={co} />
            {co.sensitiveCompany && (
            <Chip
              variant="filled"
              size="small"
              color="warning"
              label="Sensitive Company"
            />
            )}
          </>
        );
      },

    },
    {
      header: "Action",
      enableSorting: false,
      enableColumnFilter: false,
      accessorFn: (row) => {
        const actionMap = {
          IN_PROGRESS: "started",
          SUCCESS: "completed",
          BROKEN_FILE: "broken file",
          NO_DATA_OR_METRIC_NOT_FOUND: "no data",
          READY: "created",
        };
        const actionValue = [...new Set(row.tasks.map((task) => task.status))][0];
        return actionValue ? actionMap[actionValue] : "";
      },
      cell: (info) => (
        <>
          {info.getValue() === "completed" && <TaskActionSuccess>completed</TaskActionSuccess>}
          {info.getValue() === "started" && <TaskActionInfo>started</TaskActionInfo>}
          {info.getValue() === "broken file" && <TaskActionError>broken file</TaskActionError>}
          {info.getValue() === "no data" && <TaskActionError>no data</TaskActionError>}
          {info.getValue() === "created" && <TaskActionInfo>created</TaskActionInfo>}
        </>
      ),
    },
    {
      header: "File Name",
      accessorKey: "filename",
      cell: (info) => (
        <TextCell value={info.getValue()} />
      ),
    },
    {
      header: "Updated At",
      id: "updatedAt",
      accessorFn: (row) => row.tasks.reduce((acc, task) => (
        dayjs(acc).isAfter(dayjs(task.updatedAt)) ? acc : task.updatedAt
      ), 0),
      enableColumnFilter: false,
      meta: {
        sortType: "date",
      },
      cell: (info) => (
        <RelativeDateCell value={info.getValue()} />
      ),
    },
    {
      header: "Commentary",
      id: "commentary",
      accessorFn: (row) => {
        const commentedTask = row.tasks.find((x) => x.commentary || x.taskStatusDetails);
        return commentedTask?.commentary || commentedTask?.taskStatusDetails;
      },
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => (
        <TextCell value={info.getValue()} />
      ),
    },
    {
      header: "Metrics Added",
      accessorKey: "metricCount",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => (
        <Typography
          sx={(theme) => ({
            fontWeight: (
              info.getValue() > 0
                ? theme.typography.fontWeightBold
                : theme.typography.fontWeightRegular
            ),
          })}
          variant="body2"
        >
          {info.getValue() > 0 ? info.getValue() : "-"}
        </Typography>
      ),
    },
    {
      header: "Audit",
      accessorKey: "audit",
      enableColumnFilter: false,
      enableSorting: false,
      cell: (info) => {
        const audit = info.getValue();
        let render = null;
        if (!audit) { render = null; } else if (audit.status !== "SUCCESS") {
          render = <Typography variant="body2">Error in Audit</Typography>;
        } else {
          const {
            avgAbsoluteValue, avgExactValue, avgOffByOne, avgThousandTruncation,
          } = audit;
          render = (
            <>
              <Typography variant="body2">
                Absolute Value:
                {" "}
                { formatPercentage(avgAbsoluteValue)}
              </Typography>
              <Typography variant="body2">
                Exact Value:
                {" "}
                { formatPercentage(avgExactValue)}
              </Typography>
              <Typography variant="body2">
                Off By One:
                {" "}
                { formatPercentage(avgOffByOne)}
              </Typography>
              <Typography variant="body2">
                Thousand Truncation:
                {" "}
                { formatPercentage(avgThousandTruncation)}
              </Typography>
            </>
          );
        }
        return (
          <Box sx={{ width: 200, overflow: "hidden" }}>
            {render}
          </Box>
        );
      },
      minSize: 200,
    },
    {
      header: "",
      id: "viewDetails",
      placeholderId: "viewDetailsPlaceholder",
      accessorFn: (row) => row,
      enableSorting: false,
      enableColumnFilter: false,
      cell: (info) => (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          startIcon={<VisibilityIcon />}
          onClick={() => {
            setActiveItem({
              item: info.getValue(),
            });
            setDetailsOpen(true);
          }}
        >
          Details
        </Button>
      ),
    },
  ], []);

  const page = useMemo(() => depageData(data), [data]);

  const taskIds = activeItem?.item.tasks.map((task) => task.taskId);
  const keyMetrics = [...new Set(activeItem?.item.tasks.map((x) => x.name))].toSorted();
  const status = activeItem?.item.tasks.map((task) => task.status)[0];

  const table = useReactTable({
    data: page,
    columns,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
    },
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isError) {
    return (
      <Page>
        <Breadcrumbs>
          <Link
            component={RouterLink}
            color="inherit"
            to="/metric-tasks"
          >
            Metric Tasks
          </Link>
          <Typography>Activity Feed</Typography>
        </Breadcrumbs>
        <Page.Title>Activity Feed</Page.Title>
        <Page.Content>
          <Paper sx={{ padding: 2 }}>
            <Box>
              <Typography variant="body1">Error loading activity feed.</Typography>
            </Box>
          </Paper>
        </Page.Content>
      </Page>
    );
  }

  return (
    <Page>
      <Breadcrumbs>
        <Link
          color="inherit"
          href="/metric-tasks"
        >
          Metric Tasks
        </Link>
        <Typography>Activity Feed</Typography>
      </Breadcrumbs>
      <Page.Content>
        <Card
          sx={{
            maxHeight: "calc(100vh - 200px)",
            overflow: "hidden",
          }}
        >
          {(isLoading || isFetchingNextPage) && (
            <CenteredProgress
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            />
          )}

          <Box
            padding={1}
          >
            <Typography variant="h3">Activity Feed</Typography>
          </Box>

          <TableToolbar
            columnHeaders={columns}
            globalFilter={globalFilter}
            columnVisibility={columnVisibility}
            sorting={sorting}
            columnFilters={columnFilters}
            onRemoveColumnFilters={() => {
              setColumnFilters([]);
            }}
            onRemoveSorting={() => {
              setSorting([]);
            }}
            onRemoveVisibility={() => {
              setColumnVisibility({});
            }}
            onGlobalFilterChange={(value) => {
              setGlobalFilter(value);
            }}
          />

          <VirtualTableContainer
            onScrollBottomReached={() => {
              if (!isFetching && hasNextPage) {
                fetchNextPage?.();
              }
            }}
          >
            <StickyTable
              centerTotalSize={table.getCenterTotalSize()}
            >
              <StickyTableHead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <HeaderCell
                        key={header.id}
                        header={header}
                        column={header.column}
                        table={table}
                      />
                    ))}
                  </TableRow>
                ))}
              </StickyTableHead>
              <VirtualTableBody
                rows={table.getRowModel().rows}
                estimateSize={100}
                table={table}
              />
            </StickyTable>
          </VirtualTableContainer>
        </Card>

        <Dialog
          open={detailsOpen}
          onClose={() => {
            setDetailsOpen(false);
          }}
          maxWidth="lg"
          fullWidth
        >
          <DialogTitle>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                gap={1}
              >
                {activeItem?.item.filename}

                {status === "SUCCESS" && <TaskActionSuccess>completed</TaskActionSuccess>}
                {status === "IN_PROGRESS" && <TaskActionInfo>started</TaskActionInfo>}
                {status === "BROKEN_FILE" && <TaskActionError>broken file</TaskActionError>}
                {status === "NO_DATA_OR_METRIC_NOT_FOUND" && <TaskActionError>broken file</TaskActionError>}
                {status === "READY" && <TaskActionInfo>created</TaskActionInfo>}
              </Box>

              <IconButton
                size="small"
                onClick={() => {
                  setDetailsOpen(false);
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            {activeItem && (
              <Box>
                {status === "SUCCESS" && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <KeyMetrics
                      keyMetrics={keyMetrics}
                    />
                    <Commentary
                      taskIds={taskIds}
                    />
                    <TaskDiff
                      taskIds={taskIds}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        downloadFile(taskIds);
                      }}
                    >
                      Download File
                    </Button>
                  </Box>
                )}
                {status !== "SUCCESS" && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    gap={1}
                  >
                    <KeyMetrics
                      keyMetrics={keyMetrics}
                    />
                    <Commentary
                      taskIds={taskIds}
                    />
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        downloadFile(taskIds);
                      }}
                    >
                      Download File
                    </Button>
                  </Box>
                )}
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Page.Content>
    </Page>
  );
}
