import { paths } from "@/api/index";
import axios from "axios";

type CloseTask = paths["/api/tasks/close/{event_id}"]["post"];
export type CloseTaskResponse =
  CloseTask["responses"]["200"]["content"]["application/json"];
export const closeTask = async (
  eventId: string
): Promise<CloseTaskResponse> => {
  const response = await axios.post<CloseTaskResponse>(
    `/api/tasks/close/${eventId}`
  );
  return response.data;
};

type GetEvents = paths["/api/tasks/events"]["get"];
export type GetEventsResponse =
  GetEvents["responses"]["200"]["content"]["application/json"];
export type TaskEnum = GetEventsResponse[0]["taskType"];

export const getEvents = async (
  userId: string | null = null
): Promise<GetEventsResponse> => {
  try {
    const response = await axios.get<GetEventsResponse>("/api/tasks/events", {
      params: userId ? { userId } : undefined,
    });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

type GetEventCount = paths["/api/tasks/events/count"]["get"];
export type GetEventCountResponse =
  GetEventCount["responses"]["200"]["content"]["application/json"];

export const getEventCount = async (
  userId: string
): Promise<GetEventCountResponse> => {
  try {
    const response = await axios.get<GetEventCountResponse>(
      "/api/tasks/events/count",
      {
        params: { userId },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  getEvents,
  closeTask,
  getEventCount,
};
