import axios from "axios";
import { searchParams } from "@/utils/general";

export const getSharepointFiles = async (valorId) => {
  const response = await axios.get(`/api/sharepoint-files/all/${valorId}`);
  return response.data;
};

export const getDocNotesMap = async () => {
  try {
    const response = await axios.get("/api/sharepoint-files/doc-notes-map");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateDocNoteType = (fileId, docType, noteType) => {
  const params = {
    doc_type: docType,
    note_type: noteType,
  };

  try {
    const response = axios.put(`/api/sharepoint-files/doc-note-type/${fileId}?${searchParams(params)}`);
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  getSharepointFiles, getDocNotesMap, updateDocNoteType,
};
