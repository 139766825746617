export class MapStep {
  // Create new instances of the same class as static attributes
  static Plan = new MapStep("plan");

  static Collect = new MapStep("collect");

  static Map = new MapStep("map");

  constructor(name) {
    this.name = name;
  }
}
export default MapStep;
