import axios from "axios";
import { buildURLParameters } from "@/utils/general";

import { paths, components } from "./index";

export const searchPersons = async (
  currentValorId: string,
  q: string,
  fields: string[] = [],
  firstName: string = null,
) => {
  const params = buildURLParameters({
    currentValorId, q, fields, firstName,
  });
  const response = await axios.get(`/api/people?${params}`);
  return response.data;
};

export type GetPerson = paths["/api/people/{valorPersonId}"]["get"]["responses"]["200"]["content"]["application/json"];
export type Experience = components["schemas"]["Experience"]
export const getPerson = async (valorPersonId: string): Promise<GetPerson> => {
  const response = await axios.get(`/api/people/${valorPersonId}`);
  return response.data;
};

type UpdatePerson = paths["/api/people/{valorPersonId}"]["patch"]
type PatchPersonBody = UpdatePerson["requestBody"]["content"]["application/json"]

export const patchPerson = async (
  valorPersonId: string,
  body: PatchPersonBody,
) => {
  const response = await axios.patch(`/api/people/${valorPersonId}`, body);
  return response.data;
};

type createPerson = paths["/api/people"]["post"]
type CreatePersonBody = createPerson["requestBody"]["content"]["application/json"]

export const createPerson = async (
  body: CreatePersonBody,
) => {
  const response = await axios.post("/api/people", body);
  return response.data;
};

type UpdateExperience = paths["/api/people/{valorPersonId}/experience"]["patch"]
type PatchExperienceBody = UpdateExperience["requestBody"]["content"]["application/json"]

export const patchExperience = async (
  valorPersonId: string,
  experienceId: string,
  body: PatchExperienceBody,
) => {
  const response = await axios.patch(`/api/people/${valorPersonId}/experience/${experienceId}`, body);
  return response.data;
};

type PostExperience = paths["/api/people/{valorPersonId}/experience"]["post"]
type PostExperienceBody = PostExperience["requestBody"]["content"]["application/json"]
export const postExperience = async (
  valorPersonId: string,
  body: PostExperienceBody,
) => {
  const response = await axios.post(`/api/people/${valorPersonId}/experience`, body);
  return response.data;
};

export const deleteExperience = async (
  valorPersonId: string,
  experienceId: string,
) => {
  const response = await axios.delete(`/api/people/${valorPersonId}/experience/${experienceId}`);
  return response.data;
};

export const deletePerson = async (
  valorPersonId: string,
) => {
  const response = await axios.delete(
    `/api/people/${valorPersonId}`,
  );
  return response.data;
};

export default {
  getPerson,
  deletePerson,
  patchPerson,
  patchExperience,
  postExperience,
  deleteExperience,
  searchPersons,
};
