import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box, Slider, Typography, Theme, createStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: "90%",
  },
  title: {
    width: "100%",
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "12px",
    textAlign: "left",
  },
}));

interface Props {
  max: number;
  title: string;
  step?: number;
  min: number;
  valueLabelFormat(val: number): string | number;
  onSlide(val: number[]): void;
  scale?(x: number): number;
  descale?(x: number): number;
  initialValue?: number[];
}

export default function DualSliderFilter(props: Props) {
  const {
    max,
    title,
    min,
    valueLabelFormat,
    onSlide,
    step,
    scale = (x) => x,
    descale = (x) => x,
    initialValue,
  } = props;
  const [initialMin, initialMax] = initialValue.map(descale);
  const classes = useStyles();
  const [value, setValue] = useState<number[]>([initialMin, initialMax] || [min, max]);

  useEffect(() => {
    setValue(initialValue.map(descale));
  }, [initialValue, descale]);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
  };
  const handleChangeCommitted = (event, newValue) => {
    event.preventDefault();
    onSlide(newValue.map((x) => scale(x)));
  };
  return (
    <Box
      className={classes.root}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography className={classes.title}>{title}</Typography>
      <Slider
        valueLabelFormat={valueLabelFormat}
        value={value}
        max={max}
        min={min}
        scale={scale}
        step={step}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
      />
      <Box height={24} display="flex" justifyContent="space-between" width="100%">
        <Typography>
          {value[0] !== min && valueLabelFormat(scale(value[0]))}
          {value[0] === min && value[1] !== max && "Min"}
        </Typography>
        <Typography>
          {value[1] !== max && valueLabelFormat(scale(value[1]))}
          {value[1] === max && value[0] !== min && "Max"}
        </Typography>
      </Box>
    </Box>
  );
}

DualSliderFilter.propTypes = {
  max: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  step: PropTypes.number,
  min: PropTypes.number.isRequired,
  valueLabelFormat: PropTypes.func.isRequired,
  onSlide: PropTypes.func.isRequired,
  scale: PropTypes.func,
  descale: PropTypes.func,
  initialValue: PropTypes.arrayOf(PropTypes.number),
};

DualSliderFilter.defaultProps = {
  scale: (x) => x,
  descale: (x) => x,
  step: null,
  initialValue: [],
};
