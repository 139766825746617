import React from "react";
import {
  Box, Chip,
} from "@mui/material";
import UserAvatar from "@/ui/atoms/UserAvatar";

type UserValue = {
  firstName: string;
  lastName: string;
  profilePicture: string;
};

export default function UserCell({
  value,
  children,
}: {
  value: UserValue
  children?: React.ReactNode
}) {
  if (!value || typeof value !== "object") return <>-</>;

  const fullName = `${value.firstName} ${value.lastName}`;

  const avatarUser = {
    name: fullName,
    profilePicture: value.profilePicture,
  };

  return (
    <Box
      display="flex"
      alignItems="flex-start"
      gap={0.5}
      sx={{ flexDirection: "column" }}
      overflow="hidden"
      whiteSpace="nowrap"
      minWidth="140px"
      height="100%"
      justifyContent="center"
    >
      <Chip
        avatar={(
          <UserAvatar
            user={avatarUser}
            style={{
              width: "25px",
              height: "25px",
              fontSize: "1em",
            }}
          />
        )}
        label={fullName}
        sx={{ height: "25px" }}
      />

      {children ? (
        <Box display="flex" flexDirection="column">
          {children}
        </Box>
      ) : null}
    </Box>
  );
}
