import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import { components } from "@/api/index";
import { getOrganizationByDomains } from "@/api/Organization";

import SearchDialog from "./SearchDialog";
import AddDialog from "./AddDialog";

type SmallOrganization = components["schemas"]["SmallOrganization"];
type MatchingOrgsByDomain = components["schemas"]["MatchingOrgsByDomain"];
type MatchingOrgsByDomainResponse = components["schemas"]["MatchingOrgsByDomainResponse"];

export default function BulkAdd({
  onOrgSelect,
}: {
  onOrgSelect: (org: SmallOrganization) => void,
}) {
  const [searchOpen, setSearchOpen] = useState(false);
  const [isSearching, setSearching] = useState(false);
  const [addOpen, setAddOpen] = useState(false);

  const [addResults, setAddResults] = useState<MatchingOrgsByDomain[]>([]);
  const [selectedOrgs, setSelectedOrgs] = useState<string[]>([]);

  async function doSearch(query: string) {
    const domains = query
      .split(/[(\r?\n),]/gm)
      .map((x) => x.trim())
      .filter((x) => x.length);

    setSearching(true);
    const result: MatchingOrgsByDomainResponse = await getOrganizationByDomains(domains);
    setSearching(false);

    // add in empty slots where there are none
    const finalResults = result.domains.map((d1) => (
      result.matches.find((match: MatchingOrgsByDomain) => (d1 === match.domain))
      || {
        domain: d1,
        orgs: [],
      }
    ));
    setAddResults(finalResults);

    setSearchOpen(false);
    setAddOpen(true);
  }

  function addOrganization(org: SmallOrganization) {
    setSelectedOrgs([...selectedOrgs, org.valorId]);

    onOrgSelect(org);
  }

  function startBulkAddDialog() {
    setSearchOpen(true);
    setSearching(false);
    setAddOpen(false);
    setSelectedOrgs([]);
  }

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={() => startBulkAddDialog()}
      >
        Bulk Add
      </Button>
      <SearchDialog
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        onSubmit={(q) => doSearch(q)}
        loading={isSearching}
      />
      <AddDialog
        added={selectedOrgs}
        results={addResults}
        open={addOpen}
        onClose={() => setAddOpen(false)}
        onAdd={(org) => addOrganization(org)}
      />
    </Box>
  );
}
