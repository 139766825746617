/* eslint-disable react/require-default-props, react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import {
  useTable,
  useSortBy,
  useBlockLayout,
  useFilters,
  useGlobalFilter,
  usePagination,
  useAsyncDebounce,
  useRowSelect,
} from "react-table";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  Toolbar,
  InputAdornment,
  Input,
  InputLabel,
  lighten,
  Checkbox,
  makeStyles,
  useTheme,
} from "@material-ui/core";
import {
  NavigateNext,
  NavigateBefore,
  LastPageOutlined,
  FirstPageOutlined,
  FilterList,
  Delete,
  ArrowDownward,
  ArrowUpward,
  Search,
} from "@material-ui/icons";
import clsx from "clsx";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { isValid, isWithinInterval } from "date-fns";
import { useSticky } from "react-table-sticky";
import SingleSelect from "@/ui/atoms/SingleSelect";

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.primary.main,
    backgroundColor: lighten(theme.palette.primary.light, 0.85),
  },
  title: {
    flex: "1 1 100%",
  },
}));

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((filterValue) => {
    setGlobalFilter(filterValue || undefined);
  }, 200);

  return (
    <Box marginRight={2} style={{ flow: "right" }}>
      <InputLabel htmlFor="search-with-icon-adornment">Search</InputLabel>
      <Input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        id="search-with-icon-adornment"
        startAdornment={(
          <InputAdornment position="start">
            <Search />
          </InputAdornment>
        )}
      />
    </Box>
  );
}

// Define a default UI for filtering
function DefaultColumnFilter({ column: { filterValue, setFilter } }) {
  return (
    <Input
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      id="input-with-icon-adornment"
      startAdornment={(
        <InputAdornment position="start">
          <FilterList />
        </InputAdornment>
        )}
    />
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    width: "100%",
  },
  tableContainer: {
    overflowX: "auto !important",
    "& [data-sticky-td]": {
      backgroundColor: theme.palette.background.paper,
    },
    "& td[data-sticky-td]": {
      padding: 0,
      "& > div": {
        height: "75px",
        boxShadow: "0 0 5px rgba(0,0,0,0.75)",
        clipPath: "inset(0px -5px 0px 0px)",
      },
    },
  },
  cell: ({ selectable }) => ({
    padding: selectable ? 0 : 8,
  }),
  headerCell: ({ selectable }) => ({
    padding: selectable ? 0 : 8,
    fontWeight: "bold",
    verticalAlign: "top",
    whiteSpace: "nowrap",
  }),
}));

export default function CustomTable({
  columns,
  data,
  selectable,
  skipPageReset,
  title,
  titleStyle,
  displayBlock = false,
  defaultFilters = [],
  defaultSortBy = [],
  pageSize = 10,
  headerBackgroundColor,
  headerTextColor,
  autoResetFilters = true,
  autoResetSortBy = true,
  separatorIndexes = new Set(),
}) {
  const classes = useStyles({ selectable });
  const [hoveringOnId, setHoveringOnId] = useState("");
  const theme = useTheme();

  const setTextColor = headerTextColor || theme.palette.text.primary;
  const setBackgroundColor = headerBackgroundColor || theme.palette.background.paper;

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => rows.filter((row) => {
        const rowValue = row.values[id];
        return rowValue !== undefined
          ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
          : true;
      }),
      multiSelect: (rows, id, filterValue) => {
        const values = new Set(filterValue);
        return rows.filter((row) => values.has(row.values[id]));
      },
      date: (rows, id, filterValue) => (filterValue ? rows.filter((row) => filterValue.isSame(dayjs(row.values[id]), "day")) : rows),
      dateRange: (rows, id, filterValue) => (
        filterValue.startDate
          ? rows.filter((row) => (
            isValid(row.values[id])
            && isWithinInterval(
              row.values[id],
              { start: filterValue.startDate, end: filterValue.endDate || new Date(3000, 1, 1) },
            )))
          : rows
      ),
      jsonArraySearch(rows, id, filterValue) {
        if (!filterValue) {
          return true;
        }
        return rows.filter((row) => {
          const rowValues = row.values[id]?.flatMap((val) => Object.values(val));
          return rowValues !== undefined
            ? rowValues.some((val) => val.toLowerCase().includes(filterValue.toLowerCase()))
            : false;
        });
      },
    }),
    [],
  );

  const sortTypes = React.useMemo(
    () => ({
      date: (rowA, rowB, columnId, desc) => {
        const a = rowA.values[columnId];
        const b = rowB.values[columnId];
        if (a === null) {
          // we always want the nulls to be at the bottom
          return desc ? -1 : 1;
        }
        if (b === null) {
          return desc ? 1 : -1;
        }
        return a?.getTime() > b?.getTime() ? 1 : -1;
      },
    }),
    [],
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    [],
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Checkbox color="primary" ref={resolvedRef} {...rest} />;
    },
  );
  IndeterminateCheckbox.displayName = "IndeterminateCheckbox";

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    allColumns,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      filterTypes,
      sortTypes,
      initialState: {
        filters: defaultFilters,
        sortBy: defaultSortBy,
        pageSize,
      },
      // use the skipPageReset option to disable page resetting temporarily
      autoResetPage: !skipPageReset,
      autoResetGlobalFilter: false,
      autoResetFilters,
      autoResetSortBy,
    },
    useFilters, // useFilters!
    // Dirty hack to conditionally pass the useBlockLayout hook
    displayBlock ? useBlockLayout : useGlobalFilter,
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (selectable) {
        hooks.visibleColumns.push((visibleColumns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            ),
          },
          ...visibleColumns,
        ]);
      }
    },
    useSticky,
  );

  const renderPaginationDetails = () => (
    <div style={{ textAlign: "right" }}>
      Rows per page:
      <SingleSelect
        width={48}
        variant="standard"
        id="rows-per-page"
        onChange={setPageSize}
        options={[10, 25, 50, 100]}
        value={state.pageSize}
      />
      <IconButton onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
        <FirstPageOutlined />
      </IconButton>
      <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
        <NavigateBefore />
      </IconButton>
      <span>
        &nbsp;
        {state.pageIndex + 1}
        &nbsp; of &nbsp;
        {pageOptions.length}
        &nbsp;
      </span>
      <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
        <NavigateNext />
      </IconButton>
      <IconButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <LastPageOutlined />
      </IconButton>
    </div>
  );

  const toolbarClasses = useToolbarStyles();
  const enhancedTableToolbar = (numSelected, tableTitle) => (
    <Toolbar
      className={clsx(toolbarClasses.root, {
        [toolbarClasses.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={toolbarClasses.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <Tooltip title="Delete">
            <IconButton aria-label="delete">
              <Delete />
            </IconButton>
          </Tooltip>
          {numSelected}
          {" "}
          rows selected
        </Typography>
      ) : (
        <Typography
          className={toolbarClasses.title}
          variant="h2"
          id="tableTitle"
          component="div"
          style={titleStyle}
        >
          {tableTitle}
        </Typography>
      )}

      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
    </Toolbar>
  );

  // Render the UI for your table
  return (
    <Box
      className={classes.root}
      boxShadow={1}
      borderRadius="10px"
      bgcolor="background.paper"
    >
      {enhancedTableToolbar(selectedFlatRows.length, title)}
      <div className={classes.tableContainer}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Table {...getTableProps()}>
          <TableHead style={{ backgroundColor: setBackgroundColor }}>
            <TableRow role="checkbox">
              {allColumns.map((column, index) => {
                const headerProps = column.getHeaderProps(
                  column.getSortByToggleProps(),
                );
                return (
                  <TableCell
                    key={`head-cell-${index}`}
                    id={`head-cell-${index}`}
                    classes={{ root: classes.headerCell }}
                    {...headerProps}
                    onClick={() => {}}
                    // style={{ border: 'thick' }}
                  >
                    <Box
                      style={{
                        color: setTextColor,
                      }}
                      onClick={headerProps.onClick}
                      onMouseEnter={() => {
                        setHoveringOnId(column.id);
                      }}
                      onMouseLeave={() => {
                        setHoveringOnId("");
                      }}
                    >
                      {column.render("Header")}
                      {column.canSort && (
                        <IconButton
                          style={{
                            padding: 0,
                            visibility:
                              column.isSorted || column.id === hoveringOnId
                                ? "visible"
                                : "hidden",
                            color: column.isSorted ? "gray" : "#bababa",
                          }}
                        >
                          {column.isSortedDesc ? (
                            <ArrowDownward fontSize="small" />
                          ) : (
                            <ArrowUpward fontSize="small" />
                          )}
                        </IconButton>
                      )}
                    </Box>
                    <div>
                      {column.canFilter && !(column.disableFilter === true) ? column.render("Filter") : null}
                    </div>
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, rowI) => {
              prepareRow(row);
              return (
                <TableRow key={`body-row-${rowI}`} {...row.getRowProps()} style={{ borderTop: (separatorIndexes.has(rowI) ? "solid gray" : "") }}>
                  {row.cells.map((cell, index) => (
                    <TableCell
                      key={`body-cell-${rowI}-${index}`}
                      {...cell.getCellProps()}
                      classes={{ root: classes.cell }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {renderPaginationDetails()}
    </Box>
  );
}

CustomTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array,
  selectable: PropTypes.bool,
  title: PropTypes.string,
  titleStyle: PropTypes.object,
  defaultFilters: PropTypes.array,
  defaultSortBy: PropTypes.array,
  pageSize: PropTypes.number,
  displayBlock: PropTypes.bool,
  headerBackgroundColor: PropTypes.string,
  headerTextColor: PropTypes.string,
  autoResetFilters: PropTypes.bool,
};
