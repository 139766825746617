/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from "react";
import dayjs from "dayjs";
import {
  useTable,
  useColumnOrder,
  useSortBy,
  useRowSelect,
  usePagination,
} from "react-table";
import { useSticky } from "react-table-sticky";
import {
  Box,
  IconButton,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import {
  NavigateNext,
  NavigateBefore,
  LastPageOutlined,
  FirstPageOutlined,
} from "@material-ui/icons";
import {
  formatTotal,
  formatDollars,
  formatFloat,
  formatPercentage,
  formatBigDollars,
} from "@/utils/numberFormat";
import {
  getYoYGrowth,
  getLastValue,
} from "@/utils/calculateTimeSeriesMetrics";
import SingleSelect from "@/ui/atoms/SingleSelect";
import RemoveButton from "@/ui/atoms/RemoveButton";
import SparkLine from "@/ui/atoms/SparkLine";
import ColumnDrawer from "../ColumnDrawer";
import {
  renderCompanyMark,
  renderGroupHeader,
  renderPct,
  renderTotals,
  renderDollars,
  renderDescription,
} from "./Columns";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "auto",
    width: "100%",
  },
  tableContainer: {
    overflowX: "scroll !important",
    "& [data-sticky-td]": {
      backgroundColor: theme.palette.background.paper,
    },
    "& td[data-sticky-td]": {
      padding: 0,
      "& > div": {
        height: "75px",
        boxShadow: "0 0 5px rgba(0,0,0,0.75)",
        clipPath: "inset(0px -5px 0px 0px)",
      },
    },
  },
  header: {
    height: "64px",
    backgroundColor: theme.palette.background.paper,
  },
  headerTitle: {
    color: theme.palette.text.secondary,
    fontWeight: theme.typography.fontWeightBold,
  },
  headerContent: {
    display: "flex",
    alignItems: "center",
  },
  hoverPopover: {
    pointerEvents: "none",
  },
  paper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "320px",
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    outline: "none",
    boxShadow: theme.shadows[5],
  },
}));

export default function CompetitorTable(props) {
  const {
    data,
    removeCompetitors,
    initialColumns,
    bannedColumns,
    allowPresets,
    openDrawer,
    setDrawerOpen,
    currentValorId,
    navigate,
  } = props;
  const classes = useStyles();

  const [descriptionAnchorEl, setDescriptionAnchorEl] = useState(null);
  const [popoverText, setPopoverText] = useState("");
  const [hoveringOnId, setHoveringOnId] = useState("");

  const openDescriptionPopOver = Boolean(descriptionAnchorEl);

  const onShowDescription = (ref, description) => {
    if (description?.length) {
      setDescriptionAnchorEl(ref.current);
      setPopoverText(description);
      return;
    }
    setDescriptionAnchorEl(null);
    setPopoverText("");
  };

  const hasVisibleChildren = (parentCol) => (
    parentCol.columns.map((col) => col.isVisible).some((x) => x)
  );

  const moveInvisibleToEnd = (columns) => columns.sort((el1, el2) => {
    const el1Vis = hasVisibleChildren(el1);
    const el2Vis = hasVisibleChildren(el2);

    if (el1Vis && !el2Vis) return -1;
    if (!el1Vis && el2Vis) return 1;
    return 0;
  });

  const simpleSort = useMemo(
    () => (rowA, rowB, columnId) => {
      const a = rowA.values[columnId];
      const b = rowB.values[columnId];
      return a > b ? 1 : -1;
    },
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: <div style={{ backgroundColor: "transparent", height: "32px" }} />,
        id: "company",
        sticky: "left",
        columns: [
          {
            Header: "Company",
            accessor: "name",
            id: "name",
            Cell: ({ row }) => {
              const uuidRegex = /^[a-f0-9-]{36}$/;
              const valorId = row?.original?.valorId;
              const isValidValor = uuidRegex.test(valorId);
              return (
                <Box
                  style={{ backgroundColor: "transparent" }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {isValidValor && removeCompetitors && (
                    <RemoveButton
                      onClick={() => removeCompetitors([valorId])}
                      style={{
                        visibility: valorId === currentValorId ? "hidden" : "",
                      }}
                    />
                  )}
                  {renderCompanyMark(
                    row.original,
                    navigate,
                  )}
                </Box>
              );
            },
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Description"),
        id: "description",
        columns: [
          {
            Header: "Description",
            accessor: "description",
            id: "description",
            disableSortBy: true,
            Cell: ({ value }) => renderDescription(value, onShowDescription),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Capital"),
        id: "capital",
        columns: [
          {
            Header: "Total Raised",
            accessor: "totalFundingUsd",
            id: "totalFundingUsd",
            sortType: simpleSort,
            Cell: ({ value }) => (
              <Box width="120px">
                <Typography variant="subtitle1">
                  <strong>{formatBigDollars(value)}</strong>
                </Typography>
              </Box>
            ),
          },
          {
            Header: "Last Round Raised",
            accessor: "lastRoundTotalUsd",
            id: "lastRoundTotalUsd",
            sortType: simpleSort,
            Cell: ({ value, row }) => (
              <Box style={{ width: "200px" }}>
                {value && (
                  <Typography variant="subtitle1">
                    <strong>{formatBigDollars(value)}</strong>
                  </Typography>
                )}
                {row.original.lastRoundTs && (
                  <Typography variant="body2">
                    Last Raise:&nbsp;
                    {dayjs(row.original.lastRoundTs * 1000).format(
                      "MMMM D, YYYY",
                    )}
                  </Typography>
                )}
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("LinkedIn Employees"),
        id: "linkedIn",
        columns: [
          {
            Header: "Employees",
            accessor: (row) => getLastValue(row.employeeTotalHistory),
            id: "employeeTotal",
            sortType: simpleSort,
            Cell: ({ value }) => renderTotals(value),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.employeeTotalHistory),
            id: "employeeTotalYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "employeeTotalHistory",
            id: "employeeTotalHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatTotal}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("LinkedIn Jobs"),
        id: "linkedInJobs",
        columns: [
          {
            Header: "Jobs",
            accessor: (row) => getLastValue(row.jobTotalHistory),
            id: "jobTotal",
            sortType: simpleSort,
            Cell: ({ value }) => renderTotals(value),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.jobTotalHistory),
            id: "jobTotalYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "jobTotalHistory",
            id: "jobTotalHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatTotal}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Web Traffic"),
        id: "webTraffic",
        columns: [
          {
            Header: "Website Traffic",
            accessor: (row) => getLastValue(row.webTrafficHistory),
            id: "webTrafficTotal",
            sortType: simpleSort,
            Cell: ({ value }) => renderTotals(value),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.webTrafficHistory),
            id: "webTrafficYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "webTrafficHistory",
            id: "webTrafficHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatTotal}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Social Traffic"),
        id: "socialTraffic",
        columns: [
          {
            Header: "Social",
            accessor: (row) => getLastValue(row.socialTrafficHistory),
            id: "socialTrafficTotal",
            sortType: simpleSort,
            Cell: ({ value }) => renderTotals(value),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.socialTrafficHistory),
            id: "socialTrafficYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "socialTrafficHistory",
            id: "socialTrafficHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatTotal}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Second Measure Customers"),
        id: "secondMeasureCustomer",
        columns: [
          {
            Header: "Customer % of Pop",
            accessor: (row) => getLastValue(row.customerPctHistory),
            id: "customersAsPctOfPopulation",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "customerPctHistory",
            id: "customerPctHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatPercentage}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
          {
            Header: "Lifetime Customer % of Pop",
            accessor: (row) => getLastValue(row.lifetimeCustomerPctHistory),
            id: "lifetimeCustomersAsPctOfPopulation",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "lifetimeCustomerPctHistory",
            id: "lifetimeCustomerPctHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatPercentage}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
          {
            Header: "New Customers",
            accessor: (row) => getLastValue(row.newCustomersHistory),
            id: "newCustomers",
            isSortedDesc: "newCustomers",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "newCustomersHistory",
            id: "newCustomersHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatPercentage}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Second Measure Growth"),
        id: "secondMeasureGrowth",
        columns: [
          {
            Header: "Observed Sales YoY",
            accessor: (row) => getYoYGrowth(row.observedSalesHistory),
            id: "observedSalesYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "observedSalesHistory",
            id: "observedSalesHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatBigDollars}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
          {
            Header: "Observed Customers YoY",
            accessor: (row) => getYoYGrowth(row.observedCustomersHistory),
            id: "observedCustomersYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "observedCustomersHistory",
            id: "observedCustomersHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatTotal}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
      {
        Header: () => renderGroupHeader("Second Measure Trends"),
        id: "secondMeasureTrends",
        columns: [
          {
            Header: "Observed Transactions YoY",
            accessor: (row) => getYoYGrowth(row.observedTransactionsHistory),
            id: "observedTransactionsYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "observedTransactionsHistory",
            id: "observedTransactionsHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatFloat}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
          {
            Header: "Sales per Customer",
            accessor: (row) => getLastValue(row.salesPerCustomerHistory),
            id: "salesPerCustomer",
            sortType: simpleSort,
            Cell: ({ value }) => renderDollars(value),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.salesPerCustomerHistory),
            id: "salesPerCustomerYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "salesPerCustomerHistory",
            id: "salesPerCustomerHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatDollars}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
          {
            Header: "Transactions per Customer",
            accessor: (row) => getLastValue(row.txnsPerCustomerHistory),
            id: "txnsPerCustomer",
            sortType: simpleSort,
            Cell: ({ value }) => renderTotals(value, 2),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.txnsPerCustomerHistory),
            id: "txnsPerCustomerYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "txnsPerCustomerHistory",
            id: "txnsPerCustomerHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatFloat}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
          {
            Header: "Average Transaction Value",
            accessor: (row) => getLastValue(row.avgTxnValueHistory),
            id: "avgTxnValue",
            sortType: simpleSort,
            Cell: ({ value }) => renderDollars(value),
          },
          {
            Header: "YoY",
            accessor: (row) => getYoYGrowth(row.avgTxnValueHistory),
            id: "avgTxnValueYoyPct",
            sortType: simpleSort,
            Cell: ({ value }) => renderPct(value),
          },
          {
            Header: "Trendline",
            accessor: "avgTxnValueHistory",
            id: "avgTxnValueHistory",
            disableSortBy: true,
            Cell: ({ value }) => (
              <Box>
                <SparkLine
                  dataFormatter={formatBigDollars}
                  data={value}
                  width={144}
                />
              </Box>
            ),
          },
        ],
      },
    ],
    [],
  );

  const {
    getTableProps,
    headerGroups,
    columns: parentColumns,
    setColumnOrder,
    prepareRow,
    toggleHideColumn,
    toggleHideAllColumns,
    page, // Instead of using 'rows',
    // which has only the rows for the active page
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        hiddenColumns: initialColumns.length
          ? columns
            .filter((x) => !initialColumns.includes(x.id))
            .flatMap((cols) => cols.columns)
            .map((y) => y.id)
          : [],
        pageSize: 25,
      },
    },
    useColumnOrder,
    useSortBy,
    usePagination,
    useRowSelect,
    useSticky,
  );

  const [statefulParentColumns, setStatefulParentColumns] = useState(
    moveInvisibleToEnd(parentColumns),
  );

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder(
      statefulParentColumns,
      result.source.index,
      result.destination.index,
    );
    moveInvisibleToEnd(reorderedItems);
    setStatefulParentColumns(reorderedItems);
    const childrenIds = reorderedItems.flatMap((items) => (
      items.columns.flatMap((column) => column.id)
    ));
    setColumnOrder(childrenIds);
  };

  const hideAllColumns = () => {
    toggleHideAllColumns(true);
    toggleHideColumn("name");
  };

  const prepareChecks = (cols) => {
    const parentLabel = {
      capital: "Capital",
      description: "Description",
      linkedIn: "Linkedin",
      socialTraffic: "Social Referrals to Homepage",
      webTraffic: "Web Traffic",
      sourced: "Similarity",
      secondMeasureCustomer: "Second Measure Customer Info",
      secondMeasureTrends: "Second Measure Trends",
    };
    const prepped = cols.reduce((result, col) => {
      const item = {};
      if (bannedColumns.indexOf(col.id) === -1) {
        item.parent = parentLabel[col.id] || "Company Information";
        item.children = col.columns.map((x) => ({
          id: x.id,
          label: x.Header,
          value: x.isVisible,
        }));
        result.push(item);
      }
      return result;
    }, []);

    return prepped.sort((a, b) => (a.parent > b.parent ? 1 : -1));
  };

  const renderHeaders = (headerGroup, index) => {
    // top group
    if (index === 0) {
      return (
        <DragDropContext onDragEnd={onDragEnd} key="0drag">
          <Droppable droppableId="droppable" direction="horizontal">
            {(dropProvided) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                {...dropProvided.droppableProps}
                ref={dropProvided.innerRef}
              >
                {headerGroup.headers.map((column, idx) => (
                  <Draggable
                    key={column.valorId}
                    draggableId={column.id}
                    index={idx}
                  >
                    {(dragProvided) => (
                      <TableCell
                        id={`header-groups-${idx}`}
                        {...column.getHeaderProps()}
                        ref={dragProvided.innerRef}
                        {...dragProvided.draggableProps}
                        {...dragProvided.dragHandleProps}
                        style={{
                          padding: 0,
                          ...dragProvided.draggableProps.style,
                        }}
                      >
                        {column.render("Header")}
                      </TableCell>
                    )}
                  </Draggable>
                ))}
                {dropProvided.placeholder}
              </TableRow>
            )}
          </Droppable>
        </DragDropContext>
      );
    }

    return (
      <TableRow
        {...headerGroup.getHeaderGroupProps()}
        className={classes.header}
      >
        {headerGroup.headers.map((column) => (
          <TableCell
            key={column.id}
            onMouseEnter={() => {
              setHoveringOnId(column.id);
            }}
            onMouseLeave={() => {
              setHoveringOnId("");
            }}
            className={classes.headerTitle}
            {...column.getHeaderProps(column.getSortByToggleProps())}
          >
            <Box className={classes.headerContent}>
              {column.render("Header")}

              {column.canSort && (
                <IconButton
                  style={{
                    padding: 8,
                    visibility: (column.isSorted || column.id === hoveringOnId) ? "visible" : "hidden",
                    color: column.isSorted ? "gray" : "#bababa",
                  }}
                >
                  {column.isSortedDesc ? (
                    <ArrowDownwardIcon fontSize="small" />
                  ) : (
                    <ArrowUpwardIcon fontSize="small" />
                  )}
                </IconButton>
              )}
            </Box>
          </TableCell>
        ))}
      </TableRow>
    );
  };

  const renderPaginationDetails = () => (
    <div style={{ textAlign: "right" }}>
      Rows per page:
      <SingleSelect
        width={48}
        variant="standard"
        id="rows-per-page"
        onChange={setPageSize}
        options={[10, 25, 50, 100]}
        value={pageSize}
      />
      <IconButton onClick={() => gotoPage(0)} disabled={!canNextPage}>
        <FirstPageOutlined />
      </IconButton>
      <IconButton onClick={() => previousPage()} disabled={!canPreviousPage}>
        <NavigateBefore />
      </IconButton>
      <span>
        &nbsp;
        {pageIndex + 1}
        &nbsp; of &nbsp;
        {pageOptions.length}
        &nbsp;
      </span>
      <IconButton onClick={() => nextPage()} disabled={!canNextPage}>
        <NavigateNext />
      </IconButton>
      <IconButton
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
      >
        <LastPageOutlined />
      </IconButton>
    </div>
  );

  return (
    <Box
      className={classes.root}
      boxShadow={1}
      borderRadius="10px"
      bgcolor="background.paper"
    >
      <Popover
        open={openDescriptionPopOver}
        anchorEl={descriptionAnchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        classes={{
          paper: classes.paper,
        }}
        onClose={() => {
          setDescriptionAnchorEl(null);
        }}
      >
        <Typography>{popoverText}</Typography>
      </Popover>
      <ColumnDrawer
        open={openDrawer}
        onClose={() => setDrawerOpen(false)}
        columns={prepareChecks(parentColumns)}
        toggleColumn={(id) => toggleHideColumn(id)}
        toggleAll={hideAllColumns}
        allowPresets={allowPresets}
      />
      <div className={classes.tableContainer}>
        <Table {...getTableProps()} size="small">
          <TableHead>{headerGroups.map(renderHeaders)}</TableHead>
          <TableBody id="competitor-table-body">
            {page.map((row) => {
              prepareRow(row);
              return (
                <TableRow key={row.id} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
      {renderPaginationDetails()}
    </Box>
  );
}
