import axios from "axios";
import { paths } from "@/api/index";
import { searchParamsObject } from "@/utils/general";

type GetSignalFeatures = paths["/api/models/{model_id}/index/{index_id}/signal-features"]["get"];
export type SignalFeatures = GetSignalFeatures["responses"]["200"]["content"]["application/json"];

const getSignalFeatures = async (
  modelId: string,
  indexId: string,
  limit: number,
): Promise<SignalFeatures> => {
  const response = await axios.get(`/api/models/${modelId}/index/${indexId}/signal-features${limit ? `?limit=${limit}` : ""}`);
  return response.data;
};

type getSignalDistribution = paths["/api/models/{model_id}/index/{index_id}/signal-features/{feature_name}"]["get"];
export type SignalDistribution = getSignalDistribution["responses"]["200"]["content"]["application/json"];

const getSignalDistribution = async (
  modelId: string,
  indexId: string,
  columnName: string,
): Promise<SignalDistribution> => {
  const response = await axios.get(`/api/models/${modelId}/index/${indexId}/signal-features/${columnName}`);
  return response.data;
};

type GetSignalCoverage = paths["/api/models/{model_id}/index/{index_id}/signal-coverage"]["get"];
export type SignalCoverage = GetSignalCoverage["responses"]["200"]["content"]["application/json"];
const getSignalCoverage = async (
  modelId: string,
  indexId: string,
): Promise<SignalCoverage> => {
  const response = await axios.get(`/api/models/${modelId}/index/${indexId}/signal-coverage`);
  return response.data;
};

type GetSignalModels = paths["/api/models"]["get"];
export type SignalModels = GetSignalModels["responses"]["200"]["content"]["application/json"];
const getSignalModels = async (filter): Promise<SignalModels> => {
  const params = searchParamsObject({
    filter: filter ? JSON.stringify(filter) : null,
  });
  const response = await axios.get("/api/models", { params });
  return response.data;
};

export {
  getSignalFeatures,
  getSignalDistribution,
  getSignalCoverage,
  getSignalModels,
};
