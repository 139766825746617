export const fundsName = {
  isGrowthFund6: "Fund VI",
  isGrowthFund5: "Fund V",
  isGrowthFund4: "Fund IV",
  isGrowthFund3: "Fund III",
  isVofFund: "VOF",
  isCvConsortioFund: "CV Consortio",
  isSeedFund1: "Seed I",
  isVsvFund1: "VSV I",
  isVsvFund2: "VSV II",
  isRdFund: "R&D",
};
export default fundsName;
