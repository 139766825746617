import React from "react";
import { useQuery } from "react-query";
import { getCoinvestors } from "@/api/Organization";
import { Column } from "@tanstack/react-table";
import { SelectFilter, uniqFlatSort } from "@/components/Table/CustomFilters";

export default function InvestorsFilter({ column, valorId }: {
  column: Column<unknown>;
  valorId: string;
}) {
  const {
    data: options,
  } = useQuery(
    ["coinvestors", valorId],
    () => getCoinvestors(valorId),
    {
      retry: false,
    },
  );

  const allInvestors = "All Investors";

  return (
    <SelectFilter
      getFilterValue={column.getFilterValue}
      setFilterValue={column.setFilterValue}
      getFacetedUniqueValues={column.getFacetedUniqueValues}
      options={options?.data}
      onChange={(event) => {
        const { value } = event.target;
        if (value === allInvestors) {
          column.setFilterValue(undefined);
        } else {
          column.setFilterValue(value);
        }
      }}
      filterOptions={(opts) => [
        allInvestors,
        ...(uniqFlatSort(opts)),
      ]}
    />
  );
}
