import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export const getLastValue = (data, valueAccessor = "value") => {
  if (!data || !data.length) {
    return null;
  }
  return data[data.length - 1][valueAccessor];
};

const calculateGrowth = (first, last) => {
  if (first === null || last === null) {
    return null;
  }
  if (last === 0) {
    return -1;
  }

  const growth = (last - first) / first;
  if (growth === Infinity) {
    return null;
  }
  return growth;
};

const getNMonthGrowth = (data, n, valueAccessor = "value") => {
  if (!data || data?.length === 0) {
    return null;
  }
  const lastIndex = data.length - 1;
  const lastPoint = data[lastIndex];

  const targetDate = dayjs.unix(lastPoint.timestamp).utc().subtract(n, "month");
  const targetDateRange = [
    targetDate.startOf("month").unix(),
    targetDate.endOf("month").unix(),
  ];

  const firstPoint = data.find(
    (val) => val.timestamp >= targetDateRange[0] && val.timestamp <= targetDateRange[1],
  );

  if (firstPoint === undefined) {
    return null;
  }

  return calculateGrowth(
    firstPoint[valueAccessor],
    lastPoint[valueAccessor],
    valueAccessor,
  );
};

export const get3MonthGrowth = (data, valueAccessor = "value") => getNMonthGrowth(data, 3, valueAccessor);

export const getYoYGrowth = (data, valueAccessor = "value") => getNMonthGrowth(data, 12, valueAccessor);

export const getYoyThreeMonthMonthlySmoothedGrowth = (data, valueAccessor = "value") => {
  const getThreeMonthAverage = (baseIndex) => {
    const periodData = data.slice(baseIndex - 2, baseIndex + 1); // 3 months + 1 inclusive

    if (periodData.some((val) => val[valueAccessor] === null)) {
      return null;
    }

    return periodData.reduce((acc, curr) => acc + curr[valueAccessor], 0) / periodData.length;
  };

  const lastIndex = data.length - 1;
  const lastThreeMonthAverage = getThreeMonthAverage(lastIndex);
  const targetThreeMonthAverage = getThreeMonthAverage(lastIndex - 12);
  const growth = calculateGrowth(targetThreeMonthAverage, lastThreeMonthAverage);

  return growth;
};

export default {
  get3MonthGrowth, getLastValue, getYoYGrowth, getYoyThreeMonthMonthlySmoothedGrowth,
};
