/* eslint-disable no-console */
import axios from "axios";
import { paths } from "@/api/index";

const createMap = async (map) => {
  try {
    const response = await axios.post("/api/market-maps/map", map);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const updateMap = async (map) => {
  try {
    const response = await axios.patch(`/api/market-maps/map/${map.id}`, map);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const deleteMap = async (mapId) => {
  try {
    const response = await axios.delete(`/api/market-maps/map/${mapId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createDimension = async (mapId, dimension) => {
  try {
    const response = await axios.post(`/api/market-maps/map/${mapId}/dimension`, dimension);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const patchDimension = async (mapId, dimension) => {
  try {
    const response = await axios.patch(`/api/market-maps/map/${mapId}/dimension/${dimension.id}`, dimension);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const deleteDimension = async (mapId, dimensionId) => {
  try {
    const response = await axios.delete(`/api/market-maps/map/${mapId}/dimension/${dimensionId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createDimensionValue = async (mapId, dimensionId, dimensionValue) => {
  try {
    const response = await axios.post(`/api/market-maps/map/${mapId}/dimension/${dimensionId}/value`, dimensionValue);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const patchDimensionValue = async (mapId, dimensionId, dimensionValue) => {
  try {
    const response = await axios.patch(`/api/market-maps/map/${mapId}/dimension/${dimensionId}/value/${dimensionValue.id}`, dimensionValue);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const deleteDimensionValue = async (mapId, dimensionId, dimensionValueId) => {
  try {
    const response = await axios.delete(`/api/market-maps/map/${mapId}/dimension/${dimensionId}/value/${dimensionValueId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createCellMember = async (mapId, cellMember) => {
  try {
    const response = await axios.post(`/api/market-maps/map/${mapId}/cell-member`, cellMember);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const deleteCellMember = async (mapId, cellMember) => {
  try {
    const response = await axios.delete(`/api/market-maps/map/${mapId}/cell-member`, { data: cellMember });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getCellMembers = async (mapId) => {
  try {
    const response = await axios.get(`/api/market-maps/cell-members/${mapId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

type GetMapsForOrg = paths["/api/market-maps/org/{valor_id}"]["get"]
export type MapsForOrg = GetMapsForOrg["responses"]["200"]["content"]["application/json"];

const queryMapsForOrg = async (valorId: string): Promise<MapsForOrg> => {
  const response = await axios.get<MapsForOrg>(`/api/market-maps/org/${valorId}`);
  return response.data as MapsForOrg;
};

const getMarketMap = async (marketMapId) => {
  try {
    const response = await axios.get(`/api/market-maps/${marketMapId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getAllMarketMaps = async () => {
  try {
    const response = await axios.get("/api/market-maps/all");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const respondToMapSuggestion = async (mapId, suggestion) => {
  try {
    const response = await axios.post(`/api/market-maps/${mapId}/suggestion`, suggestion);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const getAssociatedOrgs = async (mapId) => {
  try {
    const response = await axios.get(`/api/market-maps/${mapId}/orgs`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const addOrgAssociation = async (mapId, valorId) => {
  try {
    const response = await axios.post(`/api/market-maps/map/${mapId}/org/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const deleteOrgAssociation = async (mapId, valorId) => {
  try {
    const response = await axios.delete(`/api/market-maps/map/${mapId}/org/${valorId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

const createExcelReport = async (mapId) => {
  try {
    const response = await axios.get(`/api/market-maps/${mapId}/orgs/xlsx`, {
      responseType: "blob",
    });
    const filename = response.headers["content-disposition"]
      .split(";")
      .find((x) => x.includes("filename"))
      .replace("filename=", "")
      .replace(/"/g, "")
      .trim();

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    return null;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export {
  addOrgAssociation,
  createCellMember,
  createDimension,
  createDimensionValue,
  createExcelReport,
  createMap,
  deleteCellMember,
  deleteDimension,
  deleteDimensionValue,
  deleteMap,
  deleteOrgAssociation,
  getAllMarketMaps,
  getAssociatedOrgs,
  getCellMembers,
  getMarketMap,
  patchDimension,
  patchDimensionValue,
  queryMapsForOrg,
  respondToMapSuggestion,
  updateMap,
};
