import React from "react";
import { useTheme } from "@material-ui/core";
import {
  Box, Typography,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      padding: theme.spacing(0.5, 0),
    },
    title: {
      paddingBottom: theme.spacing(0.5),
      color: theme.palette.text.secondary,
      fontWeight: `${theme.typography.fontWeightBold} !important`,
      textAlign: "left",
    },
  });
});

interface Props {
  title: string;
  children: React.ReactElement;
}

export default function LabeledDetail(props: Props) {
  const { title, children } = props;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography className={classes.title} variant="caption">{title}</Typography>
      {children}
    </Box>
  );
}
