import React from "react";
import {
  Box,
  makeStyles,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import PropTypes from "prop-types";
import CancelIcon from "@material-ui/icons/Cancel";
import CompanyAvatar from "../CompanyAvatar";

const useStyles = makeStyles({
  topRight: {
    position: "absolute",
    top: "-10px",
    right: "-10px",
    fontSize: "18px",
  },
});

export default function RemovableCompany({ handleRemove, company }) {
  const classes = useStyles();
  const {
    name, logoUrl, valorId, domain,
  } = company;

  const showName = name.length < 15;

  return (
    <Tooltip title={showName ? "" : name} placement="bottom">
      <Box p={2.5} alignItems="center" style={{ position: "relative" }}>
        <CompanyAvatar size="75" src={logoUrl} name={name} domain={domain} />
        {showName && (
          <Typography variant="subtitle2" align="center">
            {name}
          </Typography>
        )}
        {handleRemove && (
          <div className={classes.topRight}>
            <IconButton onClick={() => handleRemove(valorId)}>
              <CancelIcon style={{ fill: "#838383" }} />
            </IconButton>
          </div>
        )}
      </Box>
    </Tooltip>
  );
}

RemovableCompany.propTypes = {
  company: PropTypes.object.isRequired,
  handleRemove: PropTypes.func,
};
