import React from "react";
import { Box, Typography } from "@mui/material";
import GrokIcon from "@/ui/atoms/Icons/Grok";

import { useAuth } from "@/hooks/useAuth";
import { useParams, useNavigate } from "react-router-dom";
import Prompt from "./Prompt";
import RecentChats from "./RecentChats";
import Chat from "./Chat";
import Drawer from "./Drawer";
import ChatSearch from "./Search";

export default function Grok() {
  const { user } = useAuth();

  const { section, subsection } = useParams();
  const navigate = useNavigate();

  const [showDrawer, setShowDrawer] = React.useState(false);

  const startChat = (question: string) => {
    navigate("/grok/chat", { state: { question } });
  };

  return (
    <Box
      py={1}
      width="100%"
      height="100%"
      position="relative"
      overflow="hidden"
    >
      <Box
        position="absolute"
        left={0}
        width="100px"
        height="100%"
        onMouseOver={() => setShowDrawer(true)}
      />

      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)} />

      {section === "chat" && (
        <Box py={1} height="100%" width={600} maxWidth="100%" margin="auto">
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            gap={5}
            padding={1}
            height="100%"
          >
            <Chat id={subsection} />
          </Box>
        </Box>
      )}

      {section === "search" && (
        <Box>
          <ChatSearch />
        </Box>
      )}

      {section !== "chat" && section !== "search" && (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          gap={5}
          padding={1}
          width={600}
          maxWidth="100%"
          margin="auto"
          my={1}
        >
          <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
            <GrokIcon />
            <Typography variant="h2">
              Good afternoon,
              {' '}
              {user.firstName}
            </Typography>
          </Box>

          <Box width="100%">
            <Prompt onSubmit={(question) => startChat(question)} />
          </Box>

          <RecentChats />
        </Box>
      )}
    </Box>
  );
}
