import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import { TableRow as MuiTableRow, Box, useTheme } from "@mui/material";
import { useVirtualizer } from "@tanstack/react-virtual";
import {
  flexRender,
  SortingState,
  ColumnFiltersState,
} from "@tanstack/react-table";
import { isObject } from "@/utils/isObjectTypeGuard";

const ParentRefContext = React.createContext<React.RefObject<HTMLDivElement | null>>(null);
const useParentRef = () => {
  const parentRef = useContext(ParentRefContext);
  if (!parentRef) {
    throw new Error("ParentRefContext is null");
  }
  return parentRef;
};

export const TableRow = MuiTableRow;

export function VirtualTableContainer({
  onScrollBottomReached,
  children,
  height,
  minHeight,
  fullScreen = false,
}: {
  onScrollBottomReached?: () => void;
  children: React.ReactNode;
  height?: string;
  minHeight?: string;
  fullScreen?: boolean;
}) {
  const theme = useTheme();

  // let defaultNegativeHeight = 350;
  let defaultNegativeHeight = 550;
  if (theme.breakpoints.values.md < window.innerWidth) {
    // defaultNegativeHeight = 250;
    defaultNegativeHeight = 450;
  }
  if (theme.breakpoints.values.sm < window.innerWidth) {
    // defaultNegativeHeight = 150;
    defaultNegativeHeight = 250;
  }

  const ref = useRef<HTMLDivElement>(null);
  const handleScroll = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollTop, scrollHeight, clientHeight } = containerRefElement;
        if (scrollHeight - scrollTop - clientHeight < defaultNegativeHeight) {
          onScrollBottomReached?.();
        }
      }
    },
    [onScrollBottomReached, defaultNegativeHeight],
  );

  return (
    <ParentRefContext.Provider value={ref}>
      <Box
        ref={ref}
        onScroll={(e) => handleScroll(e.target as HTMLDivElement)}
        sx={{
          position: "relative",
          height: {
            xs: height || "calc(100vh - 450px)",
            sm: height || "calc(100vh - 350px)",
            md: height || "calc(100vh - 450px)",
          },
          minHeight: minHeight || "500px",
          overflow: "auto",
          ...(fullScreen
            ? {
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: theme.zIndex.drawer - 1,
              backgroundColor: theme.palette.background.paper,
            }
            : {}),
        }}
      >
        {children}
      </Box>
    </ParentRefContext.Provider>
  );
}

export function VirtualTableBody({
  rows,
  estimateSize,
  onRowClick,
  onRowDoubleClick,
  table,
}: {
  rows: any[];
  estimateSize: number;
  onRowClick?: (row: any) => void;
  onRowDoubleClick?: (row: any) => void;
  table: any;
}) {
  const tableContainerRef = useParentRef();
  const theme = useTheme();
  const [activeRow, setActiveRow] = useState<number | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  // Touch handling state
  const [currentRow, setCurrentRow] = useState(null);
  const [touchStartTime, setTouchStartTime] = useState<number | null>(null);
  const [touchX, setTouchX] = useState<number | null>(null);
  const [touchY, setTouchY] = useState<number | null>(null);

  const initializeVirtualization = useCallback(() => {
    if (!tableContainerRef.current || !rows.length) return;

    const container = tableContainerRef.current;
    const { height } = container.getBoundingClientRect();

    if (height > 0) {
      setIsInitialized(true);
    }
  }, [tableContainerRef, rows]);

  useEffect(() => {
    if (!tableContainerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      initializeVirtualization();
    });

    resizeObserver.observe(tableContainerRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [tableContainerRef, initializeVirtualization]);

  useEffect(() => {
    initializeVirtualization();
  }, [initializeVirtualization]);

  const rowVirtualizer = useVirtualizer({
    getScrollElement: useCallback(
      () => tableContainerRef?.current,
      [tableContainerRef],
    ),
    count: rows.length,
    estimateSize: useCallback(() => estimateSize, [estimateSize]),
    overscan: 5,
    measureElement:
      typeof window !== "undefined"
      && navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
  });

  const visibleColumns = table.getVisibleLeafColumns();
  const columnVirtualizer = useVirtualizer({
    horizontal: true,
    count: visibleColumns.length - 1,
    getScrollElement: useCallback(
      () => tableContainerRef?.current,
      [tableContainerRef],
    ),
    estimateSize: useCallback(
      (index) => visibleColumns[index + 1].getSize(),
      [visibleColumns],
    ),
    overscan: 3,
  });

  const virtualRows = rowVirtualizer.getVirtualItems();
  const totalSize = rowVirtualizer.getTotalSize() + estimateSize;

  const virtualColumns = columnVirtualizer.getVirtualItems();

  // Calculate padding for virtual columns
  const virtualPaddingLeft = visibleColumns.reduce((acc, curr, index) => {
    if (index !== 0 && index < ((virtualColumns[0]?.index ?? 0) + 1 || 0)) {
      return acc + curr.getSize();
    }
    return acc;
  }, 0);

  const virtualPaddingRight = virtualColumns.length
    ? columnVirtualizer.getTotalSize()
      - (virtualColumns[virtualColumns.length - 1]?.end ?? 0)
    : 0;

  const handleTouchStart = useCallback((e: React.TouchEvent, row: any) => {
    setTouchStartTime(new Date().getTime());
    setTouchX(e.touches[0].clientX);
    setTouchY(e.touches[0].clientY);
    setCurrentRow(row);
  }, []);

  const handleTouchEnd = useCallback(
    (e: React.TouchEvent) => {
      if (!touchX || !touchY || !touchStartTime) return;

      const endTouchX = e.changedTouches[0].clientX;
      const endTouchY = e.changedTouches[0].clientY;
      const deltaX = Math.abs(endTouchX - touchX);
      const deltaY = Math.abs(endTouchY - touchY);

      if (deltaX > 5 || deltaY > 5) {
        setTouchX(null);
        setTouchY(null);
        return;
      }

      const endTime = new Date().getTime();
      if (endTime - touchStartTime < 500) {
        onRowDoubleClick?.(currentRow);
      }
    },
    [currentRow, onRowDoubleClick, touchStartTime, touchX, touchY],
  );

  if (!isInitialized) {
    return (
      <tbody />
    );
  }

  return (
    <tbody
      style={{
        display: "grid",
        height: `${totalSize}px`,
        position: "relative",
      }}
    >
      {virtualRows.map((virtualRow) => {
        const row = rows[virtualRow.index];
        const visibleCells = row.getVisibleCells();

        if (!visibleColumns.length) return null;

        return (
          <tr
            data-index={virtualRow.index}
            ref={(node) => rowVirtualizer.measureElement(node)}
            key={row.id}
            style={{
              display: "flex",
              position: "absolute",
              transform: `translateY(${virtualRow.start}px)`,
              width: "100%",
              height: `${estimateSize}px`,
              maxHeight: `${estimateSize}px`,
              minHeight: `${estimateSize}px`,
              zIndex: activeRow === virtualRow.index ? 1 : 0,
            }}
            onClick={() => onRowClick?.(row)}
            onDoubleClick={() => onRowDoubleClick?.(row)}
            onTouchStart={(e) => handleTouchStart(e, row)}
            onTouchEnd={handleTouchEnd}
            onTouchCancel={handleTouchEnd}
            onMouseOver={() => setActiveRow(virtualRow.index)}
            onMouseOut={() => setActiveRow(null)}
          >
            {/* First column */}
            <td
              style={{
                background:
                  virtualRow.index % 2
                    ? theme.palette.background.oddTableRow
                    : theme.palette.background.paper,
                borderRight: `3px solid ${theme.palette.divider}`,
                borderBottom: `1px solid ${theme.palette.white.dark}`,
                boxSizing: "border-box",
                minWidth: `${visibleColumns[0]?.getSize()}px`,
                width: `${visibleColumns[0]?.getSize()}px`,
                maxWidth: `${visibleColumns[0]?.getSize()}px`,
              }}
            >
              {flexRender(
                visibleColumns[0].columnDef.cell,
                visibleCells[0].getContext(),
              )}
            </td>

            {/* Left padding for virtual columns */}
            {virtualPaddingLeft > 0 && (
              <td
                style={{ display: "flex", width: `${virtualPaddingLeft}px` }}
              />
            )}

            {/* Virtual columns */}
            {virtualColumns.map((virtualColumn) => {
              const cell = visibleCells[virtualColumn.index + 1];
              if (!cell) return null;

              return (
                <td
                  key={cell.id}
                  style={{
                    background: cell.column.getIsFiltered()
                      ? theme.palette.background.activeTableColumn
                      : virtualRow.index % 2
                        ? theme.palette.background.oddTableRow
                        : theme.palette.background.paper,
                    borderBottom: `1px solid ${theme.palette.white.dark}`,
                    borderRight: `1px solid ${theme.palette.divider}`,
                    boxSizing: "border-box",
                    minWidth: `${cell.column.getSize()}px`,
                    width: `${cell.column.getSize()}px`,
                    maxWidth: `${cell.column.getSize()}px`,
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            })}

            {/* Right padding for virtual columns */}
            {virtualPaddingRight > 0 && (
              <td
                style={{ display: "flex", width: `${virtualPaddingRight}px` }}
              />
            )}
          </tr>
        );
      })}
    </tbody>
  );
}

export const mapSorting = (sorting: SortingState): string[] => sorting?.map((x) => `${x.desc ? "-" : ""}${x.id}`) || [];

const parseValue = (val: Array<{ value: string }> | { value: string }) => {
  if (Array.isArray(val) && isObject(val[0])) {
    return val.map((x) => x.value);
  }
  if (isObject(val) && val.value !== undefined) {
    return val.value;
  }
  return val;
};

export const mapFilters = (
  columnFilters: ColumnFiltersState,
  globalFilter: string | null = "",
) => ({
  ...columnFilters.reduce(
    (acc, curr) => ({
      ...acc,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      [curr.id]: parseValue(curr.value as any),
    }),
    {},
  ),
  ...(globalFilter?.length ? { "*": globalFilter } : {}),
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const depageData = (data?: { pages: any[] }) => data?.pages.map((x) => x.data).flat() || [];

export const getNextPageParam = (lastPage) => lastPage?.markers?.next;
