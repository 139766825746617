import React from "react";
import { Box, Card } from "@mui/material";
import { Organization } from "@/api/Organization";

import Stats from "./Stats";

type PublicDataPageProps = {
  company: Organization;
};

export function PublicDataPage({
  company,
}: PublicDataPageProps) {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={4}
    >
      <Card elevation={0}>
        <Stats company={company} />
      </Card>
    </Box>
  );
}

export default PublicDataPage;
