/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AutocompleteSelect from "@/ui/atoms/AutocompleteSelect";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import CompetitorBarChart from "@/ui/molecules/CompetitorBarChart";
import CompetitorChart from "@/ui/molecules/CompetitorChart";
import CompetitorLineChart from "@/ui/molecules/CompetitorLineChart";
import DropdownInfo from "@/ui/atoms/DropdownInfo";
import RemoveButton from "@/ui/atoms/RemoveButton";
import SingleSelect from "@/ui/atoms/SingleSelect";
import Slider from "@/ui/atoms/Slider";
import StartEndDates from "@/ui/molecules/StartEndDates";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import getStartAndEndDate from "@/utils/getStartAndEndDate";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { getCompetitorsHistoriesByValorId } from "@/api/NeedleCompetitors";
import {
  metricsOptions,
  metricAccessors,
  defaultMetrics,
  defaultHistoriesValues,
  historiesOptions,
  graphTabs,
  chartRepresentsDoc,
} from "./data";

dayjs.extend(isBetween);

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  alert: {
    position: "absolute",
    zIndex: theme.zIndex.appBar + 1,
    boxShadow: theme.shadows[3],
    left: "50%",
  },
}));

const border = "1px solid rgba(49, 49, 49, .1)";
const DEFAULT_HEIGHT = 270;

const getMapToColor = (arr, colors) => {
  const mapToColor = {};
  arr.forEach((value, index) => {
    mapToColor[value] = colors[index];
  });
  return mapToColor;
};

// This is a false positive by eslint
// eslint-disable-next-line react/function-component-definition
export const CompetitorsGraph = (props) => {
  const { company, competitors, removeCompetitors } = props;
  const classes = useStyles();
  const [graph, setGraph] = useLocalStorageState(
    "bar",
    "competitor-kpis-graph",
  );
  const [metrics, setMetrics] = useLocalStorageState(
    defaultMetrics,
    "competitor-kpis-graph-metric-list",
  );
  const [historiesValues, setHistoriesValues] = useLocalStorageState(
    defaultHistoriesValues,
    "competitor-kpis-graph-histories-values-list",
  );
  const [barHeight, setBarHeight] = useLocalStorageState(
    DEFAULT_HEIGHT,
    "competitor-kpis-bar-height",
  );
  const [chartsPerRow, setChartsPerRow] = useLocalStorageState(
    competitors.length > 6 ? 6 : 3,
    "competitor-kpis-charts-per-row",
  );
  const theme = useTheme();
  const isMdDown = useMediaQuery((t) => t.breakpoints.down("md"));
  const competitorNames = competitors.map((c) => c.name);
  const companyToColor = getMapToColor(
    competitorNames,
    theme.palette.competitors,
  );
  const dates = getStartAndEndDate("last_2_years");
  const [startDate, setStartDate] = useState(dates.startDate);
  const [endDate, setEndDate] = useState(dates.endDate);
  const [dateRange, setDateRange] = useState("last_2_years");
  const [filteredHistories, setFilteredHistories] = useState({});
  const [chartRepresents, setChartRepresents] = useState("relative");

  // highlight all charts
  const [highlightedCompany, setHighlightedCompany] = useState(company.name);

  const [showRelativeBlurb, setShowRelativeBlurb] = useLocalStorageState(
    true,
    "competitor-kpis-charts-relative-blurb",
  );

  useEffect(
    () => {
      if (chartRepresents === "relative") {
        setTimeout(() => setShowRelativeBlurb(false), 5000);
      }
    },
    [chartRepresents, setShowRelativeBlurb],
  );

  const { data: histories } = useQuery(
    ["histories", company.valorId],
    async () => {
      const response = await getCompetitorsHistoriesByValorId([
        company.valorId,
      ]);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  useEffect(() => {
    if (histories) {
      if (startDate && endDate) {
        const filteredData = {};
        for (let i = 0; i < historiesValues.length; i += 1) {
          const option = historiesValues[i];
          filteredData[option.id] = histories[option.id].filter((value) => dayjs
            .unix(value.timestamp)
            .utc()
            .isBetween(startDate, endDate, "day", "[]"));
        }
        setFilteredHistories(filteredData);
      } else {
        setFilteredHistories(histories);
      }
    }
  }, [startDate, endDate, historiesValues, histories]);

  return (
    <Grid container spacing={1} style={{ borderTop: border }}>
      <Grid
        item
        md={4}
        lg={2}
        style={{ borderRight: border, paddingRight: "10px" }}
      >
        <Box className={classes.formControl} style={{ marginBottom: "5px" }}>
          <Typography id="bars-per-row" gutterBottom>
            Graph
          </Typography>
          <ButtonTabs
            options={graphTabs}
            onClick={(option) => setGraph(option.key)}
            activeKey={graph}
          />
        </Box>
        <SingleSelect
          className={classes.formControl}
          width="100%"
          id="highlight-all-charts"
          title="Highlight All Charts"
          value={highlightedCompany}
          onChange={setHighlightedCompany}
          options={competitorNames}
          showNone
        />
        <FormControl
          margin="dense"
          variant="outlined"
          className={classes.formControl}
        >
          <InputLabel id="competitors-select-outlined-label">
            Competitors
          </InputLabel>
          <Select
            labelId="competitors-select-outlined-label"
            id="competitors-select-outlined"
            value=""
            onChange={(e) => {
              removeCompetitors([e.target.value]);
            }}
            label="Competitors"
          >
            {competitors
              .filter((c) => c.valorId !== company.valorId)
              .map((c) => (
                <MenuItem key={c.valorId} value={c.valorId}>
                  <ListItemText primary={c.name} />
                  <RemoveButton />
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {graph === "line" ? (
          <AutocompleteSelect
            id="histories"
            label="Histories"
            value={historiesValues}
            onChange={setHistoriesValues}
            options={historiesOptions}
          />
        ) : (
          <AutocompleteSelect
            id="metrics"
            label="Metrics"
            value={metrics}
            onChange={setMetrics}
            options={metricsOptions}
          />
        )}
        <Box className={classes.formControl}>
          <Slider
            id="adjust-height"
            title="Adjust Height"
            min={200}
            max={610}
            defaultValue={barHeight || DEFAULT_HEIGHT}
            onChange={setBarHeight}
          />
          {!isMdDown && (
            <>
              <Typography id="charts-per-row" gutterBottom>
                Charts per row
              </Typography>
              <ButtonTabs
                options={[1, 2, 3, 4]}
                onClick={setChartsPerRow}
                activeKey={chartsPerRow}
              />
            </>
          )}
        </Box>
      </Grid>
      <Grid container item md={8} lg={10}>
        {showRelativeBlurb && chartRepresents === "relative" && graph === "line" && (
        <Alert className={classes.alert} variant="filled" severity="info">
          Relative Growth shows a growth multiple since the start date.
        </Alert>
        )}
        {graph === "line" && (
          <Grid item xs={12}>
            <DropdownInfo
              style={{ marginLeft: "1%" }}
              component={(
                <SingleSelect
                  id="chart-represents"
                  width={170}
                  title="Chart Represents"
                  value={chartRepresents}
                  onChange={setChartRepresents}
                  options={{
                    relative: "Relative Growth",
                    absolute: "Absolute Values",
                  }}
                />
              )}
              title={chartRepresentsDoc}
            />
            <StartEndDates
              startDate={startDate}
              endDate={endDate}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              dateRange={dateRange}
              setDateRange={setDateRange}
            />
          </Grid>
        )}
        {(graph === "line" ? historiesValues : metrics).map((metric) => (
          <Grid key={metric.id} item xs={12} lg={12 / chartsPerRow}>
            <CompetitorChart
              metric={metric}
              currentCompany={company.name}
              defaultHighlightedCompany={highlightedCompany}
              height={barHeight}
            >
              {graph === "line" ? (
                <CompetitorLineChart
                  history={filteredHistories[metric.id]}
                  allDisplayValues={competitorNames}
                  companyToColor={companyToColor}
                  chartRepresents={chartRepresents}
                />
              ) : (
                <CompetitorBarChart
                  competitors={competitors}
                  metricAccessors={metricAccessors}
                />
              )}
            </CompetitorChart>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

CompetitorsGraph.propTypes = {
  company: PropTypes.object.isRequired,
};

export default CompetitorsGraph;
