import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import {
  Avatar,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  useParams, useNavigate, Route, Routes, Navigate,
} from "react-router-dom";
import Helmet from "react-helmet";
import CompetitorTable from "@/ui/molecules/CompetitorTable";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import { mapToExcel } from "@/utils/xlsx";
import AddCompanyDialog from "@/ui/molecules/AddCompanyDialog";
import CenteredProgress from "@/ui/atoms/CenteredProgress";

import { Add, ViewColumnOutlined, GetApp } from "@mui/icons-material";
import xlsx from "json-as-xlsx";
import { postCompetitorAnalysis } from "@/api/CompetitorAnalysis";
import {
  getNeedleCompetitorsByValorId,
  createNeedleCompetitors,
  deleteNeedleCompetitors,
} from "@/api/NeedleCompetitors";
import { saytForOrg } from "@/api/Search";
import { tabs } from "./data";
import CompetitorsGraph from "./CompetitorsGraph";
import OrganizationDiscover from "./OrganizationDiscover";
import useTripwire from "../useTripwire";

function OrganizationCompetitors({ company }) {
  const { valorId: id } = company;
  const [openDrawer, setDrawerOpen] = useState(false);
  const { "*": subsection, slug } = useParams();
  const navigate = useNavigate();

  const [openExcelDialog, setExcelDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const {
    status: needleStatus,
    data: competitors = [],
    refetch,
  } = useQuery(
    ["Competitors", company.valorId],
    async () => {
      const response = await getNeedleCompetitorsByValorId([id]);
      if (response?.status === 404 || response?.status === 422) {
        throw new Error(response.status);
      }
      return response;
    },
    {
      retry: 1,
    },
  );

  useTripwire(id, "competitors");

  const downloadResearchReport = () => {
    // post competitor analysis
    postCompetitorAnalysis(competitors.map((x) => x.valorId));
    setExcelDialogOpen(false);
  };

  const downloadRawData = () => {
    xlsx(mapToExcel(competitors), {
      fileName: `${company.name} Competitors KPIS`, // Name of the resulting spreadsheet
      extraLength: 0, // A bigger number means that columns will be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    });
    setExcelDialogOpen(false);
  };

  const uploadNeedleCompetitors = async (competitorIds) => {
    if (competitorIds?.length > 0) {
      await createNeedleCompetitors(id, competitorIds);
      await refetch();
    }
  };

  const removeCompetitors = async (competitorIds) => {
    if (competitorIds?.length > 0) {
      await deleteNeedleCompetitors(id, competitorIds);
      await refetch();
    }
  };

  const renderNotSuccess = (status) => {
    if (status === "loading") {
      return <CenteredProgress />;
    }
    return <Typography>No Competitors</Typography>;
  };
  return (
    <>
      <Helmet>
        <title>{`${company.name} - vOS Competitor Dashboard`}</title>
        <meta
          property="og:title"
          content={`${company.name} - vOS Competitor Dashboard`}
        />
        <meta
          property="og:description"
          content={`Compare KPIs for ${company.name} and its competitors on vOS`}
        />
      </Helmet>
      <AddCompanyDialog
        search={saytForOrg}
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleAddClick={uploadNeedleCompetitors}
      />
      {needleStatus !== "success" ? (
        renderNotSuccess(needleStatus)
      ) : (
        <>
          <Grid container style={{ marginBottom: "1%" }}>
            <Grid item md={6} container justifyContent="flex-end" sx={{ overflow: "hidden" }}>
              <ButtonTabs
                width="312px"
                options={tabs}
                onClick={(option) => navigate(`/${slug}/org/${id}/competitors/${option.key}`)}
                activeKey={subsection}
              />
            </Grid>
            <Grid item md={6} container justifyContent="flex-end">
              {subsection === "table" && (
                <Button
                  style={{ marginRight: "5px", height: "30px" }}
                  variant="outlined"
                  color="secondary"
                  onClick={() => setDrawerOpen(!openDrawer)}
                  startIcon={<ViewColumnOutlined />}
                >
                  EDIT COLUMNS
                </Button>
              )}
              {(subsection === "table" || subsection === "graph") && (
                <>
                  <Button
                    style={{ height: "30px" }}
                    color="primary"
                    variant="contained"
                    startIcon={<Add color="inherit" />}
                    onClick={() => setDialogOpen(true)}
                  >
                    ADD COMPANY
                  </Button>

                  <Tooltip title="Export to Excel" placement="top">
                    <IconButton
                      style={{ height: "30px", width: "30px" }}
                      onClick={() => setExcelDialogOpen(true)}
                    >
                      <GetApp />
                    </IconButton>
                  </Tooltip>
                </>
              )}
            </Grid>
          </Grid>

          <Routes>
            <Route
              path=""
              element={<Navigate to="table" replace />}
            />
            <Route
              path="table"
              element={(
                <CompetitorTable
                  currentValorId={id}
                  openDrawer={openDrawer}
                  setDrawerOpen={setDrawerOpen}
                  key="needle"
                  data={competitors}
                  removeCompetitors={removeCompetitors}
                  initialColumns={[
                    "company",
                    "description",
                    "capital",
                    "linkedIn",
                    "webTraffic",
                    "secondMeasureGrowth",
                  ]}
                  bannedColumns={["sourced"]}
                />
              )}
            />

            <Route
              path="graph"
              element={(
                <CompetitorsGraph
                  company={company}
                  competitors={competitors}
                  removeCompetitors={removeCompetitors}
                />
              )}
            />

            <Route
              path="search"
              element={(
                <OrganizationDiscover
                  company={company}
                  id={company.valorId}
                />
              )}
            />
          </Routes>
          <Dialog
            open={openExcelDialog}
            onClose={() => setExcelDialogOpen(false)}
          >
            <DialogTitle>Select Format</DialogTitle>
            <List>
              <ListItem button onClick={downloadRawData}>
                <ListItemAvatar>
                  <Avatar>
                    <GetApp />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Raw Data" />
              </ListItem>
              <ListItem button onClick={downloadResearchReport}>
                <ListItemAvatar>
                  <Avatar>
                    <GetApp />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary="Research Report" />
              </ListItem>
            </List>
          </Dialog>
        </>
      )}
    </>
  );
}

OrganizationCompetitors.propTypes = {
  company: PropTypes.object.isRequired,
};

export default OrganizationCompetitors;
