export type ElementType = "block-quote" |
  "heading-one" |
  "heading-two" |
  "numbered-list" |
  "bulleted-list" |
  "list-item" |
  "list-item-text" |
  "link" |
  "paragraph" |
  "image" |
  "image-placeholder";

export const BlockQuote: ElementType = "block-quote";
export const BulletedList: ElementType = "bulleted-list";
export const HeadingOne: ElementType = "heading-one";
export const HeadingTwo: ElementType = "heading-two";
export const ListItem: ElementType = "list-item";
export const ListItemText: ElementType = "list-item-text";
export const NumberedList: ElementType = "numbered-list";
export const Link: ElementType = "link";
export const Paragraph: ElementType = "paragraph";
export const Image: ElementType = "image";
export const ImagePlaceHolder: ElementType = "image-placeholder";

export const ServerTypes = [
  BlockQuote,
  BulletedList,
  HeadingOne,
  HeadingTwo,
  ListItem,
  ListItemText,
  NumberedList,
  Link,
  Paragraph,
  Image,
];

export type EmptyText = {
  text: string
};

export type ImageElement = {
  type: "image";
  url: string;
  name: string;
  children: EmptyText[];
};
