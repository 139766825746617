import React from "react";
import { withStyles } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";

const StyledTabs = withStyles((theme) => ({
  root: {
    borderBottom: "1px solid #e8e8e8",
  },
  indicator: {
    backgroundColor: theme.palette.action.active,
  },
}))(Tabs);

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    minWidth: 72,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginRight: theme.spacing(4),
    "&:hover": {
      color: theme.palette.text.primary,
      opacity: 1,
    },
    "&$selected": {
      color: theme.palette.action.active,
      fontWeight: theme.typography.fontWeightBold,
    },
    "&:focus": {
      color: theme.palette.action.action,
    },
  },
  selected: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
}))((props) => <Tab disableRipple {...props} />);

export default function SlidingTabs({ values, setValue, value }) {
  const labelToIndex = values.reduce(
    (prev, curr, index) => ({
      ...prev,
      [curr]: index,
    }),
    {},
  );

  const onChange = (event, newIntegerValue) => {
    // newValue is in
    setValue(values[newIntegerValue]);
  };

  return (
    <StyledTabs value={labelToIndex[value]} onChange={onChange}>
      {values.map((value2) => (
        <StyledTab key={`styled-tab-${value2}`} label={value2} />
      ))}
    </StyledTabs>
  );
}
