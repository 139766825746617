import React, { useState, useCallback } from "react";

import { useQuery } from "react-query";
import { Box } from "@material-ui/core";
import { DragDropContext } from "react-beautiful-dnd";
import PropTypes from "prop-types";
import DraggableList from "@/ui/atoms/DraggableList";
import AddCompanyDialog from "@/ui/molecules/AddCompanyDialog";

const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props {
  column: string;
  id: string;
  dontShowAccess?: boolean;
  prefixTitle?: string;
  getRestrictions: (id: string, column: string) => any[];
  removeRestriction: (userId: string, valorId: string, column: string) => any[];
  addRestrictions: (id: string, valorIds: string[], granted: boolean, column: string) => any[];
  search: (search: string) => any;
}

export default function DragAndDropCompanies(props: Props) {
  const {
    column,
    id,
    dontShowAccess,
    prefixTitle,
    getRestrictions,
    removeRestriction,
    addRestrictions,
    search,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState({ restrictions: [], access: [] });
  const [highlight, setHighlight] = useState(false);
  const [granted, setGranted] = useState(false);

  const onDragStart = useCallback(() => {
    setHighlight(true);
  }, []);

  const onDragEnd = useCallback(
    (result) => {
      const { source, destination } = result;
      setHighlight(false);
      // dropped outside the list
      if (!destination) {
        return;
      }

      let newData = {};
      if (source.droppableId === destination.droppableId) {
        const reorderedCompanies = reorder(
          data[source.droppableId],
          source.index,
          destination.index,
        );

        newData = { ...data };
        newData[source.droppableId] = reorderedCompanies;
      } else {
        newData = move(
          data[source.droppableId],
          data[destination.droppableId],
          source,
          destination,
        );
      }
      addRestrictions(
        id,
        newData[destination.droppableId].map((company) => company.valorId),
        destination.droppableId === "access",
        column,
      );
      setData(newData);
    },
    [data],
  );

  const { refetch } = useQuery(
    [`${column}_restrictions`, id],
    async () => getRestrictions(id, column),
    {
      onSuccess: setData,
    },
  );

  const handleDelete = async (valorId) => {
    await removeRestriction(id, valorId, column);
    await refetch();
  };

  const handleAddClick = async (valorIds, canAccess = granted) => {
    await addRestrictions(id, valorIds, canAccess, column);
    await refetch();
  };

  return (
    <>
      <AddCompanyDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        handleAddClick={handleAddClick}
        search={search}
      />
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        <DraggableList
          values={data.restrictions}
          handleAddClick={() => {
            setDialogOpen(true);
            setGranted(false);
          }}
          prefixTitle={prefixTitle}
          removeRestriction={handleDelete}
          highlight={highlight}
          id="restrictions"
        />
        {!dontShowAccess && (
          <Box marginTop={2}>
            <DraggableList
              values={data.access}
              handleAddClick={() => {
                setDialogOpen(true);
                setGranted(true);
              }}
              prefixTitle={prefixTitle}
              removeRestriction={handleDelete}
              highlight={highlight}
              id="access"
            />
          </Box>
        )}
      </DragDropContext>
    </>
  );
}

DragAndDropCompanies.propTypes = {
  column: PropTypes.string.isRequired, // role_id or user_id;
  id: PropTypes.string.isRequired,
  dontShowAccess: PropTypes.bool,
  prefixTitle: PropTypes.string,
};

DragAndDropCompanies.defaultProps = {
  prefixTitle: "",
};
