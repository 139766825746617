import { AxiosResponse } from "axios";

import HTTPValidationError from "./HTTPValidationError";
import AuthenticationError from "./AuthenticationError";
import ServerError from "./ServerError";

export { HTTPValidationError, AuthenticationError, ServerError };

export default function toError(response: AxiosResponse): Error | undefined {
  if (response.status === 422) {
    return new HTTPValidationError("Validation Error", response.data);
  }

  if (response.status === 403) {
    return new AuthenticationError();
  }

  if (response.status >= 500) {
    return new ServerError();
  }

  return undefined;
}
