/* eslint-disable no-console */
import axios from "axios";
import { paths, operations } from "@/api/index";

export const getManagementData = async ({ sinceDate }) => {
  const params = {
    taken_since: sinceDate,
  };

  const searchParams = new URLSearchParams(
    Object.fromEntries(Object.entries(params).filter(([, v]) => v != null)),
  ).toString();

  try {
    const response = await axios.get(`/api/users/management-statistics?${searchParams}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export type User = paths["/api/users/me"]["get"]["responses"]["200"]["content"]["application/json"];
export const getUserData = async (): Promise<User> => {
  const response = await axios.get<User>("/api/users/me");

  if (response.data?.permissions?.length) {
    // uniqueify permissions, using the latest one (user)
    // to override the previous ones (role), match on process id as well
    const processPermissions = response.data.permissions
      .filter((permission) => permission.processId);
    const uniquePermissions = response.data.permissions.reduce((acc, permission) => {
      if (permission.process_id) {
        return acc;
      }
      return {
        ...acc,
        [permission.id]: permission,
      };
    }, {});
    const uniqueProcessPermissions = processPermissions.reduce((acc, permission) => ({
      ...acc,
      [`${permission.processId}:${permission.id}`]: permission,
    }), {});
    response.data.permissions = Object.values(uniquePermissions)
      .concat(Object.values(uniqueProcessPermissions));
  }

  return response.data;
};

export const updateUserData = async ({ id, firstName, lastName }) => {
  const response = await axios.patch(`/api/users/${id}`, { firstName, lastName });
  return response.data;
};

export const getRestrictions = async ({ id, column }) => {
  const response = await axios.get(`/api/compliance/restrictions/${id}/${column}`);
  return response.data;
};

export const getAllUsers = async () => {
  const response = await axios.get("/api/users/");
  return response.data;
};

export const getInvestorUsers = async () => {
  try {
    const response = await axios.get("/api/users/investors");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

/* eslint-disable no-param-reassign */
export const getLastInteractions = async ({ limit }) => {
  const paramsData = { limit };
  const filtered = Object.keys(paramsData)
    .filter((key) => paramsData[key] !== undefined)
    .reduce((obj, key) => {
      obj[key] = paramsData[key];
      return obj;
    }, {});
  const params = `?${new URLSearchParams(filtered).toString()}`;

  try {
    const response = await axios.get(`/api/users/interactions${params}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const dismissInteraction = async ({ valorId }) => {
  try {
    const response = await axios.post(
      `/api/users/interactions/${valorId}/dismiss`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export type GetProfilePictureDataEndpoint = operations["get_profile_pic_api_users_profile_pic_get"]
export type GetProfilePictureResponses = GetProfilePictureDataEndpoint["responses"];
export type GetProfilePictureResult = GetProfilePictureResponses["200"]["content"]["application/json"];
export const getProfilePic = async (microsoftToken: string): Promise<GetProfilePictureResult> => {
  const config = {
    headers: {
      "ms-access-token": microsoftToken,
    },
  };
  try {
    const response = await axios.get("/api/users/profile-pic", config);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateProfilePicture = async (file) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  const formData = new FormData();
  formData.append("image", file);
  try {
    const response = await axios.post("/api/users/profile-pic", formData, config);
    return response.data;
  } catch (error) {
    console.error("Error updating profile picture: ", error);
    throw error;
  }
};

export default {
  getUserData,
  updateUserData,
  getInvestorUsers,
  getLastInteractions,
  dismissInteraction,
  getProfilePic,
  updateProfilePicture,
};
