import {
  Element, Node, Transforms, Editor,
} from "slate";
import { ReactEditor } from "slate-react";
import {
  ListType,
  withLists,
  withListsReact,
  onKeyDown as prezlyOnKeyDown,
} from "@prezly/slate-lists";
import {
  BulletedList,
  ListItem,
  ListItemText,
  NumberedList,
  Paragraph,
} from "../types/Element";

export const onKeyDown = (editor, e) => {
  prezlyOnKeyDown(editor, e);

  // start an unordered list when user types `- ` or `* ` at the start of a block
  if (e.key === " ") {
    // get position
    const { selection } = editor;
    if (selection) {
      const [match] = Editor.nodes(editor, {
        match: (n) => Element.isElementType(n, Paragraph),
      });
      if (!match) return;
      const [paragraph, path] = match;
      if (!paragraph.children) return;
      // get last two characters of paragraph
      const firstChars = paragraph.children[0].text;
      const lastTwoChars = paragraph.children?.[paragraph.children.length - 1].text.slice(-2);
      const lastThreeChars = paragraph.children?.[paragraph.children.length - 1].text.slice(-3);
      const startListShortcuts = [
        "-",
        "*",
        "1.",
        "1)",
      ];
      const midListShortcuts = startListShortcuts.map((shortcut) => `\n${shortcut}`);
      if (
        midListShortcuts.includes(lastThreeChars)
        || midListShortcuts.includes(lastTwoChars)
        || startListShortcuts.includes(firstChars)
      ) {
        // create list
        const shortcutMatch = (
          midListShortcuts.includes(lastThreeChars)
            ? lastThreeChars
            : (midListShortcuts.includes(lastTwoChars) ? lastTwoChars : firstChars)
        );
        const listType = /1[.)]/.test(shortcutMatch) ? NumberedList : BulletedList;
        const list = {
          type: listType,
          children: [
            {
              type: ListItem,
              children: [
                {
                  type: ListItemText,
                  children: [{ text: "" }],
                },
              ],
            },
          ],
        };

        // remove shortcut without removing entire paragraph
        if (midListShortcuts.includes(lastThreeChars) || midListShortcuts.includes(lastTwoChars)) {
          let chars = 2;
          if (midListShortcuts.includes(lastThreeChars)) {
            chars = 3;
          }

          // get the path of the current paragraph
          const currentPath = Editor.path(editor, selection.focus);
          const [currentText] = Editor.node(editor, currentPath);

          // remove the last two characters of the current paragraph
          const newParagraph = {
            ...currentText,
            text: currentText.text.slice(0, -chars),
          };

          const newPath = [
            ...path.slice(0, -1),
            path[path.length - 1] + 1,
          ];
          Transforms.insertNodes(
            editor,
            list,
            {
              at: newPath,
            },
          );

          // insert clean paragraph
          Transforms.delete(editor, { at: currentPath, distance: 1 });
          Transforms.insertNodes(editor, newParagraph, { at: currentPath });

          // move cursor to end of list item
          Transforms.select(editor, newPath.concat([0, 0, 0]));
        } else if (startListShortcuts.includes(firstChars)) {
          // remove current paragraph
          Transforms.delete(editor, { at: selection.focus.path.slice(0, -1), distance: 1 });

          // insert list
          Transforms.insertNodes(editor, list, { at: path });

          // move cursor to end of list item
          Transforms.select(editor, path.concat([0, 0, 0]));
        }

        // prevent default
        e.preventDefault();
      }
    }
  }
};

const ListTypeTransformer = {
  ol: NumberedList,
  ul: BulletedList,
};

const withOrderedListTypes = (editor: ReactEditor) => {
  const { normalizeNode } = editor;
  // changing this introduced an issue, disabling it instead
  // eslint-disable-next-line no-param-reassign
  editor.normalizeNode = (entry) => {
    const [node, path] = entry;
    if (Element.isElementType(node, NumberedList)) {
      // based on depth of nested list, assign list type
      const listTypes = "1ai";
      const depth = Math.floor(path.length / 2);

      const newNode = {
        ...node,
        listType: listTypes[depth % 3],
      };

      // replace node
      Transforms.setNodes(editor, newNode, { at: path });
    }

    normalizeNode(entry);
  };
  return editor;
};

const withListsPlugin = withLists({
  isConvertibleToListTextNode(node: Node) {
    return Element.isElementType(node, Paragraph);
  },
  isDefaultTextNode(node: Node) {
    return Element.isElementType(node, Paragraph);
  },
  isListNode(node: Node, type?: ListType) {
    if (type) {
      const transformedType = ListTypeTransformer[type] || type;
      return Element.isElementType(node, transformedType);
    }
    return (
      Element.isElementType(node, NumberedList)
      || Element.isElementType(node, BulletedList)
    );
  },
  isListItemNode(node: Node) {
    return Element.isElementType(node, ListItem);
  },
  isListItemTextNode(node: Node) {
    return Element.isElementType(node, ListItemText);
  },
  createDefaultTextNode(props = {}) {
    return { children: [{ text: "" }], ...props, type: Paragraph };
  },
  createListNode(type: ListType = ListType.UNORDERED, props = {}) {
    const nodeType = type === ListType.ORDERED ? NumberedList : BulletedList;
    return { children: [{ text: "" }], ...props, type: nodeType };
  },
  createListItemNode(props = {}) {
    return { children: [{ text: "" }], ...props, type: ListItem };
  },
  createListItemTextNode(props = {}) {
    return { children: [{ text: "" }], ...props, type: ListItemText };
  },
});

export default function withListsCustom(editor: ReactEditor) {
  return withOrderedListTypes(withListsReact(withListsPlugin(editor)));
}
