/* eslint-disable no-console */
import axios from "axios";

export const followCompany = async (userId, valorId) => {
  try {
    const response = await axios.post(
      `/api/followers/user/${userId}/org/${valorId}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const unfollowCompany = async (userId, valorId) => {
  try {
    const response = await axios.delete(
      `/api/followers/user/${userId}/org/${valorId}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const updateFollowing = (userId, valorId, isFollowing) => {
  if (isFollowing) {
    return unfollowCompany(userId, valorId);
  }
  return followCompany(userId, valorId);
};

export const snoozeUpdates = async (date, userId, valorId) => {
  try {
    const response = await axios.post(
      `/api/followers/user/${userId}/org/${valorId}/snooze`,
      null,
      {
        params: {
          date,
        },
      },
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const doesUserFollowCompany = async (userId, valorId) => {
  try {
    const response = await axios.get(
      `/api/followers/user/${userId}/org/${valorId}`,
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFollowList = async (userId) => {
  try {
    const response = await axios.get(`/api/followers/user/${userId}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFollowUpdates = async (
  userId,
  keysetCursor,
) => {
  let url = `/api/followers/user/${userId}/updates`;
  let paramsData = {};

  // keysetCursor is purely for internal pagination of the query
  if (keysetCursor) {
    const {
      cursors,
      asc,
    } = keysetCursor;
    paramsData = {
      cursors,
      is_reverse_direction: asc,
    };
  }
  const params = `?${new URLSearchParams(paramsData).toString()}`;
  url += params;
  try {
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const createHomeFilter = async (filter) => {
  try {
    const response = await axios.post("/api/followers/filters", filter);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getHomeFilters = async () => {
  try {
    const response = await axios.get("/api/followers/filters");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  followCompany,
  unfollowCompany,
  doesUserFollowCompany,
  getFollowList,
  getFollowUpdates,
  createHomeFilter,
  getHomeFilters,
};
