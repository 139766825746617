import axios from "axios";
import { operations } from "@/api/index";
import toError from "./Error";

axios.interceptors.response.use((res) => {
  const error = toError(res);
  if (error) {
    throw error;
  }
  return res;
});

type GetResponses = operations["route_get_image_url_api_images__name__get"]["responses"];
export type ImageUrl = GetResponses["200"]["content"]["application/json"];
export const getImageUrl = async (name: string): Promise<ImageUrl> => {
  const response = await axios.get<ImageUrl>(`/api/images/${name}`);
  return response.data;
};

type PostExternalResponses = operations["route_upload_external_image_api_images_external_post"]["responses"];
export type ExternalImageUpload = PostExternalResponses["200"]["content"]["application/json"];
export const uploadExternalImage = async (url: string): Promise<ExternalImageUpload> => {
  const response = await axios.post<ExternalImageUpload>("/api/images/external", { url });
  return response.data;
};

type PostResponses = operations["route_upload_image_api_images__name__post"]["responses"];
export type ImageUpload = PostResponses["200"]["content"]["application/json"];
export const uploadImage = async (name: string, file: File): Promise<ImageUpload> => {
  const formData = new FormData();
  formData.append("image", file);
  const response = await axios.post<ImageUpload>(`/api/images/${name}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data;
};

export default {
  getImageUrl,
  uploadImage,
};
