import React, {
  useState, useEffect,
} from "react";
import {
  Tooltip, TextField, IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function DebouncedTextField({
  value: initialValue,
  placeholder,
  onChange,
  debounce = 500,
  label,
  InputProps: inputProps,
  size,
  variant,
  clearable,
  sx,
}: {
  value: string | number
  placeholder?: string,
  onChange: (value: string | number) => void
  debounce?: number,
  label?: string,
  InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>,
  size?: "small" | "medium",
  variant?: "standard" | "filled" | "outlined",
  clearable?: boolean,
  sx?: any,
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "onChange">) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);

    return () => clearTimeout(timeout);
  }, [value, debounce, onChange]);

  return (
    <TextField
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      label={label}
      placeholder={placeholder}
      InputProps={{
        ...inputProps,
        endAdornment: clearable && value && (
          <Tooltip
            title="Clear"
            placement="top"
          >
            <IconButton
              size="small"
              onClick={() => {
                setValue("");
              }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        ),
      }}
      size={size}
      variant={variant}
      sx={sx}
    />
  );
}
