import React, { useMemo, useState } from "react";
import {
  Alert, AlertTitle, Autocomplete, Box, TextField,
} from "@mui/material";
import { useQuery, useQueryClient } from "react-query";
import ProcessesMapping from "@/constants/ProcessesMapping";
import {
  getProcessSettings,
  getProcessTasks,
  getResponseLinkedTasks,
  createTaskSurveyResponse,
  deleteTaskSurveyResponse,
} from "@/api/Process";
import { useAuth } from "@/hooks/useAuth";
import dayjs from "dayjs";

const NAME_FIELD_SETTING_ID = "33a4daaf-4e75-4f8b-9eca-45e853b6e9ed";
const COMPANY_FIELD_SETTING_ID = "e7b76202-dae4-497e-b773-3b79e5d02bb3";

interface Props {
  responseId: string;
  valorId: string;
}

export default function TaskSelector({
  responseId,
  valorId,
}: Props) {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const [linkingAlertMessage, setLinkingAlertMessage] = useState(null);

  const {
    data: linkedTasks = [],
    isLoading: isLoadingLinkedTasks,
  } = useQuery(
    ["LinkedTasks", responseId],
    () => getResponseLinkedTasks([responseId]),
    {
      enabled: !!responseId,
    },
  );

  const settingsParams = [
    null,
    "enable_survey_linking",
    null,
  ];

  const {
    data: processSettings = [],
    isLoading: isLoadingProcessSettings,
  } = useQuery(
    ["ProcessSettings", settingsParams],
    () => getProcessSettings(...settingsParams),
  );

  const hardcodedFilters = {
    AND: {
      eq: {
        "tasks.fieldSettings.e7b76202-dae4-497e-b773-3b79e5d02bb3.company": [
          `${valorId}`,
        ],
      },
    },
  };

  const taskParams = [
    user.readableProcessIds,
    100,
    null,
    null,
    hardcodedFilters,
  ];

  const {
    data: { data: tasks } = { data: [] },
    isLoading: isLoadingTasks,
  } = useQuery(
    ["ProcessesTasks", taskParams],
    () => getProcessTasks(...taskParams),
    {
      enabled: !!user && !!valorId,
    },
  );

  const sortTasks = (unsortedTasks) => unsortedTasks.sort((a, b) => {
    const taskA = ProcessesMapping[a.processId];
    const taskB = ProcessesMapping[b.processId];
    const mappingComparison = taskA.localeCompare(taskB);

    if (mappingComparison !== 0) {
      return mappingComparison;
    }

    return dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf();
  });

  const sortedFilteredTasks = useMemo(() => {
    const processIdSet = new Set(processSettings.map((setting) => setting.processId));
    const filteredTasks = tasks.filter((task) => processIdSet.has(task.processId));
    return sortTasks(filteredTasks);
  }, [tasks]);

  const sortedLinkedTasks = useMemo(() => sortTasks(linkedTasks), [linkedTasks]);

  const handleTaskLinking = async (event, newValue, reason, details) => {
    const linkedParams = {
      processId: details.option.processId,
      taskId: details.option.id,
      responseId,
    };

    try {
      if (reason === "selectOption") {
        queryClient.setQueryData(["LinkedTasks", responseId], () => newValue);
        await createTaskSurveyResponse(linkedParams);
      } else if (reason === "removeOption") {
        queryClient.setQueryData(["LinkedTasks", responseId], () => newValue);
        await deleteTaskSurveyResponse(linkedParams);
      }
    } catch (err) {
      setLinkingAlertMessage("An error occurred while linking tasks; please try again. If the issue persists, contact the labs team.");
      queryClient.invalidateQueries(["LinkedTasks", responseId], { exact: true });
    }
  };

  const reduceTasks = (tasksToReduce) => {
    const processNameMap = new Map();

    return tasksToReduce.reduce((acc, task) => {
      const displayProps = {
        taskId: task.id,
        processId: task.processId,
        name: null,
        company: null,
        hasDuplicateName: false,
      };

      Object.keys(task.fieldValues).forEach((fieldId) => {
        if (
          task.fieldValues[fieldId].fieldSetting?.fieldSettingId
          === NAME_FIELD_SETTING_ID
        ) {
          displayProps.name = task.fieldValues[fieldId].value;
        }
        if (
          task.fieldValues[fieldId].fieldSetting?.fieldSettingId
          === COMPANY_FIELD_SETTING_ID
        ) {
          displayProps.company = task.fieldValues[fieldId].company;
        }
      });

      if (displayProps.name) {
        const processNameKey = `${task.processId}-${displayProps.name}`;
        if (processNameMap.has(processNameKey)) {
          displayProps.hasDuplicateName = true;
          acc[processNameMap.get(processNameKey)].hasDuplicateName = true;
        } else {
          processNameMap.set(processNameKey, task.internalId);
        }
      }

      acc[task.internalId] = displayProps;
      return acc;
    }, {});
  };

  const objectTasks = useMemo(() => (reduceTasks(sortedFilteredTasks)), [sortedFilteredTasks]);

  return (
    <>
      {linkingAlertMessage && (
        <Alert severity="error" onClose={() => setLinkingAlertMessage(null)}>
          <AlertTitle>Error</AlertTitle>
          {linkingAlertMessage}
        </Alert>
      )}
      <Box>
        <Autocomplete
          multiple
          id="task-select"
          loading={isLoadingLinkedTasks || isLoadingTasks || isLoadingProcessSettings}
          options={sortedFilteredTasks}
          isOptionEqualToValue={(option, value) => option.internalId === value.internalId}
          disableCloseOnSelect
          clearIcon={null}
          value={sortedLinkedTasks}
          getOptionLabel={(t) => {
            const displayProps = objectTasks[t.internalId];
            let optionLabel = `${ProcessesMapping[displayProps.processId]}`;
            if (displayProps.name) {
              optionLabel += ` | ${displayProps.name}`;
            } else if (displayProps.company) {
              optionLabel += ` | ${displayProps.company.name}`;
            }
            if (displayProps.hasDuplicateName) {
              optionLabel += ` | ID: ${displayProps.taskId}`;
            }
            return optionLabel;
          }}
          sx={{ width: "50%" }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Select Opportunities"
              placeholder="Opportunities"
            />
          )}
          onChange={handleTaskLinking}
        />
      </Box>
    </>
  );
}
