import React from "react";
import { useNavigate, Link as RouterLink, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { readNote } from "@/api/Notes";
import { useAuth } from "@/hooks/useAuth";

import {
  Breadcrumbs, Link, Typography, Skeleton,
  Card, Box, Alert,
} from "@mui/material";
import Note from "../NotesList/Note";

export default function ViewNote() {
  const params = useParams();
  const { user } = useAuth();
  const valorId = params.id || null;
  const documentId = params.noteId || null;

  const navigate = useNavigate();

  const {
    data: note,
    isLoading,
    isError,
  } = useQuery(
    ["Note", valorId, documentId],
    async () => readNote(valorId, documentId),
  );

  if (isError) {
    return (
      <Card elevation={0}>
        <Box
          padding={1}
        >
          <Alert severity="error">
            Error loading note. Check that you have access to this note before trying again.
            If issues persist, please contact the Labs Team at
            {" "}
            <Link
              href="mailto:labs@valorep.com?subject=Note Access"
            >
              labs@valorep.com
            </Link>
            .
          </Alert>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={0}>
      <Box
        padding={1}
      >
        <Box
          mb={1}
        >
          <Breadcrumbs>
            <Link
              component={RouterLink}
              to={`/org/${valorId}/notes`}
            >
              Notes
            </Link>
            {isLoading && <Skeleton variant="text" width={200} />}
            {!isLoading && (
              <Typography>
                {note?.title}
              </Typography>
            )}
          </Breadcrumbs>
        </Box>
        {isLoading && <Skeleton variant="rectangular" height={300} />}
        {!isLoading && (
          <Note
            note={note}
            showEdit={note?.authorId === user.id}
            showDelete={false}
            showViewNote={false}
            showCopyLink
            editNote={() => navigate(`/org/${valorId}/notes/edit/${documentId}`)}
          />
        )}
      </Box>
    </Card>
  );
}
