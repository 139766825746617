import React from "react";
import { Box, Tooltip, TooltipProps as TooltipPropsType } from "@mui/material";
//                              ..                     @%(/#%%@
//                         .%,,,,,,,,,              @,,,,///*//#   %/%,,*****
//                   .%,,,,,,,,,,,,,,,(#           ,,,,,,@@////#  #*@,,,,*****/
//              &/@,,,,,,,,,,,,,,,,,.      &      (,,,,,,,*/////(  &,,,,,,/****/
//           ,,,,,,,,(,,@&&&        %    %@ *     ,,,,,,,,&#/////*#,,,,,,,,*****
//           (,,,,,,,,,(,,&       &  (   .(,,     ,,,,,,,,,/&///@(,,,,,,,,/*****
//             ,,,,,,,,,,,,*        */,,,,,,,      ,,,,,,,,%*//(/,,,,,,,,,*****
//              &,,,,,,,,,@,,&&@*,,,,,,,,,,,*      /,,,,,,,*#/(/,,,,,,,,*****/
//                ,,,,,,,,*/,%@#,,,,,,,,,,,,,       @,,,,&%*,@@,,,,,,,,@****&
//                 *,,,,,,,,/*(#,,,,,,,,,,,,,,@           &******&%,,,*****#
//                   %,,%*********(,,,,,,//.,             ////%
//                        /@&*******(.....% /,,,         //@@&
//                         &//*****... %&,,,,,,.        (////
//                        @///// **@%#/,,,,,&          //@%&.
//                       #/*//%    @**&**             /////
//                      (@///*                      &%*///
//        (******#,,,,,,///@,%**&  ,@*,,,,(*********%*//%
//      %*********,,,,,,,,,,&,,,,,,,,,,,,,,,,,/*******/
//      **(//#****%,,,,,,/,,,,,,,,,,,,,,,,,,,,,,****(/(
//      @&@///@***,,,,,,,*%,,,,,,,,,,,,,,,,,,,#@//&
//    @/////@***&,,,,,,,,&*#,,,,,/@#/*@(&#.
//   (////#,,,,,,,,,,/,,,***&///(,@@////&#&@  &
//  @//(/@,,,,,,,,,*@*///&**@,,/@&@/#////%///*##////
//  @////(@@@%%///////////,/,,,,,,,,,,,,,#@//*%//&**%
//   ,////////&//////@,,,,,&,,,,,,,,,,,,,,,,,,,&***#
//      ,,**,,,,,,@,,,,,,,,,,&((,,,,,,,,,,,,,,%**&
//      *,,,,,,,,,,,,,,,,,,,,*,.       .&&**@
//   #****,,,,,,,*,,,,,,,,,,&,&
//    .*****//,,,,,,,,,,,,,,%**%
// IF THIS ISN'T WORKING, YOU PROBABLY NEED TO OVERFLOW: HIDDEN THE PARENT

type ClampsTooltipProps = {
  title: string;
  placement?: TooltipPropsType["placement"];
  noEllipsis?: boolean;
  TooltipProps?: TooltipPropsType;
  children: React.ReactNode;
};

export default function ClampsTooltip({
  title,
  placement = "top",
  noEllipsis = false,
  TooltipProps,
  children,
}: ClampsTooltipProps) {
  const [hasTooltip, setHasTooltip] = React.useState(false);
  const contentRef = React.useRef<HTMLDivElement>(null);
  const fakeRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // listen for reflow
    let reflowTimeout;
    const handleReflow = () => {
      clearTimeout(reflowTimeout);
      reflowTimeout = setTimeout(() => {
        const contentBox = contentRef.current;
        const fakeBox = fakeRef.current;

        if (contentBox && fakeBox) {
          const contentWidth = contentBox.clientWidth;
          const fakeWidth = fakeBox.clientWidth;
          const hasOverflow = contentWidth < fakeWidth;
          setHasTooltip(hasOverflow);
        }
      }, 100);
    };

    handleReflow();

    window.addEventListener("resize", handleReflow);
    return () => {
      window.removeEventListener("resize", handleReflow);
    };
  }, [contentRef, fakeRef]);

  const visibleContent = (
    <Box
      ref={contentRef}
      sx={{
        overflow: "hidden",
        textOverflow: noEllipsis ? null : "ellipsis",
        whiteSpace: "nowrap",
        "& *": {
          overflow: "hidden",
          textOverflow: noEllipsis ? null : "ellipsis",
          whiteSpace: "nowrap",
        },
      }}
    >
      {children}
    </Box>
  );

  return (
    <Box
      position="relative"
    >
      {hasTooltip ? (
        <Tooltip
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...TooltipProps}
          title={title}
          placement={placement}
        >
          {visibleContent}
        </Tooltip>
      ) : visibleContent}
      {/* fake box to gauge height / width of content */}
      <Box
        ref={fakeRef}
        visibility="hidden"
        position="absolute"
        top={0}
        left={0}
        sx={{
          pointerEvents: "none",
          whiteSpace: "nowrap",
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
