export default {
  versionUrl: import.meta.env.VITE_REACT_APP_VERSION_URL,
  microsoft: {
    tenant: import.meta.env.VITE_REACT_APP_MICROSOFT_TENANT,
    clientId: import.meta.env.VITE_REACT_APP_MICROSOFT_CLIENT_ID,
  },
  api: {
    ingress: import.meta.env.VITE_REACT_APP_API_INGRESS,
  },
  firebase: {
    apiKey: import.meta.env.VITE_REACT_APP_FBASE_apiKey,
    authDomain: import.meta.env.VITE_REACT_APP_FBASE_authDomain,
    databaseURL: import.meta.env.VITE_REACT_APP_FBASE_databaseURL,
    projectId: import.meta.env.VITE_REACT_APP_FBASE_projectId,
    storageBucket: import.meta.env.VITE_REACT_APP_FBASE_storageBucket,
    messagingSenderId: import.meta.env.VITE_REACT_APP_FBASE_messagingSenderId,
    appId: import.meta.env.VITE_REACT_APP_FBASE_appId,
  },
  amplitude: {
    key: import.meta.env.VITE_REACT_APP_AMPLITUDE_KEY,
  },
};
