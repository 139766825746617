import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

type PageProps = {
  children: React.ReactElement<PageProps>[];
  "data-cy"?: string;
};

const PageBox = styled(Box)<PageProps>(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(2),
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(0.5),
    padding: theme.spacing(0.5),
  },
}));

type PageTitleProps = {
  children: React.ReactElement<PageTitleProps> | string;
  "data-cy"?: string;
};
function PageTitle({
  children,
  "data-cy": dataCy,
}: PageTitleProps) {
  return (
    <Typography data-cy={dataCy} variant="h1">
      { children }
    </Typography>
  );
}

type PageContentProps = {
  children:
    | React.ReactElement<PageContentProps>[]
    | React.ReactElement<PageContentProps>;
  "data-cy"?: string;
};
function PageContent({
  children,
  "data-cy": dataCy,
}: PageContentProps) {
  return (
    <Box
      data-cy={dataCy}
      mt={2}
    >
      { children }
    </Box>
  );
}

function PageComponent({
  children,
  "data-cy": dataCy,
}: PageProps) {
  return (
    <PageBox
      data-cy={dataCy}
    >
      { children }
    </PageBox>
  );
}

PageComponent.Title = PageTitle;
PageComponent.Content = PageContent;

export default PageComponent;
