import React from "react";
import { Box } from "@mui/material";
import {
  Routes, Route, Navigate,
} from "react-router-dom";
import { Organization } from "@/api/Organization";
import ViewNote from "./ViewNote";
import ViewNotes from "./ViewNotes";
import EditNotes from "./EditNotes";

type OrganizationNotesPageProps = {
  company: Organization;
};

function OrganizationNotesPage({
  company,
}: OrganizationNotesPageProps) {
  return (
    <Box>
      <Routes>
        <Route path="" element={<Navigate to="view" replace />} />
        <Route
          path="view"
          element={
            <ViewNotes company={company} />
          }
        />

        <Route
          path="new"
          element={
            <EditNotes company={company} />
          }
        />
        <Route
          path="edit/:noteId"
          element={
            <EditNotes company={company} />
          }
        />

        <Route
          path="view/:noteId"
          element={<ViewNote />}
        />
      </Routes>
    </Box>
  );
}

export default OrganizationNotesPage;
