import React, { useMemo } from "react";
import { useQuery } from "react-query";
import {
  formatBigDollars, formatBigFundingRoundDollars,
} from "@/utils/numberFormat";
import {
  getFundingSourceLink,
} from "@/utils/getFundingSourceLink";
import Section from "@/ui/atoms/Section";
import dayjs from "dayjs";
import {
  Box, Typography, Chip, Grid, Tooltip, Skeleton, Link, useTheme,
} from "@mui/material";
import { Organization, getOrganizationFundingById } from "@/api/Organization";
import MiniTable from "@/components/MiniTable";
import LabeledDetail from "@/components/LabeledDetail";

interface Props {
  company: Organization
}

function FundingRounds({ company }: Props) {
  const {
    data: { fundingRounds = [], midTierFirms = [], topTierFirms = [] } = {}, isLoading,
  } = useQuery(
    ["FundingRounds", company.valorId],
    async () => getOrganizationFundingById(company.valorId),
  );

  const theme = useTheme();

  const mostRecentPost = fundingRounds
    .map(({ postValuation }) => postValuation)
    .filter((y) => !!y)
    ?.[0];
  const totalRaised = company.totalFundingUsd;

  const renderKeyInvestors = () => {
    const combined = [...topTierFirms, ...midTierFirms];
    let combinedInvestors = {};

    const investorData = combined.map((investorName) => {
      const response = {
        valorId: "",
        name: investorName,
        lead: [],
        participated: [],
        tooltip: "",
      };
      fundingRounds.forEach((round) => {
        if (round.leadInvestorNames.indexOf(investorName) !== -1) {
          response.lead.push({ type: round.dealType, date: round.dealDate });
        } else if (round.investorNames.indexOf(investorName) !== -1) {
          response.participated.push({ type: round.dealType, date: round.dealDate });
        }
        combinedInvestors = {
          ...combinedInvestors,
          ...(round.investors?.reduce(
            (acc, { valorId, name }) => ({ ...acc, [name]: valorId }),
            {},
          ) || {}),
          ...(round.leadInvestors?.reduce(
            (acc, { valorId, name }) => ({ ...acc, [name]: valorId }),
            {},
          ) || {}),
        };
      });

      response.valorId = combinedInvestors[investorName];

      if (response.lead.length) {
        response.tooltip = `Lead: ${response.lead.map(({ type, date }) => `${type} ${date ? `on ${dayjs(date).format("MM/DD/YYYY")}` : ""}`).join(", ")}\n\n`;
      }
      if (response.participated.length) {
        response.tooltip += ` Participated: ${response.participated.map(({ type, date }) => `${type} ${date ? `on ${dayjs(date).format("MM/DD/YYYY")}` : ""}`).join(", ")}`;
      }

      return response;
    });

    return (
      <Box display="flex" sx={{ flexWrap: "wrap", gap: "8px" }}>
        {investorData.map(({
          name, tooltip, lead, valorId,
        }) => (
          <Tooltip key={name} title={tooltip}>
            <Chip
              sx={
                {
                  border: lead.length ? `2px solid ${theme.palette.primary.main}` : null,
                  backgroundColor: theme.palette.background.blue,
                }
              }
              size="small"
              label={name}
              component={valorId ? "a" : null}
              href={valorId ? `/org/${valorId}` : null}
              clickable={!!valorId}
            />
          </Tooltip>
        ))}
      </Box>

    );
  };

  const columns = useMemo(() => [
    {
      id: "name",
      header: "Deal Name",
      accessorKey: "dealType",
      cell: ({ row: { original }, getValue }) => {
        const { leadInvestorNames, investorNames } = original;
        const title = `${leadInvestorNames.length ? `Lead: ${leadInvestorNames.join(", ")} \n` : ""}
        ${investorNames.length ? `Participated: ${investorNames.join(", ")}` : ""}`;

        const showTitle = leadInvestorNames.length && investorNames.length;
        return (
          <Tooltip title={showTitle ? title : null} enterNextDelay={300}>
            <span
              style={{
                display: "block",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: 120,
              }}
            >
              {getValue()}
            </span>
          </Tooltip>
        );
      },
    },
    {
      id: "postMoney",
      header: "Post-Money Val.",
      accessorKey: "postValuation",
      cell: ({ getValue }) => {
        const val = getValue();
        if (!val) { return null; }

        return formatBigDollars(val);
      },
    },

    {
      id: "amount",
      header: "Round Size",
      accessorKey: "roundSize",
      cell: ({ getValue }) => {
        const val = getValue();
        if (!val) { return null; }

        return formatBigFundingRoundDollars(val);
      },
    },
    {
      id: "close_date",
      header: "Close Date",
      accessorKey: "dealDate",
      cell: ({ getValue }) => {
        const val = getValue();
        if (!val) { return null; }

        return dayjs(val).format("MM/DD/YY");
      },
    },
  ], []);

  const fundingRoundLink = getFundingSourceLink({
    affId: company.affId,
    pbId: company.pbId,
    cbId: company.cbId,
    fundingSource: fundingRounds?.[0]?.source,
  });

  return (
    <Section>
      <Section.Title>
        Funding Rounds
      </Section.Title>
      <Section.Content>
        <Grid container>
          <Grid item xs={12} sm={12} md={3} lg={12}>
            <Box>
              <LabeledDetail title="NOTABLE INVESTORS" expandable={!!fundingRounds?.length} maxLines={1} lineHeight="25px">
                {isLoading
                  && (
                    <Box display="flex" mb={1} sx={{ flexWrap: "wrap", gap: "8px" }}>
                      <Skeleton variant="rounded" height="32px" width="128px" />
                      <Skeleton variant="rounded" height="32px" width="96px" />
                      <Skeleton variant="rounded" height="32px" width="96px" />
                      <Skeleton variant="rounded" height="32px" width="96px" />
                      <Skeleton variant="rounded" height="32px" width="128px" />
                      <Skeleton variant="rounded" height="32px" width="96px" />
                    </Box>
                  )}
                {fundingRounds.length ? renderKeyInvestors() : !isLoading ? <Typography variant="overline">---</Typography> : null}
              </LabeledDetail>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={12}>
            <Box>
              <LabeledDetail title="TOTAL RAISED">
                {totalRaised ? <Typography variant="body2">{formatBigDollars(totalRaised)}</Typography> : <Typography variant="overline">---</Typography>}
              </LabeledDetail>
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={3} lg={12}>
            <Box>
              <LabeledDetail title="MOST RECENT POST-MONEY VAL.">
                {isLoading
                  && (
                    <Skeleton height="16px" width="64px" />
                  )}
                {mostRecentPost ? <Typography variant="body2">{formatBigDollars(mostRecentPost)}</Typography> : !isLoading ? (
                  <Typography variant="overline">
                    ---
                  </Typography>
                ) : null}
              </LabeledDetail>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <LabeledDetail title="FUNDING ROUNDS" expandable={!!fundingRounds.length} maxLines={8} lineHeight="36px">
              <MiniTable
                isLoading={isLoading}
                data={fundingRounds}
                columns={columns}
                emptyTable="No Funding Rounds Found"
              />
            </LabeledDetail>
            {isLoading ? <Skeleton width="160px" /> : fundingRounds[0]?.source ? (
              <Link target="_blank" href={fundingRoundLink}>
                Sourced from
                {" "}
                {fundingRounds[0]?.source}
              </Link>
            ) : null}
          </Grid>
        </Grid>
      </Section.Content>
    </Section>
  );
}
export default FundingRounds;
