import React from "react";
import {
  Box, Typography, Button,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { Add } from "@mui/icons-material";
import { Droppable, Draggable } from "react-beautiful-dnd";
import { capitalizeFirstLetter } from "@/utils/general";
import RemovableCompany from "../RemovableCompany";

const useStyles = makeStyles(() => createStyles({
  button: {
    height: "30px",
    marginBottom: "8px",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "2px solid #e8e8e8",
  },
  droppableList: {
    overflowX: "auto",
  },
}));

function DroppableList({
  list,
  removeRestriction,
  id,
  highlight,
}) {
  return (
    <Droppable key={id} droppableId={id} direction="horizontal">
      {(provided) => (
        <div
          ref={provided.innerRef}
          style={{
            display: "flex",
            background: highlight ? "lightblue" : "transparent",
            minHeight: "128px",
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...provided.droppableProps}
        >
          {list.map((company, index) => (
            <Draggable
              key={company.valorId}
              draggableId={company.valorId}
              index={index}
            >
              {(provided2) => (
                <div
                  ref={provided2.innerRef}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided2.draggableProps}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...provided2.dragHandleProps}
                >
                  <RemovableCompany
                    company={company}
                    handleRemove={(valorId) => removeRestriction(valorId)}
                  />
                </div>
              )}
            </Draggable>
          ))}
        </div>
      )}
    </Droppable>
  );
}

interface Props {
  values: unknown[];
  handleAddClick(...args: unknown[]): unknown;
  removeRestriction(...args: unknown[]): unknown;
  highlight: boolean;
  id: string;
  prefixTitle?: string;
}
export default function DraggableList(props: Props) {
  const {
    values,
    handleAddClick,
    removeRestriction,
    highlight,
    id,
    prefixTitle = "",
  } = props;
  const classes = useStyles();

  return (
    <Box boxShadow={1} bgcolor="background.paper" borderRadius="10px" p={1}>
      <div className={classes.header}>
        <Typography variant="h3" align="left">
          {`${prefixTitle} ${capitalizeFirstLetter(id)}`}
          {" "}
          (
          {values.length}
          )
        </Typography>
        <Button
          className={classes.button}
          color="primary"
          variant="outlined"
          startIcon={<Add color="inherit" />}
          onClick={handleAddClick}
        >
          ADD
        </Button>
      </div>
      <div className={classes.droppableList}>
        <DroppableList
          list={values}
          removeRestriction={removeRestriction}
          id={id}
          highlight={highlight}
        />
      </div>
    </Box>
  );
}
