import React from "react";
import {
  Box,
  Typography,
  Tooltip,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import CallMadeIcon from "@mui/icons-material/CallMade";
import UserAvatar from "@/ui/atoms/UserAvatar";

function RoleCard({ role, handleMemberClick }) {
  const navigate = useNavigate();

  const empty = (title) => (
    <Box
      border={1}
      borderRadius={6}
      borderColor={(theme) => theme.palette.gray.light}
      marginTop="6px"
      marginBottom="6px"
    >
      <Typography variant="subtitle1" align="center">
        {" "}
        {title}
      </Typography>
    </Box>
  );

  return (
    <Box
      boxShadow={1}
      bgcolor="background.paper"
      borderRadius="10px"
      sx={{
        width: 368,
        marginTop: 2,
      }}
      data-cy={`compliance__role-card-${role.name.toLowerCase()}`}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box
          sx={{
            marginLeft: "10px",
            marginTop: "1px",
          }}
        >
          <Typography variant="h2" align="left">
            {role.name}
          </Typography>
          <Typography variant="body2" align="left">
            {role.description}
          </Typography>
        </Box>
        <Link
          to={`/compliance-and-permissions/role?id=${role.id}`}
          sx={{
            float: "right",
            marginTop: 1,
            marginRight: 0.5,
          }}
        >
          More Details
          <CallMadeIcon style={{ fontSize: 14 }} />
        </Link>
      </Box>
      <Box p={1}>
        <Typography variant="subtitle1" component="p">
          Restrictions (
          {role.restrictions.length}
          )
        </Typography>
        {role.restrictions.length > 0 ? (
          <AvatarGroup max={10}>
            {role.restrictions?.map((company) => (
              <Tooltip
                key={`tooltip-${company.valorId}`}
                title={`${company.name}`}
                onClick={() => navigate(`/org/${company.valorId}`)}
                placement="top"
              >
                <Avatar
                  key={`avatar-${company.valorId}`}
                  style={{ cursor: "pointer" }}
                  alt={company.name}
                  src={company.logoUrl}
                />
              </Tooltip>
            ))}
          </AvatarGroup>
        ) : (
          empty("No Restrictions")
        )}
        <br />
        <Typography variant="subtitle1" component="p">
          Members (
          {role.members.length}
          )
        </Typography>
        {role.members.length > 0 ? (
          <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {role.members?.map((user) => (
              <UserAvatar
                displayTooltip
                key={`avatar-${user.id}`}
                onClick={() => handleMemberClick(user)}
                style={{ cursor: "pointer" }}
                user={user}
              />
            ))}
          </Box>
        ) : (
          empty("No Members")
        )}
      </Box>
    </Box>
  );
}

export default RoleCard;
