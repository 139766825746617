export const Segments = {
  consumer: "Consumer",
  advanced_engineering: "Advanced Engineering",
  real_estate: "Real Estate",
  enterprise_b_2_b: "Enterprise / B2B",
  enterprise_b2b: "Enterprise / B2B",
  vertical_ai: "Vertical AI",
  health: "Health",
  security: "Security",
  food_retail: "Food / Retail",
  fintech: "Fintech",
  blockchain_crypto: "Blockchain / Crypto",
  biotech_pharma: "Biotech / Pharma",
  energy_sustainability: "Energy / Sustainability",
  transportation_logistics: "Transportation / Logistics",
  metaverse: "Metaverse",
  advanced_computing: "Advanced Computing",
};

export default Segments;
