import React, {
  useState, useMemo, useEffect, forwardRef, useImperativeHandle,
} from "react";
import {
  Box, Typography, Collapse,
} from "@mui/material";
import { FieldList } from "@/api/Process";
import Input from "@/components/FormInputs/Input";

import { uniqBy } from "lodash";
import { FIELD_VALUE_TYPE_MAP as typeMap } from "@/pages/ProcessManagement/constants";
import TaskFormLoader from "./TaskFormLoader";
import {
  getFieldsByStage,
  getHasLifecycle,
  SPECIAL_FIELD_SETTING_IDS,
} from "../ProcessView/ViewUtils";
import useProcess from "../ProcessContext/useProcess";
import useTask from "../ProcessContext/useTask";

type EditTaskFormProps = {
  processId: string;
  taskId: string;
  onBeforeUpdate: () => void;
  onUpdateError: () => void;
  onUpdate: () => void;
  scroll?: "none" | "auto";
  alert: React.ReactNode;
};

const Field = React.memo(({
  field,
  value,
  onChange,
  onBlur,
  organizationName,
  required,
  error,
  disabled,
}: {
  field: FieldList[0];
  value: any;
  onChange: (v: any, f: FieldList[0]) => void;
  onBlur: (v: any, f: FieldList[0]) => void;
  organizationName: string;
  required: boolean;
  error: boolean;
  disabled: boolean;
}) => (
  <Input
    dataCy={`fields.${field.type}.${field.id}`}
    key={field.id}
    type={field.type}
    name={field.name}
    value={value}
    onChange={(newValue) => onChange(newValue, field)}
    onBlur={(v) => onBlur(v, field)}
    choices={field.choices}
    isOrganizationField={field.isOrganizationField}
    organizationName={organizationName}
    required={required}
    disabled={disabled}
    error={error}
  />
));
Field.displayName = "Field";

function MaskedField({
  field,
  value,
  // onChange,
  onBlur,
  organizationName,
  required,
  error,
  disabled,
}: {
  field: FieldList[0];
  value: any;
  // onChange: (value: any, field: FieldList[0]) => void;
  onBlur: (v: any, f: FieldList[0]) => void;
  organizationName: string;
  required: boolean;
  error: boolean;
  disabled: boolean;
}) {
  const [bareValue, setBareValue] = useState();
  const [maskValue, setMaskValue] = useState();

  useEffect(() => {
    if (bareValue !== value) {
      setBareValue(value);
    }
  }, [value, bareValue]);

  const inputValue = useMemo(() => {
    if (maskValue !== undefined) {
      return maskValue;
    }
    if (bareValue !== undefined) {
      return bareValue;
    }
    return null;
  }, [maskValue, bareValue]);

  return (
    <Field
      field={field}
      value={inputValue}
      onChange={(newValue) => {
        setMaskValue(newValue);
      }}
      onBlur={onBlur}
      organizationName={organizationName}
      required={required}
      error={error}
      disabled={disabled}
    />
  );
}

const EditTaskForm = forwardRef((
  {
    processId,
    taskId,
    onBeforeUpdate,
    onUpdateError,
    onUpdate,
    scroll,
    alert,
  }: EditTaskFormProps,
  ref: React.Ref<{ validate: () => boolean }>,
) => {
  const {
    data: {
      fields: fieldData,
      lifecycleProcessFieldId: lifecycleId,
      lifecycleStages,
    },
    isLoading: isLoadingFields,
  } = useProcess(processId);

  const {
    data: {
      task: serverValue,
    },
    actions: {
      refetch,
      updateTaskFieldValue,
    },
    isLoading: isLoadingTask,
  } = useTask(processId, taskId);

  const [openStage, setOpenStage] = useState(null);

  const nameField = fieldData?.find(
    (field) => field?.settingId === SPECIAL_FIELD_SETTING_IDS.NAME,
  );
  const descriptionField = fieldData?.find(
    (field) => field?.settingId === SPECIAL_FIELD_SETTING_IDS.DESCRIPTION,
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [maskValues, setMaskValues] = useState<any | null>(null);
  const computedValues = useMemo(() => {
    // combine maskValues and value.fieldValues
    const currentValues = serverValue?.fieldValues;
    const mask = maskValues;
    if (!mask || Object.keys(mask).length === 0) {
      return currentValues;
    }

    return {
      ...(serverValue?.fieldValues || {}),
      ...(maskValues || {}),
    };
  }, [serverValue, maskValues]);

  const remainingFields = fieldData?.filter(
    (field) => !(
      field?.settingId === SPECIAL_FIELD_SETTING_IDS.DESCRIPTION
        || field?.settingId === SPECIAL_FIELD_SETTING_IDS.NAME
    ),
  );
  const formFields = remainingFields?.filter(
    (field) => field.showOnForm !== false,
  );

  const [primaryCompanyFieldsDisabled, setPrimaryCoFieldsDisabled] = useState(false);

  const hasLifecycle = React.useMemo(
    () => getHasLifecycle(lifecycleStages),
    [lifecycleStages],
  );
  const fieldsByStage = React.useMemo(
    () => getFieldsByStage(lifecycleId, lifecycleStages, fieldData),
    [lifecycleId, lifecycleStages, fieldData],
  );

  const primaryCompanyField = useMemo(
    () => fieldData?.find((f) => f.isPrimary && f.type === "company"),
    [fieldData],
  );
  const primaryCompany = useMemo(
    () => computedValues?.[primaryCompanyField?.id]?.company,
    [computedValues, primaryCompanyField],
  );
  const primaryValorIdValue = primaryCompany?.valorId;

  const currentStageId = useMemo(() => {
    const lifecycleFieldValue = computedValues?.[lifecycleId]?.choiceId
      || computedValues?.fieldValues?.[lifecycleId]?.choiceId
      || null;
    return lifecycleFieldValue;
  }, [computedValues, lifecycleId]);

  const getRequiredFields = React.useCallback((
    stageId,
    stages,
    fields,
  ) => {
    let required = fields.filter((f) => f.required);
    const allStage = stages?.find((s) => s.stageId === null);
    const currentStage = stages?.find((s) => s.stageId === stageId);
    if (allStage || currentStage) {
      required = uniqBy([
        ...uniqBy([
          ...(allStage?.fields || []),
          ...(currentStage?.fields || []),
        ], "childProcessFieldId").filter((f) => f.required)
          .map(({ childProcessFieldId }) => (
            fields
              .find((f) => f.id === childProcessFieldId)
          )),
        ...required,
      ], "id");
    }
    const result = required.map((f) => ({
      ...f,
      stages: stages?.filter((s) => s.fields.some((field) => (
        field.childProcessFieldId === f.id
        && field.visible
      ))),
    }), []);
    return result;
  }, []);

  const getInvalidFields = React.useCallback((values, requiredFields) => requiredFields.filter(
    ({ id: fieldId, type }) => {
      const fieldValue = values
        ?.[fieldId]
        ?.[typeMap[type]];
      if (fieldValue === undefined || fieldValue === null) {
        return true;
      }
      if (Array.isArray(fieldValue) && fieldValue.length === 0) {
        return true;
      }
      return false;
    },
  ), []);

  const requiredFields = useMemo(() => getRequiredFields(
    currentStageId,
    lifecycleStages,
    fieldData,
  ), [currentStageId, lifecycleStages, fieldData, getRequiredFields]);

  const unfilledRequiredFields = useMemo(() => {
    const emptyRequiredField = getInvalidFields(
      computedValues,
      requiredFields,
    );
    return emptyRequiredField;
  }, [computedValues, requiredFields, getInvalidFields]);

  const [validating, setValidating] = useState(false);
  useImperativeHandle(ref, () => ({
    validate: () => {
      setValidating(true);

      // validate every required field has a value
      const required = getRequiredFields(
        currentStageId,
        lifecycleStages,
        fieldData,
      );

      // set error state for required
      const emptyRequiredFields = getInvalidFields(
        computedValues,
        required,
      );

      // return if valid
      return emptyRequiredFields.map((f) => ({
        field: f,
        error: "required",
      }));
    },
  }));

  useEffect(() => {
    // get lifecycle field value id
    setOpenStage(currentStageId);
  }, [currentStageId]);

  const pushChanges = async (formValues: T) => {
    // get diff of changes
    const values = JSON.parse(JSON.stringify(formValues));

    if (Object.keys(values).length === 0) {
      return;
    }

    const hasFields = Object.keys(values || {}).length > 0;

    // if primary company field has changed, fetch new company data
    const currentPCFValue = primaryCompanyField
      && values?.fieldValues?.[primaryCompanyField.id]?.company?.valorId;
    const newPCFValue = primaryCompanyField
      && formValues?.[primaryCompanyField.id]?.company?.valorId;
    const hasPrimaryCompanyChanged = currentPCFValue !== newPCFValue && newPCFValue !== undefined;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let updatePromises: Promise<any>[] = [];
    if (hasFields) {
      const changedFields = Object.entries(values)
        .map(([fieldId, fieldValues]) => {
          // get field
          const field = fieldData.find((f) => f.id === fieldId);
          if (Array.isArray(fieldValues)) {
            return fieldValues.map((fieldValue) => ({
              fieldId,
              fieldValue: fieldValue[typeMap[field.type]],
            }));
          }
          return { fieldId, fieldValue: fieldValues[typeMap[field.type]] };
        })
        .flat();

      const changedFieldsWithFieldData = changedFields.map(
        ({ fieldId, fieldValue }) => {
          const foundField = fieldData.find((f) => f.id === fieldId);
          return {
            fieldId,
            fieldType: foundField.type,
            fieldValue,
            isOrganizationField: foundField?.isOrganizationField,
            settingId: foundField?.settingId,
          };
        },
      );

      const fieldValueUpdates = changedFieldsWithFieldData.map(
        ({
          fieldId, fieldType, fieldValue, isOrganizationField, settingId,
        }) => updateTaskFieldValue(
          processId,
          taskId,
          fieldType,
          fieldId,
          settingId,
          fieldValue,
          isOrganizationField,
          primaryValorIdValue,
        ),
      );

      updatePromises = fieldValueUpdates;
    }

    onBeforeUpdate();
    try {
      if (hasPrimaryCompanyChanged) {
        setPrimaryCoFieldsDisabled(true);
        await Promise.all(updatePromises);
      } else {
        await Promise.all(updatePromises);
      }

      refetch().then(() => {
        setPrimaryCoFieldsDisabled(false);
        // unset any mask values present in values
        setMaskValues((prev) => {
          const newValues = { ...prev };
          Object.keys(values).forEach((key) => {
            delete newValues[key];
          });
          return newValues;
        });
      });

      onUpdate();
    } catch (e) {
      onUpdateError();
    }
  };

  const handleBlur = (newValue, field) => {
    const newValObj = {
      [typeMap[field.type]]: newValue,
    };

    const oldValue = serverValue?.fieldValues?.[field.id]?.[typeMap[field.type]];
    if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
      // eslint-disable-next-line eqeqeq
      if (
        oldValue === undefined
        && ((Array.isArray(newValue) && newValue.length === 0) || !newValue)
        && field.type !== "checkbox"
      ) {
        return;
      }
      pushChanges({ [field.id]: newValObj });

      // update mask
      setMaskValues((prev) => ({
        ...prev,
        [field.id]: newValObj,
      }));
    }
  };

  if (isLoadingFields || isLoadingTask || !taskId) {
    return <TaskFormLoader edit />;
  }

  function getStageIdentifier(stageId, stageName, isFieldDependent) {
    if (isFieldDependent) {
      return stageName;
    }
    return stageId;
  }

  return (
    <Box
      display="flex"
      flexDirection={{
        xs: "column",
        md: "row",
      }}
      gap={1}
      width="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        flexGrow={1}
        padding={1}
        sx={{
          overflowY: {
            xs: "visible",
            md: "auto",
          },
          maxHeight: {
            xs: "auto",
            md: scroll !== "none" ? "80vh" : "auto",
          },
        }}
      >
        {alert}
        <Box display="flex" flexDirection="column" gap={1}>
          {!hasLifecycle && (
            <>
              {nameField
                && (
                  <MaskedField
                    key={nameField.id}
                    field={nameField}
                    value={computedValues?.[nameField.id]?.[typeMap[nameField.type]]}
                    onBlur={handleBlur}
                    organizationName={primaryCompany?.name}
                    required={requiredFields.some((f) => f.id === nameField.id)}
                    disabled={false}
                    error={validating
      && unfilledRequiredFields.some((f) => f.id === nameField.id)}
                  />
                )}

              {descriptionField
                && (
                  <MaskedField
                    key={descriptionField.id}
                    field={descriptionField}
                    value={computedValues?.[descriptionField.id]?.[typeMap[descriptionField.type]]}
                    onBlur={handleBlur}
                    organizationName={primaryCompany?.name}
                    required={requiredFields.some((f) => f.id === descriptionField.id)}
                    disabled={false}
                    error={validating
      && unfilledRequiredFields.some((f) => f.id === descriptionField.id)}
                  />
                )}

              {formFields?.map(
                (d) => d
                  && (
                    <MaskedField
                      key={d.id}
                      field={d}
                      value={computedValues?.[d.id]?.[typeMap[d.type]]}
                      onBlur={handleBlur}
                      organizationName={primaryCompany?.name}
                      required={requiredFields.some((f) => f.id === d.id)}
                      disabled={(d.isOrganizationField
                                                && (primaryCompanyFieldsDisabled
                                                  // has primary company
                                                  || !primaryValorIdValue))
                                              || d.readOnly}
                      error={validating
        && unfilledRequiredFields.some((f) => f.id === d.id)}
                    />
                  ),
              )}
            </>
          )}
          {hasLifecycle && (
            <Box>
              {fieldsByStage
                .filter((stage) => stage.hasVisibleFields)
                .map(
                  ({
                    stageId, stageName, isFieldDependent, fields,
                  }) => (
                    <Box key={`stage-${stageId || stageName}`} my={1}>
                      {stageId && stageName.length > 0 && (
                      <Box
                        sx={{
                          cursor: "pointer",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          gap: 1,
                          borderWidth: 1,
                          borderStyle: "solid",
                          borderColor: (theme) => (
                            (
                              validating
                              && unfilledRequiredFields.some(
                                (f) => (
                                  f.stages.some((stage) => (
                                    getStageIdentifier(stageId, stageName, isFieldDependent)
                                    === getStageIdentifier(
                                      stage?.stageId,
                                      stage?.stageName,
                                      isFieldDependent,
                                    )
                                  ))
                                ),
                              )
                            )
                              ? theme.palette.error.main
                              : theme.palette.divider
                          ),
                          background: (theme) => (
                            getStageIdentifier(stageId, stageName, isFieldDependent)
                            === currentStageId
                            || (
                              getStageIdentifier(stageId, stageName, isFieldDependent) === openStage
                              && isFieldDependent
                            )
                              ? theme.palette.blue.main
                              : theme.palette.disabled),
                          color: (theme) => (
                            getStageIdentifier(stageId, stageName, isFieldDependent)
                            === currentStageId
                            || (
                              getStageIdentifier(stageId, stageName, isFieldDependent) === openStage
                              && isFieldDependent
                            )
                              ? theme.palette.getContrastText(
                                theme.palette.blue.main,
                              )
                              : (
                                validating
                                && unfilledRequiredFields.some(
                                  (f) => (
                                    f.stages.some((stage) => (
                                      getStageIdentifier(stageId, stageName, isFieldDependent)
                                      === getStageIdentifier(
                                        stage?.stageId,
                                        stage?.stageName,
                                        isFieldDependent,
                                      )
                                    ))
                                  ),
                                )
                              )
                                ? theme.palette.error.main
                                : theme.palette.text.primary
                          ),
                          fontWeight: (theme) => (
                            getStageIdentifier(stageId, stageName, isFieldDependent)
                            === currentStageId
                            || (
                              getStageIdentifier(stageId, stageName, isFieldDependent) === openStage
                              && isFieldDependent
                            )
                              ? theme.typography.fontWeightBold
                              : theme.typography.fontWeightRegular),
                          px: 1,
                        }}
                        onClick={() => {
                          if (!isFieldDependent) {
                            setOpenStage((prev) => (prev === stageId ? null : stageId));
                          } else {
                            setOpenStage((prev) => (prev === stageName ? null : stageName));
                          }
                        }}
                        data-cy={`stage-${stageId}`}
                      >
                        <Typography variant="overline">{stageName}</Typography>
                      </Box>
                      )}
                      <Collapse
                        in={
                          getStageIdentifier(stageId, stageName, isFieldDependent) === openStage
                          || getStageIdentifier(stageId, stageName, isFieldDependent) === null
                          || getStageIdentifier(stageId, stageName, isFieldDependent) === "all"
                          || stageName.length === 0
                        }
                        key={stageId || stageName}
                      >
                        <Box display="flex" flexDirection="column" gap={1} my={1}>
                          {fields
                            .filter((field) => field.visible)
                            .map((field) => {
                              if (field) {
                                return (
                                  <MaskedField
                                    key={field.id}
                                    field={field}
                                    value={computedValues?.[field.id]?.[typeMap[field.type]]}
                                    onBlur={handleBlur}
                                    choices={field.choices}
                                    isOrganizationField={field.isOrganizationField}
                                    organizationName={primaryCompany?.name}
                                    required={requiredFields.some((f) => f.id === field.id)}
                                    disabled={(field.isOrganizationField
                                      && (primaryCompanyFieldsDisabled
                                        // has primary company
                                        || !primaryValorIdValue))
                                    || field.readOnly}
                                    error={validating
                      && unfilledRequiredFields.some((f) => f.id === field.id)}
                                  />
                                );
                              }
                              return null;
                            })}
                        </Box>
                      </Collapse>
                    </Box>
                  ),
                )}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
});

EditTaskForm.displayName = "EditTaskForm";
export default EditTaskForm;
