import React, { useState, useMemo } from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Skeleton,
  Snackbar,
  TextField,
} from "@mui/material";
import Section from "@/ui/atoms/Section";
import { useQuery } from "react-query";
import { Form, Field } from "react-final-form";
import {
  getReportingPeriodTypes,
  getOrganizationReportingPeriods,
  putOrganizationReportingPeriods,
} from "@/api/Metrics";

type ReportingPeriodsProps = {
  valorId: string;
  disabled?: boolean;
};

function ReportPeriodTypeLoader() {
  const width = 100;
  const height = 30;
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={0.5}
      py={1}
    >
      <Checkbox disabled />
      <Skeleton variant="rounded" width={width} height={height} />
      <Checkbox disabled />
      <Skeleton variant="rounded" width={width} height={height} />
      <Checkbox disabled />
      <Skeleton variant="rounded" width={width} height={height} />
      <Checkbox disabled />
      <Skeleton variant="rounded" width={width} height={height} />
    </Box>
  );
}

export default function ReportingPeriods({
  valorId,
  disabled,
}: ReportingPeriodsProps) {
  const {
    data: reportingPeriodTypes = [],
    isLoading: reportingPeriodTypesLoading,
    error: reportingPeriodTypesError,
  } = useQuery(
    "reportingPeriodTypes",
    () => getReportingPeriodTypes().then((data) => data.filter((x) => x.type !== "OTHER")),
  );

  const {
    data: reportingPeriodsData = [],
    isLoading: reportingPeriodsLoading,
    error: reportingPeriodsError,
    refetch: refetchReportingPeriods,
  } = useQuery(
    ["reportingPeriods", valorId],
    () => getOrganizationReportingPeriods(valorId),
    { refetchInterval: false },
  );

  const [successOpen, setSuccessOpen] = useState(false);

  const formState = useMemo(() => ({
    ...(reportingPeriodsData.reduce((acc, item) => ({
      ...acc,
      [`reportingPeriodType-${item.periodType}`]: {
        checked: (
          reportingPeriodsData
            .map((x) => x.periodType)
            .includes(item.periodType)
        ),
        title: item.otherTitle,
        description: item.otherDescription,
      },
    }), {})),
  }), [reportingPeriodsData]);

  if (reportingPeriodTypesError || reportingPeriodsError) {
    return (
      <Section mt={1}>
        <Section.Title>Reporting Periods</Section.Title>
        <Section.Content>
          <Alert severity="error">
            There was an error loading reporting periods.
            {" "}
            Please refresh the page or contact the labs team if issues persist.
          </Alert>
        </Section.Content>
      </Section>
    );
  }

  return (
    <Section>
      <Section.Title>Reporting Periods</Section.Title>
      <Section.Content>
        {reportingPeriodTypesLoading || reportingPeriodsLoading ? (
          <ReportPeriodTypeLoader />
        ) : (
          <Box
            gap={1}
            py={1}
          >
            <Form
              initialValues={formState}
              onSubmit={async (values: {
              [key: string]: {
                checked: boolean;
                title: string;
                description: string;
              }
            }) => {
                const payload = Object.entries(values)
                  .map(([key, value]) => {
                    const [, periodType] = key.split("-");
                    if (value.checked) {
                      return {
                        periodType,
                        otherTitle: value.title,
                        otherDescription: value.description,
                      };
                    }
                    return null;
                  })
                  .filter((x) => x !== null);
                await putOrganizationReportingPeriods(valorId, payload);
                setSuccessOpen(true);
                refetchReportingPeriods();
              }}
              render={({
                handleSubmit,
                values,
                form,
                submitting,
              }) => (
                <form onSubmit={handleSubmit}>
                  <FormControl component="fieldset">
                    <FormGroup
                      aria-label="Reporting Periods"
                      row
                    >
                      {reportingPeriodTypes.map((item) => (
                        <Box
                          key={item.type}
                          width={item.hasAdditionalInfo ? "100%" : "auto"}
                        >
                          <Field
                            key={item.type}
                            name={`reportingPeriodType-${item.type}.checked`}
                            type="checkbox"
                            render={({ input }) => (
                              <FormControlLabel
                                key={item.type}
                                value={item.type}
                                control={(
                                  <Checkbox
                                    color="primary"
                                    size="small"
                                    value={input.value}
                                    checked={input.checked}
                                    onChange={input.onChange}
                                    disabled={disabled || submitting}
                                  />
                              )}
                                label={item.title}
                                labelPlacement="end"
                              />
                            )}
                          />
                          {item.hasAdditionalInfo && values[`reportingPeriodType-${item.type}`]?.checked && (
                          <Box
                            display="flex"
                            flexDirection="column"
                            gap={1}
                          >
                            <Field
                              key={`${item.type}-title`}
                              name={`reportingPeriodType-${item.type}.title`}
                              render={({ input, meta }) => (
                                <TextField
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...input}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...meta}
                                  label={`${item.title} Title`}
                                  onChange={(event) => input.onChange(event.target.value)}
                                  error={meta.submitFailed && meta.error}
                                  disabled={disabled || submitting}
                                />
                              )}
                            />
                            <Field
                              key={`${item.type}-description`}
                              name={`reportingPeriodType-${item.type}.description`}
                              render={({ input, meta }) => (
                                <TextField
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...input}
                                  // eslint-disable-next-line react/jsx-props-no-spreading
                                  {...meta}
                                  multiline
                                  label={`${item.title} Description`}
                                  onChange={(event) => input.onChange(event.target.value)}
                                  error={meta.submitFailed && meta.error}
                                  disabled={disabled || submitting}
                                />
                              )}
                            />
                          </Box>
                          )}
                        </Box>
                      ))}
                    </FormGroup>
                  </FormControl>
                  <Box
                    mt={1}
                    display="flex"
                    flexDirection="row"
                    gap={1}
                  >
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={disabled || submitting}
                    >
                      Save Reporting Periods
                    </Button>
                    {!submitting && (
                    <Button
                      type="reset"
                      variant="outlined"
                      color="primary"
                      onClick={() => {
                        form.reset();
                      }}
                      disabled={disabled}
                    >
                      Reset
                    </Button>
                    )}
                  </Box>
                </form>
              )}
            />
          </Box>
        )}
      </Section.Content>
      <Snackbar
        open={successOpen}
        autoHideDuration={6000}
        onClose={() => setSuccessOpen(false)}
        message="Reporting Periods Saved"
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" onClose={() => setSuccessOpen(false)}>
          Reporting Periods Saved
        </Alert>
      </Snackbar>
    </Section>
  );
}
