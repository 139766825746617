import { useCallback } from "react";
import { useSocket } from "@/hooks/useSocket";
import { useAuth } from "@/hooks/useAuth";

export const JOIN_CONVERSATION = "join_conversation";
export const LEAVE_CONVERSATION = "leave_conversation";
export const STOP_STREAM = "stop_stream";
export const SEND_MESSAGE = "chat_message";

export const CHAT_RESPONSE_CHUNK = "chat_response_chunk";
export const CHAT_ERROR = "error";
export const CHAT_RESPONSE_COMPLETE = "chat_response_complete";
export const STREAM_CANCELLED = "stream_cancelled";
export const CHAT_RESPONSE_CANCELLED = "chat_response_cancelled";

export default function useChat() {
  const socket = useSocket();

  const { user } = useAuth();

  const joinConversation = useCallback((conversationId) => {
    socket.emit(JOIN_CONVERSATION, {
      conversationId,
    });
  }, [socket]);
  const leaveConversation = useCallback((conversationId) => {
    socket.emit(LEAVE_CONVERSATION, {
      conversationId,
    });
  }, [socket]);
  const stopStream = useCallback((conversationId) => {
    socket.emit(STOP_STREAM, {
      conversationId,
    });
  }, [socket]);
  const sendMessage = useCallback((conversationId, message) => {
    socket.emit(SEND_MESSAGE, {
      conversationId,
      message,
      userId: user.id,
    });
  }, [socket, user.id]);

  return {
    joinConversation,
    leaveConversation,
    stopStream,
    sendMessage,
    subscribe: (...args) => socket.on(...args),
    unsubscribe: (...args) => socket.off(...args),
  };
}
