import React from "react";
import { styled } from "@mui/system";
import SvgIcon from "@mui/material/SvgIcon";

const Rect = styled("rect", {
  shouldForwardProp: (prop) => prop !== "filled",
})(({ theme, filled }) => ({
  fill: (filled ? theme.palette.green.main : theme.palette.action.disabled),
}));

export default function Signal(props) {
  const { bars } = props;
  return (
    <SvgIcon>
      <Rect
        filled={bars >= 1}
        x="2.73"
        y="12.03"
        width="4"
        height="8"
        rx="2"
      />
      <Rect
        filled={bars >= 2}
        x="9.73"
        y="8.03"
        width="4"
        height="12"
        rx="2"
      />
      <Rect
        filled={bars >= 3}
        x="16.74"
        y="4.03"
        width="4"
        height="16"
        rx="2"
      />
    </SvgIcon>
  );
}
