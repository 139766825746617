import React, { useState, useEffect } from "react";
import {
  AppBar, Box, Button, Toolbar, Typography, ButtonBase, TextField,
} from "@mui/material";
import IHEChangeCellIcon from "@/pages/IHEChange/IHEIcon";
import Progress from "@/ui/atoms/Progress";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import { useDebounce } from "@/hooks/useDebounce";
import { ArrowBack } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

type VotingFabProps = {
  activeVote: "AGREE" | "DISAGREE" | "DISCUSS";
  activeComment: string;
  isSavingComment: boolean;
  handleVoteChange: (vote: "AGREE" | "DISAGREE" | "DISCUSS") => void;
  handleCommentChange: (comment: string) => void;
  isLoading: boolean;
  backLocation?: string;
};
function VotingFab({
  activeVote,
  activeComment,
  isSavingComment,
  handleVoteChange,
  handleCommentChange,
  isLoading,
  backLocation,
}: VotingFabProps) {
  const isActiveAgree = activeVote === "AGREE";
  const isActiveDisagree = activeVote === "DISAGREE";
  const isActiveDiscuss = activeVote === "DISCUSS";
  const isInTouchPWA = useIsInTouchPWA();

  const [comment, setComment] = useState(activeComment);
  const debouncedComment = useDebounce(comment, 500);

  useEffect(() => {
    if (debouncedComment !== activeComment) {
      handleCommentChange(debouncedComment);
    }
  }, [debouncedComment, activeComment, handleCommentChange]);

  return (
    <AppBar
      position="fixed"
      sx={{
        top: "auto",
        bottom: {
          xs: isInTouchPWA ? 62 : 0,
          sm: isInTouchPWA ? 62 : 16,
        },
        left: "50%",
        backgroundColor: (t) => t.palette.background.paper,
        color: (t) => t.palette.text.primary,
        transform: "translateX(-50%)",
        maxWidth: "600px",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        borderRadius: (t) => t.spacing(1),
      }}
    >
      <Box>
        <Toolbar>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              margin: 1,
            }}
          >
            {isLoading ? <Progress /> : null}
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              alignItems="flex-start"
              justifyContent="space-between"
              sx={{ width: "100%" }}
            >
              {backLocation ? (
                <Button
                  to={decodeURIComponent(backLocation)}
                  LinkComponent={RouterLink}
                  variant="outlined"
                  color="inherit"
                  startIcon={<ArrowBack />}
                  // sx={{ marginBottom: 1 }}
                >
                  Back
                </Button>
              ) : null}
              <Typography
                variant="h6"
                component="div"
                sx={{ flexGrow: 1, textAlign: "center", width: "100%" }}
              >
                Do you approve/disapprove of the recommendation?
              </Typography>
            </Box>
            <Box display="flex" flexDirection="row" gap={1}>
              <ButtonBase
                onClick={() => handleVoteChange("AGREE")}
                disabled={isLoading}
                color="inherit"
                sx={{
                  display: "flex",
                  gap: "4px",
                  padding: 1,
                  "&:hover": {
                    backgroundColor: (t) => t.palette.action.hover,
                  },
                  "&.Mui-disabled": {
                    color: (t) => t.palette.text.disabled,
                  },
                  border: (t) => (isActiveAgree
                    ? `1px solid ${t.palette.green.main}`
                    : "1px solid transparent"),
                  borderRadius: (t) => t.spacing(1),
                }}
              >
                Approve
                <IHEChangeCellIcon
                  color="green"
                  size="big"
                  animated={isActiveAgree}
                />
              </ButtonBase>
              <ButtonBase
                color="inherit"
                onClick={() => handleVoteChange("DISAGREE")}
                disabled={isLoading}
                sx={{
                  display: "flex",
                  gap: "4px",
                  padding: 1,
                  "&:hover": {
                    backgroundColor: (t) => t.palette.action.hover,
                  },
                  "&.Mui-disabled": {
                    color: (t) => t.palette.text.disabled,
                  },

                  border: (t) => (isActiveDisagree
                    ? `1px solid ${t.palette.red.main}`
                    : "1px solid transparent"),
                  borderRadius: (t) => t.spacing(1),
                }}
              >
                Disapprove
                <IHEChangeCellIcon
                  color="red"
                  size="big"
                  animated={isActiveDisagree}
                />
              </ButtonBase>
              <ButtonBase
                onClick={() => handleVoteChange("DISCUSS")}
                disabled={isLoading}
                color="inherit"
                sx={{
                  display: "flex",
                  gap: "4px",
                  padding: 1,
                  "&:hover": {
                    backgroundColor: (t) => t.palette.action.hover,
                  },
                  "&.Mui-disabled": {
                    color: (t) => t.palette.text.disabled,
                  },
                  border: (t) => (isActiveDiscuss
                    ? `1px solid ${t.palette.gray.main}`
                    : "1px solid transparent"),
                  borderRadius: (t) => t.spacing(1),
                }}
              >
                Discuss
                <IHEChangeCellIcon
                  color="gray"
                  size="big"
                  animated={isActiveDiscuss}
                />
              </ButtonBase>
            </Box>
            <Box sx={{ height: "32px" }}>
              {isSavingComment ? (
                <Box display="flex">
                  Saving Comment...
                  <Progress />
                </Box>
              ) : null}
            </Box>
            <TextField
              label="Comments"
              InputLabelProps={{ shrink: true }}
              disabled={!activeVote}
              multiline
              rows={isInTouchPWA ? 2 : 4}
              defaultValue={activeComment}
              fullWidth
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </Box>
        </Toolbar>
      </Box>
    </AppBar>
  );
}

export default VotingFab;
