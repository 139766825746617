import React from "react";
import {
  Box, Typography,
} from "@mui/material";
import Pipeline from "./Pipeline";

export default function LeanGrowthUpdates() {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography variant="h2">Lean/Growth Key Updates</Typography>
      <Pipeline />
    </Box>
  );
}
