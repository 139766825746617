import React from "react";
import dayjs from "dayjs";
import { Box, Typography } from "@mui/material";

export default function DateCell({ value }: { value: number | string | null }) {
  if (value === null) {
    return (<>-</>);
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
    >
      <Typography
        variant="body2"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
        })}
      >
        {dayjs(value).fromNow()}
      </Typography>
      {dayjs(value).format("MM/DD/YYYY")}
    </Box>
  );
}
