import React from "react";
import {
  Box,
  Typography,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  Chip,
  Menu,
} from "@mui/material";
import {
  Edit, Delete,
  DragIndicatorOutlined,
  Add,
  MoreVert,
} from "@mui/icons-material";
import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

export default function FieldListItem({
  field,
  onEdit,
  onDelete,
  onLink,
  disabled,
}: {
  field: {
    id: string;
    name: string;
  };
  onEdit?: (f) => void;
  onDelete?: () => void;
  onLink?: (fieldId: string) => void;
  disabled?: boolean;
}) {
  const [showDelete, setShowDelete] = React.useState(false);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const anchorEl = React.useRef(null);

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        opacity: disabled ? 0.5 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
      >
        <DragIndicatorOutlined
          sx={{
            cursor: "grab",
          }}
        />
        <Typography>
          {field.name}
        </Typography>
        {field.isOrganizationField && (<Chip label="Org" variant="outlined" size="small" />)}
      </Box>

      <Box
        display={{
          xs: "none",
          md: "flex",
        }}
        flexBasis={0}
      >
        {onEdit && (
          <Tooltip title="Edit Field">
            <IconButton
              onClick={() => {
                onEdit(field);
              }}
            >
              <Edit />
            </IconButton>
          </Tooltip>
        )}
        {onDelete && (
          <Tooltip title="Delete Field">
            <IconButton
              onClick={() => {
                setShowDelete(true);
              }}
            >
              <Delete />
            </IconButton>
          </Tooltip>
        )}
        {onLink && (
          <Tooltip title="Add Field to Process">
            <IconButton
              onClick={() => {
                onLink(field.id);
              }}
            >
              <Add />
            </IconButton>
          </Tooltip>
        )}
      </Box>

      <Box
        display={{
          xs: "auto",
          md: "none",
        }}
      >
        <IconButton
          ref={anchorEl}
          onClick={() => setMenuOpen(true)}
        >
          <MoreVert />
        </IconButton>
        <Menu
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          anchorEl={anchorEl.current}
        >
          <ListItem
            onClick={() => {
              onEdit(view.id);
              setMenuOpen(false);
            }}
          >
            <ListItemIcon>
              <Edit />
            </ListItemIcon>
            <ListItemText>
              Edit View
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              setShowDelete(true);
              setMenuOpen(false);
            }}
          >
            <ListItemIcon>
              <Delete />
            </ListItemIcon>
            <ListItemText>
              Delete View
            </ListItemText>
          </ListItem>
          <ListItem
            onClick={() => {
              onLink(view.id);
              setMenuOpen(false);
            }}
          >
            <ListItemIcon>
              <Add />
            </ListItemIcon>
            <ListItemText>
              Add View to Process
            </ListItemText>
          </ListItem>
        </Menu>
      </Box>

      <Dialog
        open={showDelete}
        onClose={() => {
          setShowDelete(false);
        }}
      >
        <DialogTitle>
          Are you sure you want to delete
          {" "}
          {field?.name}
          ?
        </DialogTitle>
        <DialogContent>
          This will permanantly delete the field.
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onDelete();
              setShowDelete(false);
            }}
            color="primary"
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              setShowDelete(false);
            }}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
