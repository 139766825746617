import React from "react";
import {
  Box, Chip,
  ClickAwayListener,
} from "@mui/material";
import UserAvatar from "@/ui/atoms/UserAvatar";
import OverflowBox from "./OverflowBox";

type UserValue = {
  id: string;
  firstName: string;
  lastName: string;
  profilePicture: string;
};

const renderUsers = (users: UserValue[], showLabel: boolean = true) => users.map((user) => {
  const fullName = `${user.firstName} ${user.lastName}`;

  const avatarUser = {
    name: fullName,
    profilePicture: user.profilePicture,
  };

  if (!showLabel) {
    return (
      <UserAvatar
        key={user.id}
        user={avatarUser}
        style={{
          width: "32px",
          height: "32px",
          fontSize: "1em",
        }}
      />
    );
  }

  return (
    <Chip
      key={user.id}
      avatar={(
        <UserAvatar
          user={avatarUser}
          style={{
            width: "25px",
            height: "25px",
            fontSize: "1em",
          }}
        />
        )}
      label={`${user.firstName} ${user.lastName}`}
      sx={{ height: "25px" }}
    />
  );
});

export default function UserCell(
  { value, showLabel = true }:
  { value: UserValue[], showLabel?: boolean},
) {
  const [showOverflow, setShowOverflow] = React.useState(false);
  if (!value || !Array.isArray(value)) return <>-</>;

  return (
    <ClickAwayListener onClickAway={() => setShowOverflow(false)}>
      <Box
        position="relative"
        height="100%"
        display="flex"
      >
        <Box
          display="inline-flex"
          flexWrap="nowrap"
          alignItems="center"
          gap={0.5}
          overflow="hidden"
          whiteSpace="nowrap"
          width="100%"
          sx={{
            overflow: "hidden",
            cursor: "pointer",
          }}
          onClick={() => setShowOverflow(true)}
        >
          {renderUsers(value, showLabel)}
        </Box>
        <OverflowBox open={showOverflow}>{renderUsers(value, true)}</OverflowBox>
      </Box>
    </ClickAwayListener>
  );
}
