import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Dialog,
  FormControl,
  InputAdornment,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";

export default function SearchDialog({
  open,
  loading,
  onClose,
  onSubmit,
}: {
  open: boolean,
  loading: boolean,
  onClose: () => void,
  onSubmit: (domains: string) => void,
}) {
  const [query, setQuery] = useState("");

  useEffect(() => {
    setQuery("");
  }, [open]);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      fullScreen={fullScreen}
      scroll="paper"
    >
      <DialogTitle>Search by Domains</DialogTitle>
      <DialogContent>
        <Box
          paddingBottom="8px"
        >
          <Typography variant="body1">
            Enter a comma separated or new line separated list of domains to search by.
          </Typography>
        </Box>
        <FormControl
          fullWidth
        >
          <TextField
            variant="outlined"
            fullWidth
            multiline
            placeholder="valorep.com, tesla.com etc"
            disabled={loading}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: query.length ? (
                <InputAdornment
                  position="end"
                  onClick={() => !loading && setQuery("")}
                >
                  <CloseIcon />
                </InputAdornment>
              ) : null,
            }}
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          alignItems="flex-end"
          gap={1}
        >
          <Button
            variant="outlined"
            onClick={onClose}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onSubmit(query);
            }}
            disabled={loading}
          >
            Find
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
