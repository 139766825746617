import React, { useState, useEffect } from "react";
import {
  Typography, Avatar, Tooltip,
} from "@mui/material";
import CustomTable from "@/ui/molecules/CustomTable";
import SingleSelect from "@/ui/atoms/SingleSelect";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { getRoles, updateUserRole, getUsers } from "@/api/Compliance";
import { useConfetti } from "@/hooks/useConfetti";

function UsersTable({ title, defaultFilters }) {
  const triggerConfetti = useConfetti();
  const [skipPageReset, setSkipPageReset] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useQuery(["Users"], getUsers, {
    onSuccess: setUsers,
  });

  const { data: roles = [] } = useQuery(["Roles"], getRoles);
  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.

  // When our cell renderer calls updateUser, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateUser = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);

    setUsers((old) => {
      updateUserRole(old[rowIndex].id, value);
      return old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      });
    });
  };

  // After data chagnes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  // React.useEffect(() => {
  //   setSkipPageReset(false)
  // }, [data])

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        Cell: ({ cell: { value }, row }) => (
          <div style={{ display: "flex" }}>
            <Tooltip title="More Details" placement="top">
              <Avatar
                onClick={() => {
                  navigate(
                    `/compliance-and-permissions/user?id=${row.original.id}`,
                  );
                }}
                style={{ cursor: "pointer" }}
                alt={value}
                src={row.original.profilePicture}
                sx={{
                  width: 40,
                  height: 40,
                }}
              />
            </Tooltip>
            <Typography
              variant="subtitle1"
              sx={{
                marginTop: "5px",
                marginLeft: "5px",
              }}
            >
              {value}
            </Typography>
          </div>
        ),
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Role",
        accessor: "roleId",
        Filter: ({ column: { filterValue, setFilter } }) => (
          <SingleSelect
            id="table-select-filter"
            showNone
            variant="standard"
            onChange={(value) => { setFilter(value === "" ? null : value); }}
            options={roles}
            value={filterValue || ""}
            getKey={(role) => role.id}
            getLabel={(role) => role.name}
          />
        ),
        filter: "equals",
        Cell: ({ value: initialValue, row: { index }, column: { id } }) => {
          // We need to keep and update the state of the cell normally
          const [value, setValue] = useState(initialValue);

          const onChange = (newValue) => {
            setValue(newValue);
            updateUser(index, id, newValue);
            triggerConfetti();
          };

          // If the initialValue is changed external, sync it up with our state
          useEffect(() => {
            setValue(initialValue);
          }, [initialValue]);

          return (
            <SingleSelect
              id={`column-select-${index}`}
              value={value || ""}
              variant="standard"
              onChange={onChange}
              options={roles}
              getKey={(role) => role.id}
              getLabel={(role) => role.name}
            />
          );
        },
      },
    ],
    [roles],
  );

  return (
    <CustomTable
      title={title}
      data={users}
      columns={columns}
      skipPageReset={skipPageReset}
      defaultFilters={defaultFilters}
    />

  );
}

UsersTable.defaultProps = {
  defaultFilters: [],
};

export default UsersTable;
