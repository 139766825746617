import React, { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import {
  getResponses,
  getAllSections,
  getSurveyQuestions,
} from "@/api/FrameworkSurvey";
import {
  Box,
  Card,
  Divider,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { CalendarMonth } from '@mui/icons-material';
import { TextCell, UserCell, MultiUserCell } from "@/components/Table/Primitives";
import DataTable from "@/components/LocalTable";
import Page from "@/ui/molecules/Page";
import UserAvatar from "@/ui/atoms/UserAvatar";
import dayjs from "dayjs";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { formatBigDollars } from "@/utils/numberFormat";
import CenteredProgress from "@/ui/atoms/CenteredProgress";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import { useAuth } from "@/hooks/useAuth";
import TaskSelector from "../Tasks";

const periodConverter = {
  MONTHLY: "M",
  QUARTERLY: "Q",
  YEARLY: "Y",
};

const getDifferenceColor = (theme, value) => {
  if (value === null) {
    return theme.palette.gray.main;
  }
  if (value >= 10) {
    return theme.palette.green.main;
  }
  if (value >= -25) {
    return theme.palette.yellow.main;
  }
  return theme.palette.red.dark;
};

const calculatePersonalCoDifference = ({ personal, company }) => {
  let difference = null;
  if (company !== "N/A" && personal !== "N/A") {
    const coNum = Number.parseFloat(company);
    const perNum = Number.parseFloat(personal);
    if (coNum === 0 && perNum === 0) {
      return null;
    }
    if (coNum === 0) {
      difference = ((coNum - perNum) / Math.abs(perNum)) * 100;
      difference = difference.toFixed(2);
    } else {
      difference = ((perNum - coNum) / Math.abs(coNum)) * 100;
      difference = difference.toFixed(2);
    }
  }
  return difference;
};

export function OrgFrameworkResponse() {
  const { id: valorId, responseId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const surveyId = queryParams.get("surveyId");
  const { user } = useAuth();

  const theme = useTheme();

  function cellHelper(row) {
    const acc = {};
    const { questionType } = row.question;
    if (questionType === "MULTIPLE_CHOICE") {
      acc.accessor = (r) => r.choice.text;
      acc.cell = (prop) => <TextCell value={prop.getValue()} />;
    } else if (questionType === "RETURN_DISTRIBUTION") {
      acc.accessor = (r) => r.choice.text;
      acc.cell = (prop) => <TextCell value={prop.getValue()} />;
    } else if (questionType === "PERCENTAGE_ESTIMATE") {
      acc.accessor = (r) => `${r.commentary}%`;
      acc.cell = (prop) => <TextCell value={prop.getValue()} />;
    } else if (questionType === "QUARTER") {
      acc.accessor = (r) => r.commentary;
      acc.cell = (prop) => <TextCell value={prop.getValue()} />;
    } else if (questionType === "TEXT") {
      acc.accessor = (r) => r.choice.text; // should be null
      acc.cell = (prop) => <TextCell value={prop.getValue()} />;
    } else if (questionType === "FISCAL_YEAR") {
      acc.accessor = (r) => r.answerData;
      acc.cell = (prop) => {
        const parse = JSON.parse(prop.getValue());
        return (
          <TextCell value={`Current FY: ${parse.currentYear}`} />
        );
      };
    } else if (questionType === "MULTIPLE_USER") {
      acc.accessor = (r) => r.users;
      acc.cell = (prop) => <MultiUserCell value={prop.getValue()} />;
    } else if (questionType === "SINGLE_USER" || questionType === "SINGLE_USER_PARTNER") {
      acc.accessor = (r) => r.users[0];
      acc.cell = (prop) => <UserCell value={prop.getValue()} />;
    } else if (questionType.startsWith("DOLLAR_ESTIMATE")) {
      acc.accessor = (r) => r.answerData;
      acc.cell = (prop) => {
        const cellQuestionType = prop.row.original.question.questionType;
        let personal;
        let period;
        let company;
        let style = {};
        if (
          [
            "DOLLAR_ESTIMATE_WITH_PERIOD_TYPE",
            "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
          ].includes(cellQuestionType)
        ) {
          const answerObj = JSON.parse(prop.getValue());
          personal = answerObj.personal;
          period = answerObj.period;
          company = answerObj.company;
        } else if (cellQuestionType === "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION") {
          const answerObj = JSON.parse(prop.getValue());
          personal = answerObj.personal;
          company = answerObj.company;
        } else {
          personal = prop.getValue();
        }

        const difference = calculatePersonalCoDifference({ personal, company });
        const differenceColor = getDifferenceColor(theme, difference);

        if ([
          "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_WITH_PERIOD_TYPE",
          "DOLLAR_ESTIMATE_BIZ_AND_PERSONAL_PREDICTION"]
          .includes(cellQuestionType)) {
          style = { borderBottom: `3px solid ${differenceColor}` };
        }

        return (
          <Box sx={{ ...style }}>
            {company && (
              <Typography variant="subtitle2">
                {company === "N/A" ? company : formatBigDollars(company)}
              </Typography>
            )}
            {company && <Typography variant="subtitle2">v.</Typography>}
            <Typography variant="subtitle2" sx={{ fontStyle: "italic" }}>
              {personal === "N/A" ? personal : formatBigDollars(personal)}
            </Typography>
            {period && (
              <Typography variant="subtitle2">{periodConverter[period]}</Typography>
            )}
          </Box>
        );
      };
    } else {
      acc.accessor = (r) => r.choice.text;
      acc.cell = (prop) => <TextCell value={prop.getValue()} />;
    }
    return acc;
  }

  const columns = useMemo(
    () => [
      {
        header: "Question",
        id: "question",
        accessorFn: (row) => row.attributeName,
        cell: (prop) => {
          const { subtitle } = prop.row.original.question;
          return (
            <Tooltip
              title={(subtitle || null)}
              placement="top"
            >
              <div>
                <TextCell value={prop.getValue()} />
              </div>
            </Tooltip>
          );
        },
        size: 200,
      },
      {
        header: "Answer",
        id: "answer",
        accessorFn: (row) => cellHelper(row).accessor(row),
        size: 200,
        cell: (prop) => cellHelper(prop.row.original).cell(prop),
      },
      {
        header: "Commentary",
        id: "value",
        accessorFn: (row) => row.commentary,
        cell: (prop) => <TextCell value={prop.getValue()} clamp={false} />,
        size: 775,
      },
    ].map((column) => ({
      ...column,
      enableColumnFilter: false,
      enableSorting: false,
      enableColumnMenu: false,
    })),
    [theme],
  );

  const multiChoiceColumns = useMemo(
    () => {
      const newColumns = [
        ...columns.slice(0, 1),
        {
          header: "Color",
          id: "color",
          accessorFn: (row) => row.choice.color,
          size: 84,
          cell: (prop) => (
            <Box
              sx={{
                width: "64px",
                height: "32px",
                borderRadius: theme.spacing(1),
                backgroundColor: prop.getValue()
                  ? theme.palette[prop.getValue()].main
                  : theme.palette.grey[400],
              }}
            />
          ),
        },
        ...columns.slice(1),
      ];
      return newColumns;
    },
    [columns],
  );

  const {
    data: response,
    isLoading: isLoadingResponse,
  } = useQuery(
    ["surveyResponse", responseId],
    async () => {
      const r = await getResponses({ responseIds: [responseId] });
      return r?.[0];
    },
  );

  const {
    data: sections = [],
    isLoading: isLoadingSections,
  } = useQuery(
    ["Sections", response?.vertical],
    () => getAllSections({ isAAR: response.vertical === "after_action_review" }),
    {
      enabled: !!response,
    },
  );

  const {
    data: survey,
    isLoading: isLoadingSurvey,
  } = useQuery(
    ["InvestmentFramework", surveyId],
    () => getSurveyQuestions(surveyId),
    {
      enabled: !!surveyId,
    },
  );

  function createTableData(surveyResponse, surveySections) {
    const result = { multiChoiceData: [], otherData: [] };
    surveySections.forEach((section) => {
      section.attributes.forEach((attribute) => {
        if (surveyResponse.answers[attribute.id]) {
          if (surveyResponse.answers[attribute.id].question.questionType === "MULTIPLE_CHOICE") {
            result.multiChoiceData.push({
              attributeName: attribute.name,
              attributeSubtitle: attribute.subtitle,
              ...surveyResponse.answers[attribute.id],
            });
          } else {
            result.otherData.push({
              attributeName: attribute.name,
              attributeSubtitle: attribute.subtitle,
              ...surveyResponse.answers[attribute.id],
            });
          }
        }
      });
    });
    return result;
  }

  const { multiChoiceData, otherData } = useMemo(
    () => createTableData(response, sections),
    [response, sections],
  );

  const isLoading = useMemo(
    () => (
      isLoadingResponse || isLoadingSections || isLoadingSurvey
    ),
    [isLoadingResponse, isLoadingSections, isLoadingSurvey],
  );

  if (isLoading) {
    return <CenteredProgress />;
  }

  return (
    <Page>
      <Page.Title>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
        >
          <span>
            {(response.vertical === 'after_action_review') ? (
              `${InvestmentProductMappings[response.vertical]} \u2014 ${response.surveyName}`
            ) : (`${InvestmentProductMappings[response.department]} \u2014 ${response.surveyName}`)}
          </span>
        </Box>
      </Page.Title>
      <Page.Content>
        <Box
          display="flex"
          justifyContent="center"
          mb={4}
        >
          <Card
            sx={{
              width: "1500px",
              maxWidth: "2000px",
            }}
          >
            <Box
              padding={2}
              display="flex"
              flexDirection="column"
              gap={2}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                flexDirection={{
                  xs: "column",
                  sm: "row",
                }}
              >
                <Box
                  display="flex"
                  flexGrow={1}
                  gap={2}
                >
                  <Box display="flex" gap={1} alignItems="center">
                    <CompanyAvatar
                      name={response.organization.name}
                      domain={response.organization.domain}
                      size="64"
                      src={response.organization.logoUrl}
                    />
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                    >
                      <Typography variant="h3">
                        {response.organization.name}
                      </Typography>
                      <Link
                        href={`//www.${response.organization.domain}`}
                        target="_blank"
                        sx={{
                          textDecoration: "none",
                          color: theme.palette.text.secondary,
                        }}
                      >
                        <Typography variant="body2">
                          {response.organization.domain}
                        </Typography>
                      </Link>
                    </Box>
                  </Box>
                  <Divider orientation="vertical" flexItem />
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    flexDirection="row"
                  >
                    <UserAvatar user={response.user} />
                    <Stack>
                      <Typography variant="boldSubtitle2">
                        {(response.vertical !== "after_action_review") ? "Completed by" : "Reviewer"}
                      </Typography>
                      <Typography variant="body1">
                        {`${response.user.firstName} ${response.user.lastName}`}
                      </Typography>
                    </Stack>
                  </Box>
                  {response.surveySubjectUser && (
                    <Box
                      display="flex"
                      gap={1}
                      alignItems="center"
                      flexDirection="row"
                    >
                      <UserAvatar user={response.surveySubjectUser} />
                      <Stack>
                        <Typography variant="boldSubtitle2">
                          Reviewee
                        </Typography>
                        <Typography variant="body1">
                          {`${response.surveySubjectUser.firstName} ${response.surveySubjectUser.lastName}`}
                        </Typography>
                      </Stack>
                    </Box>
                  )}
                  <Box
                    display="flex"
                    gap={1}
                    alignItems="center"
                    flexDirection="row"
                  >
                    <CalendarMonth fontSize="large" sx={{ color: theme.palette.primary.main }} />
                    <Stack>
                      <Typography variant="boldSubtitle2">Completed on</Typography>
                      <Typography variant="body1">
                        {dayjs.utc(response.completedAt).local().format("MM/DD/YYYY")}
                      </Typography>
                    </Stack>
                  </Box>
                </Box>
              </Box>
              {survey?.canLinkTasks && (response.user.id === user.id) && (
                <Box
                  display="flex"
                  flexDirection="column"
                  gap={2}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      color: theme.palette.text.secondary,
                    }}
                  >
                    Link to a CRM List
                  </Typography>
                  <TaskSelector
                    responseId={responseId}
                    valorId={valorId}
                  />
                </Box>
              )}
              <Box
                display="flex"
                flexDirection="column"
                gap={2}
              >
                <Typography
                  variant="h2"
                  sx={{
                    color: theme.palette.text.secondary,
                  }}
                >
                  Response Summary
                </Typography>
                {otherData?.length > 0 && (
                  <Box borderLeft={(t) => `1px solid ${t.palette.divider}`}>
                    <DataTable
                      data={otherData}
                      columns={columns}
                    />
                  </Box>
                )}
                {multiChoiceData?.length > 0 && (
                  <Box borderLeft={(t) => `1px solid ${t.palette.divider}`}>
                    <DataTable
                      data={multiChoiceData}
                      columns={multiChoiceColumns}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Card>
        </Box>
      </Page.Content>
    </Page>
  );
}

export default OrgFrameworkResponse;
