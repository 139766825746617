// Adjustments for MUI v5, incorporating `styled` for styling components.
import React, { useEffect, useState } from "react";
import { Field } from "react-final-form";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  textAlign: "left",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-inputMultiline": {
    height: theme.spacing(4),
    fontSize: theme.spacing(3),
  },
}));

interface Props {
  questionId: string;
  initialValue: string;
  initialComment: string;
}

export default function DollarEstimate(props: Props) {
  const { questionId, initialValue, initialComment } = props;
  const [notApplicable, setNotApplicable] = useState(false);

  useEffect(
    () => {
      if (initialValue === "N/A") {
        setNotApplicable(true);
      }
    },
    [initialValue],
  );

  const validator = (value: string) => {
    if (!value) {
      return "Required";
    }
    if (value !== "N/A" && Number.isNaN(Number(value))) {
      return "Invalid Input Numbers / Commas Only unless N/A";
    }
    return undefined;
  };

  const onCheckChange = (event, onChange) => {
    const { checked } = event.target;
    setNotApplicable(checked);
    if (checked) {
      onChange("N/A");
    } else {
      onChange("");
    }
  };

  const formatter = (value: string) => {
    if (!value) return "";
    if (value !== "N/A" && !Number.isNaN(Number(value))) {
      return `$${Number(value).toLocaleString("en-US")}`;
    }
    return value;
  };

  const parser = (value: string) => {
    const parsedValue = value.replaceAll(",", "").replaceAll("$", "");
    if (!parsedValue) {
      return "";
    }
    if (parsedValue === "N/A" || Number.isNaN(Number(parsedValue))) {
      return parsedValue;
    }

    return String(Number(parsedValue));
  };

  return (
    <Grid item xs={12}>
      <StyledTypography variant="body2" sx={{ fontWeight: 900 }}>
        Company&apos;s Metric Numbers and Commas Only, Complete Numbers not in
        Thousands or Millions
      </StyledTypography>
      <Grid container spacing={1}>
        <Grid item xs={8}>
          <Field
            name={`${questionId}.answerData`}
            initialValue={initialValue}
            validate={validator}
            parse={parser}
            format={formatter}
          >
            {({ input, meta }) => (
              <>
                <FormGroup>
                  <FormControlLabel
                    control={(
                      <Checkbox
                        data-cy={`not-applicable-${questionId}`}
                        checked={notApplicable}
                        onChange={(event) => onCheckChange(event, input.onChange)}
                      />
                    )}
                    label="Not Applicable"
                  />
                </FormGroup>
                <StyledTextField
                  {...input}
                  data-cy={`dollar-estimate-${questionId}`}
                  error={!!(meta.submitFailed && meta.error)}
                  label={meta.submitFailed ? meta.error : ""}
                  disabled={notApplicable}
                  multiline
                  variant="outlined"
                />
              </>
            )}
          </Field>
        </Grid>
        <Grid item xs={4}>
          <StyledTypography variant="body2" sx={{ height: "42px" }}>
            COMMENTS
          </StyledTypography>
          <Field name={`${questionId}.commentary`} initialValue={initialComment}>
            {({ input }) => (
              <StyledTextField
                {...input}
                data-cy={`dollar-estimate-commentary-${questionId}`}
                multiline
                variant="outlined"
              />
            )}
          </Field>
        </Grid>
      </Grid>
    </Grid>
  );
}
