/* eslint-disable no-console */
import axios from "axios";
import { searchParams } from "@/utils/general";

export const getUnmappedFolders = async (sinceDate) => {
  try {
    const response = await axios.get("/api/verity/unmapped-folders", { params: { sinceDate } });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getUntaggedFiles = async (sinceDate) => {
  try {
    const response = await axios.get("/api/verity/untagged-files", { params: { sinceDate } });
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const postNote = async (note) => {
  try {
    const response = await axios.post("/api/verity/new-note", note);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const ignoreFolder = async (folder) => {
  try {
    const response = await axios.post("/api/verity/ignore-folder", folder);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const ignoreFile = async (file) => {
  try {
    const response = await axios.post("/api/verity/ignore-file", file);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getDocTypes = async () => {
  try {
    const response = await axios.get("/api/verity/doc-types");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const acknowledgeFolderAndAllChildren = async (folderId, newValorId) => {
  const params = {
    folder_id: folderId,
    new_valor_id: newValorId,
  };

  try {
    const response = await axios.put(`/api/verity/acknowledge-folder-and-all-children?${searchParams(params)}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  getUnmappedFolders,
  getUntaggedFiles,
  postNote,
  getDocTypes,
  ignoreFolder,
  ignoreFile,
  acknowledgeFolderAndAllChildren,
};
