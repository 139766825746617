import React from "react";
import { Box, MenuItem, useTheme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

type FilterProps = {
  getFilterValue: () => {
    min: string | null;
    max: string | null;
  };
  setFilterValue: (value: {
    min: string | null;
    max: string | null;
  }) => void;
};

type DateRange = {
  min: string | null;
  max: string | null;
};

export default function DateRange({
  getFilterValue,
  setFilterValue,
}: FilterProps) {
  const theme = useTheme();

  const currentValue = getFilterValue() as DateRange ?? { min: null, max: null };
  return (
    <Box width={theme.spacing(45)} padding={1}>
      <Box display="flex" justifyContent="space-between" gap={1}>
        <DatePicker
          label="From"
          value={currentValue.min ? dayjs(currentValue.min) : null}
          onChange={(date) => {
            setFilterValue({
              min: date.format("MM/DD/YYYY"),
              max: currentValue.max,
            });
          }}
        />
        <DatePicker
          label="To"
          value={currentValue.max ? dayjs(currentValue.max) : null}
          onChange={(date) => {
            setFilterValue({
              min: currentValue.min,
              max: date.format("MM/DD/YYYY"),
            });
          }}
        />
      </Box>
      <Box
        mt={1}
        pt={1}
        sx={{ borderTop: `1px solid ${theme.palette.divider}` }}
      >
        <MenuItem
          selected={currentValue.min === dayjs().subtract(1, "week").format("MM/DD/YYYY") && currentValue.max === dayjs().format("MM/DD/YYYY")}
          onClick={() => setFilterValue({
            min: dayjs().subtract(1, "week").format("MM/DD/YYYY"),
            max: dayjs().format("MM/DD/YYYY"),
          })}
        >
          Last Week
        </MenuItem>

        <MenuItem
          selected={currentValue.min === dayjs().subtract(2, "week").format("MM/DD/YYYY") && currentValue.max === dayjs().format("MM/DD/YYYY")}
          onClick={() => setFilterValue({
            min: dayjs().subtract(2, "week").format("MM/DD/YYYY"),
            max: dayjs().format("MM/DD/YYYY"),
          })}
        >
          Last Two Weeks
        </MenuItem>
        <MenuItem
          selected={currentValue.min === dayjs().subtract(1, "month").format("MM/DD/YYYY") && currentValue.max === dayjs().format("MM/DD/YYYY")}
          onClick={() => setFilterValue({
            min: dayjs().subtract(1, "month").format("MM/DD/YYYY"),
            max: dayjs().format("MM/DD/YYYY"),
          })}
        >
          Last Month
        </MenuItem>
        <MenuItem
          disabled={!currentValue.min && !currentValue.max}
          onClick={() => setFilterValue(undefined)}
        >
          Clear
        </MenuItem>
      </Box>
    </Box>
  );
}
