export const DEVELOPER_ROLE_ID = 1;
const GROWTH_FUND_PARTNER_ROLE_ID = 2;
// const VP_PRINCIPAL = 3;
// const COMPLIANCE_ROLE_ID = 4;
// const VSV_PARTNER_VP_PRINCIPAL_ROLE_ID = 6;
// const SUPPORT_ROLE_ID = 7;
// const OPERATIONS_ROLE_ID = 8;
// const VSV_OPS_ROLE_ID = 9;
// const FINANCE_ROLE_ID = 10;
// const ASSOCIATES = 11;
export const MARKET_MAPS_ROLE_ID = 12;
const NEW_USER_ROLE_ID = 13;
const CEO_ROLE_ID = 14;
const PRESIDENTS_ROLE_ID = 15;
const OPS_PARTNER = 16;
// const HR_ROLE_ID = 17;
export const ADMIN_ROLE_ID = 18;
// const RESEARCH_TEAM = 19;
// const CFO = 20;
// const HR_MANAGEMENT = 21;
// const CLO = 22;
// const PORTFOLIO_MANAGEMENT_ROLE_ID = 23;
export const ATREIDES_ROLE_ID = 27;

const READ_SURVEY_RESULTS = 1;
const CAN_SEARCH_ORGS = 120;
const CAN_SEARCH_DEAL_SEARCH = 121;
const READ_PROCESS = 68;
const READ_TASKS_AND_ALERTS = 100;
const READ_RECENT_MEETINGS = 101;
const READ_FOLLOWS = 102;
const READ_HELP = 103;
const READ_PCAP_AUDIT = 104;
const READ_UNTAGGED_DOCS = 73;
const READ_ORGS = 66;
const READ_MARKET_MAPS = 60;
const WRITE_MARKET_MAPS = 61;

// const READ_PIPELINE_SIGNALS = 111;
const READ_ALL_TASKS = 105;
const WRITE_TASKS = 80;
// const READ_MODELS = 59;
const READ_METRICS = 50;
const WRITE_METRICS = 51;
const READ_MOIC_WITHOUT_DISCLAIMER = 106;
const READ_MOIC_SCORE = 949;
const READ_WEEKLY_MEETING = 107;
const READ_COMPLIANCE = 88;
const READ_VPO = 76;
// const READ_REV_GEN = 114;
const READ_FIRM_LIST = 86;
const READ_INTERNAL_FILES = 57;
const READ_ACTIVITY_FEED = 56;
const READ_TEGUS_SCORES = 83;
const WRITE_TEGUS_SCORES = 84;
const READ_MARKET_MAPS_DRAFTS = 110;
const VIEW_PRIVILEGED = 109;
const READ_SIGNAL_MODEL_PIPELINE = 112;
const READ_EXPLAINABILITY = 113;
const READ_VOTE_ON_FIRST_MEETING = 115;
const WRITE_VOTE_ON_FIRST_MEETING = 116;
const GLASS_MOIC_SCORES = 117;
const READ_METRIC_TASKS = 118;
const CAN_SEND_VPO_REPORTS = 78;
const READ_MANAGEMENT_DASHBOARD = 85;
const CAN_EDIT_DRAFT_COLLAB_SURVEYS = 122;
const CAN_EDIT_ALL_SURVEYS = 124;
const CAN_SEE_VAAI_REPORTS = 125;
const CAN_WRITE_ORGS = 67;
const CAN_SEE_LLM_CHAT = 952;

// const READ_PIPELINE_SIGNALS = 111;
export const isInPartnerClass = (user) =>
  // eslint-disable-next-line no-bitwise
  ~[
    OPS_PARTNER,
    GROWTH_FUND_PARTNER_ROLE_ID,
    CEO_ROLE_ID,
    PRESIDENTS_ROLE_ID,
  ].indexOf(user.roleId);
const CAN_WRITE_PROCESS = 69;
const CAN_DELETE_PROCESS = 200;
const CAN_SEE_CRM_LISTS = 201;
const CAN_FAVORITE_PROCESSES = 202;
const CAN_WRITE_PROCESS_FIELDS = 203;
const CAN_WRITE_PROCESS_VIEWS = 950;

const CAN_WRITE_SELF_REVIEW_ANALYST = 304;
const CAN_WRITE_SELF_REVIEW_ASSOCIATE = 305;
const CAN_WRITE_SELF_REVIEW_VP = 306;
const CAN_WRITE_SELF_REVIEW_PRINCIPAL = 307;
const CAN_WRITE_PEER_REVIEW_ANALYST = 308;
const CAN_WRITE_PEER_REVIEW_ASSOCIATE = 309;
const CAN_WRITE_PEER_REVIEW_VP = 310;
const CAN_WRITE_PEER_REVIEW_PRINCIPAL = 311;
const CAN_VIEW_ANALYST_ASSOCIATE_AARS = 312;
const CAN_VIEW_ALL_AARS = 313;
const CAN_RELEASE_ALL_PEER_REVIEWS = 315;

const userHasPermission = (user, perms) => perms.every((perm) => user?.permissions.find((p) => p.id === perm)?.granted);

const checkPerms = (perms) => (user) => {
  const result = userHasPermission(user, perms);
  return result;
};
export const isAdmin = (user) => user?.roleId === ADMIN_ROLE_ID;
export const canEditDraftCollabSurveys = checkPerms([
  CAN_EDIT_DRAFT_COLLAB_SURVEYS,
]);
export const canEditAllSurveys = checkPerms([CAN_EDIT_ALL_SURVEYS]);
export const isNewUser = (user) => user?.roleId === NEW_USER_ROLE_ID;
export const isInAlpha = (user) => user?.roleId === DEVELOPER_ROLE_ID || user?.roleId === ADMIN_ROLE_ID;
export const canSeeProcess = (user, process) => user?.permissions
  .filter((perm) => perm.id === READ_PROCESS)
  .filter((perm) => perm.processId === process)
  .some((perm) => perm.granted);
export const canDealSearch = checkPerms([CAN_SEARCH_DEAL_SEARCH]);
export const canSeeOrgSearch = checkPerms([CAN_SEARCH_ORGS]);

export const canSeeDeals = (user) => canSeeProcess(user, "deals");
export const canSeePortfolio = (user) => canSeeProcess(user, "portfolio");
export const canSeeOnboarding = (user) => canSeeProcess(user, "rev-gen");
export const canSeeRevGen = (user) => canSeeProcess(user, "vsv-revops");
export const isInBeta = (user) => user?.roleId !== MARKET_MAPS_ROLE_ID;
export const canSeeDev = (user) => isInAlpha(user);
export const canSeeLLMChat = checkPerms([CAN_SEE_LLM_CHAT]);
export const canSeeVAAIReports = checkPerms([CAN_SEE_VAAI_REPORTS]);
export const canSeeTasksAndAlerts = checkPerms([READ_TASKS_AND_ALERTS]);
export const canSeeRecentMeetings = checkPerms([READ_RECENT_MEETINGS]);
export const canSeeFollows = checkPerms([READ_FOLLOWS]);
export const canSeeHelp = checkPerms([READ_HELP]);
export const canSeeHome = checkPerms([READ_FOLLOWS, READ_RECENT_MEETINGS]);
export const canSeePCAPAudit = checkPerms([READ_PCAP_AUDIT]);
export const canSeeUntaggedDocs = checkPerms([READ_UNTAGGED_DOCS]);
export const canSeeOrgs = checkPerms([READ_ORGS]);
export const canSeeMarketMaps = checkPerms([READ_MARKET_MAPS]);
export const canWriteMarketMaps = checkPerms([WRITE_MARKET_MAPS]);
export const canSeeAllTasks = checkPerms([READ_ALL_TASKS]);
export const canWriteTasks = checkPerms([WRITE_TASKS]);
export const canSeeSignalModelPipeline = checkPerms([
  READ_SIGNAL_MODEL_PIPELINE,
]);
export const canSeeExplainability = checkPerms([READ_EXPLAINABILITY]);
export const canSeeMetrics = checkPerms([READ_METRICS]);
export const canWriteMetrics = checkPerms([WRITE_METRICS]);
export const canReadManagementDashboard = checkPerms([
  READ_MANAGEMENT_DASHBOARD,
]);
export const canSeeVotesOnFirstMeeting = checkPerms([
  READ_VOTE_ON_FIRST_MEETING,
]);
export const canVoteOnFirstMeeting = checkPerms([WRITE_VOTE_ON_FIRST_MEETING]);
export const canSeeMOICScoreWithoutDisclaimer = checkPerms([
  READ_MOIC_WITHOUT_DISCLAIMER,
]);
export const canSeeSurveyResults = checkPerms([READ_SURVEY_RESULTS]);
export const canSeeMOICChip = checkPerms([READ_MOIC_SCORE]);
export const canSeeWeeklyMeeting = checkPerms([READ_WEEKLY_MEETING]);
export const canSeeCompliance = checkPerms([READ_COMPLIANCE]);
export const canSeeVPO = checkPerms([READ_VPO]);
export const canSeeFirmList = checkPerms([READ_FIRM_LIST]);
export const canGlassMoicScores = checkPerms([GLASS_MOIC_SCORES]);
export const canSeeFinancials = checkPerms([READ_METRICS]);
export const canSeeMetricTasks = checkPerms([READ_METRIC_TASKS]);
export const canFetchAllTasks = checkPerms([READ_METRICS]);
export const canSeeMetricsInternal = checkPerms([READ_INTERNAL_FILES]);
export const canSeeMetricsActivity = checkPerms([READ_ACTIVITY_FEED]);
export const canSeeProcessManagement = checkPerms([READ_PROCESS]);
export const canReadTegusScores = checkPerms([READ_TEGUS_SCORES]);
export const canWriteTegusScores = checkPerms([WRITE_TEGUS_SCORES]);
export const canSeeMarketMapsDrafts = checkPerms([READ_MARKET_MAPS_DRAFTS]);
export const canSeePrivileged = checkPerms([VIEW_PRIVILEGED]);
export const canSendVPOReports = checkPerms([CAN_SEND_VPO_REPORTS]);
export const canSeeSurveyDashboard = checkPerms([READ_MANAGEMENT_DASHBOARD]);
export const canSeePortfolioHealth = checkPerms([VIEW_PRIVILEGED]);
export const canSeeCRMLists = checkPerms([CAN_SEE_CRM_LISTS]);
export const canFavoriteProcesses = checkPerms([CAN_FAVORITE_PROCESSES]);
export const canDeleteProcesses = checkPerms([CAN_DELETE_PROCESS]);
export const canCreateProcesses = checkPerms([CAN_WRITE_PROCESS]);
export const canArchiveProcesses = checkPerms([CAN_WRITE_PROCESS]);
export const canEditProcess = checkPerms([CAN_WRITE_PROCESS]);
export const canEditProcessFields = checkPerms([CAN_WRITE_PROCESS_FIELDS]);

export const canWriteSelfReviewAnalyst = checkPerms([
  CAN_WRITE_SELF_REVIEW_ANALYST,
]);
export const canWriteSelfReviewAssociate = checkPerms([
  CAN_WRITE_SELF_REVIEW_ASSOCIATE,
]);
export const canWriteSelfReviewVP = checkPerms([CAN_WRITE_SELF_REVIEW_VP]);
export const canWriteSelfReviewPrincipal = checkPerms([
  CAN_WRITE_SELF_REVIEW_PRINCIPAL,
]);
export const canWritePeerReviewAnalyst = checkPerms([
  CAN_WRITE_PEER_REVIEW_ANALYST,
]);
export const canWritePeerReviewAssociate = checkPerms([
  CAN_WRITE_PEER_REVIEW_ASSOCIATE,
]);
export const canWritePeerReviewVP = checkPerms([CAN_WRITE_PEER_REVIEW_VP]);
export const canWritePeerReviewPrincipal = checkPerms([
  CAN_WRITE_PEER_REVIEW_PRINCIPAL,
]);
export const canViewAnalystAssociateAARs = checkPerms([
  CAN_VIEW_ANALYST_ASSOCIATE_AARS,
]);
export const canViewAllAARs = checkPerms([CAN_VIEW_ALL_AARS]);
export const canReleaseAllPRs = checkPerms([CAN_RELEASE_ALL_PEER_REVIEWS]);
const checkOnePerm = (perms) => (user) => perms.some((perm) => user?.permissions.find((p) => p.id === perm)?.granted);
export const canSeeAARVertical = checkOnePerm([
  CAN_WRITE_SELF_REVIEW_ANALYST,
  CAN_WRITE_SELF_REVIEW_ASSOCIATE,
  CAN_WRITE_SELF_REVIEW_VP,
  CAN_WRITE_SELF_REVIEW_PRINCIPAL,
  CAN_WRITE_PEER_REVIEW_ANALYST,
  CAN_WRITE_PEER_REVIEW_ASSOCIATE,
  CAN_WRITE_PEER_REVIEW_VP,
  CAN_WRITE_PEER_REVIEW_PRINCIPAL,
  CAN_VIEW_ANALYST_ASSOCIATE_AARS,
  CAN_VIEW_ALL_AARS,
]);
export const canEditProcessViews = checkPerms([CAN_WRITE_PROCESS_VIEWS]);
export const canCreateOrgs = checkPerms([CAN_WRITE_ORGS]);
