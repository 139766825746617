export const getFundingSourceLink = ({
  affId, cbId, pbId, fundingSource,
}) => {
  if (fundingSource === "pitchbook") {
    return `https://my.pitchbook.com/profile/${pbId}/company/profile#deal-history`;
  } if (fundingSource === "crunchbase") {
    return `https://www.crunchbase.com/organization/${cbId}/company_financials`;
  } if (fundingSource === "affinity") {
    return `https://valor.affinity.co/companies/${affId}`;
  }
  return null;
};

export default getFundingSourceLink;
