import * as dayjs from "dayjs";
import startCase from "lodash/startCase";
import { isStringOrNumber } from "./general";

// NOTE: dayjs.unix(timeStamp).utc().format("MMM-YY") fails when running Jest
// This seems to be a known forsaken issue: https://github.com/iamkun/dayjs/issues/475
// For this reason createSheetColumnsForTest is a copy pasta function just for testing purposes
export const createSheetColumnsForTest = (data, key) => {
  const timeToValue = {};
  data.forEach((company) => {
    if (key in company && company[key]) {
      const cols = company[key];
      cols.forEach((col) => {
        if (!(col.timestamp in timeToValue)) {
          timeToValue[col.timestamp] = {};
        }

        timeToValue[col.timestamp][company.name] = col.value;
      });
    }
  });

  // sort by timestamp first
  const excelColumns = Object.keys(timeToValue)
    .map((timeStamp) => parseInt(timeStamp, 10))
    .sort((a, b) => a - b)
    .map((timeStamp) => ({
      label: timeStamp,
      value: (row) => timeToValue[timeStamp][row.name] || "",
    }));
  return [{ label: "Time", value: (row) => row.name }, ...excelColumns];
};

/**
 * Creates excel sheet's columns to feed to the xlsx function from https://github.com/LuisEnMarroquin/json-as-xlsx
 * @param {*} data  competitor KPIS
 * @param {*} key   metric key to generate the columns for.
 * @returns
 */
export const createSheetColumns = (data, key) => {
  const timeToValue = {};
  data.forEach((company) => {
    if (key in company && company[key]) {
      const cols = company[key];
      cols.forEach((col) => {
        if (!(col.timestamp in timeToValue)) {
          timeToValue[col.timestamp] = {};
        }

        timeToValue[col.timestamp][company.name] = col.value;
      });
    }
  });

  // sort by timestamp first
  const excelColumns = Object.keys(timeToValue)
    .map((timeStamp) => parseInt(timeStamp, 10))
    .sort((a, b) => a - b) // https://stackoverflow.com/questions/7000851/array-sort-doesnt-sort-numbers-correctly
    .map((timeStamp) => ({
      label: dayjs.unix(timeStamp).utc().format("MMM-YY"),
      value: (row) => timeToValue[timeStamp][row.name] || "",
    }));
  return [{ label: "Time", value: (row) => row.name }, ...excelColumns];
};

const sheets = [
  "avgTxnValueHistory",
  "customerPctHistory",
  "employeeTotalHistory",
  "jobTotalHistory",
  "lifetimeCustomerPctHistory",
  "newCustomersHistory",
  "observedCustomersHistory",
  "observedSalesHistory",
  "observedTransactionsHistory",
  "retentionHistory",
  "revenueHistory",
  "salesPerCustomerHistory",
  "socialTrafficHistory",
  "spendHistory",
  "txnsPerCustomerHistory",
  "webTrafficHistory",
];

// check if at least one company has some data for the metric
const isNotEmpty = (data, metric) => {
  for (let i = 0; i < data.length; i += 1) {
    const company = data[i];
    if (metric in company && company[metric] && company[metric].length > 0) {
      return true;
    }
  }
  return false;
};

/**
 * Creates excel sheets data to feed to the xlsx function from https://github.com/LuisEnMarroquin/json-as-xlsx
 * @param {*} data  competitor KPIS
 * @returns
 */
export const mapToExcel = (data) => {
  if (!data || data.length === 0) {
    return [];
  }
  const first = data[0];
  const tableColumns = Object.keys(first).filter((column) => isStringOrNumber(first[column]));

  // avoid exporting empty sheets
  const nonEmptySheets = sheets.filter((sheet) => isNotEmpty(data, sheet));

  return [
    {
      sheet: "Table",
      columns: tableColumns.map((column) => ({
        label: startCase(column),
        value: (row) => row[column] || "",
      })),
      content: data,
    },
    ...nonEmptySheets.map((sheet) => ({
      sheet: startCase(sheet),
      columns: createSheetColumns(data, sheet),
      content: data,
    })),
  ];
};
