/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useParams, useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Close, Assignment } from "@material-ui/icons";
import {
  Drawer,
  Box,
  Typography,
  ButtonBase,
  Button,
  Tooltip,
  ClickAwayListener,
} from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import useKeyPress from "@/hooks/useKeyPress";
import ButtonTabs from "@/ui/atoms/ButtonTabs";
import useLocalStorageState from "@/hooks/useLocalStorageState";
import Progress from "@/ui/atoms/Progress";

import { getAvailableFrameworkSurveys } from "@/api/FrameworkSurvey";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import AARSurveys from "@/constants/AARSurveys";
import { useAuth } from "@/hooks/useAuth";
import {
  canWriteSelfReviewAnalyst,
  canWriteSelfReviewAssociate,
  canWriteSelfReviewVP,
  canWriteSelfReviewPrincipal,
  canWritePeerReviewAnalyst,
  canWritePeerReviewAssociate,
  canWritePeerReviewVP,
  canWritePeerReviewPrincipal,
  canViewAnalystAssociateAARs,
  canViewAllAARs,
  canSeeAARVertical,
} from "@/constants/Roles";

const useStyles = makeStyles((theme) => ({
  drawer: {
    flexShrink: 0,
    width: 450,
    [theme.breakpoints.down("xs")]: {
      width: "95vw",
    },
  },
  drawerPaper: {
    display: "flex",
    flexDirection: "column",
    width: 450,
    [theme.breakpoints.down("xs")]: {
      width: "95vw",
    },
    paddingTop: theme.spacing(7),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
  },
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    margin: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
  switchTypeContainer: {
    display: "flex",
    justifyContent: "space-around",
    marginBottom: theme.spacing(2),
    padding: `0 ${theme.spacing(3)}px`,

  },
  interiorButtons: {
    [theme.breakpoints.down("sm")]: {
      width: "172px",
    },
  },
  drawerTitle: {
    fontWeight: theme.typography.fontWeightBold,
  },
  surveyCardContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    marginBottom: "64px",
    overflowY: "scroll",
  },
  surveyCard: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "72%",
    margin: theme.spacing(2),
    padding: theme.spacing(3),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
  },
  surveyTop: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    width: "100%",
    height: "100%",
  },
  surveyBottom: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  surveyTitle: {
    paddingBottom: theme.spacing(1),
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightBold,
  },
  surveyClip: {
    marginRight: theme.spacing(2),
    borderRadius: "8px",
  },
}));

function SurveyDrawer(props) {
  const { open, onClose } = props;
  const theme = useTheme();
  const { user } = useAuth();

  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const [currentSurveyVertical, setSurveyVertical] = useLocalStorageState(
    "investments",
    "currentSurveyVertical",
  );

  const [currentSurveyDepartment, setSurveyDepartment] = useLocalStorageState(null, "currentSurveyDepartment");

  useKeyPress("Escape", () => onClose());

  const verticalOrder = ["investments", "scale_group"];
  if (canSeeAARVertical(user)) {
    verticalOrder.push("after_action_review");
  }

  const departmentOrder = [
    "seed",
    "vsv",
    "ancillary",
    "platform",
    "growth_ops",
    "rev_gen",
    "human_capital",
    "self_review",
    "peer_review",
  ];

  const { data: surveys = {}, isLoading: isLoadingFrameworks } = useQuery(
    ["FrameworkSurveys", id],
    async () => {
      let response = await getAvailableFrameworkSurveys({ valorId: id });
      if (response?.status === 404 || response?.status === 422 || response?.status === 403) {
        throw new Error(response.status);
      }

      if (canViewAllAARs(user)) {
        // No action needed
      } else {
        if (canViewAnalystAssociateAARs(user)) {
          // No action needed
        } else {
          if (!canWriteSelfReviewAnalyst(user)) {
            response = response.filter((r) => r?.id !== AARSurveys.selfReviewAnalyst);
          }
          if (!canWriteSelfReviewAssociate(user)) {
            response = response.filter((r) => r?.id !== AARSurveys.selfReviewAssociate);
          }
        }

        if (!canWriteSelfReviewVP(user)) {
          response = response.filter((r) => r?.id !== AARSurveys.selfReviewVP);
        }
        if (!canWriteSelfReviewPrincipal(user)) {
          response = response.filter((r) => r?.id !== AARSurveys.selfReviewPrincipal);
        }
        if (!canWritePeerReviewAnalyst(user)) {
          response = response.filter((r) => r?.id !== AARSurveys.peerReviewAnalyst);
        }
        if (!canWritePeerReviewAssociate(user)) {
          response = response.filter((r) => r?.id !== AARSurveys.peerReviewAssociate);
        }
        if (!canWritePeerReviewVP(user)) {
          response = response.filter((r) => r?.id !== AARSurveys.peerReviewVP);
        }
        if (!canWritePeerReviewPrincipal(user)) {
          response = response.filter((r) => r?.id !== AARSurveys.peerReviewPrincipal);
        }
      }

      return response.reduce((result, item) => {
        const { vertical, department } = item;
        if (!result[vertical]) {
          result[vertical] = {};
        }
        if (!result[vertical][department]) {
          result[vertical][department] = [];
        }
        result[vertical][department].push(item);
        return result;
      }, {});
    },
    {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    },
  );

  const handleSurveyVertical = (newVertical) => {
    setSurveyVertical(newVertical);
    if (Object.keys(surveys[newVertical]).length) {
      const sorted = Object.keys(surveys[newVertical]).sort(
        (a, b) => departmentOrder.indexOf(a) - departmentOrder.indexOf(b),
      );
      setSurveyDepartment(sorted[0]);
    }
  };

  const renderSurveyBox = ({ title, link, id: surveyId }) => {
    const copyLink = async () => navigator.clipboard.writeText(`${window.location.origin}${link}`);
    const openLink = () => {
      onClose();
      return navigate(link);
    };
    return (
      <Box className={classes.surveyCard} key={link}>
        <Box className={classes.surveyTop}>
          <Typography className={classes.surveyTitle} variant="subtitle1">
            {title}
          </Typography>
          <Box className={classes.surveyBottom}>
            <Button
              disabled={surveyId === null}
              data-cy={`launch-survey-${surveyId}`}
              onClick={() => openLink()}
              variant="outlined"
              color="primary"
            >
              LAUNCH SURVEY
            </Button>
            <Tooltip title="Copy Link to Clipboard" placement="top">
              <ButtonBase
                disabled={surveyId === null}
                onClick={() => copyLink()}
                className={classes.surveyClip}
              >
                <Assignment />
              </ButtonBase>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    );
  };

  const renderVertical = (vertical) => {
    if (!surveys[vertical]) {
      return null;
    }
    const possibleDepartments = Object.keys(surveys[vertical]);
    if (vertical === "scale_group") {
      possibleDepartments.push("human_capital");
    }

    const prepAndMap = (s) => (s?.map((f) => ({
      title: f.name,
      link: `/org/${id}/surveys/${f.id}`,
      id: f.id,
    }))
      ?.map(renderSurveyBox));

    const optionDepartments = possibleDepartments
      .sort(
        (a, b) => departmentOrder.indexOf(a) - departmentOrder.indexOf(b),
      )
      .map((v) => ({
        key: v,
        label: InvestmentProductMappings[v],
      }));

    return (
      <>
        <Box className={classes.interiorButtons}>
          <ButtonTabs
            options={optionDepartments}
            color="secondary"
            disableTest={({ key }) => !surveys[vertical][key]?.length}
            onClick={(option) => setSurveyDepartment(option.key)}
            activeKey={currentSurveyDepartment}
          />
        </Box>
        <Box className={classes.surveyCardContainer}>
          {currentSurveyDepartment
            && prepAndMap(surveys[vertical][currentSurveyDepartment])}
        </Box>
      </>
    );
  };
  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={onClose}
    >
      <Drawer
        anchor="right"
        open={open}
        variant="persistent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Box className={classes.header}>
          <Typography style={{ color: theme.palette.text.primary }} variant="h3">
            Surveys
          </Typography>
          <ButtonBase onClick={onClose}>
            <Close />
          </ButtonBase>
        </Box>
        <Box className={classes.switchTypeContainer}>
          {isLoadingFrameworks ? null : (
            <ButtonTabs
              options={verticalOrder.map((v) => ({
                key: v,
                label: InvestmentProductMappings[v],
              }))}
              onClick={(option) => handleSurveyVertical(option.key)}
              activeKey={currentSurveyVertical}
            />
          )}
        </Box>
        <Box display="flex" style={{ flexDirection: "column", alignItems: "center" }} justifyContent="center">
          {isLoadingFrameworks ? (
            <Progress />
          ) : (
            renderVertical(currentSurveyVertical)
          )}
        </Box>
      </Drawer>
    </ClickAwayListener>
  );
}

SurveyDrawer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SurveyDrawer;
