import React from "react";
import {
  Checkbox,
  FormControl,
  TextField,
  Autocomplete,
} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface Option {
  id: string;
  label: string;
  formatter?: string,
  group?: string
}

interface Props {
  id: string;
  label?: string;
  options: Option[];
  value: Option[],
  onChange: (value: string | Option[]) => void
}

export default function AutocompleteSelect({
  id,
  label,
  value,
  onChange,
  options,
}: Props) {
  return (
    <FormControl
      margin="dense"
      variant="outlined"
      fullWidth
    >
      <Autocomplete
        multiple
        value={value}
        onChange={(event, newVal: string | Option[]): void => {
          onChange(newVal);
        }}
        isOptionEqualToValue={(option, current) => option.id === current.id}
        groupBy={(option) => option.group}
        size="small"
        id={id}
        getOptionLabel={(option) => option.label}
        options={options}
        disableCloseOnSelect
        renderOption={(props, option, state) => (
          <li
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
          >
            <Checkbox
              color="primary"
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={state.selected}
            />
            {option.label}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label={label}
            placeholder="Search"
          />
        )}
      />
    </FormControl>
  );
}
