import React from "react";
import {
  Box,
  Typography,
  Link,
} from "@mui/material";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";

type OrganizationTaskListHeaderProps = {
  valorId: string;
  name: string;
  avatar: string;
};

export default function OrganizationTaskListHeader({
  valorId,
  name,
  avatar,
}: OrganizationTaskListHeaderProps) {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      gap={2}
    >
      <Link href={`/org/${valorId}`}>
        <CompanyAvatar
          src={avatar}
          alt={name}
          size="30"
        />
      </Link>
      <Typography variant="body2">{name}</Typography>
    </Box>
  );
}
