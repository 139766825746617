import React from "react";
import { useQuery } from "react-query";
import { getDrafts } from "@/api/FrameworkSurvey";
import {
  Box, Typography, Card, Skeleton,
} from "@mui/material";
import { useNavigate, Link } from "react-router-dom";
import CompanyAvatar from "@/ui/atoms/CompanyAvatar";
import { InvestmentProductMappings } from "@/constants/InvestmentProductsMapping";
import dayjs from "dayjs";

export function DraftCard() {
  const { data: drafts = [], isLoading } = useQuery("Drafts", async () => getDrafts(null));
  const navigate = useNavigate();

  return (
    <Card>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          color: (theme) => theme.palette.text.secondary,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: (theme) => theme.spacing(1),
            paddingTop: (theme) => theme.spacing(2),
            padding: (theme) => theme.spacing(1),
            borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
          }}
        >
          <Typography
            sx={{
              color: (theme) => theme.palette.text.secondary,
              fontWeight: (theme) => theme.typography.fontWeightSemiBold,
              fontSize: "12px",
            }}
          >
            RECENT SURVEY DRAFTS
          </Typography>
        </Box>
        <Box
          sx={{
            "& > div:not(:last-child)": {
              borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
            },
          }}
        >
          {isLoading && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
              <Skeleton variant="rectangular" width="100%" height={60} />
              <Skeleton variant="rectangular" width="100%" height={60} />
              <Skeleton variant="rectangular" width="100%" height={60} />
              <Skeleton variant="rectangular" width="100%" height={60} />
            </Box>
          )}
          {drafts.length === 0 && !isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80px",
              }}
            >
              <Typography variant="caption">No drafts available</Typography>
            </Box>
          )}
          {drafts.slice(0, 4).map((draft) => (
            <Box
              key={draft.id}
              gap={1}
              sx={{
                height: "80px",
                overflow: "hidden",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                padding: (theme) => theme.spacing(1),
                "&:hover": {
                  cursor: "pointer",
                  backgroundColor: (theme) => theme.palette.action.hover,
                },
              }}
              onClick={() => navigate(
                `/org/${draft.organization.valorId}/surveys/${draft.surveyId}?responseId=${draft.id}`,
              )}
            >
              <Box
                sx={{
                  minWidth: 40,
                  minHeight: 40,
                  maxWidth: 40,
                  maxHeight: 40,
                }}
              >
                <CompanyAvatar src={draft.organization.logoUrl} />
              </Box>
              <Box>
                <Typography
                  sx={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    width: "100%",
                    WebkitLineClamp: 2,
                  }}
                >
                  {`${draft.organization.name} - ${draft.name} - ${InvestmentProductMappings[draft.department]}`}
                </Typography>
                <Typography variant="caption">
                  {dayjs
                    .utc(draft.lastModified)
                    .local()
                    .format("MM/DD/YYYY hh:mm a")}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: (theme) => theme.spacing(1, 2),
            borderTop: (theme) => `1px solid ${theme.palette.divider}`,
            color: (theme) => theme.palette.primary.main,
            fontWeight: (theme) => theme.typography.fontWeightBold,
            fontSize: "16px",
            textDecoration: "none !important",
            "&:hover": {
              backgroundColor: (theme) => theme.palette.action.hover,
            },
          }}
        >
          <Link to="/home/drafts">SEE MORE</Link>
        </Box>
      </Box>
    </Card>
  );
}

export default DraftCard;
