import React from "react";
import { Chip } from "@mui/material";
import { SxProps, styled } from "@mui/material/styles";
import mapping from "./SectorMapping";

interface Props {
  sectorType: (
      "enterprise_b2b" |
      "bio_tech_pharma" |
      "financial_technology" |
      "transportation_logistics" |
      "strategic_tech" |
      "advanced_engineering" |
      "advanced_manufacturing" |
      "health_wellness" |
      "food_retail_consumer" |
      "energy" |
      // old
      "consumer" |
      "real_estate" |
      "enterprise_b_2_b" |
      "vertical_ai" |
      "health" |
      "security" |
      "food_retail" |
      "fintech" |
      "blockchain_crypto" |
      "biotech_pharma" |
      "energy_sustainability" |
      "metaverse" |
      "advanced_computing"
    );
  sx: SxProps;
}

const BlueChip = styled(Chip)(({ theme }) => ({
  color: theme.palette.text.primary,
  backgroundColor: theme.palette.background.blue,
}));

export function SectorChip({ sectorType, sx }: Props) {
  const map = mapping[sectorType];
  if (map === undefined) { return null; }
  const { Icon, label } = map;
  return (
    <BlueChip
      color="primary"
      icon={<Icon />}
      label={label}
      sx={sx}
    />
  );
}

export default SectorChip;
