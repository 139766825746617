import React from "react";
import {
  Box, Card, Grid, Skeleton, Alert, Link, Button,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";

import { useQuery } from "react-query";
import { getSignalFeatures, getSignalCoverage } from "@/api/SignalFeatures";
import { hideFimoicaSignal } from "@/api/Signals";
import { Organization, getOrganizationSignalsById } from "@/api/Organization";
import SignalChip from "@/components/SignalChip";

import ErrorMessage from "@/ui/atoms/ErrorMessage";
import { useAuth } from "@/hooks/useAuth";
import { canGlassMoicScores, canSeeExplainability } from "@/constants/Roles";
import FeatureList from "./FeatureList";
import FeatureCoverage from "./FeatureCoverage";

const useMoic = (valorId) => {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["SignalMoic", valorId],
    async () => getOrganizationSignalsById(valorId, ["signal_fimoica_prediction"]),
    {
      retry: false,
    },
  );
  return {
    data,
    isLoading,
    error,
    isError,
  };
};

const useFeatures = (valorId, modelId, limit) => {
  const {
    data,
    isLoading,
    isError,
    error,
  } = useQuery(
    ["SignalFeatures", modelId, valorId, limit],
    async () => getSignalFeatures(modelId, valorId, limit),
  );
  return {
    data: data?.data,
    isLoading,
    isError,
    error,
  };
};

const useCoverage = (valorId, modelId) => {
  const {
    data,
    isError,
    isLoading,
    error,
  } = useQuery(
    ["SignalCoverage", modelId, valorId],
    async () => getSignalCoverage(modelId, valorId),
  );
  return {
    data: data?.data,
    isLoading,
    isError,
    error,
  };
};

export default function ModelFeatures({
  company,
  modelId,
}: {
  company: Organization;
  modelId: string;
}) {
  const {
    data,
    isLoading: featuresLoading,
    isError: isFeaturesError,
  } = useFeatures(company.valorId, modelId, 7);
  const features = data?.filter((feature) => feature.value !== null)
    .toSorted((a, b) => Math.abs(b.shapScore) - Math.abs(a.shapScore));
  const {
    data: coverage,
    isLoading: coverageLoading,
    isError: isCoverageError,
  } = useCoverage(company.valorId, modelId);
  const {
    data: moic,
    isLoading: moicLoading,
    isError: isMoicError,
  } = useMoic(company.valorId);

  let predictedMoic = null;
  let fimoicaSignal = null;
  if (moic !== undefined) {
    fimoicaSignal = moic?.signals?.find((s) => (
      s.signalType === "signal_fimoica_prediction"
    ));
    const fimoicaPrediction = fimoicaSignal?.score;
    if (fimoicaPrediction !== undefined && fimoicaPrediction !== null) {
      predictedMoic = Math.round(
        fimoicaPrediction * 100,
      ) / 100;
    }
  }

  const { user } = useAuth();

  const canSeeDeleteMoic = canGlassMoicScores(user);

  const overallExisting = coverage?.reduce((acc, { existing: e }) => acc + e, 0);
  const overallTotal = coverage?.reduce((acc, { total: t }) => acc + t, 0);
  const overallCoverage = (
    overallTotal > 0
      ? Math.floor((overallExisting / overallTotal) * 100)
      : null
  );
  const hideFeatureList = overallCoverage === null || overallCoverage < 40;

  if (!canSeeExplainability(user) || isFeaturesError || isCoverageError || isMoicError) {
    return (
      <ErrorMessage
        Icon={<LockIcon />}
        title="Unauthorized"
        message={(
          <>
            You don’t have access to the Model Explainability for this company. If you think this is
            an error, please contact
            {" "}
            <Link
              href="mailto:labs@valorep.com?subject=Explainability Access"
            >
              labs@valorep.com
            </Link>
          </>
        )}
      />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={8}>
        <Card
          sx={{
            width: "100%",
            height: "100%",
          }}
        >
          {featuresLoading ? (
            <FeatureList.Skeleton />
          ) : (
            <FeatureList
              modelId={modelId}
              features={features}
              company={company}
              showLowCoverageAlert={hideFeatureList}
            />
          )}
        </Card>
      </Grid>

      <Grid
        item
        sm={12}
        md={4}
        sx={{
          width: "100%",
        }}
      >
        <Box display="flex" flexDirection="column" gap={1}>
          {predictedMoic !== null && !moicLoading && (
            <Card>
              <Box padding={1}>
                {moicLoading ? (
                  <Box>
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                  </Box>
                ) : !isMoicError && predictedMoic !== null ? (
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap={1}
                  >
                    <SignalChip
                      signal={{
                        signalType: "signal_fimoica_prediction",
                        score: predictedMoic,
                      }}
                      showIcon
                      showName
                    />
                    {canSeeDeleteMoic ? (
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => hideFimoicaSignal(fimoicaSignal.signalId)}
                      >
                        HIDE MOIC Score
                      </Button>
                    ) : null}
                  </Box>
                ) : null}
                {isMoicError && (
                  <Alert severity="error">
                    Unauthorized access. Please contact your administrator.
                  </Alert>
                )}
              </Box>
            </Card>
          )}
          <Card>
            <Box padding={1}>
              {coverageLoading ? (
                <FeatureCoverage.Loading />
              ) : (
                <FeatureCoverage coverage={coverage} />
              )}
            </Box>
          </Card>
        </Box>
      </Grid>
    </Grid>
  );
}
