import React, { useEffect, useState } from "react";

import { useField } from "react-final-form";
import {
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  styled,
} from "@mui/material";

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  textAlign: "left",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  width: "100%",
  "& .MuiInputBase-inputMultiline": {
    height: theme.spacing(4),
    fontSize: theme.spacing(3),
  },
}));

interface Estimate {
  personal: string;
  period: string;
}

interface Props {
  questionId: string;
  initialValue: Estimate;
  initialComment: string;
}

const initialState = { personal: "", period: "" };

export default function DollarEstimateBizPersonalPrediction(props: Props) {
  const { questionId, initialValue = initialState, initialComment = "" } = props;
  const [notPersonalApplicable, setPersonalNotApplicable] = useState(false);
  const [estimates, setEstimates] = useState(initialValue);
  useEffect(() => {
    setEstimates(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (estimates.personal === "N/A") {
      setPersonalNotApplicable(true);
    }
  }, [estimates.personal]);

  const validate = (value: string) => {
    const { personal, period } = JSON.parse(value);
    if (personal && personal !== "N/A" && !period) {
      return "Required Period Type";
    }
    if (!personal) {
      return "Required";
    }
    if (personal !== "N/A" && Number.isNaN(Number(personal))) {
      return "Invalid Input Numbers / Commas Only unless N/A";
    }
    return undefined;
  };

  const formatVal = (val: string) => {
    if (!val) return "";
    if (val !== "N/A" && !Number.isNaN(Number(val))) {
      return `$${Number(val).toLocaleString("en-US")}`;
    }
    return val;
  };

  const cleanValue = (val) => {
    const parsedValue = val.replaceAll(",", "").replaceAll("$", "");
    if (!parsedValue) {
      return "";
    }
    if (parsedValue === "N/A" || Number.isNaN(Number(parsedValue))) {
      return parsedValue;
    }
    return String(Number(parsedValue));
  };

  const parse = (value: string) => {
    const obj = JSON.parse(value);

    const cleanedPersonal = cleanValue(obj.personal);
    let cleanedPeriod = obj.period;

    if (cleanedPersonal === "N/A") {
      cleanedPeriod = "";
    }

    return JSON.stringify({
      personal: cleanedPersonal,
      period: cleanedPeriod,
    });
  };

  const { input: estimateInput, meta: estimateMeta } = useField(
    `${questionId}.answerData`,
    {
      initialValue: JSON.stringify(initialValue),
      validate,
      parse,
    },
  );

  const { input: commentaryInput } = useField(`${questionId}.commentary`, { initialValue: initialComment });

  const handleChange = (key: string, val: string) => {
    const cleanedVal = cleanValue(val);
    const copy = { ...estimates, [key]: cleanedVal };
    const formattedCopy = { ...estimates, [key]: formatVal(cleanedVal) };
    setEstimates(formattedCopy);
    estimateInput.onChange(JSON.stringify(copy));
  };

  const onCheckChange = (event, setCheckChange, onChange) => {
    const { checked } = event.target;
    setCheckChange(checked);
    if (checked) {
      onChange("N/A");
    } else {
      onChange("");
    }
  };

  return (
    <Grid container item xs={12}>
      <Grid item xs={12}>
        <Typography
          variant="body1"
          sx={{
            color:
              estimateMeta.submitFailed && estimateMeta.error
                ? "red"
                : "inherit",
          }}
        >
          What Period Type is this Metric For?
        </Typography>
        <RadioGroup
          data-cy={`period-${questionId}`}
          defaultValue={null}
          row
          onChange={(event) => handleChange("period", event.target.value)}
          value={estimates.period}
        >
          <FormControlLabel
            disabled={estimates.personal === "N/A"}
            value="MONTHLY"
            control={<Radio />}
            label="Monthly"
          />
          <FormControlLabel
            disabled={estimates.personal === "N/A"}
            value="QUARTERLY"
            control={<Radio />}
            label="Quarterly"
          />
          <FormControlLabel
            disabled={estimates.personal === "N/A"}
            value="YEARLY"
            control={<Radio />}
            label="Yearly"
          />
        </RadioGroup>
      </Grid>
      <Grid container item xs={12} spacing={1}>
        <Grid item xs={12}>
          <StyledTypography variant="body2">
            Numbers and Commas Only, Complete Numbers not in Thousands or
            Millions
          </StyledTypography>
        </Grid>
        <Grid item xs={8}>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  data-cy={`not-applicable-${questionId}`}
                  checked={notPersonalApplicable}
                  value={notPersonalApplicable}
                  onClick={(event) => onCheckChange(event, setPersonalNotApplicable, (val) => handleChange("personal", val))}
                />
              )}
              label="Not Applicable"
            />
          </FormGroup>
          <StyledTextField
            data-cy={`dollar-estimate-with-period-${questionId}`}
            value={formatVal(estimates.personal)}
            onChange={(event) => handleChange("personal", event.target.value)}
            data-formstate={estimateMeta?.error ? "error" : ""}
            disabled={notPersonalApplicable}
            multiline
            variant="outlined"
            error={!!(estimateMeta.submitFailed && estimateMeta.error)}
            label={estimateMeta.submitFailed ? estimateMeta.error : ""}
          />
        </Grid>
        <Grid item xs={4}>
          <StyledTypography variant="body2" sx={{ height: "42px" }}>
            COMMENTS
          </StyledTypography>
          <StyledTextField
            {...commentaryInput}
            data-cy={`dollar-estimate-with-period-commentary-${questionId}`}
            multiline
            variant="outlined"
            onChange={(event) => commentaryInput.onChange(event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
