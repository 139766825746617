/* eslint-disable no-console */
import axios from "axios";

export const lastTwoWeeksofInteractions = async () => {
  try {
    const response = await axios.get("/api/interactions/all");
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export default {
  lastTwoWeeksofInteractions,
};
