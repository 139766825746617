export const SORT_TYPE_MAP = {
  text: "alpha",
  date: "date",
  target_date: "date",
  number: "numeric",
  dollar: "numeric",
  company: "alpha",
  user: "alpha",
  user_multi: "alpha",
  select: "default",
  select_multi: "default",
  checkbox: "boolean",
  note: "alpha",
  latest_note: "alpha",
  person: "alpha",
  person_multi: "alpha",
  ryg: "default",
  probability_equity_value_json: "numeric",
};

export const FILTER_TYPE_MAP = {
  text: "text",
  date: "date",
  target_date: "date",
  number: "number",
  dollar: "number",
  company: "company",
  user: "user",
  user_multi: "user_multi",
  select: "select",
  select_multi: "select",
  checkbox: "boolean",
  person: "person",
  person_multi: "person_multi",
  latest_note: "note",
  latest_org_note: "note",
  most_recent_comment: "comment",
  ryg: "ryg",
};

export const FIELD_NAME_TYPE_MAP = {
  select: "choiceId",
  select_multi: "choiceId",
  company: "company",
  user: "user",
  user_multi: "user",
  person: "person",
  person_multi: "person",
  number: "number",
  dollar: "number",
  text: "value",
  text_multi: "value",
  checkbox: "value",
  date: "number",
  target_date: "number",
  ryg: "value",
  latest_note: "note",
  latest_org_note: "note",
  most_recent_comment: "comment",
  probability_equity_value_json: "jsonNumericValue",
};

export const FIELD_VALUE_TYPE_MAP = {
  select: "choiceId",
  select_multi: "choices",
  ryg: "value",
  date: "numericValue",
  target_date: "numericValue",
  checkbox: "value",
  user_multi: "users",
  user: "user",
  person: "person",
  person_multi: "persons",
  text: "value",
  text_multi: "value",
  company: "company",
  dollar: "numericValue",
  number: "numericValue",
  probability_equity_value_json: "jsonValue",
};
