import React from "react";

import "regenerator-runtime";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);

// eslint-disable-next-line no-console
reportWebVitals(console.log);
