import React from "react";
import SvgIcon from "@mui/material/SvgIcon";

export default function Flake() {
  return (
    <SvgIcon width="602" height="602" viewBox="0 0 602 602">
      <g id="top-v">
        <path
          d="M172.5 0H226.5L270 105L326.5 240L301 297.5L172.5 0Z"
          fill="#0042ED"
        />
        <path
          d="M429.5 3.05176e-05H378.5L334.5 106L278.108 244.5L301 297.5L429.5 3.05176e-05Z"
          fill="#0042ED"
        />
      </g>
      <g id="bottom-v">
        <path
          d="M172.5 602H226.5L270 497L326.5 362L301 304.5L172.5 602Z"
          fill="#0042ED"
        />
        <path
          d="M429.5 602H378.5L334.5 496L278.108 357.5L301 304.5L429.5 602Z"
          fill="#0042ED"
        />
      </g>
      <g id="right-v">
        <path
          d="M602 429.5V375.5L497 332L362 275.5L304.5 301L602 429.5Z"
          fill="#0042ED"
        />
        <path
          d="M602 172.5V223.5L496 267.5L357.5 323.892L304.5 301L602 172.5Z"
          fill="#0042ED"
        />
      </g>
      <g id="left-v">
        <path
          d="M0 172.5V226.5L105 270L240 326.5L297.5 301L0 172.5Z"
          fill="#0042ED"
        />
        <path
          d="M3.05176e-05 429.5V378.5L106 334.5L244.5 278.108L297.5 301L3.05176e-05 429.5Z"
          fill="#0042ED"
        />
      </g>
    </SvgIcon>
  );
}
