import React from "react";
import {
  Box, Typography, Collapse, ClickAwayListener, Button,
  Skeleton,
} from "@mui/material";
import GrokIcon from "@/ui/atoms/Icons/Grok";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getConversations } from "@/api/Chat";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { Add } from "@mui/icons-material";

export default function Drawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const [internalOpen, setInternalOpen] = React.useState(false);

  const {
    data: chats = [],
    isLoading: chatsLoading,
  } = useQuery("conversations", () => getConversations());
  const isLoading = chatsLoading;

  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          display: {
            xs: "none",
            sm: "flex",
          },
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          cursor: "pointer",
          position: "absolute",
          margin: 2,
          zIndex: internalOpen ? 0 : 2,
        }}
        onClick={() => {
          navigate("/grok");
        }}
        onMouseOver={() => {
          setInternalOpen(true);
        }}
      >
        <GrokIcon />
        Grok
        <DoubleArrowIcon />
      </Typography>

      <Collapse
        in={open || internalOpen}
        orientation="horizontal"
        easing="ease"
        sx={{
          position: "absolute",
          left: 0,
          height: "100%",
          zIndex: 1,
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRight: (theme) => `solid 1px ${theme.palette.divider}`,
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            onClose();
            setInternalOpen(false);
          }}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            gap={2}
            padding={2}
            width="300px"
            onMouseLeave={() => {
              onClose();
              setInternalOpen(false);
            }}
          >
            <Typography
              variant="h3"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/grok");
              }}
            >
              <GrokIcon />
              Grok
            </Typography>

            <Button
              variant="contained"
              size="small"
              startIcon={<Add />}
              onClick={() => {
                navigate("/grok");
              }}
            >
              Start New Chat
            </Button>

            <Typography
              variant="overline"
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
              }}
            >
              Recents
            </Typography>

            {isLoading && (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
              >
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
                <Skeleton variant="text" width="100%" />
              </Box>
            )}

            {chats.length === 0 && !isLoading && (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              gap={1}
            >
              <Typography>
                No recent chats
              </Typography>
            </Box>
            )}

            {chats.length > 0 && (
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
            >
              {chats.map((chat) => (
                <Typography
                  variant="body1"
                  key={chat.id}
                  sx={{
                    cursor: "pointer",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => {
                    navigate(`/grok/chat/${chat.id}`);
                  }}
                >
                  {chat.title}
                </Typography>
              ))}
            </Box>
            )}
          </Box>
        </ClickAwayListener>
      </Collapse>
    </Box>
  );
}
