import React from "react";
import { useQuery } from "react-query";
import {
  Box, Skeleton, Alert, Typography,
} from "@mui/material";
import { getActivityFeedTasks } from "@/api/Metrics";

type CommentaryProps = {
  taskIds: string[];
};

export default function Commentary({
  taskIds,
}: CommentaryProps) {
  const {
    data,
    isLoading,
    isError,
  } = useQuery(["activityFeed", "tasks", ...taskIds], () => getActivityFeedTasks(taskIds), {
    refetchInterval: 0,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <Box
        py={1}
      >
        <Skeleton variant="text" width="100%" height={24} />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box>
        <Alert severity="error">
          Error loading task details. Please refresh the page or contact the labs team.
        </Alert>
      </Box>
    );
  }

  const taskReason = [...new Set(data.map((task) => task.taskStatusDetails))].at(0);
  const commentary = [...new Set(data.map((task) => task.commentary))].at(0);

  if (taskReason?.length || commentary?.length) {
    return (
      <Box
        py={1}
      >
        <Typography variant="body1">Comments:</Typography>
        {taskReason}
        {commentary}
      </Box>
    );
  }
  return (
    <Box
      py={1}
    >
      <Typography variant="body1">No commentary left on task.</Typography>
    </Box>
  );
}
