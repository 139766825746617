import React from "react";
import {
  Box, Typography,
  Chip,
  IconButton, Divider,
  Stack, Fab,
  Snackbar, Alert, Tooltip,
} from "@mui/material";
import Section from "@/ui/atoms/Section";
import Progress from "@/ui/atoms/Progress";
import { canReadTegusScores, canWriteTegusScores } from "@/constants/Roles";
import { useQuery, useQueryClient } from "react-query";
import {
  getTranscriptById, getFavorites, postFavorite, deleteFavorite,
  Score,
} from "@/api/Tegus";
import Clamps from "@/components/Clamps";
import {
  ArrowDropDown as ArrowDown, ArrowDropUp as ArrowUp, Favorite, Poll, Share, Print,
} from "@mui/icons-material";
import { useAuth } from "@/hooks/useAuth";
import Helmet from "react-helmet";
import NotPrintable from "@/components/NotPrintable";
import useIsInTouchPWA from "@/hooks/useIsInTouchPWA";
import Conversation from "./Conversation";
import IHEWindow from "./IHEWindow";
import { promptTypeSort, promptTypeTitleMap } from "./utils";
import IHEChip from "./IHEChip";

function SectionTitle({ children, onClick }: { children: React.ReactNode, onClick?: () => void }) {
  return (
    <Typography
      variant="h3"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 1,
        width: "100%",
        cursor: onClick ? "pointer" : "default",
      }}
      onClick={onClick}
    >
      {children}
    </Typography>
  );
}

function LLMScore({
  score: {
    promptType,
    color,
    reason,
  },
}: {
  score: Score;
}) {
  const [show, setShow] = React.useState(false);
  return (
    <Box>
      <IHEChip
        key={promptType}
        label={promptTypeTitleMap[promptType]}
        color={color}
        onClick={() => setShow((prev) => !prev)}
      />
      <Clamps
        maxLines={0}
        lineHeight={1.43}
        collapsed={!show}
      >
        <Typography variant="body2">
          {reason}
        </Typography>
      </Clamps>
    </Box>
  );
}

export default function Transcript({
  id,
}: {
  id: number;
}) {
  const [showTopics, setShowTopics] = React.useState(true);
  const [showMoreSummary, setShowMoreSummary] = React.useState(false);
  const [showMoreBio, setShowMoreBio] = React.useState(true);
  const [showMoreQuestions, setShowMoreQuestions] = React.useState(true);
  const [showIhe, setShowIhe] = React.useState(false);

  const [hasSummaryOverflow, setHasSummaryOverflow] = React.useState(true);
  const [hasBioOverflow, setHasBioOverflow] = React.useState(true);

  const [showCopiedSnackbar, setShowCopiedSnackbar] = React.useState(false);

  const {
    data,
    isLoading,
  } = useQuery(
    ["transcript", id],
    () => getTranscriptById(id),
  );

  const titleRef = React.useRef();
  React.useEffect(() => {
    // if isloading changes, scroll title into view
    titleRef.current?.scrollIntoView({
      behavior: "smooth",
    });
  }, [isLoading, id]);

  const {
    data: favoriteData,
    isLoading: favoriteLoading,
    refetch: refetchFavorites,
  } = useQuery(
    ["favorites"],
    () => getFavorites(),
    {
      refetchInterval: false,
    },
  );

  const queryClient = useQueryClient();

  const favorite = async () => {
    if (favoriteData && favoriteData.find((f) => f.toString() === id)) {
      queryClient.setQueryData(["favorites"], (oldData) => oldData.filter((f) => f.toString() !== id));
      await deleteFavorite(id);
      refetchFavorites();
    } else {
      queryClient.setQueryData(["favorites"], (oldData) => [...oldData, id]);
      await postFavorite(id);
      refetchFavorites();
    }
  };
  const { user } = useAuth();

  const isInTouchPWA = useIsInTouchPWA();

  const transcript = data;
  const isFavorite = React.useMemo(
    () => favoriteData?.find((f) => f.toString() === id),
    [favoriteData, id],
  );

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={300}
      >
        <Progress />
      </Box>
    );
  }

  const canSeeIHE = canReadTegusScores(user);
  const canWriteIHE = canWriteTegusScores(user);

  return (
    <Section>
      <Helmet>
        <title>{transcript.title}</title>
      </Helmet>

      {canWriteIHE && (
        <NotPrintable>
          <Tooltip title="View IHE for Transcript">
            <Fab
              color="secondary"
              onClick={() => setShowIhe((prev) => !prev)}
              sx={{
                position: "fixed",
                bottom: isInTouchPWA ? 140 : 100,
                right: 20,
                zIndex: 2000,
                opacity: 0.8,
              }}
            >
              <Poll />
            </Fab>
          </Tooltip>
        </NotPrintable>
      )}
      <Stack
        direction="row"
        gap={1}
        alignItems="center"
        justifyContent="space-between"
        width="100%"
      >
        <Typography variant="h2" ref={titleRef}>
          {transcript.title}
        </Typography>
        <NotPrintable>
          <Stack direction="row" gap={0.5} alignItems="center">
            <Tooltip title="Add to favorites">
              <IconButton
                onClick={() => {
                  favorite();
                }}
                sx={{
                  color: (theme) => (isFavorite ? theme.palette.pink.main : null),
                }}
                disabled={favoriteLoading}
              >
                <Favorite />
              </IconButton>
            </Tooltip>

            <Tooltip title="Share">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href);
                  setShowCopiedSnackbar(true);
                }}
              >
                <Share />
              </IconButton>
            </Tooltip>

            <IconButton
              onClick={() => {
                window.print();
              }}
            >
              <Print />
            </IconButton>

            <Snackbar
              open={showCopiedSnackbar}
              autoHideDuration={6000}
              onClose={() => setShowCopiedSnackbar(false)}
              message="Link copied to clipboard!"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            >
              <Alert
                severity="info"
                onClose={() => setShowCopiedSnackbar(false)}
              >
                Link copied to clipboard!
              </Alert>
            </Snackbar>
          </Stack>
        </NotPrintable>
      </Stack>
      <Section.Content>
        <Box display="flex" flexDirection="column" gap={2}>
          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.blue.highlight,
              color: (theme) => theme.palette.getContrastText(theme.palette.blue.highlight),
              borderRadius: 0.5,
              padding: 1,
            }}
          >
            <SectionTitle
              onClick={() => {
                setShowMoreSummary((prev) => !prev);
              }}
            >
              Summary
              {hasSummaryOverflow && (
                <IconButton>
                  {showMoreSummary ? <ArrowDown /> : <ArrowUp />}
                </IconButton>
              )}
            </SectionTitle>
            <Clamps
              maxLines={3}
              collapsed={showMoreSummary}
              lineHeight={1.43}
              onReflow={(hasOverflow) => {
                setHasSummaryOverflow(hasOverflow);
              }}
            >
              {transcript?.summary}
            </Clamps>
          </Box>
          <Divider />
          <Box>
            <SectionTitle
              onClick={() => {
                setShowMoreBio((prev) => !prev);
              }}
            >
              Expert Details
              {hasBioOverflow && (
                <IconButton>
                  {showMoreBio ? <ArrowDown /> : <ArrowUp />}
                </IconButton>
              )}
            </SectionTitle>
            <Clamps
              maxLines={3}
              collapsed={showMoreBio}
              lineHeight={1.43}
              onReflow={(hasOverflow) => {
                setHasBioOverflow(hasOverflow);
              }}
            >
              {data?.biography}
            </Clamps>
          </Box>
          {data?.screeningQuestions?.length > 0 && (
            <>
              <Divider />
              <Box>
                <SectionTitle
                  onClick={() => {
                    setShowMoreQuestions((prev) => !prev);
                  }}
                >
                  <span>
                    Screening Questions
                    {" "}
                    {data?.screeningQuestions.length > 0
                      && `(${data?.screeningQuestions.length})`}
                  </span>
                  <IconButton>
                    {showMoreQuestions ? <ArrowDown /> : <ArrowUp />}
                  </IconButton>
                </SectionTitle>
                <Box display="flex" flexDirection="column" gap={1}>
                  <Clamps
                    maxLines={0}
                    collapsed={showMoreQuestions}
                    lineHeight={1.43}
                  >
                    {data?.screeningQuestions?.map(({ question, answer }) => (
                      <Box key={question + answer}>
                        <Typography variant="boldBody2">
                          Q:
                          {" "}
                          {question}
                        </Typography>
                        <Typography variant="body2">
                          A:
                          {answer}
                        </Typography>
                      </Box>
                    ))}
                  </Clamps>
                </Box>
              </Box>
            </>
          )}
          <Divider />
          <Stack>
            <SectionTitle
              onClick={() => {
                setShowTopics((prev) => !prev);
              }}
            >
              Topics (
              {transcript?.inferredDiscussionTopics?.length}
              )
              <IconButton>
                {showTopics ? <ArrowDown /> : <ArrowUp />}
              </IconButton>
            </SectionTitle>
            <Clamps maxLines={0} collapsed={showTopics}>
              <Stack direction="row" flexWrap="wrap" gap={1}>
                {transcript?.inferredDiscussionTopics?.map((topic) => (
                  <Chip key={topic} label={topic} size="small" />
                ))}
              </Stack>
            </Clamps>
          </Stack>
          {canSeeIHE && (
            <>
              <Divider />
              <Stack>
                <SectionTitle>
                  LLM IHE Scores
                  {" "}
                  {!transcript.scores?.length && " - Scores not available."}
                </SectionTitle>
                <Stack spacing={2} marginTop={2}>
                  {transcript.scores
                    .toSorted(
                      (a, b) => promptTypeSort[a.promptType]
                        - promptTypeSort[b.promptType],
                    )
                    .map((score) => (
                      <LLMScore key={score.promptType} score={score} />
                    ))}
                </Stack>
              </Stack>
            </>
          )}
          <Divider />
          <Box
            sx={{
              pageBreakBefore: "always",
              pageBreakAfter: "always",
            }}
          >
            <Stack direction="row" gap={2} alignItems="center">
              <Typography variant="h3">Interview</Typography>
              <Typography variant="body2">
                Call on
                {" "}
                {transcript?.callDate.format("MMM DD, YYYY")}
              </Typography>
            </Stack>
            <Conversation conversation={transcript?.conversation} />
          </Box>
        </Box>
      </Section.Content>

      {showIhe && (
        <IHEWindow
          transcriptId={transcript.id}
          onClose={() => setShowIhe(false)}
        />
      )}
    </Section>
  );
}
