import React, { useState } from "react";
import {
  TextField, Box, Typography, Button, Modal, Link,
} from "@mui/material";
import omitBy from "lodash/omitBy";
import { Form, Field } from "react-final-form";
import { getOverrideOrg } from "@/api/Organization";

type FieldConfig = {
  editable: boolean;
  label: string;
  validate?: (value: any) => string | undefined;
  previewFunc?: (val: any) => JSX.Element | string;
};

type Fields = {
  [key: string]: FieldConfig;
};

const fields: Fields = {
  cbId: { editable: true, label: "CrunchBase ID" },
  pbId: { editable: true, label: "PitchBook ID" },
  affId: { editable: true, label: "Affinity ID" },
  liId: { editable: true, label: "LinkedIn ID" },
  pdlId: { editable: true, label: "People Data Lab ID" },
  smId: { editable: true, label: "SecondMeasure ID" },
  ceId: { editable: true, label: "Consumer Edge ID" },
  lhId: { editable: true, label: "Launch ID" },
  aaId: { editable: true, label: "App Annie ID" },
  dfId: { editable: true, label: "Diffbot ID" },
  ghId: { editable: true, label: "Github ID" },
  syId: { editable: true, label: "Synaptic ID" },
  tgId: { editable: true, label: "Tegus ID" },
  smV2Id: { editable: true, label: "SecondMeasure V2 ID" },
  pbInvId: { editable: true, label: "PitchBook Investor ID" },
};

const idFields = [
  "cbId",
  "pbId",
  "ceId",
  "affId",
  "liId",
  "pdlId",
  "smId",
  "lhId",
  "aaId",
  "dfId",
  "ghId",
  "syId",
  "tgId",
];

type OverrideProps = {
  currentState: Record<string, any>;
  onSubmit: (data: any) => Promise<any>;
  valorId: string;
  mode?: "edit" | "create";
};

function Override({
  currentState, onSubmit, valorId, mode = "edit",
}: OverrideProps) {
  const [requested, setRequested] = useState(false);
  const [differenceModelData, setDifferenceModel] = useState < any >(null);
  const [flowId, setFlowId] = useState < string | null >(null);

  const handleCreateClick = async (state: any) => {
    let combinedIdsData: any = null;
    const keys = Object.keys(state);
    const combinedIds = keys.filter((k) => idFields.includes(k));

    combinedIdsData = await Promise.all(combinedIds
      .map(async (k) => (state[k]
        ? { [k]: await getOverrideOrg({ [k]: state[k] }) }
        : null
      )));
    combinedIdsData = combinedIdsData.filter((x) => x).reduce(
      (acc, obj) => {
        const [key] = Object.keys(obj);
        if (obj[key]) {
          const {
            valorId: vId, name, logoUrl, domain,
          } = obj[key];
          acc[key] = {
            valorId: vId, name, logoUrl, domain,
          };
        }
        return acc;
      },
      {},
    );

    setDifferenceModel({ state, combinedIdsData });
  };

  const handleEditClick = async (state: any) => {
    let diff = omitBy(currentState, (v, k) => state[k] === v);

    const keys = Object.keys(diff);
    diff = (
      keys
        .reduce((acc, key) => {
          acc[key] = {
            before: currentState[key],
            after: state[key],
          };
          return acc;
        }, {})
    );

    const combinedIds = keys.filter((k) => idFields.includes(k));

    let combinedIdsData: any = null;
    if (combinedIds.length) {
      combinedIdsData = await Promise.all(combinedIds
        .map(async (k) => (diff[k].after
          ? { [k]: await getOverrideOrg({ [k]: diff[k].after }) }
          : null
        )));
      combinedIdsData = combinedIdsData.filter((x) => x).reduce(
        (acc, obj) => {
          const [key] = Object.keys(obj);
          if (obj[key]) {
            const {
              valorId: vId, name, logoUrl, domain,
            } = obj[key];
            acc[key] = {
              valorId: vId, name, logoUrl, domain,
            };
          }
          return acc;
        },
        {},
      );
    }

    setDifferenceModel({ state: diff, combinedIdsData });
  };

  const submitMode = {
    create: handleCreateClick,
    edit: handleEditClick,
  };

  const renderModal = () => (
    <Modal
      open={!!differenceModelData}
      onClose={() => setDifferenceModel(null)}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,.5)",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          maxWidth: "640px",
          maxHeight: "560px",
          overflowY: "auto",
          padding: 4,
          outline: "none",
          backgroundColor: (theme) => theme.palette.background.paper,
          borderRadius: 1,
          boxShadow: (theme) => theme.shadows[5],
        }}
      >
        <Box>
          {differenceModelData && (
            <>
              <Typography variant="subtitle1">
                These ID(s) appear elsewhere and will be removed
              </Typography>
              <pre>
                {JSON.stringify(differenceModelData.combinedIdsData, null, 2)}
              </pre>
            </>
          )}
        </Box>
        <Box>
          <Typography variant="subtitle1">Changes</Typography>
          <pre>{JSON.stringify(differenceModelData.state, null, 2)}</pre>
        </Box>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => navigator.clipboard.writeText(
              JSON.stringify({ valorId, ...differenceModelData }, null, 2),
            )}
          >
            Copy Changelog to Clipboard
          </Button>
        </Box>

        <Box>
          {requested ? (
            <Typography variant="subtitle1">Submitting...</Typography>
          ) : null}
          <Button
            variant="outlined"
            color="secondary"
            disabled={requested}
            onClick={() => setDifferenceModel(null)}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={requested}
            onClick={async () => {
              try {
                setRequested(true);
                let flow = null;
                if (mode === "create") {
                  const response = await onSubmit({
                    valorId,
                    ...differenceModelData.state,
                  });
                  flow = response.flow;
                } else {
                  const response = await onSubmit({
                    valorId,
                    ...Object.keys(differenceModelData.state).reduce(
                      (acc, key) => {
                        acc[key] = differenceModelData.state[key].after;
                        return acc;
                      },
                      {},
                    ),
                  });
                  flow = response.flow;
                }

                setFlowId(flow);
                setDifferenceModel(null);
              } catch (e) {
                console.error(e);
              } finally {
                setRequested(false);
              }
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Box>
      {flowId && (
        <Link
          href={`https://app.prefect.cloud/account/3926ffa2-3f24-41e0-9148-503e16d82f32/workspace/31cbb8f4-180d-4bca-afa8-0a9ad455fbea/flow-runs/flow-run/${flowId}`}
          target="_blank"
        >
          FLOW LINK
        </Link>
      )}
      {!!differenceModelData && renderModal()}

      <Typography variant="h2" sx={{ my: 1 }}>Override IDs</Typography>
      <Box display="flex" flexDirection="column">
        <Form
          onSubmit={submitMode[mode]}
          initialValues={currentState}
          render={({ handleSubmit, submitting, pristine }) => (
            <form onSubmit={handleSubmit}>
              {Object.keys(fields)
                .filter((x) => fields[x].editable)
                .map((field) => (
                  <Box
                    key={field}
                    display="flex"
                    style={{ height: "64px", marginBottom: "16px" }}
                  >
                    <Field
                      name={field}
                      validate={fields[field].validate}
                      render={({ input, meta }) => (
                        <>
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...input}
                            label={fields[field].label}
                            variant="outlined"
                            fullWidth
                            error={meta.error && meta.touched}
                            helperText={
                              meta.error && meta.touched ? meta.error : ""
                            }
                          />
                          {fields[field].previewFunc
                            && fields[field].previewFunc(input.value)}
                        </>
                      )}
                    />
                  </Box>
                ))}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitting || pristine}
              >
                Submit
              </Button>
            </form>
          )}
        />
      </Box>
    </Box>
  );
}

export default Override;
