import React from "react";
import {
  Alert, Box, Typography, Card,
} from "@mui/material";
import useFirstMeetingReports from "@/components/FirstMeetings/useFirstMeetings";
import FirstMeetingResponseItem from "@/components/FirstMeetings/ResponseItem";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { useQueryParam, StringParam, withDefault } from "use-query-params";
import Page from "@/ui/molecules/Page";
import { canVoteOnFirstMeeting } from "@/constants/Roles";
import { useAuth } from "@/hooks/useAuth";
import { getLastMonday } from "@/utils/general";

export default function FirstMeetingReports() {
  const LAST_MONDAY = getLastMonday(dayjs).format("YYYY-MM-DD");
  const [completedSince, setCompletedSince] = useQueryParam(
    "completedSince",
    withDefault(StringParam, LAST_MONDAY),
  );
  const { user } = useAuth();

  const canVote = canVoteOnFirstMeeting(user);

  const { data: responses, isLoading } = useFirstMeetingReports(
    completedSince,
    null,
  );

  const sortedResponses = React.useMemo(
    () => responses.sort(
      (a, b) => {
        const aDate = dayjs(a.completedAt);
        const bDate = dayjs(b.completedAt);
        return aDate.isBefore(bDate) ? 1 : -1;
      },
    ),
    [responses],
  );

  return (
    <Page>
      <Page.Title>Opportunity Assessment Approvals</Page.Title>
      <Page.Content>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          gap={1}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Completed Since"
              value={dayjs(completedSince)}
              onChange={(newValue) => {
                setCompletedSince(newValue.format("YYYY-MM-DD"));
              }}
              format="MM/DD/YYYY"
              minDate={dayjs("2024-03-20")}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            marginTop: 2,
          }}
        >
          {isLoading && <Typography variant="body1">Loading...</Typography>}

          <Box
            sx={{
              "& > div:not(:last-child)": {
                marginBottom: (t) => `${t.spacing(2)} !important`,
              },
            }}
          >
            {sortedResponses?.length === 0 && !isLoading && (
              <Alert severity="info">
                No responses found for this date range.
              </Alert>
            )}
            {sortedResponses?.map((response) => (
              <Card key={response.id}>
                <FirstMeetingResponseItem
                  response={response}
                  canVote={canVote}
                  canSeeVotes={false}
                  collapseVoted
                />
              </Card>
            ))}
          </Box>
        </Box>
      </Page.Content>
    </Page>
  );
}
